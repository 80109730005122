import { colors } from "config/theme/colors";
import { getMediaPx } from "helpers";
import styled from "styled-components";

export const Wrap = styled.div`
  border: 1px solid ${colors.grey10};
`;

export const Top = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 80px;
  height: 80px;
  align-items: center;
  padding-left: 15px;
  gap: 15px;
`;

export const ItemWrap = styled.div`
  display: grid;
  grid-template-columns: 150px 1fr;
  gap: 25px;
  padding: 15px;
  border-bottom: 1px solid ${colors.grey10};
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    grid-template-columns: 1fr;
  }
`;

export const ItemGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
  img {
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }
  @media (max-width: ${p => getMediaPx(p, "xl")}px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    grid-template-columns: 1fr;
  }
`;

export const Bottom = styled.div`
  background-color: ${colors.withOpacity(colors.grey10, 0.3)};
`;
