import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { CopyingStayModalListElement } from "./CopyingStayModalListElement";

const List = styled.div`
  width: 100%;
  height: auto;
  margin-top: ${({ theme }) => theme.setSpacing(6)}px;
`;

const CopyingStayModalList = ({ state, sections, onChange }) => {
  return (
    <List>
      {sections.map(({ label, key }) => (
        <CopyingStayModalListElement key={key} onChange={() => onChange(key)} value={state[key]} label={label} elementIdentifier={key} />
      ))}
    </List>
  );
};

CopyingStayModalList.propTypes = {
  state: PropTypes.shape().isRequired,
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
};

export { CopyingStayModalList, List };
