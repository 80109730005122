import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Search } from "@material-ui/icons";
import moment from "moment";
import PropTypes from "prop-types";

import { Content } from "components/ui/Content";
import { Spinner } from "components/ui/Spinner/Spinner";
import { Message } from "components/ui/Messages/Message";

import { FlightsSearchResults } from "feature/panel/Trips/_shared/Flights/FlightsSearchResults";
import { shoudShowFlightsSearchResults, shouldShowNotFoundText } from "feature/panel/Trips/_shared/Flights/helpers";

import { clearFoundFlights, getAirlineCodesStart, searchFlightsStart } from "store/trips/actions";
import AddIcon from "@material-ui/icons/Add";

import config from "config/app";
import { Controller } from "react-hook-form";
import { Button } from "feature/panel/_shared/MapWidget_old/styledComponents";
import { useEffectDependenciesOnly } from "hooks/useEffectDependenciesOnly";

import { FlightFormWrapper, MarginedAInputWrapper, SearchButton, SpinnerSection } from "./style";
import { inputs } from "./inputs";
import { NotFoundText, CONTENT_MARGIN_VALUES } from "./helpers";
import ManualForm from "./ManualForm";

const defaultValues = {
  departure_airport_code: "",
  departure_terminal: "",
  arrival_airport_code: "",
  arrival_terminal: "",
  adjust_airport_local_times: false,
};

const FlightsSearchForm = ({
  defaultFlightDate,
  onFlightsSelect,
  showManualForm,
  form: {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  },
  onShowManualForm,
}) => {
  const nextInputRef = useRef(null);
  const dispatch = useDispatch();

  const airlineCode = watch("carrier_code");
  const flightNumber = watch("carrier_flight_number");
  const flightDate = watch("date");

  const [searchedFlightNumber, setSearchedFlightNumber] = useState("");
  const [selectedFlights, setSelectedFlights] = useState([]);
  const [airlineCodeSelect, setAirlineCodeSelect] = useState(null);

  const { airlineCodes, listOfFoundFlights, inProgress, finished, actionType } = useSelector(state => state.trips);

  const { withFlights, withoutFlights } = CONTENT_MARGIN_VALUES;
  const contentMargin = listOfFoundFlights.length ? withFlights : withoutFlights;

  const onFlightsSelectHandler = () => {
    onFlightsSelect({
      selectedFlights,
      airlineCode: airlineCode?.value || airlineCode?.optionValue?.value,
      flightNumber,
      flightDate,
    });
  };
  const init = () => {
    dispatch(clearFoundFlights());
    setSelectedFlights([]);
  };

  useEffect(() => {
    dispatch(getAirlineCodesStart());
  }, [dispatch]);

  useEffect(init, []);
  useEffect(() => {
    if (!flightDate) {
      const newDate = moment(defaultFlightDate);

      reset({ date: newDate, departure_time: moment(Date.now()), arrival_at: moment(Date.now()) });
    }
  }, [defaultFlightDate]);

  useEffect(onFlightsSelectHandler, [selectedFlights]);

  useEffect(() => {
    if (showManualForm) reset({ ...defaultValues, carrier_code: airlineCode, carrier_flight_number: flightNumber, date: flightDate });
  }, [showManualForm]);

  const handleSearchFlights = values => {
    setSelectedFlights([]);
    dispatch(
      searchFlightsStart({
        fs: values["carrier_code"].value,
        number: +values["carrier_flight_number"],
        date: values["date"].format(config.apiDateFormat),
      }),
    );

    setSearchedFlightNumber(`${values["carrier_code"].value} ${flightNumber}`);
  };

  const handleFlightSelect = selectedFlight => {
    if (selectedFlights.some(flight => flight.id === selectedFlight.id)) {
      setSelectedFlights(selectedFlights.filter(flight => flight.id !== selectedFlight.id));
    } else {
      setSelectedFlights([...selectedFlights, selectedFlight]);
    }
  };

  return (
    <>
      <FlightFormWrapper showManualForm={showManualForm}>
        {inputs.map(item => (
          <MarginedAInputWrapper key={item.key}>
            <Controller
              control={control}
              name={item.props.name}
              rules={item.rules}
              render={({ field: { onChange, value = {} } }) => {
                const isCarrierCode = item.props.name === "carrier_code";

                return React.cloneElement(item.element, {
                  ...item.props,
                  value,
                  nextInputRef: (item.key !== 2 && nextInputRef) || null,
                  options: airlineCodes,
                  error: errors[item.props.name]?.message,
                  helperText: errors[item.props.name]?.message,
                  onChange: e => {
                    onChange(isCarrierCode ? e : e?.target?.value || e);
                  },
                  onBlur: e => {
                    if (isCarrierCode) {
                      const airline = airlineCodes.find(
                        item => e.target.value.toUpperCase() === item.value || e.target.value === item.label,
                      );
                      if (airline) {
                        setValue("carrier_code", { inputValue: airline.label, optionValue: airline });
                      }
                    }
                  },
                });
              }}
            />
          </MarginedAInputWrapper>
        ))}
        {!showManualForm && (
          <SearchButton
            size="large"
            onClick={handleSubmit(handleSearchFlights)}
            disabled={!airlineCode || !flightNumber || !flightDate || !flightDate.isValid()}
          >
            <Search />
            SEARCH
          </SearchButton>
        )}
      </FlightFormWrapper>
      <Content margin={contentMargin}>
        {inProgress && (
          <SpinnerSection>
            <Spinner />
          </SpinnerSection>
        )}

        {shoudShowFlightsSearchResults(finished, inProgress, actionType, listOfFoundFlights.length) && (
          <FlightsSearchResults flights={listOfFoundFlights} selectedFlights={selectedFlights} onFlightSelect={handleFlightSelect} />
        )}

        {showManualForm && <ManualForm control={control} errors={errors} />}

        {shouldShowNotFoundText(finished, actionType, listOfFoundFlights.length) && !showManualForm && (
          <>
            <Message type="info">
              <NotFoundText searchedFlightNumber={searchedFlightNumber} />
            </Message>
            <div style={{ display: "flex", justifyContent: "center", width: "100%", marginTop: 15 }}>
              <Button onClick={onShowManualForm} primary>
                <AddIcon />
                <span>Add flight manually</span>
              </Button>
            </div>
          </>
        )}
      </Content>
    </>
  );
};

FlightsSearchForm.defaultProps = {
  defaultFlightDate: null,
};

FlightsSearchForm.propTypes = {
  defaultFlightDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onFlightsSelect: PropTypes.func.isRequired,
};

export { FlightsSearchForm };
