import React from "react";
import PropTypes from "prop-types";
import { BreadcrumbsListItem } from "components/ui/Breadcrumbs/BreadcrumbsListItem";

const BreadcrumbsList = ({ list }) => list.map(item => <BreadcrumbsListItem key={item.url} {...item} />);

BreadcrumbsList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    isLast: PropTypes.bool.isRequired,
  }).isRequired).isRequired,
};

export { BreadcrumbsList };
