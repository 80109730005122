import React, { useContext } from "react";
import PropTypes from "prop-types";
import uuidv4 from "uuid";
import moment from "moment";

import TableCell from "@material-ui/core/TableCell";

import { Link } from "components/ui/Links/Links";
import { TableContext } from "components/ui/Tables/TableContext";
import { setUrl } from "utils/url";
import { StyledMuiTableRow, ActionTableCell } from "components/ui/Tables/_shared/styledComponents";

import config from "config/app";

const DefaultTableRow = ({ item }) => {
  const { columns, url, urlParams, actions: Actions, onRowClick } = useContext(TableContext);

  const params = () => {
    const paramsObj = {};
    urlParams.forEach(param => {
      if (item[param]) paramsObj[param] = item[param];
    });
    return paramsObj;
  };
  const handleRowClick = () => onRowClick(item);

  const dateCloumns = ["created_at", "updated_at", "expired_at"];

  return (
    <StyledMuiTableRow onClick={handleRowClick}>
      {columns &&
        columns.map(column => (
          <TableCell key={uuidv4()}>
            {url && (
              <Link to={setUrl(url, params())} cv="grey100" linkFor="table">
                {item[column]}
              </Link>
            )}
            {!url && (dateCloumns.includes(column) ? moment(item[column]).format(config.dateTimeFormat) : item[column])}
          </TableCell>
        ))}

      {Actions && (
        <ActionTableCell align="right">
          <Actions item={item} />
        </ActionTableCell>
      )}
    </StyledMuiTableRow>
  );
};

DefaultTableRow.propTypes = {
  item: PropTypes.shape().isRequired,
};

export { DefaultTableRow, StyledMuiTableRow, ActionTableCell };
