import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import styled from "styled-components";

import { PANEL_SETTINGS_CUSTOM_APPS } from "constants/routes";
import { LanguageSelector } from "components/_shared/LanguageSelector/LanguageSelector";
import { LanguageContext } from "components/_shared/LanguageSelector/LanguageContext";

const StyledWrapper = styled(({ languageSelector, ...props }) => <AppBar component="div" {...props} />)`
  && {
    top: 64px;
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.grey90};
    height: ${({ languageSelector }) => (languageSelector ? 128 : 64)}px;
    width: 100%;
    z-index: 1099;
    height: auto;
  }

  &:not(:last-child) {
    box-shadow: none;
  }
`;

const SlotsArea = styled.div`
  position: relative;
  width: 100%;
  height: inherit;
  min-height: 64px;
  max-width: 100vw;
  overflow-x: auto;

  @media (max-width: 1366px) {
    display: flex;
    padding: 0 20px;
  }
`;

const LeftSlot = styled.div`
  position: absolute;
  top: 0;
  left: ${({ theme }) => theme.setSpacing(6)}px;
  height: 64px;
  width: auto;
  display: flex;
  align-items: center;

  @media (max-width: 1366px) {
    position: static;
  }
`;

const MiddleSlot = styled.div`
  position: absolute;
  top: 0;
  left: ${({ moveToLeft }) => (moveToLeft ? 41 : 50)}%;
  height: 64px;
  width: auto;
  transform: translateX(-50%);
  display: flex;
  align-items: center;

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  @media screen and (min-width: 1380px) and (max-width: 1400px) {
    max-width: 29vw;
  }

  @media (max-width: 1366px) {
    position: static;
    transform: none;
    margin: 0 auto;
  }
`;

const RightSlot = styled.div`
  position: absolute;
  top: 0;
  right: ${({ theme }) => theme.setSpacing(6)}px;
  height: 64px;
  width: auto;
  display: flex;
  align-items: center;

  @media (max-width: 1366px) {
    position: static;
  }
`;

const LanguageSelectorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #f2f2f2;
  padding: 12px 0;
`;

const ContextBarComponent = ({
  leftSlot: LeftSlotContent,
  middleSlot: MiddleSlotContent,
  rightSlot: RightSlotContent,
  languageSelector,
  localLanguages,
  localLanguagesErrors,
  onLanguagesChange,
  onLanguageSwitch,
  editableLanguages,
  canSwitchLanguage,
}) => {
  const location = useLocation();
  const { currentLanguage, setLanguage, additionalLanguages } = useContext(LanguageContext);

  return (
    <StyledWrapper position="static" languageSelector={languageSelector && additionalLanguages.length > 0}>
      <SlotsArea>
        {LeftSlotContent && <LeftSlot>{typeof LeftSlotContent === "function" ? <LeftSlotContent /> : LeftSlotContent}</LeftSlot>}
        {MiddleSlotContent && (
          <MiddleSlot moveToLeft={window.innerWidth < 1457 && PANEL_SETTINGS_CUSTOM_APPS === location.pathname}>
            <MiddleSlotContent location={location} />
          </MiddleSlot>
        )}
        {RightSlotContent && <RightSlot>{typeof RightSlotContent === "function" ? <RightSlotContent /> : RightSlotContent}</RightSlot>}
      </SlotsArea>
      {languageSelector && additionalLanguages.length > 0 && (
        <LanguageSelectorWrapper>
          <LanguageSelector
            languages={localLanguages}
            errors={localLanguagesErrors}
            onEditToggle={onLanguagesChange}
            currentLanguage={currentLanguage}
            onChange={setLanguage}
            onLanguageSwitch={onLanguageSwitch}
            editableLanguages={editableLanguages}
            canSwitchLanguage={canSwitchLanguage}
            center
          />
        </LanguageSelectorWrapper>
      )}
    </StyledWrapper>
  );
};

ContextBarComponent.defaultProps = {
  leftSlot: null,
  middleSlot: null,
  rightSlot: null,
  languageSelector: false,
};

ContextBarComponent.propTypes = {
  leftSlot: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  middleSlot: PropTypes.func,
  rightSlot: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  languageSelector: PropTypes.bool,
};

const ContextBar = ContextBarComponent;

export { ContextBar, ContextBarComponent, StyledWrapper, LeftSlot, MiddleSlot, RightSlot };
