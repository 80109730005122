import React from "react";
import { Terms as TermsContent } from "feature/panel/Help/Terms/Terms";
import { DefaultPageLeftSection } from "feature/views/PublicPagesShared/DefaultPageLeftSection";
import { FullHeightColumn } from "components/ui/Grid/Grid";
import Grid from "@material-ui/core/Grid";
import { Content } from "components/ui/Content";
import { BrandSection } from "components/public/Brand/BrandSection";
import { DefaultPageRightSection } from "feature/views/PublicPagesShared/DefaultPageRightSection";
import bg from "assets/images/sign_up_bg.jpg";

const Terms = () => {
	return (
  <Grid container spacing={0} component="article">
    <DefaultPageLeftSection>
      <FullHeightColumn alignContent="flex-start">
        <Grid item xs={12}>
          <Content margin={10}>
            <BrandSection />
          </Content>
        </Grid>
        <Grid item xs={12}>
          <TermsContent component={Content} />
        </Grid>
      </FullHeightColumn>
    </DefaultPageLeftSection>
    <DefaultPageRightSection backgroundImage={bg} />
  </Grid>
	);
};

export { Terms };
