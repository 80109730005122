import React from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import styled from "styled-components";
import { TableCell, Tooltip } from "@material-ui/core";

import config from "config/app";

import { shorten } from "utils/string";
import { setUrl } from "utils/url";
import { OPERATOR_TYPE } from "constants/content";
import { ADMIN_OPERATOR_DETAILS_GENERAL_PATH, ADMIN_USER_DETAILS_PATH } from "constants/routes";

import { TableRow, useTableContext } from "components/ui/Tables";
import { LinkedTableRowCell } from "components/ui/Tables/LinkedTableRowCell";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

const OperatorLink = styled(Link)`
  color: #000000;
  font-weight: bold;
  text-decoration: underline;
`;

const renderCellValue = (key, userObj, location) => {
  if (!userObj[key]) {
    return "";
  }
  if (key === "name") {
    const nameColumnValue = `${userObj.firstname} ${userObj.lastname}`;
    if (nameColumnValue.length > 24) {
      return (
        <Tooltip title={nameColumnValue}>
          <span>{shorten(nameColumnValue, 24)}</span>
        </Tooltip>
      );
    }
    return nameColumnValue;
  }
  if (key === "operators") {
    const userOperators = userObj?.operators?.filter(operatorItem => operatorItem.type !== OPERATOR_TYPE.admin);
    if (userOperators.length) {
      return (
        <>
          <OperatorLink
            to={{
              pathname: setUrl(ADMIN_OPERATOR_DETAILS_GENERAL_PATH, { id: userObj.operators[0].id }),
              state: {
                previousUrl: location.pathname,
              },
            }}
          >
            {userObj.operators[0].name}
          </OperatorLink>
          {userOperators.length > 1 && (
            <Tooltip title={userOperators?.map(({ name }) => name).join(", ")}>
              <span> ...</span>
            </Tooltip>
          )}
        </>
      );
    }
    return "";
  }
  if (key === "lastLoginAt" || key === "passwordExpiresAt") {
    return moment(userObj[key]).format(config.dateTimeFormat);
  }
  return userObj[key];
};

const UsersTableRow = ({ item: user }) => {
  const permissionsService = usePermissionsService();
  const location = useLocation();
  const { headers } = useTableContext();
  const { id } = user;

  const columnsToRedner = headers.filter(header => header.show);

  const itemRedirectUrl = setUrl(ADMIN_USER_DETAILS_PATH, { id });
  const isReadDisabled = !permissionsService.can(
    PERMISSIONS.actions.read,
    PERMISSIONS.sections.admin,
    PERMISSIONS.resources.users,
    id || null,
  );

  const renderCellWrapper = key => {
    if (key === "operators") {
      return <TableCell key={key}>{renderCellValue(key, user, location)}</TableCell>;
    }
    if (isReadDisabled) {
      return <TableCell key={key}>{renderCellValue(key, user)}</TableCell>;
    }

    return (
      <LinkedTableRowCell url={itemRedirectUrl} key={key}>
        {renderCellValue(key, user)}
      </LinkedTableRowCell>
    );
  };

  return <TableRow item={user}>{columnsToRedner.map(({ key }) => renderCellWrapper(key))}</TableRow>;
};

UsersTableRow.propTypes = {
  item: PropTypes.shape({
    username: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    operators: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export { UsersTableRow };
