import React, { cloneElement } from "react";
import { Controller } from "react-hook-form";
import moment from "moment";
import { getDateFormat } from "utils/getDateFormat";
import { DatePickerS, DateTimePickerS, TimePickerS } from "./style";
import { ConditionalWrapper } from "../conditionalWrapper";
import InputErrorOrHint from "../components/Error";

const inputs = {
  date: <DatePickerS />,
  time: <TimePickerS />,
  dateTime: <DateTimePickerS />,
};

const DateTimeInput = ({
  label = "label",
  control,
  name,
  rules,
  type = "date",
  styleContainer = {},
  format,
  defaultValue,
  disabled,
  placeholder,
}) => {
  const renderInput = ({ error, value, onChange } = {}) => {
    return (
      <div style={{ ...styleContainer, opacity: disabled ? 0.4 : 1 }}>
        {cloneElement(inputs[type], {
          error,
          value: moment(value, format),
          onChange,
          format,
          label,
          ampm: false,
          disabled,
          placeholder,
        })}
        <InputErrorOrHint error={error} />
      </div>
    );
  };

  return (
    <>
      <ConditionalWrapper
        condition={control}
        wrapper={() => (
          <Controller
            control={control}
            name={name}
            rules={rules}
            defaultValue={defaultValue}
            render={({ field: { onChange, value }, fieldState: { error } }) => renderInput({ error, onChange, value })}
          />
        )}
      >
        {renderInput()}
      </ConditionalWrapper>
    </>
  );
};

export default DateTimeInput;
