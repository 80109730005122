import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";

import { ContextNavigationList } from "components/ui/Lists/ContextNavigationList";

import { checkSectionHasError } from "feature/panel/_shared/helpers";
import { ADMIN_USER_DETAILS_PATH } from "constants/routes";
import { GENERAL_SECTION_ERROR_NAMES } from "feature/admin/Operators/Details/constants";

import { setUrl } from "utils/url";

const Navigation = () => {
  const { errors } = useSelector(state => state.adminUsers);
  const params = useParams();
  const list = [
    {
      url: setUrl(ADMIN_USER_DETAILS_PATH, { id: params.id }),
      label: "General",
      icon: <ListAltOutlinedIcon />,
      isActive: !params.content,
      hasError: checkSectionHasError(GENERAL_SECTION_ERROR_NAMES, errors),
    },
  ];
  return <ContextNavigationList list={list} />;
};

export { Navigation };
