import styled from "styled-components";
import { colors } from "config/theme/colors";

export const Wrap = styled.div`
  min-height: 60px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background-color: ${colors.withOpacity(colors.error, 0.3)};
  color: ${colors.error};
`;
