import React from "react";
import { GhostButton } from "components/ui/Buttons";
import { CircularProgress } from "@mui/material";
import { colors } from "config/theme/colors";
import { ButtonsWrap } from "./style";

const Buttons = ({ confirmTitle, cancelTitle, onConfirm, onCancel, isLoading, hideConfirm, hideCancel, confirmDisabled }) => {
  return (
    <ButtonsWrap>
      {!hideCancel && (
        <GhostButton align="right" cv="grey40" cvh="grey60" onClick={onCancel}>
          {cancelTitle}
        </GhostButton>
      )}

      {!hideConfirm && (
        <GhostButton align="right" onClick={onConfirm} disabled={isLoading || confirmDisabled}>
          {isLoading ? <CircularProgress size={16} style={{ color: colors.brand }} /> : confirmTitle}
        </GhostButton>
      )}
    </ButtonsWrap>
  );
};

export default Buttons;
