import PropTypes from "prop-types";
import { responsiveConfig } from "config/responsive";
import styled from "styled-components";
import { getMediaPx } from "helpers";
import { getPanelPaddingStyles } from "components/_new/responsiveStyle";
import { Text as ItineraryTitle } from "./components/Title/style";

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 240px 1fr;
  height: ${p => (p.hasAdditionalMenu ? "calc(100vh - 195px)" : "calc(100vh - 130px)")};
  font-size: 16px;
  overflow: auto;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    grid-template-columns: 1fr;
    height: auto;
  }

  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    position: absolute;
    z-index: 10;
    top: ${p => (p.type === "trip" || !p.hasLanguages ? 178 : 245)}px;
    width: 100%;
    bottom: 0px;
  }

  @media (max-width: ${p => getMediaPx(p, "xs")}px) {
    top: ${p => (p.type === "trip" ? 178 : 235)}px;
  }
`;

export const TitleMobile = styled.div`
  display: none;
  font-weight: 600;
  padding: 20px 15px;
  position: sticky;
  top: 64px;
  background-color: #fff;
  z-index: 9;
  @media (max-width: ${responsiveConfig.mediaPx.laptop}px) {
    display: block;
  }
`;

export const Wrapper = styled.div`
  ${getPanelPaddingStyles()}
  max-width: 100%;
  overflow-x: hidden;
  position: relative;

  ${p => (p.slimTopPadding ? `padding-top: 0px !important` : ``)};
  ${ItineraryTitle} {
    @media (max-width: ${responsiveConfig.mediaPx.laptop}px) {
      font-size: 16px;
      margin-right: 15px;
    }
  }
`;

export const BottomSwitches = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0 8px;

  @media (max-width: ${responsiveConfig.mediaPx.laptop}px) {
    flex-direction: column;
    align-items: start;
    gap: 20px;
  }
`;

export const SwitchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const SwitchTip = styled.div`
  font-size: 11px;
`;

Wrapper.propTypes = {
  slimTopPadding: PropTypes.bool,
};
