import React from "react";
import styled from "styled-components";

import logotype from "assets/images/logotype.svg";
import { setSpacing } from "config/theme/spacing";

const stylesFor = {
  text: {
    small: {
      height: setSpacing(5)
    },
    medium: {
      height: setSpacing(8)
    },
    big: {
      height: setSpacing(11)
    }
  },
  sign: {
    small: {
      height: setSpacing(5)
    },
    medium: {
      height: setSpacing(8)
    },
    big: {
      height: setSpacing(11)
    }
  }
};

const Logo = styled(props => <img src={logotype} alt="Logotype" {...props} />)`
  && {
    ${({ size, type }) => `
        height: ${
          stylesFor[type] && stylesFor[type][size].height
            ? stylesFor[type][size].height
            : setSpacing(5)
        }px;
      `}
  }
`;

export { Logo };
