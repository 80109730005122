import { getMediaPx } from "helpers";
import styled from "styled-components";

export const Wrap = styled.div`
  display: flex;
  margin-top: 15px;
  img {
    width: 180px;
    height: 180px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    @media (max-width: ${p => getMediaPx(p, "sm")}px) {
      width: 100%;
      height: auto;
      &:hover {
        transform: none;
      }
    }
  }
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    display: block;
  }
`;

export const Item = styled.div`
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 22px;
  white-space: nowrap;
  span {
    font-weight: bold;
  }
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    white-space: normal;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 22px;
  margin-left: 22px;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    margin-left: 0;
    margin-top: 15px;
    grid-template-columns: 1fr;
  }

  #left,
  #right {
    ${Item}:last-child {
      margin-bottom: 0;
    }
  }
`;
