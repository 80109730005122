import React from "react";
import { Wrapper } from "feature/panel/Itinerary/style";
import ItineraryTitle from "feature/panel/Itinerary/components/Title";
import OutlinedButton from "components/_new/Buttons/OutlinedButton";
import AddIcon from "@mui/icons-material/Add";
import { useFieldArray } from "react-hook-form";
import uuidv4 from "uuid";
import { NoResultsMessage } from "components/ui/Messages";
import DnD from "components/_new/DnD_new";
import Item from "./item";

const Passcodes = ({
  form: {
    control,
    formState: { errors },
    watch,
  },
}) => {
  const { fields, append, remove, replace, move } = useFieldArray({
    control,
    name: "passcode_groups.passcode_groups",
    keyName: "rf_id",
  });

  const onDragEnd = (_, { source, destination }) => move(source.index, destination.index);

  return (
    <Wrapper>
      <ItineraryTitle style={{ paddingLeft: 0 }}>
        Passcode categories <OutlinedButton text="Add" startIcon={<AddIcon />} onClick={() => append({ id: uuidv4() })} />
      </ItineraryTitle>
      {fields?.length <= 0 && <NoResultsMessage>Please click &quot;ADD&quot; to start adding passcode groups</NoResultsMessage>}
      <DnD
        list={fields}
        element={<Item control={control} errors={errors} onRemove={index => remove(index)} watch={watch} />}
        // idVar="rf_id"
        contentStyle={{ padding: 0 }}
        onDragEnd={onDragEnd}
      />
    </Wrapper>
  );
};

export default Passcodes;
