import React from "react";
import { Paper } from "@material-ui/core";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Table, TableHeader, TableBody, TablePagination } from "components/ui/Tables";

import { connectColumns } from "./TableOfConnectItinerariesColumns";
import { ConnectItineraryTableRow } from "./ConnectItineraryTableRow";
import { TableRowActions } from "./TableRowActions";

const ActionsLabelWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Label = styled.div`
  width: 72px;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SORT_COLUMNS = {
  field1: "field1",
  vamoosId: "vamoos_id",
  operatorCode: "operator_code",
  operatorName: "operator_name",
  createdAt: "created_at",
  downloads: "downloads",
  id: "id",
};

const TableOfConnectItineraries = ({ itineraries, tableHandlers, count, isDataLoading }) => {
  const { handleChangePage, handleChangeRowsPerPage, handleChangeOrder, rowsPerPage, page } = tableHandlers;

  const ActionsLabel = () => (
    <ActionsLabelWrapper>
      <Label>Connect</Label>
    </ActionsLabelWrapper>
  );

  const handleOrderChange = ({ column, order }) => {
    handleChangeOrder({ column: SORT_COLUMNS[column], order });
  };

  return (
    <Paper>
      <Table
        headers={connectColumns}
        list={itineraries}
        actions={TableRowActions}
        onChangePage={handleChangePage}
        onChangeOrder={handleOrderChange}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        isDataLoading={isDataLoading}
      >
        <TableHeader withActions actionsLabel={<ActionsLabel />} />
        <TableBody rowComponent={ConnectItineraryTableRow} />
        <TablePagination count={count} page={page - 1} rowsPerPage={rowsPerPage} />
      </Table>
    </Paper>
  );
};

TableOfConnectItineraries.defaultProps = {
  itineraries: [],
  tableHandlers: {
    handleChangePage: () => {},
    handleChangeRowsPerPage: () => {},
    rowsPerPage: 10,
    page: 0,
  },
  count: 0,
  isDataLoading: false,
};

TableOfConnectItineraries.propTypes = {
  itineraries: PropTypes.arrayOf(PropTypes.shape({})),
  tableHandlers: PropTypes.shape({
    handleChangePage: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func,
    rowsPerPage: PropTypes.number,
    page: PropTypes.number,
  }),
  count: PropTypes.number,
  isDataLoading: PropTypes.bool,
};

export { TableOfConnectItineraries };
