import React from "react";
import styled from "styled-components";
import MuiSwitch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const StyledFormControlLable = styled(FormControlLabel)`
  && {
    margin: 0;
    .MuiFormControlLabel-label {
      font-size: ${({ theme }) => theme.fonts.fontSize.body2}px;
      color: ${({ theme }) => theme.colors.grey90};
      display: flex;
      align-items: center;
      gap: 16px;
    }
    .MuiTypography-body1 {
      font-size: 1rem !important;
      font-family: Montserrat !important;
      letter-spacing: 0 !important;
      white-space: nowrap;
    }
  }
`;

const Switch = styled(({ label, labelPlacement, ...rest }) => (
  <StyledFormControlLable control={<MuiSwitch {...rest} />} label={label} labelPlacement={labelPlacement} />
))`
  && {
    .MuiSwitch-switchBase {
      color: #fff;
    }
    .MuiSwitch-track {
      background-color: #000;
    }
    .Mui-checked {
      color: ${({ theme }) => theme.colors.brand};
      ${({ disabled }) => (disabled ? "opacity: .5;" : "")}
      + .MuiSwitch-track {
        background-color: ${({ theme }) => theme.colors.brand};
      }

      :hover {
        background-color: ${({ theme }) => theme.colors.withOpacity(theme.colors.brand, 0.08)};
      }
    }
  }
`;

export { Switch };
