import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Input } from "components/ui/Forms/Inputs";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

const StyledWrapper = styled.div`
  display: flex;
  height: 100%;
  width: ${({ fullWidth }) => fullWidth || "300px"};
  justify-content: center;
  align-items: center;

  .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.grey40};
    transition: ${({ theme }) => theme.transition};
  }

  :hover,
  &.Mui-focused {
    .MuiSvgIcon-root {
      color: ${({ theme }) => theme.colors.brandHover};
    }
  }
`;

export const CloseSearchButton = styled(InputAdornment)`
  && {
    cursor: pointer;
  }
`;

const SearchField = ({ width, onSearchChange, label, value, ...rest }) => {
  const handleValueChange = ({ target }) => {
    const { value: targetValue } = target;
    onSearchChange(targetValue);
  };

  const handleReset = () => handleValueChange({ target: { value: "" } });

  const inputSearchIcon = value ? <CloseOutlinedIcon /> : <SearchIcon />;

  return (
    <StyledWrapper fullWidth={width}>
      <Input
        label={label}
        name="search"
        noShadow
        fullWidth={!!width}
        endAdornment={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <CloseSearchButton position="end" onClick={handleReset}>
            {inputSearchIcon}
          </CloseSearchButton>
        }
        value={value}
        onChange={handleValueChange}
        {...rest}
      />
    </StyledWrapper>
  );
};

SearchField.defaultProps = {
  width: "",
  onSearchChange: () => {},
  label: "Search",
  value: "",
};

SearchField.propTypes = {
  width: PropTypes.string,
  onSearchChange: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.string,
};

export { SearchField, StyledWrapper };
