export class BrandRequestDto {
  /** @type {string} */
  name;

  /** @type {import(dto/dto.d.ts).BrandSectionRequestDto} */
  logo;

  /** @type {import(dto/dto.d.ts).BrandSectionRequestDto} */
  creations_header;

  /** @type {import(dto/dto.d.ts).BrandSectionRequestDto} */
  creations_footer;

  /** @type {boolean} */
  is_active;
}
