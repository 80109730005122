import { DeleteOutline } from "@mui/icons-material";
import React from "react";
import { IconWrap } from "./style";

const DeleteButton = ({ onClick, showMobileVersionFrom }) => {
  const handleClick = e => {
    e.stopPropagation();
    onClick();
  };

  return (
    <IconWrap onClick={handleClick} showMobileVersionFrom={showMobileVersionFrom}>
      <DeleteOutline />
    </IconWrap>
  );
};

export default DeleteButton;
