import React from "react";
import Checkbox from "components/_new/Checkbox";
import { convertToTimeFormat } from "utils/dataConverters";
import { Item } from "../../ViewFlightModal/style";
import { Info } from "./style";

const estimatedDepartureInfo = [
  {
    key: 0,
    label: "Estimated departure time",
    varName: "estimated_departure_time",
    render: data => convertToTimeFormat(data.departure_at_utc, data.airports?.[0].time_zone),
  },
  {
    key: 1,
    label: "Departure airport",
    varName: "departure_airport",
    render: data => data.airports?.[0].name,
  },
  {
    key: 2,
    label: "Departure city",
    varName: "departure_city",
    render: data => data.airports?.[0].city,
  },
];

const estimatedArrivalInfo = [
  {
    key: 0,
    label: "Estimated arrival time",
    varName: "estimated_arrival_time",
    render: data => convertToTimeFormat(data.arrival_at_utc, data.airports?.[1].time_zone),
  },
  {
    key: 1,
    label: "Arrival airport",
    varName: "arrival_airport",
    render: data => data.airports?.[1].name,
  },
  {
    key: 2,
    label: "Arrival city",
    varName: "arrival_city",
    render: data => data.airports?.[1].city,
  },
];

const FlightSelectItem = ({ data = {}, onSelect }) => {
  return (
    <div style={{ marginBottom: 15 }}>
      <Checkbox
        label={
          <Item style={{ marginBottom: 0 }}>
            Flight:
            <span>
              {" "}
              {data.carrier_iata || data.carrier_icao || data.carrier} {data.flight_number}
            </span>
          </Item>
        }
        onChange={value => {
          onSelect(value, data);
        }}
      />
      <Info>
        <div id="left">
          {estimatedDepartureInfo.map(item => (
            <Item key={item.key}>
              {item.label}: <span>{item.render ? item.render(data) : data[item.varName]} </span>
            </Item>
          ))}
        </div>
        <div id="right">
          {estimatedArrivalInfo.map(item => (
            <Item key={item.key}>
              {item.label}: <span>{item.render ? item.render(data) : data[item.varName]} </span>
            </Item>
          ))}
        </div>
      </Info>
    </div>
  );
};

export default FlightSelectItem;
