import config from "config/app";

/**
 * Custom logging service to be sure that logs are visible only on certain environments
 */
export class Logger {
  // eslint-disable-next-line class-methods-use-this
  /**
   * 
   * @param  {...any} msgs 
   */
  static debug(...msgs) {
    if (["staging", "uat"].includes(config.webVamoosEnv) && config.env !== "test") {
      // eslint-disable-next-line no-console
      console.log("[DEBUG]", ...msgs);
    }
  }
}