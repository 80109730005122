import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Table, TableHeader, TableBody, TablePagination } from "components/ui/Tables";
import { SwitchAction, TableActionsContainer } from "components/ui/Tables/TableRowActions";
import { PoisTableRow } from "feature/panel/Trips/_shared/Locations/PoisTab/PoisTableRow/PoisTableRow";
import headers from "feature/panel/Pois/Index/TableOfPois/TableOfPoisColumns.json";

import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";

import { Link } from "components/ui/Links/Links";
import { Message } from "components/ui/Messages/Message";
import { PANEL_POIS_PATH, PANEL_STAYS_POIS_PARAM } from "constants/routes";
import { setLocationsFilter } from "utils/dataConverters";

import { CREATE_ONE_LABEL, DEFINE_LOCATION_LABEL, NO_POIS_FOUND_FOR_LOCATIONS, NO_HOTEL_LOCATION } from "constants/content";
import { PERMISSIONS } from "constants/permissions";
import { usePermissionsService } from "hooks/usePermissionsService";
import { useTableHandlers } from "hooks/useTableHandlers";
import { useService } from "hooks/useService";
import { PoiService } from "services/PoiService";
import { Logger } from "services/application/Logger";
import { font } from "config/theme/fonts";
import { StyledLeadText } from "../../../../../../components/ui/Forms/FormSection";

const StyledLink = styled(Link)`
  && {
    margin-left: ${({ theme }) => theme.setSpacing(1)}px;
    line-height: 24px;
  }
`;

const PoiColumns = headers.columns.map(column => ({ ...column, sortable: false }));

const PoisTab = ({ context }) => {
  const titleRef = useRef();
  const [pois, setPois] = useState([]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [tableWidth, setTableWidth] = useState(0);
  const [clickedOverride, setClickedOverride] = useState(null);

  const poiService = useService(PoiService);

  const permissionsService = usePermissionsService();
  const { pois: poisList, setValueFor, locations, vamoos_id } = useContext(context);

  const hasLocations = locations && !!locations.length;

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoos_id);

  const { page, rowsPerPage, handleChangeRowsPerPage, handleChangePage } = useTableHandlers();
  const handleToggle = item => {
    const matchingPoi = poisList.find(({ id }) => id === item.id);
    const newPoiObject = matchingPoi
      ? { ...matchingPoi, isOn: !matchingPoi.isOn }
      : { ...item, id: item.id, isOn: !item.isDefaultOn, isDefaultOn: item.isDefaultOn };
    const updateExistingPoi = () => poisList.map(poi => (poi.id === matchingPoi.id ? newPoiObject : poi));
    const addNewPoi = () => [...poisList, newPoiObject];
    const newPoisList = matchingPoi ? updateExistingPoi() : addNewPoi();
    setValueFor(
      "pois",
      newPoisList?.filter(poi => poi.isDefaultOn !== poi.isOn).sort((a, b) => a.name.localeCompare(b.name)),
    );
  };
  const bodyCellAction = item => {
    const checked = (() => {
      const matchingPoi = poisList.find(({ id }) => id === item.item.id);
      return matchingPoi ? matchingPoi.isOn : item.item.isDefaultOn;
    })();
    return item?.item.isOverriden ? null : (
      <TableActionsContainer>
        <SwitchAction disabled={!canEdit} clickAction={() => handleToggle(item.item)} checked={checked} />
      </TableActionsContainer>
    );
  };

  const getPoisList = async () => {
    if (hasLocations) {
      try {
        setIsLoading(true);
        const { items, total_matches } = await poiService.getTablePois(
          page,
          rowsPerPage,
          null,
          null,
          null,
          null,
          setLocationsFilter(locations),
          false,
        );
        setPois(items);
        setCount(total_matches);
      } catch (e) {
        Logger.debug(e);
      } finally {
        setIsLoading(false);
      }
    } else {
      setPois([]);
    }
  };

  useEffect(() => {
    getPoisList();
  }, [page, rowsPerPage]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (clickedOverride) setClickedOverride(null);
    }, 1000);

    return () => clearTimeout(timer);
  }, [clickedOverride]);

  if (isLoading) return <LoadingScreen />;
  if (!hasLocations) {
    const generalSectionPath = window.location.pathname.replace(`/${PANEL_STAYS_POIS_PARAM}`, "");
    return (
      <Message type="info" text={NO_HOTEL_LOCATION}>
        <StyledLink to={generalSectionPath}>{DEFINE_LOCATION_LABEL}</StyledLink>
      </Message>
    );
  }
  if (!pois.length)
    return (
      <Message type="info" text={NO_POIS_FOUND_FOR_LOCATIONS}>
        <StyledLink to={PANEL_POIS_PATH}>{CREATE_ONE_LABEL}</StyledLink>
      </Message>
    );

  const TableRow = props => <PoisTableRow {...props} tableWidth={tableWidth} />;
  const TableRowOverride = props => <PoisTableRow {...props} clickedOverride={clickedOverride} shouldBlink />;

  const onRowClick = item => {
    if (item.isOverriden) {
      titleRef.current?.scrollIntoView({ behavior: "smooth" });
      setClickedOverride(item.id);
    }
  };
  return (
    <>
      <div ref={titleRef} />
      <StyledLeadText style={{ marginBottom: 15 }}>Trip specific settings</StyledLeadText>

      <Table list={poisList} headers={PoiColumns} actions={bodyCellAction} isDataLoading={isLoading} noPaper withBorder>
        <TableHeader withActions />
        <TableBody
          rowComponent={TableRowOverride}
          placeholderText="This table will show any trip specific overrides you make from the shared POI settings below"
        />
      </Table>

      <StyledLeadText style={{ marginBottom: 15, marginTop: 35 }}>Shared POI settings</StyledLeadText>

      <Table
        // list={pois?.filter(item => !poisList.find(newItem => newItem.id === item.id))}
        list={pois?.map(item => ({ ...item, isOverriden: Boolean(poisList.find(newItem => newItem.id === item.id)) }))}
        headers={PoiColumns}
        actions={bodyCellAction}
        isDataLoading={isLoading}
        noPaper
        withBorder
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        setTableWidth={setTableWidth}
        onRowClick={onRowClick}
      >
        <TableHeader withActions />
        <TableBody rowComponent={TableRow} />
        <TablePagination count={count} page={page - 1} rowsPerPage={rowsPerPage} />
      </Table>
    </>
  );
};

PoisTab.propTypes = {
  context: PropTypes.shape({}).isRequired,
};

export { PoisTab };
