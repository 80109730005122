export const locationMarkerXposition = 20;
export const locationMarkerYposition = 16;
export const locationMarkerScaledSize = 40;

export const defaultMarkerXposition = 15;
export const defaultMarkerYposition = 12;
export const defaultMarkerScaledSize = 30;

export const markerAnchorPoints = 22;

export const formattedPosition = value => Number(value).toFixed(7);

export const getCountryName = addressComponents => {
  if (addressComponents) {
    const countryComponent = addressComponents.find(component => component.types[0] === "country");
    if (countryComponent) return countryComponent.long_name || countryComponent.formatted_address;
  }
  return "";
};
