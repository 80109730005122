import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { GLOBAL_CONTENT, LOCATIONS_LABELS } from "constants/content";
import { SecondaryButton } from "components/ui/Buttons/SecondaryButton";
import { Clear } from "@material-ui/icons";
import { useService } from "hooks/useService";
import { StayService } from "services/domain/StayService";
import { Autocomplete } from "components/ui/Forms/Autocomplete";
import SelectInput from "components/ui/Inputs/Select";

const StaysListItem = styled.div`
  width: 100%;
  margin-top: 15px;

  ${({ isSelected, theme }) => (isSelected ? `background-color: ${theme.colors.brand}20;` : "")}
`;

const StayListItemFieldWrapper = styled.div`
  flex-grow: 2;
  /* max-width: 50%; */

  &:last-of-type {
    min-width: 118px;
    text-align: right;
    flex-grow: 0;
  }
`;

const StayListItem = ({ stay, onStaySelect, onStayClear, stays }) => {
  const handleSelectClear = () => {
    onStayClear(stay);
  };

  const clearButton = (
    <SecondaryButton onClick={handleSelectClear} size="small">
      <Clear />
      {GLOBAL_CONTENT.clear}
    </SecondaryButton>
  );

  return (
    <StaysListItem isSelected={!!stay?.vamoosId}>
      <SelectInput options={stays} optionLabelVar="name" optionValueVar="vamoos_id" label="Name" />
      {/* <StayListItemFieldWrapper>{!!stay?.vamoosId && clearButton}</StayListItemFieldWrapper> */}
    </StaysListItem>
  );
};

export { StayListItem };
