import { PanelTemplate } from "components/templates/Panel/PanelTemplate";
import { BaseButton } from "components/ui/Buttons";
import { Input } from "components/ui/Forms";
import { ErrorText } from "components/ui/Inputs/TextInput/style";
import { Message } from "components/ui/Messages";
import { Paper } from "feature/panel/Help/styledComponents";
import { UserIdField } from "feature/panel/_shared/UserIdField/UserIdField";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useMutation } from "@tanstack/react-query";
import { ITINERARY_DELETE_PASSCODE_URL } from "constants/api";
import { Controller, useForm } from "react-hook-form";
import { HttpClient } from "services/application/httpClient/httpClient";
import { setNotification } from "store/app/actions";
import { setUrl } from "utils/url";
import { Content } from "../Branding/Branding";
import { Navigation } from "../Navigation";
import { Header } from "../_shared/styledComponents";

const Passcodes = () => {
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    setValue,
    reset
  } = useForm({
    defaultValues: {
      operator: '',
      passcode: null,
    }
  });
  const currentOperatorCode = useSelector(state => state.auth.currentOperatorCode);

  const contextBar = {
    left: () => null,
    middle: Navigation,
    right: () => null,
  };

  const passcodeMutation = useMutation(({ operator, passcode }) => {
    const url = setUrl(ITINERARY_DELETE_PASSCODE_URL, { operator, code: passcode }, true);
    return HttpClient.delete(url);
  }, {
    onSuccess: (res) => {
      reset({ operator: currentOperatorCode, passcode: '' }, { keepValues: false });
      dispatch(setNotification({ type: "success", message: `The passcode has been successfully deleted.` }));
    },
    onError: (err, vars) => {
      dispatch(setNotification({ type: "error", message: `Passcode "${vars.passcode}" could not be deleted.` }));
    }
  })

  const onSubmit = values => passcodeMutation.mutate(values);

  useEffect(() => {
    if (currentOperatorCode) {
      setValue('operator', currentOperatorCode);
    }
  }, [currentOperatorCode]);

  return (
    <PanelTemplate contextBar={contextBar}>
      <Paper>
        <Content>
          <div>
            <Header>Delete existing passcode</Header>
            <Message
              type="error"
              largeIcon
              dangerousInnerHtml
              text="<span>By using this tool you can free up passcodes that are currently associated with your entries in Vamoos. For security reasons, all
        used passcodes remain associated with their trips even after a new one has been added. This is to avoid accidental re-use as this
        risks giving access to a new customer’s data to an old customer that still has the old login details.</span><br/><br/>
        <span>By proceeding you are acknowledging that you are aware of this potential issue with re-using passcodes.</span>"
            />
          </div>
          <div style={{ margin: "32px 0", display: 'flex', flexDirection: 'column', gap: 20 }}>

            <Controller
              control={control}
              name="operator"
              render={({ field: { onChange, value } }) => (
                <>
                  <UserIdField isRequired onChange={e => onChange(e.target.value)} label="User ID" value={value} />
                </>
              )}
            />

            <Controller
              control={control}
              name="passcode"
              rules={{ required: { value: true, message: "Passcode field is required" } }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <Input isRequired value={value} onChange={e => onChange(e.target.value)} label="Passcode" />
                  {error?.message ? <ErrorText>{error.message}</ErrorText> : null}
                </>
              )}
            />
          </div>

          <BaseButton disabled={passcodeMutation.isLoading} style={{ color: "#fff" }} onClick={handleSubmit(onSubmit)}>
            Delete
          </BaseButton>
        </Content>
      </Paper>
    </PanelTemplate>
  );
};

export default Passcodes;
