import { colors } from "config/theme/colors";
import { getMediaPx } from "helpers";
import styled from "styled-components";

export const Wrap = styled.div`
  display: flex;
  justify-content: ${p => (p.isMy ? "flex-end" : "start")};
  margin-bottom: 15px;
`;

export const Content = styled.div`
  border-radius: 8px;
  padding: 15px;
  background-color: ${p => (p.isMy ? colors.withOpacity(colors.brand, 0.3) : colors.grey10)};
  margin-top: 5px;
`;

export const ContentWrap = styled.div`
  width: 45%;

  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    width: auto;
    max-width: 90%;
    padding: 5px 0;
  }
`;
