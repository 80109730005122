import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import TableCell from "@material-ui/core/TableCell";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { SwitchAction, Table, TableBody, TableRow } from "components/ui/Tables";

import { useManageTripContext } from "feature/panel/Trips/_shared/ManageTripContext";

import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { TableActionsContainer } from "../../ui/Tables/TableRowActions";
import { ConfirmationModal } from "../../ui/Modals/ConfirmationModal";
import { mergeTravelPeopleToDisplay } from "../../../helpers";
import { GLOBAL_CONTENT, INDIVIDUAL_SETTINGS } from "../../../constants/content";
import { Checkbox } from "../../ui/Forms";

const FieldWrapper = styled(FormControlLabel)`
  margin-left: 0;
  margin-bottom: 12px;
`;

const TableWrapper = styled.div`
  .MuiTable-root {
    .MuiTableBody-root {
      .MuiTableRow-root {
        cursor: pointer;

        td {
          white-space: normal;
        }
      }
    }
  }

  &.inactive {
    opacity: 0.5;
    pointer-events: none;
  }
  &.inactive .MuiTable-root {
    background-color: #f0f0f0;
  }
`;

const TravellersListRow = ({ item }) => {
  const { name } = item;
  return (
    <TableRow item={item}>
      <TableCell>{name}</TableCell>
    </TableRow>
  );
};

TravellersListRow.propTypes = {
  item: PropTypes.shape().isRequired,
};

const TravellersRestrictionModal = ({ open, data, onClose, onToggle, name }) => {
  const [isSwitchActionTouched, setIsSwitchActionTouched] = useState(false);
  const [initialData, setInitialData] = useState(null); // Used when cancel changes
  const [travelPeopleToDisplay, setTravelPeopleToDisplay] = useState(null);
  const {
    setValueFor,
    listOfFlights,
    vamoos_id,
    editMode,
    is_wiped,
    travelPeople,
    errors,
    destinationDocuments,
    travelDocuments,
    storyboard,
  } = useManageTripContext();

  const restrictableSections = {
    listOfFlights,
    destinationDocuments,
    travelDocuments,
    storyboard,
  };

  useEffect(() => {
    // set initially travelPeopleToDisplay
    const mergeTravelPeople = mergeTravelPeopleToDisplay(travelPeople, errors);
    setTravelPeopleToDisplay(mergeTravelPeople);
  }, [travelPeople, errors]);

  const permissionsService = usePermissionsService();

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoos_id);

  useEffect(() => {
    setInitialData(data);
  }, [open]);

  const handleToggle = traveller => {
    setIsSwitchActionTouched(true);
    const updatedData = { ...data };
    const noRestrictions = updatedData.restricted_to_traveller_internal_ids === null;
    const isTravellerHasAccess = noRestrictions || updatedData.restricted_to_traveller_internal_ids.includes(traveller.internal_id);
    const travellerInternalIdsByName = travelPeople.filter(t => t.name === traveller.name).map(t => t.internal_id);

    if (isTravellerHasAccess) {
      if (noRestrictions) {
        const toggledTravellersWithAccessIds = travelPeople.filter(t => t.name !== traveller.name).map(t => t.internal_id);
        updatedData.restricted_to_traveller_internal_ids = [...toggledTravellersWithAccessIds];
      } else {
        updatedData.restricted_to_traveller_internal_ids = updatedData.restricted_to_traveller_internal_ids.filter(
          internal_id => !travellerInternalIdsByName.includes(internal_id),
        );
      }
    } else {
      updatedData.restricted_to_traveller_internal_ids = [...updatedData.restricted_to_traveller_internal_ids, ...travellerInternalIdsByName];
    }
    onToggle(updatedData);
  };

  const switchAction = item => {
    const checked = (() => {
      return data.restricted_to_traveller_internal_ids === null || data.restricted_to_traveller_internal_ids.includes(item.item.internal_id);
    })();
    return (
      <TableActionsContainer>
        {(data.restricted_to_traveller_internal_ids !== null) && (
          <>
            <SwitchAction clickAction={() => handleToggle(item.item)} checked={checked} disabled={!canEdit} />
          </>
        )}
      </TableActionsContainer>
    );
  };
  switchAction.propTypes = {
    item: PropTypes.shape().isRequired,
  };

  const onConfirm = () => {
    const updatedData = restrictableSections[name].map(item => {
      if (item.id === data.id) {
        return data;
      }
      return item;
    });

    setValueFor(name, updatedData);
    setIsSwitchActionTouched(false);
    onClose();
  };

  useEffect(() => {
    return () => {
      setIsSwitchActionTouched(false);
      setInitialData(null);
    };
  }, []);

  const onToggleRestrictionMode = () => {
    const updatedData = { ...data };
    updatedData.restricted_to_traveller_internal_ids = updatedData.restricted_to_traveller_internal_ids === null
      ? []
      : null;

    onToggle(updatedData);
    setIsSwitchActionTouched(true);
  }

  return (
    open && (
      <ConfirmationModal
        open={open}
        title={INDIVIDUAL_SETTINGS.title}
        confirmLabel={GLOBAL_CONTENT.apply}
        confirmDisabled={!isSwitchActionTouched}
        onCancel={() => {
          onClose(initialData);
          setIsSwitchActionTouched(false);
          setInitialData(null);
        }}
        onConfirm={onConfirm}
        minwidth="50%"
      >
        {canEdit && (
            <FieldWrapper
              label={INDIVIDUAL_SETTINGS.checkboxLabel}
              control={<Checkbox checked={data.restricted_to_traveller_internal_ids !== null} onChange={() => onToggleRestrictionMode()} />}
            />
          )}
        <TableWrapper className={data.restricted_to_traveller_internal_ids === null ? "inactive" : ""}>
          <Table headers='' list={travelPeopleToDisplay} actions={switchAction}>
            <TableBody rowComponent={TravellersListRow} />
          </Table>
        </TableWrapper>
      </ConfirmationModal>
    )
  );
};
TravellersRestrictionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export { TravellersRestrictionModal };
