import React, { useContext } from "react";
import PropTypes from "prop-types";

import { SortableList } from "components/ui/Lists";

import { FeaturesListItem } from "./FeaturesListItem";
import { TopFourContext } from "./TopFourContext";

const ListOfTopFourFeatures = ({ features, disabled }) => {
  const { onOrderChange } = useContext(TopFourContext);

  return (
    <SortableList lockAxis="y" onSortEnd={onOrderChange} lockToContainerEdges>
      {features.map((feature, index) => (
        <FeaturesListItem disabled={disabled} key={`feature-${feature.id}`} feature={feature} index={index} />
      ))}
    </SortableList>
  );
};

ListOfTopFourFeatures.defaultProps = {
  features: [],
  disabled: false
};

ListOfTopFourFeatures.propTypes = {
  features: PropTypes.arrayOf(PropTypes.shape()),
  disabled: PropTypes.bool
};

export { ListOfTopFourFeatures };
