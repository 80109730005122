import uuidv4 from "uuid";

const createSectionInitValues = () => ({
  id: uuidv4(),
  title: "",
  isNewItem: true,
});

const createGalleryInitValues = () => ({
  id: uuidv4(),
  headline: "",
  isNewItem: true,
});

export { createSectionInitValues, createGalleryInitValues };
