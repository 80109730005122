import * as React from "react";
import Popover from "@mui/material/Popover";

const BasicPopover = ({ anchorEl, children, containerStyle, onClose }) => {
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        style={{ borderRadius: 50 }}
        onClose={onClose}
      >
        <div style={containerStyle}>{children}</div>
      </Popover>
    </>
  );
};

export default BasicPopover;
