import { createOrUpdateTripStart, createOrUpdateTripValidationFail } from "store/trips/actions";

import { STORYBOARD_SHOW_ICONS_DEFAULT_ACCOUNT_VALUE, STORYBOARD_SHOW_ICONS_DISPLAYABLE_VALUES } from "constants/storyboard";
import { TripRequestDto } from "dto/TripRequestDto";
import { HttpClient } from "services/application/httpClient/httpClient";
import { FILE_S3_INIT_URL } from "constants/api";
import { ListIndexService } from "services/ListIndexService";
import { StorageService } from "services/StorageService";
import { NotificationsService } from "services/NotificationsService";
import { compareNotifications } from "feature/panel/Itinerary/pages/Trips/Notifications/helpers";
import { transformNotificationTemplateData } from "domain/Trip";

export const updateTripOriginalIndexes = async state => {
  const listIndexService = new ListIndexService(new StorageService());
  listIndexService.clearOriginalIndexes();
  const stateMap = ["destinationDocuments", "travelDocuments", "locations", "travelPeople", "storyboard"];
  await listIndexService.updateOriginalIndexesPosition(state, stateMap);

  const storyboardDocumentsMap = state.storyboard.map(day => `documentsForDay-nr${day.id}`);
  const storyboardPayload = state.storyboard.reduce(
    (obj, day) => Object.assign(obj, { [`documentsForDay-nr${day.id}`]: day.documents }),
    {},
  );
  await listIndexService.updateOriginalIndexesPosition(storyboardPayload, storyboardDocumentsMap);
};

export const createTripHandler = async (tripDomainObject, validator, dispatcher, context = "update") => {
  const notificationService = new NotificationsService();

  let allNotifications = await notificationService.getAllNotifications();
  allNotifications = transformNotificationTemplateData(allNotifications, tripDomainObject.language, true);

  tripDomainObject.notifications = tripDomainObject.notifications.filter(payloadNotif => {
    const sharedNotif = allNotifications.find(n => n.id === payloadNotif.template_id);
    if (!sharedNotif) { return true }

    if (sharedNotif.is_active !== payloadNotif.is_active) {
      return true;
    }

    const identical = compareNotifications(payloadNotif, sharedNotif);
    return !identical;
  });

  await updateTripOriginalIndexes(tripDomainObject);

  await tripDomainObject.refreshLinks();

  const dto = TripRequestDto(tripDomainObject);

  const payload = {
    referenceCode: tripDomainObject.passcode,
    operatorCode: tripDomainObject.userId,
    data: dto,
    context,
  };
  const isValid = validator(dto);

  if (isValid) {
    await dispatcher(createOrUpdateTripStart(payload));
  } else {
    await dispatcher(createOrUpdateTripValidationFail());
  }
};

export const transformStoryBoardIconShowValues = selectedValue => {
  const [summary, details] = STORYBOARD_SHOW_ICONS_DISPLAYABLE_VALUES.slice(1);

  if (selectedValue === STORYBOARD_SHOW_ICONS_DEFAULT_ACCOUNT_VALUE) {
    return { show_story_board_summary: undefined, show_story_board_daily: undefined };
  }
  if (selectedValue === summary.value) {
    return { show_story_board_summary: "1", show_story_board_daily: "0" };
  }
  if (selectedValue === details.value) {
    return { show_story_board_summary: "0", show_story_board_daily: "1" };
  }
  return { show_story_board_summary: "1", show_story_board_daily: "1" };
};

export const transformStoryBoardDisplayableValue = (showSummaryFlag, showDailyFlag) => {
  const [summaryAndDetails, summary, details] = STORYBOARD_SHOW_ICONS_DISPLAYABLE_VALUES;
  if (!showSummaryFlag && !showDailyFlag) {
    return STORYBOARD_SHOW_ICONS_DEFAULT_ACCOUNT_VALUE;
  }
  if (Number(showSummaryFlag) && Number(showDailyFlag)) {
    return summaryAndDetails.value;
  }
  if (Number(showSummaryFlag)) {
    return summary.value;
  }
  return details.value;
};

export const GENERAL_SECTION_ERROR_NAMES = [
  "operator_code",
  "reference_code",
  "field1",
  "field2",
  "field3",
  "field4",
  "departure_date",
  "return_date",
  "start_time",
  "timezone",
  "client_reference",
  "background",
  "logo",
  "passcode",
];

export const TRIPS_SECTION_ERRORS = [
  {
    label: "General",
    errorNames: GENERAL_SECTION_ERROR_NAMES,
  },
  {
    label: "Documents",
    errorNames: ["documents"],
  },
  {
    label: "Locations",
    errorNames: ["locations"],
  },
  {
    label: "Storyboard",
    errorNames: ["details"],
  },
  {
    label: "Notifications",
    errorNames: ["notifications", "gpsNotifications"],
  },
  {
    label: "People",
    errorNames: ["travellers"],
  },
  {
    label: "Flights",
    errorNames: ["flights"],
  },
  {
    label: "Inspirations",
    errorNames: ["inspirations"],
  },
  {
    label: "Messaging",
    errorNames: ["messaging"],
  },
];

export const uploadToS3 = (url, file) => {
  return fetch(url, {
    method: "put",
    body: file,
  });
};

export const saveExternalImageToS3 = async imageUrl => {  
  try {
    imageUrl += (imageUrl.includes("?") ? "&" : "?") + "not-from-cache-please";
    const image = await fetch(imageUrl, {
      headers: {
        Origin: window.location.origin,
      },
    });
    const fileName = imageUrl
      .split("/")
      .pop()
      .replace(",", "-");
    if (image.blob) {
      const localBlob = await image.blob();
      const blobPreview = window.URL.createObjectURL(localBlob);
      const file = new File([localBlob], fileName, { type: localBlob.type });
      const { data } = await HttpClient.post(FILE_S3_INIT_URL, {
        filename: fileName,
        content_type: localBlob.type,
      });

      await uploadToS3(data.url, file);

      return {
        file_name: fileName,
        file_url: data.s3url,
        is_library_file: false,
        preview_url: blobPreview,
        type: localBlob.type,
      };
    }

    return null;
  } catch (e) {
    return null;
  }
};
