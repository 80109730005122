import React from "react";
import RadioMui from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { ConditionalWrapper } from "components/ui/Inputs/conditionalWrapper";
import { Controller } from "react-hook-form";
import { FormControlLabelS } from "./style";

const RadioButtons = ({ control, name, rules, label, labelStyle = {}, wrapStyle, buttons }) => {
  const renderButtons = ({ error, value, onChange, name } = {}) => {
    return (
      <RadioGroup value={value} onChange={onChange} name={name}>
        {buttons.map(item => (
          <FormControlLabelS value={item.value} label={item.label} labelStyle={labelStyle} style={wrapStyle} control={<RadioMui />} />
        ))}
      </RadioGroup>
    );
  };

  return (
    <ConditionalWrapper
      condition={control}
      wrapper={() => (
        <Controller
          control={control}
          name={name}
          rules={rules}
          defaultValue={null}
          render={({ field: { onChange, value }, fieldState: { error } }) => renderButtons({ error, onChange, value, name })}
        />
      )}
    >
      {renderButtons()}
    </ConditionalWrapper>
  );
};

export default RadioButtons;
