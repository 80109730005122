import OutlinedButton from "components/_new/Buttons/OutlinedButton";
import { Message } from "components/ui/Messages";
import { NotFoundText } from "feature/panel/Trips/_shared/Flights/FlightsSearchForm/helpers";
import React from "react";

const NotFound = ({ flight, onClick }) => {
  return (
    <>
      <Message type="info">
        <NotFoundText searchedFlightNumber={flight} />
      </Message>
      <div style={{ marginTop: 15, textAlign: "center" }}>
        <OutlinedButton text="Add flight manually" onClick={onClick} />
      </div>
    </>
  );
};

export default NotFound;
