import React, { useEffect, useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";

import { Table, TableHeader, TableBody, TablePagination } from "components/ui/Tables";
import { TableOfPoisFilters } from "feature/panel/Pois/Index/TableOfPois/TableOfPoisFilters";
import { PoisTableRow } from "feature/panel/Pois/Index/TableOfPois/PoisTableRow/PoisTableRow";
import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";
import { NoResultsMessage } from "components/ui/Messages/NoResultsMessage";
import { NO_RESULTS_FOUND_MESSAGE, TABLE_NAMES } from "constants/content";

import headers from "feature/panel/Pois/Index/TableOfPois/TableOfPoisColumns.json";

import { useTableHandlers } from "hooks/useTableHandlers";

import { EventBus } from "services/application/EventBus";
import { PoiHasBeenUpdated } from "events/PoiHasBeenUpdated";
import { PoiHasBeenDisabledOrEnabled } from "events/PoiHasBeenDisabledOrEnabled";
import { PoiMapper } from "mappers/PoiMapper";

import { TableOfPoisRowActions } from "./TableOfPoisRowActions";
import { filterPOIs, sortArrayOfObjects } from "./helpers";

const TableOfPois = ({ onClickRowAction, onSearch, data, isLoading, isFetching, refetch, total }) => {
  const poiMapper = new PoiMapper();
  const [searchValue, setSearchValue] = useState("");
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [sorting, setSorting] = useState(null);

  const { rowsPerPage, page, handleChangeRowsPerPage, handleChangePage } = useTableHandlers();

  const mappedData = data?.map(poi => poiMapper.fromDtoToDomain(poi));

  const pois = useMemo(() => {
    const filtered = filterPOIs(mappedData, searchValue, selectedCountries);

    const column = headers.columns.find(item => item.key === sorting?.column)?.varName;
    const sorted = sortArrayOfObjects(filtered, column, sorting?.order);

    return sorted;
  }, [searchValue, data, selectedCountries, sorting]);

  const handleCountryChange = countries => {
    handleChangePage(0);
    setSelectedCountries(countries);
  };
  const handleSearchChange = value => {
    handleChangePage(0);
    setSearchValue(value);
  };

  const isFiltering = searchValue || selectedCountries.length;

  useEffect(() => {
    if (isFiltering) {
      onSearch(pois);
    } else {
      onSearch([]);
    }
  }, [pois]);

  useEffect(() => {
    EventBus.on(PoiHasBeenUpdated, refetch);
    EventBus.on(PoiHasBeenDisabledOrEnabled, refetch);

    return () => {
      EventBus.remove(PoiHasBeenUpdated, refetch);
      EventBus.remove(PoiHasBeenDisabledOrEnabled, refetch);
    };
  }, [refetch]);
  const content = (() => {
    if (isLoading) return <LoadingScreen />;
    if (!mappedData?.length) return <NoResultsMessage height="120px">{NO_RESULTS_FOUND_MESSAGE}</NoResultsMessage>;
    return (
      <Table
        list={isFiltering ? pois : pois?.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage) || []}
        headers={headers.columns}
        actions={props => <TableOfPoisRowActions onClickAction={onClickRowAction} {...props} />}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onChangeOrder={setSorting}
        isDataLoading={isLoading || isFetching}
        // count={5}
        noPaper
      >
        <TableHeader withActions />
        <TableBody rowComponent={props => <PoisTableRow onPoiUpdate={() => refetch()} {...props} />} />
        <TablePagination page={isFiltering ? 1 : page - 1} count={total} tableId={TABLE_NAMES.poi} />
      </Table>
    );
  })();
  return (
    <Paper>
      <TableOfPoisFilters
        selectedCountries={selectedCountries}
        setSelectedCountry={handleCountryChange}
        setPoiSearch={handleSearchChange}
      />
      {content}
    </Paper>
  );
};

TableOfPois.propTypes = {
  onClickRowAction: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};

export { TableOfPois };
