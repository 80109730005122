import { LibraryRepository } from "repositories/LibraryRepository";

export class LibraryService {

  constructor(
    libraryRepository = new LibraryRepository()
  ) {
    this.libraryRepository = libraryRepository;
  }

  async getListOfFiles(page, rowsPerPage, path, sortingOrder, sortingBy, search) {
    return this.libraryRepository.getListOfFiles(page, rowsPerPage, path, sortingOrder, sortingBy, search);
  }

  async getAllItemsFromDirectory(path) {
    return this.libraryRepository.getAllItemsFromDirectory(path);
  }

  async searchItems(query) {
    return this.libraryRepository.searchItems(query);
  }

  async createFolder(name, path) {
    return this.libraryRepository.createFolder(name, path);
  }

  async uploadFileToS3(file) {
    return this.libraryRepository.uploadFileToS3(file);
  }

  async imageUrlToFile(imageUrl, prefix) {
    const url = new URL(imageUrl);
    const res = await fetch(imageUrl);
    const blob = await res.blob();

    const file = new File([blob], `${ prefix ? prefix + "_" : "" }${ url.pathname.substring(1) }_${ (new Date().getTime()) }`, { type: blob.type });
    return file;
  };

  async addFile(folderPath, url, remote_url, file_name, options) {
    return this.libraryRepository.addFile(folderPath, url, remote_url, file_name, options);
  }

  async removeItem(item) {
    return this.libraryRepository.removeItem(item);
  }

  async updateItem(selectedItem, name, path, url) {
    const { icon_id, file, is_folder, id, remote_url } = selectedItem;

    if (selectedItem.path !== `${path}/${name}` || (file && file.s3_url !== url) || (remote_url && remote_url !== url)) {
      const newPath = `${path}/${name}`;

      const payload = {
        icon_id,
        path: newPath,
        id,
      };
      if (!is_folder && !remote_url) {
        payload.url = url;
      }
      if (!is_folder && remote_url) {
        payload.remote_url = url;
      }

      return this.libraryRepository.updateItem(payload);
    }

    return null;

  }
}