import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import Checkbox from "components/_new/Checkbox";
import BaseModal from "components/_new/ModalBase";
import { colors } from "config/theme/colors";
import { hasDuplicate } from "feature/panel/Itinerary/helpers";
import { objectToArray } from "helpers";

const FeaturesModal = ({ features = {}, handleSave, onClose }) => {
  const [data, setData] = useState({});

  const hasDuplicateInObject = (object, currentItem) => {
    const values = Object.values(object);
    const currentItemValue = currentItem.name; // Assuming 'name' is the property to check for duplicates

    let count = 0;
    for (const value of values) {
      if (value.name === currentItemValue) {
        count++;
        if (count > 1) {
          return true;
        }
      }
    }
    return false;
  };

  const onCheck = (checked, key) => {
    const newData = { ...data };
    newData[key].is_featured = checked;
    setData(newData);
  };

  const onSave = () => {
    handleSave(data);
    onClose();
  };

  useEffect(() => {
    if (features) setData(features);
  }, [features]);

  return (
    <BaseModal title="Select TOP 4 features" confirmTitle="Save" onConfirm={onSave} onCancel={onClose} topLineStyle={{ marginBottom: 15 }}>
      {Object.entries(data).map(([key, { name, is_featured, group_name }]) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Checkbox label={name} checked={is_featured} onChange={checked => onCheck(checked, key)} />

          {hasDuplicateInObject(features, { name }) && <span style={{ color: colors.grey20 }}>({group_name})</span>}
        </div>
      ))}
    </BaseModal>
  );
};

export default FeaturesModal;
