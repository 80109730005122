import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { WatchLater } from "@material-ui/icons";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";

import { EditAction, DeleteAction } from "components/ui/Tables";
import { SortableListItem, SortableHandler } from "components/ui/Lists";
import { TextLimiter } from "components/ui/TextLimiter";

import { AnchorLikeButton, ListRowSquareField } from "feature/panel/Stays/_shared/styledComponents";
import {
  ContentDivider,
  ContentText,
  DirectoryWrapper,
  ReorderButton,
  StyledAccountIcon,
  StyledReorderIcon,
} from "feature/panel/Stays/_shared/DailyDirectoryVoucherShared/styledComponents";

import { TABLE_ACTIONS_TITLES } from "constants/content";
import { WEEKDAY_VALUES_ARRAY } from "utils/directories";
import { TableActionsContainer } from "components/ui/Tables/TableRowActions";
import { getMediaPx } from "helpers";

const WeekdayChip = styled.p`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 34px;
    font-size: ${({ theme }) => theme.fonts.fontSize.body3}px;
    margin: 0 ${({ theme }) => theme.setSpacing(2)}px 0 0;
    border: 1px solid ${({ active, theme }) => (active ? theme.colors.brand : theme.colors.grey40)};
    border-radius: ${({ theme }) => 2 * theme.borderRadius}px;
    background: ${({ active, theme }) => (active ? theme.colors.brand : theme.colors.white)};
    color: ${({ active, theme }) => (!active ? theme.colors.grey40 : theme.colors.white)};
    text-transform: capitalize;

    :last-of-type {
      margin: 0;
      @media (max-width: ${p => getMediaPx(p, "md")}px) {
        margin-right: 10px;
      }
    }
    @media (max-width: ${p => getMediaPx(p, "md")}px) {
      border: 0;
      margin: 0;
      background: 0;
      color: ${({ active, theme }) => (!active ? theme.colors.grey40 : theme.colors.brand)};
      font-weight: 600;
      padding: 0;
      width: auto;
      margin-right: 15px;
    }
  }
`;

const SelectItem = styled(MenuItem)`
  &&& {
    background: none;
    :hover {
      background: none;
    }
  }
`;

const FlexContainer = styled.div`
  display: flex;
`;

const DailyListRow = ({ directory, onEditStart, onDirectoryClick, onDeleteClick, index, isSorted }) => {
  const { is_list, name, weekdays } = directory;
  const icon = is_list ? <StyledAccountIcon /> : <WatchLater />;

  return (
    <SortableListItem index={index}>
      <DirectoryWrapper isSorted={isSorted}>
        <Grid container spacing={3}>
          <Grid container item xs={4} alignItems="center">
            <FlexContainer>
              <SortableHandler>
                <ReorderButton>
                  <StyledReorderIcon />
                </ReorderButton>
              </SortableHandler>
              <ListRowSquareField>{icon}</ListRowSquareField>
              <AnchorLikeButton onClick={onDirectoryClick} disabled={!is_list}>
                <ContentText>
                  <TextLimiter text={name} maxChar={30} />
                </ContentText>
              </AnchorLikeButton>
            </FlexContainer>
          </Grid>
          <Grid container item xs={4} alignItems="center">
            <ContentDivider>
              {WEEKDAY_VALUES_ARRAY.map(weekday => (
                <WeekdayChip key={weekday} active={weekdays[weekday]}>
                  {weekday[0]}
                </WeekdayChip>
              ))}
            </ContentDivider>
          </Grid>
          <Grid item xs={4}>
            <ContentDivider>
              <TableActionsContainer>
                <EditAction
                  clickAction={() => onEditStart(directory)}
                  tooltip={TABLE_ACTIONS_TITLES.editResource(TABLE_ACTIONS_TITLES.resourceTypes.activity)}
                />
                <DeleteAction
                  clickAction={() => onDeleteClick(directory)}
                  tooltip={TABLE_ACTIONS_TITLES.deleteResource(TABLE_ACTIONS_TITLES.resourceTypes.activity)}
                />
              </TableActionsContainer>
            </ContentDivider>
          </Grid>
        </Grid>
      </DirectoryWrapper>
    </SortableListItem>
  );
};

DailyListRow.propTypes = {
  directory: PropTypes.shape({
    is_list: PropTypes.bool.isRequired,
    updated_at: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    weekdays: PropTypes.shape({}).isRequired,
  }).isRequired,
  onEditStart: PropTypes.func.isRequired,
  onDirectoryClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  isSorted: PropTypes.bool.isRequired,
};

export { DailyListRow, WeekdayChip, SelectItem };
