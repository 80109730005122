import React from "react";
import styled from "styled-components";
import { getStickyPanelContentStyles } from "components/_new/responsiveStyle";
import SelectInput from "components/ui/Inputs/Select";
import ItineraryTitle from "../Title";
import { Wrapper } from "../../style";

const options = [
  {
    label: "Always show",
    value: "always",
  },
  {
    label: "Show during stay",
    value: "during",
  },
  {
    label: "Passcodes only",
    value: "passcodes",
  },
  {
    label: "Off",
    value: "off",
  },
];

const AccessSettingsContainer = styled.div`
  background-color: rgba(232, 232, 233, 0.3);
  ${getStickyPanelContentStyles()}
`;

const AccessSettingInput = ({ control, name = "access" }) => {
  return (
    <AccessSettingsContainer>
      <div style={{ paddingTop: 20, paddingBottom: 20 }}>
        <ItineraryTitle style={{ paddingLeft: 0 }}>Access settings</ItineraryTitle>
        <SelectInput
          control={control}
          name={name}
          label="When to display"
          options={options}
          labelStyle={{ backgroundColor: "rgb(248 248 248)" }}
          isClearable={false}
        />
      </div>
    </AccessSettingsContainer>
  );
};

export default AccessSettingInput;
