import styled from "styled-components";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const ArrowIcon = styled(ArrowForwardIosIcon)`
  && {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.grey50};
    margin: 0 12px;
  }
`;

const BreadcrumbsMenuArea = styled.div`
  display: flex;
  align-items: center;
`;

export { ArrowIcon, BreadcrumbsMenuArea };
