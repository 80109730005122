import * as actionTypes from "store/trips/actionTypes";
import { createAction } from "utils/store";

export const getTripsStart = createAction(actionTypes.TRIPS_GET_TRIPS_START);
export const getTripsSuccess = createAction(actionTypes.TRIPS_GET_TRIPS_SUCCESS);
export const getTripsFail = createAction(actionTypes.TRIPS_GET_TRIPS_FAIL);

export const createOrUpdateTripStart = createAction(actionTypes.CREATE_OR_UPDATE_TRIP_START);
export const createOrUpdateTripSuccess = createAction(actionTypes.CREATE_OR_UPDATE_TRIP_SUCCESS);
export const createOrUpdateTripFail = createAction(actionTypes.CREATE_OR_UPDATE_TRIP_FAIL);
export const createOrUpdateTripValidationFail = createAction(actionTypes.CREATE_OR_UPDATE_TRIP_VALIDATION_FAIL);

export const getTripCreationsStart = createAction(actionTypes.TRIPS_GET_TRIP_CREATIONS_START);
export const getTripCreationsSuccess = createAction(actionTypes.TRIPS_GET_TRIP_CREATIONS_SUCCESS);
export const getTripCreationsFail = createAction(actionTypes.TRIPS_GET_TRIP_CREATIONS_FAIL);

export const createTripCreationStart = createAction(actionTypes.CREATE_TRIP_CREATION_START);
export const createTripCreationSuccess = createAction(actionTypes.CREATE_TRIP_CREATION_SUCCESS);
export const createTripCreationFail = createAction(actionTypes.CREATE_TRIP_CREATION_FAIL);

export const deleteTripCreationStart = createAction(actionTypes.DELETE_TRIP_CREATION_START);
export const deleteTripCreationSuccess = createAction(actionTypes.DELETE_TRIP_CREATION_SUCCESS);
export const deleteTripCreationFail = createAction(actionTypes.DELETE_TRIP_CREATION_FAIL);

export const refreshTripCreationStart = createAction(actionTypes.REFRESH_TRIP_CREATION_START);
export const refreshTripCreationSuccess = createAction(actionTypes.REFRESH_TRIP_CREATION_SUCCESS);
export const refreshTripCreationFail = createAction(actionTypes.REFRESH_TRIP_CREATION_FAIL);

export const clearTripCreationJob = createAction(actionTypes.CLEAR_TRIP_CREATION_JOB);

export const getAirlineCodesSuccess = createAction(actionTypes.TRIPS_GET_AIRLINE_CODES_SUCCESS);
export const getAirlineCodesFail = createAction(actionTypes.TRIPS_GET_AIRLINE_CODES_FAIL);
export const getAirlineCodesStart = createAction(actionTypes.TRIPS_GET_AIRLINE_CODES_START);

export const searchFlightsStart = createAction(actionTypes.TRIPS_SEARCH_FLIGHTS_START);
export const searchFlightsSuccess = createAction(actionTypes.TRIPS_SEARCH_FLIGHTS_SUCCESS);
export const searchFlightsFail = createAction(actionTypes.TRIPS_SEARCH_FLIGHTS_FAIL);

export const clearFoundFlights = createAction(actionTypes.TRIPS_CLEAR_FOUND_FLIGHTS);

export const tripsAddSelectedFlightsStart = createAction(actionTypes.TRIPS_ADD_SELECTED_FLIGHTS_START);
export const tripsAddSelectedFlightsSuccess = createAction(actionTypes.TRIPS_ADD_SELECTED_FLIGHTS_SUCCESS);
export const tripsAddSelectedFlightsFail = createAction(actionTypes.TRIPS_ADD_SELECTED_FLIGHTS_FAIL);
export const tripsClearSelectedFlightsList = createAction(actionTypes.CLEAR_LIST_OF_SELECTED_FLIGHTS);

export const copyTripStart = createAction(actionTypes.COPY_TRIP_START);
export const copyTripSuccess = createAction(actionTypes.COPY_TRIP_SUCCESS);
export const copyTripFail = createAction(actionTypes.COPY_TRIP_FAIL);

export const toggleTripActiveStatusStart = createAction(actionTypes.TOGGLE_TRIP_ACTIVE_STATUS_START);

export const checkIsPasscodeAvailableStart = createAction(actionTypes.CHECK_PASSCODE_AVAILABILITY_START);
export const checkIsPasscodeAvailableSuccess = createAction(actionTypes.CHECK_PASSCODE_AVAILABILITY_SUCCESS);

export const getRecentLocationStart = createAction(actionTypes.GET_RECENT_LOCATION_START);
export const getRecentLocationSuccess = createAction(actionTypes.GET_RECENT_LOCATION_SUCCESS);
export const getRecentLocationFail = createAction(actionTypes.GET_RECENT_LOCATION_FAIL);

export const getPublicStaysStart = createAction(actionTypes.GET_PUBLIC_STAYS_START);
export const getPublicStaysSuccess = createAction(actionTypes.GET_PUBLIC_STAYS_SUCCESS);
export const getPublicStaysFail = createAction(actionTypes.GET_PUBLIC_STAYS_FAIL);

export const setTripsFormTouched = createAction(actionTypes.SET_TRIPS_FORM_TOUCHED);
export const setTripFinishedActionStatus = createAction(actionTypes.SET_TRIPS_FINISHED_STATUS);
export const setTripsActionStatus = createAction(actionTypes.SET_TRIPS_ACTION_STATUS);

export const openCreationModal = createAction(actionTypes.OPEN_CREATION_MODAL);
export const closeCreationModal = createAction(actionTypes.CLOSE_CREATION_MODAL);