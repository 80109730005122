import styled from "styled-components";
import { colors } from "config/theme/colors";
import { INPUT_BORDER_COLOR, INPUT_HEIGHT } from "../config";

export const Wrap = styled.div`
  position: relative;
  opacity: ${p => (p.disabled ? 0.4 : 1)};
`;

export const getSelectStyles = error => ({
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  control: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: "transparent",
    borderRadius: 8,
    borderColor: error ? colors.error : isFocused ? colors.brand : INPUT_BORDER_COLOR,
    minHeight: INPUT_HEIGHT.desktop + "px",
    boxShadow: `0 0 0 1px ${isFocused ? colors.brand : "#fff"}`,
    padding: "0 0 0 7px",

    "&:hover": {
      borderColor: colors.brand,
      //   boxShadow: `0 0 0 1px ${colors.brand}`,
    },
    // "@media only screen and (max-width: 600px)": {
    //   // height: INPUT_HEIGHT.mobile + "px",
    //   minHeight: "auto",
    // },
  }),
  input: styles => ({ ...styles, borderRadius: 8, fontFamily: "Montserrat" }),
  menu: styles => ({ ...styles, zIndex: 999999 }),
  dropdownIndicator: styles => ({
    ...styles,
    // padding: "8px 0",
    marginRight: 2,
    color: error ? colors.error : INPUT_BORDER_COLOR,
    cursor: "pointer",
    width: 40,
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    transition: "all .2s ease",
    borderRadius: 100,
    "&:hover": {
      color: INPUT_BORDER_COLOR,
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  }),
  indicatorSeparator: styles => ({ ...styles, display: "none" }),
  placeholder: () => ({ display: "none" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isSelected ? "#E0E0E0" : "#fff",
    color: "#000",
    "&:hover": {
      backgroundColor: isSelected ? "#E0E0E0" : "#F2F2F2",
    },
  }),
  multiValue: styles => ({
    ...styles,
    borderRadius: 50,
    backgroundColor: colors.brand,
    padding: "2px 5px",
    color: "#fff",
  }),
  multiValueLabel: styles => ({
    ...styles,
    color: "#fff",
    fontWeight: 600,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    borderRadius: 50,
    backgroundColor: "#fff",
    color: colors.brand,
    width: 18,
    height: 18,
    marginTop: 2,
    marginLeft: 5,
    cursor: "pointer",
  }),
  valueContainer: styles => ({
    ...styles,
    // marginTop: 4,
    "@media only screen and (max-width: 900px)": {
      marginTop: 0,
    },
  }),
});
