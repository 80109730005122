import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";

import { SwitchAction } from "components/ui/Tables/TableRowActions";

import { toggleConnectStayStart } from "store/adminConnect/actions";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SwitchWrapper = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colors.grey10};
  background-color: ${({ theme }) => theme.colors.withOpacity(theme.colors.grey10, 0.3)};
`;

const TableRowActions = ({ item }) => {
  const reduxDispatch = useDispatch();
  const permissionsService = usePermissionsService();

  const { isListed, id } = item;

  const toggleActiveStatus = (id, value) => {
    reduxDispatch(toggleConnectStayStart({ id, isListed: value }));
  };

  const isStaysConnectDisabled = !permissionsService.can(
    PERMISSIONS.actions.update,
    PERMISSIONS.sections.admin,
    PERMISSIONS.resources.stays,
  );

  return (
    <Wrapper>
      <SwitchWrapper>
        <SwitchAction checked={isListed} clickAction={() => toggleActiveStatus(id, !isListed)} disabled={isStaysConnectDisabled} />
      </SwitchWrapper>
    </Wrapper>
  );
};

TableRowActions.propTypes = {
  item: PropTypes.shape({
    isListed: PropTypes.bool,
    id: PropTypes.number,
  }).isRequired,
};

export { TableRowActions };
