import React from "react";
import { TimePicker, Checkbox, Input, DateTimePicker } from "components/ui/Forms";

export const inputs = [
  {
    key: 2,
    label: "Departure airport (IATA/ICAO)",
    name: "departure_airport_code",
    rules: { required: { value: true, message: "This field is required" } },
    type: "text",
  },
  {
    key: 3,
    label: "Departure Terminal",
    name: "departure_terminal",
    type: "text",
  },
  {
    key: 4,
    label: "Departure time",
    name: "departure_time",
    rules: { required: { value: true, message: "This field is required" } },
    type: "text",
    element: <TimePicker size="large" />,
  },
  {
    key: 5,
    label: "Arrival airport (IATA/ICAO)",
    name: "arrival_airport_code",
    rules: { required: { value: true, message: "This field is required" } },
    type: "text",
  },
  {
    key: 6,
    label: "Arrival Terminal",
    name: "arrival_terminal",
    type: "text",
  },
  {
    key: 7,
    label: "Arrival At",
    name: "arrival_at",
    type: "text",
    rules: { required: { value: true, message: "This field is required" } },
    element: <TimePicker size="large" />,
  },
  // {
  //   key: 8,
  //   element: <Checkbox />,
  //   label: "Adjust airport local times",
  //   name: "adjust_airport_local_times",
  // },
];
