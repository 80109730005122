import React from "react";
import PropTypes from "prop-types";

import Filter from "./index";
import BaseModal from "../../../../../components/_new/ModalBase";

const MobileFilterModal = ({ handleChangeParams, openFilterModal, setOpenFilterModal, handleChangePage, page, type, form, filterCount, setFilterCount, isLoading }) => {

  return (
    <BaseModal
      title="Filter"
      hideButtons
      onCancel={() => setOpenFilterModal(false)}
      modalStyle={{ overflow: "auto" }}
      contentStyle={{ overflow: "visible" }}
    >
      <Filter
        handleChangeParams={handleChangeParams}
        openFilterModal={openFilterModal}
        setOpenFilterModal={setOpenFilterModal}
        handleChangePage={handleChangePage}
        page={page}
        type={type}
        form={form}
        filterCount={filterCount}
        setFilterCount={setFilterCount}
        isLoading={isLoading}
      />
    </BaseModal>
  )
};

MobileFilterModal.propTypes = {
  handleChangeParams: PropTypes.func.isRequired,
  openFilterModal: PropTypes.bool.isRequired,
  setOpenFilterModal: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  form: PropTypes.shape({}).isRequired,
  filterCount: PropTypes.number.isRequired,
  setFilterCount: PropTypes.func.isRequired,
};

export default MobileFilterModal;
