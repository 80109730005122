import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import config from "config/app";
import { Wrap, IframeWrap } from "./style";

const ItineraryPreviewModal = ({ userId, passcode, onClose }) => {
  const webURL = `${config.webVamoos[config.webVamoosEnv]}`;

  return (
    <Wrap>
      <CloseIcon style={{ position: "absolute", top: 15, right: 15, cursor: "pointer" }} onClick={onClose} />
      <IframeWrap>
        <iframe
          id="inlineFrameExample"
          title="Inline Frame Example"
          src={`${webURL}/quickstart?operatorCode=${userId}&passcode=${passcode}`}
        />
      </IframeWrap>
    </Wrap>
  );
};

export default ItineraryPreviewModal;
