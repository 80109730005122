import React, { memo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { TitleText3 } from "components/ui/Typography/Typography";

import { toggleFeatureActiveStatusStart } from "store/adminFeatures/actions";

import { FeatureRow } from "./FeatureRow";

const StyledWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey10};
  margin-bottom: ${({ theme }) => theme.setSpacing(3)}px;
  &:last-of-type {
    border-bottom: none;
  }
`;

const TitleWrapper = styled.div`
  width: 160px;
  margin-right: ${({ theme }) => theme.setSpacing(6)}px;
  margin-top: 10px;
`;

const FeaturesWrapper = styled.div`
  flex-grow: 1;
`;

const FeatureSection = memo(({ feature, isEditPermitted }) => {
  const { features, name } = feature;

  const dispatch = useDispatch();

  const handleToggleSwitch = featureSelected => {
    const newFeatures = {};
    Object.keys(features || []).forEach(key => {
      newFeatures[features[key].id] =
        features[key].id === featureSelected.id ? { ...features[key], isActive: !features[key].isActive } : features[key];
    });
    const updatedItem = {
      ...feature,
      features: newFeatures,
    };

    dispatch(toggleFeatureActiveStatusStart(updatedItem));
  };

  return (
    <StyledWrapper>
      <TitleWrapper>
        <TitleText3 cv="grey50">{name}</TitleText3>
      </TitleWrapper>
      <FeaturesWrapper>
        {Object.keys(features || [])?.map(key => (
          <FeatureRow
            key={`feature-${features[key].id}`}
            feature={features[key]}
            onSwitch={() => handleToggleSwitch(features[key])}
            disabled={!isEditPermitted}
          />
        ))}
      </FeaturesWrapper>
    </StyledWrapper>
  );
});

FeatureSection.propTypes = {
  feature: PropTypes.shape({
    options: PropTypes.arrayOf(PropTypes.string),
    features: PropTypes.shape({}),
    name: PropTypes.string,
    isEditPermitted: PropTypes.bool,
  }).isRequired,
};

export { FeatureSection };
