import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";

import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";

import { Logotype } from "components/templates/_shared/Logotype/Logotype";

import { UserMenu } from "components/templates/Panel/UserMenu";
import { MainNavigation } from "components/templates/Admin/MainNavigation";
import { TopBar } from "components/templates/_shared/TopBar/TopBar";
import { OperatorSelector } from "components/templates/_shared/OperatorSelector/OperatorSelector";
import { ToolbarSection, MainNavigationArea, ActionsArea } from "components/templates/_shared/styledComponents";

import { ADMIN_OPERATORS_PATH } from "constants/routes";
import { OPERATOR_TYPE } from "constants/content";

const PanelTopBar = () => {
  const operators = useSelector(state => state.auth.operators);
  const currentOperatorCode = useSelector(state => state.auth.currentOperatorCode);

  const selectedOperator = operators?.find(({ code }) => code === currentOperatorCode);
  const isOperatorActive = selectedOperator?.isActive;
  const type = selectedOperator?.type;
  const isOperatorAdmin = type === OPERATOR_TYPE.admin;

  const [openUserMenu, setOpenUserMenu] = useState(false);
  const userMenuRef = useRef(null);

  const handleToggleUserMenu = () => {
    setOpenUserMenu(!openUserMenu);
  };

  const handleCloseUserMenu = event => {
    const { current } = userMenuRef;
    if (current && current.contains(event.target)) {
      return;
    }
    setOpenUserMenu(false);
  };

  return (
    <TopBar>
      <Logotype redirectUrl={ADMIN_OPERATORS_PATH} />

      <MainNavigationArea>{isOperatorActive && isOperatorAdmin && <MainNavigation />}</MainNavigationArea>

      <ActionsArea>
        <OperatorSelector />
        <ToolbarSection marginRight="3">
          <IconButton
            ref={userMenuRef}
            edge="end"
            aria-label="account of current user"
            aria-controls=""
            aria-haspopup="true"
            color="inherit"
            onClick={handleToggleUserMenu}
          >
            <AccountCircle />
          </IconButton>
          <UserMenu open={openUserMenu} anchor={userMenuRef} onClose={handleCloseUserMenu} />
        </ToolbarSection>
      </ActionsArea>
    </TopBar>
  );
};

export { PanelTopBar };
