import localforage from "localforage";

export class StorageService {
  async getPropertyByName(key) {
    return localforage.getItem(key);
  }

  async setPropertyByName(key, value) {
    return localforage.setItem(key, value);
  }

  async removePropertyByName(key) {
    return localforage.removeItem(key);
  }
}
