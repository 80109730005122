// @ts-check
import { FeatureRequestDto } from "./FeatureRequestDto";

/**
 * @param {FeatureStay} feature  feature stay object
 * @returns {FeatureStayRequestDto} Feature stay item
 */

export function FeatureStayRequestDto(feature) {
  /**
   * @type {FeatureStayRequestDto}
   */
  const dto = {};
  if (!feature?.isNewItem) dto.id = feature.id;

  dto.name = feature.name;
  dto.is_active = feature.isActive;
  dto.position = feature.position;
  dto.features = Object.values(feature?.features)
    .sort((a, b) => a.position - b.position)
    .map(innerFeature => FeatureRequestDto(innerFeature));

  return dto;
}
