import React, { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import { ContextBarTitle } from "components/templates/Panel/ContextBarTitle";
import { PanelTemplate } from "components/templates/Panel/PanelTemplate";
import { BackHistoryButton, CancelHistoryButton, PrimaryButton } from "components/ui/Buttons";
import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";

import { useExtendedNavigate } from "hooks/useExtendedNavigate";
import { usePayloadValidator } from "hooks/usePayloadValidator";
import { useTitle } from "hooks/useTitle";

import { createTripHandler } from "feature/panel/Trips/_shared/helpers";
import { ManageTripContext } from "feature/panel/Trips/_shared/ManageTripContext";
import { tripInitialState } from "feature/panel/Trips/_shared/tripInitialState";
import { tripStateReducer } from "feature/panel/Trips/_shared/tripStateReducer";

import { changeOperatorStart, clearActionFlags, pushSuccessNotification, tryRouteChangeStart } from "store/app/actions";
import { getCurrentOperatorStart } from "store/operator/actions";
import { CREATE_OR_UPDATE_TRIP_START, CREATE_OR_UPDATE_TRIP_SUCCESS } from "store/trips/actionTypes";

import {
  PANEL_TRIPS_CREATE_CONTENT_PATH,
  PANEL_TRIPS_CREATE_PATH,
  PANEL_TRIPS_EDIT_CONTENT_PATH,
  PANEL_TRIPS_EDIT_PATH,
  PANEL_TRIPS_ROOT_PATH,
} from "constants/routes";

import { usePrompt } from "components/ui/CustomPrompt/CustomPrompt";
import { useSavingCoverContext } from "components/ui/SavingCover/SavingCoverContext";

import { useForm } from "react-hook-form";
import { NotificationsService } from "services/NotificationsService";
import { getAllNotificationsSuccess } from "store/notifications/actions";

import { HistoryOutlined } from "@material-ui/icons";
import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { GLOBAL_CONTENT, NOTIFICATION_TYPES, PAGE_TITLE_LABEL, SITE_LEAVE_WARNING_MESSAGES, TRIP_WIZARD_CONTENT } from "constants/content";
import { PERMISSIONS } from "constants/permissions";
import { transformNotificationTemplateData, Trip } from "domain/Trip";
import { usePermissionsService } from "hooks/usePermissionsService";
import useResourceLock, { generateLockId } from "hooks/useResourceLock";
import { ListIndexService } from "services/ListIndexService";
import { StorageService } from "services/StorageService";
import { TripService } from "services/TripService";
import { setTripFinishedActionStatus, setTripsActionStatus, setTripsFormTouched } from "store/trips/actions";
import { getListOfPoisObjects } from "utils/dataConverters";
import { createInspirationNotification } from "utils/notifications";
import { setUrl } from "utils/url";
import { populateNullableNotifications } from "../../Itinerary/pages/Trips/Notifications/helpers";
import { CopyingTripModal } from "../Index/CopyingTripModal/CopyingTripModal";
import { shouldRedirect } from "./helpers";
import { TripWizardActions } from "./TripWizardActions/TripWizardActions";
import { TripWizardNavigation } from "./TripWizardNavigation/TripWizardNavigation";

const noMarginPages = ["storyboard"];
let currentImsertWorker;

const TripWizard = () => {
  const listIndexService = new ListIndexService(new StorageService());

  /** @type {NotificationsService} */
  const notificationService = new NotificationsService();

  const form = useForm();

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const extendedNavigate = useExtendedNavigate();
  const dispatch = useDispatch();

  const params = useParams();
  const savingCover = useSavingCoverContext();

  const { reference_code, operator_code } = params;
  const content = params["*"]
    .replace("/edit", "/")
    .split("/")
    .pop();
  const isEditMode = !!params.operator_code && !!params.reference_code;

  const pageTitle = isEditMode
    ? PAGE_TITLE_LABEL.editTrip({ operatorCode: params.operator_code, referenceCode: params.reference_code })
    : PAGE_TITLE_LABEL.createTrip;

  useTitle(pageTitle);

  const [copyData, setCopyData] = useState({ modalOpen: false, body: null });
  const [tripLoaded, setTripLoaded] = useState(false);
  const [originalTrip, setOriginalTrip] = useState(false);
  const [forceUpdateOpen, setForceUpdateOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [formState, dispatchLocal] = useReducer(tripStateReducer, tripInitialState);
  const [shouldDisableSaveButton, setSaveButtonState] = useState(false);
  const [lockOwner, setLockOwner] = useState(null);
  const [lockMeta, setLockMeta] = useState(null);
  const [isIdled, setIsIdled] = useState(false);

  const isNoMargin = noMarginPages.includes(pathname.split("/")[pathname.split("/")?.length - 1]);

  const permissionsService = usePermissionsService();

  const { checkValidation } = usePayloadValidator("trips");

  const { passcode, userId, vamoos_id, is_wiped, is_active } = formState;

  const { errors, inProgress, actionType, finished } = useSelector(state => state.trips);
  const { currentOperatorCode, operators, user } = useSelector(state => state.auth);
  const isTripFormTouched = useSelector(state => state.trips.isTripFormTouched === true);

  const defaultLanguageCode = useSelector(({ operator }) => operator.currentOperator.defaultLanguageCode);
  const imsertApiKey = useSelector(({ operator }) => operator.currentOperator?.integrations?.imsert?.api_key);

  const tripLanguage = formState?.language || defaultLanguageCode;
  const shouldShowSpinnerWhenUpdate = (inProgress && actionType === CREATE_OR_UPDATE_TRIP_START) || isSaving;
  const shouldGetTripAfterUpdate = finished && CREATE_OR_UPDATE_TRIP_SUCCESS === actionType;

  const isNotificationsDirty = () => {
    const dirtyField = form.formState.dirtyFields?.notifications?.find(item => {
      return Object.entries(item).some(([key, value]) => key !== "trip_notif" && value);
    });

    return !!dirtyField;
  };

  usePrompt(SITE_LEAVE_WARNING_MESSAGES.createTitle(SITE_LEAVE_WARNING_MESSAGES.contexts.vamoosEditor), (isTripFormTouched || isNotificationsDirty()), [
    PANEL_TRIPS_CREATE_PATH,
    PANEL_TRIPS_CREATE_CONTENT_PATH,
    PANEL_TRIPS_EDIT_PATH,
    PANEL_TRIPS_EDIT_CONTENT_PATH,
  ]);

  const canEditItinerary = permissionsService.can(
    PERMISSIONS.actions.update,
    PERMISSIONS.sections.vamoosList,
    isEditMode ? vamoos_id : null,
  );

  const canReadCurrentItinerary = isEditMode
    ? !tripLoaded ||
      !vamoos_id ||
      (tripLoaded && permissionsService.can(PERMISSIONS.actions.read, PERMISSIONS.sections.vamoosList, vamoos_id))
    : true;

  const canCreateItinerary = permissionsService.can(
    PERMISSIONS.actions.create,
    PERMISSIONS.sections.vamoosList,
    PERMISSIONS.resources.default,
  );

  const handleSaveTrip = async () => {
    setIsSaving(true);

    const storyboard = formState.storyboard.map(item => {
      const isHash = item.headline?.charAt(0) === "#";

      item.meta = { ...item.meta, day_number: Math.floor(item.meta.day_number), hide_day_info: isHash };
      item.headline = isHash ? item.headline.substring(1) : item.headline;
      item.content_type = "text/html";

      return item;
    });

    const formData = {
      ...formState,
      passcode: formState.passcode.trim(),
      userId: formState.userId.trim(),
      storyboard,
    };

    // const notifications = form.watch("notifications");
    const pois = await getListOfPoisObjects(formData.pois, formData.locations);
    const payload = {
      ...formData,
      pois,
    };

    await createTripHandler(payload, checkValidation, dispatch, isEditMode ? "update" : "create");

    if (currentImsertWorker) {
      currentImsertWorker.terminate();
    }

    // @ts-ignore
    currentImsertWorker = new Worker(new URL("../../../../utils/imsert.worker.js", import.meta.url));
    currentImsertWorker.postMessage({
      apiKey: imsertApiKey,
      originalTrip: originalTrip ? {
        id: originalTrip.id,
        storyboard: originalTrip.storyboard,
        locations: originalTrip.locations,
      } : null,
      newTrip: {
        storyboard: payload.storyboard?.map(s => {
          const tempDivElement = document.createElement("div");
          tempDivElement.innerHTML = s.content;
          const plainContentText = tempDivElement.textContent || tempDivElement.innerText || "";

          return {
            ...s,
            plainText: plainContentText,
          };
        }),
        locations: payload.locations,
      },
    });

    setOriginalTrip(payload);
    // setLock({ id: formState.vamoos_id, userId }, dispatch);
    setIsSaving(false);
  };

  const setValueFor = (fieldName, value, formTouchedValue = true) => {
    dispatchLocal({
      type: "setValueFor",
      fieldName,
      value,
    });

    if (!isTripFormTouched) {
      dispatch(setTripsFormTouched(formTouchedValue));
    }
  };

  const init = () => {
    dispatch(clearActionFlags("trips"));
    dispatch(getCurrentOperatorStart());
    listIndexService.clearOriginalIndexes();
    dispatch(setTripsFormTouched(false));
  };

  const clearFormTouched = (needToClearForm) => {
    if (actionType === CREATE_OR_UPDATE_TRIP_SUCCESS || needToClearForm) {
      dispatch(setTripsFormTouched(false));
    }
  };

  const tryRedirectAfterPasscodeChange = () => {
    if (shouldRedirect(actionType, passcode, reference_code, userId, operator_code) && !isTripFormTouched) {
      const url = setUrl(
        PANEL_TRIPS_EDIT_CONTENT_PATH,
        { operator_code: formState.userId, reference_code: formState.passcode, content: content === "create" ? "" : content },
        true,
      );
      navigate(url);
    }
  };

  const getNotificationTemplates = async (language, isDefault) => {
    const templates = await notificationService.getAllNotifications();
    const transformedTemplates = transformNotificationTemplateData(templates, language, true);
    dispatch(getAllNotificationsSuccess(transformedTemplates));

    if (!transformedTemplates.some(notification => notification.type === NOTIFICATION_TYPES.inspiration)) {
      transformedTemplates.push(createInspirationNotification(formState.inspiration ? formState.inspiration.vamoos_id : ""));
    }

    if (isDefault) {
      await setValueFor("notifications", transformedTemplates);
      clearFormTouched(true);
    }

    return transformedTemplates;
  };

  const getDefaultTrip = async () => {
    await getNotificationTemplates(defaultLanguageCode, true);
    setTripLoaded(true);
  };

  const getCurrentOperator = () => {
    dispatch(getCurrentOperatorStart());
  };

  const getTripDetails = async () => {
    const tripService = new TripService();

    if (reference_code && operator_code) {
      try {
        const data = await tripService.getTrip(operator_code, reference_code);
        const language = data.language || defaultLanguageCode;
        const payload = { ...Trip(data), currentPasscode: reference_code, language };

        const templates = await getNotificationTemplates(language, false);
        payload.notifications = populateNullableNotifications(templates, payload?.notifications, formState?.language || defaultLanguageCode);
        const templatesToInclude = templates.filter(template => !payload.notifications.some(notification => notification.template_id === template.id));
        payload.notifications = [...payload.notifications, ...templatesToInclude];

        dispatchLocal({ type: "setAllValues", payload });
        dispatch(setTripsFormTouched(false));
        dispatch(setTripFinishedActionStatus(false));
        dispatch(setTripsActionStatus(null));

        form.reset(payload);
        setOriginalTrip(payload);
        setTripLoaded(true);
        return payload;
      } catch (e) {
        dispatchLocal({ type: "setAllValues", payload: { ...tripInitialState, language: defaultLanguageCode } });
        setTripLoaded(true);
      }
    }
  };

  const { requestLock, takeoverLock, isConnected } = useResourceLock({
    lockId: generateLockId("trip", vamoos_id),
    onLockStatusChanged: async payload => {
      setLockMeta(payload.meta_data);
      setLockOwner(payload.own_lock);
      if (payload.meta_data.isTakeover) {
        await getTripDetails();
      }
    },
  });

  useEffect(() => {
    if (vamoos_id && user && isConnected) {
      requestLock({
        owner_name: user.username,
        owner_id: user.id,
      });
    }
  }, [vamoos_id, user, isConnected]);

  const handleCancelButton = () => {
    dispatch(tryRouteChangeStart(PANEL_TRIPS_ROOT_PATH));
    extendedNavigate(PANEL_TRIPS_ROOT_PATH);
  };

  const handleForceUpdate = async notification_text => {
    const tripService = new TripService();

    try {
      await tripService.sendUpdate(formState.userId, formState.passcode, notification_text);
      dispatch(pushSuccessNotification(TRIP_WIZARD_CONTENT.notifications.forceUpdate));
    } catch (e) {
      dispatch(pushSuccessNotification(TRIP_WIZARD_CONTENT.notifications.forceUpdateError));
    }

    setForceUpdateOpen(false);
  };

  const handleEraseTripToggle = () => {
    createTripHandler({ ...originalTrip, is_active: !formState.is_active }, () => true, dispatch);
    setValueFor("is_active", !formState.is_active, false);
  };

  const triggerCopyTrip = () => {
    const { modalOpen } = copyData;
    const body = modalOpen
      ? copyData.body
      : {
          field1: originalTrip.field1,
          operator_code: originalTrip.userId,
          reference_code: originalTrip.passcode,
        };
    setCopyData({ body, modalOpen: !modalOpen });
  };

  const closeCopyTripModal = () => setCopyData({ ...copyData, modalOpen: false });

  const getTripAfterUpdate = () => {
    /**
     * @todo: condition related to "finished" variable is important until app uses global redux actions CLEAR_ACTION_FLAGS
     */

    if (tripLoaded && shouldGetTripAfterUpdate) {
      getTripDetails();
    }
  };

  useEffect(closeCopyTripModal, [params.reference_code, params.operator_code]);
  useEffect(init, []);
  useEffect(clearFormTouched, [actionType]);
  useEffect(tryRedirectAfterPasscodeChange, [actionType, isTripFormTouched]);
  useEffect(getTripAfterUpdate, [shouldGetTripAfterUpdate]);
  useEffect(getCurrentOperator, []);

  useEffect(() => {
    const tripOperator = operators?.find(operator => {
      const usedCodes = operator.usedCodes.map(code => code.toLowerCase());

      return usedCodes.includes(operator_code?.toLowerCase());
    });

    if (!formState?.vamoos_id && tripOperator && tripOperator.code !== currentOperatorCode) {
      dispatch(changeOperatorStart({ code: tripOperator?.code, isSelectedOperatorActive: tripOperator?.isActive }));
    } else if (params.operator_code && params.reference_code) {
      getTripDetails();
    } else {
      getDefaultTrip();
    }
  }, [params.reference_code, params.operator_code, currentOperatorCode]);

  useEffect(() => {
    if (shouldShowSpinnerWhenUpdate) {
      savingCover.show();
    } else {
      savingCover.hide();
    }
  }, [shouldShowSpinnerWhenUpdate]);

  useEffect(() => {
    setValueFor("language", formState.language || defaultLanguageCode, false);
  }, [defaultLanguageCode, formState?.vamoos_id]);

  const contextbar = {
    left: () =>
      (isTripFormTouched || isNotificationsDirty()) ? (
        <CancelHistoryButton role="link" clickHandler={handleCancelButton} />
      ) : (
        <BackHistoryButton role="link" clickHandler={handleCancelButton} />
      ),
    middle: () => <ContextBarTitle title={pageTitle} />,
    right: () => {
      if (is_wiped) {
        return null;
      }
      if (tripLoaded && !is_active) {
        return canEditItinerary ? (
          <PrimaryButton onClick={handleEraseTripToggle}>
            <HistoryOutlined />
            <span>{GLOBAL_CONTENT.restore}</span>
          </PrimaryButton>
        ) : null;
      }
      return (
        <TripWizardActions
          onSave={handleSaveTrip}
          showSpinner={shouldShowSpinnerWhenUpdate}
          disabled={
            !passcode ||
            !!errors.passcode ||
            !userId ||
            (!isTripFormTouched && !isNotificationsDirty()) ||
            isSaving ||
            shouldShowSpinnerWhenUpdate
          }
          isEditMode={isEditMode}
          vamoosId={formState?.vamoos_id}
          triggerCopyVamoos={triggerCopyTrip}
          onForceUpdate={handleForceUpdate}
          forceUpdateDisabled={isTripFormTouched || isNotificationsDirty()}
          forceUpdateOpen={forceUpdateOpen}
          setForceUpdateOpen={setForceUpdateOpen}
          tripLanguage={tripLanguage}
        />
      );
    },
  };

  const context = {
    ...formState,
    setValueFor,
    editMode: isEditMode,
    errors,
    shouldDisableSaveButton,
    setSaveButtonState,
    form,
  };

  const onCancel = () => {
    if (lockMeta) {
      navigate("/panel/trips");
    }
  };

  const handleTakeover = () =>
    takeoverLock({
      owner_name: user.username,
      owner_id: user.id,
      isTakeover: true,
    });

  return (
    <PanelTemplate
      hasPermission={isEditMode ? canReadCurrentItinerary : canCreateItinerary}
      contextBar={contextbar}
      hasStickyPanel={isNoMargin}
      navigation={
        <TripWizardNavigation
          isEditMode={isEditMode}
          onEraseConfirm={handleEraseTripToggle}
          isTripActive={formState.is_active}
          isTripDeleted={formState.is_wiped}
          vamoosId={formState.vamoos_id}
        />
      }
    >
      <ManageTripContext.Provider value={context} form={form}>
        <ConfirmationModal
          onConfirm={handleTakeover}
          confirmLabel="Force take over"
          cancelLabel="Go back"
          onCancel={onCancel}
          title={`Trip is currently being edited by ${lockMeta?.owner_name}`}
          open={!lockOwner && lockMeta?.owner_id}
        />
        {!isEditMode || (isEditMode && tripLoaded) ? <Outlet /> : <LoadingScreen />}
      </ManageTripContext.Provider>

      <CopyingTripModal
        open={copyData.modalOpen}
        onCancel={triggerCopyTrip}
        item={copyData.body || {}}
        hasUnsavedChanges={isTripFormTouched || isNotificationsDirty()}
        onSave={handleSaveTrip}
        showSpinner={shouldShowSpinnerWhenUpdate}
      />
    </PanelTemplate>
  );
};

TripWizard.defaultProps = {
  hasPermission: true,
};

export { TripWizard };
