import React from "react";
import PropTypes from "prop-types";

import { EditAction, DeleteAction, TableActionsContainer } from "components/ui/Tables/TableRowActions";
import { TABLE_ACTIONS_TITLES } from "constants/content";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

const { folder, file, link } = TABLE_ACTIONS_TITLES.resourceTypes;

const LibraryTableActions = ({ onClickAction, item }) => {
  const permissionsService = usePermissionsService();

  const handleEdit = () => onClickAction("edit", item);
  const handleDelete = () => onClickAction("delete", item);
  const resourceType = (() => {
    if (item.remote_url) return link;
    if (item.is_folder) return folder;
    return file;
  })();

  return (
    <TableActionsContainer>
      {permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.operator, PERMISSIONS.resources.library) && (
        <EditAction
          clickAction={handleEdit} 
          tooltip={TABLE_ACTIONS_TITLES.editResource(TABLE_ACTIONS_TITLES.resourceTypes[resourceType])} 
          data-testid={`library-edit-${resourceType}`}
        />
      )}
      {permissionsService.can(PERMISSIONS.actions.delete, PERMISSIONS.sections.operator, PERMISSIONS.resources.library) && (
        <DeleteAction
          clickAction={handleDelete}
          tooltip={TABLE_ACTIONS_TITLES.deleteResource(TABLE_ACTIONS_TITLES.resourceTypes[resourceType])}
          data-testid={`library-delete-${resourceType}`}
        />
      )}
    </TableActionsContainer>
  );
};

LibraryTableActions.propTypes = {
  onClickAction: PropTypes.func.isRequired,
  item: PropTypes.shape().isRequired,
};

export { LibraryTableActions };
