import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import theme from "config/theme";
import { delay } from "lodash";
import IconButton from "@material-ui/core/IconButton";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { ClickAwayListener, Chip, Paper } from "@material-ui/core";

import { DEFAULT_DEBOUNCE_DELAY } from "constants/defaults";

import { Input } from "components/ui/Forms/Inputs";
import { BodyText2 } from "components/ui/Typography/Typography";

const AutoCompleteWrapper = styled.div`
  position: relative;

  .MuiOutlinedInput-adornedStart {
    padding-left: 0;
  }

  .MuiInputBase-root {
    ${({ isAutocompleteDisabled }) => (isAutocompleteDisabled ? "" : "background: white;")}
  }

  &&&& {
    label {
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 80%;
      overflow: hidden;
    }
  }
`;

const ResultsWrapper = styled(Paper)`
  && {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    max-height: 300px;
    overflow: auto;
    transform: translateY(${({ translatedup }) => (translatedup ? `calc(100% - ${theme.setSpacing(6)}px)` : "100%")});
  }
`;

const OptionWrapper = styled.button`
  width: 100%;
  padding: ${theme.setSpacing(3)}px;
  transition: background-color 0.3s ease;
  cursor: pointer;
  background: none;
  border: none;
  text-align: left;
  ${({ brandedBg }) => (brandedBg ? `background: ${theme.colors.withOpacity(theme.colors.brand, 0.2)};` : "")}

  &:hover,
  &:focus {
    background: ${theme.colors.grey10};
    outline: none;
  }
`;

const StyledChip = styled(Chip)`
  && {
    margin: 0 2px;
    background: ${theme.colors.brand};
    color: ${theme.colors.white};
    width: ${({ isOrphan }) => (isOrphan ? `calc(100% - ${theme.setSpacing(6)}px)` : "auto")};
    flex-basis: ${({ isOrphan }) => (isOrphan ? `calc(100% - ${theme.setSpacing(6)}px)` : "0")};
    max-width: calc(100% - ${theme.setSpacing(6)}px);

    .MuiChip-deleteIcon {
      color: ${theme.colors.white};
      margin-left: auto;
    }

    &:hover,
    &:focus {
      background: ${theme.colors.brand} !important;
    }

    &:first-of-type {
      margin-left: ${theme.setSpacing(3)}px;
    }
  }
`;

const StyledInput = styled(({ shrinkInput, ...rest }) => <Input {...rest} />)`
  && {
    cursor: ${({ shrinkInput }) => (shrinkInput ? "default" : "text")};
  }

  .MuiInputBase-input {
    display: ${({ shrinkInput }) => (shrinkInput ? "none" : "block")};
  }
`;

const TogglerWrapper = styled.div`
  margin: 0 0 0 auto;
`;

/**
 *
 * The component should not be extended since it's deprecated.
 *
 * @deprecated since Autocomplete created - use it instead of the component
 */
const UnsafeAutocomplete = forwardRef(
  (
    {
      onOptionClick,
      options,
      value,
      helperText,
      multiple,
      selectedList,
      onDelete,
      onBlur,
      onClick,
      onClickAway,
      maxValuesCount,
      disabled,
      disableOptionsOpenAfterInputClick,
      ...rest
    },
    ref,
  ) => {
    const [optionsOpen, setOptionsOpen] = useState(false);
    const [isFocus, setIsFocus] = useState(false);

    useImperativeHandle(ref, () => ({
      optionsClose: () => setOptionsOpen(false),
    }));

    useEffect(() => {
      if ((multiple && value.length === 0) || (!multiple && !value)) {
        setIsFocus(false);
      }
    }, [value]);

    useEffect(() => {
      if (!disabled && !optionsOpen && isFocus && value?.length > 0 && options.length > 0) {
        setOptionsOpen(true);
      }
    }, [value, options]);

    const handleOptionClick = option => {
      onOptionClick(multiple ? option : option.value);
      setOptionsOpen(false);
    };

    const optionItems = options.map(option => (
      <OptionWrapper onClick={() => handleOptionClick(option)} key={option.key} brandedBg={option.isStay}>
        <BodyText2>{option.label}</BodyText2>
      </OptionWrapper>
    ));

    const optionsComponent = optionsOpen && options.length > 0 && <ResultsWrapper translatedup={helperText}>{optionItems}</ResultsWrapper>;

    const handleToggleOptionsOpen = e => {
      e.stopPropagation();
      setOptionsOpen(!optionsOpen);
    };

    const handleBlur = () => {
      delay(onBlur, DEFAULT_DEBOUNCE_DELAY);
      setIsFocus(false);
    };

    const handleFocus = () => {
      setIsFocus(true);
    };

    const handleClick = e => {
      if ((maxValuesCount === false || (maxValuesCount !== false && selectedList.length < maxValuesCount)) && !disabled) {
        if (!disableOptionsOpenAfterInputClick) {
          setOptionsOpen(true);
        }
        setIsFocus(true);
        onClick(e);
      }
      e.stopPropagation();
    };

    const handleClickAway = () => {
      setOptionsOpen(false);
      if ((multiple && value.length === 0) || (!multiple && !value)) {
        setIsFocus(false);
      }
      onClickAway();
    };

    const Chips = () => (
      <>
        {selectedList.map(item => (
          <StyledChip
            onClick={e => e.stopPropagation()}
            isOrphan={maxValuesCount === 1}
            label={item.label}
            key={item.key}
            onDelete={() => {
              setIsFocus(false);
              onDelete(item);
            }}
            size="small"
          />
        ))}
      </>
    );

    const DropDownToggler = () => (
      <TogglerWrapper>
        <IconButton size="small" onClick={handleToggleOptionsOpen}>
          {optionsOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </IconButton>
      </TogglerWrapper>
    );

    const defaultProps = {
      value,
      helperText,
    };

    const multipleProps = {
      ...defaultProps,
      startAdornment: <Chips />,
      endAdornment: (maxValuesCount === false || (maxValuesCount !== false && selectedList.length < maxValuesCount)) && <DropDownToggler />,
    };

    const combineProps = () => (multiple ? multipleProps : defaultProps);
    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <AutoCompleteWrapper isAutocompleteDisabled={disabled}>
          {/* Remove browser autocompele dropdown only working way around */}
          <input type="hidden" value="sample" />
          <StyledInput
            autoComplete="new-password"
            labelShrink={isFocus || !!selectedList?.length || !!value?.length}
            {...rest}
            {...combineProps()}
            onBlur={handleBlur}
            onClick={handleClick}
            onFocus={handleFocus}
            shrinkInput={multiple && maxValuesCount !== false && selectedList.length === maxValuesCount}
            disabled={disabled}
          />
          {optionsComponent}
        </AutoCompleteWrapper>
      </ClickAwayListener>
    );
  },
);

UnsafeAutocomplete.defaultProps = {
  helperText: "",
  multiple: false,
  selectedList: [],
  onDelete: () => {},
  onBlur: () => {},
  onClick: () => {},
  onClickAway: () => {},
  maxValuesCount: false,
  disabled: false,
  disableOptionsOpenAfterInputClick: false,
};

UnsafeAutocomplete.propTypes = {
  onOptionClick: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ).isRequired,
  value: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  disableOptionsOpenAfterInputClick: PropTypes.bool,
  selectedList: PropTypes.arrayOf(PropTypes.object),
  onDelete: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  onClickAway: PropTypes.func,
  maxValuesCount: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

export { UnsafeAutocomplete };
