import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import CropOriginalOutlinedIcon from "@material-ui/icons/CropOriginalOutlined";
import ImageIcon from "@mui/icons-material/Image";
import { ImageBaseProps } from "components/ui/Images/ImageBaseProps";

const ImagePlaceholderArea = styled(({ hasError, ...rest }) => <ImageBaseProps {...rest} />)`
  && {
    border: 1px solid ${({ theme, hasError }) => theme.colors.withOpacity(theme.colors[hasError ? "error" : "grey50"], 0.2)};
    background-color: ${({ theme, hasError }) => theme.colors.withOpacity(theme.colors[hasError ? "error" : "grey10"], 0.2)};
    svg {
      color: ${({ theme, hasError }) => theme.colors.withOpacity(theme.colors[hasError ? "error" : "grey50"], 0.5)};
    }
  }
`;

const ImagePlaceholder = ({ iconSize, hasError, ...props }) => (
  <ImagePlaceholderArea hasError={hasError} {...props}>
    <ImageIcon style={{ fontSize: iconSize }} />
  </ImagePlaceholderArea>
);

ImagePlaceholder.defaultProps = {
  iconSize: 24,
  hasError: false,
};

ImagePlaceholder.propTypes = {
  iconSize: PropTypes.number,
  hasError: PropTypes.bool,
};

export { ImagePlaceholder };
