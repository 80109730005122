import React, { useState } from "react";
import PropTypes from "prop-types";
import { BodyText3 } from "components/ui/Typography/Typography";
import { ContentDivider } from "feature/panel/Stays/_shared/Publisher/styledComponents";
import { DirectoryRow, FieldWrapper } from "feature/panel/Stays/_shared/Publisher/DirectoryRow";
import { NoResultsMessage } from "components/ui/Messages/NoResultsMessage";
import { Checkbox } from "components/ui/Forms";
import { PUBLISHER_CONTENT } from "constants/content";
import { useDispatch, useSelector } from "react-redux";
import { setPublisherModalValue } from "store/stays/actions";

const defaultRowValues = { page_break_before: false, show_image: true, image_size: "small" };

const PublisherCreatePageTwo = () => {
  const dispatch = useDispatch();
  const directories = useSelector(({ stays }) => stays.publisherModal.form.directories);
  const listOfAllAvailableDirectories = useSelector(({ stayWizard }) => stayWizard.form.directories.items.filter(dir => !dir.parent_id));
  const [areAllSelected, setAreAllSelected] = useState(true);

  const handleAllSelectChange = ({ target }) => {
    setAreAllSelected(target.checked);

    if(target.checked) {
      const newList = listOfAllAvailableDirectories.map(availableDirectory => {
        const currentState = directories.find(dir => dir.id === availableDirectory.id);

        if(currentState) {
          return currentState;
        }

        return { section: availableDirectory.id, ...defaultRowValues }
      })

      dispatch(setPublisherModalValue({
        field: "directories",
        value: newList
      }));

    } else {
      dispatch(setPublisherModalValue({
        field: "directories",
        value: []
      }));
    }
  }

  const handleDirectoriesChange = (section, field, value) => {
    const newList = directories.map(dir => dir.section === section
      ? { ...dir, [field]: value }
      : dir
    );

    dispatch(setPublisherModalValue({
      field: "directories",
      value: newList
    }));
  };

  const handleActivateToggle = (tag) => {
    const isActive = directories.some(dir => dir.section === tag);
    const newList = isActive
      ? directories.filter(dir => dir.section !== tag)
      : [ ...directories, { section: tag, ...defaultRowValues } ];

    dispatch(setPublisherModalValue({
      field: "directories",
      value: newList
    }));

    if(newList.length !== listOfAllAvailableDirectories.length) {
      setAreAllSelected(false);
    }
  };

  const directoriesData = listOfAllAvailableDirectories.map(({ name, tag }) => {
    const active = directories.some(({ section }) => section === tag);
    const rowData = active ? directories.find(({ section }) => section === tag) : defaultRowValues;
    
    return ({
      name,
      tag,
      active,
      ...rowData
    })
  });

  const noDirectoriesMessage = !directoriesData.length && (
    <NoResultsMessage>
      {PUBLISHER_CONTENT.doesntHaveDirectories}
    </NoResultsMessage>
  );

  return (
    <>
      <ContentDivider>
        <BodyText3>{PUBLISHER_CONTENT.directories.directory}</BodyText3>
        <BodyText3>{PUBLISHER_CONTENT.directories.newPage}</BodyText3>
        <BodyText3>{PUBLISHER_CONTENT.directories.showImage}</BodyText3>
        <BodyText3>{PUBLISHER_CONTENT.directories.imageSize}</BodyText3>
      </ContentDivider>
      <ContentDivider>
        <FieldWrapper
          label={PUBLISHER_CONTENT.directories.selectAll}
          control={(
            <Checkbox
              checked={areAllSelected}
              onChange={handleAllSelectChange}
            />
          )}
        />
      </ContentDivider>
      {directoriesData.map(dir => (
        <DirectoryRow
          key={dir.id}
          directory={dir}
          onValueChange={(fieldName, value) => handleDirectoriesChange(dir.tag, fieldName, value)}
          onActivateToggle={() => handleActivateToggle(dir.tag)}
        />
      ))}
      {noDirectoriesMessage}
    </>
  );
};

PublisherCreatePageTwo.propTypes = {
  publisher: PropTypes.shape({
    directories: PropTypes.arrayOf(PropTypes.shape({})).isRequired
  }).isRequired,
};

export { PublisherCreatePageTwo };
