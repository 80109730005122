import React, { useState } from "react";
import Switch from "components/_new/Switch";
import angleIcon from "../../../../../../../../assets/icons/angle-right.svg";
import { SecondaryText, Title, Wrap } from "./style";
import SpecificSettingsPlaceholder from "../specificSettingsPlaceholder";
import { MobileItemContainer, MobileItemContent } from "../../../Notifications/MobileItem/style";
import { useActiveIcon } from "../../../../../../../../hooks/useActiveIcon";
import { ChipWithRedirect } from "../../../../../../../../components/ui/Chips/ChipWithRedirect";

const MobileRow = ({ item, clickedRow, onChange, onClick, isChecked, index, disabled }) => {
  const { name, country, longitude, latitude, description, is_default_on, icon, isOverriden, id, library_node } = item;
  const [isOpened, setIsOpened] = useState(false);

  const iconUrl = icon?.url;
  const chipFileIcon = useActiveIcon(library_node);

  const onRowClick = () => {
    if (isOverriden) onClick(id, true);
  };

  return (
    <MobileItemContainer isOverridden={isOverriden} clickedRow={clickedRow} templateId={id} id={`row-${id}${isOverriden ? "-template" : "-mob"}`}>
      <MobileItemContent isOverridden={isOverriden} onClick={onRowClick}>
      {iconUrl && (
        <div style={{ marginRight: 15, paddingTop: 2 }}>
          <img src={iconUrl} alt="icon" style={{ width: 18, height: 18 }} />
        </div>
      )}

      <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "start" }}>
        <div>
          <Title>{name}</Title>
          {isOverriden ? (
            <SpecificSettingsPlaceholder style={{ marginLeft: 0, marginTop: 5 }} />
          ) : (
            <>
              <SecondaryText>{country}</SecondaryText>
              <SecondaryText>
                {latitude}, {longitude}
              </SecondaryText>
              {isOpened && (
                <>
                  <div style={{ marginTop: 15 }}>{description}</div>
                  {
                    library_node &&
                    <div style={{ marginTop: 15 }}>
                      <ChipWithRedirect item={library_node} icon={chipFileIcon} editable={false} maxChar={15} />
                    </div>
                  }
                </>
              )
              }
            </>
          )}
        </div>
        <div style={{ display: isOverriden ? "none" : "flex", alignItems: "center", marginTop: "-6px"}}>
          <Switch checked={isChecked ? isChecked(item) : is_default_on} onChange={() => onChange(item, index)} disabled={disabled} />
            <div style={{ visibility: (description || !!library_node) ? "visible" : "hidden"}}>
              <img
                src={angleIcon}
                style={{ marginRight: 15, marginLeft: 15, transform: `rotate(${isOpened ? 90 : 0}deg)` }}
                alt="angle-icon"
                onClick={() => setIsOpened(!isOpened)}
              />
            </div>
        </div>
      </div>
      </MobileItemContent>
    </MobileItemContainer>
  );
};

export default MobileRow;
