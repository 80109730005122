import React from "react";
import { colors } from "config/theme/colors";
import { ErrorText } from "../../TextInput/style";

const InputErrorOrHint = ({ error, hint, currentLanguage = "en" }) => {
  const getErrorMessage = message => {
    if (typeof message === "object") {
      return message[currentLanguage];
    }

    return message;
  };
  const isError = error => {
    if (typeof error !== "object") {
      if (error) return true;
    }

    if (typeof error === "object") {
      if (error.message || error.ref) return true;
    }

    return false;
  };

  return (
    <>
      {!error && hint && <ErrorText style={{ color: colors.grey40 }}>{hint}</ErrorText>}
      {isError(error) && <ErrorText>{getErrorMessage(error.message) || "This field is required"}</ErrorText>}
    </>
  );
};

export default InputErrorOrHint;
