// @ts-check

import { VamoosImage } from "./VamoosImage";

/**
 *
 * @param {StayOverrideDto} stayOverrideDto
 * @returns {StayOverride}
 */

export function StayOverride(stayOverrideDto) {
  /**
   * @type {StayOverride}
   */
  const domain = {};

  domain.vamoosId = stayOverrideDto.vamoos_id;
  domain.operatorCode = stayOverrideDto.operator_code;
  domain.referenceCode = stayOverrideDto.reference_code;
  domain.latitude = stayOverrideDto.latitude;
  domain.longitude = stayOverrideDto.longitude;
  domain.countryIso = stayOverrideDto.country_iso;
  domain.shortDescription = stayOverrideDto.short_description;
  domain.longDescription = stayOverrideDto.long_description;
  domain.shortDescriptionOriginal = stayOverrideDto.short_description_original;
  domain.longDescriptionOriginal = stayOverrideDto.long_description_original;
  domain.shortDescriptionUser = stayOverrideDto.short_description_user;
  domain.longDescriptionUser = stayOverrideDto.long_description_user;
  domain.name = stayOverrideDto.name;

  if (stayOverrideDto?.background) {
    const splitedUrl = stayOverrideDto?.background.split("/");
    const file = {
      name: splitedUrl[splitedUrl.length - 1].split("?")[0],
      file_url: stayOverrideDto?.background,
      preview_url: stayOverrideDto?.background,
    };
    domain.background = VamoosImage(file, null);
  }

  return domain;
}
