import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import styled from "styled-components";
import { debounce } from "lodash";

import Grid from "@material-ui/core/Grid";

import { GET_ADMIN_USERS_URL } from "constants/api";
import {
  ADMIN_USERS_INDEX,
  CREATE_CONTENT_LABELS,
  EMPTY_LIST_MESSAGES_BASE,
  NAMES_OF_RESOURCES_LISTS,
  NO_RESULTS_FOUND_MESSAGE,
} from "constants/content";

import { AdminTemplate } from "components/templates/Admin/AdminTemplate";
import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";
import { NoResultsMessage } from "components/ui/Messages/NoResultsMessage";

import { useTableHandlers } from "hooks/useTableHandlers";

import { ManageAccountsSubMenu } from "feature/admin/_shared/ManageAccountsSubMenu";

import { SearchField } from "feature/panel/_shared/SearchField/SearchField";

import { getAdminUsersStart } from "store/adminUsers/actions";
import { GET_ADMIN_USERS_START } from "store/adminUsers/actionTypes";

import { checkQueryParamExistence } from "utils/url";

import { TableOfUsers } from "./TableOfUsers/TableOfUsers";
import { TableFilters } from "./TableOfUsers/TableFilters";

export const ShowArchivedContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${({ theme }) => theme.setSpacing(2)}px;
`;

const UsersIndex = () => {
  const isLoadingScreen = useSelector(({ adminUsers }) => {
    return adminUsers.inProgress && !adminUsers.finished && adminUsers.actionType === GET_ADMIN_USERS_START;
  });
  const usersCount = useSelector(state => state.adminUsers.usersCount);
  const listOfUsers = useSelector(state => state.adminUsers.listOfUsers, shallowEqual);
  const listOfOperators = useSelector(state => state.adminOperators.listOfOperators, shallowEqual);

  const [operatorsFilter, setOperatorsFilter] = useState([]);
  const [roleIdFilter, setRoleIdFilter] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const tableHandlers = useTableHandlers(GET_ADMIN_USERS_URL, getAdminUsersStart, {
    customSearchParam: "search",
    defaultOrderBy: "updated_at",
    defaultOrder: "desc",
  });

  const { url, handleSearch, handleChangeParams } = tableHandlers;

  const debouncedSearch = useRef(
    debounce(query => {
      handleSearch(query, true);
    }, 300),
  );

  const onSearch = query => {
    setSearchValue(query);
    return debouncedSearch.current(query);
  };

  const contextBar = {
    left: <SearchField label={ADMIN_USERS_INDEX.searchUsers} onSearchChange={onSearch} value={searchValue} />,
    middle: ManageAccountsSubMenu,
    right: <TableFilters onRoleSelect={setRoleIdFilter} onOperatorSelect={setOperatorsFilter} operatorsFilter={operatorsFilter} />,
  };

  const content = () => {
    if (!listOfUsers.length && isLoadingScreen) return <LoadingScreen />;
    if (!listOfUsers.length && !isLoadingScreen)
      return (
        <NoResultsMessage>
          {checkQueryParamExistence(url, "search")
            ? NO_RESULTS_FOUND_MESSAGE
            : EMPTY_LIST_MESSAGES_BASE(CREATE_CONTENT_LABELS.trip, NAMES_OF_RESOURCES_LISTS.trip)}
        </NoResultsMessage>
      );
    return (
      <TableOfUsers
        users={listOfUsers}
        operators={listOfOperators}
        tableHandlers={tableHandlers}
        count={usersCount}
        isDataLoading={isLoadingScreen}
      />
    );
  };

  useEffect(() => {
    handleChangeParams({
      operator_id: operatorsFilter[0]?.key,
      role_id: roleIdFilter,
    });
  }, [operatorsFilter, roleIdFilter]);

  return (
    <AdminTemplate contextBar={contextBar}>
      <Grid container justifyContent="center">
        <Grid item md={12}>
          {content()}
        </Grid>
      </Grid>
    </AdminTemplate>
  );
};

export { UsersIndex };
