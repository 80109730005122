import styled from "styled-components";
import FormControlLabel from "@mui/material/FormControlLabel";
import { font } from "config/theme/fonts";
import { colors } from "config/theme/colors";

export const FormControlLabelS = styled(FormControlLabel)`
  .MuiFormControlLabel-root {
    margin-left: 0;
  }
  .MuiTypography-root {
    font-family: ${font};
    font-size: 16px;
    ${p => p.labelStyle};
  }
  .MuiButtonBase-root {
    color: rgb(164, 167, 170) !important;
  }

  .Mui-checked {
    color: ${colors.brand} !important;
  }
`;
