import React from "react";
import { Grid } from "@material-ui/core";
import { useLocation } from "react-router-dom";

import { MenuBox } from "./MenuBox";

const MenuSubpage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isHistoryVersion = queryParams.get("versionHistory");

  return (
    <Grid>
      <Grid>
        <MenuBox isHistoryVersion={isHistoryVersion} />
      </Grid>
    </Grid>
  );
};

export { MenuSubpage };
