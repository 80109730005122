import moment from "moment";
import React from "react";
import styled from "styled-components";
import config from "config/app";
import { colors } from "config/theme/colors";
import { DndRequestsTableAction } from "feature/panel/Stays/_shared/DoNotDisturb/DndRequestsTableAction";

const Wrap = styled.div`
  padding: 15px;
  border-bottom: 1px solid ${colors.grey10};
  div {
    color: ${colors.grey40};
    margin-bottom: 15px;
    font-size: 14px;
    span {
      color: #000;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const MobileItem = ({ item, onAccept, canEditItinerary }) => {
  return (
    <Wrap>
      <div>
        Room - <span>{item?.profile?.room_number}</span>
      </div>
      <div>
        Guest name - <span>{item?.profile?.booking_name}</span>
      </div>
      <div>
        Date sent - <span>{moment(item.requested_at).format(config.dateFormat)}</span>
      </div>
      <div>
        Time sent - <span>{moment(item.requested_at).format(config.timeFormat)}</span>
      </div>
      <div>
        DND end time - <span>{moment(item.ends_at).format(config.timeFormat)}</span>
      </div>
      <DndRequestsTableAction
        item={item}
        onAccept={onAccept}
        canEdit={canEditItinerary}
        style={{ float: "none", maxWidth: "none", justifyContent: "center" }}
      />
    </Wrap>
  );
};

export default MobileItem;
