import { responsiveConfig } from "config/responsive";
import styled from "styled-components";
import { getMediaPx } from "helpers";

export const Wrap = styled.div`
  padding: 40px;
  @media (max-width: ${responsiveConfig.mediaPx.laptop}px) {
    padding: 15px;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  /* padding: 40px; */
  padding-bottom: 40px;
  margin-bottom: 0px;
  border-bottom: 1px solid #e8e8e9;
  @media (max-width: ${responsiveConfig.mediaPx.laptop}px) {
    grid-template-columns: 1fr;
  }
`;

export const InputsGrid = styled.div`
  display: grid;
  gap: 35px;
  margin-top: 35px;
  /* margin-bottom: 30px; */
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    gap: 24px;
    margin-top: 24px;
  }
`;

export const SwithcersWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InstantViewWrap = styled.div`
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid #e8e8e9;
`;

export const InstantViewFlex = styled.div`
  display: flex;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    flex-direction: column;
  }
`;

export const InstantViewGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin-left: 15px;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    margin-left: 0;
    margin-top: 15px;
    margin-bottom: 25px;
  }
`;
