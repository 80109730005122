import { ERRORS } from "constants/content";
import { emailValidate, phoneValidate } from "utils/validation";
import { firstLetterUpperCase } from "utils/string";

export const validateContactUsForm = modifiedInspiration => {
  const errorsList = {};
  if (!emailValidate(modifiedInspiration.email)) errorsList.email = ERRORS.invalidEmail;
  if (!phoneValidate(modifiedInspiration.phone_number)) errorsList.phone_number = ERRORS.invalidPhone;
  ["full_name", "email", "subject", "phone_number", "message"].forEach(fieldName => {
    if (!modifiedInspiration[fieldName].length) {
      errorsList[fieldName] = ERRORS.isRequired(firstLetterUpperCase(fieldName.replace("_", " ")));
    }
  });

  return errorsList;
};
