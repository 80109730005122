import React, { useState } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { H1, H2, H3, H4, H5, TitleText, LeadText, BodyText, CaptionText } from "components/ui/Typography/Typography";
import { PrimaryButton, SecondaryButton, GhostButton } from "components/ui/Buttons";
import { Input, Textarea, Select, UnsafeAutocomplete } from "components/ui/Forms";
import { DatePicker, TimePicker } from "components/ui/Forms/DatePickers";
import { Map } from "components/ui/Maps/Map";
import { Message } from "components/ui/Messages/Message";
import { Content } from "components/ui/Content";
import { useTitle } from "hooks/useTitle";
import { filterOptions } from "utils/filters/filterOptions";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const MapWrapper = styled.div`
  width: 100%;
  height: 480px;
  position: relative;
`;

const Styleguide = () => {
  useTitle("Styleguide page");
  const [selectValue, setSelectValue] = useState(0);
  const [autocompleteValue, setAutocompleteValue] = useState("");
  const [autocompleteValue2, setAutocompleteValue2] = useState("");
  const [autocompleteList, setAutocompleteList] = useState([]);
  const [datePickerValue, setDatePickerValue] = useState(null);

  const handleAddToMultipleAutocompleteLust = item => {
    const checkExist = autocompleteList.filter(el => el.key === item.key).length > 0;
    if (!checkExist) setAutocompleteList([...autocompleteList, item]);
  };

  const handleDeleteFromAutocompleteList = item => {
    setAutocompleteList([...autocompleteList.filter(el => el.key !== item.key)]);
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper elevation={3} className={classes.paper}>
            <Grid container spacing={3} alignItems="center" align="center">
              <Grid item xs={12} sm={6} md={4}>
                <H1 cv="brandHover">Header H1</H1>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <H2>Header H2</H2>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <H3 cv="brand">Header H3</H3>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <H4 cv="warning">Header H4</H4>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <H5 cv="info">Header H5</H5>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TitleText>Title text</TitleText>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <LeadText>Lead text</LeadText>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <BodyText>Body text</BodyText>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CaptionText>Caption text</CaptionText>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} className={classes.paper}>
            <Grid container spacing={3} alignItems="center" align="center">
              <Grid item xs={12} sm={6} md={4}>
                <PrimaryButton size="large" className={classes.button}>
                  Primary Button
                </PrimaryButton>
                <PrimaryButton size="large" className={classes.button} disabled>
                  Primary Button
                </PrimaryButton>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <PrimaryButton size="medium" className={classes.button}>
                  Primary Button
                </PrimaryButton>
                <PrimaryButton size="medium" className={classes.button} disabled>
                  Primary Button
                </PrimaryButton>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <PrimaryButton size="small" className={classes.button}>
                  Primary Button
                </PrimaryButton>
                <PrimaryButton size="small" className={classes.button} disabled>
                  Primary Button
                </PrimaryButton>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <SecondaryButton size="large" className={classes.button}>
                  Secondary Button
                </SecondaryButton>
                <SecondaryButton size="large" className={classes.button} disabled>
                  Secondary Button
                </SecondaryButton>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <SecondaryButton size="medium" className={classes.button}>
                  Secondary Button
                </SecondaryButton>
                <SecondaryButton size="medium" className={classes.button} disabled>
                  Secondary Button
                </SecondaryButton>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <SecondaryButton size="small" className={classes.button}>
                  Secondary Button
                </SecondaryButton>
                <SecondaryButton size="small" className={classes.button} disabled>
                  Secondary Button
                </SecondaryButton>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <GhostButton size="large" className={classes.button}>
                  Ghost Button
                </GhostButton>
                <GhostButton size="large" className={classes.button} disabled>
                  Ghost Button
                </GhostButton>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <GhostButton size="medium" className={classes.button}>
                  Ghost Button
                </GhostButton>
                <GhostButton size="medium" className={classes.button} disabled>
                  Ghost Button
                </GhostButton>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <GhostButton size="small" className={classes.button}>
                  Ghost Button
                </GhostButton>
                <GhostButton size="small" className={classes.button} disabled>
                  Ghost Button
                </GhostButton>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} className={classes.paper}>
            <Grid container spacing={3} justifyContent="flex-start">
              <Grid item xs={3}>
                <Input size="small" label="small size" />
              </Grid>
              <Grid item xs={3}>
                <Input label="default size" />
              </Grid>
              <Grid item xs={3}>
                <Input size="large" helperText="helper text" label="Input with helper text" />
              </Grid>
              <Grid item xs={3}>
                <Textarea label="Textarea" rows={3} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Content margin={3}>
          <Message text="This is an error message" type="error" onDelete={() => {}} />
        </Content>
        <Content margin={3}>
          <Message text="This is a warning message" type="warning" onDelete={() => {}} />
        </Content>
        <Content margin={3}>
          <Message text="This is an info message" type="info" onDelete={() => {}} />
        </Content>
        <Content margin={3}>
          <Message text="This is a success message" onDelete={() => {}} />
        </Content>
        <Grid item xs={12}>
          <Paper elevation={3} className={classes.paper}>
            <Grid container spacing={3} justifyContent="flex-start">
              <Grid item xs={12} md={4}>
                <Select label="Select" name="example" value={selectValue} size="small" onChange={e => setSelectValue(e.target.value)}>
                  <MenuItem value={0}>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={1}>Option1</MenuItem>
                  <MenuItem value={2}>Option2</MenuItem>
                  <MenuItem value={3}>Option3</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} md={4}>
                <Select label="Select" name="example" value={selectValue} onChange={e => setSelectValue(e.target.value)}>
                  <MenuItem value={0}>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={1}>Option1</MenuItem>
                  <MenuItem value={2}>Option2</MenuItem>
                  <MenuItem value={3}>Option3</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} md={4}>
                <Select label="Select" name="example" value={selectValue} size="large" onChange={e => setSelectValue(e.target.value)}>
                  <MenuItem value={0}>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={1}>Option1</MenuItem>
                  <MenuItem value={2}>Option2</MenuItem>
                  <MenuItem value={3}>Option3</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <UnsafeAutocomplete
            type="text"
            value={autocompleteValue}
            onChange={({ target }) => setAutocompleteValue(target.value)}
            onOptionClick={setAutocompleteValue}
            options={filterOptions(
              [
                { value: "Poland", label: "Poland", key: "1" },
                { value: "Portugal", label: "Portugal", key: "2" },
                { value: "Panama", label: "Panama", key: "3" },
              ],
              autocompleteValue,
            )}
            placeholder="Start typing with 'p' for auto selection"
            helperText="Select country"
          />
        </Grid>
        <Grid item xs={12}>
          <UnsafeAutocomplete
            type="text"
            value={autocompleteValue2}
            label="Select country"
            onChange={({ target }) => setAutocompleteValue2(target.value)}
            onOptionClick={handleAddToMultipleAutocompleteLust}
            options={filterOptions(
              [
                { value: "Poland", label: "Poland", key: "1" },
                { value: "Portugal", label: "Portugal", key: "2" },
                { value: "Panama", label: "Panama", key: "3" },
              ],
              autocompleteValue2,
            )}
            placeholder="Start typing with 'p' for auto selection"
            helperText="Select country"
            selectedList={autocompleteList}
            onDelete={handleDeleteFromAutocompleteList}
            multiple
          />
        </Grid>
        <Grid item xs={12}>
          <DatePicker value={datePickerValue} onChange={setDatePickerValue} size="small" label="Date picker" autoOk />
          <TimePicker value={datePickerValue} onChange={setDatePickerValue} size="large" label="Time picker" autoOk />
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} className={classes.paper}>
            <MapWrapper>
              <Map />
            </MapWrapper>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export { Styleguide };
