import React, { useEffect } from "react";
import BaseModal from "components/_new/ModalBase";
import Input from "components/ui/Inputs/TextInput";
import SelectInput from "components/ui/Inputs/Select";
import IconSelectOption from "components/_new/IconSelectOption";
import { useQuery } from "@tanstack/react-query";
import { components } from "react-select";
import { useForm } from "react-hook-form";

// const InputTest = props => <components.Input {...props}>test</components.Input>;

const DocumentsModal = ({ onCancel, onSubmit, label, modalLabel, icon, hideTitleInput }) => {
  const { control, handleSubmit, reset } = useForm();
  const { data: { items: icons } = {} } = useQuery({ queryKey: ["icon?count=500"] });

  const iconsModified = icons
    ?.filter(item => item.section === "navigation")
    ?.map(item => ({
      ...item,
      name: <IconSelectOption src={item.url} name={item.name.split(":")[1] || item.name} />,
    }));

  useEffect(() => {
    reset({ icon, label });
  }, [icon]);

  return (
    <BaseModal
      title={`Edit "${modalLabel}"`}
      onConfirm={handleSubmit(onSubmit)}
      onCancel={onCancel}
      modalStyle={{ overflow: "visible" }}
      contentStyle={{ overflow: "visible" }}
      confirmTitle="Update"
    >
      {!hideTitleInput && <Input autoFocus label="Title" style={{ marginTop: 25, marginBottom: 25 }} control={control} name="label" />}
      <SelectInput
        label="Icon"
        autoFocus={hideTitleInput}
        options={iconsModified}
        optionLabelVar="name"
        optionValueVar="id"
        control={control}
        name="icon"
        isClearable={false}
        styleContainer={{ marginTop: 15 }}
      />
    </BaseModal>
  );
};

export default DocumentsModal;
