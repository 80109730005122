import { getMediaPx } from "helpers";
import styled, { css } from "styled-components";

const gpsStyles = css`
  grid-template-columns: 1fr 1fr;
`;

const timedStyles = css`
  grid-template-columns: ${props => props.showNumberOfDays ? "1fr 1fr 30px 1fr" : "2fr 30px 1fr"};
`;

export const InputsRow = styled.div`
  display: grid;
  gap: 15px;
  align-items: flex-start;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    grid-template-columns: 1fr;
  }
  
  ${props => props.type === "gps" && gpsStyles}
  ${props => props.type === "timed" && timedStyles}
`;

export const AtWrap = styled.div`
  align-self: center;
  text-align: center;
  margin-bottom: ${({ hasError }) => (hasError ? "17px" : "0")};
  transition: all 0.2s ease-in-out;
`;

export const SwitchWrapper = styled.div`
  position: absolute;
  right: ${({ theme }) => theme.setSpacing(6)}px;
  transform: translateY(-85%);
  display: flex;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 24px;
`;

export const LanguageSelectorWrapper = styled.div`
  margin: ${({ theme }) => theme.setSpacing(4)}px 0 0 0;
`;
