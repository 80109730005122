import styled from "styled-components";
import { colors } from "config/theme/colors";

export const Menu = styled.div`
  position: fixed;
  right: ${({ open }) => (open ? 0 : "-61vw")};
  top: 64px;
  height: calc(100vh - 64px);
  width: 61vw;
  background-color: #1b222b;
  z-index: 999999;
  transition: all 0.2s ease-in-out;
  box-shadow: ${({ open }) => open && "0 20px 30px rgba(0, 0, 0, 0.5)"};
  padding: 30px 30px 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  &.animation {
    animation: open 0.5s ease-in-out;
  }
  @keyframes open {
    0% {
      right: -61vw;
    }
    25% {
      right: 0;
    }
    75% {
      right: -10vw;
    }
    100% {
      right: -1px;
    }
  }
`;
export const Overlay = styled.div`
  position: fixed;
  left: ${({ open }) => (open ? 0 : "-40vw")};
  top: 64px;
  height: calc(100vh - 64px);
  width: 40vw;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999;
  backdrop-filter: blur(1px);
  transition: all 0.2s ease-in-out;

  &.animation-overlay {
    animation: open-overlay 0.5s ease-in-out;
  }
  @keyframes open-overlay {
    0% {
      left: -40vw;
    }
    25% {
      left: 0;
    }
    75% {
      left: -10vw;
    }
    100% {
      left: -1px;
    }
  }
`;
