import React from "react";
import PropTypes from "prop-types";

import { CUSTOM_APP_ADMIN } from "constants/content";
import { BundleListHeader, CustomAppWrapper, InfoCell } from "./styledComponents";
import { CustomAppListItem } from "./CustomAppListItem";

const CustomAppList = ({ customApps, canEdit }) => {
  return (
    <CustomAppWrapper>
      <div>
        <BundleListHeader>
          <InfoCell>{CUSTOM_APP_ADMIN.table.name}</InfoCell>
          <InfoCell>{CUSTOM_APP_ADMIN.table.status}</InfoCell>
          <InfoCell>{CUSTOM_APP_ADMIN.table.created}</InfoCell>
          <InfoCell>{CUSTOM_APP_ADMIN.table.lastChange}</InfoCell>
        </BundleListHeader>
        {customApps.map(item => (
          <CustomAppListItem item={item} key={item.id} disabled={!canEdit} />
        ))}
      </div>
    </CustomAppWrapper>
  );
};

CustomAppList.propTypes = {
  customApps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  canEdit: PropTypes.bool.isRequired,
};

export { CustomAppList };
