import React, { memo } from "react";
import propTypes from "prop-types";
import { useSelector } from "react-redux";

import { Logo } from "components/ui/Logo/Logo";
import { Link } from "components/ui/Links/Link";
import { PANEL_USER_SETTINGS, ROOT_PATH } from "constants/routes";
import { LogoArea } from "./style";

const Logotype = memo(({ redirectUrl, toggleMenu, isOpen }) => {
  const { operators } = useSelector(state => state.auth);
  const newRedirectUrl = operators.length === 0 ? PANEL_USER_SETTINGS : redirectUrl;

  const handleLogoClick = () => {
    if (isOpen) {
      toggleMenu();
    }
  };

  return (
    <LogoArea onClick={handleLogoClick}>
      <Link to={newRedirectUrl}>
        <Logo />
      </Link>
    </LogoArea>
  );
});

Logotype.defaultProps = {
  redirectUrl: ROOT_PATH,
};

Logotype.propTypes = {
  redirectUrl: propTypes.string,
};

export { Logotype, LogoArea };
