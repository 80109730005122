export class PoiHasBeenDisabledOrEnabled {
  static name = "PoiHasBeenDisabledOrEnabled";

  static noPopup = true;

  constructor() {
    this.name = "PoiHasBeenDisabledOrEnabled";
    this.noPopup = true;
  }
}
