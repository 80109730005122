import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Editor } from "@tinymce/tinymce-react";
import config from "config/app";

import emailIcon from "assets/images/tiny-editor/email.svg";
import phoneIcon from "assets/images/tiny-editor/phone.svg";
import { GLOBAL_CONTENT, LOGIN_SCREEN_EDITOR_LABELS } from "constants/content";
import { colors } from "config/theme/colors";
import { getMediaPx } from "../../../../../../helpers";

const EditorWrapper = styled.div`
  .tox .tox-toolbar,
  .tox .tox-toolbar__overflow,
  .tox .tox-toolbar__primary {
    height: 48px;
    background: none;
    border-bottom: 1px solid ${colors.grey10};
  }
  & > .tox-tinymce {
    border-radius: ${({ theme }) => `${theme.setSpacing(2)}px`} !important;
    border-color: ${({ theme }) => theme.colors.grey40} !important;
    
    .tox-statusbar {
      display: none;
    }
  }
  
  & > .tox-tinymce.focused {
    outline: 1.5px solid rgb(255, 124, 70) !important;
    border: 1px solid rgb(255, 124, 70) !important;
  }

  && {
    .tox-tbtn--enabled {
      background: #ffffff;

      &:hover {
        background: #c8cbcf;
      }
    }
  }

  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    .tox .tox-toolbar__primary {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      width: 100%;
    }
`;

const Label = styled.div`
  font-size: ${({ theme }) => theme.setSpacing(3.5)}px;
  margin-bottom: ${({ theme }) => theme.setSpacing(2)}px;
  font-weight: 500;
  color: ${p => (p.error ? colors.error : p.isFocused ? colors.brand : "#4f4f4f")};
`;

const HelperTextContent = styled.small`
  color: ${({ theme, error }) => (error ? theme.colors.error : theme.colors.grey40)};
`;

const validatedPhoneNumber = phone => {
  const cleanedPhoneNumber = phone.replace(/[\s()-]+/g, "");

  const phonePattern = /^[+\d,()]+$/;

  const isValid = phonePattern.test(cleanedPhoneNumber);

  return isValid ? cleanedPhoneNumber : "";
};

const emailPopupConfig = editor => ({
  title: LOGIN_SCREEN_EDITOR_LABELS.insertEmail,
  tooltip: LOGIN_SCREEN_EDITOR_LABELS.addEmail,
  body: {
    type: "panel",
    items: [
      {
        type: "input",
        name: "email",
        label: LOGIN_SCREEN_EDITOR_LABELS.email,
      },
    ],
  },
  buttons: [
    {
      type: "cancel",
      name: "closeButton",
      text: GLOBAL_CONTENT.cancel,
    },
    {
      type: "submit",
      name: "submitButton",
      text: LOGIN_SCREEN_EDITOR_LABELS.save,
      primary: true,
    },
  ],
  initialData: {
    email: "",
  },
  onChange: () => {},
  onSubmit: api => {
    const { email } = api.getData();
    const content = editor.getContent();

    editor.setContent(`${content} <a href="mailto:${email}>${email}</a>`);
    api.close();
  },
});

const phoneNumberPopupConfig = editor => ({
  title: LOGIN_SCREEN_EDITOR_LABELS.insertPhone,
  tooltip: LOGIN_SCREEN_EDITOR_LABELS.addPhone,
  body: {
    type: "panel",
    items: [
      {
        type: "input",
        name: "phone",
        label: LOGIN_SCREEN_EDITOR_LABELS.phone,
      },
    ],
  },
  buttons: [
    {
      type: "cancel",
      name: "closeButton",
      text: GLOBAL_CONTENT.cancel,
    },
    {
      type: "submit",
      name: "submitButton",
      text: LOGIN_SCREEN_EDITOR_LABELS.save,
      primary: true,
    },
  ],
  initialData: {
    phone: validatedPhoneNumber(editor.selection.getContent()),
  },
  onSubmit: api => {
    const { phone } = api.getData();

    const selection = editor.selection;

    const content = selection.getContent();

    if (!phone) {
      const selectedNode = editor.selection.getNode();
      selectedNode.parentNode.removeChild(selectedNode);
    }

    selection.setContent(phone ? `<a href='tel:${phone}'>${content || phone}</a>` : content);

    api.close();
  },
});

const TextInputEditor = ({ value, onChange, label, error, helperText, disabled, onBlur, toolbar = "link addEmail addPhone", height, index, maxHeight, paste_as_text }) => {
  const [isDirty, setIsDirty] = useState(false);

  const setup = editor => {
    editor.on("keydown", function keydown(e) {
      if (e.keyCode === 32) {
        const currentNode = editor.selection.getNode();
        if (currentNode.tagName === "A") {
          const linkText = currentNode.textContent;

          const textBeforSpace = linkText.substring(0, editor.selection.getRng().startOffset);
          const textAfterSpace = linkText.substring(editor.selection.getRng().startOffset);

          const textNodeBefore = editor.getDoc().createTextNode(textBeforSpace);
          const textNodeAfter = editor.getDoc().createTextNode(textAfterSpace);

          const isTextBeforeEmpty = textBeforSpace?.length <= 1;

          if (isTextBeforeEmpty) {
            currentNode.parentNode.insertBefore(textNodeBefore, currentNode);
          }
          if (!isTextBeforeEmpty) currentNode.parentNode.insertBefore(textNodeAfter, currentNode.nextSibling);

          currentNode.textContent = isTextBeforeEmpty ? textAfterSpace : linkText.substring(0, editor.selection.getRng().startOffset);

          // Move the cursor to the end of the newly created text node
          const range = editor.getDoc().createRange();
          range.setStartAfter(isTextBeforeEmpty ? textNodeBefore : currentNode);
          range.setEndAfter(isTextBeforeEmpty ? textNodeBefore : currentNode);
          const selection = editor.selection.getRng();
          selection.setStart(range.startContainer, range.startOffset);
          selection.setEnd(range.endContainer, range.endOffset);
          editor.selection.setRng(selection);
        }
      }
    });

    editor.addShortcut("ctrl+m", "Edit the HTML source code that is generated.", function openSourceCode() {
      editor.execCommand("mceCodeEditor");
    });

    editor.ui.registry.addButton("addEmail", {
      text: `<img src="${emailIcon}" />`,
      tooltip: LOGIN_SCREEN_EDITOR_LABELS.addEmail,
      onAction: () => {
        editor.windowManager.open(emailPopupConfig(editor));
      },
    });
    editor.ui.registry.addButton("addPhone", {
      text: `<img src="${phoneIcon}" />`,
      tooltip: LOGIN_SCREEN_EDITOR_LABELS.addPhone,
      onAction: () => {
        editor.windowManager.open(phoneNumberPopupConfig(editor));
      },
    });

    editor.on('focus', (e) => {
      e.target.editorContainer.classList.toggle('focused');
    });

    editor.on('blur', (e) => {
      e.target.editorContainer.classList.toggle('focused');
    });
  };

  const onContentChange = (text, editor) => {
    const withouthNbsp = text.replaceAll(/&(amp|quot|lt|gt);/g, "");
    if (isDirty) onChange(text, editor);
  };
  return (
    <>
      <Label>{label}</Label>
      <EditorWrapper>
        <Editor
          apiKey={config.vendors.TinyMCE.API_KEY}
          tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
          value={value}
          key={index}
          onBlur={onBlur ? onBlur : undefined}
          // onActivate={() => {
          //   document.querySelectorAll("button[title='Font sizes'] .tox-tbtn__select-label").forEach(x => x.innerText = 'Medium');
          // }}
          init={{
            height,
            max_height: maxHeight ?? 300,
            min_height: 150,
            autoresize_bottom_margin: 0,
            menubar: false,
            force_br_newlines: true,
            force_p_newlines: false,
            forced_root_block: "",
            link_assume_external_targets: "https",
            link_default_protocol: "https",
            link_title: false,
            target_list: false,
            browser_spellcheck: true,
            content_style:
              "@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Raleway:wght@300;400;500;600;700&display=swap'); body { font-family: Montserrat; font-size: 1rem; color: #313840; }",
            default_link_target: "_blank",
            setup,
            smart_paste: false,
            fontsize_formats: "Small=0.8rem Medium=1rem Large=1.5rem",
            paste_as_text,
            // encoding: "html",

            plugins: [
              "advlist autolink lists link image charmap print preview anchor nonbreaking autoresize",
              "searchreplace visualblocks",
              "insertdatetime media table paste code help wordcount",
            ],
            contextmenu: false,

            toolbar,
          }}
          onEditorChange={onContentChange}
          onKeyDown={() => setIsDirty(true)}
          onMouseEnter={() => setIsDirty(true)}
          disabled={disabled}
        />
      </EditorWrapper>

      {helperText && <HelperTextContent error={error}>{helperText}</HelperTextContent>}
    </>
  );
};

TextInputEditor.defaultProps = {
  value: "",
  disabled: false,
  label: "",
  error: "",
  helperText: "",
  toolbar: "link addEmail addPhone",
  height: 100,
  index: 0,
};

TextInputEditor.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  error: PropTypes.any,
  helperText: PropTypes.string,
  toolbar: PropTypes.string,
  height: PropTypes.number,
  index: PropTypes.number,
};

export { TextInputEditor };
