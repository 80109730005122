import styled from "styled-components";
import { colors } from "config/theme/colors";
import { getMediaPx } from "helpers";

export const DirectoryItemWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 100px 100px 160px;
  align-items: center;
  padding-left: 15px;
  border: 1px solid ${colors.grey10};
  border-top: none;
  height: 56px;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    grid-template-columns: 1fr;
    height: auto;
    padding: 15px;
    gap: 15px;
  }
`;
