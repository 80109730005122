import React, { useState, useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import spacetime from "spacetime";
import soft from "timezone-soft";
import moment from "moment";
import { colors } from "config/theme/colors";
import { matchSorter } from "match-sorter";
import allTimezones from "./timezones";
import { Wrap, Label } from "./style";
import SearchableSelect from "../Forms/Select/index";
import { getSelectStyles } from "../Inputs/Select/style";
import SelectInput from "../Inputs/Select";

moment.locale("en");

export { allTimezones };

const TimezoneSelect = ({
  value,
  onBlur,
  onChange,
  labelStyle = "original",
  timezones,
  startDate,
  label,
  onCreateOption,
  startTime,
  control,
  name = "timezone",
  watch,
  setValue,
  disabled,
  shouldSetUsersTimezone,
  ...props
}) => {
  const [options, setOptions] = useState([]);
  const [isInputFocused, setIsFocused] = useState(false);

  if (!timezones) timezones = allTimezones;

  const finalValue = watch(name) || value || Intl.DateTimeFormat().resolvedOptions().timeZone;

  // const startTimeOrigin = moment(startTime).format("hh:mm:ss");
  const notExisting = finalValue && !timezones[finalValue];
  const getOptions = () => {
    const result = Object.entries(timezones)
      .reduce((selectOptions, zone) => {
        const now = spacetime(moment(startDate).format("MMMM DD YYYY hh:mm:ss"), zone[0]);

        if (!now) {
          selectOptions.push({
            value: zone[0],
          });

          return selectOptions;
        }

        const tz = now.timezone();
        const tzStrings = soft(zone[0]);

        let label = "";
        const abbr = now.isDST()
          ? // @ts-expect-error
            tzStrings[0].daylight?.abbr
          : // @ts-expect-error
            tzStrings[0].standard?.abbr;
        const altName = now.isDST() ? tzStrings[0].daylight?.name : tzStrings[0].standard?.name;

        const min = tz.current.offset * 60;
        const hr = `${(min / 60) ^ 0}:` + (min % 60 === 0 ? "00" : Math.abs(min % 60));
        const prefix = `(GMT${hr.includes("-") ? hr : `+${hr}`}) ${zone[1]}`;

        switch (labelStyle) {
          case "original":
            label = prefix;
            break;
          case "altName":
            label = `${prefix} ${altName?.length ? `(${altName})` : ""}`;
            break;
          case "abbrev":
            label = `${prefix} ${abbr?.length < 5 ? `(${abbr})` : ""}`;
            break;
          default:
            label = `${prefix}`;
        }

        selectOptions.push({
          value: tz.name,
          label,
          offset: tz.current.offset,
          abbrev: abbr,
          altName,
        });

        return selectOptions;
      }, [])
      .sort((a, b) => a.offset - b.offset);

    setOptions([...(notExisting ? [{ value: finalValue, label: finalValue }] : []), ...result]);
  };

  const handleChange = tz => {
    if (onChange) onChange(tz);
  };
  useEffect(() => {
    if (finalValue) getOptions();
  }, [labelStyle, timezones, startDate, finalValue]);

  useEffect(() => {
    if (finalValue) {
      const newValue = options.find(item => item.value === (finalValue || finalValue.value));
      if (shouldSetUsersTimezone && newValue) setValue(name, newValue.value);
      if (newValue && onChange) onChange(newValue);
    }
  }, [options]);
  const findFuzzyTz = zone => {
    let currentTime = spacetime.now("GMT");
    try {
      currentTime = spacetime.now(zone);
    } catch (err) {
      return;
    }
    return options
      .filter(tz => tz.offset === currentTime.timezone().current.offset)
      .map(tz => {
        let score = 0;
        if (
          currentTime.timezones[tz.value.toLowerCase()] &&
          !!currentTime.timezones[tz.value.toLowerCase()].dst === currentTime.timezone().hasDst
        ) {
          if (tz.value.toLowerCase().indexOf(currentTime.tz.substring(currentTime.tz.indexOf("/") + 1)) !== -1) {
            score += 8;
          }
          if (tz.label.toLowerCase().indexOf(currentTime.tz.substring(currentTime.tz.indexOf("/") + 1)) !== -1) {
            score += 4;
          }
          if (tz.value.toLowerCase().indexOf(currentTime.tz.substring(0, currentTime.tz.indexOf("/")))) {
            score += 2;
          }
          score += 1;
        } else if (tz.value === "GMT") {
          score += 1;
        }
        return { tz, score };
      })
      .sort((a, b) => b.score - a.score)
      .map(({ tz }) => tz)[0];
  };
  const parseTimezone = zone => {
    if (!finalValue || !zone) return null; //
    if (typeof zone === "object" && zone.value && zone.label) return zone;
    if (typeof zone === "string") {
      return options.find(tz => tz.value === zone) || (zone.indexOf("/") !== -1 && findFuzzyTz(zone));
    } else if (zone?.value && !zone?.label) {
      return options.find(tz => tz.value === zone.value);
    }
  };

  const handleFocus = () => setIsFocused(true);
  const handleBlur = e => {
    if (onBlur) onBlur(e);
    setIsFocused(false);
  };

  // useEffect(() => {
  //   if (notExisting && finalValue) {
  //     setOptions(matchSorter([{ label: finalValue, value: finalValue }, ...options], "", { keys: ["value", "label"] }));
  //     setValue(name, finalValue);
  //   }
  // }, [finalValue]);

  return (
    <Wrap>
      <SelectInput
        control={control}
        name={name}
        modifyValue={value => parseTimezone(value)}
        onCreateOption={value => {
          setOptions([{ label: value, value }, ...options]);

          setValue(name, value);
        }}
        options={options}
        type="creatable"
        label="Select timezone"
        disabled={disabled}
        noSort
      />
      {/* <CreatableSelect
        value={parseTimezone(value)}
        onChange={handleChange}
        options={options}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onCreateOption={onCreateOption}
        placeholder=""
        styles={getSelectStyles()}
        {...props}
      /> */}
    </Wrap>
  );
};

export default TimezoneSelect;
