import React from "react";
import OutlinedButton from "components/_new/Buttons/OutlinedButton";
import { colors } from "config/theme/colors";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import { Wrap } from "./styled";
import Item from "./Item";

const List = ({ onClick, chats, messages, currentChat, onSettingsOpen, openSettings, hasAdditionalMenu }) => {
  return (
    <Wrap open={currentChat || openSettings} hasAdditionalMenu={hasAdditionalMenu}>
      <div style={{ overflow: "auto" }}>
        {chats.map((item, index) => (
          <Item
            item={item}
            isActive={currentChat?.conversation === item.conversation}
            message={messages?.find(message => message.conversation === item.conversation)}
            onClick={onClick}
            key={index}
          />
        ))}
      </div>
      <div>
        <OutlinedButton
          text="Settings"
          type="grey"
          style={{
            width: "100%",
            borderRadius: 0,
            border: 0,
            borderTop: `1px solid ${colors.withOpacity(colors.grey10, 0.5)}`,
            backgroundColor: colors.withOpacity(colors.grey10, 0.5),
          }}
          startIcon={<SettingsApplicationsIcon />}
          onClick={onSettingsOpen}
        />
      </div>
    </Wrap>
  );
};

export default List;
