import { ERRORS } from "constants/content";
import { emailValidate, phoneValidate } from "utils/validation";

export const validateInspirationForm = modifiedInspiration => {
  const errorsList = {};
  const fields = [
    { displayName: "label", valueName: "name" },
    { displayName: "email", valueName: "contact_email" },
    { displayName: "phone", valueName: "contact_phone" },
  ];

  if (!emailValidate(modifiedInspiration.contact_email)) errorsList.contact_email = ERRORS.invalidEmail;
  if (!phoneValidate(modifiedInspiration.contact_phone)) errorsList.contact_phone = ERRORS.invalidPhone;
  
  fields.forEach(({ displayName, valueName }) => {
    if (!modifiedInspiration[valueName].length) errorsList[valueName] = ERRORS.isRequired(displayName);
  });

  modifiedInspiration.data?.rows?.forEach(row => {
    row.images.forEach(img => {
      if (!img.image) {
        if (errorsList?.images) {
          errorsList?.images.push(img.id);
        } else {
          errorsList.images = [img.id];
        }
      }
    });
  });

  return errorsList;
};
