import React, { useContext, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Wrapper } from "feature/panel/Itinerary/style";
import DocumentsTitle from "feature/panel/Itinerary/components/DocumentsTitle";
import { modifyInpuNameToUseDefaultItinerary } from "feature/panel/Itinerary/helpers";
import Item from "../../Trips/Documents/item";
import { DEFAULT_ACTION_ICON_ID, DEFAULT_BOOK_NOW_ICON_ID } from "../../../../Stays/_shared/initStaysState";
import { EditorContext } from "../../../index";

const Actions = ({
  form: {
    control,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  },
  canEdit,
  isDefaultStayLanguage,
}) => {
  const { stayLanguages } = useContext(EditorContext);
  const actionIconIdName = modifyInpuNameToUseDefaultItinerary("general.meta.travel_documents_icon_id", !isDefaultStayLanguage);
  const bookNowIconIdName = modifyInpuNameToUseDefaultItinerary("general.meta.destination_documents_icon_id", !isDefaultStayLanguage);
  const defaultLanguageName = !isDefaultStayLanguage ? stayLanguages?.find(lng => lng.code === watch("main.general.language"))?.name : null;

  const actionIconId = +watch(actionIconIdName);
  const bookNowIconId = +watch(bookNowIconIdName);

  const { currentOperator } = useSelector(state => state.operator);
  const defaultLabels = useMemo(() => {
    const initialLabels = {
      actionButton: {
        label: "Action",
        iconId: DEFAULT_ACTION_ICON_ID,
      },
      bookNow: {
        label: "Book now",
        iconId: DEFAULT_BOOK_NOW_ICON_ID,
      },
    };

    if (currentOperator) {
      return Object.keys(initialLabels).reduce((acc, key) => {
        acc[key] = currentOperator.labels?.find(label => label.screen === key) || initialLabels[key];
        return acc;
      }, {});
    }
    return initialLabels;
  }, [currentOperator]);

  const onSubmitAction = ({ icon }, type) =>
    setValue(type === "action" ? actionIconIdName : bookNowIconIdName, icon, { shouldDirty: true });

  // to remove the action if all the relevant fields are empty.
  const actionLabel = watch("actions.meta.travel_documents_label");
  const bookNowLabel = watch("actions.meta.destination_documents_label");
  const actionFile = watch("actions.documents.travel[0].file");
  const bookNowFile = watch("actions.documents.destination[0].file");
  useEffect(() => {
    if (actionLabel || actionFile) return;
    if (!actionLabel && !actionFile) {
      clearErrors("actions.meta.travel_documents_label");
      clearErrors("actions.documents.travel[0].file");
    }
  }, [actionLabel, actionFile]);

  useEffect(() => {
    if (bookNowLabel || bookNowFile) return;
    if (!bookNowLabel && !bookNowFile) {
      clearErrors("actions.meta.destination_documents_label");
      clearErrors("actions.documents.destination[0].file");
    }
  }, [bookNowLabel, bookNowFile]);

  return (
    <Wrapper>
      <DocumentsTitle
        label="Action"
        canEdit={canEdit}
        control={control}
        iconId={actionIconId || defaultLabels.actionButton.iconId}
        modalLabel="icon"
        onSubmitModal={data => onSubmitAction(data, "action")}
        isDefaultStayLanguage={isDefaultStayLanguage}
        hideAddButton
        hideTitleInput
        styleContainer={{ top: 0 }}
        defaultLanguageName={defaultLanguageName}
      />

      <div style={{ height: 20 }} />

      <Item
        control={control}
        inputNames={{ title: "actions.meta.travel_documents_label", file: "actions.documents.travel[0].file" }}
        watch={watch}
        label="Title"
        errors={errors}
        labelStyle={{ backgroundColor: "#fff" }}
        hideActions
        type="stay"
        setValue={setValue}
        autoFocus
        hideMobileDeleteButton
      />

      <div style={{ height: 40 }} />

      <DocumentsTitle
        label="Book now"
        canEdit={canEdit}
        control={control}
        iconId={bookNowIconId || defaultLabels.bookNow.iconId}
        isDefaultStayLanguage={isDefaultStayLanguage}
        modalLabel="icon"
        onSubmitModal={onSubmitAction}
        hideAddButton
        hideTitleInput
        styleContainer={{ top: 0 }}
        defaultLanguageName={defaultLanguageName}
      />

      <div style={{ height: 20 }} />

      <Item
        control={control}
        inputNames={{ title: "actions.meta.destination_documents_label", file: "actions.documents.destination[0].file" }}
        watch={watch}
        label="Title"
        labelStyle={{ backgroundColor: "#fff" }}
        hideActions
        type="stay"
        setValue={setValue}
        hideMobileDeleteButton
      />
    </Wrapper>
  );
};

export default Actions;
