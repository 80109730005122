class NavigationStack {
  constructor() {
    this.$stack = [];
  }

  add(path) {
    this.$stack.push(path);
  }

  remove(callback) {
    this.$stack.pop();
    const lastPath = this.$stack[this.$stack.length - 1];
    if (typeof callback === "function") {
      callback(lastPath);
    }
  }

  flush() {
    this.$stack = [];
  }

  get stack() {
    return this.$stack;
  }

  get path() {
    return this.$stack[this.$stack.length - 1];
  }
}

const navigationStack = new NavigationStack();

export { navigationStack, NavigationStack };
