import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { Content } from "components/ui/Content";
import { Input, Radio } from "components/ui/Forms";
import { FormControlLabel } from "@material-ui/core";
import { CaptionText, TitleText3 } from "components/ui/Typography/Typography";
import { ALLOWED_ROLES_NAMES_AND_ORDER, CONSTANT_ROLES } from "constants/defaults";

const RoleSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .MuiFormControlLabel-root {
    margin: 10px 0;
  }
`;

const StyledTitle = styled(TitleText3)`
  && {
    font-weight: 600;
  }
`;

function ModifyUserModal({ onCancel, modifyModal, onConfirm, title, confirmLabel, onChange, roles, errors }) {
  const isEditMode = !!modifyModal.body.id;
  const listedRoles = roles
    .filter(
      role => ALLOWED_ROLES_NAMES_AND_ORDER.includes(role.name.toLowerCase()) 
        && (isEditMode ? true : role.name.toLowerCase() !== CONSTANT_ROLES.SELECTED_ACCESS)
    )
    .sort(
      (a, b) => ALLOWED_ROLES_NAMES_AND_ORDER.indexOf(a.name.toLowerCase()) - ALLOWED_ROLES_NAMES_AND_ORDER.indexOf(b.name.toLowerCase())
    );

  const roleDescriptionLabel = (name, description) => (
    <>
      <StyledTitle>{name}</StyledTitle>
      <CaptionText>{description}</CaptionText>
    </>
  );

  return modifyModal.open ? (
    <ConfirmationModal
      onCancel={onCancel}
      open={modifyModal.open}
      onConfirm={onConfirm}
      title={title}
      confirmLabel={confirmLabel}
      fullWidth
    >
      <Content>
        <Input
          name="email"
          label="Contact email"
          value={modifyModal.body.email || ""}
          onChange={onChange}
          error={!!errors.email}
          helperText={errors.email || ""}
          disabled={isEditMode}
        />
      </Content>
      <RoleSelectWrapper>
        {listedRoles.map(role => (
          <>
            <FormControlLabel
              name="role_id"
              label={roleDescriptionLabel(role.name, role.description)}
              control={(
                <Radio
                  color="primary"
                  checked={modifyModal.body.role_id === role.id}
                  onChange={() => onChange({ target: { name: "role_id", value: role.id } })}
                />
              )}
            />
          </>
        ))}
      </RoleSelectWrapper>
    </ConfirmationModal>
  ) : null;
}

ModifyUserModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  modifyModal: PropTypes.shape({ body: PropTypes.shape({}), open: PropTypes.bool }).isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  confirmLabel: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.shape({}).isRequired,
  roles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export { ModifyUserModal };
