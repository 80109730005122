import { DeleteOutline, PeopleAltOutlined } from "@mui/icons-material";
import Badge from "components/_new/Badge";
import AttachButton from "components/_new/Buttons/AttachButton";
import OutlinedButton from "components/_new/Buttons/OutlinedButton";
import TravellersModal from "components/_new/TravellersModal";
import Input from "components/ui/Inputs/TextInput";
import { DOCUMENTS_FILE_TYPES } from "constants/defaults";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { ResponsiveHideFrom, ResponsiveShowFrom } from "feature/panel/Itinerary/responsive";
import { colors } from "config/theme/colors";
import DeleteButton from "components/_new/DnD/DeleteIcon";

import TableAction from "components/_new/Table/Action";
import { Actions, FileTitleWrap, IconWrap, ItemGrid } from "./style";
import { renderAddFileMini } from "../Locations/ListOfLocations/item";

const Item = ({
  onDelete,
  control,
  index,
  disabled,
  inputNameTab,
  errors,
  setValue,
  inputNames = {},
  watch,
  hideActions,
  type,
  wrapStyle,
  onMultiUpload,
  multiUpload,
  hasFile,
  tab,
  useNormalButton,
  trigger,
  clearErrors,
  hideMobileDeleteButton,
}) => {
  const [isTravellersOpen, setIsTravellersOpen] = useState(false);
  const [selectedPeopleIds, setSelectedPeopleIds] = useState(null);

  const { title: titleInputName, file: fileInputName } = inputNames;

  const itemError = errors?.documents?.documents?.[tab]?.[index] || errors?.actions?.documents?.[tab];

  const people = watch("people.travellers");
  const restrictedTravellerIds = watch(`${inputNameTab}.${index}.meta.restricted_to_traveller_internal_ids`);
  const file = watch(fileInputName || `${inputNameTab}.${index}.file`);
  const fileName = watch(titleInputName || `${inputNameTab}.${index}.name`);

  const toggleModal = () => setIsTravellersOpen(!isTravellersOpen);

  const onTravellersChange = arr => {
    setValue(`${inputNameTab}.${index}.meta.restricted_to_traveller_internal_ids`, arr, { shouldDirty: true });
    toggleModal();
  };

  useEffect(() => {
    if (!fileName && file) {
      if (clearErrors) clearErrors(titleInputName || `${inputNameTab}.${index}.name`);
      setValue(titleInputName || `${inputNameTab}.${index}.name`, file.file_name, { shouldDirty: true });
    }
  }, [file]);
  useEffect(() => {
    if (!restrictedTravellerIds || !people.length) return;

    if (restrictedTravellerIds?.length === 0) {
      setSelectedPeopleIds([]);
    } else {
      const selectedPeopleIdsData = people
        .filter(person => person.travellersData.some(traveller => restrictedTravellerIds?.includes(traveller.internal_id)))
        .map(person => person.id);

      setSelectedPeopleIds(selectedPeopleIdsData);
    }
  }, [people, restrictedTravellerIds]);

  const handleConfirm = (files, onChange) => {
    if (!Array.isArray(files)) {
      onChange(files);
    } else {
      const remainingFiles = files.filter((file, index) => index !== 0);
      onMultiUpload(remainingFiles);
      onChange(files[0]);
    }
  };

  const hasError = errors?.documents || errors?.actions?.documents;
  const reValidate = () => {
    if (hasError && trigger) trigger();
  };

  const handleDeleteItem = () => {
    onDelete(index);
    reValidate();
  };

  const reValidateOnChange = () => {
    if (!hasError) return;
    reValidate();
  };

  return (
    <ItemGrid
      hasFile={file}
      disabled={disabled}
      hideActions={hideActions}
      type={type}
      style={wrapStyle}
      className={`${itemError && "dnd-error"}`}
    >
      <ResponsiveShowFrom size="md">
        {!hideMobileDeleteButton && !disabled && <DeleteButton onClick={handleDeleteItem} />}
      </ResponsiveShowFrom>
      {isTravellersOpen && (
        <TravellersModal
          people={people}
          onConfirm={onTravellersChange}
          toggleModal={toggleModal}
          disabled={disabled}
          selectedPeopleIds={selectedPeopleIds}
          setSelectedPeopleIds={setSelectedPeopleIds}
        />
      )}
      <FileTitleWrap hasError={itemError} type={type}>
        <Input
          control={control}
          name={titleInputName || `${inputNameTab}.${index}.name`}
          label="Title"
          disabled={disabled}
          autoFocus={!fileName}
          customOnChange={reValidateOnChange}
        />
      </FileTitleWrap>

      <Actions hasFile={file} type={type}>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          name={fileInputName || `${inputNameTab}.${index}.file`}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <>
              {type === "stay" ? (
                <AttachButton
                  allowedFileTypes={DOCUMENTS_FILE_TYPES}
                  onConfirm={files => handleConfirm(files, onChange)}
                  text="Attach / link"
                  file={value}
                  onDelete={() => onChange(null)}
                  types={["library", "upload", "webpage"]}
                  disabled={disabled}
                  error={error}
                  hideDescription
                  // iconOnlyMobile
                  mobileFullScreen
                  multiUpload={multiUpload}
                />
              ) : (
                renderAddFileMini({
                  name: fileInputName || `${inputNameTab}.${index}.file`,
                  control,
                  multiUpload,
                  handleDeleteFile: () => setValue(fileInputName || `${inputNameTab}.${index}.file`, null),
                  watchItem: file,
                  mediaTypes: DOCUMENTS_FILE_TYPES,
                  shouldButtonExpand: hasFile,
                  useNormalButton,
                  onCustomConfirm: (files => handleConfirm(files, onChange)),
                  disabled,
                })
              )}
            </>
          )}
        />

        {!hideActions && (
          <>
            <TableAction
              element={<PeopleAltOutlined />}
              onClick={toggleModal}
              mobileStyle={{ borderTop: `1px solid ${colors.grey10}` }}
              badgeContent={selectedPeopleIds?.length ?? null}
            />
              <ResponsiveHideFrom size="md" customStyles={{ display: "contents" }}>
                <TableAction element={<DeleteOutline />} onClick={handleDeleteItem} disabled={disabled} />
              </ResponsiveHideFrom>
          </>
        )}
      </Actions>
    </ItemGrid>
  );
};

export default Item;
