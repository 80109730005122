import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import MapOutlinedIcon from "@material-ui/icons/MapOutlined";

import { InformationalModal } from "components/ui/Modals/InformationalModal";
import { BodyText2 } from "components/ui/Typography/Typography";
import { ImagePlaceholderWithIcon } from "feature/panel/_shared/Sortable/StyledComponents/shared";

import { convertToDateTimeFormat } from "utils/dataConverters";

const CategoriesWrapper = styled.div`
  display: flex;
`;

const TravelInfoWrapper = styled.div`
  margin-left: ${({ theme }) => theme.setSpacing(6)}px;
`;

const PreviewImage = styled.img`
  border-radius: ${({ theme }) => theme.setSpacing(2)}px;
  width: 180px;
  height: 180px;
`;

const DataValue = styled(BodyText2)`
  && {
    font-weight: ${({ theme }) => theme.fonts.fontWeight.bold};
    margin-left: ${({ theme }) => theme.setSpacing(2)}px;
  }
`;

const DataPieceWrapper = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.setSpacing(4)}px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const StyledImagePlaceholderWithIcon = styled(ImagePlaceholderWithIcon)`
  height: 180px;
  width: 180px;
`;

const FlightDetailsModal = ({ open, flight, onClose }) => {
  const {
    carrier,
    carrier_fs_code,
    carrier_flight_number,
    departure_airport,
    departure_at_utc,
    live_departure_at_utc,
    departure_terminal,
    departure_gate,
    arrival_airport,
    arrival_at_utc,
    live_arrival_at_utc,
    arrival_terminal,
    arrival_gate,
    map,
  } = flight;
  const title = `${carrier_fs_code} ${carrier_flight_number} ${carrier ? carrier.name : null}`;

  const departureGateText = (
    <DataPieceWrapper>
      <BodyText2>Departure gate:</BodyText2>
      <DataValue>{departure_gate || " -"}</DataValue>
    </DataPieceWrapper>
  );

  const departureTerminalText = (
    <DataPieceWrapper>
      <BodyText2>Departure terminal:</BodyText2>
      <DataValue>{departure_terminal || " -"}</DataValue>
    </DataPieceWrapper>
  );

  const arrivalGateText = (
    <DataPieceWrapper>
      <BodyText2>Arrival gate:</BodyText2>
      <DataValue>{arrival_gate || " -"}</DataValue>
    </DataPieceWrapper>
  );

  const arrivalTerminalText = (
    <DataPieceWrapper>
      <BodyText2>Arrival terminal:</BodyText2>
      <DataValue>{arrival_terminal || " -"}</DataValue>
    </DataPieceWrapper>
  );
  return (
    open && (
      <InformationalModal open={open} onCancel={onClose} header={title} maxWidth="xl">
        <CategoriesWrapper>
          {map ? <PreviewImage src={map} /> : <StyledImagePlaceholderWithIcon Icon={MapOutlinedIcon} />}
          <TravelInfoWrapper>
            <DataPieceWrapper>
              <BodyText2>Departure time:</BodyText2>
              <DataValue>{convertToDateTimeFormat(live_departure_at_utc ?? departure_at_utc, departure_airport.timezone, true)}</DataValue>
            </DataPieceWrapper>
            <DataPieceWrapper>
              <BodyText2>Departure airport:</BodyText2>
              <DataValue>{departure_airport.name}</DataValue>
            </DataPieceWrapper>
            <DataPieceWrapper>
              <BodyText2>Departure city:</BodyText2>
              <DataValue>{departure_airport.city}</DataValue>
            </DataPieceWrapper>
            {departureGateText}
            {departureTerminalText}
          </TravelInfoWrapper>
          <TravelInfoWrapper>
            <DataPieceWrapper>
              <BodyText2>Arrival time:</BodyText2>
              <DataValue>{convertToDateTimeFormat(live_arrival_at_utc ?? arrival_at_utc, arrival_airport.timezone, true)}</DataValue>
            </DataPieceWrapper>
            <DataPieceWrapper>
              <BodyText2>Arrival airport:</BodyText2>
              <DataValue>{arrival_airport.name}</DataValue>
            </DataPieceWrapper>
            <DataPieceWrapper>
              <BodyText2>Arrival city:</BodyText2>
              <DataValue>{arrival_airport.city}</DataValue>
            </DataPieceWrapper>
            {arrivalGateText}
            {arrivalTerminalText}
          </TravelInfoWrapper>
        </CategoriesWrapper>
      </InformationalModal>
    )
  );
};

FlightDetailsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  flight: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
};

export { FlightDetailsModal };
