import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { debounce } from "lodash";
import MenuItem from "@material-ui/core/MenuItem";

import { Select, UnsafeAutocomplete } from "components/ui/Forms";

import { setUrlParams } from "utils/url";
import { GET_ADMIN_OPERATORS_URL } from "constants/api";
import { ADMIN_USERS_INDEX } from "constants/content";

import { getRolesStart } from "store/roles/actions";
import { getAdminUsersOperators, getAdminUsersOperatorsSuccess } from "store/adminUsers/actions";

const RoleSelector = styled(Select)`
  width: 200px;
`;

RoleSelector.displayName = "RoleSelector";

const OperatorSelectorWrapper = styled.div`
  margin: 0 24px;
`;

const OperatorSelector = styled(UnsafeAutocomplete)`
  width: 200px;
  overflow: hidden;
`;

OperatorSelector.displayName = "OperatorSelector";

const ALL = "all";

const TableFilters = ({ operatorsFilter, onRoleSelect, onOperatorSelect }) => {
  const [selectedRole, setSelectedRole] = useState(ALL);
  const [selectedOperators, setSelectedOperators] = useState([]);
  const listOfOperators = useSelector(state => state.adminUsers.operatorFilterItems, shallowEqual);
  const listOfRoles = useSelector(state => state.roles.listOfRoles, shallowEqual);
  const [operatorSearchValue, setOperatorSearchValue] = useState("");
  const dispatch = useDispatch();
  const handleChangeRole = ({ target }) => {
    const { value } = target;
    setSelectedRole(value);
    if (value === ALL) {
      onRoleSelect(null);
    } else {
      onRoleSelect(value);
    }
  };

  const handleChangeSearch = ({ target }) => {
    const { value } = target;
    setOperatorSearchValue(value);
  };

  const handleSelectOperator = selectedItem => {
    setSelectedOperators([selectedItem]);
    onOperatorSelect([selectedItem]);
    setOperatorSearchValue("");
  };

  const handleRemoveFromSelectedList = () => {
    setSelectedOperators([]);
    onOperatorSelect([]);
    setSelectedRole(ALL);
    onRoleSelect(null);
    setOperatorSearchValue("");
  };

  const debouncedOperatorsDispatch = useRef(
    debounce(search => {
      if (search === "" || search?.length) {
        dispatch(
          getAdminUsersOperators({
            searchMode: search !== "",
            url: setUrlParams(GET_ADMIN_OPERATORS_URL, {
              count: 50,
              page: 1,
              order_by: "created_at",
              order: "desc",
              archived: "off",
              ...(search && search !== ADMIN_USERS_INDEX.allOperators && { search }),
            }),
          }),
        );
      } else {
        dispatch(getAdminUsersOperatorsSuccess({ operators: [] }));
      }
    }, 300),
  );

  useEffect(() => {
    debouncedOperatorsDispatch.current(operatorSearchValue);
  }, [operatorSearchValue]);

  useEffect(() => {
    dispatch(getRolesStart());
  }, []);

  return (
    <>
      <OperatorSelectorWrapper>
        <OperatorSelector
          multiple
          autoComplete="off"
          name="operators"
          label={ADMIN_USERS_INDEX.filterByOperator}
          onOptionClick={handleSelectOperator}
          onChange={handleChangeSearch}
          options={[...listOfOperators].map(({ id, code, name }) => ({ value: code, label: `${name} (${code})`, key: id }))}
          value={operatorSearchValue}
          fullWidth={false}
          selectedList={selectedOperators}
          onDelete={handleRemoveFromSelectedList}
          maxValuesCount={1}
        />
      </OperatorSelectorWrapper>
      <RoleSelector
        fullWidth={false}
        value={selectedRole}
        name="roles"
        label={ADMIN_USERS_INDEX.filterByRole}
        onChange={handleChangeRole}
        disabled={operatorsFilter.length === 0}
      >
        <MenuItem key="0" value={ALL}>
          {ADMIN_USERS_INDEX.all}
        </MenuItem>
        {listOfRoles?.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
      </RoleSelector>
    </>
  );
};

TableFilters.propTypes = {
  onRoleSelect: PropTypes.func.isRequired,
  onOperatorSelect: PropTypes.func.isRequired,
  operatorsFilter: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export { TableFilters };
