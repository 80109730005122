import React from "react";
import BaseModal from "components/_new/ModalBase";

const DeleteFlightModal = ({ departure_airport, arrival_airport, carrier_fs_code, carrier_flight_number, onConfirm, onCancel }) => {
  return (
    <BaseModal
      title={`Are you sure you want to delete flight ${carrier_fs_code} ${carrier_flight_number} from ${departure_airport?.city} to ${arrival_airport?.city}?`}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  );
};

export default DeleteFlightModal;
