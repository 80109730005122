import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";
import { ImageOutlined } from "@material-ui/icons";

import { ADMIN_FEATURES_CONTENT, GLOBAL_CONTENT, USER_DETAILS_ACTIVE_LABEL, USER_DETAILS_INACTIVE_LABEL } from "constants/content";
import { RemoveButton } from "feature/panel/_shared/Sortable/StyledComponents/shared";

import { FlexGrow } from "components/ui/Content";
import { Input } from "components/ui/Forms";
import { ImagePreview, ImagePlaceholder } from "components/ui/Images";
import { SortableListElement } from "components/_shared/SortableListElement/SortableListElement";
import { 
  SortableListElementContentWithError 
} from "components/_shared/SortableListElementContentWithError/SortableListElementContentWithError";
import { ToggleControl } from "components/ui/ToggleControl/ToggleControl";
import { ChipWithRedirect } from "components/ui/Chips/ChipWithRedirect";
import { AttachFilesButton } from "components/ui/Buttons";
import { DEFAULT_LIBRARY_TABS } from "components/ui/LibraryPopup/LibraryPopup";

import { deleteFeatureItemStart, setValueForStayFeature } from "store/adminFeatures/actions";

import { IMAGES_MIME_TYPES } from "constants/defaults";
import { formatDisplayableLibraryPopupValues } from "utils/library";
import { checkErrorExistFor } from "utils/validation";
import { isObjectEmpty } from "utils/object";
import { VamoosImage } from "domain/VamoosImage";
import { DeleteConfirmation } from "./DeleteConfirmation";

const { library, files } = DEFAULT_LIBRARY_TABS;

const FeatureListItem = ({ index, feature, errors, disabled }) => {
  const [deleteConfirmModal, setDeleteConfirmModal] = useState({ open: false, count: null });
  const preview = feature?.icon?.previewUrl;
  const dispatch = useDispatch();

  const onFeatureChange = newFeatures => {
    dispatch(setValueForStayFeature(newFeatures));
  };

  const handleChangeFeatureName = useRef(
    debounce(
      (name, featureItem) =>
        onFeatureChange({
          ...featureItem,
          name,
        }),
      20,
    ),
  );

  const handleChangeAttachment = images => {
    const [newImage] = formatDisplayableLibraryPopupValues(images);
    const newFeature = {
      ...feature,
      icon: VamoosImage(newImage),
    };

    onFeatureChange(newFeature);
  };

  const handleRemoveAttachment = () => {
    const newFeature = {
      ...feature,
      icon: null,
    };
    onFeatureChange(newFeature);
  };

  const renderAttachmentDeleter = () => (
    <ChipWithRedirect onDelete={handleRemoveAttachment} item={feature?.icon} maxChar={15} icon={<ImageOutlined />} editable={!disabled} />
  );

  const renderAttachmentUploader = () => (
    <AttachFilesButton
      label={GLOBAL_CONTENT.attach}
      size="small"
      onSelect={handleChangeAttachment}
      allowedFileTypes={IMAGES_MIME_TYPES}
      tabs={[library, files]}
      error={errors?.image || null}
      disabled={disabled}
    />
  );

  const handleSwitchChange = featureItem => {
    onFeatureChange({
      ...featureItem,
    });
  };
  const handleFeatureItemDelete = (count, featureGroupId, id) => {
    if (count > 0) {
      setDeleteConfirmModal({ open: true, count });
    } else {
      dispatch(deleteFeatureItemStart({ featureGroupId, id }));
    }
  };
  const handleFeatureItemDeleteConfirm = (featureGroupId, id) => {
    setDeleteConfirmModal({ open: false, count: null });
    dispatch(deleteFeatureItemStart({ featureGroupId, id }));
  };

  const handleFeatureItemDeleteCancel = () => setDeleteConfirmModal({ open: false, count: null });

  return (
    <SortableListElement index={index} withError={!isObjectEmpty(errors)}>
      <SortableListElementContentWithError>
        {feature?.icon ? (
          <ImagePreview image={preview} bgType="contain" width="40px" height="40px" />
        ) : (
          <ImagePlaceholder width="40px" height="40px" />
        )}
        <FlexGrow grow={1}>
          <Input
            label={ADMIN_FEATURES_CONTENT.title}
            value={feature.name}
            onChange={({ target }) => handleChangeFeatureName.current(target.value, feature)}
            name={`${feature.name}-document-nr${index}`}
            size="small"
            error={checkErrorExistFor("name", errors)}
            helperText={errors?.name || null}
            disabled={disabled}
          />
        </FlexGrow>
        {preview ? renderAttachmentDeleter() : renderAttachmentUploader()}
        <ToggleControl
          value={feature?.isActive}
          onChange={() => handleSwitchChange({ ...feature, isActive: !feature?.isActive })}
          falseValueLabel={USER_DETAILS_INACTIVE_LABEL}
          trueValueLabel={USER_DETAILS_ACTIVE_LABEL}
          disabled={disabled}
        />
        {/* <RemoveButton onClick={() => handleFeatureItemDelete(feature.featureGroupId, feature.id)} /> */}
        <RemoveButton onClick={() => handleFeatureItemDelete(feature.count, feature.featureGroupId, feature.id)} disabled={disabled} />
        {deleteConfirmModal.open && (
          <DeleteConfirmation
            open={deleteConfirmModal.open}
            count={deleteConfirmModal.count}
            onConfirmModal={() => handleFeatureItemDeleteConfirm(feature.featureGroupId, feature.id)}
            onCancelModal={handleFeatureItemDeleteCancel}
          />
        )}
      </SortableListElementContentWithError>
    </SortableListElement>
  );
};

FeatureListItem.defaultProps = {
  errors: {},
  disabled: false,
};

FeatureListItem.propTypes = {
  index: PropTypes.number.isRequired,
  feature: PropTypes.shape({
    id: PropTypes.number,
    featureGroupId: PropTypes.number,
    isActive: PropTypes.bool,
    name: PropTypes.string,
    isFeatured: PropTypes.bool,
    icon: PropTypes.shape({
      previewUrl: PropTypes.string,
    }),
    count: PropTypes.number
  }).isRequired,
  errors: PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.string,
  }),
  disabled: PropTypes.bool,
};

export { FeatureListItem };
