import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { LIST_COLUMNS } from "feature/panel/Notifications/listColumns";
import { ActionsContext } from "feature/panel/Notifications/ActionsContext";
import { NotificationsRowActions } from "feature/panel/Notifications/NotificationsRowActions";
import { NotificationRowTemplate } from "feature/panel/Notifications/NotificationRowTemplate";

import { Table, TableBody, TableHeader, TablePagination } from "components/ui/Tables";
import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";
import { NoResultsMessage } from "components/ui/Messages/NoResultsMessage";

import { useTableHandlers } from "hooks/useTableHandlers";

import {
  CHANGES_SAVED_MESSAGE,
  CREATE_CONTENT_LABELS,
  EMPTY_LIST_MESSAGES_BASE,
  NAMES_OF_RESOURCES_LISTS,
  TABLE_NAMES,
} from "constants/content";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { NotificationsService } from "services/NotificationsService";
import { setNotification } from "store/app/actions";
import { setDefaultApiErrorMessage } from "utils";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { HttpClient } from "services/application/httpClient/httpClient";
import { useEffectDependenciesOnly } from "hooks/useEffectDependenciesOnly";

const NotificationsList = forwardRef(({ updateDefaultActiveStatus, onEditClick, activeType }, ref) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  /** @type {NotificationsService} */
  const notificationService = new NotificationsService();
  const permissionsService = usePermissionsService();

  const canCreateNotification = permissionsService.can(
    PERMISSIONS.actions.create,
    PERMISSIONS.sections.operator,
    PERMISSIONS.resources.notifications,
  );

  const { rowsPerPage, page, sortingOrder, sortingBy, handleChangeOrder, handleChangeRowsPerPage, handleChangePage, refactoredHandleChangeOrder } = useTableHandlers();

  const { data: { items: notifications, total_matches } = { data: { items: [], total_matches: 0 } }, isLoading } = useQuery({
    queryKey: [`${activeType}Notifications`, { rowsPerPage, page, activeType, sortingBy, sortingOrder }],
    queryFn: ({ queryKey }) => {
      const { rowsPerPage, page, activeType, sortingBy, sortingOrder } = queryKey[1];
      const ordering = `&order_by=${sortingBy}&order=${sortingOrder}`;

      return HttpClient.get(`notification/list/${activeType}?&count=${rowsPerPage}&page=${page}${sortingBy ? ordering : ""}`);
    },
    refetchOnMount: true, // to change notifications when operator is changed
    select: res => ({
        items: (res?.data?.items || []).map(item => ({
          ...item,
          ...(item.delivery_at ? { start_at: item.delivery_at.substr(11, 5) } : {}),
        })),
        total_matches: res?.data?.total_matches || 0,
    }),
  });

  const [deleteModal, setDeleteModal] = useState({ open: false, body: {} });
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteOpen = notification => {
    const { open } = deleteModal;
    const body = open ? deleteModal.body : notification;
    setDeleteModal({ body, open: !open });
  };

  const handleDeleteConfirm = async () => {
    try {
      setIsDeleting(true);
      await notificationService.removeNotification(deleteModal.body);
      await queryClient.invalidateQueries({ queryKey: [`${activeType}Notifications`], refetchType: "all" });
      await dispatch(setNotification({ type: "success", message: CHANGES_SAVED_MESSAGE }));
    } catch (error) {
      await dispatch(
        setNotification({
          type: "error",
          message: setDefaultApiErrorMessage(error),
        }),
      );
    }
    setIsDeleting(false);
    handleDeleteOpen();
  };

  const actions = {
    updateDefaultActiveStatus,
    onEditClick,
    handleDeleteOpen,
  };

  useEffectDependenciesOnly(() => {
    handleChangePage(0);
  }, [activeType]);

  const onOrderChange = data => handleChangeOrder(data, TABLE_NAMES.notifications);

  const mainContent = (() => {
    if (isLoading && !notifications?.length) return <LoadingScreen />;
    if (!notifications?.length && !canCreateNotification) return null;
    if (!notifications?.length && canCreateNotification)
      return (
        <NoResultsMessage>
          {EMPTY_LIST_MESSAGES_BASE(CREATE_CONTENT_LABELS.notificationTemplate, NAMES_OF_RESOURCES_LISTS.notifications[activeType])}
        </NoResultsMessage>
      );
    return (
      <ActionsContext.Provider value={actions}>
        <Table
          headers={LIST_COLUMNS[activeType]}
          list={notifications}
          actions={NotificationsRowActions}
          onChangeOrder={onOrderChange}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          count={total_matches}
          isDataLoading={isLoading}
          refactoredHandleChangeOrder={refactoredHandleChangeOrder}
          tableId={TABLE_NAMES.notifications}
          sortingByFromHook={sortingBy}
          sortingOrderFromHook={sortingOrder}
        >
          <TableHeader withActions tableId={TABLE_NAMES.notifications} />
          <TableBody rowComponent={NotificationRowTemplate} />
          <TablePagination count={total_matches} tableId={TABLE_NAMES.notifications} />
        </Table>
      </ActionsContext.Provider>
    );
  })();

  return (
    <>
      {mainContent}
      {deleteModal.open && (
        <ConfirmationModal
          onCancel={handleDeleteOpen}
          open={deleteModal.open}
          onConfirm={handleDeleteConfirm}
          showSpinner={isDeleting}
          title="Are you sure you want to delete notification?"
        />
      )}
    </>
  );
});

NotificationsList.propTypes = {
  updateDefaultActiveStatus: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  activeType: PropTypes.string.isRequired,
};

export { NotificationsList, ActionsContext };
