import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import Grid from "@material-ui/core/Grid";
import { isEqual } from "lodash";

import { PanelTemplate } from "components/templates/Panel/PanelTemplate";
import { ContextBarTitle } from "components/templates/Panel/ContextBarTitle";
import { BackHistoryButton, CancelHistoryButton, PrimaryButton } from "components/ui/Buttons";
import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";

import { DefaultIconSection } from "feature/panel/Pois/IconManager/DefaultIconSection";
import { IconListSection } from "feature/panel/Pois/IconManager/IconListSection";

import { setNotification, tryRouteChangeStart } from "store/app/actions";
import { toggleIconFormTouched, updateIconsSuccess } from "store/icons/actions";

import { GLOBAL_CONTENT, SITE_LEAVE_WARNING_MESSAGES } from "constants/content";
import { PANEL_POIS_PATH } from "constants/routes";
import { GET_ICONS, POI_ICONS_COUNT, UPDATE_POI_ICON } from "constants/api";
import { HttpClient } from "services/application/httpClient/httpClient";
import { setUrl } from "utils/url";
import { isObjectEmpty } from "utils/object";
import { sendDefaultApiErrorMessage } from "utils";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { usePrompt } from "components/ui/CustomPrompt/CustomPrompt";
import { useExtendedNavigate } from "hooks/useExtendedNavigate";

function sortByUsageCount(a, b) {
  return b.usageCount - a.usageCount;
}

let updatedIconsList = [];

const IconManager = () => {
  const dispatch = useDispatch();
  const navigate = useExtendedNavigate();

  const permissionsService = usePermissionsService();

  const [originalIconsList, setOriginalIconsList] = useState([]);
  const [inProgress, setInProgress] = useState(false);
  const [defaultIcon, setDefaultIcon] = useState({});
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const isIconFormTouched = useSelector(state => state.icons.isIconFormTouched);

  usePrompt(SITE_LEAVE_WARNING_MESSAGES.createTitle(SITE_LEAVE_WARNING_MESSAGES.contexts.iconsManager), isIconFormTouched);

  const handleChangeIcons = changedIcon => {
    const isInUpdatedIconsList = updatedIconsList.some(icon => icon.id === changedIcon.id);
    const updateIconInList = () =>
      updatedIconsList
        .map(item => (item.id === changedIcon.id ? changedIcon : item))
        .filter(item => {
          const originalIcon = originalIconsList.find(original => original.id === item.id);

          return !isEqual(originalIcon, item);
        });

    const addIconToList = () => [...updatedIconsList, changedIcon];

    updatedIconsList = isInUpdatedIconsList ? updateIconInList() : addIconToList();

    if (!isIconFormTouched) {
      dispatch(toggleIconFormTouched(true));
    }

    setSaveButtonDisabled(updatedIconsList.length === 0);
  };

  const getPoiIcons = async () => {
    try {
      const { data: icons } = await HttpClient.get(GET_ICONS);
      const poiIcons = icons.items.filter(icon => icon.section === "poi");
      const iconsCount = await HttpClient.get(POI_ICONS_COUNT);
      const iconsCountArray = Object.entries(iconsCount.data);
      const newIconsList = poiIcons
        .map(icon => {
          const iconCountValues = iconsCountArray.find(([id]) => icon.id === Number(id));
          return {
            ...icon,
            usageCount: iconCountValues ? iconCountValues[1] : 0,
          };
        })
        .sort(sortByUsageCount);
      const iconDefault = newIconsList.find(icon => icon.is_default);
      setDefaultIcon(iconDefault);
      setOriginalIconsList(newIconsList);
      setInProgress(false);
    } catch (e) {
      setInProgress(false);
    }
  };

  const handleIconsSaveFinished = updatedIconsResponses => {
    if (updatedIconsResponses.length) {
      const originalIcons = originalIconsList.map(icon => {
        const updatedIcon = updatedIconsResponses.find(i => i.data && i.data.id === icon.id);

        return updatedIcon ? { ...icon, ...updatedIcon.data, usageCount: icon.usageCount } : icon;
      });
      const message =
        updatedIconsResponses.length > 1 ? `${updatedIconsResponses.length} Icons updated successfully` : "Icon updated successfully";

      dispatch(updateIconsSuccess(originalIcons));
      getPoiIcons();
      setSaveButtonDisabled(true);
      dispatch(toggleIconFormTouched(false));
      dispatch(
        setNotification({
          type: "success",
          message,
        }),
      );
      updatedIconsList = [];
      setOriginalIconsList(originalIcons);
    }
  };

  const handleSave = () => {
    const updatedList = updatedIconsList
      .filter(icon =>
        originalIconsList.some(i => {
          const hasLocalisedName = !!i.localised_name;
          if (i.id !== icon.id) return false;
          if (hasLocalisedName && icon.name === i.localised_name) return false;
          if (!icon.name && (!hasLocalisedName || i.name === i.localised_name)) return false;
          return hasLocalisedName || icon.name !== i.name || !isEqual(icon.localisation, i.localisation);
        }),
      )
      .map(({ usageCount, ...rest }) => rest);

      updatedList
        .reduce(async (promise, { id, name, localisation }) => {

          const responses = await promise;

          const url = setUrl(UPDATE_POI_ICON, { id });
          const body = { name, localisation };
          return Promise.resolve([...responses, await HttpClient.post(url, body)]);
        }, Promise.resolve([]))
        .then(handleIconsSaveFinished)
        .catch(error => {
          sendDefaultApiErrorMessage(dispatch, error)
        });
  };

  const init = () => {
    // eslint-disable-next-line no-unused-vars
    let cleanup = false;
    dispatch(toggleIconFormTouched(false));
    setInProgress(true);
    getPoiIcons();

    return () => {
      cleanup = true;
    };
  };

  const handleCancelButton = () => {
    dispatch(tryRouteChangeStart(PANEL_POIS_PATH));
    navigate(PANEL_POIS_PATH);
  };

  useEffect(init, []);

  const contextBar = {
    left: () =>
      isIconFormTouched ? (
        <CancelHistoryButton role="link" clickHandler={handleCancelButton} />
      ) : (
        <BackHistoryButton role="link" clickHandler={handleCancelButton} />
      ),
    middle: () => <ContextBarTitle title="Manage icons" />,
    right: () => (
      <PrimaryButton onClick={handleSave} disabled={saveButtonDisabled}>
        <SaveOutlinedIcon />
        <span>{GLOBAL_CONTENT.save}</span>
      </PrimaryButton>
    ),
  };

  const defaultIconElement = !isObjectEmpty(defaultIcon) && <DefaultIconSection data={defaultIcon} onChange={handleChangeIcons} />;
  const content = inProgress ? (
    <LoadingScreen />
  ) : (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={10}>
        {defaultIconElement}
      </Grid>
      <Grid item xs={12} md={10}>
        <IconListSection list={originalIconsList} onChange={handleChangeIcons} />
      </Grid>
    </Grid>
  );

  return (
    <PanelTemplate
      contextBar={contextBar}
      hasPermission={
        permissionsService.can(PERMISSIONS.actions.read, PERMISSIONS.sections.operator, PERMISSIONS.resources.icons) &&
        permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.operator, PERMISSIONS.resources.icons)
      }
      languageSelector
    >
      {content}
    </PanelTemplate>
  );
};

export { IconManager };
