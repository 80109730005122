import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";

import { DefaultPageRightSection } from "feature/views/PublicPagesShared/DefaultPageRightSection";
import { DefaultPageLeftSection } from "feature/views/PublicPagesShared/DefaultPageLeftSection";
import { FullHeightColumn } from "components/ui/Grid/Grid";
import { FooterSection } from "feature/views/Home/FooterSection";
import { BrandSection } from "components/public/Brand/BrandSection";
import { FormSection } from "feature/views/Home/FormSection";
import { LeadText } from "components/ui/Typography/Typography";
import { useTitle } from "hooks/useTitle";
import bg from "assets/images/sign_in_bg.jpg";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const Home = () => {
  useTitle("Log in");

  const authStatus = useSelector(state => state.auth.authStatus);

  const isAuthSucces = authStatus === "success";

  return (
    <Grid container spacing={0} component="article">
      {isAuthSucces && <Navigate to="/panel/trips" />}
      <DefaultPageLeftSection>
        <FullHeightColumn alignContent="space-between">
          <Grid item xs={12}>
            <BrandSection />
          </Grid>
          <Grid item xs={12}>
            <FormSection />
          </Grid>
          <Grid item xs={12}>
            <FooterSection />
          </Grid>
        </FullHeightColumn>
      </DefaultPageLeftSection>
      <DefaultPageRightSection backgroundImage={bg}>
        <Grid item xs={9}>
          <LeadText cv="white">
            Vamoos – the iPhone and Android app that allows your clients to carry all their travel or event documents on their phone or
            tablet complete with weather forecast and maps – all viewable offline.
          </LeadText>
        </Grid>
        <Grid item xs={9} />
      </DefaultPageRightSection>
    </Grid>
  );
};

export { Home };
