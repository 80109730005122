import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import uuidv4 from "uuid";

import { LIBRARY_UPLOAD } from "constants/content";
import { Folder } from "./Folder";

const FoldersContainer = styled.div`
  width: 100%;
  flex-wrap: wrap;
  display: flex;
`;

const GenericMessage = styled.span`
  padding: ${({ theme }) => theme.setSpacing(3)}px;
`;

const FoldersList = ({ folders, handleFolderClick }) => {
  const renderFolder = (folderName, folderPath) => {
    return (
      <Folder
        id="folder"
        folderPath={folderPath}
        folderName={folderName}
        handleFolderClick={() => handleFolderClick({ path: folderPath })}
        key={uuidv4()}
      />
    );
  };

  const renderFolders = () => {
    return folders.map(({ name, path }) => {
      return renderFolder(name, path);
    });
  };

  return (
    <FoldersContainer>{folders.length ? renderFolders() : <GenericMessage>{LIBRARY_UPLOAD.noFolders}</GenericMessage>}</FoldersContainer>
  );
};

FoldersList.propTypes = {
  folders: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleFolderClick: PropTypes.func.isRequired,
};

export { FoldersList, GenericMessage };
