import { UUID_LENGTH } from "./constants/defaults";

export const deleteCookies = cookieName => {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

    // Trim any leading spaces
    name = name.trim();

    if (name === cookieName) {
      // Set the cookie to expire in the past
      document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }
  }
};

export const isValidEmail = email => {
  const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return pattern.test(email);
};

export const getMediaPx = (propsObj, size) => {
  return propsObj.theme.breakpoints.values[size];
};

export const objectToArray = (obj = {}) => Object.entries(obj).map(item => ({ id: item[0], ...item[1] }));
export const arrayToObject = (arr = [], key = "id") => {
  const result = {};

  arr.forEach(item => {
    result[item[key]] = item;
  });

  return result;
};
export const mergeTravelPeopleToDisplay = (travelPeople, errors) => {
  const mergeTravelPeople = [];
  travelPeople.forEach(t => {
    const found = mergeTravelPeople.find(m => m.name === t.name);
    const foundNameError = errors?.travellers?.validationErrors?.find(e => e.traveller.internal_id === t.internal_id);
    if (found && foundNameError?.name.isNameUnique !== false) {
      found.email = found.email && t.email ? found.email + ", " + t.email : found.email || t.email;
      found.nested.push(t); // use nested only after calling mergeTravelPeopleToDisplay, as nested is not added in the normal flow
      return;

      // to add a duplicate name in a separate field
    }
    if (foundNameError?.name.isNameUnique === false) {
      const prefix = t.internal_id.toString().slice(0, UUID_LENGTH);
      const found = mergeTravelPeople.find(m => m.internal_id.toString().includes(prefix));
      if (found) {
        found.email = found.email && t.email ? found.email + ", " + t.email : found.email || t.email;
        found.nested.push(t); // use nested only after calling mergeTravelPeopleToDisplay, as nested is not added in the normal flow
        return;
      }
      mergeTravelPeople.push({ ...t, nested: [t] });
      return;
    }
    mergeTravelPeople.push({ ...t, nested: [t] });
  });
  return mergeTravelPeople;
};

export const getRestrictionInfo = (travelPeople, errors, item, data) => {
  const mergeTravelPeople = mergeTravelPeopleToDisplay(travelPeople, errors);
  const personalisedTravellerIds = item.restricted_to_traveller_internal_ids?.filter(id =>
    mergeTravelPeople.some(p => p.internal_id === id),
  );
  const hasRestrictions = !!data.find(el => el.restricted_to_traveller_internal_ids !== null);

  return { personalisedTravellerIds, hasRestrictions, mergeTravelPeople };
};
