import React from "react";
import { Content } from "components/ui/Content/Content";
import { H1 } from "components/ui/Typography/Typography";
import SignInForm from "feature/views/Home/SignIn";
import { deviceType } from "../../../utils/deviceDetect";

const FormSection = () => {
  const device = deviceType();
  return (
    <section>
      <Content element="header" margin={11}>
        <H1 cv="grey90" spacing={device === "mobile" ? 16 : ""}>Log in</H1>
      </Content>
      <SignInForm />
    </section>
  );
}

export { FormSection };
