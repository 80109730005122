import React from "react";
import { useSelector } from "react-redux";
import { ArrowBackIos } from "@material-ui/icons";
import { CUSTOM_APPS, GLOBAL_CONTENT } from "constants/content";
import { PhonePresentation, ContentWrapper, PhoneHeader, LeftSlot, MiddleSlot, RightSlot } from "../PhonePresentation";
import { PresentationContent, WysywigContent } from "../TextsPage/sharedComponents";
import { HomeButton } from "../sharedComponents";
import { createMarkupForPreview } from "../TextsPage/helpers";

const TermsPreview = ({ language, title }) => {
  const accentColor = useSelector(state => state.customAppForm.customAppForm.colors.accentColor);
  const termsValue =
    useSelector(
      state => state.customAppForm.customAppForm?.menu?.documents?.terms?.languages?.[language.current || language]?.html_content,
    ) || "";

  const textColors = useSelector(state => state.customAppForm.customAppForm.menu.documents.terms.styles);

  const homeButton = (
    <HomeButton color={accentColor}>
      <ArrowBackIos />
      {GLOBAL_CONTENT.home}
    </HomeButton>
  );

  const headerConfig = {
    label: title,
    bgColor: "#ffffff",
    visible: true,
    leftSlot: homeButton,
  };

  const content = (
    <PresentationContent bodyTextColor={textColors?.textColor} headerTextColor={textColors?.headlineColor} linkColor={accentColor}>
      <WysywigContent dangerouslySetInnerHTML={createMarkupForPreview(termsValue)} />
      <h3>{CUSTOM_APPS.termsAndCondition.appUseHeadline}</h3>
      <p>{CUSTOM_APPS.termsAndCondition.appUsePreviewContentP1}</p>
      <p>{CUSTOM_APPS.termsAndCondition.appUsePreviewContentP2}</p>
      <h3>{CUSTOM_APPS.termsAndCondition.scopeOfServiceHeadline}</h3>
      <p>{CUSTOM_APPS.termsAndCondition.scoupOfServiceContent}</p>
      <h3>{CUSTOM_APPS.termsAndCondition.disclaimerHeadline}</h3>
      <p>{CUSTOM_APPS.termsAndCondition.disclaimerContent}</p>
      <h3>{CUSTOM_APPS.termsAndCondition.intelectualRightsHeadline}</h3>
      <p>{CUSTOM_APPS.termsAndCondition.intelectualRightsContent}</p>
    </PresentationContent>
  );

  return (
    <PhonePresentation>
      <>
        <PhoneHeader bgColor={headerConfig?.bgColor} fontColor={headerConfig?.fontColor}>
          <LeftSlot>{homeButton}</LeftSlot>
          <MiddleSlot>{headerConfig.label}</MiddleSlot>
          <RightSlot />
        </PhoneHeader>
        <ContentWrapper bgColor={textColors?.backgroundColor}>{content}</ContentWrapper>
      </>
    </PhonePresentation>
  );
};

export { TermsPreview };
