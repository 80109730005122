import { colors } from "config/theme/colors";
import { font } from "config/theme/fonts";
import styled from "styled-components";

export const Loader = styled.div`
  position: absolute;
  width: 26px;
  height: 26px;
  right: 7px;
  top: 7px;
  border-radius: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  .MuiCircularProgress-root {
    width: 16px !important;
    height: 16px !important;
    color: rgb(141, 144, 149);
  }
`;

export const Wrap = styled.div`
  position: relative;
  max-width: 210px;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 40px;
  display: flex;
  min-width: 0;
  align-items: center;
  padding: 0 15px;
  font-family: ${font};
  border-radius: 8px;
  font-size: 16px;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
  // font-weight: 600;
  background-color: ${colors.withOpacity(colors.grey10, 0.3)};
  .MuiSvgIcon-root {
    margin-right: 10px;
    color: rgb(141, 144, 149);
  }
  &:hover {
    background-color: ${colors.withOpacity(colors.grey10, 0.7)};
  }
  @media (max-width: 600px) {
    width: 100%;
    max-width: none;
    min-width: 150px;
  }
`;
