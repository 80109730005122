// @ts-check

import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { LS_LAST_VISITED_PAGE_KN } from "constants/localstorage";
import { PANEL_ROOT_PATH, ROOT_PATH, PANEL_USER_SETTINGS, ADMIN_OPERATORS_PATH, VERIFY_PATH } from "constants/routes";
import { AUTH_STATUSES } from "store/auth/reducer";
import { OPERATOR_TYPE } from "constants/content";
import { StorageService } from "services/StorageService";
import { setIsInitialAuth } from "../../../store/auth/actions";

const PrivateView = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const storageService = new StorageService();

  const currentOperatorCode = useSelector(state => state.auth.currentOperatorCode);
  const authStatus = useSelector(state => state.auth.authStatus);
  const operators = useSelector(state => state.auth.operators, shallowEqual);
  const needsVerification = useSelector(state => state.auth.requireVerification);

  const authRedirectPath =
    operators?.find(({ code }) => code === currentOperatorCode)?.type === OPERATOR_TYPE.admin ? ADMIN_OPERATORS_PATH : PANEL_ROOT_PATH;

  const setRedirectUrl = () => {
    const pathToSave = authStatus === AUTH_STATUSES.success ? location.pathname : authRedirectPath;
    storageService.setPropertyByName(LS_LAST_VISITED_PAGE_KN, pathToSave);
  };

  const scrollWindowToTop = () => {
    window.scrollTo(0, 0);
  };

  const resolveContentToDisplay = ({ pathname }) => {
    dispatch(setIsInitialAuth(false)); // set isInitialAuth to false if user is redirected from login page by pressing log in button
    return currentOperatorCode || pathname === PANEL_USER_SETTINGS ? (
      children
    ) : (
      <Navigate to={PANEL_USER_SETTINGS} state={{ from: location }} />
    );
  };

  useEffect(setRedirectUrl, [location.pathname]);
  useEffect(scrollWindowToTop, [location.pathname]);
  useEffect(() => {
    if (authStatus !== AUTH_STATUSES.success) localStorage.setItem("linkToRedirect", location.pathname);
  }, [authStatus]);

  if (needsVerification) {
    return <Navigate to={VERIFY_PATH} state={{ from: location }} />;
  }

  return authStatus === AUTH_STATUSES.success ? resolveContentToDisplay(location) : <Navigate to={ROOT_PATH} state={{ from: location }} />;
};

export { PrivateView };
