export const types = (value, typeToCompare) => {
  // eslint-disable-next-line valid-typeof
  if (typeof value === typeToCompare) return value;
  throw new TypeError(`Expected ${value} value to be ${typeToCompare} type`);
};

export const isFunction = func => {
  if (typeof func !== "function") {
    throw new Error("Expected argument to be a function.");
  }
  return func;
};

export const isObject = object => typeof object === "object" && object !== null && object !== [] && object.constructor === Object;
export const isString = string => typeof string === "string";
export const isNumber = number => {
  if (Number.isNaN(number)) {
    return false;
  }
  return typeof number === "number";
};
