import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Add from "@material-ui/icons/Add";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Paper } from "feature/panel/Settings/_shared/styledComponents";
import { AssetField } from "feature/panel/Settings/Branding/AssetField";
import { PrimaryButton } from "components/ui/Buttons";
import { PanelTemplate } from "components/templates/Panel/PanelTemplate";
import { BRANDING_LABELS } from "constants/content";
import {
  clearBrandingForm,
  getBrandingListStart,
  getDefaultBrandingDataStart,
  getSelectedBrandDataStart,
  setValueForCreationFooterStart,
  setValueForCreationHeaderStart,
  setValueForCreationLinkStart,
  setValueForDefaultCreationFooterStart,
  setValueForDefaultCreationHeaderStart,
  setValueForDefaultCreationLinkStart,
  setValueForDefaultLogoStart,
  setValueForLogoStart,
} from "store/branding/actions";
import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";

import {
  GET_SELECTED_BRANDING_DATA_START,
  GET_DEFAULT_BRANDING_DATA_START,
  SET_VALUE_FOR_CREATION_FOOTER_START,
  SET_VALUE_FOR_CREATION_HEADER_START,
  SET_VALUE_FOR_LOGO_START,
} from "store/branding/actionTypes";

import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

import { SelectBrandProfile } from "../../_shared/SelectBranding/SelectBrandProfile";
import { Navigation } from "../Navigation";
import { NewBrandingModal } from "./NewBrandingModal";
import { InformationField } from "../_shared/InformationField";
import { CreationLabels } from "./CreationLabels";

export const Content = styled.div`
  max-width: 960px;
  margin: auto;
  position: relative;
`;

const LoadingScreenWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => theme.colors.white}AA;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Branding = ({ hasPermission }) => {
  const permissionsService = usePermissionsService();
  const dispatch = useDispatch();

  const [createBrandingModal, setCreateBrandingModal] = useState(false);

  const canCreate = permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.operator, PERMISSIONS.resources.operators);
  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.operator, PERMISSIONS.resources.operators);

  const loadingDataActions = [
    GET_SELECTED_BRANDING_DATA_START,
    GET_DEFAULT_BRANDING_DATA_START,
    SET_VALUE_FOR_CREATION_HEADER_START,
    SET_VALUE_FOR_CREATION_FOOTER_START,
    SET_VALUE_FOR_LOGO_START,
  ];

  const brandList = useSelector(({ branding }) => branding?.brandingList || []);
  const isDataLoading = useSelector(({ branding }) => branding.inProgress && loadingDataActions.includes(branding.actionType));
  const {
    creationsHeader,
    creationsFooter,
    logo,
    id: editedBrandId,
    isDefault: isDefaultBrand,
    instantViewLabel,
    instantViewMapsLabel,
  } = useSelector(({ branding }) => branding?.brandingForm || {});

  const handleBrandSelect = ({ target }) => {
    const isBrandDefault = brandList.find(brand => brand.id === target.value)?.isDefault;

    if (target.value && isBrandDefault) {
      dispatch(getDefaultBrandingDataStart());
    } else if (target.value && !isBrandDefault) {
      dispatch(getSelectedBrandDataStart(target.value));
    } else {
      dispatch(clearBrandingForm());
    }
  };

  useEffect(() => {
    dispatch(getBrandingListStart());

    return () => {
      dispatch(clearBrandingForm());
    };
  }, []);

  const handleCreationHeaderChange = files => {
    if (isDefaultBrand) {
      dispatch(setValueForDefaultCreationHeaderStart(files));
    } else {
      dispatch(setValueForCreationHeaderStart(files));
    }
  };

  const handleCreationFooterChange = files => {
    if (isDefaultBrand) {
      dispatch(setValueForDefaultCreationFooterStart(files));
    } else {
      dispatch(setValueForCreationFooterStart(files));
    }
  };

  const handleLogoChange = files => {
    if (isDefaultBrand) {
      dispatch(setValueForDefaultLogoStart(files));
    } else {
      dispatch(setValueForLogoStart(files));
    }
  };

  const handleLinkChange = (value, linkType) => {
    if (isDefaultBrand) {
      dispatch(setValueForDefaultCreationLinkStart({ value, linkType }));
    } else {
      dispatch(setValueForCreationLinkStart({ value, linkType }));
    }
  };

  const contextBar = {
    left: () => null,
    middle: Navigation,
    right: () =>
      canCreate && (
        <PrimaryButton onClick={() => setCreateBrandingModal(true)}>
          <Add />
          {BRANDING_LABELS.createBrand}
        </PrimaryButton>
      ),
  };

  const loading = (
    <LoadingScreenWrapper>
      <LoadingScreen />
    </LoadingScreenWrapper>
  );

  return (
    <PanelTemplate contextBar={contextBar} hasPermission={hasPermission}>
      <Paper>
        <Content>
          {isDataLoading ? loading : null}

          <InformationField title={BRANDING_LABELS.brandingProfile}>
            <SelectBrandProfile brandingProfileId={editedBrandId} onBrandProfileChange={handleBrandSelect} />
          </InformationField>

          <AssetField
            title={BRANDING_LABELS.logo}
            file={logo?.file ? { ...logo?.file, name: logo.name } : null}
            onEditClick={handleLogoChange}
            height={98}
            showDescription={false}
            disabled={!canEdit}
          />

          <AssetField
            title={BRANDING_LABELS.creationHeader}
            file={creationsHeader?.file ? { ...creationsHeader?.file, name: creationsHeader.name } : null}
            onEditClick={handleCreationHeaderChange}
            height={116}
            disabled={!canEdit}
          />
          <AssetField
            title={BRANDING_LABELS.creationFooter}
            file={creationsFooter?.file ? { ...creationsFooter?.file, name: creationsFooter.name } : null}
            onEditClick={handleCreationFooterChange}
            height={98}
            disabled={!canEdit}
          />
          <CreationLabels onLinkChange={handleLinkChange} instantViewLabel={instantViewLabel} instantViewMapsLabel={instantViewMapsLabel} />

          <NewBrandingModal isOpen={createBrandingModal} onModalClose={() => setCreateBrandingModal(false)} />
        </Content>
      </Paper>
    </PanelTemplate>
  );
};

Branding.propTypes = {
  hasPermission: PropTypes.bool.isRequired,
};

export { Branding };
