import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import moment from "moment/moment";
import TableCell from "@material-ui/core/TableCell";
import { TableRow } from "components/ui/Tables";
import { TextLimiter } from "components/ui/TextLimiter";
import { LanguageContext } from "components/_shared/LanguageSelector/LanguageContext";
import { LocalisationService } from "services/LocalisationService";
import { calculateStartingPointValue, periods } from "../Itinerary/pages/Trips/Notifications/helpers";
import config from "../../../config/app";

const LocalisedTableCell = styled(TableCell)`
  && {
    ${({ $localised }) =>
      !$localised &&
      `
      opacity: 0.4;
    `}
  }
`;

const NotificationRowTemplate = ({ item }) => {
  const { currentLanguage } = useContext(LanguageContext);
  const localisationService = new LocalisationService(currentLanguage);

  const { type, id } = item;
  const content = localisationService.isPropertyLocalised(item, "content") ? localisationService.localise(item, "content") : item.content;
  const url = localisationService.isPropertyLocalised(item, "url") ? localisationService.localise(item, "url") : item.url;

  const isContentLocalised = localisationService.isPropertyLocalised(item, "content");
  const isUrlLocalised = localisationService.isPropertyLocalised(item, "url");

  const urlCell = type !== "forced" && (
    <LocalisedTableCell $localised={isUrlLocalised}>
      <TextLimiter text={url} maxChar={40} />
    </LocalisedTableCell>
  );

  const calculateNotificationPeriod = () => {
    const deliveryAtDays = item.delivery_at_days === 0 ? "" : Math.abs(item.delivery_at_days);
    const startingPointValue = calculateStartingPointValue(item.delivery_at_relative_to, item.delivery_at_days);
    const period = periods.find(p => p.value === startingPointValue).label;

    return item.delivery_at_days === 0 ? period : `${deliveryAtDays} ${period}`;
  };

  const formatDate = date => {
    return moment(date)
      .locale(navigator.language || navigator.languages[0])
      .format(config.momentLocaleInputDateFormat);
  };

  const renderCellsByType = () => ({
    timed: (
      <>
        <TableCell>{item.delivery_at_relative_to ? calculateNotificationPeriod() : ""}</TableCell>
        <TableCell>{item.start_at ?? ""}</TableCell>
      </>
    ),
    gps: (
      <>
        <TableCell>{item.start_at ? formatDate(item.start_at) : ""}</TableCell>
        <TableCell>{item.end_at ? formatDate(item.end_at) : ""}</TableCell>
        <TableCell>{item.latitude && item.longitude ? `${item.latitude}, ${item.longitude}` : ""}</TableCell>
      </>
    ),
  });

  return (
    <TableRow item={item}>
      <TableCell width="80px">{id}</TableCell>
      <LocalisedTableCell $localised={isContentLocalised}>
        <TextLimiter text={content} maxChar={50} />
      </LocalisedTableCell>
      {urlCell}
      {renderCellsByType()[item.type]}
    </TableRow>
  );
};

NotificationRowTemplate.propTypes = {
  item: PropTypes.shape().isRequired,
};

export { NotificationRowTemplate };
