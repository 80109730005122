// @ts-check

import { Feature } from "./Feature";

/**
 * @param {FeatureStayResponseDto} stayFeatureDto  feature stay object
 * @returns {FeatureStay} Feature stay item
 */

export function FeatureStay(stayFeatureDto) {
  /**
   * @type {FeatureStay}
   */
  const domain = {};

  domain.id = stayFeatureDto.id;
  domain.isActive = stayFeatureDto.is_active;
  domain.name = stayFeatureDto.name;
  domain.options = stayFeatureDto.options;
  domain.position = stayFeatureDto.position;
  domain.features = stayFeatureDto.features ? stayFeatureDto.features.map(featureItemDto => Feature(featureItemDto)) : [];
  domain.isTouched = false;

  return domain;
}
