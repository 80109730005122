import React from "react";
import { TableCell, TableRow } from "@mui/material";
import styled, { css, keyframes } from "styled-components";
import SpecificSettingsPlaceholder from "./specificSettingsPlaceholder";
import { TextLimiter } from "../../../../../../../components/ui/TextLimiter";

export const blinkAnimation = keyframes`
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
`;

export const Wrap = styled(TableRow)`
  && {
    width: 100%;
    cursor: ${({ item }) => (item?.isOverriden ? "pointer" : "default")};

    ${({ clickedRow, item }) =>
      clickedRow === item?.id &&
      css`
        animation: ${blinkAnimation} 0.5s ease-out 2;
      `};
  }
`;

export const CustomTableRow = ({ data, onClick, columns, clickedRow }) => {
  if (!data) return null;

  const isOverriden = data?.isOverriden;

  return (
    <>
      {isOverriden ? (
        <Wrap item={data} onClick={() => onClick && onClick(data.id)} style={{ opacity: 0.3 }}>
          <TableCell>{columns[0].renderCell(data)}</TableCell>
          <TableCell>
            <TextLimiter maxChar={35} text={data.name} />
          </TableCell>
          <TableCell colSpan={isOverriden ? 6 : 1}>
            <div style={{ display: "flex", alignItems: "center" }}>{isOverriden && <SpecificSettingsPlaceholder />}</div>
          </TableCell>
        </Wrap>
      ) : (
        <Wrap item={data} clickedRow={clickedRow} id={`row-${data.id}`}>
          {columns.map(item => (
            <TableCell key={item.id} align={item.align} style={{ padding: item.isAction && 0, width: item.isAction && 72 }}>
              {item.renderCell
                ? item.renderCell(data)
                : item.format && typeof data[item.id] === "number"
                ? item.format(data[item.id])
                : data[item.id]}
            </TableCell>
          ))}
        </Wrap>
      )}
    </>
  );
};

export default CustomTableRow;
