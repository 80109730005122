import React from "react";
import { styled } from "@mui/material";
import { colors } from "config/theme/colors";
import { ListItem } from "./ListItem";

const StyledListItem = styled(ListItem)`
  display: flex;
  align-items: center;
  border: 1px solid ${colors.grey10};
  background-color: #ffffff;
  border-radius: 0;
  margin: 0;
  box-shadow: none;
  border-bottom: none;

  &:last-child {
    border-bottom: 1px solid ${colors.grey10};
  }

  .MuiSvgIcon-root {
    color: #000000;
  }
`;

export const SortableListItem = ({ item, renderItem, index, ...props }) => {

  const ListItemComponent = props.refactored ? StyledListItem : ListItem;
  return (
    <ListItemComponent {...props}>
      {renderItem(item, index)}
    </ListItemComponent>
  )
}