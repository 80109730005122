import { responsiveConfig } from "config/responsive";
import { font } from "config/theme/fonts";
import { getMediaPx } from "helpers";
import styled from "styled-components";

export const Info = styled.div`
  width: 100%;
  padding: 0 20px;
  font-size: 22px;
  font-family: ${font};
  font-weight: 600;
  color: #fff;
  position: absolute;
  z-index: 3;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 20px;

  @media (max-width: 1200px) {
    padding: 35px;
  }
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    display: ${({ open }) => (open ? "block" : "none")};
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    padding: 0;
    justify-content: center;
  }
`;

export const Image = styled.div`
  width: 400px;
  height: 750px;
  border: 1px solid #C4C4C4;
  border-radius: 8px;
  margin-bottom: 25px;
  background-image: url("${({ bg }) => bg}");
  background-size: cover;
  background-position: center;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    background-color: ${({ bg }) => (bg ? "rgba(0, 0, 0, 0.1)" : "#fff")};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    
  }
  &::before {
    content: "App background image";
    display: ${p => (p.bg ? "none" : "block")} ;
    position: absolute;
    top: -8px;
    left: 16px;
    width: fit-content;
    height: 1em;
    color: black;
    z-index: 10;
    font-size: 9pt;
    background: white;
    padding: 0 5px;
    color: rgb(79, 79, 79);
    @media (max-width: ${p => getMediaPx(p, "md")}px) {
      display: none;
    }
  }
  @media (max-width: 1200px) {
    width: 100%;
    height: 400px;
  }
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    width: 100%;
    height: 100%;
    background-color: #fff;
    margin-bottom: 0;
  }
`;
