import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { Content } from "components/ui/Content/Content";
import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { Input } from "components/ui/Forms";

import { createNewBrandStart } from "store/branding/actions";

import { GLOBAL_CONTENT, BRANDING_LABELS, ERRORS } from "constants/content";
import { CREATE_NEW_BRAND_START } from "store/branding/actionTypes";

const NewBrandingModal = ({ isOpen, onModalClose }) => {
  const dispatch = useDispatch();

  const { brandingList, actionType, inProgress, finished } = useSelector(({ branding }) => branding);

  const [brandName, setBrandName] = useState("");
  const [isFormTouched, setIsFormTouched] = useState(false);
  const [errors, setErrors] = useState({});

  const handleSave = () => {
    if (brandName) {
      dispatch(createNewBrandStart({ name: brandName }));
    } else {
      setErrors({ name: ERRORS.cantBeBlank("Brand name") });
    }
  };

  const handleCancel = () => {
    onModalClose();
  };

  const handleChange = ({ target }) => {
    const isUnique = brandingList.findIndex(brand => brand.name.toLowerCase() === target.value.toLowerCase()) === -1;
    setBrandName(target.value);
    if (!isFormTouched) {
      setIsFormTouched(true);
    } else {
      if (brandName && isUnique && errors?.name) {
        setErrors({});
      }
      if (!brandName?.length) {
        setErrors({ name: ERRORS.cantBeBlank("Brand name") });
      }
      if (!isUnique) {
        setErrors({ name: ERRORS.isUnique("Brand name") });
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      setBrandName("");
      setIsFormTouched(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (actionType === CREATE_NEW_BRAND_START && finished) {
      onModalClose();
    }
  }, [actionType, finished]);

  return (
    <ConfirmationModal
      fullWidth
      title={BRANDING_LABELS.createBrandLabel}
      open={isOpen}
      maxWidth="sm"
      onConfirm={handleSave}
      onCancel={handleCancel}
      confirmLabel={GLOBAL_CONTENT.save}
      confirmDisabled={!!errors?.name}
      showSpinner={actionType === CREATE_NEW_BRAND_START && inProgress}
    >
      <Content>
        <Input
          name="name"
          label={GLOBAL_CONTENT.name}
          value={brandName}
          onChange={handleChange}
          error={!!errors.name}
          helperText={errors.name || ""}
          isRequired
        />
      </Content>
    </ConfirmationModal>
  );
};

NewBrandingModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
};

export { NewBrandingModal };
