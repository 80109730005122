import theme from "config/theme";
import styled from "styled-components";
import { fontSize } from "config/theme/fonts";

export const Wrap = styled("div")`
  padding: 15px;
  background-color: ${theme.colors.brand};
  color: #fff;
  font-size: 24px;
  cursor: pointer;
`;
