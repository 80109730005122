import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { FeatureOptionList } from "feature/panel/Stays/_shared/General/FeatureOptionList";

import { TitleText3 } from "components/ui/Typography/Typography";
import { useDispatch, useSelector } from "react-redux";
import { setStayWizardFeatures } from "store/stayWizard/actions";
import { LanguageContext } from "components/_shared/LanguageSelector/LanguageContext";

const FeatureRowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 60px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey10};
  &:last-of-type {
    border-bottom: 0;
  }
`;

const TitleArea = styled.div`
  margin-right: 12px;
  width: 17%;
  min-height: 60px;
  margin-bottom: auto;
  display: flex;
  align-items: center;

  p {
    max-width: 160px;
    color: ${({ theme }) => theme.colors.grey50};
  }
`;

const FeatureRow = ({ featureGroup, disabled }) => {
  const dispatch = useDispatch();

  const { currentLanguage } = useContext(LanguageContext);

  const isStayDefaultLanguage = useSelector(({ stayWizard }) =>
    stayWizard.defaultVariant.language ? stayWizard.defaultVariant.language === currentLanguage.code : true,
  );

  const features = useSelector(({ stayWizard }) => (isStayDefaultLanguage ? stayWizard.form.features : stayWizard.defaultVariant.features));

  const activeFeatures = featureGroup?.features?.filter(featureItem => featureItem.isActive);

  const handleToggleFeatureOption = option => {
    if (features.find(feature => feature.id === option.id)) {
      dispatch(setStayWizardFeatures(features.filter(feature => feature.id !== option.id)));
    } else {
      dispatch(setStayWizardFeatures([...features, option]));
    }
  };

  return (
    <FeatureRowWrapper>
      <TitleArea>
        <TitleText3>{featureGroup.name}</TitleText3>
      </TitleArea>
      <FeatureOptionList list={activeFeatures} onChange={option => handleToggleFeatureOption(option)} disabled={disabled} />
    </FeatureRowWrapper>
  );
};

FeatureRow.defaultProps = {
  disabled: false,
};

FeatureRow.propTypes = {
  featureGroup: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.string),
    features: PropTypes.arrayOf(
      PropTypes.shape({
        is_active: PropTypes.bool,
        name: PropTypes.string,
      }),
    ),
  }).isRequired,
  disabled: PropTypes.bool,
};

export { FeatureRow, FeatureRowWrapper, TitleArea };
