import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { ArrowBackIos } from "@material-ui/icons";

import { CUSTOM_APPS, GLOBAL_CONTENT, MESSAGING_CONTENT } from "constants/content";
import { firstLetterUpperCase } from "utils/string";

import presentationBg from "assets/images/ca_menu_bg.jpg";
import sendIcon from "assets/images/send.svg";

import { PhonePresentation, blurredBgImage, bgDimmed, PhoneHeader, LeftSlot, MiddleSlot, RightSlot } from "../PhonePresentation";
import { InputPreview, ButtonIconImg, MobileSendButton } from "../elementsAndColors/AccentColor/styledComponents";

const MessagingContent = styled.div`
  height: calc(100% - 91px);
  color: #ffffff;
  ${({ bgImage }) => blurredBgImage(bgImage)};
  ${({ theme }) => bgDimmed(theme)}
`;

const MessagingWrapper = styled.div`
  position: relative;
  z-index: 3;
  padding: 5px;
`;
const InputMessageContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.setSpacing(3)}px;
  text-align: left;
`;
const MobileButtonWrapper = styled.div`
  margin-top: ${({ theme }) => theme.setSpacing(1.5)}px;
`;
const StyledMobileSendButton = styled(MobileSendButton)`
  margin: 0 0 0 auto;
`;

const DayMonthHeader = styled.div`
  font-size: ${({ theme }) => theme.setSpacing(2.5)}px;
  margin-top: 10px;
`;
const SingleMessageBox = styled.div`
  width: 200px;
  margin: ${({ incomingMessage }) => (incomingMessage ? " 10px 0 0 auto" : " 10px auto 0 0 ")};
  text-align: left;
  background-color: ${({ incomingMessage, bgColor, theme }) => (incomingMessage ? bgColor : theme.colors.grey30)};
  padding: ${({ theme }) => theme.setSpacing(2)}px;
  border-radius: ${({ theme }) => theme.setSpacing(1.5)}px;
  font-size: ${({ theme }) => theme.setSpacing(3)}px;

  small {
    width: 100%;
    display: block;
    text-align: right;
  }
`;

const MessagesContainer = styled.div`
  margin: 5px;
`;

const BackButton = styled.div`
  && {
    font-size: 11px;
    color: ${({ color }) => color};

    .MuiSvgIcon-root {
      font-size: 12px;
      margin-bottom: -2px;
      margin-right: -3px;
    }
  }
`;

const MessagingPreview = () => {
  const [bgLoaded, setBgLoaded] = useState(false);

  const bgColor = useSelector(state => state.customAppForm.customAppForm.colors.defaultBackground);
  const accentColor = useSelector(state => state.customAppForm.customAppForm.colors.accentColor);

  const backgroundImage = new Image();
  backgroundImage.src = presentationBg;

  useEffect(() => {
    const handleBgLoad = () => {
      setBgLoaded(true);
    };

    backgroundImage.addEventListener("load", handleBgLoad);

    return () => {
      backgroundImage.removeEventListener("load", handleBgLoad);
    };
  }, [presentationBg]);

  const previewLoading = !!presentationBg && !bgLoaded;

  const backButton = (
    <BackButton color="#FFFFFF">
      <ArrowBackIos />
      {firstLetterUpperCase(GLOBAL_CONTENT.back)}
    </BackButton>
  );

  const preview = {
    label: MESSAGING_CONTENT.label,
    visible: true,
    fontColor: "#FFFFFF",
    leftSlot: backButton,
  };

  const messages = MESSAGING_CONTENT.messagess.map((message, index) => (
    <SingleMessageBox key={`message-no-${index}`} incomingMessage={message.user !== "me"} bgColor={accentColor}>
      {message.content}
      <small>{message.createdAt}</small>
    </SingleMessageBox>
  ));

  const inputMessage = (
    <InputMessageContainer>
      <InputPreview color={accentColor}>
        <span>{CUSTOM_APPS.elements.accentColor.inputLabel}</span>
      </InputPreview>
      <MobileButtonWrapper>
        <StyledMobileSendButton color={accentColor}>
          <ButtonIconImg src={sendIcon} />
        </StyledMobileSendButton>
      </MobileButtonWrapper>
    </InputMessageContainer>
  );
  return (
    <PhonePresentation>
      <>
        <MessagingContent bgImage={presentationBg}>
          <PhoneHeader bgColor={preview.bgColor} fontColor={preview.fontColor}>
            <LeftSlot>{preview.leftSlot}</LeftSlot>
            <MiddleSlot>{preview.label}</MiddleSlot>
            <RightSlot />
          </PhoneHeader>
          <MessagingWrapper>
            <DayMonthHeader>{CUSTOM_APPS.previews.messagingDate}</DayMonthHeader>
            {messages}
          </MessagingWrapper>
        </MessagingContent>
        {inputMessage}
      </>
    </PhonePresentation>
  );
};

export { MessagingPreview };
