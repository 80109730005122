import React from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { setButtonAttr } from "utils/styles/buttons";

const BaseButton = styled(({ ml, mr, iconButton, ...rest }) => <Button {...rest} />)`
  && {
    border-radius: ${({ theme }) => theme.borderRadius}px;
    background: ${({ theme, cv }) => theme.colors[cv] || theme.colors.brand};
    padding: ${({ size }) => setButtonAttr("padding", size)};
    font-size: ${({ size }) => setButtonAttr("font-size", size)};
    font-weight: ${({ theme }) => theme.fonts.fontWeight.semiBold};
    text-transform: uppercase;
    width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
    height: ${({ size }) => setButtonAttr("height", size)};
    letter-spacing: ${({ theme }) => theme.buttons.letterSpacing};

    margin-left: ${({ ml, theme }) => (ml ? theme.setSpacing(ml) : 0)}px;
    margin-right: ${({ mr, theme }) => (mr ? theme.setSpacing(mr) : 0)}px;

    ${({ size, iconButton }) => (iconButton ? `min-width: ${setButtonAttr("height", size)};` : null)} :hover {
      box-shadow: ${({ theme }) => theme.shadows.box};
    }

    .MuiSvgIcon-root {
      margin-right: ${({ theme, iconButton }) => (!iconButton ? theme.setSpacing(2) : 0)}px;
    }

    .MuiTouchRipple-rippleVisible {
      .MuiTouchRipple-child {
        color: ${({ theme, cv }) => theme.colors[cv] || theme.colors.brand};
      }
    }
  }
`;

export { BaseButton };
