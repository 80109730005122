import { responsiveConfig } from "config/responsive";
import { getMediaPx } from "helpers";
import React from "react";
import styled from "styled-components";

export const ResponsiveShowFrom = styled.div`
  display: none;
  @media (max-width: ${p => getMediaPx(p, p.size)}px) {
    display: block;
    ${p => p.customStyles};
  }
`;

export const ResponsiveHideFrom = styled.div`
  display: block;
  ${p => p.customStyles};
  @media (max-width: ${p => getMediaPx(p, p.size)}px) {
    display: none;
  }
`;

export const HideOnTablet = styled.div`
  display: ${p => p.display || "contents"};
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    display: none;
  }
`;

export const HideOnLaptop = styled.div`
  display: ${p => p.display || "contents"};
  @media (max-width: ${responsiveConfig.mediaPx.laptop}px) {
    display: none;
  }
`;

export const MobileOnly = styled.div`
  display: none;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    display: contents;
  }
`;

export const LaptopOnly = styled.div`
  display: none;
  @media (max-width: ${responsiveConfig.mediaPx.laptop}px) {
    display: ${({ display }) => display || "contents"};
  }
`;
