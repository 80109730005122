import SendIcon from "@mui/icons-material/Send";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import OutlinedButton from "components/_new/Buttons/OutlinedButton";
import Input from "components/ui/Inputs/TextInput";
import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { HttpClient } from "services/application/httpClient/httpClient";
import { encodeParameter } from "utils/url";
import { Grid } from "./style";

//

const Send = ({ currentChat, disabled }) => {
  const inputRef = useRef(null);
  const queryClient = useQueryClient();
  const { operator_code, reference_code } = useParams();
  const { control, handleSubmit, reset, watch, setValue } = useForm();

  const message = watch("message");

  const { mutate, isLoading } = useMutation(
    body => HttpClient.post(`/itinerary/${encodeParameter(operator_code)}/${encodeParameter(reference_code)}/messaging/${currentChat.conversation}`, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["chatList"] });
        queryClient.invalidateQueries({ queryKey: ["messages"] });
        reset({ message: "" });
      },
    },
  );

  const onKeyPress = e => {
    const isEnter = e.key === "Enter";

    if (isEnter && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(mutate)();
    }

    if (isEnter && e.shiftKey) {
      e.preventDefault();
      const textarea = inputRef.current;
      const cursorPos = textarea.selectionStart;

      setValue("message", message.substring(0, cursorPos) + "\n" + message.substring(cursorPos, message.length));

      setTimeout(() => {
        textarea.focus();
        textarea.setSelectionRange(cursorPos + 1, cursorPos + 1);
      }, 0);
    }
  };

  return (
    <Grid>
      <Input
        control={control}
        name="message"
        label="Put your text for new message here..."
        onKeyPress={onKeyPress}
        ref={inputRef}
        multiline
        disabled={disabled}
      />
      <OutlinedButton
        text="Send"
        startIcon={<SendIcon style={{ marginLeft: 3 }} />}
        // style={{ width: 48, height: 48 }}
        type="solid"
        onClick={handleSubmit(mutate)}
        isLoading={isLoading}
        disabled={!message || disabled}
        iconOnlyMobile
      />
    </Grid>
  );
};

export default Send;
