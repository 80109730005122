export const LS_TOKEN_KN = "authToken";
export const LS_LAST_VISITED_PAGE_KN = "lastVisitedPage";
export const LS_VISITED_PREV_PAGE_KN = "visitedPrevPage";
export const LS_TRIPS_SELECTED_COLUMNS = "TripsSelectedColumns";
export const LS_STAYS_SELECTED_COLUMNS = "staysSelectedColumns";
export const LS_ADMIN_OPERATORS_SELECTED_COLUMNS = "adminOperatorsSelectedColumns";
export const LS_ADMIN_USERS_SELECTED_COLUMNS = "adminUsersSelectedColumns";
export const LS_OPERATOR_CODE = "currentOperatorCode";
export const LS_ITEMS_ORIGINAL_INDEXES = "ItemsOriginalIndexes";
export const LS_OLD_ITEMS_ORIGINAL_INDEXES = "OldItemsOriginalIndexes";
export const LS_FORCE_UPDATE_LAST_STATE = "vamoosForceUpdateLastState";
export const LS_SEND_ONLY_TO_ACTIVE_LAST_STATE = "vamoosSendOnlyToActiveLastState";
export const LS_CURRENT_TAB_ID = "currentTabId"

export const LS_USER_EMAIL = "userEmail";
export const LS_AUTH_METHOD = "authMethod";
