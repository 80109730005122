import config from "config/app";

export const setTitleForDocumet = title => {
  if (title && typeof title === "string" && title.length > 0) return `${title} - ${config.name}`;
  return `${config.name}`;
};

export const defaultStateReducer = (initialState, additionalActions = () => {}) => (state, action) => {
  if (typeof additionalActions !== "function") return state;

  const { type, value, fieldName, payload } = action;

  const cases = {
    setValueFor: fieldName
      ? {
          ...state,
          [fieldName]: value,
        }
      : state,
    resetAllValues: initialState,
    setAllValues: {
      ...state,
      ...payload,
    },
    ...additionalActions(state, action),
  };

  if (Object.keys(cases).includes(type)) return cases[type];
  return state;
};
