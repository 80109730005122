import BaseModal from "components/_new/ModalBase";
import React from "react";

const FileExistModal = ({ onConfirm, onCancel }) => {
  return (
    <BaseModal
      title="There is already a file with that name. Do you want to replace this?"
      confirmTitle="Replace"
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  );
};

export default FileExistModal;
