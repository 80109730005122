import React from "react";
import PropTypes from "prop-types";
import { PersonItem } from "feature/panel/Trips/_shared/People/PersonItem";

const PeopleList = ({ people, onPersonChange, onPersonDelete, errors, disabled }) =>
  people.map((person, index) => (
    <PersonItem
      key={person.internal_id}
      person={person}
      index={index}
      onPersonChange={onPersonChange}
      onPersonDelete={onPersonDelete}
      errors={errors || {}}
      disabled={disabled}
      people={people}
    />
  ));

PeopleList.propTypes = {
  people: PropTypes.arrayOf(PropTypes.object).isRequired,
  onPersonChange: PropTypes.func.isRequired,
  onPersonDelete: PropTypes.func.isRequired,
  errors: PropTypes.shape().isRequired,
  disabled: PropTypes.bool.isRequired
};

export { PeopleList };
