import React, { useContext } from "react";
import PropTypes from "prop-types";

import { NotificationItem } from "feature/panel/_shared/Notifications/GpsNotificationForm/NotificationItem";
import { NoTemplatesMessage } from "feature/panel/_shared/Notifications/NoTemplatesMessage";
import { NOTIFICATION_TYPES } from "constants/content";
import { useQuery } from "@tanstack/react-query";
import { HttpClient } from "services/application/httpClient/httpClient";

const GpsNotificationForm = ({ context, disabled }) => {
  const { notifications } = useContext(context);

  const { data: allNotifications } = useQuery({
    queryKey: ["gpsNotifications"],
    queryFn: () => HttpClient.get("/notification/list/gps?&order=desc"),
  });

  const gpsNotificationsList = notifications.filter(notification => notification.type === NOTIFICATION_TYPES.gps);

  return gpsNotificationsList.length ? (
    gpsNotificationsList.map((notification, index) => (
      <NotificationItem
        disabled={disabled}
        allNotifications={allNotifications?.data?.items || []}
        key={notification.id}
        item={notification}
        index={index}
        context={context}
      />
    ))
  ) : (
    <NoTemplatesMessage notificationType="GPS" />
  );
};

GpsNotificationForm.defaultProps = {
  disabled: false,
};

GpsNotificationForm.propTypes = {
  context: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool,
};

export { GpsNotificationForm };
