import React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { ADMIN_OPERATORS_PATH, ADMIN_USERS_PATH, ADMIN_FEATURES_PATH, ADMIN_ICONS_PATH, ADMIN_CONNECT_PATH } from "constants/routes";
import { ADMIN_MAIN_NAVIGATION_LABELS } from "constants/content";

import { MainNavigationButton } from "components/ui/Buttons";

import { checkIsActiveUrl } from "utils/url";

const { accounts, settings } = ADMIN_MAIN_NAVIGATION_LABELS;

const MainNavigation = () => {
  const { pathname } = useLocation();

  const { currentOperatorCode } = useSelector(state => state.auth);

  return currentOperatorCode ? (
    <>
      <MainNavigationButton
        to={ADMIN_OPERATORS_PATH}
        isActive={
          checkIsActiveUrl(pathname, ADMIN_OPERATORS_PATH) ||
          checkIsActiveUrl(pathname, ADMIN_USERS_PATH) ||
          checkIsActiveUrl(pathname, ADMIN_CONNECT_PATH)
        }
      >
        {accounts}
      </MainNavigationButton>
      <MainNavigationButton
        to={ADMIN_FEATURES_PATH}
        isActive={checkIsActiveUrl(pathname, ADMIN_FEATURES_PATH) || checkIsActiveUrl(pathname, ADMIN_ICONS_PATH)}
      >
        {settings}
      </MainNavigationButton>
    </>
  ) : null;
};

export { MainNavigation };
