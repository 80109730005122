import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Spinner } from "components/ui/Spinner/Spinner";
import { Logo } from "components/ui/Logo/Logo";
import { Typography } from "@material-ui/core";
import { colors } from "config/theme/colors";

export const StyledWrapper = styled.div`
  width: inherit;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${({ theme }) => theme.setSpacing(8)}px 0;
`;

const LoadingScreen = ({ withLogo, text }) => (
  <StyledWrapper>
    <Spinner size={42} cv="brand" />
    {withLogo && <Logo />}
    <div style={{ fontSize: 14, color: colors.brand, fontWeight: 600, marginTop: 15 }}>{text}</div>
  </StyledWrapper>
);

LoadingScreen.defaultProps = {
  withLogo: false,
};

LoadingScreen.propTypes = {
  withLogo: PropTypes.bool,
};

export { LoadingScreen };
