export const GET_CURRENT_OPERATOR_DATA_START = "GET_CURRENT_OPERATOR_DATA_START";
export const GET_CURRENT_OPERATOR_DATA_SUCCESS = "GET_CURRENT_OPERATOR_DATA_SUCCESS";
export const GET_CURRENT_OPERATOR_DATA_FAIL = "GET_CURRENT_OPERATOR_DATA_FAIL";

export const UPDATE_COMPANY_DATA_START = "UPDATE_COMPANY_DATA_START";
export const UPDATE_COMPANY_DATA_SUCCESS = "UPDATE_COMPANY_DATA_SUCCESS";
export const UPDATE_COMPANY_DATA_FAIL = "UPDATE_COMPANY_DATA_FAIL";

export const GET_ICONS_LIST_START = "GET_ICONS_LIST_START";
export const GET_ICONS_LIST_SUCCESS = "GET_ICONS_LIST_SUCCESS";
export const GET_ICONS_LIST_FAIL = "GET_ICONS_LIST_FAIL";

export const SWITCH_COMPANY_POIS_LIST = "SWITCH_COMPANY_POIS_LIST";
export const CHANGE_COMPANY_TEMPERATURE_SCALE = "CHANGE_COMPANY_TEMPERATURE_SCALE";
export const CHANGE_COMPANY_REFACTOR_OPT_IN = "CHANGE_COMPANY_REFACTOR_OPT_IN";
export const CHANGE_COMPANY_STORYBOARD_VIEW = "CHANGE_COMPANY_STORYBOARD_VIEW";
export const CHANGE_COMPANY_NAME = "CHANGE_COMPANY_NAME";
export const CHANGE_COMPANY_CONTACT_PERSON = "CHANGE_COMPANY_CONTACT_PERSON";
export const CHANGE_COMPANY_TELEPHONE = "CHANGE_COMPANY_TELEPHONE";
export const CHANGE_COMPANY_EMAIL = "CHANGE_COMPANY_EMAIL";
export const CHANGE_COMPANY_NUMBER_OF_BOOKINGS = "CHANGE_COMPANY_NUMBER_OF_BOOKINGS";
export const CHANGE_COMPANY_NUMBER_OF_PASSENGERS = "CHANGE_COMPANY_NUMBER_OF_PASSENGERS";
export const CHANGE_COMPANY_DESCRIPTION = "CHANGE_COMPANY_DESCRIPTION";
export const CHANGE_COMPANY_WEBSITE = "CHANGE_COMPANY_WEBSITE";
export const CHANGE_COMPANY_LANGUAGE = "CHANGE_COMPANY_LANGUAGE";
export const CHANGE_COMPANY_ADDITIONAL_LANGUAGES = "CHANGE_COMPANY_ADDITIONAL_LANGUAGES";