import React, { useContext, useState } from "react";
import styled from "styled-components";
import * as PropTypes from "prop-types";
import { EditOutlined } from "@material-ui/icons";
import { useSelector } from "react-redux";

import { SecondaryButton } from "components/ui/Buttons";
import { AttachFilesButton } from "components/ui/Buttons/AttachFilesButton";
import { Input } from "components/ui/Forms";
import { LeadText } from "components/ui/Typography/Typography";
import { IconImage } from "components/ui/Icons";
import { ChipWithRedirect } from "components/ui/Chips/ChipWithRedirect";
import { Spinner } from "components/ui/Spinner/Spinner";
import { Message } from "components/ui/Messages/Message";

import { useActiveIcon } from "hooks/useActiveIcon";
import { useListOfLanguages } from "hooks/useListOfLanguages";

import { DOCUMENTS_FILE_TYPES } from "constants/defaults";
import { ERRORS } from "constants/content";

import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { LanguageContext } from "components/_shared/LanguageSelector/LanguageContext";

import { EditActionNameDialog } from "./EditActionNameDialog";
import { LanguageLock } from "../LanguageLock";

const ActionHeader = styled.div`
  display: flex;
  align-items: center;
`;

const ActionTitle = styled(LeadText)`
  && {
    line-height: ${({ theme }) => theme.setSpacing(8)}px;
    font-weight: ${({ theme }) => theme.fonts.fontWeight.default};
    color: ${({ theme }) => theme.colors.grey90};
    margin: ${({ theme }) => `0 ${theme.setSpacing(6)}px 0 ${theme.setSpacing(5)}px`};
  }
`;

const Action = styled.div`
  padding: ${({ theme }) => theme.setSpacing(10)}px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey10};

  :last-of-type {
    border-bottom: none;
  }
  :first-of-type {
    padding-top: 0;
  }
`;

const InputWrapper = styled.div`
  width: 320px;
  margin-right: ${({ theme }) => theme.setSpacing(6)}px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DocumentBackground = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: ${({ theme }) => theme.setSpacing(7)}px;
  padding: ${({ theme }) => theme.setSpacing(6)}px;
  background: ${({ theme }) => theme.colors.withOpacity(theme.colors.grey10, 0.3)};
`;

const IconEditButton = styled(SecondaryButton)`
  && {
    margin: ${({ theme }) => `0 ${theme.setSpacing(6)}px 0 0`};
  }
`;

function ActionListElement({ onChange, onUploadFile, onDeleteFile, onChangeIcon, label, title, file, context, iconId }) {
  const permissionsService = usePermissionsService();
  const allLanguages = useListOfLanguages();

  const { currentLanguage } = useContext(LanguageContext);

  const isStayDefaultLanguage = useSelector(({ stayWizard }) =>
    stayWizard.defaultVariant.language ? stayWizard.defaultVariant.language === currentLanguage.code : true,
  );

  const isWiped = useSelector(({ stayWizard }) => stayWizard.form.isWiped);
  const vamoosId = useSelector(({ stayWizard }) => stayWizard.form.vamoosId);
  const editMode = useSelector(({ stayWizard }) => stayWizard.form.vamoosId !== null);

  const defaultLanguageName = useSelector(({ stayWizard, operator }) => {
    if (stayWizard.defaultVariant.language) {
      return allLanguages.find(lang => lang.code === stayWizard.defaultVariant.language)?.name;
    }

    return allLanguages.find(lang => lang.code === operator.currentOperator.defaultLanguageCode)?.name;
  });

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoosId);

  const { availableIcons } = useSelector(state => state.operator);
  const stayWizardErrors = useSelector(({ stayWizard }) => stayWizard.errors);
  const fileIcon = useActiveIcon(file);

  const [editIconModal, setEditIconModal] = useState({ open: false, body: {} });

  const toggleModal = () => {
    setEditIconModal({
      open: true,
      body: {
        iconId,
      },
    });
  };

  const onModalValueChange = ({ target }) => {
    setEditIconModal({
      open: true,
      body: {
        ...editIconModal.body,
        iconId: target.value,
      },
    });
  };

  const onModalConfirm = () => {
    onChangeIcon(editIconModal.body.iconId);
    setEditIconModal({
      open: false,
      body: {
        iconId: null,
      },
    });
  };

  const onModalCancel = () => {
    setEditIconModal({
      open: false,
      body: {
        iconId: null,
      },
    });
  };

  const hasError = !!stayWizardErrors.actions?.[context];

  const selectedIcons = availableIcons.find(icon => icon.id === Number(iconId)) || {};

  const fileUploader = (
    <AttachFilesButton
      disabled={isWiped || (editMode && !canEdit)}
      onSelect={onUploadFile}
      name="action"
      size="small"
      allowedFileTypes={DOCUMENTS_FILE_TYPES}
      error={stayWizardErrors.actions?.[context]?.file}
    />
  );
  const fileDeleter = <ChipWithRedirect disabled={isWiped || (editMode && !canEdit)} onDelete={onDeleteFile} item={file} icon={fileIcon} />;

  return (
    <Action>
      <EditActionNameDialog
        onConfirm={onModalConfirm}
        onCancel={onModalCancel}
        onChange={onModalValueChange}
        open={editIconModal.open}
        iconId={editIconModal.body.iconId}
      />
      <ActionHeader>
        {iconId > 0 ? <IconImage src={selectedIcons.url} darkened /> : <Spinner size={28} />}
        <ActionTitle>{label}</ActionTitle>
        {!isWiped && !(editMode && !canEdit) && (
          <IconEditButton disabled={!isStayDefaultLanguage} onClick={toggleModal} cv="grey40" iconButton>
            <EditOutlined />
          </IconEditButton>
        )}
        {!isStayDefaultLanguage && <LanguageLock message={`The icon can only be changed in the main (${defaultLanguageName}) version`} />}
      </ActionHeader>
      <DocumentBackground>
        <InputWrapper>
          <Input
            size="small"
            value={title}
            onChange={onChange}
            label="Title"
            name={label}
            error={stayWizardErrors.actions?.[context]?.label}
            helperText={stayWizardErrors.actions?.[context]?.label}
            disabled={isWiped || (editMode && !canEdit)}
          />
        </InputWrapper>
        <ButtonWrapper>{file?.file_name || file?.web_url || file?.https_url ? fileDeleter : fileUploader}</ButtonWrapper>
      </DocumentBackground>
      {hasError && <Message text={ERRORS.invalidDocumentFile} type="error" margin={{ top: "24px" }} />}
    </Action>
  );
}

ActionListElement.defaultProps = {
  file: null,
  title: null,
};

ActionListElement.propTypes = {
  onChange: PropTypes.func.isRequired,
  onUploadFile: PropTypes.func.isRequired,
  onDeleteFile: PropTypes.func.isRequired,
  onChangeIcon: PropTypes.func.isRequired,
  title: PropTypes.string,
  label: PropTypes.string.isRequired,
  context: PropTypes.string.isRequired,
  iconId: PropTypes.number.isRequired,
  file: PropTypes.shape({
    file_name: PropTypes.string,
    web_url: PropTypes.string,
    https_url: PropTypes.string,
  }),
};

export { ActionListElement, ActionHeader, ActionTitle, Action, InputWrapper, DocumentBackground };
