import { isObject } from "contracts/types";
import { CREATION_AND_PUBLISHER_SECTION_TYPES } from "constants/defaults";
import { initialPublisher } from "store/stays/reducer";

export class PublisherService {
  /**
   * @param {object} publisherObject
   * @param {string} propertyName
   * @param {object} location
   * @returns {object}
   */
  static preparePublisherPayload(publisherObject, propertyName, location) {
    const sectionsOrder = ["cover", "toc", "notes_front", "directory", "map", "poi_list", "notes_end"];

    if (isObject(publisherObject)) {
      const { id, fileName } = publisherObject;

      const sections = this.generateSections(publisherObject, propertyName, location);

      sections.sort((a, b) => {
        const indexOfA = sectionsOrder.indexOf(a.type);
        const indexOfB = sectionsOrder.indexOf(b.type);

        const posA = indexOfA === -1 ? 100 : indexOfA;
        const posB = indexOfB === -1 ? 100 : indexOfB;

        if (posA > posB) {
          return 1;
        }
        if (posA < posB) {
          return -1;
        }
        return 0;
      });

      const data = {
        name: fileName || "",
        show_dates: false,
        sections,
      };

      if (id) data.id = id;

      return data;
    }
    return null;
  }

  /**
   * @param {object} publisherObject
   * @param {string} propertyName
   * @param {object} location
   * @returns {object[]}
   */
  static generateSections(publisherObject, propertyName, location) {
    const sections = [
      this.generateSectionCover(propertyName, publisherObject),
      this.generateTableOfContent(publisherObject),
      this.generateSectionFrontPage(publisherObject),
      this.generateSectionDirectories(publisherObject),
      ...this.generateSectionMaps(publisherObject),
      this.generateSectionPoiList(publisherObject, location),
      this.generateSectionLastPage(publisherObject),
    ];

    return sections.filter(section => section !== null);
  }

  /**
   * @param {object} publisher
   * @returns {object}
   */
  static setupExitingPublisher(publisher) {
    const {
      name,
      id,
      settings: { sections },
    } = publisher;

    const coverSection = sections.find(section => section.type === CREATION_AND_PUBLISHER_SECTION_TYPES.cover);
    const mapsSection = sections.filter(section => section.type === CREATION_AND_PUBLISHER_SECTION_TYPES.map);
    const notesEndSection = sections.find(section => section.type === CREATION_AND_PUBLISHER_SECTION_TYPES.notesEnd);
    const notesFrontSection = sections.find(section => section.type === CREATION_AND_PUBLISHER_SECTION_TYPES.notesFront);
    const directorySection = sections.find(section => section.type === CREATION_AND_PUBLISHER_SECTION_TYPES.directory);
    const poiListSection = sections.find(section => section.type === CREATION_AND_PUBLISHER_SECTION_TYPES.poiList);
    const tableOfContents = sections.find(section => section.type === CREATION_AND_PUBLISHER_SECTION_TYPES.tableOfContents);

    const prepareMapObject = ({ options, show_pois, headline }) => ({ ...options, show_pois, headline });

    return {
      id,
      fileName: name,
      tableOfContents: !!tableOfContents,
      map1: mapsSection[0] ? { ...prepareMapObject(mapsSection[0]), show: true } : initialPublisher.map1,
      map2: mapsSection[1] ? { ...prepareMapObject(mapsSection[1]), show: true } : initialPublisher.map2,
      map3: mapsSection[2] ? { ...prepareMapObject(mapsSection[2]), show: true } : initialPublisher.map3,
      directories: directorySection ? directorySection.nodes : [],
      areDirectoriesEnabled: directorySection !== undefined,
      cover: {
        show: coverSection !== undefined,
        imagesize: coverSection?.imagesize || "reduced",
        body: coverSection?.notes,
      },
      poiList: poiListSection ? { show: true, orderBy: poiListSection.order_by } : initialPublisher.poiList,
      lastPage: {
        show: !!notesEndSection,
        body: notesEndSection ? notesEndSection.content : "",
        headline: notesEndSection ? notesEndSection.headline : "",
      },
      contactDetailsLastPage: notesEndSection ? notesEndSection.show_contact_details : false,
      frontPage: {
        show: !!notesFrontSection,
        body: notesFrontSection ? notesFrontSection.content : "",
        headline: notesFrontSection ? notesFrontSection.headline : "",
      },
      contactDetailsFrontPage: notesFrontSection ? notesFrontSection.show_contact_details : false,
    };
  }

  /**
   * @param {string} propertyName
   * @param {object} coverData
   * @returns {object}
   */
  static generateSectionCover(propertyName, coverData) {
    const { cover: coverSection } = coverData;
    if (coverSection && coverSection.show) {
      return {
        type: CREATION_AND_PUBLISHER_SECTION_TYPES.cover,
        headline: propertyName,
        imagesize: coverSection.imagesize,
        notes: coverSection.body,
      };
    }
    return null;
  }

  /**
   * @param {object}
   * @returns {object}
   */
  static generateSectionFrontPage(frontPageData) {
    const { frontPage, contactDetailsFrontPage } = frontPageData;

    if (isObject(frontPage)) {
      const { headline, body, show } = frontPage;
      if (show) {
        return {
          type: CREATION_AND_PUBLISHER_SECTION_TYPES.notesFront,
          content: body,
          headline,
          show_contact_details: contactDetailsFrontPage,
        };
      }
      return null;
    }
    return null;
  }

  /**
   * @param {object} directoriesSectionData
   * @returns {object}
   */
  static generateSectionDirectories(directoriesSectionData) {
    const { directories } = directoriesSectionData;
    if (directories && directories.length) {
      return {
        type: CREATION_AND_PUBLISHER_SECTION_TYPES.directory,
        nodes: directories,
      };
    }
    return null;
  }

  /**
   * @param {object} tableOfContentsSectionData
   * @returns {object}
   */
  static generateTableOfContent(tableOfContentsSectionData) {
    const { tableOfContents } = tableOfContentsSectionData;
    return tableOfContents
      ? {
          type: CREATION_AND_PUBLISHER_SECTION_TYPES.tableOfContents,
        }
      : null;
  }

  /**
   * @param {object} mapsData
   * @returns {object}
   */
  static generateSectionMaps(mapsData) {
    const { map1, map2, map3 } = mapsData;
    const maps = [map1, map2, map3];
    return maps
      .map(mapObject => {
        if (isObject(mapObject) && mapObject.show) {
          const { show, latitude, longitude, zoom, show_labels, ...rest } = mapObject;
          return {
            type: CREATION_AND_PUBLISHER_SECTION_TYPES.map,
            options: {
              latitude,
              longitude,
              zoom,
            },
            ...rest,
          };
        }
        return null;
      })
      .filter(mapSection => mapSection !== null);
  }

  /**
   * @param {object} poisData
   * @param {object} location
   * @returns {object}
   */
  static generateSectionPoiList(poisData, location) {
    const { poiList: poiListObject } = poisData;
    if (isObject(poiListObject)) {
      const { show, orderBy } = poiListObject;
      if (show) {
        return {
          type: CREATION_AND_PUBLISHER_SECTION_TYPES.poiList,
          order_by: orderBy,
          location: {
            latitude: location.latitude,
            longitude: location.longitude,
          },
        };
      }
    }
    return null;
  }

  /**
   * @param {object} lastPageData
   * @returns {object}
   */
  static generateSectionLastPage(lastPageData) {
    const { lastPage, contactDetailsLastPage } = lastPageData;
    if (isObject(lastPage)) {
      const { headline, body, show } = lastPage;
      if (show) {
        return {
          type: CREATION_AND_PUBLISHER_SECTION_TYPES.notesEnd,
          content: body,
          headline,
          show_contact_details: contactDetailsLastPage,
        };
      }
      return null;
    }
    return null;
  }
}
