import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ArtTrackIcon from "@mui/icons-material/ArtTrack";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import TableAction from "components/_new/Table/Action";
import { colors } from "config/theme/colors";
import DeleteDirectoryModal from "feature/panel/Itinerary/components/DeleteDirectoryModal";
import { ResponsiveHideFrom, ResponsiveShowFrom } from "feature/panel/Itinerary/responsive";
import React, { useState } from "react";
import DirectoryModal from "../../../components/Modal/modal";
import { Buttons } from "../../Trips/Creations/MobileItem/style";
import { Buttons as ButtonsWrap, Name, Wrap } from "./style";

const Item = ({ item, index, directories, replace, update, remove, onDirSelect, onCustomUpdate, activeDir, onDelete }) => {
  const [showButtons, setShowButtons] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const toggleButtons = () => setShowButtons(!showButtons);
  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);

  return (
    <Wrap>
      {showDeleteModal && (
        <DeleteDirectoryModal type="directory" name={item.name} onConfirm={() => onDelete(item, index)} onCancel={toggleDeleteModal} />
      )}
      {isOpen && (
        <DirectoryModal
          item={item}
          onClose={() => setIsOpen(false)}
          directories={directories}
          replace={replace}
          update={update}
          index={index}
          onCustomUpdate={onCustomUpdate}
          type="directory"
          activeDir={activeDir}
        />
      )}

      <div style={{ display: "flex", justifyContent: "center" }}>{item.is_list ? <AccountTreeIcon /> : <ArtTrackIcon />}</div>
      <Name is_list={item.is_list} onClick={() => onDirSelect({ ...item, index })}>
        {item.name}
      </Name>
      <ResponsiveShowFrom size="md">
        <Buttons style={{ gap: 0, height: 50, top: 15, padding: 0, right: 15 }}>
          {showButtons && (
            <>
              <TableAction
                element={<EditOutlined />}
                onClick={() => setIsOpen(true)}
                wrapStyle={{ width: 48, height: 48, backgroundColor: "#fff", border: `1px solid ${colors.grey10}` }}
              />
              <TableAction
                element={<DeleteOutline />}
                onClick={() => remove(index)}
                wrapStyle={{ width: 48, height: 48, backgroundColor: "#fff", border: `1px solid ${colors.grey10}` }}
              />
            </>
          )}
          <TableAction
            element={<MoreHorizIcon />}
            onClick={toggleButtons}
            wrapStyle={{ width: 48, height: 48, backgroundColor: "#fff", border: `1px solid ${colors.grey10}` }}
          />
        </Buttons>
      </ResponsiveShowFrom>
      <ResponsiveHideFrom size="md">
        <ButtonsWrap>
          <TableAction element={<EditOutlined />} onClick={() => setIsOpen(true)} />
          <TableAction element={<DeleteOutline />} onClick={toggleDeleteModal} />
        </ButtonsWrap>
      </ResponsiveHideFrom>
    </Wrap>
  );
};

export default Item;
