import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { BodyText2 } from "components/ui/Typography/Typography";
import { TRIP_WIZARD_CONTENT } from "constants/content";
import { SortableListNext } from "components/ui/Lists/SortableListNext/SortableListNext";

import { EntriesContext } from "../EntriesContext";

const InfoSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
`;

const DayPositionLabel = styled.strong`
  white-space: nowrap;
`;

const NestedContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 8px;
  padding: 2px 0;
`;

const SortingEntriesDialogList = ({ list, onChangeOrder }) => {
  const { show_days, tripLength } = useContext(EntriesContext);
  const [grouped, setGrouped] = useState([]);

  const daysCount = Math.max(tripLength, Math.max(...list.map(({ meta }) => meta.day_number || 0)));

  const onListUpdate = ({ newIndex, item, to }) => {
    let sorted = grouped
      .filter(({ id }) => id !== item.id)
      .map(group => ({
        ...group,
        items: group.items ? group.items.filter(({ id }) => id !== item.id) : undefined,
      }));

    if (to === "root") {
      sorted = [
        ...sorted.slice(0, newIndex),
        show_days ? { ...item, meta: { ...item.meta, day_number: "" } } : item,
        ...sorted.slice(newIndex),
      ].map((frame, index) =>
        show_days
          ? {
              ...frame,
              meta: {
                ...frame.meta,
                day_number: index + 1,
              },
              items: frame.items?.map(subitem => ({ ...subitem, meta: { ...subitem.meta, day_number: index + 1 } })),
            }
          : frame,
      );
    } else {
      sorted = sorted.map(group => {
        if (group.id === to) {
          return {
            ...group,
            items: group.items
              ? [
                  ...group.items.slice(0, newIndex),
                  show_days ? { ...item, meta: { ...item.meta, day_number: group.meta.day_number } } : item,
                  ...group.items.slice(newIndex),
                ]
              : undefined,
          };
        }

        return group;
      });
    }

    sorted = sorted.map(frame => ({
      ...frame,
      items: frame.items?.map(subitem => ({ ...subitem, meta: { ...subitem.meta, day_number: frame.meta.day_number } })),
    }));

    setGrouped(sorted);

    const flat = sorted.reduce((newList, current) => {
      if (show_days) {
        return [...newList, ...current.items];
      }

      return [...newList, current];
    }, []);

    onChangeOrder(flat);
  };

  const formatDay = day => (day ? `${TRIP_WIZARD_CONTENT.storyboard.day} ${day}` : `${TRIP_WIZARD_CONTENT.storyboard.day} ?`);
  const formatPosition = pos => `#${pos}`;

  useEffect(() => {
    if (show_days) {
      setGrouped(
        Array.from(Array(daysCount).keys()).map(key => ({
          id: `day-${key + 1}`,
          items: list.filter(({ meta, position }) =>
            meta?.day_number ? meta?.day_number === key + 1 : key + 1 === position || (key + 1 === daysCount && position >= daysCount),
          ),
          headline: "",
          position: null,
          location: {
            name: "",
          },
          meta: {
            day_number: key + 1,
          },
        })),
      );
    } else {
      setGrouped([...list]);
    }
  }, []);

  return (
    list &&
    list.length > 0 && (
      <>
        <SortableListNext
          listId="root"
          items={grouped}
          onSort={onListUpdate}
          useDragHandle
          group={{
            name: "storyboard",
            put: [],
          }}
          renderItem={item => {
            if (show_days) {
              return (
                <NestedContainer>
                  <DayPositionLabel>{formatDay(item.meta?.day_number)}</DayPositionLabel>
                  <SortableListNext
                    listId={item.id}
                    items={item.items}
                    useDragHandle
                    group={{
                      name: "storyboard-day",
                      put: ["storyboard-day"],
                    }}
                    renderItem={subitem => {
                      return (
                        <InfoSection>
                          <BodyText2 component="span">{subitem.headline}</BodyText2>
                          {subitem.location && <BodyText2 component="span">{subitem.location ? subitem.location.name : ""}</BodyText2>}
                        </InfoSection>
                      );
                    }}
                  />
                </NestedContainer>
              );
            }
            return (
              <InfoSection>
                <BodyText2 component="span">
                  <DayPositionLabel>{show_days ? formatDay(item.meta?.day_number) : formatPosition(item.position)}</DayPositionLabel>
                </BodyText2>

                <BodyText2 component="span">{item.headline}</BodyText2>
                {item.location && <BodyText2 component="span">{item.location ? item.location.name : ""}</BodyText2>}
              </InfoSection>
            );
          }}
        />
      </>
    )
  );
};

SortingEntriesDialogList.propTypes = {
  onChangeOrder: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export { SortingEntriesDialogList };
