import styled from "styled-components";

export const Button = styled("button")``;

export const Wrap = styled("a")`
  margin-bottom: 5px;
  display: flex;

  strong {
    margin-right: 3px;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;
