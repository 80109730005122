export const GET_CUSTOM_APP_BY_ID_START = "GET_CUSTOM_APP_BY_ID_START";
export const GET_CUSTOM_APP_BY_ID_SUCCESS = "GET_CUSTOM_APP_BY_ID_SUCCESS";
export const GET_CUSTOM_APP_BY_ID_FAIL = "GET_CUSTOM_APP_BY_ID_FAIL";

export const SET_VALUE_FOR_FIELD = "SET_VALUE_FOR_FIELD";
export const SET_BUNDLE_ID_UNQUENESS_FLAG = "SET_BUNDLE_ID_UNQUENESS_FLAG";
export const SET_VALUE_FOR_ALL = "SET_VALUE_FOR_ALL";

export const CLEAR_CUSTOM_APP_FORM = "CLEAR_CUSTOM_APP_FORM";

export const CHECK_USER_ID_FOR_CUSTOM_APP_AVAILABILITY_START = "CHECK_USER_ID_FOR_CUSTOM_APP_AVAILABILITY_START";
export const CHECK_USER_ID_FOR_CUSTOM_APP_AVAILABILITY_FAIL = "CHECK_USER_ID_FOR_CUSTOM_APP_AVAILABILITY_FAIL";

export const SAVE_NEW_CUSTOM_APP_START = "SAVE_NEW_CUSTOM_APP_START";
export const SAVE_NEW_CUSTOM_APP_SUCCESS = "SAVE_NEW_CUSTOM_APP_SUCCESS";
export const SAVE_NEW_CUSTOM_APP_FAIL = "SAVE_NEW_CUSTOM_APP_FAIL";

export const SUBMIT_CUSTOM_APP_DEFINITION_FOR_REVIEW_START = "SUBMIT_CUSTOM_APP_DEFINITION_FOR_REVIEW_START";
export const SUBMIT_CUSTOM_APP_DEFINITION_FOR_REVIEW_SUCCESS = "SUBMIT_CUSTOM_APP_DEFINITION_FOR_REVIEW_SUCCESS";
export const SUBMIT_CUSTOM_APP_DEFINITION_FOR_REVIEW_FAIL = "SUBMIT_CUSTOM_APP_DEFINITION_FOR_REVIEW_FAIL";

export const REVISE_CUSTOM_APP_START = "REVISE_CUSTOM_APP_START";
export const REVISE_CUSTOM_APP_SUCCESS = "REVISE_CUSTOM_APP_SUCCESS";
export const REVISE_CUSTOM_APP_FAIL = "REVISE_CUSTOM_APP_FAIL";

export const UPDATE_CUSTOM_APP_DEFINITION_START = "UPDATE_CUSTOM_APP_DEFINITION_START";
export const UPDATE_CUSTOM_APP_DEFINITION_SUCCESS = "UPDATE_CUSTOM_APP_DEFINITION_SUCCESS";
export const UPDATE_CUSTOM_APP_DEFINITION_FAIL = "UPDATE_CUSTOM_APP_DEFINITION_FAIL";

export const DISMISS_CUSTOM_APP_START = "DISMISS_CUSTOM_APP_START";
export const DISMISS_CUSTOM_APP_SUCCESS = "DISMISS_CUSTOM_APP_SUCCESS";
export const DISMISS_CUSTOM_APP_FAIL = "DISMISS_CUSTOM_APP_FAIL";

export const GET_CUSTOM_APP_VERSIONS_LIST_START = "GET_CUSTOM_APP_VERSIONS_LIST_START";
export const GET_CUSTOM_APP_VERSIONS_LIST_SUCCESS = "GET_CUSTOM_APP_VERSIONS_LIST_SUCCESS";
export const GET_CUSTOM_APP_VERSIONS_LIST_FAIL = "GET_CUSTOM_APP_VERSIONS_LIST_FAIL";

export const CHECK_BUNDLE_ID_AVAILABLE_START = "CHECK_BUNDLE_ID_AVAILABLE_START";
export const CHECK_BUNDLE_ID_AVAILABLE_SUCCESS = "CHECK_BUNDLE_ID_AVAILABLE_SUCCESS";

export const SET_VALUE_FOR_APP_TITLE = "SET_VALUE_FOR_APP_TITLE";
export const SET_VALUE_FOR_APP_BUNDLE_ID = "SET_VALUE_FOR_APP_BUNDLE_ID";
export const SET_VALUE_FOR_APP_SEQUENCE_NUMBER = "SET_VALUE_FOR_APP_SEQUENCE_NUMBER";
export const SET_IS_FORM_TOUCHED = "SET_IS_FORM_TOUCHED";
export const SET_VALUE_FOR_APP_TYPE = "SET_VALUE_FOR_APP_TYPE";

export const SET_VALUE_FOR_APP_ACCENT_COLOR = "SET_VALUE_FOR_APP_ACCENT_COLOR";
export const SET_VALUE_FOR_APP_DEFAULT_BG_COLOR = "SET_VALUE_FOR_APP_DEFAULT_BG_COLOR";

export const SET_VALUE_FOR_APP_LOGIN_BG_COLOR = "SET_VALUE_FOR_APP_LOGIN_BG_COLOR";
export const SET_VALUE_FOR_APP_LOGIN_BORDER_COLOR = "SET_VALUE_FOR_APP_LOGIN_BORDER_COLOR";
export const SET_VALUE_FOR_APP_LOGIN_TEXT_COLOR = "SET_VALUE_FOR_APP_LOGIN_TEXT_COLOR";
export const SET_VALUE_FOR_APP_LOGIN_NAVIGATION_COLOR = "SET_VALUE_FOR_APP_LOGIN_NAVIGATION_COLOR";

export const SET_VALUE_FOR_APP_INPUT_FIELD_BG_COLOR = "SET_VALUE_FOR_APP_INPUT_FIELD_BG_COLOR";
export const SET_VALUE_FOR_APP_INPUT_FIELD_FRAME_COLOR = "SET_VALUE_FOR_APP_INPUT_FIELD_FRAME_COLOR";
export const SET_VALUE_FOR_APP_INPUT_TEXT_COLOR = "SET_VALUE_FOR_APP_INPUT_TEXT_COLOR";
export const SET_VALUE_FOR_APP_INPUT_HINT_COLOR = "SET_VALUE_FOR_APP_INPUT_HINT_COLOR";

export const SET_VALUE_FOR_APP_BUTTON_BG_ACTIVE_COLOR = "SET_VALUE_FOR_APP_BUTTON_BG_ACTIVE_COLOR";
export const SET_VALUE_FOR_APP_BUTTON_BG_INACTIVE_COLOR = "SET_VALUE_FOR_APP_BUTTON_BG_INACTIVE_COLOR";
export const SET_VALUE_FOR_APP_BUTTON_TEXT_ACTIVE_COLOR = "SET_VALUE_FOR_APP_BUTTON_TEXT_ACTIVE_COLOR";
export const SET_VALUE_FOR_APP_BUTTON_TEXT_INACTIVE_COLOR = "SET_VALUE_FOR_APP_BUTTON_TEXT_INACTIVE_COLOR";

export const SET_VALUE_FOR_APP_MENU_BG_COLOR = "SET_VALUE_FOR_APP_MENU_BG_COLOR";
export const SET_VALUE_FOR_APP_MENU_ICON_COLOR = "SET_VALUE_FOR_APP_MENU_ICON_COLOR";
export const SET_VALUE_FOR_APP_MENU_TEXT_COLOR = "SET_VALUE_FOR_APP_MENU_TEXT_COLOR";
export const SET_VALUE_FOR_APP_MENU_ITINERARY_BG_COLOR = "SET_VALUE_FOR_APP_MENU_ITINERARY_BG_COLOR";
export const SET_VALUE_FOR_APP_MENU_DIVIDER_COLOR = "SET_VALUE_FOR_APP_MENU_DIVIDER_COLOR";

export const SET_VALUE_FOR_APP_TEXTS_BG_COLOR = "SET_VALUE_FOR_APP_TEXTS_BG_COLOR";
export const SET_VALUE_FOR_APP_TEXTS_CONTENT_COLOR = "SET_VALUE_FOR_APP_TEXTS_CONTENT_COLOR";
export const SET_VALUE_FOR_APP_TEXTS_HEADLINE_COLOR = "SET_VALUE_FOR_APP_TEXTS_HEADLINE_COLOR";

export const SET_VALUE_FOR_APP_LOGIN_TEXT_1_STRING = "SET_VALUE_FOR_APP_LOGIN_TEXT_1_STRING";
export const SET_VALUE_FOR_APP_LOGIN_TEXT_1_COLOR = "SET_VALUE_FOR_APP_LOGIN_TEXT_1_COLOR";
export const SET_VALUE_FOR_APP_LOGIN_TEXT_2_STRING = "SET_VALUE_FOR_APP_LOGIN_TEXT_2_STRING";
export const SET_VALUE_FOR_APP_LOGIN_TEXT_2_COLOR = "SET_VALUE_FOR_APP_LOGIN_TEXT_2_COLOR";
export const SET_VALUE_FOR_APP_LOGIN_TEXT_3_STRING = "SET_VALUE_FOR_APP_LOGIN_TEXT_3_STRING";
export const SET_VALUE_FOR_APP_LOGIN_TEXT_3_COLOR = "SET_VALUE_FOR_APP_LOGIN_TEXT_3_COLOR";
export const SET_VALUE_FOR_APP_LOGIN_TEXT_4_STRING = "SET_VALUE_FOR_APP_LOGIN_TEXT_4_STRING";
export const SET_VALUE_FOR_APP_LOGIN_TEXT_4_COLOR = "SET_VALUE_FOR_APP_LOGIN_TEXT_4_COLOR";
export const SET_VALUE_FOR_APP_LOGIN_TEXT_5_STRING = "SET_VALUE_FOR_APP_LOGIN_TEXT_5_STRING";
export const SET_VALUE_FOR_APP_LOGIN_TEXT_5_COLOR = "SET_VALUE_FOR_APP_LOGIN_TEXT_5_COLOR";
export const SET_VALUE_FOR_APP_LOGIN_USER_ID_HINT_STRING = "SET_VALUE_FOR_APP_LOGIN_USER_ID_HINT_STRING";
export const SET_VALUE_FOR_APP_LOGIN_NEXT_STRING = "SET_VALUE_FOR_APP_LOGIN_NEXT_STRING";
export const SET_VALUE_FOR_APP_LOGIN_PASSCODE_HEADER_STAY_STRING = "SET_VALUE_FOR_APP_LOGIN_PASSCODE_HEADER_STAY_STRING";
export const SET_VALUE_FOR_APP_LOGIN_PASSCODE_HEADER_TRIP_STRING = "SET_VALUE_FOR_APP_LOGIN_PASSCODE_HEADER_TRIP_STRING";
export const SET_VALUE_FOR_APP_LOGIN_PASSCODE_HINT_STRING = "SET_VALUE_FOR_APP_LOGIN_PASSCODE_HINT_STRING";
export const SET_VALUE_FOR_APP_LOGIN_PASSCODE_SUBMIT_BUTTON_STRING = "SET_VALUE_FOR_APP_LOGIN_PASSCODE_SUBMIT_BUTTON_STRING";
export const SET_VALUE_FOR_APP_LOGIN_PASSCODE_SKIP_BUTTON_STRING = "SET_VALUE_FOR_APP_LOGIN_PASSCODE_SKIP_BUTTON_STRING";
export const SET_VALUE_FOR_APP_MENU_LOAD_NEW_STRING = "SET_VALUE_FOR_APP_MENU_LOAD_NEW_STRING";
export const SET_VALUE_FOR_APP_MENU_VIEW_ALL_STRING = "SET_VALUE_FOR_APP_MENU_VIEW_ALL_STRING";
export const SET_VALUE_FOR_APP_MENU_REFRESH_STRING = "SET_VALUE_FOR_APP_MENU_REFRESH_STRING";
export const SET_VALUE_FOR_APP_MENU_SETTINGS_STRING = "SET_VALUE_FOR_APP_MENU_SETTINGS_STRING";
export const SET_VALUE_FOR_APP_MENU_ABOUT_AS_STRING = "SET_VALUE_FOR_APP_MENU_ABOUT_AS_STRING";
export const SET_VALUE_FOR_APP_MENU_FAQ_STRING = "SET_VALUE_FOR_APP_MENU_FAQ_STRING";
export const SET_VALUE_FOR_APP_MENU_TERMS_STRING = "SET_VALUE_FOR_APP_MENU_TERMS_STRING";
export const SET_VALUE_FOR_APP_SETTING_TITLE_STRING = "SET_VALUE_FOR_APP_SETTING_TITLE_STRING";
export const SET_VALUE_FOR_APP_MY_ITINERART_TITLE_STRING = "SET_VALUE_FOR_APP_MY_ITINERART_TITLE_STRING";
export const SET_VALUE_FOR_APP_ABOUT_US_TITLE_STRING = "SET_VALUE_FOR_APP_ABOUT_US_TITLE_STRING";
export const SET_VALUE_FOR_APP_FAQ_TITLE_STRING = "SET_VALUE_FOR_APP_FAQ_TITLE_STRING";
export const SET_VALUE_FOR_APP_TERMS_TITLE_STRING = "SET_VALUE_FOR_APP_TERMS_TITLE_STRING";
export const SET_VALUE_FOR_APP_GALLERY_TITLE_STRING = "SET_VALUE_FOR_APP_GALLERY_TITLE_STRING";

export const SET_VALUE_FOR_APP_UPPERCASE_MENU_NAMES_SETTING = "SET_VALUE_FOR_APP_UPPERCASE_MENU_NAMES_SETTING";
export const SET_VALUE_FOR_APP_LOGIN_BORDERS_SETTING = "SET_VALUE_FOR_APP_LOGIN_BORDERS_SETTING";
export const SET_VALUE_FOR_APP_DEFAULT_STATUSBAR_STYLE_SETTING = "SET_VALUE_FOR_APP_DEFAULT_STATUSBAR_STYLE_SETTING";
export const SET_VALUE_FOR_APP_DEFAULT_USER_ID_SETTING = "SET_VALUE_FOR_APP_DEFAULT_USER_ID_SETTING";
export const SET_VALUE_FOR_APP_BUTTON_SQUARE_CORNERS_SETTING = "SET_VALUE_FOR_APP_BUTTON_SQUARE_CORNERS_SETTING";
export const SET_VALUE_FOR_APP_BUTTON_INPUT_FIELD_SQUARE_CORNERS_SETTING = "SET_VALUE_FOR_APP_BUTTON_INPUT_FIELD_SQUARE_CORNERS_SETTING";

export const SET_VALUE_FOR_APP_CUSTOM_FONT = "SET_VALUE_FOR_APP_CUSTOM_FONT";
export const SET_VALUE_FOR_APP_ICON_IMAGE = "SET_VALUE_FOR_APP_ICON_IMAGE";
export const SET_VALUE_FOR_APP_ICON_TRANSPARENT_IMAGE = "SET_VALUE_FOR_APP_ICON_TRANSPARENT_IMAGE";
export const SET_VALUE_FOR_APP_ICON_BACKGROUND_COLOR = "SET_VALUE_FOR_APP_ICON_BACKGROUND_COLOR";
export const SET_VALUE_FOR_APP_LOGO_IMAGE = "SET_VALUE_FOR_APP_LOGO_IMAGE";
export const SET_VALUE_FOR_APP_BACKGROUND_IMAGE = "SET_VALUE_FOR_APP_BACKGROUND_IMAGE";

export const SET_VALUE_FOR_APP_MENU_DOC_NAME = "SET_VALUE_FOR_APP_MENU_DOC_NAME";
export const SET_VALUE_FOR_APP_MENU_DOC_CONTENT = "SET_VALUE_FOR_APP_MENU_DOC_CONTENT";

export const SET_CUSTOM_APP_CURRENT_LANGUAGE = "SET_CUSTOM_APP_CURRENT_LANGUAGE";

export const SET_VALUE_FOR_APP_LOGIN_TEXT = "SET_VALUE_FOR_APP_LOGIN_TEXT";
export const SET_VALUE_FOR_APP_LOGIN_TEXT_COLOR_COMMON = "SET_VALUE_FOR_APP_LOGIN_TEXT_COLOR_COMMON";

export const SET_COLOR_FOR_APP = "SET_COLOR_FOR_APP";
export const SET_VALUE_FOR_APP_DEFAULT_LANGUAGE = "SET_VALUE_FOR_APP_DEFAULT_LANGUAGE";
export const SET_VALUE_FOR_APP_LANGUAGES = "SET_VALUE_FOR_APP_LANGUAGES";
