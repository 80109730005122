import { getMediaPx } from "helpers";
import styled from "styled-components";

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 80px;
  gap: 15px;
  align-items: center;
  justify-content: center;
  padding-left: 15px;

  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    grid-template-columns: 1fr;
    padding: 15px;
  }
`;
