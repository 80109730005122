import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Editor } from "@tinymce/tinymce-react";
import config from "config/app";
import { debounce } from "lodash";

const TermsAndCondEditorWrapper = styled.div`
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.grey40};
  border-radius: 8px;

  & > .tox-tinymce {
    border-radius: ${({ theme, adornment }) =>
      adornment ? `${theme.setSpacing(2)}px ${theme.setSpacing(2)}px 0 0` : `${theme.setSpacing(2)}px`} !important;
    border: none !important;
    min-height: ${({ adornment }) => (adornment ? 260 : 460)}px;

    .tox-tbtn {
      margin-left: 1px;
      margin-right: 1px;
    }
  }
`;

const HelperTextContent = styled.small`
  color: ${({ theme, error }) => (error ? theme.colors.error : theme.colors.grey40)};
`;

const CustomAppEditor = ({ value, onEditorContentChange, bottomAdornment, error, helperText, disabled, forceChange }) => {
  const [initEditor, setInitEditorFlag] = useState(false);
  const [content, setContent] = useState("");
  const editorChangeRef = useRef(debounce(editoContent => onEditorContentChange(editoContent), 200));

  const onChange = currentValue => {
    setContent(currentValue);
  };

  const init = () => {
    if (forceChange) {
      setContent(value);
    } else {
      if (content === "" && value !== "") {
        setContent(value);
      }
    }
  };

  const contentChange = () => {
    if (value !== content && initEditor) {
      editorChangeRef.current(content);
    }
    if (!initEditor) {
      setInitEditorFlag(true);
    }
  };

  useEffect(init, [value]);
  useEffect(contentChange, [content]);

  return (
    <>
      <TermsAndCondEditorWrapper adornment={!!bottomAdornment}>
        <Editor
          apiKey={config.vendors.TinyMCE.API_KEY}
          tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
          value={content}
          init={{
            height: 230,
            menubar: false,
            force_br_newlines: true,
            force_p_newlines: false,
            forced_root_block: "",
            block_formats: "Paragraph=p; Header=h3",
            plugins: [
              "advlist autolink lists link image charmap print preview anchor nonbreaking",
              "searchreplace visualblocks",
              "insertdatetime media table paste code help wordcount",
            ],
            toolbar: "formatselect | bold italic underline | link ",
            setup: (editor) => {
              editor.on('focus', (e) => {
                e.target.editorContainer.classList.toggle('focused');
              });
          
              editor.on('blur', (e) => {
                e.target.editorContainer.classList.toggle('focused');
              });
            }
          }}
          onEditorChange={val => onChange(val)}
          disabled={disabled}
        />
        {bottomAdornment && bottomAdornment}
      </TermsAndCondEditorWrapper>
      {helperText && <HelperTextContent error={error}>{helperText}</HelperTextContent>}
    </>
  );
};

CustomAppEditor.defaultProps = {
  bottomAdornment: null,
  error: false,
  disabled: false,
  helperText: "",
};

CustomAppEditor.propTypes = {
  value: PropTypes.string.isRequired,
  onEditorContentChange: PropTypes.func.isRequired,
  bottomAdornment: PropTypes.element,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
};

export { CustomAppEditor };
