import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";

import { GeneralInfoForm } from "feature/panel/Trips/_shared/General/GeneralInfoForm";
import { MobilePreview } from "feature/panel/_shared/MobilePreview/MobilePreview";
import { ManageTripContext, useManageTripContext } from "feature/panel/Trips/_shared/ManageTripContext";
import { CustomLogo } from "feature/panel/_shared/CustomLogo/CustomLogo";
import { InstantView } from "feature/panel/Trips/_shared/General/InstantView";

import { FormSection } from "components/ui/Forms/FormSection";
import { Message } from "components/ui/Messages/Message";
import { H5 } from "components/ui/Typography/Typography";

import { PANEL_TRIPS_ACTION_MESSAGES } from "constants/content";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

const Line = styled.div`
  text-align: center;
  width: 100%;
  height: auto;
  z-index: 1;
`;

const StyledH5 = styled(H5)`
  white-space: pre-wrap;
`;

const General = () => {
  const permissionsService = usePermissionsService();
  const [showArchivedInfo, setShowArchivedInfo] = useState(true);
  const {
    is_active,
    logo,
    setValueFor,
    editMode,
    storyboard,
    is_wiped,
    background,
    field1,
    field2,
    field3,
    field4,
    vamoos_id,
  } = useManageTripContext();

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoos_id);
  const canCreate = permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.vamoosList, vamoos_id);

  const isInputEnabled = editMode ? !is_wiped && canEdit : canCreate;

  const toggleMessageShow = () => setShowArchivedInfo(!showArchivedInfo);

  const setBackground = newBackground => setValueFor("background", newBackground);

  const archivedMessage = !is_active && showArchivedInfo && !is_wiped && canEdit && (
    <Message text={PANEL_TRIPS_ACTION_MESSAGES.archivedInformation} type="error" onDelete={toggleMessageShow} />
  );

  const shouldShowInstantViewSection = editMode && storyboard.length > 0;

  const colorVariant = background ? "white" : "grey50";

  return (
    <Grid container justifyContent="center">
      {archivedMessage}
      <Grid item xs={12} md={10}>
        <FormSection title="General information">
          <Grid container justifyContent="space-between" spacing={10}>
            <Grid item xs={12} sm={12} md={6}>
              <GeneralInfoForm />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <MobilePreview
                context={ManageTripContext}
                background={background}
                disabled={!isInputEnabled}
                onBackgroundChange={setBackground}
              >
                <Line>
                  <StyledH5 cv={colorVariant}>{`${field1} ${field2}`}</StyledH5>
                </Line>
                <Line>
                  <StyledH5 cv={colorVariant}>{`${field3} ${field4}`}</StyledH5>
                </Line>
              </MobilePreview>
            </Grid>
          </Grid>
        </FormSection>

        <FormSection title="Overlay logo" divider="top">
          <CustomLogo logo={logo || {}} contextValueSetter={setValueFor} disabled={!isInputEnabled} />
        </FormSection>

        {shouldShowInstantViewSection && !is_wiped && (
          <FormSection title="Instant view" divider="top">
            <InstantView />
          </FormSection>
        )}
      </Grid>
    </Grid>
  );
};

export { General };
