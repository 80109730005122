import React from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { FixedContent } from "components/ui/Content";
import { BackgroundCover, BackgroundImage } from "components/ui/Backgrounds/Backgrounds";
import { FullHeightColumn } from "components/ui/Grid/Grid";
import { Navigation } from "components/public/Navigation/Navigation";

const DefaultPageRightSection = ({ backgroundImage, hideNavigation, children }) => (
  <Grid item lg={5}>
    <FixedContent>
      <BackgroundImage background={backgroundImage}>
        <BackgroundCover opacity="0.25">
          <FullHeightColumn>
            <Grid container alignContent="space-between" justifyContent="center">
              { !hideNavigation ? (
                <Grid item md={12} lg={10} xl={9}>
                  <Navigation />
                </Grid>
              ) : null }
              {children}
            </Grid>
          </FullHeightColumn>
        </BackgroundCover>
      </BackgroundImage>
    </FixedContent>
  </Grid>
);

DefaultPageRightSection.defaultProps = {
  children: null,
  hideNavigation: false,
};

DefaultPageRightSection.propTypes = {
  children: PropTypes.node,
  backgroundImage: PropTypes.string.isRequired,
  hideNavigation: PropTypes.bool,
};

export { DefaultPageRightSection };
