import BaseModal from "components/_new/ModalBase";
import React from "react";

const ConfirmDeleteModal = ({ onConfirm, onCancel }) => {
  return (
    <BaseModal
      title={
        <>
          You’ve removed the location pn from your storyboard. <br /> Do you want to permanently remove it from the trip?
        </>
      }
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  );
};

export default ConfirmDeleteModal;
