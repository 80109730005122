import { useReducer } from "react";
import { defaultStateReducer } from "utils/hooks";

const useDefaultReducer = (initialState, additionalActions) => {
  const createStateReducer = defaultStateReducer(initialState, additionalActions);
  const [state, dispatch] = useReducer(createStateReducer, initialState);

  const setValueFor = (fieldName, value) => {
    dispatch({
      type: "setValueFor",
      fieldName,
      value,
    });
  };

  const setAllValues = payload => {
    dispatch({
      type: "setAllValues",
      payload,
    });
  };

  return { state, dispatch, setValueFor, setAllValues };
};

export { useDefaultReducer };
