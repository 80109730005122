import React from "react";
import PropTypes from "prop-types";
import { InsertPhotoOutlined } from "@material-ui/icons";

import { formatDisplayableLibraryPopupValues, getFilePreviewUrl } from "utils/library";
import { AttachFilesButton } from "components/ui/Buttons/AttachFilesButton";
import { ChipWithRedirect } from "components/ui/Chips/ChipWithRedirect";
import { ImagePreview, ImagePlaceholder } from "components/ui/Images";

import { RemoveButtonBin } from "feature/panel/_shared/Sortable/StyledComponents/shared";
import {
  StyledSortableListElement,
  ContentArea,
  DescArea,
  ImageArea,
  RemoveButtonContainer,
  ErrorMessageContainer,
} from "feature/panel/_shared/Sortable/StyledComponents/sortableListFormImagesListItem";
import { IMAGES_MIME_TYPES } from "constants/defaults";
import { DEFAULT_LIBRARY_TABS } from "components/ui/LibraryPopup/LibraryPopup";
import { ERRORS } from "../../../../constants/content";
import { TextInputEditor } from "../../Settings/CustomApps/_shared/LoginScreen/TextInputEditor";

const SortableListFormImagesListItem = ({ index, item, onUpdate, onDelete, disabled, errorImgId }) => {
  const { id, description, image } = item;
  const handleAttachFile = files => {
    const [newImage] = formatDisplayableLibraryPopupValues(files);
    onUpdate(id, { ...item, image: newImage });
  };

  const handelDeleteImageFile = () => {
    onUpdate(id, { ...item, image: null });
  };

  const handleChangeDesc = value => onUpdate(id, { ...item, description: value });

  const handleRemoveImage = () => onDelete(id);

  const [width, height] = [175, 150];

  const renderFileDeleter = () => (
    <ChipWithRedirect onDelete={handelDeleteImageFile} item={image} icon={<InsertPhotoOutlined />} maxChar={15} editable={!disabled} />
  );

  const renderFileUploader = () => (
    <AttachFilesButton
      size="small"
      name={`attached-file-${index}`}
      onSelect={handleAttachFile}
      allowedFileTypes={IMAGES_MIME_TYPES}
      tabs={Object.values(DEFAULT_LIBRARY_TABS).splice(0, 2)}
      disabled={disabled}
    />
  );

  return (
    <StyledSortableListElement
      index={index}
      item={item}
      style={{ width: "100%", flexShrink: 0, flexGrow: 0, padding: 4 }}
      disabled={disabled}
      withShadow
    >
      <ContentArea>
        <DescArea alignCenter={!image}>
          <TextInputEditor
            label="Image description"
            name={`image-desc-${index}`}
            value={description}
            onChange={val => handleChangeDesc(val)}
            disabled={disabled}
            toolbar={false}
            index={index}
            maxHeight={height * 2}
            paste_as_text
          />
        </DescArea>
        <ImageArea>
          {image && image.file_name ? (
            <ImagePreview image={getFilePreviewUrl(image)} width={`${width}px`} height={`${height}px`} />
          ) : (
            <>
              <ImagePlaceholder width={`${width}px`} height={`${height}px`} iconSize={48} hasError={!!errorImgId} />
              {errorImgId && <ErrorMessageContainer>{ERRORS.isRequired("Image")}</ErrorMessageContainer>}
            </>
          )}
          {image && image.file_name ? renderFileDeleter() : renderFileUploader()}
        </ImageArea>
      </ContentArea>
      <RemoveButtonContainer>{!disabled && <RemoveButtonBin onClick={handleRemoveImage} />}</RemoveButtonContainer>
    </StyledSortableListElement>
  );
};

SortableListFormImagesListItem.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    image: PropTypes.shape({
      file_name: PropTypes.string,
    }),
    description: PropTypes.string,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export { SortableListFormImagesListItem };
