import styled from "styled-components";
import { SortableListElement } from "components/_shared/SortableListElement/SortableListElement";

const StyledSortableListElement = styled(SortableListElement)`
  && {
    position: relative;
    height: auto;
    min-height: 80px;
  }
`;

const ContentArea = styled.div`
  width: calc(100% - 72px);
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
`;

const DescArea = styled.div`
  height: 100%;
  display: flex;
  width: calc(100% - 196px);
  flex-direction: column;
`;

const ImageArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 24px;
  align-items: center;
  position: relative;
`;

const ErrorMessageContainer = styled.div`
  position: absolute;
  bottom: 40px;
  font-size: 12px;
  line-height: 16px;
  color: #f44336;
`;

const RemoveButtonContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 12px;
`;

export { StyledSortableListElement, ContentArea, DescArea, ImageArea, ErrorMessageContainer, RemoveButtonContainer };
