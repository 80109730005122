import { DeleteOutlined, FilterListOutlined } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import uuidv4 from "uuid";

import FloatingAction from "components/_new/FloatingAction";
import { PanelTemplate } from "components/templates/Panel/PanelTemplate";
import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";
import { NoResultsMessage } from "components/ui/Messages/NoResultsMessage";
import VamoosListMenu from "feature/panel/Itinerary/components/TopSecondMenu/menus/vamoosList";
import { Actions } from "feature/panel/Trips/Index/Actions";
import { VamoosListNavigation } from "feature/panel/_shared/Navigations/VamoosListNavigation";
import { SearchField } from "feature/panel/_shared/SearchField/SearchField";

import { useTableHandlers } from "hooks/useTableHandlers";

import { pushErrorNotification, toggleTripRedirectChecked } from "store/app/actions";
import { CREATE_OR_UPDATE_TRIP_SUCCESS, TRIPS_GET_TRIPS_SUCCESS } from "store/trips/actionTypes";
import { getTripsStart } from "store/trips/actions";

import { GET_ITINERARIES_URL } from "constants/api";
import {
  CREATE_CONTENT_LABELS,
  EMPTY_LIST_MESSAGES_BASE,
  NAMES_OF_RESOURCES_LISTS,
  NO_RESULTS_FOUND_MESSAGE,
  TABLE_NAMES,
} from "constants/content";
import { DEFAULT_DEBOUNCE_DELAY } from "constants/defaults";
import { debounce } from "lodash";

import notifications from "constants/notifications";
import { PERMISSIONS } from "constants/permissions";
import { PANEL_STAYS_PATH } from "constants/routes";
import TopSecondMenu from "feature/panel/Itinerary/components/TopSecondMenu";
import { usePermissionsService } from "hooks/usePermissionsService";
import { use100vh } from "react-div-100vh";
import { StayService } from "services/domain/StayService";
import { getCurrentOperatorStart } from "store/operator/actions";
import { checkQueryParamExistence } from "utils/url";
import OutlinedButton from "../../../../components/_new/Buttons/OutlinedButton";
import Table from "../../../../components/_new/Table";
import filterFields from "../../../../constants/filterFields.json";
import { LS_TRIPS_SELECTED_COLUMNS } from "../../../../constants/localstorage";
import { useColumnSelector } from "../../../../hooks/useColumnSelector";
import { useService } from "../../../../hooks/useService";
import { Logger } from "../../../../services/application/Logger";
import { deviceType } from "../../../../utils/deviceDetect";
import { getRequiredData } from "../../../../utils/saveTableSettings";
import ColumnSelectorModal from "../../Itinerary/columnSelectorModal";
import CopyModal from "../../Itinerary/copyModal";
import { ResponsiveHideFrom, ResponsiveShowFrom } from "../../Itinerary/responsive";
import { fetchStays } from "../../Stays/Index/helpers";
import BulkActions from "./BulkActions";
import CustomTableRow from "./CustomRow";
import Filter from "./Filter";
import MobileFilterModal from "./Filter/MobileFilterModal";
import MobileItem from "./MobileItem";
import MobileItemHeader from "./MobileItem/MobileItemHeader";
import initialColumns from "./TableOfTripsColumns.json";
import { generateColumns } from "./helpers";
import { ActionItemsContainer } from "./style";

const TripsIndex = () => {
  const height100vh = use100vh();
  const [searchValue, setSearchValue] = useState("");
  const [showArchived, setShowArchived] = useState(false);
  const [staysCount, setStaysCount] = useState(0);
  const [staysLoading, setStaysLoading] = useState(false);
  const [openCopyTripModal, setOpenCopyTripModal] = useState(false);
  const [activeTrip, setActiveTrip] = useState(null);
  const [openColumnSelectorModal, setOpenColumnSelectorModal] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [selectedTrips, setSelectedTrips] = useState({ allItemsSelected: false, vamoosIds: [] });
  const [checkedOnCurrentPage, setCheckedOnCurrentPage] = useState([]);

  const permissionsService = usePermissionsService();

  const { listOfTrips, inProgress, finished, tripsCount, actionType } = useSelector(state => state.trips);
  const { currentOperatorCode } = useSelector(state => state.auth);
  const { tripRedirectChecked } = useSelector(state => state.app);
  const { currentOperator, isFetchingCurrentOperator } = useSelector(state => state.operator);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const device = deviceType();
  const { storedColumns: storedTripsColumns, handleSaveColumns } = useColumnSelector(
    LS_TRIPS_SELECTED_COLUMNS,
    initialColumns,
    device,
    listOfTrips,
  );
  const staysService = useService(StayService);
  const form = useForm({
    defaultValues: {
      filters: [
        {
          id: uuidv4(),
          column: Object.keys(filterFields[TABLE_NAMES.trip])[0],
          operator: Object.values(filterFields[TABLE_NAMES.trip])[0]?.operators?.value,
          userInputValue: undefined,
        },
      ],
    },
  });

  const { tables } = getRequiredData(TABLE_NAMES.trip);
  const defaultOrder = tables?.[device]?.[TABLE_NAMES.trip]?.ordering?.order || "desc";
  const defaultOrderBy = tables?.[device]?.[TABLE_NAMES.trip]?.ordering?.column || "updated_at";

  const tableHandlers = useTableHandlers(GET_ITINERARIES_URL, getTripsStart, {
    params: { type: TABLE_NAMES.trip },
    customSearchParam: "search",
    defaultOrderBy,
    defaultOrder,
  });

  const {
    handleSearch,
    handleChangeArchived,
    forceQuery,
    url,
    handleChangeParams,
    handleChangePage,
    page,
    clearAllCheckboxSelections,
    setClearAllCheckboxSelections,
    refactoredHandleChangeOrder,
  } = tableHandlers;

  const staysTableHandlers = useTableHandlers();

  const canCreate = permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.vamoosList, PERMISSIONS.resources.default);

  const handleToggleShowArchived = () => {
    if (page !== 1) handleChangePage(0);
    setSelectedTrips({ allItemsSelected: false, vamoosIds: [] });
    setCheckedOnCurrentPage([]);
    setShowArchived(!showArchived);
    handleChangeArchived(!showArchived ? "only" : "off");
  };

  const refreshTripsListAfterRestoreTrip = () => {
    if (finished && actionType === CREATE_OR_UPDATE_TRIP_SUCCESS) {
      forceQuery();
    }
  };

  const tryRedirectToStaysList = () => {
    if (actionType === TRIPS_GET_TRIPS_SUCCESS && !tripRedirectChecked && !staysLoading) {
      dispatch(toggleTripRedirectChecked());
      if (!listOfTrips.length && staysCount > 0) {
        navigate(PANEL_STAYS_PATH);
      }
    }
  };

  const tripCopyHandler = trip => {
    setActiveTrip(trip);
    setOpenCopyTripModal(!openCopyTripModal);
  };

  const updateCheckboxSelectionForCurrentPage = () => {
    if (finished && actionType === TRIPS_GET_TRIPS_SUCCESS) {
      if (clearAllCheckboxSelections) {
        setSelectedTrips({ allItemsSelected: false, vamoosIds: [] });
        setClearAllCheckboxSelections(false);
        setCheckedOnCurrentPage([]);
      } else {
        const itemsIdOnCurrentPage = listOfTrips.map(item => item.vamoos_id);
        const activeItemsOnCurrentPage = itemsIdOnCurrentPage.filter(id => selectedTrips.vamoosIds.includes(id));

        setCheckedOnCurrentPage(activeItemsOnCurrentPage);
        setSelectedTrips(prevState => ({
          ...prevState,
          allItemsSelected: listOfTrips.length && itemsIdOnCurrentPage.every(id => selectedTrips.vamoosIds.includes(id)),
        }));
      }
    }
  };

  const handleEncodedSearch = query => {
    setSearchValue(query);
    handleSearch(query, true);
  };

  const debounceSearchQuery = useRef(debounce(query => handleEncodedSearch(query), DEFAULT_DEBOUNCE_DELAY));

  const onSearch = query => {
    setClearAllCheckboxSelections(true);
    setSearchValue(query);
    if (query.length < 3 && query !== "") return;
    return debounceSearchQuery.current(query);
  };

  const customContextBar = () => {
    return <TopSecondMenu menu={<VamoosListMenu searchValue={searchValue} onSearch={onSearch} device={device} />} />;
  };

  const onSort = order => {
    const [key, value] = Object.entries(order)[0];
    refactoredHandleChangeOrder({ column: key, order: value }, TABLE_NAMES.trip);
  };

  let showRefactor =
    (currentOperator?.meta?.enable_refactor && currentOperator?.meta?.show_refactor !== false) ??
    process.env.REACT_APP_SHOW_REFACTORED_TRIPS === "true";
  if (process.env.REACT_APP_FORCE_NEW_TRIPS === "true") { showRefactor = true }

  const content = (() => {
    if ((inProgress && !listOfTrips.length) || !storedTripsColumns || isFetchingCurrentOperator) return <LoadingScreen />;
    if (!listOfTrips.length && !canCreate) return null;
    if (!listOfTrips.length && canCreate)
      return (
        <NoResultsMessage>
          {checkQueryParamExistence(url, ["search", "f["])
            ? NO_RESULTS_FOUND_MESSAGE
            : showArchived
            ? "Could not find any deleted trips"
            : EMPTY_LIST_MESSAGES_BASE(CREATE_CONTENT_LABELS.trip, NAMES_OF_RESOURCES_LISTS.trip)}
        </NoResultsMessage>
      );

    return (
      <>
        <Table
          columns={generateColumns(storedTripsColumns, device, permissionsService, dispatch, {
            duplicateTrip: trip => tripCopyHandler(trip),
            openColumnSelector: () => setOpenColumnSelectorModal(true),
          })}
          rows={listOfTrips}
          renderRow={
            <CustomTableRow
              showRefactoredEditor={showRefactor}
              columns={generateColumns(storedTripsColumns, device, permissionsService, dispatch, {
                duplicateTrip: trip => tripCopyHandler(trip),
              })}
              checkboxSelection={!showArchived}
            />
          }
          {...tableHandlers}
          totalMatches={tripsCount}
          containerStyle={{ height: deviceType() !== "mobile" && height100vh - 300 }}
          renderRowMobile={
            <MobileItem
              columns={generateColumns(storedTripsColumns, device, permissionsService, dispatch, {})}
              setSelectedItems={setSelectedTrips}
              selectedItems={selectedTrips}
              totalMatches={tripsCount}
              checked={checkedOnCurrentPage}
              setChecked={setCheckedOnCurrentPage}
              checkboxSelection={!showArchived}
            />
          }
          checkboxSelection={!showArchived}
          checked={checkedOnCurrentPage}
          setChecked={setCheckedOnCurrentPage}
          checkboxVarName="vamoos_id"
          onSort={onSort}
          isSorting
          tableId={TABLE_NAMES.trip}
        />
        {openCopyTripModal && (
          <CopyModal copiedItinerary={activeTrip} onCancel={() => setOpenCopyTripModal(!openCopyTripModal)} type={TABLE_NAMES.trip} />
        )}
      </>
    );
  })();

  /**
   * To avoid wrong permissions when user has been invited to specific itinerary in meantime
   * @todo: Solution should be more generic
   */
  // const refreshCurrentUserDetails = () => {
  //   dispatch(getCurrentUserStart());
  // };

  const contextBar = {
    left: <SearchField onSearchChange={onSearch} value={searchValue} fullWidth />,
    middle: VamoosListNavigation,
    right: Actions,
  };

  const updateTripsFromCheckboxes = checked => {
    if (!listOfTrips.length) return;

    const itemsIdOnCurrentPage = listOfTrips.map(item => item.vamoos_id);
    const allSelected = itemsIdOnCurrentPage.every(id => checked.includes(id));
    const activeItemsIdOnOtherPages = selectedTrips.vamoosIds.filter(id => !itemsIdOnCurrentPage.includes(id));

    setSelectedTrips({
      allItemsSelected: allSelected || [...activeItemsIdOnOtherPages, ...checked].length === tripsCount,
      vamoosIds: [...activeItemsIdOnOtherPages, ...checked],
    });
  };

  useEffect(() => {
    updateTripsFromCheckboxes(checkedOnCurrentPage);
  }, [checkedOnCurrentPage]);
  useEffect(refreshTripsListAfterRestoreTrip, [actionType, finished]);
  useEffect(forceQuery, [currentOperatorCode]);
  useEffect(tryRedirectToStaysList, [actionType, staysLoading]);
  useEffect(updateCheckboxSelectionForCurrentPage, [actionType, finished, listOfTrips.length]);

  useEffect(() => {
    dispatch(getCurrentOperatorStart());
    fetchStays({
      data: staysTableHandlers,
      setIsLoading: setStaysLoading,
      staysService,
    })
      .then(({ items }) => {
        setStaysCount(items.length);
      })
      .catch(e => {
        dispatch(pushErrorNotification(notifications.general[400]));
        Logger.debug(e);
      });
  }, []);

  return (
    <PanelTemplate
      contextBar={contextBar}
      customContextBar={customContextBar}
      styleWrapper={{ padding: "20px 15px 70px 15px", overflow: "hidden" }}
    >
      <ResponsiveShowFrom size="md">
        <FloatingAction disabled={isFetchingCurrentOperator} onClick={() => navigate(showRefactor ? "/panel/trip/create/general" : "/panel/trips/create")} />
      </ResponsiveShowFrom>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ width: "100%", height: "100%" }}>
          <ResponsiveHideFrom size="md">
            <ActionItemsContainer>
              <Filter
                handleChangeParams={handleChangeParams}
                openFilterModal={openFilterModal}
                setOpenFilterModal={setOpenFilterModal}
                type={TABLE_NAMES.trip}
                form={form}
                filterCount={filterCount}
                setFilterCount={setFilterCount}
                device={device}
                isLoading={inProgress || !finished}
              />
              <BulkActions
                selectedItems={selectedTrips}
                setSelectedItems={setSelectedTrips}
                url={url}
                forceQuery={forceQuery}
                totalMatches={tripsCount}
                type={TABLE_NAMES.trip}
                setClearAllCheckboxSelections={setClearAllCheckboxSelections}
              />
              <OutlinedButton
                text={showArchived ? "View active" : "View deleted"}
                startIcon={showArchived ? <FilterListOutlined /> : <DeleteOutlined />}
                onClick={() => handleToggleShowArchived()}
              />
            </ActionItemsContainer>
          </ResponsiveHideFrom>
          <ResponsiveShowFrom size="md">
            <MobileItemHeader
              setOpenFilterModal={setOpenFilterModal}
              handleToggleShowArchived={handleToggleShowArchived}
              setOpenColumnSelectorModal={setOpenColumnSelectorModal}
              showArchived={showArchived}
              filterCount={filterCount}
              selectedItems={selectedTrips}
              setSelectedItems={setSelectedTrips}
              url={url}
              forceQuery={forceQuery}
              totalMatches={tripsCount}
              list={listOfTrips}
              checked={checkedOnCurrentPage}
              setChecked={setCheckedOnCurrentPage}
              type={TABLE_NAMES.trip}
              setClearAllCheckboxSelections={setClearAllCheckboxSelections}
            />
          </ResponsiveShowFrom>
          {openFilterModal && (
            <MobileFilterModal
              handleChangeParams={handleChangeParams}
              openFilterModal={openFilterModal}
              setOpenFilterModal={setOpenFilterModal}
              handleChangePage={handleChangePage}
              page={page}
              type={TABLE_NAMES.trip}
              form={form}
              filterCount={filterCount}
              setFilterCount={setFilterCount}
              isLoading={inProgress || !finished}
            />
          )}
          {content}
        </div>
      </div>
      {openColumnSelectorModal && (
        <ColumnSelectorModal
          onCancel={() => setOpenColumnSelectorModal(false)}
          onConfirm={handleSaveColumns}
          columns={storedTripsColumns}
          device={device}
          localStorageKey={LS_TRIPS_SELECTED_COLUMNS}
        />
      )}
    </PanelTemplate>
  );
};

export { TripsIndex };
