export const INPUT_HEIGHT = {
  desktop: 48,
  mobile: 40,
};
export const INPUT_FONT_SIZE = {
  desktop: 16,
};
export const INPUT_LABEL_FONT_SIZE = {
  default: 16,
  focused: 14,
};
export const INPUT_BORDER_COLOR = "#C4C4C4";
export const INPUT_LABEL_COLOR = "#4f4f4f";
export const INPUT_BORDER_RADIUS = 8;
