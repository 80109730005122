import styled, { css } from "styled-components";
import { font } from "config/theme/fonts";
import { colors, colors_new } from "../../../../../config/theme/colors";

export const StyledContainer = styled.div`
  border-bottom: 1px solid #e0e0e0;
  padding: 15px;

  ${({ theme, archived }) =>
          archived === "true" &&
          css`
            background: ${theme.colors.withOpacity(theme.colors.errorLight, 0.1)};

            a {
              color: ${theme.colors.errorLight};
            }

            .MuiChip-root {
              border-color: ${theme.colors.errorLight};
              color: ${theme.colors.errorLight};
            }
          `}
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  overflow: hidden;
`;

export const Header = styled.div`
  max-width: 85%;

  ${({ theme, archived }) => css`
    color: ${archived === "true" ? theme.colors.errorLight : "#000"};
  `}
`;

export const Title = styled.div`
  font-family: ${font};
  font-size: 16px;
  font-weight: 700;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const SecondaryText = styled.div`
  font-family: ${font};
  color: ${colors_new.greyText};
  font-weight: 600;
  margin-top: 3px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 15px 37px;
  
  &:last-child {
    padding-bottom: 0;
  }

  ${({ theme, archived }) => css`
    color: ${archived === "true" ? theme.colors.errorLight : "#000"};
  `}
`;

export const KeyText = styled.span`
  color: ${colors.grey50};
`;

export const ValueText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Placeholder = styled.div`
  width: 48px;
  height: 42px;
`;
