import React from "react";
import { components } from "react-select";

const IconSelectOption = ({ src, name }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img src={src} alt="icon" width={25} style={{ marginRight: name ? 15 : 0, filter: "brightness(0)" }} />
      {name}
    </div>
  );
};

export default IconSelectOption;
