import React from "react";
import { CircularProgress } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { ButtonS } from "./style";

const OutlinedButton = ({
  text,
  startIcon,
  onClick,
  type = "default",
  style,
  iconOnlyMobile,
  disabled,
  isLoading,
  id,
  noBorder,
  tooltip,
}) => {
  return (
    <Tooltip title={tooltip}>
      <ButtonS
        variant="outlined"
        startIcon={!isLoading && startIcon}
        onClick={onClick}
        type={type}
        style={style}
        text={text}
        iconOnlyMobile={iconOnlyMobile}
        disabled={disabled || isLoading}
        noBorder={noBorder}
        id={id}
      >
        {isLoading ? <CircularProgress size={16} /> : text && <span id="text">{text}</span>}
      </ButtonS>
    </Tooltip>
  );
};

export default OutlinedButton;
