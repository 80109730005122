import AddIcon from "@mui/icons-material/Add";
import { useQuery } from "@tanstack/react-query";
import OutlinedButton from "components/_new/Buttons/OutlinedButton";
import DnD from "components/_new/DnD_new";
import { NoResultsMessage } from "components/ui/Messages";
import TitleRow from "feature/panel/Itinerary/components/TitleRow";
import React, { useState } from "react";
import Item from "./item";
import EditStayModal from "./modal";

const ListOfLocations = ({ data, onAdd, control, watch, name, onDragEnd, onDelete, disabled, update, setValue, hasFile, errors, trigger }) => {
  const [modalStayId, setModalStayId] = useState(null);
  const [isItemAdded, setIsItemAdded] = useState(false);

  const { data: recentLocations } = useQuery({ queryKey: ["/location/recent?count=5000&exclude_nested=1"] });
  const { data: publicStays } = useQuery({ queryKey: [`/itinerary/stays?count=5000`] });

  const options = [...(recentLocations?.items || []), ...(publicStays?.items || [])];

  const onCloseModal = () => setModalStayId(null);

  return (
    <div>
      {modalStayId && <EditStayModal stayId={modalStayId} onClose={onCloseModal} />}

      <TitleRow text="Locations" isSticky offsetTabs disabled={disabled}>
        <OutlinedButton
          text="Add"
          startIcon={<AddIcon />}
          onClick={() => {
            setIsItemAdded(true);
            onAdd({on_weather: true, on_maps: true, position: data.length});
          }}
        />
      </TitleRow>

      <div style={{ height: 20 }} />

      {!data.length && <NoResultsMessage height="50px">Please click &quot;ADD&quot; to start adding locations</NoResultsMessage>}
      <DnD
        list={data || []}
        disabled={disabled}
        idVar="vamoos_id"
        // contentStyle={{ padding: 0 }}
        element={
          <Item
            control={control}
            locations={options}
            name={name}
            onDelete={onDelete}
            onModalOpen={setModalStayId}
            disabled={disabled}
            watch={watch}
            update={update}
            setValue={setValue}
            autoFocus={isItemAdded}
            hasFile={hasFile}
            errors={errors}
            trigger={trigger}
          />
        }
        onDragEnd={onDragEnd}
        contentStyle={{ padding: 0 }}
      />
    </div>
  );
};

export default ListOfLocations;
