import { colors } from "config/theme/colors";
import { getMediaPx } from "helpers";
import styled from "styled-components";

export const Grid = styled.div`
  display: grid;

  grid-template-columns: 1fr minmax(160px, auto);
  align-items: center;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    grid-template-columns: 1fr;
    padding-top: 15px;
  }
`;

export const PlaceholderWrap = styled.div`
  position: relative;
  height: 50px;
  width: 50px;
  transition: all 0.2s ease-in-out;

  cursor: pointer;
  /* &::before {
    content: "+ Add";
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    transition: all 0.2s ease-in-out;
    opacity: 0;
    display: flex;
    top: 0;
    left: 0;
    color: #fff;
    border-radius: 8px;
    position: absolute;
    font-size: 12px;
    font-weight: 700;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    &::before {
      opacity: 1;
    }
  } */
`;

export const ImageWrap = styled.div`
  position: relative;
  height: 48px;
  width: 48px;
  /* margin-left: 15px; */
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    padding-right: 15px;
  }
  @media (min-width: ${p => getMediaPx(p, "md")}px) {
    &:hover {
      img {
        transition: width 0.2s ease-in-out, height 0.2s ease-in-out;

        width: 300px;
        height: auto;
        transform: translate(0, -50%);
        z-index: 9999;
      }
    }
  }
`;

export const Image = styled.img`
  height: 48px;
  width: 48px;
  border-radius: 8px;
  object-fit: cover;
  position: absolute;
`;

export const Buttons = styled.div`
  display: grid;
  grid-template-columns: 1fr 80px;
  button {
    border-left: 1px solid ${colors.grey10} !important;
  }
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    padding: 15px 10px;
    grid-template-columns: 1fr;
    button {
      border: 1px solid ${colors.grey10} !important;
      width: 100% !important;
    }
  }
`;
