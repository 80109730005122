import { responsiveConfig } from "config/responsive";
import styled from "styled-components";
import { getMediaPx } from "helpers";

export const ActionButtonsGrid = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: 48px 48px 48px 1fr;

  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    grid-template-columns: repeat(4, 40px);
  }
`;
