import styled from "styled-components";
import { colors } from "config/theme/colors";
import { Link } from "../../../../../../components/ui/Links/Links";

export const StyledLink = styled(Link)`
  && {
    margin-left: ${({ theme }) => theme.setSpacing(1)}px;
    line-height: 24px;
  }
`;

export const Wrap = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(232, 232, 233, 0.3);
  border-left: 1px solid ${colors.grey10};
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-weight: 700;
  color: ${colors.grey50};
`;
