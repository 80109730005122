import BaseModal from "components/_new/ModalBase";
import { colors } from "config/theme/colors";
import React from "react";

const EraseModal = ({ onCancel, onConfirm, type = "trip" }) => {
  return (
    <BaseModal
      title={`Warning! Are you sure want to delete the current ${type}?`}
      confirmTitle="Delete"
      onCancel={onCancel}
      onConfirm={onConfirm}
      modalStyle={{ minWidth: "auto", maxWidth: 600 }}
    >
      <div style={{ marginTop: 15, color: colors.grey40 }}>
        By pressing the Delete button below, you will delete all data for this {type}. This change will be non-reversible after a restore
        period of 5 days. This will include all data entered by you or your end users. The {type} will immediately become unavailable from
        apps, and any existing installations on devices will be wiped the next time they synchronise with our servers.
      </div>
    </BaseModal>
  );
};

export default EraseModal;
