import * as actionTypes from "store/adminUsers/actionTypes";
import { createAction } from "utils/store";

export const getAdminUsersStart = createAction(actionTypes.GET_ADMIN_USERS_START);
export const getAdminUsersSuccess = createAction(actionTypes.GET_ADMIN_USERS_SUCCESS);
export const getAdminUsersFail = createAction(actionTypes.GET_ADMIN_USERS_FAIL);

export const resetUserPasswordStart = createAction(actionTypes.RESET_USER_PASSWORD_START);
export const resetUserPasswordPush = createAction(actionTypes.RESET_USER_PASSWORD_PUSH);
export const resetUserPasswordFinish = createAction(actionTypes.RESET_USER_PASSWORD_FINISH);

export const getAdminUsersOperators = createAction(actionTypes.GET_ADMIN_USERS_OPERATORS);
export const getAdminUsersOperatorsSuccess = createAction(actionTypes.GET_ADMIN_USERS_OPERATORS_SUCCESS);