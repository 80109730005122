import styled, { css, keyframes } from "styled-components";
import { colors } from "../../../../../../../config/theme/colors";
import { getMediaPx } from "../../../../../../../helpers";
import { MobileItemWrap } from "../../../../helpers";

const blinkAnimation = keyframes`
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
`;

export const MobileItemContainer = styled(MobileItemWrap)`
  ${({ isOverridden, clickedRow, templateId }) =>
          clickedRow && clickedRow === templateId && !isOverridden &&
          css`
      animation: ${blinkAnimation} 0.5s ease-out infinite;
    `}
`;

export const MobileItemContent = styled.div`
  display: flex;
  justify-content: space-between;
  opacity: ${props => (props.isOverridden ? 0.3 : 1)};
`;
