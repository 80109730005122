import React from "react";
import Grid from "@material-ui/core/Grid";

import { DefaultPageRightSection } from "feature/views/PublicPagesShared/DefaultPageRightSection";
import { DefaultPageLeftSection } from "feature/views/PublicPagesShared/DefaultPageLeftSection";
import { FullHeightColumn } from "components/ui/Grid/Grid";
import { BrandSection } from "components/public/Brand/BrandSection";
import { PasswordResetForm } from "feature/views/PasswordReset/PasswordResetForm";

import { useTitle } from "hooks/useTitle";
import bg from "assets/images/reset_password_bg.png";
import { Content } from "components/ui/Content";
import { H3, LeadText } from "components/ui/Typography/Typography";
import styled from "styled-components";
import { PASSWORD_RESET } from "constants/content";

const MainContent = styled.section`
  max-width: 480px;
`;

const PasswordReset = () => {
  useTitle("Reset password");

  return (
    <Grid container spacing={0} component="article">
      <DefaultPageLeftSection>
        <FullHeightColumn alignContent="space-between">
          <Grid item xs={12}>
            <BrandSection />
          </Grid>
          <Grid item xs={12}>
            <MainContent>
              <Content element="header" margin={12}>
                <H3 cv="grey90">{PASSWORD_RESET.title}</H3>
              </Content>
              <Content margin={12}>
                <LeadText>{PASSWORD_RESET.description}</LeadText>
              </Content>
              <PasswordResetForm />
            </MainContent>
          </Grid>
          <Grid item xs={12} />
        </FullHeightColumn>
      </DefaultPageLeftSection>
      <DefaultPageRightSection backgroundImage={bg} />
    </Grid>
  );
};

export { PasswordReset, MainContent };
