import { getMediaPx } from "helpers";
import styled from "styled-components";

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 100px;
  gap: 15px;
  padding: 15px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
   */

  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    grid-template-columns: 1fr 48px;
  }
`;
