import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";

import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { Textarea } from "components/ui/Forms";
import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";
import { Message } from "components/ui/Messages/Message";

import { CUSTOMISED_NESTED_STAY_LABELS, STAYS_FORM_LABELS } from "constants/content";
import { setNotification } from "store/app/actions";
import { copyToClipboard } from "utils";

import { MAXIMUM_SHORT_DESCRIPTION_LENGTH } from "constants/defaults";
import { OverridesService } from "services/OverridesService";
import { OverrideRepository } from "repositories/OverrideRepository";
import { OverrideMapper } from "mappers/OverrideMapper";

const DescriptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${({ theme }) => theme.setSpacing(4)}px 0 ${({ theme }) => theme.setSpacing(3.5)}px;
  padding: ${({ theme }) => theme.setSpacing(3.5)}px ${({ theme }) => theme.setSpacing(1.5)}px ${({ theme }) => theme.setSpacing(3.5)}px
    ${({ theme }) => theme.setSpacing(3)}px;
  border-radius: ${({ theme }) => theme.setSpacing(2)}px;
  background-color: ${({ theme }) => theme.colors.descriptionBackground};
`;

const Content = styled.div`
  font-family: Montserrat;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  ${({ croppText }) =>
    croppText
      ? `
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 470px;
    height: ${({ theme }) => theme.setSpacing(4.5)}px;
    white-space: nowrap;
  `
      : ""}
`;
const RightSideBar = styled.div`
  display: flex;
  flex-direction: column;
  margin: -16px 0;

  & > * {
    cursor: pointer;
  }
`;

const PreTag = styled.span`
  font-weight: 600;
  margin: 0 ${({ theme }) => theme.setSpacing(1)}px;
`;

const WrapperIconButton = styled.div`
  color: ${({ theme }) => theme.colors.grey50};
  width: 40px;
  height: 40px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTextarea = styled(Textarea)`
  && {
    legend {
      ${({ isPlaceholderText, isShortDescriptionField, isLongDescriptionField, theme }) =>
        isPlaceholderText
          ? "width: 0"
          : `width: ${theme.setSpacing(isShortDescriptionField && !isLongDescriptionField ? 28 : 27.5)}px !important`};
    }
  }
`;

const CustomiseNestedStay = ({ isOpen, stay, onClose }) => {
  const overrideRepository = new OverrideRepository();
  const overrideMapper = new OverrideMapper();
  const overrideService = new OverridesService(overrideRepository, overrideMapper);

  const [nestedStay, setNestedStay] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isFormTouched, setFormTouched] = useState(false);
  const [shortDescription, setShortDescription] = useState("");
  const [longDescription, setLongDescription] = useState("");
  const [shortDescriptionExpanded, setShortDescriptionExpanded] = useState(false);
  const [isLongDescriptionExpanded, setIsLongDescriptionExpanded] = useState(false);

  const [isShortDescriptionFocused, setIsShortDescriptionFocused] = useState(false);
  const [isLongDescriptionFocused, setIsLongDescriptionFocused] = useState(false);

  const shortDescriptionRef = useRef();
  const longDescriptionRef = useRef();

  const dispatchRedux = useDispatch();

  const handleClear = () => {
    setShortDescription("");
    setLongDescription("");
    setShortDescriptionExpanded(false);
    setIsLongDescriptionExpanded(false);
    setIsShortDescriptionFocused(false);
    setIsLongDescriptionFocused(false);
    setFormTouched(false);
    onClose();
  };

  const handleUpdateShortDescription = ({ target }) => {
    const { value } = target;

    if (value.length <= MAXIMUM_SHORT_DESCRIPTION_LENGTH || value.length < shortDescription.length) {
      setShortDescription(value);
      if (!isFormTouched) {
        setFormTouched(true);
      }
    }
  };

  const handlePasteShortDescription = event => {
    event.preventDefault();

    const { clipboardData } = event;
    const valueFromClipboard = clipboardData.getData("text/plain");
    const selection = window.getSelection().toString();

    const getText = text => {
      return text.length <= MAXIMUM_SHORT_DESCRIPTION_LENGTH ? text : text.substring(0, MAXIMUM_SHORT_DESCRIPTION_LENGTH);
    };

    if (selection && shortDescription.includes(selection)) {
      const shortDescriptionNewText = shortDescription.replace(selection, valueFromClipboard);
      const croppedText = getText(shortDescriptionNewText);
      setShortDescription(croppedText);
    } else if (shortDescriptionRef.current.selectionStart < shortDescription?.length) {
      const beginning = shortDescription.slice(0, shortDescriptionRef.current.selectionStart);
      const ending = shortDescription.slice(shortDescriptionRef.current.selectionStart);
      const shortDescriptionNewText = `${beginning}${valueFromClipboard}${ending}`;
      const croppedText = getText(shortDescriptionNewText);
      setShortDescription(croppedText);
    } else {
      const shortDescriptionNewText = shortDescription?.length > 0 ? `${shortDescription} ${valueFromClipboard}` : valueFromClipboard;
      const croppedText = getText(shortDescriptionNewText);
      setShortDescription(croppedText);
    }
  };

  const handleUpdateLongDescription = ({ target }) => {
    setLongDescription(target.value);

    if (!isFormTouched) {
      setFormTouched(true);
    }
  };

  const getVamoosIdStay = vamoosId => {
    setLoading(true);
    overrideService
      .getOverride(vamoosId)
      .then(stayDomain => {
        setNestedStay(stayDomain);
        if (stayDomain.shortDescription) setShortDescription(stayDomain.shortDescriptionUser);
        if (stayDomain.longDescription) setLongDescription(stayDomain.longDescriptionUser);
        setLoading(false);
      })
      .catch(() => {
        const stayDomain = overrideMapper.fromDtoToDomain({});
        setNestedStay(stayDomain);
        setLoading(false);
        dispatchRedux(setNotification({ type: "error", message: CUSTOMISED_NESTED_STAY_LABELS.getVamoosIdOverridedStay }));
      });
  };

  const handleConfirm = () => {
    // snake case only for backward compatibility due to itinerary still doesnt have its domain mapper
    const vamoosId = stay?.nested?.vamoosId || stay?.nested?.vamoos_id;

    const updatedStay = {
      shortDescription,
      longDescription,
    };
    overrideService
      .saveOverride(vamoosId, updatedStay)
      .then(() => {
        dispatchRedux(setNotification({ type: "success", message: CUSTOMISED_NESTED_STAY_LABELS.stayUpdateSuccess }));
        handleClear();
      })
      .catch(() => {
        dispatchRedux(setNotification({ type: "error", message: CUSTOMISED_NESTED_STAY_LABELS.stayUpdateFail }));
      });
  };

  const handleClose = () => {
    handleClear();
  };

  const copyDescription = (content, message) => {
    if (content) {
      copyToClipboard(content);
      dispatchRedux(setNotification({ type: "success", message }));
    }
  };

  const longDescriptionLabel =
    !isLongDescriptionFocused && !longDescription
      ? CUSTOMISED_NESTED_STAY_LABELS.longDescriptionPlaceholder
      : CUSTOMISED_NESTED_STAY_LABELS.longDescriptionLabel;
  const shortDescriptionLabel =
    !isShortDescriptionFocused && !shortDescription
      ? CUSTOMISED_NESTED_STAY_LABELS.shortDescriptionPlaceholder
      : CUSTOMISED_NESTED_STAY_LABELS.shortDescriptionLabel;

  const toggleShortDescription = () => setShortDescriptionExpanded(!shortDescriptionExpanded);
  const toggleLongDescription = () => setIsLongDescriptionExpanded(!isLongDescriptionExpanded);

  const shortDescriptionText = nestedStay?.shortDescriptionOriginal || "";
  const longDescriptionText = nestedStay?.longDescriptionOriginal || "";

  const content =
    !isLoading && nestedStay ? (
      <div>
        <Message text={CUSTOMISED_NESTED_STAY_LABELS.info} type="info" />
        <DescriptionContainer>
          <Content croppText={!shortDescriptionExpanded}>
            <PreTag>{`(${CUSTOMISED_NESTED_STAY_LABELS.default})`}</PreTag>
            {shortDescriptionText}
          </Content>
          <RightSideBar>
            <WrapperIconButton onClick={toggleShortDescription}>
              {shortDescriptionExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </WrapperIconButton>
            {shortDescriptionExpanded && (
              <WrapperIconButton
                onClick={() => copyDescription(shortDescriptionText, CUSTOMISED_NESTED_STAY_LABELS.shortDescriptionCopied)}
              >
                <FileCopyOutlinedIcon />
              </WrapperIconButton>
            )}
          </RightSideBar>
        </DescriptionContainer>
        <StyledTextarea
          name="short_description"
          value={shortDescription}
          label={shortDescriptionLabel}
          rows={3}
          inputRef={shortDescriptionRef}
          onBlur={() => setIsShortDescriptionFocused(false)}
          onFocus={() => setIsShortDescriptionFocused(true)}
          onChange={handleUpdateShortDescription}
          onPaste={handlePasteShortDescription}
          isPlaceholderText={!isShortDescriptionFocused && !shortDescription}
          isShortDescriptionField
          helperText={STAYS_FORM_LABELS.maximumNumberOfCharacters(MAXIMUM_SHORT_DESCRIPTION_LENGTH)}
        />
        <DescriptionContainer>
          <Content croppText={!isLongDescriptionExpanded}>
            <PreTag>{`(${CUSTOMISED_NESTED_STAY_LABELS.default})`}</PreTag>
            {longDescriptionText}
          </Content>
          <RightSideBar>
            <WrapperIconButton onClick={toggleLongDescription}>
              {isLongDescriptionExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </WrapperIconButton>
            {isLongDescriptionExpanded && (
              <WrapperIconButton onClick={() => copyDescription(longDescriptionText, CUSTOMISED_NESTED_STAY_LABELS.longDescriptionCopied)}>
                <FileCopyOutlinedIcon />
              </WrapperIconButton>
            )}
          </RightSideBar>
        </DescriptionContainer>
        <StyledTextarea
          name="long_description"
          value={longDescription}
          label={longDescriptionLabel}
          rows={3}
          inputRef={longDescriptionRef}
          onBlur={() => setIsLongDescriptionFocused(false)}
          onFocus={() => setIsLongDescriptionFocused(true)}
          onChange={handleUpdateLongDescription}
          isPlaceholderText={!isLongDescriptionFocused && !longDescription}
          isLongDescriptionField
        />
      </div>
    ) : (
      <LoadingScreen />
    );

  useEffect(() => {
    // snake case only for backward compatibility due to itinerary still doesnt have its domain mapper
    if (stay?.nested?.vamoosId || stay?.nested?.vamoos_id) {
      getVamoosIdStay(stay?.nested?.vamoosId || stay?.nested?.vamoos_id);
    }
  }, [stay?.nested]);

  return (
    <>
      {isOpen ? (
        <ConfirmationModal
          open={isOpen}
          onCancel={handleClose}
          onConfirm={handleConfirm}
          title={CUSTOMISED_NESTED_STAY_LABELS.title(stay?.name)}
          fullWidth
          maxWidth="sm"
          confirmLabel={CUSTOMISED_NESTED_STAY_LABELS.save}
          showSpinner={false}
        >
          {content}
        </ConfirmationModal>
      ) : null}
    </>
  );
};

CustomiseNestedStay.defaultProps = {
  stay: null,
};

CustomiseNestedStay.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  stay: PropTypes.shape({
    name: PropTypes.string,
    nested: PropTypes.shape({
      short_description: PropTypes.string,
      long_description: PropTypes.string,
      vamoosId: PropTypes.number,
    }),
  }),
  onClose: PropTypes.func.isRequired,
};

export { CustomiseNestedStay };
