import * as actionTypes from "store/auth/actionTypes";
import { createAction } from "utils/store";

export const registerUserWithOperatorStart = createAction(actionTypes.REGISTER_USER_WITH_OPERATOR_START);
export const registerUserWithOperatorSuccess = createAction(actionTypes.REGISTER_USER_WITH_OPERATOR_SUCCESS);
export const registerUserWithOperatorFail = createAction(actionTypes.REGISTER_USER_WITH_OPERATOR_FAIL);

export const registerUserStart = createAction(actionTypes.REGISTER_USER_START);
export const registerUserSuccess = createAction(actionTypes.REGISTER_USER_SUCCESS);
export const registerUserFail = createAction(actionTypes.REGISTER_USER_FAIL);

export const loginStart = createAction(actionTypes.LOGIN_START);
export const loginSuccess = createAction(actionTypes.LOGIN_SUCCESS);
export const loginFail = createAction(actionTypes.LOGIN_FAIL);

export const verifySuccessStart = createAction(actionTypes.VERIFY_SUCCESS_START);
export const verifySuccessComplete = createAction(actionTypes.VERIFY_SUCCESS_COMPLETE);

export const logoutStart = createAction(actionTypes.LOGOUT_START);
export const logoutSuccess = createAction(actionTypes.LOGOUT_SUCCESS);
export const logoutFail = createAction(actionTypes.LOGOUT_FAIL);

export const getCurrentUserStart = createAction(actionTypes.GET_CURRENT_USER_START);
export const getCurrentUserSuccess = createAction(actionTypes.GET_CURRENT_USER_SUCCESS);
export const getCurrentUserFail = createAction(actionTypes.GET_CURRENT_USER_FAIL);

export const editCurrentUserStart = createAction(actionTypes.EDIT_CURRENT_USER_START);
export const editCurrentUserSuccess = createAction(actionTypes.EDIT_CURRENT_USER_SUCCESS);

export const setCurrentOperatorCode = createAction(actionTypes.SET_CURRENT_OPERATOR_CODE);
export const resetUserActionType = createAction(actionTypes.RESET_USER_ACTION_TYPE);

export const setSingleError = createAction(actionTypes.SET_SINGLE_ERROR);

export const updateOperators = createAction(actionTypes.UPDATE_OPERATORS);

export const setComposerData = createAction(actionTypes.SET_COMPOSER_DATA);

export const setIsInitialAuth = createAction(actionTypes.SET_IS_INITIAL_AUTH);

export const registerOperatorSuccess = createAction(actionTypes.REGISTER_OPERATOR_SUCCESS);

export const setCreatedVamoosList = createAction(actionTypes.SET_CREATED_VAMOOS_LIST);