import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useManageTripContext } from "feature/panel/Trips/_shared/ManageTripContext";
import { LocationsTabListItem } from "feature/panel/Trips/_shared/Locations/LocationsTab/LocationsTabListItem";

import { SortableList } from "components/ui/Lists";
import { NoResultsMessage } from "components/ui/Messages/NoResultsMessage";

import { getPublicStaysStart, getRecentLocationStart } from "store/trips/actions";

import { extractCoordinates } from "utils";
import { setNewOrder } from "utils/sortable";

import { CREATE_CONTENT_LABELS, EMPTY_LIST_MESSAGES_BASE, NAMES_OF_RESOURCES_LISTS } from "constants/content";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

const LocationsTabList = ({ setValue }) => {
  const currentLocations = useSelector(state => state.trips?.recentLocations);
  const currentStays = useSelector(state => state.trips?.publicStays);
  const dispatch = useDispatch();
  const permissionsService = usePermissionsService();

  const { locations, setValueFor, errors, notifications, vamoos_id, editMode } = useManageTripContext();

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoos_id);

  const canEditOperator = permissionsService.can(
    PERMISSIONS.actions.update,
    PERMISSIONS.sections.operator,
    PERMISSIONS.resources.operators,
  );

  const canCreate = permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.vamoosList);

  const handleChange = (id, key, value) => {
    const newLocations = locations.map(location => {
      if (location.id === id) {
        const extraPayload = {};
        if (key === "coordinates") {
          const { longitude, latitude } = extractCoordinates(value);
          extraPayload.latitude = latitude;
          extraPayload.longitude = longitude;
        }

        if (key === "file" && value === undefined) {
          const { file, ...rest } = location;
          return { ...rest, ...extraPayload };
        }

        if (key === "all") return value;

        return { ...location, ...extraPayload, [key]: value };
      }
      return location;
    });
    setValue("locations", [...newLocations]);
    setValueFor("locations", [...newLocations]);
  };

  const handleRemovedLocationInNotifications = id => {
    return notifications.map(notification => {
      if (notification?.location?.id === id || notification.location_internal_id === id) {
        return {
          ...notification,
          location: undefined,
          location_internal_id: undefined,
        };
      }
      return notification;
    });
  };

  const handleRemove = id => {
    const newNotifications = handleRemovedLocationInNotifications(id);
    setValue("locations", [...locations.filter(location => location.id !== id)]);

    setValueFor("locations", [...locations.filter(location => location.id !== id)]);
    setValueFor("notifications", [...newNotifications]);
  };

  const handleChangeOrder = ({ newIndex, oldIndex }) => {
    const newList = setNewOrder(newIndex, oldIndex, locations);
    setValueFor("locations", newList);
  };

  const getCurrentLocations = () => {
    dispatch(getRecentLocationStart());
    dispatch(getPublicStaysStart());
  };

  useEffect(getCurrentLocations, []);

  if ((!locations || !locations.length) && editMode && !canEdit) return null;

  if ((!locations || !locations.length) && ((editMode && canEdit) || (!editMode && canCreate)))
    return (
      <NoResultsMessage height="50px">
        {EMPTY_LIST_MESSAGES_BASE(CREATE_CONTENT_LABELS.add, NAMES_OF_RESOURCES_LISTS.location)}
      </NoResultsMessage>
    );

  return (
    <SortableList lockAxis="y" onSortEnd={handleChangeOrder} lockToContainerEdges useDragHandle>
      {locations.map((location, index) => (
        <LocationsTabListItem
          key={location.id}
          item={location}
          index={index}
          onChange={handleChange}
          onRemove={handleRemove}
          errors={errors.locations ? errors.locations : []}
          currentLocations={currentLocations}
          currentStays={currentStays}
          canEdit={canEdit}
          canEditOperator={canEditOperator}
        />
      ))}
    </SortableList>
  );
};

export { LocationsTabList };
