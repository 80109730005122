import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";

import { Content } from "components/ui/Content";
import { Textarea, Input } from "components/ui/Forms";
import { Accordion } from "components/ui/Accordion";

import { FeatureRowList } from "feature/panel/Stays/_shared/General/FeatureRowList";
import { FeaturesAccordionHeader } from "feature/panel/Stays/_shared/General/FeaturesAccordionHeader";

import { STAYS_FORM_LABELS } from "constants/content";
import { MAXIMUM_SHORT_DESCRIPTION_LENGTH } from "constants/defaults";
import { checkErrorExistFor } from "utils/validation";

import { getStaysFeaturesStart } from "store/stays/actions";
import { setStayWizardLongDescription, setStayWizardNumberOfRooms, setStayWizardShortDescription } from "store/stayWizard/actions";

import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

import { PANEL_STAYS_CREATE_PATH } from "constants/routes";
import { LanguageContext } from "components/_shared/LanguageSelector/LanguageContext";

import { TopFourFeatures } from "../TopFourFeatures/TopFourFeatures";
import { LanguageLock } from "../LanguageLock";

const ContentWrapper = styled.div`
  padding: 0 ${({ theme }) => theme.setSpacing(6)}px;
`;

const StyledGrid = styled(Grid)`
  &&&& .MuiTypography-body1 {
    font-family: Montserrat !important;
    letter-spacing: 0;
  }
`;
const VamoosListingInformation = ({ disabled }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const permissionsService = usePermissionsService();
  const [topFourOpen, setTopFourOpen] = useState(false);

  const { currentLanguage } = useContext(LanguageContext);

  const isStayDefaultLanguage = useSelector(({ stayWizard }) =>
    stayWizard.defaultVariant.language ? stayWizard.defaultVariant.language === currentLanguage.code : true,
  );

  const shortDescription = useSelector(({ stayWizard }) => stayWizard.form.shortDescription || "");
  const shortDescriptionPlaceholder = useSelector(({ stayWizard }) => stayWizard.defaultVariant.shortDescription);
  const longDescription = useSelector(({ stayWizard }) => stayWizard.form.longDescription || "");
  const longDescriptionPlaceholder = useSelector(({ stayWizard }) => stayWizard.defaultVariant.longDescription);
  const numberOfRooms = useSelector(({ stayWizard }) =>
    isStayDefaultLanguage ? stayWizard.form.numberOfRooms : stayWizard.defaultVariant.numberOfRooms,
  );
  const features = useSelector(({ stayWizard }) => (isStayDefaultLanguage ? stayWizard.form.features : stayWizard.defaultVariant.features));
  const vamoosId = useSelector(({ stayWizard }) => stayWizard.form.vamoosId);

  const errors = useSelector(state => state.stays.errors);
  const isTop4Disabled = features.filter(({ isActive }) => isActive).length === 0;

  const editMode = !location.pathname.startsWith(PANEL_STAYS_CREATE_PATH);

  const canCreate = permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.vamoosList);
  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoosId);

  const disableFeatures = (editMode && !canEdit) || (!editMode && !canCreate);

  const handleTopFourOpen = () => setTopFourOpen(true);
  const handleCloseTopFourModal = () => setTopFourOpen(false);

  const handleLongDescriptionChange = ({ target }) => {
    const { value } = target;
    dispatch(
      setStayWizardLongDescription({
        longDescription: value,
      }),
    );
  };

  const handleNumberOfRoomsChange = ({ target }) => {
    const { value } = target;
    dispatch(setStayWizardNumberOfRooms(value));
  };

  const handleShortDescriptionChange = ({ target }) => {
    const { value } = target;
    if (value.length <= MAXIMUM_SHORT_DESCRIPTION_LENGTH || value.length < shortDescription.length) {
      dispatch(
        setStayWizardShortDescription({
          shortDescription: value,
        }),
      );
    }
  };

  const handleShortDescriptionPaste = event => {
    event.preventDefault();

    const { clipboardData } = event;
    const value = clipboardData.getData("text/plain");

    if (value.length <= MAXIMUM_SHORT_DESCRIPTION_LENGTH) {
      dispatch(
        setStayWizardShortDescription({
          shortDescription: value,
        }),
      );
    } else {
      dispatch(
        setStayWizardShortDescription({
          shortDescription: value.substring(0, MAXIMUM_SHORT_DESCRIPTION_LENGTH),
        }),
      );
    }
  };

  const init = () => {
    dispatch(getStaysFeaturesStart());
  };

  useEffect(init, []);

  const featuresHeader = (
    <FeaturesAccordionHeader
      lockedInfo={!isStayDefaultLanguage && <LanguageLock />}
      disabled={disabled}
      onTopFourClick={handleTopFourOpen}
      isTop4Disabled={isTop4Disabled}
    />
  );

  return (
    <StyledGrid container>
      <Grid item xs={12} md={7}>
        <Content margin={4}>
          <Textarea
            name="short_description"
            value={shortDescription}
            label={STAYS_FORM_LABELS.shortDescription}
            placeholder={isStayDefaultLanguage ? "" : shortDescriptionPlaceholder}
            labelShrink={isStayDefaultLanguage ? undefined : shortDescriptionPlaceholder || undefined}
            rows={3}
            onChange={handleShortDescriptionChange}
            onPaste={handleShortDescriptionPaste}
            error={checkErrorExistFor("short_description", errors?.meta)}
            helperText={
              errors?.meta ? errors?.meta.short_description : STAYS_FORM_LABELS.maximumNumberOfCharacters(MAXIMUM_SHORT_DESCRIPTION_LENGTH)
            }
            disabled={disabled}
          />
        </Content>
        <Content>
          <Textarea
            name="long_description"
            value={longDescription}
            label={STAYS_FORM_LABELS.longDescription}
            placeholder={isStayDefaultLanguage ? "" : longDescriptionPlaceholder}
            labelShrink={isStayDefaultLanguage ? undefined : longDescriptionPlaceholder || undefined}
            rows={3}
            onChange={handleLongDescriptionChange}
            error={checkErrorExistFor("long_description", errors?.meta)}
            helperText={errors?.meta ? errors?.meta.long_description : ""}
            disabled={disabled}
          />
        </Content>
        <Content>
          <Input
            type="number"
            name="number_of_rooms"
            value={numberOfRooms}
            label={STAYS_FORM_LABELS.numberOfRooms}
            onChange={handleNumberOfRoomsChange}
            error={checkErrorExistFor("number_of_rooms", errors?.meta)}
            helperText={errors?.meta ? errors?.meta.number_of_rooms : ""}
            disabled={disabled || !isStayDefaultLanguage}
          />
          {!isStayDefaultLanguage && <LanguageLock wide defaultLanguage={{}} />}
        </Content>
      </Grid>
      <Grid item xs={12}>
        <Accordion header={featuresHeader} expanded>
          <ContentWrapper>
            <TopFourFeatures isOpen={topFourOpen} disabled={disabled || !isStayDefaultLanguage} onClose={handleCloseTopFourModal} />
            <FeatureRowList disabled={disableFeatures || !isStayDefaultLanguage} />
          </ContentWrapper>
        </Accordion>
      </Grid>
    </StyledGrid>
  );
};

VamoosListingInformation.defaultProps = {
  disabled: false,
};

VamoosListingInformation.propTypes = {
  disabled: PropTypes.bool,
};

export { VamoosListingInformation, ContentWrapper };
