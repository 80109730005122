import { font } from "config/theme/fonts";
import styled from "styled-components";

export const Wrap = styled.div`
  && {
    .MuiTreeItem-root,
    .MuiTreeItem-content {
      padding: 5px 0;
    }
    .MuiTreeItem-label {
      font-family: ${font};
    }
  }
`;
