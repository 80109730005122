import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import Input from "components/ui/Inputs/TextInput";
import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";
import { SearchField } from "components/ui/SearchField";
import config from "config/app";
import { colors } from "config/theme/colors";
import { getMediaPx } from "helpers";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { HttpClient } from "services/application/httpClient/httpClient";
import styled from "styled-components";
import { NavLine } from "../Files/style";

const SearchContainer = styled.div`
  // padding: ${({ theme }) => theme.setSpacing(6)}px;
  margin-top: 15px;

  div:first-child {
    // width: ${({ theme }) => theme.setSpacing(66)}px;
    height: auto;
  }

  label {
    z-index: 1;
  }
`;

const StyledSearch = styled(SearchField)`
  && {
    background-color: ${colors.white};
  }
`;

const InnerContainer = styled.div`
  padding-bottom: 100px;
  // width: 100%;
`;

const SearchResultsContainer = styled.div`
  // padding: ${({ theme }) => theme.setSpacing(6)}px;
  overflow-y: auto;
`;

const ResultsWrapper = styled.div`
  // width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  box-sizing: border-box;
  padding-top: ${({ theme }) => theme.setSpacing(4)}px;
  gap: 15px;
  :focus {
    outline: none;
  }
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: masonry;
  }
`;

const MiniatureCard = styled.div`
  max-width: 178px;
`;

const ImageWrapper = styled.div`
  height: 100%;
  width: 100%;
  width: 178px;
  height: 178px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  border-radius: 8px;
  border: ${props => (props.active ? "4px" : "1px")} solid ${props => (props.active ? colors.brand : colors.grey40)};
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    width: auto;
    height: auto;
  }
`;

const UnsplashSection = ({ setUploadAssets, setPermitUpload }) => {
  const [searchValue, setSearchValue] = useState("");
  const [searchLabel, setSearchLabel] = useState("Start typing in the search bar to find images");
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [imageSuggestions, setImageSuggestions] = useState([]);

  const imageSearchMutation = useMutation(
    mutationQuery => {
      const payload = JSON.stringify({
        text: {
          high: { text: mutationQuery },
        },
        provider: "unsplash",
      });
      return HttpClient.post(`${config.api.imsert.production}/search`, payload);
    },
    {
      onSuccess: (res, query) => {
        const newImageSuggestions = res.data?.results;

        if (newImageSuggestions.length === 0) {
          setSearchLabel(`No search results for "${query}`);
        } else {
          setSearchLabel(`Search results for "${query}"`);
        }

        setImageSuggestions(newImageSuggestions);
      },
      onError: err => {
        setImageSuggestions([]);
        setSearchLabel("We had trouble fetching results. Please try again later.");
        console.error(`Failed Imsert Response: ${err.toString()}`);
      },
    },
  );

  const debouncedSearch = useRef(debounce(query => imageSearchMutation.mutate(query), 1000));

  useEffect(() => {
    if (searchValue) {
      debouncedSearch.current(searchValue);
    }
  }, [searchValue]);

  return (
    <>
      <SearchContainer style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 15 }}>
        {/* <StyledSearch onSearchChange={setSearchValue} InputProps={{ "data-testid": "unsplash-section-search" }} /> */}
        <Input label="Search" onChange={setSearchValue} value={searchValue} />
      </SearchContainer>

      <NavLine style={{ marginTop: 15, marginLeft: 0 }}>
        <div style={{display: 'flex', gap: 10, width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
          <p style={{ fontSize: 14 }}>{searchLabel}</p>
          <p style={{ fontSize: 14, textAlign: "right", whiteSpace: "nowrap" }}>
            Provided by{" "}
            <a target="_blank" rel="noreferrer" href="https://unsplash.com">
              Unsplash
            </a>
          </p>
        </div>
      </NavLine>

      {imageSearchMutation.isLoading ? (
        <LoadingScreen />
      ) : (
        <SearchResultsContainer>
          <ResultsWrapper>
            {imageSuggestions.map(imgData => (
              <MiniatureCard>
                <ImageWrapper
                  onClick={() => {
                    setSelectedImageUrl(imgData.imageUrl);
                    setPermitUpload(true);

                    setUploadAssets({
                      filesUpload: [
                        {
                          url: imgData.imageUrl,
                          meta: {
                            source: "unsplash",
                            tracking_url: imgData.downloadTrackingUrl,
                            source_url: imgData.imageUrl,
                            attribution: imgData.attribution
                              ? {
                                  author_name: imgData.attribution.authorName,
                                  author_url: imgData.attribution.authorUrl,
                                  provider_url: imgData.attribution.providerUrl,
                                  provider_name: "Unsplash",
                                }
                              : undefined,
                          },
                        },
                      ],
                      isExternalUrl: true,
                      onUpload: async () => {
                        if (!imgData.downloadTrackingUrl || !process.env.REACT_APP_UNSPLASH_CLIENT_ID) {
                          return;
                        }
                        await axios.get(imgData.downloadTrackingUrl, {
                          headers: {
                            Authorization: `Client-ID ${process.env.REACT_APP_UNSPLASH_CLIENT_ID}`,
                          },
                        });
                      },
                    });
                  }}
                  active={imgData.imageUrl === selectedImageUrl}
                >
                  <img alt="" src={imgData.thumbUrl} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                </ImageWrapper>

                {imgData.attribution.authorUrl ? (
                  <p style={{ color: "#666", fontSize: 12, textAlign: "center" }}>
                    Photo by{" "}
                    <a target="_blank" rel="noreferrer" href={imgData.attribution.authorUrl}>
                      {imgData.attribution.authorName}
                    </a>
                  </p>
                ) : null}
              </MiniatureCard>
            ))}
          </ResultsWrapper>
        </SearchResultsContainer>
      )}
    </>
  );
};

UnsplashSection.propTypes = {
  handleImageSelection: PropTypes.func,
};

UnsplashSection.defaultProps = {
  handleImageSelection: () => {},
};

export default UnsplashSection;
