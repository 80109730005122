import { getStickyPanelContentStyles } from "components/_new/responsiveStyle";
import styled from "styled-components";

export const Flex = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  z-index: 10;
  flex-grow: 1;
  // border-bottom: 2px solid #eaeaea;
  padding: 0 15px;
  background-color: white;
  align-items: center;
  justify-content: space-between;

  &:after {
      content: '';
      position: absolute;
      bottom: -20px;
      left: 0px;
      width: 100%;
      height: 20px;
      background-image: linear-gradient(#FFFFFFFF, #FFFFFF00);
  }

  ${getStickyPanelContentStyles()}
`;
