// @ts-check

export class StayOverrideRequestDto {
  /**
   * @type {string}
   */
  short_description;

  /**
   * @type {string}
   */
  long_description;
}