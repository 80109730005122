import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import AddIcon from "@material-ui/icons/Add";

import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";

import { H6, BodyText2 } from "components/ui/Typography/Typography";
import { Button } from "feature/panel/_shared/MapWidget_old/styledComponents";

import { colors } from "config/theme/colors";
import { MAP_CONTENT } from "constants/content";
import { MapEvent } from "feature/panel/_shared/MapWidget_old/MapEvent";
import { useDispatch } from "react-redux";
import { setNotification } from "store/app/actions";

const StyledWrapper = styled.div`
  width: 100%;
  min-width: 160px;
  min-height: 120px;
  background: ${colors.white};
  color: ${colors.black};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const HeaderSection = styled.div``;

const BodySection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ActionSection = styled.div``;

const StyledFileCopyOutlinedIcon = styled(FileCopyOutlinedIcon)`
  && {
    color: ${colors.grey50};
    cursor: pointer;

    path {
      pointer-events: none;
    }
  }
`;

const StyledBodyText2 = styled(BodyText2)`
  && {
    line-height: 16px;
    margin-bottom: 4px;
  }
`;

const CustomInfoWindow = ({ item, formatted_address, onButtonClick }) => {
  const { latitude, longitude } = item;
  const dispatch = useDispatch();

  const handleCopyCoords = () => {
    navigator.clipboard.writeText(`${latitude}, ${longitude}`);
    dispatch(
      setNotification({
        type: "success",
        message: "Coordinates copied",
      }),
    );
  };

  const handleClick = () => {
    if (onButtonClick) onButtonClick({ formatted_address, longitude, latitude });
  };

  return (
    <StyledWrapper>
      <HeaderSection>
        <H6>{MAP_CONTENT.coordinates}</H6>
      </HeaderSection>
      <BodySection>
        <span>
          <StyledBodyText2 cv="grey100">{latitude}</StyledBodyText2>
          <StyledBodyText2 cv="grey100">{longitude}</StyledBodyText2>
        </span>
        <StyledFileCopyOutlinedIcon onClick={handleCopyCoords} />
      </BodySection>
      <ActionSection>
        <Button primary component="button" onClick={handleClick} fullWidth>
          <AddIcon />
          <span>{MAP_CONTENT.copyToField}</span>
        </Button>
      </ActionSection>
    </StyledWrapper>
  );
};

CustomInfoWindow.defaultProps = {
  marker: undefined,
  location: null,
};

CustomInfoWindow.propTypes = {
  location: PropTypes.shape({
    geometry: PropTypes.shape({
      location: PropTypes.shape({
        lat: PropTypes.func,
        lng: PropTypes.func,
      }).isRequired,
    }).isRequired,
  }),
  marker: PropTypes.shape({
    position: PropTypes.shape({
      lat: PropTypes.func,
      lng: PropTypes.func,
    }),
  }),
};

export { CustomInfoWindow, StyledWrapper, HeaderSection, BodySection, ActionSection, StyledBodyText2 };
