import styled from "styled-components";
import { getMediaPx } from "helpers";

export const responsiveConfig = {
  mediaPx: {
    laptop: 950, // show hamburger on this resolution
    tablet: 768,
  },
};

export const TabletView = styled.div`
  display: none;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    display: flex;
  }
`;
