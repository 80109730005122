import { colors } from "config/theme/colors";
import { makeStyles } from "@material-ui/core/styles";

const { success, error, warning, info, brand } = colors;

export const useVariants = makeStyles({
  variantSuccess: { backgroundColor: success },
  variantError: { backgroundColor: error },
  variantWarning: { backgroundColor: warning },
  variantInfo: { backgroundColor: info },
  variantBrand: { backgroundColor: brand },
});

const options = {
  maxSnack: 6,
  dense: false,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "right",
  },
};

export default options;
