import React, { useEffect } from "react";
import { isEmpty } from "lodash";
import Input from "components/ui/Inputs/TextInput";
import SelectInput from "components/ui/Inputs/Select";
import IconSelectOption from "components/_new/IconSelectOption";
import { useQuery } from "@tanstack/react-query";
import { DeleteOutline } from "@mui/icons-material";
import TableAction from "components/_new/Table/Action";
import { DnDItemWrap, DocumentsActions, DocumentsInputs } from "./style";
import { DEFAULT_ACTION_ICON_ID } from "../../../../../Stays/_shared/initStaysState";
import { DOCUMENTS_FILE_TYPES } from "../../../../../../../constants/defaults";
import { renderAddFileMini } from "../../Locations/ListOfLocations/item";

const DocumentItem = ({
  control,
  setValue,
  watch,
  storyBoardItemId,
  index,
  watchNameDocuments,
  disabled,
  onDelete,
  onMultiUpload,
  multiUpload,
  trigger,
  storyboardErrors,
  storyboardItemIndex,
  errors,
  clearErrors,
  addButtonClicked,
  setAddButtonClicked,
}) => {
  const watchCurrentDocument = `${watchNameDocuments}.${index}`;
  const file = watch(`${watchNameDocuments}.${index}.file`);
  const fileName = watch(`${watchNameDocuments}.${index}.name`);
  const itemError = errors?.storyboard?.details?.[storyboardItemIndex]?.documents?.[index];
  const { data: { items: icons } = {} } = useQuery({ queryKey: ["icon?count=500"] });
  const iconsModified = icons
    ?.filter(item => item.section === "navigation")
    ?.map(item => ({
      ...item,
      name: <IconSelectOption src={item.url} />,
    }));

  useEffect(() => {
    if (!fileName && file) {
      clearErrors(`${watchNameDocuments}.${index}.name`);
      setValue(`${watchNameDocuments}.${index}.name`, file.file_name);
    }
  }, [file]);

  const handleConfirm = (files, onChange) => {
    if (!Array.isArray(files)) {
      onChange(files);
    } else {
      const remainingFiles = files.filter((file, index) => index !== 0);
      onMultiUpload(remainingFiles);
      onChange(files[0]);
    }
  };

  const reValidate = () => {
    if (!isEmpty(storyboardErrors) && trigger) trigger();
  };

  const handleDeleteItem = () => {
    onDelete(index);
    reValidate();
  };

  const reValidateOnChange = () => {
    setAddButtonClicked(false);
    if (isEmpty(storyboardErrors)) return;
    reValidate();
  };

  return (
    <DnDItemWrap className={itemError && "dnd-error"}>
      <DocumentsInputs>
        <SelectInput
          label="Icon"
          options={iconsModified}
          optionLabelVar="name"
          optionValueVar="id"
          control={control}
          styleContainer={{ minWidth: 100 }}
          isClearable={false}
          name={`${watchCurrentDocument}.icon_id`}
          disabled={disabled}
          defaultValue={DEFAULT_ACTION_ICON_ID}
        />
        <Input
          label="Title"
          control={control}
          name={`${watchCurrentDocument}.name`}
          disabled={disabled}
          customOnChange={reValidateOnChange}
          autoFocus={addButtonClicked && !fileName}
          onBlur={() => setAddButtonClicked(false)}
        />
      </DocumentsInputs>
      {/* <Controller
        control={control}
        rules={{
          required: true,
        }}
        name={`${watchCurrentDocument}.file`}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <AttachButton
            allowedFileTypes={DOCUMENTS_FILE_TYPES}
            types={["library", "upload", "webpage"]}
            text="Attach / link"
            file={value}
            onDelete={() => onChange(null)}
            onConfirm={files => handleConfirm(files, onChange)}
            buttonWrapStyle={{ gridArea: "button" }}
            error={error}
            disabled={disabled}
            hideDescription
            multiUpload={multiUpload}
          />
        )}
      /> */}
      <DocumentsActions>
        {renderAddFileMini({
          control,
          name: `${watchCurrentDocument}.file`,
          watchItem: file,
          handleDeleteFile: () => setValue(`${watchCurrentDocument}.file`, null),
          mediaTypes: DOCUMENTS_FILE_TYPES,
          disabled,
          multiUpload,
          onCustomConfirm: handleConfirm,
        })}
        {!disabled && <TableAction element={<DeleteOutline />} onClick={handleDeleteItem} />}
      </DocumentsActions>
    </DnDItemWrap>
  );
};

export default DocumentItem;
