import React, { useState } from "react";
import { SwitchAction } from "components/ui/Tables";
import { SecondaryText, Title, Wrap } from "./style";
import angleIcon from "../../../../../assets/icons/angle-right.svg";

const MobileTableItem = ({ item, columns, onRowClick }) => {
  const [isOpened, setIsOpened] = useState(false);

  const getColumnValue = (columns, index) => {
    const column = columns?.[index];
    if (!column) return "";
    if (column.renderCell) {
      return column.renderCell(item);
    }

    return item[column.id];
  };

  const iconUrl = item[columns.find(item => item.type === "icon")?.id]?.url;
  const startIndex = iconUrl ? 1 : 0;
  const title = getColumnValue(columns, startIndex);
  const underTitle = getColumnValue(columns, 1 + startIndex) + ", " + getColumnValue(columns, 2 + startIndex);
  const lastLine = getColumnValue(columns, 3 + startIndex);

  const rest = columns.slice(startIndex + 4)?.filter(item => !item.isAction);
  const actions = columns.filter(item => item.isAction);
  return (
    <Wrap onClick={() => setIsOpened(!isOpened)}>
      {iconUrl && (
        <div style={{ marginRight: 15, paddingTop: 2 }}>
          <img src={iconUrl} alt="icon" style={{ width: 18, height: 18 }} />
        </div>
      )}

      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <div>
          <Title>{title}</Title>
          <SecondaryText>{underTitle}</SecondaryText>
          <SecondaryText>{lastLine}</SecondaryText>
          {isOpened && (
            <div style={{ marginTop: 15 }}>
              {rest.map((column, index) => {
                const value = item[column.id];
                return (
                  <SecondaryText style={{ color: "#000" }}>
                    {column.renderCell
                      ? column.renderCell(item)
                      : column.format && typeof value === "number"
                      ? column.format(value)
                      : value}
                  </SecondaryText>
                );
              })}
            </div>
          )}
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {actions?.map(item => item.renderCell())}
          <img src={angleIcon} style={{ marginRight: 15, marginLeft: 15, transform: `rotate(${isOpened ? 90 : 0}deg)` }} alt="angle-icon" />
        </div>
      </div>
    </Wrap>
  );
};

export default MobileTableItem;
