import React from "react";
import { colors } from "config/theme/colors";
import { isEmpty } from "lodash";
import { getMediaPx } from "helpers";
import moment from "moment";
import styled from "styled-components";
import uuidv4 from "uuid";
import { generateUniqueId } from "utils";
import { combineMessagingEmailsList, convertToFileObject } from "utils/dataConverters";
import DateTimeInput from "components/ui/Inputs/DateTimeInput";
import SelectInput from "components/ui/Inputs/Select";
import Input from "components/ui/Inputs/TextInput";
import { calculateStartingPointChange, calculateStartingPointValue } from "utils/notifications";
import { formFilePayloadObject } from "utils/library";
import { encodeParameter } from "utils/url";
import { transformStoryBoardDisplayableValue, transformStoryBoardIconShowValues } from "../Trips/_shared/helpers";

export const inputTypes = {
  text: <Input />,
  select: <SelectInput />,
  date: <DateTimeInput type="date" />,
  time: <DateTimeInput type="time" />,
};

export const hasDuplicate = (array, propertyName) => {
  const filteredArray = array.filter((item, index, arr) => arr.findIndex(t => t[propertyName] === item[propertyName]) !== index);

  return filteredArray.length > 0;
};

export const s3preparedUrl = s3Url =>
  s3Url.replace(/^.+\/(.+?)$/, (matched, filename, offset, str) => {
    let name = null;
    let ext = null;

    if (filename.includes(".")) {
      [, name, ext] = /(.+)\.([a-zA-Z0-9]+?)$/.exec(filename);
    } else {
      name = filename;
    }

    return str.replace(filename, encodeParameter(name)) + (ext ? `.${ext}` : "");
  });

export const modifyInpuNameToUseDefaultItinerary = (name, sholudAddMain) => {
  return sholudAddMain ? "main." + name : name;
};

export const getDirectoryByType = (directories, type) =>
  directories
    ?.filter(item => item.type === type)
    .map(item => ({
      ...item,
      children: item.children ? getDirectoryByType(item.children, type) : [],
      actions: item.actions.map(action => ({
        ...action,
        isChecked: true,
        file: convertToFileObject(action, true),
        icon_id: action.icon_id,
      })),
    }));

export const onDragEnd = (list, setValue, valueName) => {
  const reordered = {};

  list.forEach(item => (reordered[item.id] = item));

  setValue(valueName, reordered);
};

export const mergeTravellersByName = data => {
  const transformed = data.reduce((acc, person) => {
    const { name, email, ...rest } = person;
    if (!acc[name]) {
      acc[name] = { id: Object.keys(acc).length + 1, name, emails: [email], travellersData: [{ email, ...rest }] };
    } else {
      acc[name].emails.push(email);
      acc[name].travellersData.push({ email, ...rest });
    }
    return acc;
  }, {});

  const res = Object.values(transformed)
    .sort((a, b) => a.id - b.id)
    .map(({ emails, ...person }) => ({
      ...person,
      email: emails.join(", "),
    }));

  return res;
};

export const mergeObjectsByEmail = arr => {
  const mergedObjects = {};

  // Iterate over the array of objects
  arr.forEach(item => {
    const { name, email } = item;
    // Check if the name already exists in mergedObjects
    if (mergedObjects.hasOwnProperty(name)) {
      // If the name exists, merge the emails
      mergedObjects[name].email.push(email);
    } else {
      // If the name doesn't exist, create a new entry in mergedObjects
      mergedObjects[name] = {
        ...item,
        name,
        email: [email],
      };
    }
  });

  // Convert mergedObjects back to an array
  const resultArray = Object.values(mergedObjects).map(obj => ({
    ...obj,
    name: obj.name,
    email: obj.email.join(", "),
  }));

  return resultArray;
};

export const findObjectAndModifyChildren = (array, name, newItem) => {
  for (const obj of array) {
    const { name: objName, children } = obj;

    if (objName === name) {
      if (children) {
        obj.children.push(newItem); // Modify children array
        return array; // Return modified array
      } else {
        obj.children = [newItem]; // Create children array and push newItem
        return array; // Return modified array
      }
    }

    if (children) {
      const foundInChildren = findObjectAndModifyChildren(children, name, newItem);
      if (foundInChildren) {
        return array;
      }
    }
  }

  return null; // Object not found
};

export const getCombinedField = (data, f1, f2) => {
  const field1 = data?.[f1];
  const field2 = data?.[f2];

  if (!field1 && !field2) { return "" }
  if (field1 && !field2) { return field1 }
  if (!field1 && field2) { return field2 }

  return `${field1} ${field2}`;
}

export const modifyData = data => {
  return {
    _internal: {
      // These are kept to support the legacy fields for older API integrations
      originalFields: [data.field1 ?? "", data.field2 ?? "", data.field3 ?? "", data.field4 ?? ""]
    },
    ...(data || {}),
    field1: getCombinedField(data, "field1", "field2"),
    field2: "",
    field3: getCombinedField(data, "field3", "field4"),
    field4: "",
    general: {
      ...data?.general,
      meta: data?.general?.meta ?? {},
    },
    start_time: moment(data?.start_time || "12:00", "HH:mm").toDate(),
    documents: {
      travel: data?.documents?.travel?.map(item => {
        // meta.restricted_to_traveller_internal_ids = "restricted_to_traveller_ids" in meta ? meta.restricted_to_traveller_ids : null;

        // TODO Need to understand why modifyData is called multiple times
        // Keep only 1 call and then simplify this logic (also in destination docs, flights and in details)

        const meta = item.meta || {};

        if (data.type === "trip") {
          let applicableRestrictedIds = null;

          if ("restricted_to_traveller_ids" in meta) {
            applicableRestrictedIds = meta.restricted_to_traveller_ids;
          } else if ("restricted_to_traveller_internal_ids" in meta) {
            applicableRestrictedIds = meta.restricted_to_traveller_internal_ids;
          }
          meta.restricted_to_traveller_internal_ids = applicableRestrictedIds;
          delete meta.restricted_to_traveller_ids;
        } else if (data.type === "stay") {
          delete meta.restricted_to_traveller_internal_ids;
        }

        return {
          name: item.name,
          id: item.id,
          meta,
          file: convertToFileObject(item, true, true, true),
        };
      }),
      destination: data?.documents?.destination?.map(item => {
        const meta = item.meta || {};

        if (data.type === "trip") {
          let applicableRestrictedIds = null;
          if ("restricted_to_traveller_ids" in meta) {
            applicableRestrictedIds = meta.restricted_to_traveller_ids;
          } else if ("restricted_to_traveller_internal_ids" in meta) {
            applicableRestrictedIds = meta.restricted_to_traveller_internal_ids;
          }
          meta.restricted_to_traveller_internal_ids = applicableRestrictedIds;
          delete meta.restricted_to_traveller_ids;
        } else if (data.type === "stay") {
          delete meta.restricted_to_traveller_internal_ids;
        }

        return {
          name: item.name,
          id: item.id,
          meta,
          file: convertToFileObject(item, true, true, true),
        };
      }),
    },
    passcode_groups: data?.passcode_groups?.map(item => ({ id: uuidv4(), ...item })),
    locations: data?.locations.map(
      ({ loc_position, created_at: cat, updated_at: uat, operator_id: pi, itinerary_id, library_node, ...rest }) => ({
        ...rest,
        file: convertToFileObject(library_node, true, false, true),
        coordinates: `${rest.latitude}, ${rest.longitude}`,
        internal_id: rest.id,
      }),
    ), // make coordinates as one string
    directory: getDirectoryByType(data?.directories, "directory"),
    daily: getDirectoryByType(data?.directories, "daily"),
    voucher: getDirectoryByType(data?.directories, "voucher"),
    features: data?.features.reduce((acc, currentItem) => {
      acc[currentItem.id] = currentItem;
      return acc;
    }, {}),
    flights: data?.flights.map(item => {
      let applicableRestrictedIds = null;
      if (item.restricted_to_traveller_ids) {
        applicableRestrictedIds = item.restricted_to_traveller_ids;
      } else if (item.restricted_to_traveller_internal_ids) {
        applicableRestrictedIds = item.restricted_to_traveller_internal_ids;
      }
      item.restricted_to_traveller_internal_ids = applicableRestrictedIds;
      delete item.restricted_to_traveller_ids;

      return {
        ...item,
        restricted_to_traveller_internal_ids: applicableRestrictedIds,
      };
    }),
    details: data?.details.map((item, idx) => {
      const image = { ...item.image };
      if (image?.file?.meta?.attribution) delete image.file.meta.attribution;

      let updatedContent = item?.content;
      let updatedContentType = item?.content_type;
      if (item?.content_type === "text/plain") {
        const internalContentArea = document.createElement("internal-content-area");
        internalContentArea.innerText = item?.content;
        updatedContent = internalContentArea.innerHTML;
        updatedContentType = "text/html";
      }

      return {
      ...item,
      content: updatedContent,
      content_type: updatedContentType,
      documents: item?.documents?.map(doc => ({
        ...doc,
        file: convertToFileObject(doc, true, false, true),
      })),
      image: image ? convertToFileObject(image, true) : item?.image,
      location: item.location?.id || "none",
      headline: item.meta.hide_day_info ? "#" + item.headline : (item.headline || ""),
      meta: (() => {
        const meta = item.meta || {};
        meta.day_number = item.meta.day_number ?? item.position ?? idx + 1;

        let applicableRestrictedIds = null;
        if ("restricted_to_traveller_ids" in meta) {
          applicableRestrictedIds = meta.restricted_to_traveller_ids;
        } else if ("restricted_to_traveller_internal_ids" in meta) {
          applicableRestrictedIds = meta.restricted_to_traveller_internal_ids;
        }
        meta.restricted_to_traveller_internal_ids = applicableRestrictedIds;
        delete meta.restricted_to_traveller_ids;
        return meta;
      })(),
      position: idx + 1,
    }}), // set location as ID value but not object, so select input can read it
    travellers: mergeTravellersByName(
      data?.travellers.map(({ created_at: cat, updated_at: uat, operator_id: pi, itinerary_id, id, ...rest }) => ({
        ...rest,
        internal_id: id,
      })),
    ),
    notifications: data?.notifications.map(({ location_id, ...rest }) => ({
      ...rest,
      ...(location_id && { location_internal_id: location_id }),
    })),
    meta: {
      ...data?.meta,
      show_dnd: data?.meta.show_dnd === "off" ? false : true,
      ...(data.meta.hotel_info_icon_id && {
        hotel_info_icon_id: +data?.meta.hotel_info_icon_id,
      }),
      ...(data?.meta.destination_documents_icon_id && {
        destination_documents_icon_id: +data?.meta.destination_documents_icon_id,
      }),
      ...(data?.meta.travel_documents_icon_id && {
        travel_documents_icon_id: +data?.meta.travel_documents_icon_id,
      }),
    },
    logo: data?.logo ? convertToFileObject(data.logo, true) : data.logo,
    background: data?.background ? convertToFileObject(data.background, true) : data.background,
  }
};

export const splitObjectsByCommaSeparatedEmails = arr => {
  const resultArray = [];

  arr?.forEach(item => {
    const { name, email } = item;

    // Split the comma-separated emails into an array
    const emailArray = email?.split(",").map(email => email.trim());

    // Create a new object for each email
    emailArray?.forEach(emailValue => {
      resultArray.push({
        ...item,
        name,
        email: emailValue,
      });
    });
  });

  return resultArray;
};

export const prepareLocationFromStay = currentStay => {
  const id = generateUniqueId();

  return {
    internal_id: id,
    id,
    country: "",
    country_iso: currentStay.countryIso,
    name: currentStay.name,
    location: "",
    coordinates: `${currentStay.latitude}, ${currentStay.longitude}`,
    latitude: currentStay.latitude,
    longitude: currentStay.longitude,
    on_weather: true,
    on_maps: true,
    isNewNestedLocation: true,
    nested: {
      vamoos_id: currentStay.vamoos_id || currentStay.vamoosId,
      type: "stay",
      operator_code: currentStay.operator_code || currentStay.operatorCode,
      reference_code: currentStay.reference_code || currentStay.referenceCode,
    },
  };
};

export const TableWrap = styled.div``;

export const MobileItemWrap = styled.div`
  padding: 15px;
  /* margin-bottom: 15px; */
  border-bottom: 1px solid ${colors.grey10};
  position: relative;
  display: none;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    display: block;
    ${p => p.customStyles};
  }
`;

export const MobileItemTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 15px;
`;
export const MobileSecondaryTitle = styled.div`
  font-size: 15px;
  color: ${colors.grey40};
`;

export const defaultValues = {
  _internal: {
    busy: false
  },
  general: {
    type: "trip",
    main: {},
    branding_profile_id: "default",
    language: "",
    operator_code: "",
    passcode: "",
    field1: "",
    field3: "",
    departure_date: moment()
      .startOf("day")
      .toDate(),
    return_date: moment()
      .add(1, "day")
      .startOf("day")
      .toDate(),
    start_time: moment()
      .hour(12)
      .minute(0)
      .second(0)
      .toDate(),
    client_reference: "",
    meta: {},
    background: null,
    logo: "",
    reference_code: "",
    preview_link: "",
    timezone: "",
    is_active: true,
    is_listed: false,
    requested_listing_status: null,
    icons_to_show: "",
    vamoos_id: 0,
    routing: {},
  },
  inspiration: {
    inspiration: {
      contact_email: "",
      contact_phone: "",
      created_at: "",
      data: {},
      id: 0,
      meta: {},
      name: "",
      notification_text: "",
      operator_code: "",
      operator_id: 0,
      reference_code: "",
      travellers: [],
      updated_at: "",
      vamoos_id: 0,
    },
  },
  notifications: {
    notifications: [],
  },
  documents: {
    documents: {},
  },
  directories: {
    directory: [],
    daily: [],
    voucher: [],
  },
  passcode_groups: {
    passcode_groups: [],
  },
  features: {
    features: [],
  },
  locations: {
    locations: [],
    pois: [],
  },
  storyboard: {
    details: [],
  },
  people: {
    travellers: [],
  },
  flights: {
    flights: [],
  },
  actions: {
    meta: {
      travel_documents_label: "",
      destination_documents_label: "",
    },
    documents: {},
  },
  localInspiration: {},
  messages: {
    meta: { messaging_emails_list: [] },
  },
};

// Need this to kepp all fields in different sections: general, documents etc.
// So we can validate all fields by section
export const getData = data => {
  const result = {};

  for (const key in defaultValues) {
    const item = defaultValues[key];
    const value = {};

    Object.entries(item).forEach(item => {
      const entryKey = item[0];
      const entryValue = item[1];
      const isEntryValueEmpty = !Object.keys(entryValue ?? {}).length;

      if (typeof entryValue !== "object" || Array.isArray(entryValue)) {
        value[entryKey] = data?.[entryKey] ?? entryValue;
      } else {
        if (!value[entryKey]) value[entryKey] = isEntryValueEmpty ? data?.[entryKey] : {};
        for (const subKey in entryValue) {
          if (data?.[entryKey] != null) value[entryKey][subKey] = data[entryKey][subKey];
        }
      }
    });

    result[key] = value;
  }

  if (data.hasOwnProperty("inspiration") && !isEmpty(data.inspiration)) {
    const inspirationNotification = data?.notifications?.find(item => item.type === "inspiration");

    result.localInspiration = inspirationNotification
      ? {
          ...inspirationNotification,
          delivery_at_days: Math.abs(inspirationNotification?.delivery_at_days),
          period: calculateStartingPointValue(inspirationNotification?.delivery_at_relative_to, inspirationNotification?.delivery_at_days),
        }
      : {
          inspiration_vamoos_id: data.inspiration.vamoos_id,
          period: "disabled",
          content: "",
          delivery_at_days: 0,
        };
  }

  if (data.type === "trip") {
    result.general.icons_to_show = transformStoryBoardDisplayableValue(data.meta.show_story_board_summary, data.meta.show_story_board_daily);
    delete result?.meta?.show_story_board_daily;
    delete result?.meta?.show_story_board_summary;
  }

  const isMessagingEmailsListValid = Array.isArray(data?.meta?.messaging_emails_list);
  if (!isMessagingEmailsListValid) result.messages.meta.messaging_emails_list = [];
  if (data?.meta?.messaging_email_during || data?.meta?.messaging_email_before_after) {
    if (!isMessagingEmailsListValid) {
      result.messages.meta.messaging_emails_list =
        combineMessagingEmailsList(
          data?.meta?.messaging_email_during ?? "",
          data?.meta?.messaging_email_before_after ?? "")
        ?? [];
    } else {
      const listOfDuringEmailsFromString = data?.meta?.messaging_email_during.split(",").filter(email => email);
      const listOfBeforeAndAfterEmailsFromString = data?.meta?.messaging_email_before_after.split(",").filter(email => email);

      const updatedMessagingList = data.meta.messaging_emails_list.map(item => {
        return {
          email: item.email,
          during: listOfDuringEmailsFromString.includes(item.email),
          before_after: listOfBeforeAndAfterEmailsFromString.includes(item.email),
          id: uuidv4(),
        }
      });

      const existingEmails = updatedMessagingList.map(item => item.email);
      [...listOfDuringEmailsFromString, ...listOfBeforeAndAfterEmailsFromString].forEach(email => {
        if (!existingEmails.includes(email)) {
          updatedMessagingList.push({
            email,
            during: listOfDuringEmailsFromString.includes(email),
            before_after: listOfBeforeAndAfterEmailsFromString.includes(email),
            id: uuidv4(),
          });
        }
      });

      result.messages.meta.messaging_emails_list = updatedMessagingList;
    }
  }
  return result;
};

const alwaysVisibleFields = {
  trip: [
    "background",
    "client_reference",
    "departure_date",
    "field1",
    "field3",
    "meta",
    "return_date",
    "start_time",
    "timezone",
    "type",
    "documents",
    "language",
    "logo",
    "is_active",
  ],
  stay: [
    "background",
    "branding_profile_id",
    "departure_date",
    "field1",
    "logo",
    "meta",
    "return_date",
    "features",
    "locations",
    "directories",
    "language",
    "is_active",
  ],
  stayLng: ["background", "departure_date", "field1", "meta", "return_date", "directories", "language", "is_active"],
};

const getNotifications = (payload, fieldsToCopy) => {
  const { notifications, inspirations } = fieldsToCopy;
  const updatedNotifications = payload.notifications?.map(notification => ({
    ...notification,
    tag: null,
  }));

  if (notifications && inspirations) {
    return updatedNotifications;
  } else if (notifications) {
    return updatedNotifications.filter(item => item.type !== "inspiration");
  } else if (inspirations) {
    return updatedNotifications.filter(item => item.type === "inspiration");
  } else {
    return [];
  }
};

const clearTagProperties = data => {
  const result = { ...data };

  const processItems = items => {
    items.forEach(item => {
      if (item.hasOwnProperty("tag")) {
        item.tag = null;
      }
      if (Array.isArray(item.children) && item.children.length) {
        processItems(item.children); // Recursively process nested children
      }
    });
  };

  for (const key in result) {
    if (Array.isArray(result[key]) && result[key].length) {
      processItems(result[key]);
    }
  }
  return result;
};

const prepareDataForCopy = (fieldsToCopy, payload, type, copyToOtherOperator, newVamoosId) => {
  const copied_from = payload.vamoos_id;

  // for trips
  const storyboardDocumentsChecked = fieldsToCopy?.storyboard?.documents;
  const destinationDocuments = fieldsToCopy.destination_documents;
  const travelDocuments = fieldsToCopy.travel_documents;

  // for stays nothing for now

  delete payload.vamoos_id;
  delete payload.passcode;

  const visibleFields = newVamoosId ? alwaysVisibleFields.stayLng : alwaysVisibleFields[type];
  if (type === "trip" && !copyToOtherOperator && fieldsToCopy.locations) visibleFields.push("pois");

  const result = {};

  for (const key in payload) {
    const item = payload[key];

    if (fieldsToCopy[key] || visibleFields.includes(key)) {
      if (type === "trip") {
        if (key === "details" && !storyboardDocumentsChecked) {
          result[key] = item.map(item => ({ ...item, documents: [] }));
        } else if (key === "documents") {
          if (travelDocuments || destinationDocuments) {
            result[key] = {
              ...(travelDocuments && { travel: item.travel }),
              ...(destinationDocuments && { destination: item.destination }),
            };
          } else {
            result[key] = {};
          }
        } else {
          result[key] = item;
        }
      } else {
        if (key === "directories") {
          result["directories"] = payload["directories"].filter(item => fieldsToCopy[item.type]);
        } else if (key === "meta") {
          result["meta"] = Object.fromEntries(Object.entries(item).filter(([k, v]) => v !== undefined));
        } else if (key === "field1") {
          result["field1"] = item ? item : null;
        } else if (key === "locations") {
          result[key] = item.map(({ latitude, longitude, location, name, on_maps, on_weather }) => ({
            latitude,
            longitude,
            location,
            name,
            on_maps,
            on_weather,
          }));
        } else {
          result[key] = item;
        }
      }
    } else {
      if (key === "inspiration_vamoos_id") {
        if (fieldsToCopy.inspirations) result[key] = item;
      } else {
        const type = typeof item;
        switch (type) {
          case "number":
            result[key] = 0;
            break;
          case "string":
            result[key] = "";
            break;
          case "boolean":
            result[key] = false;
            break;
          case "object":
            if (item === null) {
              result[key] = null;
            } else if (Array.isArray(item)) {
              result[key] = [];
            } else {
              result[key] = {};
            }
            break;
          default:
            result[key] = null;
        }
      }
    }
  }

  // if operator did not copy people set all restrictions to null
  if (type === "trip") {
    const isCopyIncludesTravelPeople = fieldsToCopy?.travellers;
    const restrictableSectionsMapper = [
      { key: "details", payloadKey: "details", hasMetaProperty: true },
      { key: "flight_ids", payloadKey: "flight_ids", hasMetaProperty: false },
      { key: "travel", payloadKey: "travel_documents", hasMetaProperty: true },
      { key: "destination", payloadKey: "destination_documents", hasMetaProperty: true },
    ];

    if (!isCopyIncludesTravelPeople) {
      for (const section of restrictableSectionsMapper) {
        if (fieldsToCopy.hasOwnProperty(section.payloadKey)) {
          const { key, payloadKey, hasMetaProperty } = section;
          const targetArray = payloadKey.includes("documents") ? result.documents[key] : result[key];

          if (targetArray) {
            targetArray.forEach(item => {
              if (hasMetaProperty) {
                item.meta.restricted_to_traveller_internal_ids = null;
              } else {
                item.restricted_to_traveller_internal_ids = null;
              }
            });
          }
        }
      }
    }
  }

  const resultWithNullableTags = clearTagProperties(result);
  return {
    ...resultWithNullableTags,
    meta: { ...resultWithNullableTags.meta, copied_from },
    notifications: getNotifications(payload, fieldsToCopy),
  };
};

const getDocumentForSubmit = (item, copyToOtherOperator) => {
  if (!item || ((!item?.file_id || !item.library_node_id || !item.file_name) && !item.file)) return;
  // const { name, file_id, library_node_id, s3_url } = convertToFileObject(item) || {};
  const { alias_for_id, name, file_id, file_name, is_library_file, library_node_id, file_url } = item?.file || item;
  const { isConverted, ...fileWithoutConverted } = item?.file || {};
  const { web_url, id, s3_url, file_meta } = fileWithoutConverted || {};
  const { attribution, ...cleanFileMeta } = file_meta || {};

  if (isConverted) {
    if (fileWithoutConverted?.file_meta?.attribution) delete fileWithoutConverted.file_meta.attribution;
    return formFilePayloadObject(
      {
        ...fileWithoutConverted,
        file_name: item.name || item.file_name,
        ...(item.meta && { meta: item.meta }),
      },
      copyToOtherOperator,
    );
  }

  if (copyToOtherOperator) {
    const s3File = s3_url ?? item?.file?.s3url;
    if (s3File) {
      return {
        file_url: s3File,
        name: file_name || item.name || name,
        ...(cleanFileMeta && { file_meta: cleanFileMeta }),
        ...(item.meta && { meta: item.meta }),
      };
    }
  }

  let file = {};

  if (is_library_file || alias_for_id || (id && !item.file_id && !s3_url)) {
    file = {
      is_public: false,
      library_node_id: +alias_for_id || item.alias_for_id || library_node_id,
      name: item.name || name || file_name,
      ...(item.meta && { meta: item.meta }),
    };
  } else if (item.file_id || file_id) {
    file = {
      is_public: false,
      file_id: item.file_id || file_id,
      name: item.name || name || file_name,
      ...(item.meta && { meta: item.meta }),
    };
  } else if (library_node_id) {
    if (item.web_url) {
      file = {
        name: item.name || name || file_name,
        web_url: item.web_url,
      };
    } else {
      const { s3url } = item.file;
      file = {
        is_public: false,
        file_url: s3url || s3_url,
        name: item.name,
        ...(item.meta && { meta: item.meta }),
      };
    }
  } else {
    const { name, meta } = item;

    if (web_url) {
      file = {
        web_url,
        name,
        ...(meta && { meta }),
      };
    } else if (file_url) {
      file = {
        is_public: false,
        file_url,
        name,
        ...(meta && { meta }),
      };
    } else {
      const { s3url } = item.file;
      file = {
        is_public: false,
        file_url: s3url || s3_url,
        name,
        ...(meta && { meta }),
      };
    }
  }

  return {
    ...file,
    ...(cleanFileMeta && { file_meta: cleanFileMeta }),
  };
};

const getActions = actions => {
  return actions?.map(action => ({
    is_public: false,
    icon_id: action.icon_id,
    name: action.name,
    ...(action.alias_for_id ? { library_node_id: +action.alias_for_id } : { file_url: action.file.s3_url }),
  }));
};

const getDirectoryArray = (arr, copyToOtherOperator) => {
  return arr?.map(({ children, content, is_enabled, is_list, background, actions, name, tag, type, weekdays, video }) => ({
    children: getDirectoryArray(children),
    content,
    is_enabled,
    is_list,
    name,
    tag,
    type,
    weekdays,
    background: getDocumentForSubmit(
      background?.file ? background : { name: background?.file_name || background?.name, file: background },
      copyToOtherOperator,
    ),
    video: getDocumentForSubmit(video?.file ? video : { name: video?.file_name || video?.name, file: video }, copyToOtherOperator),
    actions:
      actions?.length > 0
        ? actions
            .filter(action => action.isChecked)
            .map(action => {
              return {
                icon_id: action.icon_id,
                name: action.name,
                ...getDocumentForSubmit(action, copyToOtherOperator),
              };
            })
        : [],
  }));
};

const clearEmptyFields = obj => {
  Object.entries(obj).forEach(([key, value]) => {
    if (
      key !== "field1" &&
      key !== "background" &&
      (value === null || value === undefined || value === "" || (typeof value === "object" && Object.keys(value).length === 0))
    ) {
      delete obj[key];
    }
  });
  return obj;
};

export const prepareDataForSubmit = (data, fieldsToCopy = null, copyToOtherOperator = false, newVamoosId = null) => {
  if (data._internal) {
    delete data._internal;
  }
  
  const isNonMainStayLanguage = data.general.type === "stay" && data.hasOwnProperty("main") && data.general.language !== data.main.general.language;
  const iconsToShow = transformStoryBoardIconShowValues(data.general.icons_to_show);
  const destination_documents_icon_id = data.general.meta.destination_documents_icon_id || undefined;
  const travel_documents_icon_id = data.general.meta.travel_documents_icon_id || undefined;

  let result = {};

  for (const key in data) {
    // eslint-disable-next-line no-continue
    if (key === "main") continue;
    const item = data[key];

    if (key === "localInspiration") {
      const currentInspirationIndex = data.notifications?.notifications?.findIndex(item => item.type === "inspiration");
      const { delivery_at_days, delivery_at_relative_to } = calculateStartingPointChange(item, item.period);
      const inspiration_vamoos_id = data.localInspiration.inspiration_vamoos_id;
      let notifications = data.notifications?.notifications;

      const inspirationNotification = {
        delivery_at_days,
        delivery_at_relative_to,
        type: "inspiration",
        start_at: moment.isMoment(item.start_at) ? moment(item.start_at).format("HH:mm") : item.start_at,
        inspiration_vamoos_id,
        is_active: true,
        content: item.content,
        url: "",
      };

      if (inspiration_vamoos_id === "none" || isEmpty(item) || item.period === "disabled") {
        notifications = data.notifications?.notifications?.filter(item => item.type !== "inspiration");
      } else {
        if (currentInspirationIndex !== -1) {
          notifications[currentInspirationIndex] = {
            ...notifications[currentInspirationIndex],
            ...inspirationNotification,
          };
        } else {
          notifications.push(inspirationNotification);
        }
      }

      result = {
        ...result,
        ...(inspiration_vamoos_id && inspiration_vamoos_id !== "none" ? { inspiration_vamoos_id } : {}),
        notifications,
      };
    } else {
      for (const keyS in item) {
        if (result[keyS] && typeof result[keyS] === "object" && !Array.isArray(result[keyS])) {
          if (keyS === "meta") {
            result = { ...result, meta: { ...data?.general?.meta, ...item?.meta } };
          } else if (keyS === "documents" && result.type !== "stay") {
            result = { ...result, documents: { ...item?.documents, ...result?.documents } };
          } else {
            result = { ...result, [keyS]: { ...result[keyS], ...item[keyS] } };
          }
        } else {
          result = {
            ...result,

            // This is needed to avoid override object
            // As example: we have meta: {} in general default_values
            // and we have meta: {...smth} in direcoties default_values
            // the problem is second meta was overriding previous one
            // so with this fix we check if there is meta object already, if yes, we just add value to this

            // eslint-disable-next-line no-loop-func
            ...Object.entries(item).reduce((prev, curr) => {
              if (result[curr[0]]) {
                return {
                  ...prev,
                  [curr[0]]:
                    typeof result[curr[0]] === "object" &&
                    !Array.isArray(result[curr[0]]) &&
                    !["start_time", "return_date", "departure_date"].includes(curr[0])
                      ? {
                          ...result[curr[0]],
                          ...curr[1],
                        }
                      : curr[1],
                };
              } else {
                return {
                  ...prev,
                  [curr[0]]: curr[1],
                };
              }
            }, {}),
          };
        }
      }
    }
  }

  const flights = result.flights || [];
  const background = data.general.background
    ? getDocumentForSubmit(
        data.general.background?.file
          ? data.general.background
          : {
              name: data.general.background?.file_name || data.general.background?.name,
              file: data.general.background,
            },
        copyToOtherOperator,
      )
    : null;

  if (result.documents) {
    result.documents.travel = result.documents?.travel?.filter(v => !!v && !!v.file) ?? [];
    result.documents.destination = result.documents?.destination?.filter(v => !!v && !!v.file) ?? [];
  }
  delete result.reference_code;
  delete result.operator_code;
  delete result.inspiration;
  delete result.preview_link;
  delete result.user_id;
  delete result.flights;
  delete result.background;
  delete result.icons_to_show;
  delete result.passcode;
  delete result.routing;
  const directory = getDirectoryArray(result?.directory, copyToOtherOperator);
  const daily = getDirectoryArray(result?.daily, copyToOtherOperator);
  const voucher = getDirectoryArray(result?.voucher, copyToOtherOperator);

  let storyboardEntryPosition = 0;
  const preparedForSubmit = {
    ...result,
    source: "portal",
    directories: [...(directory || []), ...(daily || []), ...(voucher || [])],
    features: result.features ? Object.entries(result.features).map(item => ({ id: item[0], is_featured: item[1].is_featured })) : [],
    logo: result.logo
      ? getDocumentForSubmit(
          result.logo?.file
            ? result.logo
            : {
                name: result.logo?.file_name || result.logo?.name,
                file: result.logo,
              },
          copyToOtherOperator,
        )
      : null,
    branding_profile_id: result.branding_profile_id === "default" ? null : result.branding_profile_id,
    documents: {
      travel: result.documents?.travel?.filter(item => item.file).map(item => getDocumentForSubmit(item, copyToOtherOperator)) || [],
      destination:
        result.documents?.destination?.filter(item => item.file).map(item => getDocumentForSubmit(item, copyToOtherOperator)) || [],
    },
    passcode_groups: result?.passcode_groups?.map(({ name, display_rules, passcodes }) => ({ name, display_rules, passcodes })),
    notifications: result.notifications?.map(
      ({
        id,
        operator_id,
        created_at,
        updated_at,
        itinerary_id,
        inspiration_id,
        coordinates,
        location_internal_id,
        location,
        location_id,
        url,
        latitude,
        longitude,
        delivery_at,
        start_at,
        trip_notif,
        localisation,
        template_id,
        ...rest
      }) => {
        if (rest.use_global) {
          const fieldsToOverride =
            rest.type === "timed"
              ? ["content", "url", "delivery_at_days", "delivery_at_relative_to", "start_at"]
              : ["content", "url", "start_at", "end_at", "latitude", "longitude"];

          const item = { ...rest, template_id };
          fieldsToOverride.forEach(key => (item[key] = null));
          return item;
        }

        const latitudeOrLongitudeExist = latitude || longitude;
        const isCopyIncludesNotificationsWithoutLocation = fieldsToCopy?.notifications && !fieldsToCopy?.locations && location;

        const locationId = (() => {
          if (latitudeOrLongitudeExist) return undefined;
          return location_id || location ? location.id : undefined;
        })();

        return {
          ...rest,
          start_at,
          template_id: template_id ?? undefined,
          url: url || null,
          latitude: isCopyIncludesNotificationsWithoutLocation ? location?.latitude || undefined : latitude || undefined,
          longitude: isCopyIncludesNotificationsWithoutLocation ? location?.longitude || undefined : longitude || undefined,
          ...(isCopyIncludesNotificationsWithoutLocation
            ? {}
            : {
                location_internal_id: latitudeOrLongitudeExist || !location_internal_id ? undefined : location_internal_id,
                location_id: locationId,
              }),
        };
      },
    ),
    locations: result.locations?.map(
      (
        {
          id,
          name,
          location,
          latitude: lat,
          longitude: lon,
          position,
          country,
          meta,
          localisation,
          on_weather = false,
          on_maps = false,
          internal_id,
          file,
          coordinates,
          vamoos_id,
          nested,
          library_node,
        },
        index,
      ) => {
        const isStay = data.general.type === "stay";
        const extractedLat = coordinates?.split(",")[0]?.trim();
        const extractedLon = coordinates?.split(",")[1]?.trim();
        const latitude = isStay ? extractedLat : lat || extractedLat;
        const longitude = isStay ? extractedLon : lon || extractedLon;
        const vamoosId = vamoos_id ?? nested?.vamoos_id;

        return {
          location: isStay ? data.general.meta.address : location,
          name: isStay ? data.general.field1 : name,
          latitude,
          longitude,
          position: index,
          country,
          meta,
          localisation,
          on_weather,
          on_maps,

          ...(vamoosId ? { vamoos_id: vamoosId } : {}),
          internal_id: internal_id || id || generateUniqueId(),
          ...(file
            ? {
                file: getDocumentForSubmit({ file_name: file.file_name ?? file.name, file }, copyToOtherOperator),
              }
            : {}),
        };
      },
    ),
    details: result.details?.map((item, index) => {
      if (data.general.type === "stay") {
        return {
          headline: item.headline,
          image: (() => {
            const imageObj = item.image?.file
              ? item.image
              : {
                name: item.image?.file_name || item.image?.name,
                file: item.image,
              };
            const { https_url, ...rest } = imageObj;
            return getDocumentForSubmit(rest, copyToOtherOperator);
          })(),
        }
      } else {
        const location_internal_id = item.location === "none" ? null : item.location;

        const meta = {
          ...(item.meta || {}),
        };

        const headline = item?.headline === "" ? undefined : item?.headline;
        const hideDays = result.meta?.show_days === false;
        storyboardEntryPosition = Math.max(storyboardEntryPosition + 1, item.position ?? 0, parseInt(item.meta.day_number, 10));

        const output = {
          position: storyboardEntryPosition,
          location_internal_id,
          headline: !headline ? undefined : (headline?.charAt(0) === "#" ? headline.substring(1) : headline),
          content: item.content,
          content_type: "text/html",
          documents:
            item?.documents?.map(item => ({
              ...getDocumentForSubmit(item, copyToOtherOperator),
              icon_id: item.icon_id,
            })) || [],
          meta: {
            ...meta,
            hide_day_info: headline?.charAt(0) === "#",
          },
          ...(item.image
            ? {
              image: (() => {
                const imageObj = item.image?.file
                  ? item.image
                  : {
                    name: item.image?.file_name || item.image?.name,
                    file: item.image,
                  };
                const { https_url, ...rest } = imageObj;
                return getDocumentForSubmit(rest, copyToOtherOperator);
              })(),
            }
            : {}),
        };

        if (hideDays && output.meta?.day_number) {
          delete output.meta.day_number;
        }

        return output;
      }
    }),
    flight_ids: flights?.map(item => ({ id: item.id, restricted_to_traveller_internal_ids: item.restricted_to_traveller_internal_ids })),
    return_date: moment(result.return_date).format("YYYY-MM-DD"),
    departure_date: moment(result.departure_date).format("YYYY-MM-DD"),
    start_time: moment(result.start_time, "HH:mm").format("HH:mm"),
    travellers: result.travellers?.reduce((acc, { email, name, ...rest }) => {
      if (!email || email === "") {
        let travellerData = rest?.travellersData[0];
        if (!travellerData) {
          travellerData = { name };
        } else {
          travellerData.name = name;
          delete travellerData.email;
        }
        acc.push(travellerData);
      } else {
        // to remove all item with an empty email
        const travellersDataNonEmptyEmails = rest.travellersData
          .map(traveller => ({
            ...traveller,
            email: traveller.email.trim(),
          }))
          .filter(traveller => traveller.email !== "");

        const updatedTravellers = travellersDataNonEmptyEmails.map(traveller => ({
          ...traveller,
          name,
        }));
        acc.push(...updatedTravellers);
      }
      return acc;
    }, []).map(item => {
      const { email, ...rest } = item;
      return {
        ...rest,
        ...(email && { email }),
      };
    }),
    pois: result.pois?.map(({ id, is_on }) => ({ id, is_on })),
    meta: {
      ...result.meta,
      alert_emails: Array.isArray(result.meta.alert_emails) ? result.meta.alert_emails : result.meta.alert_emails?.split(","),
      show_dnd: result.meta?.show_dnd ? "during" : "off",
      ...(result.meta.dnd_cut_off_time && {
        dnd_cut_off_time: moment(result.meta.dnd_cut_off_time, "HH:mm").format("HH:mm"),
      }),
      ...(result.type === "stay"
        ? {
            ...(result.meta.check_in_time && {
              check_in_time: moment(result.meta.check_in_time, "HH:mm").format("HH:mm"),
            }),
          ...(destination_documents_icon_id && { destination_documents_icon_id }),
          ...(travel_documents_icon_id && { travel_documents_icon_id }),
          }
        : undefined),
      ...(data.general.type === "trip" ? iconsToShow || {} : {}),
      show_messaging:
        result.meta.messaging_emails_list?.length > 0
          ? result.meta.messaging_emails_list.find(item => item.before_after)
            ? "always"
            : "during"
          : "off",
      messaging_email_before_after: result.meta.messaging_emails_list
        ?.filter(item => item.before_after)
        ?.map(item => item.email)
        ?.join(","),
      messaging_email_during: result.meta.messaging_emails_list
        ?.filter(item => item.during)
        ?.map(item => item.email)
        ?.join(","),
    },
    language: result.language?.length ? result.language : "en",
    ...(background && { background: (({ meta, ...rest }) => rest)(background) }),
  };
  if (preparedForSubmit.type === "stay") {
    preparedForSubmit.field1 = preparedForSubmit.field1 || null;
    if (!preparedForSubmit.field3) delete preparedForSubmit.field3;
    delete preparedForSubmit.start_time;
    delete preparedForSubmit.client_reference;
    delete preparedForSubmit.flight_ids;
    delete preparedForSubmit.travellers;
  }

  delete preparedForSubmit.daily;
  delete preparedForSubmit.directory;
  delete preparedForSubmit.voucher;
  delete preparedForSubmit.is_listed;
  delete preparedForSubmit.requested_listing_status;
  delete preparedForSubmit.main;
  delete preparedForSubmit.type;

  // to remove any fields that may have been inadvertently added to a non-main stays language
  if (isNonMainStayLanguage) {
    delete preparedForSubmit.features;
    delete preparedForSubmit.locations;
    delete preparedForSubmit.passcode_groups;
    delete preparedForSubmit.pois;
    delete preparedForSubmit.timezone;
    delete preparedForSubmit.logo;
  }

  return fieldsToCopy
    ? prepareDataForCopy(fieldsToCopy, preparedForSubmit, data.general.type, copyToOtherOperator, newVamoosId)
    : preparedForSubmit;
};
