import { BrandMapper } from "./BrandMapper";
import { BrandSectionMapper } from "./BrandSectionMapper";
import { LabelMapper } from "./LabelMapper";
import { UserMapper } from "./UserMapper";

export class DefaultBrandMapper extends BrandMapper {

  /**
   *
   * @param {import(mappers/BrandSectionMapper).BrandSectionMapper} brandSection
   */
   constructor(brandSectionMapper = new BrandSectionMapper(), labelMapper = new LabelMapper(), userMapper = new UserMapper()) {
    super(brandSectionMapper);

    this.labelMapper = labelMapper;

    this.userMapper = userMapper;
  }

  /**
   * @param {Brand} domain
   * @returns {BrandRequestDto}
   */
  // eslint-disable-next-line class-methods-use-this
  fromDomainToDto(domain) {
    const dto = super.fromDomainToDto(domain);

    dto.name = domain.name;
    dto.default_language = domain.defaultLanguageCode;

    if(domain.labels) {
      dto.labels = domain.labels.map(label => this.labelMapper.fromDomainToDto(label));
    }

    dto.meta = {
      ...dto.meta,
      poisList: domain.poisList,
      temperature_scale: domain.temperatureScale,
      show_story_board_summary: domain.storyBoardView?.summary,
      show_story_board_daily: domain.storyBoardView?.details,
      contact_person: domain.contactPerson,
      telephone: domain.telephone,
      number_of_bookings: domain.numberOfBookings,
      number_of_passengers: domain.numberOfPassengers,
      description: domain.description,
      website: domain.website,
      additional_languages: domain.additionalLanguageCodes,
      show_book_now_in_nested: domain.showBookNowInNested
    }

    return dto;
  }

   /**
   * @param {OperatorResponseDto} dto - operator object
   * @returns {Operator} Operator item dto
   */
    fromDtoToDomain(dto) {
      const domain = super.fromDtoToDomain(dto);
  
      domain.id = dto.id;
      domain.code = dto.code;
      domain.name = dto.name;
  
      domain.labels = dto?.labels?.map(labelDto => this.labelMapper.fromDtoToDomain(labelDto)) || [];
      domain.meta = dto.meta || {} // For backward compatibility;
      domain.meta.poisList = dto.meta?.poisList === undefined ? true : dto.meta?.poisList; // Default value is true
      domain.createdAt = dto.created_at;
      domain.updatedAt = dto.updated_at;
       
      domain.temperatureScale = dto.meta?.temperature_scale ? dto.meta?.temperature_scale : "C";
      domain.defaultLanguageCode = dto.default_language;
      domain.additionalLanguageCodes = dto.meta?.additional_languages;
  
      domain.storyBoardView = {
        summary: dto.meta?.show_story_board_summary,
        details: dto.meta?.show_story_board_daily
      }
  
      domain.poisList = dto.meta?.poisList;
      domain.showBookNowInNested = dto.meta?.show_book_now_in_nested
      domain.contactPerson = dto.meta?.contact_person;
      domain.telephone = dto.meta?.telephone;
      domain.numberOfBookings = dto.meta?.number_of_bookings;
      domain.numberOfPassengers = dto.meta?.number_of_passengers;
      domain.description = dto.meta?.description;
      domain.website = dto.meta?.website;
  
      return domain;
    }
}
