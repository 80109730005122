import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Select as MuiSelect, OutlinedInput } from "@material-ui/core";
import theme from "config/theme";
import { setInputAttr } from "utils/styles";
import { inputs } from "config/theme/forms";
import { NO_AUTO_PW_INPUT_PROPS } from "constants/forms";

const StyledInputLabel = styled(InputLabel)`
  
  && {
    color: ${theme.colors.grey40};
    font-size: ${({ size }) => setInputAttr("font-size", size)}px;
    /* transform: translate(${({ size }) => setInputAttr("transform-label", size, "before")}) scale(1); */
    transform: ${props =>
      props.value !== null && props.value !== undefined && props.value !== ""
        ? "translate(14px, -6px) scale(0.75)"
        : "translate(14px, 12px) scale(1)"} ;

    &.MuiFormLabel-root.Mui-focused {
      color: ${theme.colors.brand};
      transform: translate(14px, -6px) scale(0.75);
    }
  }
`;

const BaseSelect = ({ label, name, value, children, helperText, size, fullWidth, error, id, ...rest }) => {
  const { variant } = theme.forms.inputs;
  const inputLabelRef = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  const inputId = id || `${name}-select`;

  React.useEffect(() => {
    if (inputLabelRef && inputLabelRef.current) setLabelWidth(inputLabelRef.current.offsetWidth);
  }, [inputLabelRef]);

  return (
    <FormControl variant={variant} fullWidth={fullWidth}>
      {label && (
        <StyledInputLabel ref={inputLabelRef} htmlFor={id} size={size} value={value}>
          {label}
        </StyledInputLabel>
      )}
      <MuiSelect
        value={value}
        name={name}
        input={<OutlinedInput name={name} id={inputId} labelWidth={labelWidth} inputProps={NO_AUTO_PW_INPUT_PROPS} />}
        size={size}
        error={error}
        {...rest}
      >
        {children}
      </MuiSelect>
      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>
  );
};

BaseSelect.defaultProps = {
  label: "",
  helperText: null,
  size: "medium",
  fullWidth: true,
};

BaseSelect.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]).isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.object, PropTypes.node]).isRequired,
  label: PropTypes.string,
  helperText: PropTypes.string,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
};

const Select = styled(({ cv, ...rest }) => <BaseSelect {...rest} />)`
  && {
    div {
      border-radius: ${theme.borderRadius}px;
      height: ${({ size }) => setInputAttr("input-height", size)}px;
      padding: ${({ size }) => setInputAttr("padding", size)};
      padding-right: ${({ size }) => inputs[size].padding.right + theme.setSpacing(6)}px;

      :hover {
        color: ${theme.colors.brand};
      }
    }

    fieldset {
      border-radius: ${theme.borderRadius}px;
      border-color: ${theme.colors.grey40};
      transition: ${theme.transition};
      height: ${({ size }) => setInputAttr("height", size) + 5}px;
    }

    :focus {
      outline: 0;
    }

    :hover {
      fieldset {
        border-color: ${theme.colors.brand};
      }
    }

    .MuiSvgIcon-root {
      color: ${theme.colors.grey40};
      margin-right: ${theme.setSpacing(2)}px;
      z-index: 1;
      :hover {
        color: ${theme.colors.brand};
      }
    }

    &.Mui-focused {
      box-shadow: ${theme.shadows.box};

      div {
        color: ${theme.colors.brand};
      }

      .MuiSvgIcon-root {
        color: ${theme.colors.brand};
      }

      fieldset.MuiOutlinedInput-notchedOutline {
        border-color: ${theme.colors.brand};
      }

      :hover {
        fieldset {
          color: ${theme.colors.brandHover};
          border-color: ${theme.colors.brandHover};
        }
      }
    }

    &.MuiInputBase-root {
      border-radius: ${theme.forms.inputs.borderRadius}px;
      :hover {
        .MuiSvgIcon-root {
          color: ${theme.colors.brand};
        }
      }
    }

    .MuiSelect-root {
      color: ${({ cv }) => theme.colors[cv] || theme.colors.grey90};
      font-size: ${({ size }) => setInputAttr("font-size", size)}px;
      line-height: ${({ size }) => setInputAttr("input-height", size)}px;
      background: transparent;

      :hover,
      :focus {
        .MuiSvgIcon-root {
          color: ${theme.colors.brand};
        }
      }
    }

    &.Mui-disabled {
      background-color: ${theme.colors.withOpacity(theme.colors.grey10, 0.2)};

      .MuiSelect-root {
        color: ${theme.colors.grey50};
      }
    }

    .Mui-error,
    .Mui-error.Mui-focused {
      fieldset {
        border-color: ${theme.colors.error};
      }
    }
  }
`;

Select.defaultProps = {
  size: "medium",
};

export { Select };
