import React, { memo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ImageOutlined } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";

import { Input } from "components/ui/Forms";
import { ImagePreview, ImagePlaceholder } from "components/ui/Images";
import { AttachFilesButton } from "components/ui/Buttons";
import { DEFAULT_LIBRARY_TABS } from "components/ui/LibraryPopup/LibraryPopup";
import { ChipWithRedirect } from "components/ui/Chips/ChipWithRedirect";

import { IMAGES_MIME_TYPES } from "constants/defaults";
import { ADMIN_ICONS_CONTENT, GLOBAL_CONTENT } from "constants/content";

import { useManageIconHandlers } from "./useManageIconHandlers";
import { DefaultIconWrapper } from "./DefaultIconWrapper";
import { DefaultIconNameInputWrapper } from "./DefaultIconNameInputWrapper";

const RemoveButton = styled.button`
  border: none;
  background: transparent;
  height: 24px;
  width: 24px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 0 0 24px;

  svg {
    pointer-events: none;
    color: ${({ theme }) => theme.colors.grey50};
  }
`;

const OtherIconsElement = memo(({ icon, index, onChange, onDelete, errors, canEdit, canDelete }) => {
  const { library, files } = DEFAULT_LIBRARY_TABS;
  const { handleChangeName, handleChangeAttachment, handleRemoveAttachment, name, attachment, preview } = useManageIconHandlers(
    icon,
    onChange,
  );

  const handleDeleteIcon = () => {
    onDelete(icon);
  };

  const renderAttachmentDeleter = () => (
    <ChipWithRedirect onDelete={handleRemoveAttachment} item={attachment} maxChar={15} icon={<ImageOutlined />} editable={canEdit} />
  );

  const renderAttachmentUploader = () => (
    <AttachFilesButton
      label={GLOBAL_CONTENT.attach}
      size="small"
      onSelect={handleChangeAttachment}
      allowedFileTypes={IMAGES_MIME_TYPES}
      tabs={[library, files]}
      error={errors[icon?.id]?.image}
      disabled={!canEdit}
    />
  );

  return (
    <DefaultIconWrapper error={!!errors[icon?.id]} index={index}>
      {preview ? (
        <ImagePreview image={preview} bgType="contain" width="40px" height="40px" />
      ) : (
        <ImagePlaceholder width="40px" height="40px" />
      )}
      <DefaultIconNameInputWrapper grow={1}>
        <Input
          error={errors[icon?.id]?.name}
          helperText={errors[icon?.id]?.name}
          label={ADMIN_ICONS_CONTENT.title}
          name={`icon-name-nr${index}`}
          size="small"
          value={name}
          onChange={handleChangeName}
          disabled={!canEdit}
        />
      </DefaultIconNameInputWrapper>
      {attachment && attachment.file_name ? renderAttachmentDeleter() : renderAttachmentUploader()}
      {canDelete && (
        <RemoveButton onClick={handleDeleteIcon}>
          <CloseIcon />
        </RemoveButton>
      )}
    </DefaultIconWrapper>
  );
});

OtherIconsElement.defaultProps = {
  canEdit: true,
  canDelete: true,
};
OtherIconsElement.propTypes = {
  errors: PropTypes.shape({}).isRequired,
  icon: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
};

export { OtherIconsElement };
