import React from "react";
import PropTypes from "prop-types";
import ItineraryTitle from "../Title";
import StickyContainer from "../StickyContainer";

/**
 *
 * @param {Object} props
 * @param {string} props.text The text to be displayed in the Title Row
 * @param {Function} props.children The elements to be displayed at the end of the row
 * @param {Function} props.startElement The element to be displayed before the title text
 * @param {Object} props.titleStyles Additional styles to be added to the title text
 * @param {Object} props.titleStyles Additional styles to be added to the container
 * @param {boolean} props.isSticky Whether or not the title bar should stick to the top as the user scrolls
 * @param {boolean} props.offsetTabs Enable this if the titlerow is nested under a tab bar. This will only be applied if isSticky is enabled.
 * @param {boolean} props.extraTabPadding Adds 20px to top padding if offsetTabs is enabled.
 * @param {boolean} props.disabled Whether or not the children are displayed
 *
 * @returns
 */
const TitleRow = ({
  text,
  children,
  startElement,
  titleStyles = {},
  containerStyles = {},
  isSticky,
  offsetTabs,
  extraTabPadding,
  disabled,
  ref,
  noFade,
}) => {
  return (
    <StickyContainer
      ref={ref}
      isSticky={isSticky}
      offsetTabs={offsetTabs}
      extraTabPadding={extraTabPadding}
      noFade={noFade}
      style={containerStyles}
    >
      {startElement}
      <ItineraryTitle style={{ marginBottom: 0, width: "fit-content", paddingLeft: 0, ...titleStyles }}>{text}</ItineraryTitle>
      {!disabled ? children : null}
    </StickyContainer>
  );
};

TitleRow.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node,
  startElement: PropTypes.node,
  titleStyles: PropTypes.object,
  isSticky: PropTypes.bool,
  offsetTabs: PropTypes.bool,
  disabled: PropTypes.bool,
};

TitleRow.defaultProps = {
  text: "",
  children: null,
  startElement: null,
  titleStyles: {},
  isSticky: true,
  offsetTabs: false,
  disabled: false,
};

export default TitleRow;
