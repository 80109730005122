import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import moment from "moment";

import { StickyHeaderFormSection } from "components/ui/Forms";
import { useConfirmationModal } from "components/ui/Modals/useConfirmationModal";

import { ListOfEntries } from "feature/panel/Trips/_shared/Storyboard/Entries/ListOfEntries";
import { EntriesContext } from "feature/panel/Trips/_shared/Storyboard/Entries/EntriesContext";
import { EntriesRightMenu } from "feature/panel/Trips/_shared/Storyboard/Entries/EntriesRightMenu";
import { SortingEntriesDialog } from "feature/panel/Trips/_shared/Storyboard/Entries/SortingEntriesDialog/SortingEntriesDialog";
import { useManageTripContext } from "feature/panel/Trips/_shared/ManageTripContext";
import {
  CREATE_CONTENT_LABELS,
  EMPTY_LIST_MESSAGES_BASE,
  ERRORS,
  GLOBAL_CONTENT,
  NAMES_OF_RESOURCES_LISTS,
  TRIP_WIZARD_CONTENT,
} from "constants/content";
import { NoResultsMessage } from "components/ui/Messages/NoResultsMessage";
import { FolderModal, FOLDER_MODAL_TYPE } from "feature/panel/Library/_shared/FolderModal/FolderModal";
import { StoryboardFile } from "domain/StoryboardFile";

import { PERMISSIONS } from "constants/permissions";
import { usePermissionsService } from "hooks/usePermissionsService";
import { LibraryRepository } from "repositories/LibraryRepository";

import { useService } from "hooks/useService";
import { StoryboardService } from "services/StoryboardService";
import { Logger } from "services/application/Logger";
import { pushErrorNotification, pushSuccessNotification } from "store/app/actions";
import { LIBRARY_ROOT_PATH } from "constants/api";

import { AddFromLibrary } from "./AddFromLibrary";
import { useEntries } from "./useEntries";

const Entries = () => {
  const libraryRepository = new LibraryRepository();
  const storyboardService = useService(StoryboardService);
  const dispatch = useDispatch();
  const storyBoardDaysRef = useRef(null);
  const [showDialog, setShowDialog] = useState(false);
  const [showLibrary, setShowLibrary] = useState(false);
  const [showReplaceModal, setShowReplaceModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [libraryItem, setLibraryItem] = useState(null);
  const {
    setValueFor,
    storyboard,
    meta,
    locations,
    notifications,
    listOfFlights,
    departure_date,
    return_date,
    vamoos_id,
    is_wiped,
    editMode,
  } = useManageTripContext();
  const { availableIcons } = useSelector(state => state.operator);
  const [openAddToLibraryModal, setOpenAddToLibraryModal] = useState({ opened: false, body: null });
  const permissionsService = usePermissionsService();

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoos_id);

  const { show_days } = meta;

  const navigationIcons = availableIcons.filter(icon => icon.section === "navigation");
  const defaultDocumentIcon = availableIcons.find(icon => icon.name === "Docs 1");

  const { ConfirmationModal, openModal, modalProps } = useConfirmationModal({
    confirmLabel: GLOBAL_CONTENT.proceed,
  });

  const {
    removeEntry,
    getIfErrorExist,
    handleErrors,
    errors,
    addStayEntry,
    addEntryFromFile,
    addFlightEntry,
    addEntry,
    updateEntry,
    changeDayNumber,
    toggleDayNumbers,
    isNewEntryAdded,
    clearNewEntryAdded,
  } = useEntries({
    entries: storyboard,
    flights: listOfFlights,
    locations,
    notifications,
    showDates: show_days,
    updateEntriesList: entries => {
      setValueFor("storyboard", entries);
    },
    updateLocations: newLocationsList => setValueFor("locations", newLocationsList),
    updateNotifications: newNotificationsList => setValueFor("notifications", newNotificationsList),
    updateFlights: newFlightsList => setValueFor("listOfFlights", newFlightsList),
    setDatesVisibility: value => setValueFor("meta", { ...meta, show_days: value }),
    startDate: moment(departure_date),
  });

  const handleAddToLibrary = async (filename, path) => {
    setIsLoading(true);
    const body = openAddToLibraryModal.body || libraryItem.body;
    try {
      await storyboardService.uploadToLibrary(
        new StoryboardFile(filename, body).convertToNativeFile(),
        path.replace(new RegExp(`^${LIBRARY_ROOT_PATH}`), ""),
        body?.image?.https_url || body?.image?.previewUrl || body?.image?.preview_url || null,
      );
      setLibraryItem(null);
      setShowReplaceModal(false);
      dispatch(pushSuccessNotification("Storyboard component has been successfully saved"));
    } catch (e) {
      Logger.debug(e);
      if (e.response?.data?.error?.includes("already exist")) {
        dispatch(pushErrorNotification(ERRORS.fileNameAlreadyExists));
      } else {
        dispatch(pushErrorNotification(ERRORS.unknownError));
      }
    }
    setIsLoading(false);
  };

  const checkIfItemExist = async (filename, path) => {
    setLibraryItem({ filename, path, body: openAddToLibraryModal.body });
    const files = await libraryRepository.getAllItemsFromDirectory(path);
    const isFileExist = Boolean(files.find(item => item.name === filename));
    if (isFileExist) setShowReplaceModal(true);
    else handleAddToLibrary(filename, path);
  };
  const handleOpenAddToLibraryModal = entry => {
    setOpenAddToLibraryModal({
      opened: true,
      body: entry,
    });
  };

  const handleCloseAddToLibraryModal = () => {
    setOpenAddToLibraryModal({
      opened: false,
      body: null,
    });
  };

  const handleAddToStoryboard = () => {
    setShowLibrary(true);
  };

  const handleRemoveDay = entry => {
    if (entry?.meta?.flight_id) {
      const flightToRemove = listOfFlights.find(flight => flight.id === entry.meta.flight_id);
      if (flightToRemove) {
        openModal({
          title: TRIP_WIZARD_CONTENT.storyboard.removeFlightModalTitle(
            `${flightToRemove.carrier_fs_code}${flightToRemove.carrier_flight_number}`,
            flightToRemove.departure_airport.city,
            flightToRemove.arrival_airport.city,
          ),
          onCancel() {
            removeEntry(entry.id);
          },
          onConfirm() {
            removeEntry(entry.id, true);
          },
        });
      } else {
        removeEntry(entry.id);
      }
    } else if (entry.meta.type === "stay" && entry?.location?.id) {
      const locationToRemove = locations.find(location => location.id === entry?.location?.id);

      if (locationToRemove) {
        openModal({
          title: TRIP_WIZARD_CONTENT.storyboard.removeModalTitle(entry?.location?.name),
          onCancel() {
            removeEntry(entry.id);
          },
          onConfirm() {
            removeEntry(entry.id, true);
          },
        });
      } else {
        removeEntry(entry.id);
      }
    } else {
      removeEntry(entry.id);
    }
  };

  const setLibraryShowStatus = isOpen => {
    setShowLibrary(isOpen);
  };

  const context = {
    days: storyboard,
    tripLength: moment(return_date).diff(moment(departure_date), "days") + 1,
    updateDayData: updateEntry,
    toggleDayNumberDisplay: toggleDayNumbers,
    show_days,
    removeDay: handleRemoveDay,
    availableIcons: navigationIcons,
    setDays: value => setValueFor("storyboard", value),
    onError: handleErrors,
    getIfErrorExist,
    handleChangeDayNumber: changeDayNumber,
    errors,
    defaultDocumentIcon: defaultDocumentIcon && defaultDocumentIcon.id,
  };
  const content = storyboard.length ? (
    <div ref={storyBoardDaysRef}>
      <ListOfEntries
        onAddToLibrary={handleOpenAddToLibraryModal}
        list={storyboard}
        isNewEntryAdded={isNewEntryAdded}
        confirmScrollToNewEntry={clearNewEntryAdded}
      />
    </div>
  ) : (
    !(editMode && !canEdit) && (
      <NoResultsMessage height="50px">
        {EMPTY_LIST_MESSAGES_BASE(CREATE_CONTENT_LABELS.add, NAMES_OF_RESOURCES_LISTS.storyboard)}
      </NoResultsMessage>
    )
  );

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={10}>
        <ConfirmationModal
          title="There is already a file with that name. Do you want to replace this?"
          open={showReplaceModal}
          onConfirm={() => handleAddToLibrary(libraryItem.filename, libraryItem.path)}
          onCancel={() => {
            setShowReplaceModal(false);
            setLibraryItem(null);
          }}
          showSpinner={isLoading}
        />
        <StickyHeaderFormSection
          title={TRIP_WIZARD_CONTENT.storyboard.storyboard}
          styleContent={{ position: "sticky", top: -5, zIndex: 3 }}
          headerActions={() => (
            <EntriesRightMenu
              onAddEntry={addEntry}
              onAddToStoryboard={handleAddToStoryboard}
              onToggleShowDayNumber={toggleDayNumbers}
              showDayNumber={show_days}
              onClickSorting={setShowDialog}
              daysCount={storyboard.length}
              disabled={is_wiped || (editMode && !canEdit)}
            />
          )}
        >
          <AddFromLibrary
            libraryOpen={showLibrary}
            toggleShowLibrary={setLibraryShowStatus}
            onSelectToStoryboard={addEntryFromFile}
            onSelectPublicStay={addStayEntry}
            onSelectFlight={addFlightEntry}
            tripStartDate={departure_date}
          />
          <EntriesContext.Provider value={context}>
            {content}
            <SortingEntriesDialog open={showDialog} onClose={() => setShowDialog(false)} />
          </EntriesContext.Provider>
          <FolderModal
            title={TRIP_WIZARD_CONTENT.storyboard.addToLibrary}
            isOpen={openAddToLibraryModal.opened}
            onSave={checkIfItemExist}
            value=""
            path="/library"
            originalItem={openAddToLibraryModal.body}
            onClose={handleCloseAddToLibraryModal}
            type={FOLDER_MODAL_TYPE.storyboard}
          />
          <ConfirmationModal {...modalProps} />
        </StickyHeaderFormSection>
      </Grid>
    </Grid>
  );
};
export { Entries };
