import { takeEvery, put } from "redux-saga/effects";

import * as actionTypes from "store/inspirations/actionTypes";
import {
  getInspirationSuccess,
  getInspirationFail,
} from "store/inspirations/actions";

import { INSPIRATIONS_GET_URL } from "constants/api";
import { DEFAULT_PAGE_SIZE } from "constants/defaults";

import { setUrlParams } from "utils/url";
import { HttpClient } from "services/application/httpClient/httpClient";

function* getInspirations() {
  const url = setUrlParams(INSPIRATIONS_GET_URL, { page: 1, count: DEFAULT_PAGE_SIZE });

  try {
    const { data } = yield HttpClient.get(url);
    yield put(getInspirationSuccess(data));
  } catch (e) {
    yield put(getInspirationFail(e));
  }
}

export function* inspirationsSaga() {
  yield takeEvery(actionTypes.GET_INSPIRATIONS_START, getInspirations);
}
