import React, { useState } from "react";
import AttachmentIcon from "@mui/icons-material/Attachment";
import LibraryModal from "components/_new/LibraryModal";
import { IMAGES_MIME_TYPES } from "constants/defaults";
import { ChipWithRedirect, labelStyles } from "components/ui/Chips/ChipWithRedirect";
import { ErrorText } from "components/ui/Inputs/TextInput/style";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { InputLabel } from "@mui/material";
import { colors } from "config/theme/colors";
import OutlinedButton from "../OutlinedButton";

const AttachButton = ({
  text,
  type,
  types = ["library", "upload"],
  allowedFileTypes = IMAGES_MIME_TYPES,
  mobileFullScreen,
  libraryTitle = "Add file",
  onConfirm,
  iconOnlyMobile,
  buttonWrapStyle = {},
  hideDescription,
  startIcon,
  file,
  onDelete,
  handleOpen,
  error,
  onStaySelect,
  disabled,
  onChipCustomClick,
  customChip,
  style,
  hideUrl,
  multiUpload,
  flightProps,
  tooltip,
  label,
  chipWrapStyle,
  chipStyle,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => {
    setIsOpen(!isOpen);
    if (handleOpen) handleOpen(!isOpen);
  };
  const handleConfirm = item => {
    onConfirm(item);
    toggleOpen();
  };

  const handleStaySelect = item => {
    onStaySelect(item);
    toggleOpen();
  };

  if (file)
    return (
      customChip || (
        <ChipWithRedirect
          onDelete={onDelete}
          highQuality
          item={file}
          disabled={disabled}
          onChipCustomClick={onChipCustomClick}
          label={label}
          chipWrapStyle={chipWrapStyle}
          chipStyle={chipStyle}
          // icon={( && <InsertLinkIcon />) || null}
          {...(file.web_url || (file.https_url && !file.s3_url) ? { icon: <InsertLinkIcon /> } : {})}
        />
      )
    );

  return (
    <>
      {isOpen && (
        <LibraryModal
          title={libraryTitle}
          types={types}
          allowedFileTypes={allowedFileTypes}
          mobileFullScreen={mobileFullScreen}
          onCancel={toggleOpen}
          onConfirm={handleConfirm}
          onStaySelect={handleStaySelect}
          hideUrl={hideUrl}
          multiUpload={multiUpload}
          flightProps={flightProps}
        />
      )}
      <div style={{ position: "relative", width: "100%", ...buttonWrapStyle }}>
        <InputLabel style={{ ...labelStyles, color: colors.brand }}>{label}</InputLabel>
        <OutlinedButton
          text={text}
          type={type}
          startIcon={startIcon || <AttachmentIcon />}
          onClick={toggleOpen}
          iconOnlyMobile={iconOnlyMobile}
          disabled={disabled}
          style={style}
          tooltip={tooltip}
        />
        {error && <ErrorText>{error.message || "This field is required"}</ErrorText>}
        {!hideDescription && (
          <div style={{ marginTop: 15 }}>
            Allowed extensions: jpg, jpeg, gif, png <br /> Maximum file size: 12 MB
            <br /> Recommended dimensions: 2048 x 2048 px
          </div>
        )}
      </div>
    </>
  );
};

export default AttachButton;
