import { FORCE_UPDATE_URL } from "constants/api";
import { setUrl } from "utils/url";
import { sendDefaultApiErrorMessage } from "utils";
import { setNotification } from "store/app/actions";
import { HttpClient } from "services/application/httpClient/httpClient";

const FORCE_UDATE_RESOURCE_TYPES = {
  itinerary: "itinerary",
  inspiration: "inspiration",
};

const sendForceUpdateHandler = (requestHandler, resource_type) => ({ operator_code, reference_code, notification_text, dispatch }) => {
  const url = setUrl(FORCE_UPDATE_URL, { operator_code, reference_code, resource_type }, true);
  requestHandler
    .post(url, { notification_text })
    .then(() => {
      dispatch(
        setNotification({
          type: "success",
          message: "Force update sent successfully",
        }),
      );
    })
    .catch(error => sendDefaultApiErrorMessage(dispatch, error));
};
const sendVamoosForceUpdate = sendForceUpdateHandler(HttpClient, FORCE_UDATE_RESOURCE_TYPES.itinerary);
const sendInspirationForceUpdate = sendForceUpdateHandler(HttpClient, FORCE_UDATE_RESOURCE_TYPES.inspiration);

export { sendForceUpdateHandler, sendVamoosForceUpdate, sendInspirationForceUpdate };
