import React, { useEffect } from "react";
import BaseModal from "components/_new/ModalBase";
import Expandable from "components/_new/Expandable";
import { useMutation, useQuery } from "@tanstack/react-query";
import { CircularProgress } from "@mui/material";
import { Message } from "components/ui/Messages/Message";
import Input from "components/ui/Inputs/TextInput";
import { useForm } from "react-hook-form";
import { HttpClient } from "services/application/httpClient/httpClient";

const EditStayModal = ({ stayId, onClose }) => {
  const { control, watch, handleSubmit, reset } = useForm();

  const { data, isLoading } = useQuery({ queryKey: [`/itinerary/overrides/${stayId}`], refetchOnMount: true });
  const { mutate, isLoading: isMutating } = useMutation(body => HttpClient.post(`/itinerary/overrides/${stayId}`, body), {
    onSuccess: () => {
      reset({ short_description: "", long_description: "" });
      onClose();
    },
  });

  const shortDescription = watch("short_description");

  const onSubmit = values => mutate(values);

  useEffect(() => {
    if (data) reset({ short_description: data.short_description, long_description: data.long_description });
  }, [data]);

  return (
    <BaseModal
      title={`Customise "${data?.name ?? 'Stay'}"`}
      onCancel={onClose}
      onConfirm={handleSubmit(onSubmit)}
      isLoading={isMutating}
    >
      <div style={{overflow: 'auto'}}>
        {isLoading && (
          <div style={{ textAlign: "center", margin: "25px 0" }}>
            <CircularProgress />
          </div>
        )}
        <Message
          text="Changes made here will affect all your Vamoos itineraries using this property"
          type="info"
          margin={{ top: "15px", bottom: "15px" }}
        />
        <Expandable text={data?.short_description_original} prefix={<strong>(Default)</strong>} />
        <Input
          autoFocus
          control={control}
          name="short_description"
          label="Short description"
          style={{ marginTop: 15, marginBottom: 15 }}
          limit={250}
          hint={`${250 - (shortDescription?.length || 0)} characters left`}
          minRows={3}
          multiline
        />
        <Expandable text={data?.long_description_original} prefix={<strong>(Default)</strong>} />
        <Input
          control={control}
          name="long_description"
          label="Long description"
          style={{ marginTop: 15, marginBottom: 15 }}
          minRows={3}
          multiline
        />
      </div>
    </BaseModal>
  );
};

export default EditStayModal;
