import React, { createContext, useContext } from "react";
import { NotificationsTemplate } from "feature/panel/_shared/Notifications/NotificationsTemplate";
import { useDispatch, useSelector } from "react-redux";
import { stayWizardSetNotifications } from "store/stayWizard/actions";
import { NOTIFICATION_TYPES } from "constants/content";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { LanguageContext } from "components/_shared/LanguageSelector/LanguageContext";

const StayNotificationsBridgeContext = createContext();

const Notifications = () => {
  const permissionsService = usePermissionsService();
  const dispatch = useDispatch();

  const { currentLanguage } = useContext(LanguageContext);

  const notifications = useSelector(({ stayWizard }) => stayWizard.form.notifications);
  const coordinates = useSelector(({ stayWizard }) => stayWizard.form.coordinates);
  const errors = useSelector(({ stayWizard }) => stayWizard.errors);

  const isWiped = useSelector(({ stayWizard }) => stayWizard.form.isWiped);
  const vamoosId = useSelector(({ stayWizard }) => stayWizard.form.vamoosId);
  const editMode = useSelector(({ stayWizard }) => stayWizard.form.vamoosId !== null);

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoosId);

  const contextBridgeValue = {
    notifications: [...notifications.timed.items, ...notifications.gps.items],
    locations: [coordinates],
    type: "stay",
    errors,
    setValueFor: (key, newNotifications) => {
      dispatch(
        stayWizardSetNotifications({
          notifications: {
            timed: {
              items: newNotifications.filter(notification => notification.type === NOTIFICATION_TYPES.timed),
            },
            gps: {
              items: newNotifications.filter(notification => notification.type === NOTIFICATION_TYPES.gps),
            },
          },
          language: currentLanguage,
        }),
      );
    },
  };
  return (
    <StayNotificationsBridgeContext.Provider value={contextBridgeValue}>
      <NotificationsTemplate disabled={isWiped || (editMode && !canEdit)} context={StayNotificationsBridgeContext} />
    </StayNotificationsBridgeContext.Provider>
  );
};

export { Notifications };
