import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Content } from "components/ui/Content/Content";
import { Input, Textarea, Checkbox } from "components/ui/Forms";
import { TERMS_PATH } from "constants/routes";
import { SignUpContext } from "feature/views/SignUp/SignUpContext";
import { colors } from "config/theme/colors";
import fields from "./fields.json";

const SignUpFormStep4 = () => {
  const { handleChange, setValueFor, operator, canGoNext } = useContext(SignUpContext);

  const { terms_accepted_at: acceptTerms, meta } = operator;
  const {
    company_type: companyType,
    number_of_bookings: numberOfBookings,
    number_of_passengers: numberOfPassengers,
    source_knowledge_about_us: sourceKnowledgeAboutUs,
    description,
    website,
  } = meta;

  useEffect(() => {
    if (acceptTerms) {
      canGoNext(true);
    } else {
      canGoNext(false);
    }
  }, [acceptTerms]);

  return (
    <>
      {companyType !== "user" && (
        <>
          <Content margin={4}>
            <Input
              type="number"
              size="large"
              name="number_of_bookings"
              value={numberOfBookings}
              onChange={handleChange}
              label={(fields[companyType] && fields[companyType].bookings.label) || null}
              helperText={(fields[companyType] && fields[companyType].bookings.helperText) || null}
              autoComplete="off"
            />
          </Content>
          {companyType !== "relocation" && (
            <Content margin={4}>
              <Input
                type="number"
                size="large"
                name="number_of_passengers"
                value={numberOfPassengers}
                onChange={handleChange}
                label={(fields[companyType] && fields[companyType].passengers.label) || null}
                helperText={(fields[companyType] && fields[companyType].passengers.helperText) || null}
                autoComplete="off"
              />
            </Content>
          )}
          <Content>
            <Textarea
              name="description"
              value={description}
              onChange={handleChange}
              label="General description of business"
              rows={3}
              autoComplete="off"
            />
          </Content>
          <Content>
            <Input name="website" label="Company website" size="large" value={website} onChange={handleChange} autoComplete="off" />
          </Content>
          <Content>
            <Textarea
              label="How did you hear about us?"
              value={sourceKnowledgeAboutUs}
              name="source_knowledge_about_us"
              onChange={handleChange}
              rows={3}
              autoComplete="off"
            />
          </Content>
        </>
      )}
      <Content>
        <Checkbox
          label={
            <div style={{ color: "#000", fontWeight: 700 }}>
              Accept{" "}
              <Link to={TERMS_PATH} cv="black" target="_blank" style={{ textDecoration: "underline", color: colors.brand }}>
                Terms &amp; Conditions*
              </Link>
            </div>
          }
          name="accept_terms"
          checked={acceptTerms}
          onChange={() => setValueFor("terms_accepted_at", !acceptTerms, "operator")}
        />
      </Content>
    </>
  );
};

export { SignUpFormStep4 };
