// @ts-check
import moment from "moment";

import config from "config/app";

import { formShowMessageValue } from "utils/dataConverters";
import { formFilePayloadObject } from "utils/library";
import { StayVariantMapper } from "./StayVariantMapper";

class StayMasterMapper extends StayVariantMapper {
  /**
   * @param {import("dto/StayResponseDto").StayResponseDto} dto
   * @returns {import("domain/Stay").Stay}
   */
  fromDtoToDomain(dto) {
    const domain = super.fromDtoToDomain(dto);

    return domain;
  }

  /**
   *
   * @param {import("domain/Stay").Stay} domain
   * @returns {Promise<import("dto/StayRequestDto").StayRequestDto>}
   */
  async fromDomainToDto(domain, copyToOtherOperator = false) {
    const dto = await super.fromDomainToDto(domain, copyToOtherOperator);

    dto.logo = formFilePayloadObject(domain.logo, copyToOtherOperator);

    dto.features = domain.features.map(feature => ({
      id: feature.id,
      is_featured: feature.isFeatured,
    }));

    dto.locations = [
      {
        name: domain.propertyName,
        location: domain.address,
        latitude: domain.coordinates.latitude,
        longitude: domain.coordinates.longitude,
        on_maps: true,
        on_weather: true,
      },
    ];

    dto.pois = domain.pois;

    dto.passcode_groups = domain.passcodes.map(passcodeGroup => ({
      display_rules: passcodeGroup.display_rules,
      name: passcodeGroup.name,
      passcodes: passcodeGroup.passcodes,
    }));

    dto.meta.address = domain.address;
    dto.meta.check_in_time = domain.checkInTime;
    dto.meta.number_of_rooms = domain.numberOfRooms;

    dto.meta.travel_documents_icon_id = domain.actions.action.icon;
    dto.meta.destination_documents_icon_id = domain.actions.bookNow.icon;

    dto.meta.require_personal_details = domain.requirePersonalDetails;

    dto.meta.alert_emails = domain.alertEmails.emails.length > 0 ? domain.alertEmails.emails.split(",").map(email => email.trim()) : [];
    dto.meta.alert_email_admins = domain.alertEmails.include;
    dto.meta.map_overlays = domain.map_overlays;

    dto.meta.show_messaging = formShowMessageValue(domain.messaging.emails);

    dto.meta.show_dnd = domain.dnd.show;
    dto.meta.dnd_cut_off_time = moment(domain.dnd.time).format(config.timeFormat);
    dto.meta.dnd_contact_email = domain.dnd.email || undefined;

    dto.meta.messaging_emails_list = domain.messaging.emails;

    dto.meta.messaging_email_before_after =
      domain.messaging.emails
        .filter(email => email.before_after)
        .map(email => email.email)
        .join(",") || undefined;

    dto.meta.messaging_email_during =
      domain.messaging.emails
        .filter(email => email.during)
        .map(email => email.email)
        .join(",") || undefined;

    dto.meta.messaging_email_during_days_after = domain.messaging.dates.after || undefined;
    dto.meta.messaging_email_during_days_before = domain.messaging.dates.before || undefined;

    dto.branding_profile_id = domain.brandingProfileId || null;

    dto.meta.copied_from = domain.copiedFrom;

    return dto;
  }
}

export { StayMasterMapper };
