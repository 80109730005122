// @ts-check
export class BrandSectionRequestDto {
  /** @type {number} */
  library_node_id;

  /** @type {number} */
  file_id;

  /** @type {string} */
  file_url;

  /** @type {string} */
  web_url;

  /** @type {string} */
  name;

  /** @type {number} */
  icon_id;

  /** @type {boolean} */
  is_public;
}
