import { DeleteOutlined } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import { debounce } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import uuidv4 from "uuid";

import initialColumns from "feature/panel/Stays/Index/TableOfStaysColumns.json";

import { PanelTemplate } from "components/templates/Panel/PanelTemplate";
import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";
import { NoResultsMessage } from "components/ui/Messages/NoResultsMessage";

import { VamoosListNavigation } from "feature/panel/_shared/Navigations/VamoosListNavigation";
import { SearchField } from "feature/panel/_shared/SearchField/SearchField";
import { Actions } from "feature/panel/Stays/Index/Actions/Actions";

import { useTableHandlers } from "hooks/useTableHandlers";

import {
  CREATE_CONTENT_LABELS,
  EMPTY_LIST_MESSAGES_BASE,
  NAMES_OF_RESOURCES_LISTS,
  NO_RESULTS_FOUND_MESSAGE,
  TABLE_NAMES,
} from "constants/content";
import { DEFAULT_DEBOUNCE_DELAY, REMOVED_STAYS_COLUMNS } from "constants/defaults";

import FloatingAction from "components/_new/FloatingAction";
import { LS_STAYS_SELECTED_COLUMNS } from "constants/localstorage";
import { PERMISSIONS } from "constants/permissions";
import TopSecondMenu from "feature/panel/Itinerary/components/TopSecondMenu";
import VamoosListMenu from "feature/panel/Itinerary/components/TopSecondMenu/menus/vamoosList";
import { usePermissionsService } from "hooks/usePermissionsService";
import { useService } from "hooks/useService";
import { use100vh } from "react-div-100vh";
import { Logger } from "services/application/Logger";
import { StayService } from "services/domain/StayService";
import { StorageService } from "services/StorageService";
import { getCurrentUserStart } from "store/auth/actions";

import { FilterListOutlined } from "@material-ui/icons";
import notifications from "constants/notifications";
import { pushErrorNotification, pushSuccessNotification } from "store/app/actions";
import OutlinedButton from "../../../../components/_new/Buttons/OutlinedButton";
import Table from "../../../../components/_new/Table";
import { GET_ITINERARIES_URL } from "../../../../constants/api";
import filterFields from "../../../../constants/filterFields.json";
import { useColumnSelector } from "../../../../hooks/useColumnSelector";
import { deviceType } from "../../../../utils/deviceDetect";
import { getRequiredData } from "../../../../utils/saveTableSettings";
import { setUrlParams } from "../../../../utils/url";
import ColumnSelectorModal from "../../Itinerary/columnSelectorModal";
import CopyModal from "../../Itinerary/copyModal";
import { ResponsiveHideFrom, ResponsiveShowFrom } from "../../Itinerary/responsive";
import BulkActions from "../../Trips/Index/BulkActions";
import CustomTableRow from "../../Trips/Index/CustomRow";
import Filter from "../../Trips/Index/Filter";
import MobileFilterModal from "../../Trips/Index/Filter/MobileFilterModal";
import MobileItem from "../../Trips/Index/MobileItem";
import MobileItemHeader from "../../Trips/Index/MobileItem/MobileItemHeader";
import { ActionItemsContainer } from "../../Trips/Index/style";
import { fetchStays, generateColumns } from "./helpers";
import { useListOfLanguages } from "../../../../hooks/useListOfLanguages";

const StaysIndex = () => {
  const height100vh = use100vh();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const storageService = new StorageService();
  const permissionsService = usePermissionsService();

  const staysService = useService(StayService);

  const canCreate = permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.vamoosList, PERMISSIONS.resources.default);
  const [showArchived, setShowArchived] = useState(false);
  const { currentOperatorCode, operators } = useSelector(state => state.auth);
  const allLanguages = useListOfLanguages();

  const [defaultLanguageCode, languageOption] = useMemo(() => {
    const defaultLanguageCode = operators.find(op => op.code === currentOperatorCode).defaultLanguageCode;
    const languageOption = [allLanguages.find(lng => lng.code === defaultLanguageCode)];

    return [defaultLanguageCode, languageOption];
  }, [operators, currentOperatorCode, allLanguages]);

  const { currentOperator, isFetchingCurrentOperator } = useSelector(state => state.operator);

  const [listOfStays, setListOfStays] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [openCopyStayModal, setOpenCopyStayModal] = useState(false);
  const [activeStay, setActiveStay] = useState(null);
  const [openColumnSelectorModal, setOpenColumnSelectorModal] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [requestParams, setRequestParams] = useState(null);
  const [filterCount, setFilterCount] = useState(0);
  const [selectedStays, setSelectedStays] = useState({ allItemsSelected: false, vamoosIds: [] });
  const [checkedOnCurrentPage, setCheckedOnCurrentPage] = useState([]);
  const [clearAllCheckboxSelections, setClearAllCheckboxSelections] = useState(false);

  const device = deviceType();
  const { storedColumns: storedStaysColumns, handleSaveColumns } = useColumnSelector(
    LS_STAYS_SELECTED_COLUMNS,
    initialColumns,
    device,
    listOfStays,
  );
  const form = useForm({
    defaultValues: {
      filters: [
        {
          id: uuidv4(),
          column: Object.keys(filterFields[TABLE_NAMES.stay])[0],
          operator: Object.values(filterFields[TABLE_NAMES.stay])[0]?.operators?.value,
          userInputValue: undefined,
        },
      ],
    },
  });

  const { tables } = getRequiredData(TABLE_NAMES.stay);
  const defaultOrder = tables?.[device]?.[TABLE_NAMES.stay]?.ordering?.order || "desc";
  const defaultOrderBy = tables?.[device]?.[TABLE_NAMES.stay]?.ordering?.column || "updated_at";

  const tableHandlers = useTableHandlers(null, null, {
    params: { type: TABLE_NAMES.stay },
    defaultOrderBy,
    defaultOrder,
  });

  const { handleChangePage, page, rowsPerPage, sortingOrder, sortingBy, refactoredHandleChangeOrder } = tableHandlers;

  const handleEncodedSearch = query => {
    setSearchQuery(query);
  };

  const debounceSearchQuery = useRef(debounce(query => handleEncodedSearch(query), DEFAULT_DEBOUNCE_DELAY));

  const onSearch = query => {
    setClearAllCheckboxSelections(true);
    setSearchValue(query);
    if (query.length < 3 && query !== "") return;
    return debounceSearchQuery.current(query);
  };

  const handleToggleShowArchived = () => {
    if (page !== 1) handleChangePage(0);
    setSelectedStays({ allItemsSelected: false, vamoosIds: [] });
    setCheckedOnCurrentPage([]);
    setShowArchived(!showArchived);
  };

  const checkTableColumnsIntegrity = () => {
    storageService.getPropertyByName(LS_STAYS_SELECTED_COLUMNS).then(response => {
      if (Array.isArray(response)) {
        const hasRemovedColumn = response.some(item => REMOVED_STAYS_COLUMNS.includes(item.key));
        if (hasRemovedColumn) {
          storageService.setPropertyByName(LS_STAYS_SELECTED_COLUMNS, initialColumns);
        }
      }
    });
  };

  /**
   * To avoid wrong permissions when user has been invited to specific itinerary in meantime
   * @todo: Solution should be more generic
   */
  const refreshCurrentUserDetails = () => {
    dispatch(getCurrentUserStart());
  };

  const customContextBar = () => {
    return <TopSecondMenu menu={<VamoosListMenu searchValue={searchValue} onSearch={onSearch} type="stay" device={device} />} />;
  };

  const contextBar = {
    left: <SearchField onSearchChange={onSearch} value={searchValue} />,
    middle: VamoosListNavigation,
    right: Actions,
  };

  const updateStaysFromCheckboxes = checked => {
    if (!listOfStays.length) return;

    const itemsIdOnCurrentPage = listOfStays.map(item => item.vamoos_id);
    const allSelected = itemsIdOnCurrentPage.every(id => checked.includes(id));
    const activeItemsIdOnOtherPages = selectedStays.vamoosIds.filter(id => !itemsIdOnCurrentPage.includes(id));

    setSelectedStays({
      allItemsSelected: allSelected || [...activeItemsIdOnOtherPages, ...checked].length === count,
      vamoosIds: [...activeItemsIdOnOtherPages, ...checked],
    });
  };

  const updateCheckboxSelectionForCurrentPage = (stays, clearCheckboxes) => {
    if (clearAllCheckboxSelections || clearCheckboxes) {
      setSelectedStays({ allItemsSelected: false, vamoosIds: [] });
      setClearAllCheckboxSelections(false);
      setCheckedOnCurrentPage([]);
    } else {
      const itemsIdOnCurrentPage = stays.map(item => item.vamoos_id);
      const activeItemsOnCurrentPage = itemsIdOnCurrentPage.filter(id => selectedStays.vamoosIds.includes(id));

      setCheckedOnCurrentPage(activeItemsOnCurrentPage);
      setSelectedStays(prevState => ({
        ...prevState,
        allItemsSelected:
          listOfStays.length && itemsIdOnCurrentPage.length && itemsIdOnCurrentPage.every(id => selectedStays.vamoosIds.includes(id)),
      }));
    }
  };

  const getStays = (clearCheckboxes = false) =>
    fetchStays({
      data: { page, rowsPerPage, sortingOrder, sortingBy, showArchived, searchQuery, requestParams },
      setIsLoading,
      staysService,
    })
      .then(({ items, total_matches }) => {
        const staysWithAddress = items.map(stay => ({ ...stay, address: stay.meta?.address || "" }));
        setListOfStays(staysWithAddress);
        setCount(total_matches);

        updateCheckboxSelectionForCurrentPage(staysWithAddress, clearCheckboxes);
      })
      .catch(e => {
        dispatch(pushErrorNotification(notifications.general[400]));
        Logger.debug(e);
      });

  const handleChangeParams = (newParams, isFilterItem) => {
    setClearAllCheckboxSelections(!!isFilterItem);
    setRequestParams(newParams);
  };

  const stayCopyHandler = stay => {
    setActiveStay(stay);
    setOpenCopyStayModal(!openCopyStayModal);
  };

  const handleStayRestore = async ({ operator_code }) => {
    const { isActive } = await staysService.getStayVariant(operator_code);
    if (isActive) {
      await staysService.deactivateStay(operator_code);
    } else {
      await staysService.activateStay(operator_code);
    }
    getStays();
    dispatch(pushSuccessNotification(notifications.resource("stays").update.success));
  };

  const handleGetStays = () => {
    if (page !== 1) {
      handleChangePage(0);
    } else {
      getStays();
    }
  };

  const onSort = order => {
    const [key, value] = Object.entries(order)[0];
    refactoredHandleChangeOrder({ column: key, order: value }, TABLE_NAMES.stay);
  };

  useEffect(() => {
    getStays();
  }, [page, rowsPerPage, sortingOrder, sortingBy, currentOperatorCode, showArchived]);

  useEffect(() => {
    handleGetStays();
  }, [searchQuery, requestParams]);
  useEffect(refreshCurrentUserDetails, []);
  useEffect(checkTableColumnsIntegrity, []);
  useEffect(() => {
    updateStaysFromCheckboxes(checkedOnCurrentPage);
  }, [checkedOnCurrentPage]);

  const content = (() => {
    if (isLoading && !listOfStays.length || isFetchingCurrentOperator) return <LoadingScreen />;
    if (!listOfStays.length && !canCreate) return null;
    if (!listOfStays.length && canCreate)
      return (
        <NoResultsMessage>
          {searchQuery || requestParams
            ? NO_RESULTS_FOUND_MESSAGE
            : showArchived
            ? "Could not find any deleted stays"
            : EMPTY_LIST_MESSAGES_BASE(CREATE_CONTENT_LABELS.stay, NAMES_OF_RESOURCES_LISTS.stay)}
        </NoResultsMessage>
      );

    let showRefactor =
      (currentOperator?.meta?.enable_refactor && currentOperator?.meta?.show_refactor !== false) ??
      process.env.REACT_APP_SHOW_REFACTORED_TRIPS === "true";

    if (process.env.REACT_APP_FORCE_NEW_TRIPS === "true") { showRefactor = true }

    return (
      <>
        <Table
          columns={generateColumns(storedStaysColumns, device, permissionsService, navigate, {
            duplicateStay: stay => stayCopyHandler(stay),
            openColumnSelector: () => setOpenColumnSelectorModal(true),
            stayRestore: operator_code => handleStayRestore(operator_code),
          })}
          rows={listOfStays}
          renderRow={
            <CustomTableRow
              showRefactoredEditor={showRefactor}
              columns={generateColumns(storedStaysColumns, device, permissionsService, navigate, {
                duplicateStay: stay => stayCopyHandler(stay),
                stayRestore: operator_code => handleStayRestore(operator_code),
              })}
              checkboxSelection={!showArchived}
            />
          }
          {...tableHandlers}
          totalMatches={count}
          containerStyle={{ height: deviceType() !== "mobile" && height100vh - 300 }}
          renderRowMobile={
            <MobileItem
              {...{ duplicateStay: stay => stayCopyHandler(stay) }}
              columns={generateColumns(storedStaysColumns, device, permissionsService, navigate, {
                duplicateStay: stay => stayCopyHandler(stay),
              })}
              setSelectedItems={setSelectedStays}
              selectedItems={selectedStays}
              totalMatches={count}
              checked={checkedOnCurrentPage}
              setChecked={setCheckedOnCurrentPage}
              checkboxSelection={!showArchived}
            />
          }
          checkboxSelection={!showArchived}
          checked={checkedOnCurrentPage}
          setChecked={setCheckedOnCurrentPage}
          checkboxVarName="vamoos_id"
          onSort={onSort}
          isSorting
          tableId={TABLE_NAMES.stay}
        />
        {openCopyStayModal && (
          <CopyModal copiedItinerary={activeStay} onCancel={() => setOpenCopyStayModal(!openCopyStayModal)} type={TABLE_NAMES.stay} />
        )}
      </>
    );
  })();

  let showRefactor =
    (currentOperator?.meta?.enable_refactor && currentOperator?.meta?.show_refactor !== false) ??
    process.env.REACT_APP_SHOW_REFACTORED_TRIPS === "true";

  if (process.env.REACT_APP_FORCE_NEW_TRIPS === "true") { showRefactor = true }

  return (
    <PanelTemplate
      customContextBar={customContextBar}
      styleWrapper={{ padding: deviceType() === "mobile" ? "20px 20px 70px 20px" : "20px 20px 20px 20px" }}
    >
      <ResponsiveShowFrom size="md">
        <FloatingAction disabled={isFetchingCurrentOperator} onClick={() => navigate(showRefactor ? "/panel/stay/create/general" : "/panel/stays/create")} />
      </ResponsiveShowFrom>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ width: "100%", height: "100%" }}>
          <ResponsiveHideFrom size="md">
            <ActionItemsContainer>
              <Filter
                handleChangeParams={handleChangeParams}
                openFilterModal={openFilterModal}
                setOpenFilterModal={setOpenFilterModal}
                type={TABLE_NAMES.stay}
                form={form}
                filterCount={filterCount}
                setFilterCount={setFilterCount}
                isLoading={isLoading}
              />
              <BulkActions
                selectedItems={selectedStays}
                setSelectedItems={setSelectedStays}
                url={setUrlParams(GET_ITINERARIES_URL, {
                  type: "stay",
                  page,
                  count: rowsPerPage,
                  order: sortingOrder,
                  order_by: sortingBy,
                  search: searchQuery,
                  archived: showArchived ? "only" : "off",
                  ...(requestParams ?? {}),
                })}
                forceQuery={getStays}
                totalMatches={count}
                type={TABLE_NAMES.stay}
                setClearAllCheckboxSelections={setClearAllCheckboxSelections}
                defaultLanguageCode={defaultLanguageCode}
                languageOption={languageOption}
              />
              <OutlinedButton
                text={showArchived ? "View active" : "View deleted"}
                startIcon={showArchived ? <FilterListOutlined /> : <DeleteOutlined />}
                onClick={() => handleToggleShowArchived()}
              />
            </ActionItemsContainer>
          </ResponsiveHideFrom>
          <ResponsiveShowFrom size="md">
            <MobileItemHeader
              setOpenFilterModal={setOpenFilterModal}
              handleToggleShowArchived={handleToggleShowArchived}
              setOpenColumnSelectorModal={setOpenColumnSelectorModal}
              showArchived={showArchived}
              filterCount={filterCount}
              selectedItems={selectedStays}
              setSelectedItems={setSelectedStays}
              url={setUrlParams(GET_ITINERARIES_URL, {
                type: "stay",
                page,
                count: rowsPerPage,
                order: sortingOrder,
                order_by: sortingBy,
                search: searchQuery,
                archived: showArchived ? "only" : "off",
                ...(requestParams ?? {}),
              })}
              forceQuery={getStays}
              totalMatches={count}
              list={listOfStays}
              checked={checkedOnCurrentPage}
              setChecked={setCheckedOnCurrentPage}
              type={TABLE_NAMES.stay}
              setClearAllCheckboxSelections={setClearAllCheckboxSelections}
              defaultLanguageCode={defaultLanguageCode}
              languageOption={languageOption}
            />
          </ResponsiveShowFrom>
          {openFilterModal && (
            <MobileFilterModal
              handleChangeParams={handleChangeParams}
              openFilterModal={openFilterModal}
              setOpenFilterModal={setOpenFilterModal}
              handleChangePage={handleChangePage}
              page={page}
              type={TABLE_NAMES.stay}
              form={form}
              filterCount={filterCount}
              setFilterCount={setFilterCount}
              isLoading={isLoading}
            />
          )}
          {content}
        </div>
      </div>
      {openColumnSelectorModal && (
        <ColumnSelectorModal
          onCancel={() => setOpenColumnSelectorModal(false)}
          onConfirm={handleSaveColumns}
          columns={storedStaysColumns}
          device={device}
          localStorageKey={LS_STAYS_SELECTED_COLUMNS}
        />
      )}
    </PanelTemplate>
  );
};

export { StaysIndex };
