import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { BodyText2 } from "components/ui/Typography/Typography";
import { Checkbox } from "components/ui/Forms";

const IncludedRowArea = styled.div`
  margin-bottom: ${({ theme }) => theme.setSpacing(3)}px;
  width: 100%;
  display: flex;
  align-items: center;
  height: ${({ theme }) => theme.setSpacing(8)}px;
  justify-content: space-between;
`;

const IncludedRow = styled.div`
  display: flex;
  align-items: center;
`;

const CopyingStayModalListElement = ({ label, elementIdentifier, onChange, value, children }) => {
  return (
    <IncludedRowArea>
      <IncludedRow>
        <Checkbox checked={value} onChange={() => onChange(elementIdentifier)} />
        <BodyText2>{label}</BodyText2>
      </IncludedRow>
      {children}
    </IncludedRowArea>
  );
};

CopyingStayModalListElement.defaultProps = {
  children: undefined,
};

CopyingStayModalListElement.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
  elementIdentifier: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
};

export { CopyingStayModalListElement, IncludedRowArea, IncludedRow };
