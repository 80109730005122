import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { debounce } from "lodash";
import { DEFAULT_DEBOUNCE_DELAY } from "constants/defaults";
import MapWidget from "feature/panel/_shared/MapWidget";
import { useQuery } from "@tanstack/react-query";

import { StayListItem } from "./StayListItem";
import { PublicStaysControl } from "./StaysControl";

const InnerContainer = styled.div`
  width: 100%;
`;

const filteredStays = (stays = [], searchQuery = "", searchedCountries = []) => {
  const filteredList =
    searchedCountries.length > 0 ? stays.filter(stay => searchedCountries.includes(stay.country_iso.toLowerCase())) : stays;
  if (searchQuery === "") {
    return filteredList;
  }

  return filteredList.filter(stay => {
    const queryName = stay.name.toLowerCase();

    return queryName.includes(searchQuery.toLowerCase());
  });
};

const StaySection = ({ onStaySelect }) => {
  const handleMapSearch = useRef();
  const mapWidgetRef = useRef();

  const [selectedStay, setSelectedStay] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCountrySearch, setSelectedCountrySearch] = useState([]);
  const [stays, setStays] = useState([]);

  const debouncedSearch = useRef(debounce(search => setSearchQuery(search), DEFAULT_DEBOUNCE_DELAY));

  const { data, isLoading } = useQuery({ queryKey: ["itinerary/stays?&count=5000"] });

  const handleSelectStayFromMap = (searchedLocation, _, handleCloseInfoWindow) => {
    onStaySelect(searchedLocation);
    if (handleCloseInfoWindow) handleCloseInfoWindow();
  };

  const handleSearchChange = query => {
    debouncedSearch.current(query);
  };

  const handleCountrySearchChange = countries => {
    const countriesIso = countries?.map(country => country.toLowerCase());

    setSelectedCountrySearch(countriesIso);
  };

  const handleMarkerClick = choosenStay => {
    setSelectedStay(choosenStay);
  };

  const handleSelectStays = stay => {
    onStaySelect(stay);
  };

  const handleStayClear = () => {
    setSelectedStay(null);
    mapWidgetRef.current.closeInfoWindow();
  };

  useEffect(() => {
    setStays(filteredStays(data?.items, searchQuery, selectedCountrySearch));

    return () => {
      setStays([]);
    };
  }, [data, searchQuery, selectedCountrySearch]);

  return (
    <InnerContainer>
      <PublicStaysControl onSearchChange={handleSearchChange} onCountryChange={handleCountrySearchChange} />
      <MapWidget
        data={{
          stays,
        }}
        onSearch={handleMapSearch.current}
        switchControls={["placeholder"]}
        onMarkerClick={handleMarkerClick}
        infoWindowsProps={{
          stays: {
            isAdding: true,
            passItemOnMarkerAdd: true,
            onAddMarker: handleSelectStayFromMap,
          },
        }}
        hideSearch
        disableRightClick
      />
      <StayListItem stay={selectedStay} stays={stays} onStaySelect={handleSelectStays} onStayClear={handleStayClear} />
    </InnerContainer>
  );
};

export { StaySection };
