import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import uuidv4 from "uuid";

import { ActionButton } from "components/ui/Buttons";
import { BodyText2 } from "components/ui/Typography/Typography";
import { SortableList } from "components/ui/Lists";

import { SortableListFormImagesListItem } from "feature/panel/_shared/Sortable/SortableListFormImagesListItem";

import { setNewOrder } from "utils/sortable";

const StyledSortableList = styled(SortableList)`
  && {
    width: 100%;
    overflow-y: auto;
    display: flex;
  }
`;

const SortableListFormElement = ({ item, onGalleryChange, disabled, imageErrors }) => {
  const { images } = item;

  const handleGalleryImages = value => onGalleryChange({ ...item, images: value });

  const handleAddImage = () => {
    const imagesList = [...item.images, { id: uuidv4(), image: null, description: "" }];
    handleGalleryImages(imagesList);
  };

  const handleRemoveImage = id => {
    const imagesList = item.images.filter(img => img.id !== id);
    handleGalleryImages(imagesList);
  };

  const handleUpdateImage = (id, updatedImageData) => {
    const imagesList = item.images.map(img => (img.id === id ? updatedImageData : img));
    handleGalleryImages(imagesList);
  };

  const handleChangeOrder = ({ newIndex, oldIndex }) => {
    const imagesList = setNewOrder(newIndex, oldIndex, images);
    handleGalleryImages(imagesList);
  };

  return (
    <Grid container>
      <Grid item container xs={12} justifyContent="space-between" style={{ paddingRight: "4px" }}>
        <BodyText2>Images:</BodyText2>
        {!disabled && (
          <ActionButton size="small" onClick={handleAddImage}>
            <AddIcon />
            ADD
          </ActionButton>
        )}
      </Grid>
      <Grid item xs={12}>
        {images.length > 0 && (
          <StyledSortableList
            axis="xy"
            onSortEnd={handleChangeOrder}
            style={{ width: "100%", display: "flex", justifyContent: "space-between", flexWrap: "wrap", marginTop: 24 }}
            lockToContainerEdges
            useDragHandle
          >
            {images.map((image, index) => (
              <SortableListFormImagesListItem
                key={image.id}
                index={index}
                item={image}
                onUpdate={handleUpdateImage}
                onDelete={handleRemoveImage}
                disabled={disabled}
                errorImgId={imageErrors?.find(e => e === image.id)}
              />
            ))}
          </StyledSortableList>
        )}
      </Grid>
    </Grid>
  );
};

SortableListFormElement.propTypes = {
  onGalleryChange: PropTypes.func.isRequired,
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    title: PropTypes.string,
    position: PropTypes.number,
    images: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
};

export { SortableListFormElement, StyledSortableList };
