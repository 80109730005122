import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import uuidv4 from "uuid";
import PropTypes from "prop-types";

import { CustomSelect } from "feature/panel/_shared/Notifications/styledComponents";

export const DaySelector = ({ onChange, value, selectRange, ...selectProps }) => {
  const rangeArray = Array.from(Array(selectRange + 1).keys());

  return (
    <CustomSelect label="Number of days" value={Math.abs(value)} onChange={onChange} name="days_number" {...selectProps}>
      {rangeArray.map((eachOptionCount, index) => (
        <MenuItem key={uuidv4()} value={index}>
          {index}
        </MenuItem>
      ))}
    </CustomSelect>
  );
};

DaySelector.defaultProps = {
  selectRange: 200,
};

DaySelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  selectRange: PropTypes.number,
};
