import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { useTableHandlers } from "hooks/useTableHandlers";

import { NoResultsMessage } from "components/ui/Messages/NoResultsMessage";
import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";
import { Table, TableBody, TableHeader, TablePagination } from "components/ui/Tables";
import { DndRequestsTableAction } from "feature/panel/Stays/_shared/DoNotDisturb/DndRequestsTableAction";

import { DndRequestRow } from "feature/panel/Stays/_shared/DoNotDisturb/DndRequestRow";

import { getStaysDndStart, setStaysDndApprovalStart } from "store/stays/actions";
import { GET_STAYS_DND_START } from "store/stays/actionTypes";

import { DND_URL } from "constants/api";

import { setUrl } from "utils/url";
import { STAY_WIZARD_CONTENT } from "constants/content";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

const dndRequestsColumns = [
  { label: STAY_WIZARD_CONTENT.dnd.room, key: "room_nr", show: true, align: "left" },
  { label: STAY_WIZARD_CONTENT.dnd.guestName, key: "user_name", show: true, align: "left" },
  { label: STAY_WIZARD_CONTENT.dnd.dateSent, key: "created_at", show: true, align: "left" },
  { label: STAY_WIZARD_CONTENT.dnd.timeSent, key: "start_time", show: true, align: "left" },
  { label: STAY_WIZARD_CONTENT.dnd.endTime, key: "end_time", show: true, align: "left" },
];

const DndRequestsList = () => {
  const { operator_code } = useParams();
  const [apiUrl, setApiUrl] = useState("");
  const { dnd, inProgress, dndCount, actionType } = useSelector(state => state.stays);
  const vamoosId = useSelector(({ stayWizard }) => stayWizard.form.vamoosId);

  const permissionsService = usePermissionsService();

  const passcode = "DEFAULT";

  const canEditItinerary = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoosId);

  const dispatch = useDispatch();

  const { handleChangePage, handleChangeRowsPerPage, handleChangeOrder } = useTableHandlers(apiUrl, getStaysDndStart);

  const handleAcceptRequest = id => {
    const newList = dnd.map(request => (request.id === id ? { ...request, approved: true } : request));
    dispatch(setStaysDndApprovalStart({ id, newList, userId: operator_code, passcode }));
  };

  const prepareUrl = () => {
    if (operator_code) {
      const preparedURL = setUrl(DND_URL, { operator: operator_code, code: passcode });
      setApiUrl(preparedURL);
    }
  };

  useEffect(prepareUrl, [operator_code]);

  if (inProgress && actionType === GET_STAYS_DND_START && !dnd.length) return <LoadingScreen />;
  if (!dnd.length) return <NoResultsMessage height="0">{STAY_WIZARD_CONTENT.dnd.listIsEmpty}</NoResultsMessage>;
  return (
    <Table
      headers={dndRequestsColumns}
      list={dnd}
      actions={({ item }) => <DndRequestsTableAction item={item} onAccept={handleAcceptRequest} canEdit={canEditItinerary} />}
      count={dndCount}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      onChangeOrder={handleChangeOrder}
    >
      <TableHeader withActions />
      <TableBody rowComponent={DndRequestRow} />
      <TablePagination />
    </Table>
  );
};

export { DndRequestsList };
