import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { SORTING_COLUMN_MAPPER } from "feature/admin/Operators/_shared/constants";

import { TABLE_ACTIONS_TITLES } from "constants/content";
import { LS_ADMIN_OPERATORS_SELECTED_COLUMNS } from "constants/localstorage";

import { Table, TableHeader, TableBody, TablePagination } from "components/ui/Tables";
import { EditAction } from "components/ui/Tables/TableRowActions";
import { ColumnSelector } from "components/_shared/ColumnSelector/ColumnSelector";

import { useColumnSelectorHandlers } from "hooks/useColumnSelectorHandlers";

import { TableRowActions } from "./TableRowActions";
import { OperatorsTableRow } from "./OperatorsTableRow";
import columns from "./TableOfOperatorsColumns.json";

const ActionsLabelWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Label = styled.div`
  width: 72px;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TableOfOperators = ({ operators, tableHandlers, count, isDataLoading }) => {
  const [openColumnSelectorPopup, setOpenColumnSelectorPopup] = useState(false);

  const { headers, handleSave } = useColumnSelectorHandlers(LS_ADMIN_OPERATORS_SELECTED_COLUMNS, columns, setOpenColumnSelectorPopup);

  const { handleChangeOrder, handleChangePage, handleChangeRowsPerPage, rowsPerPage, page } = tableHandlers;

  const onOrderChange = ({ column, order }) => handleChangeOrder({ order, column: SORTING_COLUMN_MAPPER[column] });

  const ActionsLabel = () => (
    <ActionsLabelWrapper>
      <Label>Active</Label>
      <EditAction
        noBackground
        noBorder
        clickAction={() => setOpenColumnSelectorPopup(true)}
        height={16}
        tooltip={TABLE_ACTIONS_TITLES.editColumns}
      />
    </ActionsLabelWrapper>
  );

  return (
    <Table
      headers={headers}
      list={operators}
      actions={TableRowActions}
      onChangeOrder={onOrderChange}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      isDataLoading={isDataLoading}
    >
      <TableHeader withActions actionsLabel={<ActionsLabel />} />
      <TableBody rowComponent={OperatorsTableRow} />
      <TablePagination count={count} page={page - 1} rowsPerPage={rowsPerPage} />
      <ColumnSelector
        open={openColumnSelectorPopup}
        onClose={() => setOpenColumnSelectorPopup(false)}
        onSave={handleSave}
        list={headers}
        limit={headers.length}
      />
    </Table>
  );
};

TableOfOperators.propTypes = {
  operators: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  tableHandlers: PropTypes.shape({
    handleChangeOrder: PropTypes.func,
    handleChangePage: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func,
    rowsPerPage: PropTypes.number,
    page: PropTypes.number,
  }).isRequired,
  count: PropTypes.number.isRequired,
};

export { TableOfOperators };
