import { TRIPS_SEARCH_FLIGHTS_START, TRIPS_ADD_SELECTED_FLIGHTS_START } from "store/trips/actionTypes";

export const shoudShowFlightsSearchResults = (finished, inProgress, actionType, listOfFoundFlightsLength) =>
  (finished && actionType === TRIPS_SEARCH_FLIGHTS_START && listOfFoundFlightsLength > 0) ||
  (inProgress && actionType === TRIPS_ADD_SELECTED_FLIGHTS_START);

export const shouldShowNotFoundText = (finished, actionType, listOfFoundFlightsLength) =>
  finished && actionType === TRIPS_SEARCH_FLIGHTS_START && listOfFoundFlightsLength === 0;

export const shoudlCloseModal = (finished, actionType) => finished && actionType === TRIPS_ADD_SELECTED_FLIGHTS_START;
