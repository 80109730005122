import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { SharedNavigation } from "feature/panel/_shared/Navigations/SharedNavigation";
import { Actions } from "feature/panel/Pois/Index/Actions/Actions";
import { TableOfPois } from "feature/panel/Pois/Index/TableOfPois/TableOfPois";
import { PoiModal } from "feature/panel/Pois/Index/PoiModal/PoiModal";
import { PoisMap } from "feature/panel/Pois/Index/PoisMap/PoisMap";
import { useEffectDependenciesOnly } from "hooks/useEffectDependenciesOnly";
import { PanelTemplate } from "components/templates/Panel/PanelTemplate";
import { Content } from "components/ui/Content";
import { EventBus } from "services/application/EventBus";
import { PoiHasBeenCreated } from "events/PoiHasBeenCreated";
import { PoiHasBeenUpdated } from "events/PoiHasBeenUpdated";
import { PoiHasBeenDeleted } from "events/PoiHasBeenDeleted";
import { usePopupHandlers } from "hooks/usePopupHandlers";
import { getIconsListStart } from "store/operator/actions";
import { pushErrorNotification, pushSuccessNotification } from "store/app/actions";
import { ERRORS } from "constants/content";
import { callFn } from "utils";
import notifications from "constants/notifications";
import { useQuery } from "@tanstack/react-query";
import { PoiHasBeenDisabledOrEnabled } from "events/PoiHasBeenDisabledOrEnabled";

const Pois = () => {
  const ref = useRef();
  const dispatch = useDispatch();
  const poiModal = usePopupHandlers();
  const currentOperatorCode = useSelector(state => state.auth.currentOperatorCode);

  const [poi, setPoi] = useState(null);
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [searchedPois, setSearchedPois] = useState([]);

  const { data: pois, isLoading, refetch, isFetching } = useQuery({
    queryKey: ["poi?count=5000"],
  });
  const getIconsList = () => {
    dispatch(getIconsListStart());
  };

  const closePoiModal = () => {
    poiModal.close();
    setPoi(null);
  };

  const handleAddPoiFromMap = newPoi => {
    delete newPoi["country"];

    setPoi(newPoi);
    poiModal.open();
  };

  const handleClickCreatePoiButton = () => {
    poiModal.open();
  };

  const handleEditPoi = editedPoi => {
    setPoi(editedPoi);

    poiModal.open();
  };

  const handleClickRowAction = callFn({
    edit: handleEditPoi,
  });

  const resolvePoisToDisplay = list => {
    if (list?.length) {
      setIsSearchEnabled(true);
      setSearchedPois(list);
    } else {
      setIsSearchEnabled(false);
      setSearchedPois([]);
    }
  };

  useEffect(() => {
    const onPoiSave = async noPopup => {
      closePoiModal();

      try {
        refetch();
        if (!noPopup) dispatch(pushSuccessNotification(notifications.resource("poi").create.success));
      } catch (e) {
        dispatch(pushErrorNotification(ERRORS.unknownError));
      }
    };

    const onPoiDelete = async () => {
      dispatch(pushSuccessNotification(notifications.resource("poi").delete.success));

      setPoi(null);

      refetch();
    };

    EventBus.on(PoiHasBeenCreated, onPoiSave);
    EventBus.on(PoiHasBeenUpdated, onPoiSave);
    EventBus.on(PoiHasBeenDisabledOrEnabled, onPoiSave);
    EventBus.on(PoiHasBeenDeleted, onPoiDelete);

    return () => {
      EventBus.remove(PoiHasBeenCreated, onPoiSave);
      EventBus.remove(PoiHasBeenUpdated, onPoiSave);
      EventBus.remove(PoiHasBeenDeleted, onPoiDelete);
      EventBus.remove(PoiHasBeenDisabledOrEnabled, onPoiSave);
    };
  }, []);

  useEffect(getIconsList, []);

  useEffectDependenciesOnly(() => {
    refetch();
  }, [currentOperatorCode]);

  const contextBar = {
    left: null,
    middle: SharedNavigation,
    right: () => <Actions onClickCreate={handleClickCreatePoiButton} />,
  };
  return (
    <PanelTemplate languageSelector contextBar={contextBar}>
      <div ref={ref} />
      <Content>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <PoisMap pois={isSearchEnabled ? searchedPois : pois?.items} onAddMarker={handleAddPoiFromMap} loading={isLoading} />
          </Grid>
        </Grid>
      </Content>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <TableOfPois
            onClickRowAction={handleClickRowAction}
            onSearch={resolvePoisToDisplay}
            data={pois?.items}
            isLoading={isLoading}
            isFetching={isFetching}
            refetch={refetch}
            total={pois?.total_matches}
          />
        </Grid>
      </Grid>
      <PoiModal isOpen={poiModal.isOpen} onClose={closePoiModal} poi={poi} />
    </PanelTemplate>
  );
};

export { Pois };
