/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */

import { GET_CONVERSATIONS_FOR_OPERATOR_URL, OPERATE_ON_MESSAGES_URL } from "constants/api";
import { HttpClient } from "services/application/httpClient/httpClient";
import { setUrl } from "utils/url";

const getConversationsHandler = details => {
  const conversationsUrl = setUrl(GET_CONVERSATIONS_FOR_OPERATOR_URL, details, true);
 
  return HttpClient.get(conversationsUrl).then(({ data }) => data.items);
};
const getGroupedMessagesHandler = details => {
  const messagesUrl = setUrl(OPERATE_ON_MESSAGES_URL, details, true);

  return HttpClient.get(messagesUrl).then(({ data }) => {
    const messages = data.items.reverse().reduce((messagesGroups, message) => {
      if (!messagesGroups[message.conversation]) {
        messagesGroups[message.conversation] = [message];
      } else {
        messagesGroups[message.conversation].push(message);
      }
      return messagesGroups;
    }, {});

    return messages;
  });
};

const fetchConversationMessagesHandler = conversationDetails => {
  const conversationsList = getConversationsHandler(conversationDetails);
  const conversationsMessages = getGroupedMessagesHandler(conversationDetails);
  return new Promise((resolve, reject) => {
    Promise.all([conversationsList, conversationsMessages])
      .then(([conversations, groupedMessages]) => {
        const formattedConversations = conversations.map(conversation => ({
          ...conversation,
          messages: groupedMessages[conversation.conversation] || [],
        }));

        resolve(formattedConversations);
      })
      .catch(({ stack }) => {
        const error = new Error(`Unable to fetch messages from the API. Reason: ${stack}`);
        reject(error);
      });
  });
};

export { fetchConversationMessagesHandler, getConversationsHandler, getGroupedMessagesHandler };
