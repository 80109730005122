import Button from "@material-ui/core/Button";
import {
  AutorenewOutlined,
  DeleteOutlined,
  EditOutlined,
  FileCopyOutlined,
  GetAppOutlined,
  GroupOutlined,
  HistoryOutlined,
  MessageOutlined,
  PlaylistAddOutlined,
  VpnKeyOutlined,
} from "@material-ui/icons";
import { PeopleAltOutlined, RemoveRedEyeOutlined } from "@mui/icons-material";
import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";

import Tooltip from "@material-ui/core/Tooltip";
import Badge from "@mui/material/Badge";
import { Switch } from "components/ui/Forms";
import { responsiveConfig } from "config/responsive";
import { getMediaPx } from "helpers";

const baseStyling = css`
  && {
    color: ${({ theme }) => theme.colors.grey50};
    width: ${({ theme }) => theme.setSpacing(18)}px;
    height: ${({ height, theme }) => (height && typeof height === "number" ? theme.setSpacing(height) : theme.setSpacing(18))}px;
    border-radius: 0;

    @media (max-width: ${p => getMediaPx(p, "sm")}px) {
      width: 50px;
      height: 50px;
      background-color: #fff !important;
      min-width: 50px;
    }
  }
`;

const ActionWrapper = styled.span`
  border-left: ${({ theme, noBorder }) => (noBorder ? "none" : `1px solid ${theme.colors.grey10}`)};
  background-color: ${({ theme, noBackground }) => (noBackground ? "transparent" : theme.colors.withOpacity(theme.colors.grey10, 0.3))};

  &:first-child {
    border-left: none;
  }
`;

export const BaseButton = styled(({ ...rest }) => <Button {...rest} />)`
  ${baseStyling};
  opacity: ${p => (p.disabled ? 0.3 : 1)};
`;

export const BaseAction = ({ clickAction, icon, tooltip, disabled, noBackground, noBorder, ...rest }) => {
  const buttonWithTooltip = () => (
    <Tooltip title={tooltip}>
      <ActionWrapper noBackground={noBackground} noBorder={noBorder}>
        <BaseButton onClick={clickAction} disabled={disabled} {...rest}>
          {icon}
        </BaseButton>
      </ActionWrapper>
    </Tooltip>
  );

  const buttonWithoutTooltip = () => (
    <ActionWrapper noBackground={noBackground}>
      <BaseButton onClick={clickAction} disabled={disabled} {...rest}>
        {icon}
      </BaseButton>
    </ActionWrapper>
  );

  return <>{tooltip && (!disabled || rest.showTooltip) ? buttonWithTooltip() : buttonWithoutTooltip()}</>;
};

BaseAction.defaultProps = {
  clickAction: () => {},
  tooltip: null,
  disabled: false,
  noBackground: false,
  noBorder: false,
};

BaseAction.propTypes = {
  clickAction: PropTypes.func,
  icon: PropTypes.element.isRequired,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
  noBackground: PropTypes.bool,
  noBorder: PropTypes.bool,
};

export const SwitchWrapper = styled.div`
  ${baseStyling};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, noBackground }) => (noBackground ? "transparent" : theme.colors.withOpacity(theme.colors.grey10, 0.3))};
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    background-color: #fff;
  }
`;

export const SwitchAction = ({ clickAction, ...props }) => {
  return (
    <SwitchWrapper noBackground={props.noBackground}>
      <Switch onClick={clickAction} {...props} />
    </SwitchWrapper>
  );
};

SwitchAction.propTypes = {
  clickAction: PropTypes.func.isRequired,
};

export const TableActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const ShowAction = props => <BaseAction icon={<FileCopyOutlined />} {...props} />;
export const MessageAction = props => <BaseAction icon={<MessageOutlined />} {...props} />;
export const EditAction = props => <BaseAction icon={<EditOutlined />} {...props} />;
export const DownloadAction = props => <BaseAction icon={<GetAppOutlined />} {...props} />;
export const DeleteAction = props => <BaseAction icon={<DeleteOutlined />} {...props} />;
export const RevertAction = props => <BaseAction icon={<HistoryOutlined />} {...props} />;
export const RefreshAction = props => <BaseAction icon={<AutorenewOutlined />} {...props} />;
export const AddAction = props => <BaseAction icon={<PlaylistAddOutlined />} {...props} />;
export const KeyAction = props => <BaseAction icon={<VpnKeyOutlined />} {...props} />;
export const ViewAction = props => <BaseAction icon={<RemoveRedEyeOutlined />} {...props} />;
export const TravellersAction = props => <BaseAction icon={<PeopleAltOutlined />} {...props} />;

const CustomBadge = styled(Badge)`
  & .MuiBadge-badge {
    background-color: ${({ theme, cv }) => theme.colors[cv] || theme.colors.brand};
    font-size: ${({ badgesize }) => badgesize};
    ${({ textcolor }) =>
      textcolor &&
      css`
        color: ${textcolor};
      `}
  }
`;

export const TravellersRestrictionAction = props => {
  const { numberOfTravelers, noBackground, noBorder, ...rest } = props;
  return (
    <BaseAction
      noBackground={noBackground}
      noBorder={noBorder}
      icon={
        <CustomBadge badgeContent={numberOfTravelers} badgesize="12px" textcolor="white" showZero>
          <GroupOutlined />
        </CustomBadge>
      }
      {...rest}
    />
  );
};

TravellersRestrictionAction.propTypes = {
  numberOfTravelers: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  noBackground: PropTypes.bool,
  noBorder: PropTypes.bool,
};

TravellersRestrictionAction.defaultProps = {
  numberOfTravelers: null,
  noBackground: false,
  noBorder: false,
};
