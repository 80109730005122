import styled from "styled-components";
import { getMediaPx } from "../../../../helpers";

export const ActionItemsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-bottom: ${({ theme }) => theme.setSpacing(2)}px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  padding-bottom: 15px;
`;