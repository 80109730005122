import * as actionTypes from "store/customAppForm/actionTypes";

import { reducer, setState, globalReducerActions } from "utils/store";
import { CUSTOM_APPS, GLOBAL_CONTENT } from "constants/content";

const initFormObject = {
  id: null,
  name: "",
  status: null,
  bundleId: null,
  sequenceNumber: null,
  colors: {
    accentColor: "#FF7C46",
    defaultBackground: "#2E2F2E",
    loginBackground: "#2E2F2E",
    loginBorderColor: "#2E2F2E",
    loginTextColor: "#FFFFFF",
    loginNavigationColor: "#FFFFFF",
    inputFieldBackgroundColor: "#FFFFFF00",
    inputFieldFrameColor: "#FFFFFF",
    inputTextColor: "#989898",
    inputHintColor: "#AAAAAA",
    buttonBackgroundColorActive: "#FF7C46",
    buttonTextColorActive: "#FFFFFF",
    buttonTextColorInactive: "#FFFFFF",
    buttonBackgroundColorInactive: "#9E9D9E",
    menuBackgroundColour: "#2E2F2E",
    menuTextColour: "#FFFFFF",
    menuIconColour: "#FFFFFF",
    menuItineraryBackgroundColour: "#FFFFFF",
    menuDividerColour: "#FFFFFF",
    textsColors: {
      backgroundColor: "#2E2F2E",
      textColor: "#FFFFFF",
      headlineColor: "#FFFFFF",
    },
    iconBackgroundColor: "#FFFFFF",
  },
  language: "",
  default_language: "",
  strings: {
    loginText1: {
      colour: "#FFFFFF",
      languages: {
        en: {
          text: "",
        },
      },
    },
    loginText2: {
      languages: {
        en: {
          text: "",
        },
      },
      colour: "#FFFFFF",
    },
    loginText3: {
      languages: {
        en: {
          text: "",
        },
      },
      colour: "#FFFFFF",
    },
    loginText4: {
      languages: {
        en: {
          text: "",
        },
      },
      colour: "#FFFFFF",
    },
    loginText5: {
      languages: {
        en: {
          text: "",
        },
      },
      colour: "#FFFFFF",
    },
    loginUserIdHint: {
      languages: {
        en: {
          text: GLOBAL_CONTENT.userId,
        },
      },
    },
    loginNextButton: {
      languages: {
        en: {
          text: CUSTOM_APPS.loginPage.stay.next,
        },
      },
    },
    loginPasscodeHeaderTextStay: "",
    loginPasscodeHeaderTextTrip: "",
    loginPasscodeHint: "",
    loginPasscodeSubmitButton: "",
    loginPasscodeSkipButton: "",
    settingsTitle: "",
    myItinerariesTitle: "",
    aboutUsTitle: "",
    faqTitle: "",
    termsTitle: "",
    galleryTitle: "",
  },

  settings: {
    uppercasedMenuNames: false,
    loginBorders: "none",
    loginStatusbarStyle: "default",
    defaultUserId: "",
    buttonSquareCorners: true,
    inputFieldSquareCorners: false,
  },
  fonts: {
    // Sample font unpdate when font select will be ready
    customFont: "https://fonts.google.com/specimen/Open+Sans?preview.text_type=custom",
  },
  images: {
    icon: null,
    iconTransparent: null,
    logo: null,
    backgroundImage: null,
  },
  menu: {
    documents: {
      faq: {
        styles: {
          backgroundColor: "#2E2F2E",
          textColor: "#FFFFFF",
          headlineColor: "#FFFFFF",
        },
        languages: {
          en: {
            title: CUSTOM_APPS.faq.title,
            html_content: "",
          },
        },
      },
      about: {
        styles: {
          backgroundColor: "#2E2F2E",
          textColor: "#FFFFFF",
          headlineColor: "#FFFFFF",
        },
        languages: {
          en: {
            title: CUSTOM_APPS.about.title,
            html_content: "",
          },
        },
      },
      terms: {
        styles: {
          backgroundColor: "#2E2F2E",
          textColor: "#FFFFFF",
          headlineColor: "#FFFFFF",
        },
        languages: {
          en: {
            title: CUSTOM_APPS.termsAndCondition.title,
            html_content: "",
          },
        },
      },
    },
  },
};

const customAppFormInitialState = {
  errors: {},
  actionType: null,
  inProgress: false,
  finished: false,
  customAppForm: initFormObject,
  caVersionsList: [],
  count: 0,
  isFormTouched: false,
  isBundleIdUnique: true,
};

const ReducerActions = globalReducerActions("customAppForm", customAppFormInitialState, {
  [actionTypes.CLEAR_CUSTOM_APP_FORM](state) {
    return setState(state, {
      customAppForm: initFormObject,
      caVersionsList: [],
      isFormTouched: false,
      finished: false,
      actionType: null,
      isBundleIdUnique: true,
      errors: {},
    });
  },
  [actionTypes.GET_CUSTOM_APP_BY_ID_START](state) {
    return setState(state, {
      isFormTouched: false,
      actionType: actionTypes.GET_CUSTOM_APP_BY_ID_START,
      inProgress: true,
    });
  },

  [actionTypes.GET_CUSTOM_APP_BY_ID_SUCCESS](state, payload) {
    return setState(state, {
      inProgress: false,
      isFormTouched: false,
      customAppForm: { ...payload, settings: { ...payload.settings } },
      finished: false,
    });
  },

  [actionTypes.GET_CUSTOM_APP_BY_ID_FAIL](state, payload) {
    return setState(state, {
      inProgress: false,
      finished: false,
      customAppForm: {},
      errors: payload.errors,
    });
  },
  [actionTypes.SET_IS_FORM_TOUCHED](state, payload) {
    return setState(state, {
      isFormTouched: payload,
    });
  },
  [actionTypes.CHECK_USER_ID_FOR_CUSTOM_APP_AVAILABILITY_FAIL](state, payload) {
    return setState(state, {
      errors: {
        ...state.errors,
        userId: payload.error,
      },
    });
  },
  [actionTypes.CHECK_BUNDLE_ID_AVAILABLE_SUCCESS](state, payload) {
    const errors = {
      ...state.errors,
    };
    if (payload.bundleId) {
      errors.bundleId = payload.bundleId;
    } else {
      delete errors.bundleId;
    }
    return setState(state, {
      errors,
    });
  },
  [actionTypes.SET_BUNDLE_ID_UNQUENESS_FLAG](state, payload) {
    return setState(state, {
      isBundleIdUnique: payload.value,
    });
  },
  [actionTypes.SAVE_NEW_CUSTOM_APP_START](state) {
    return setState(state, {
      inProgress: true,
      isFormTouched: false,
      actionType: actionTypes.SAVE_NEW_CUSTOM_APP_START,
    });
  },
  [actionTypes.SAVE_NEW_CUSTOM_APP_SUCCESS](state) {
    return setState(state, {
      inProgress: false,
      isFormTouched: false,
      finished: true,
      actionType: actionTypes.SAVE_NEW_CUSTOM_APP_SUCCESS,
    });
  },
  [actionTypes.SAVE_NEW_CUSTOM_APP_FAIL](state, payload) {
    return setState(state, {
      inProgress: false,
      errors: { ...payload.errors },
    });
  },
  [actionTypes.UPDATE_CUSTOM_APP_DEFINITION_START](state) {
    return setState(state, {
      inProgress: true,
      actionType: actionTypes.UPDATE_CUSTOM_APP_DEFINITION_START,
    });
  },
  [actionTypes.UPDATE_CUSTOM_APP_DEFINITION_SUCCESS](state) {
    return setState(state, {
      inProgress: false,
      finished: true,
    });
  },
  [actionTypes.UPDATE_CUSTOM_APP_DEFINITION_FAIL](state, payload) {
    return setState(state, {
      inProgress: false,
      finished: false,
      errors: { ...payload.errors },
    });
  },
  [actionTypes.SUBMIT_CUSTOM_APP_DEFINITION_FOR_REVIEW_START](state) {
    return setState(state, {
      inProgress: true,
      actionType: actionTypes.SUBMIT_CUSTOM_APP_DEFINITION_FOR_REVIEW_START,
    });
  },
  [actionTypes.SUBMIT_CUSTOM_APP_DEFINITION_FOR_REVIEW_SUCCESS](state) {
    return setState(state, {
      inProgress: false,
      finished: true,
    });
  },
  [actionTypes.SUBMIT_CUSTOM_APP_DEFINITION_FOR_REVIEW_FAIL](state, payload) {
    return setState(state, {
      inProgress: false,
      finished: false,
      errors: { ...payload.errors },
    });
  },
  [actionTypes.DISMISS_CUSTOM_APP_START](state) {
    return setState(state, {
      inProgress: true,
      actionType: actionTypes.DISMISS_CUSTOM_APP_START,
    });
  },
  [actionTypes.DISMISS_CUSTOM_APP_SUCCESS](state) {
    return setState(state, {
      inProgress: false,
      finished: true,
    });
  },
  [actionTypes.DISMISS_CUSTOM_APP_FAIL](state, payload) {
    return setState(state, {
      inProgress: false,
      finished: false,
      errors: { ...payload.errors },
    });
  },
  [actionTypes.GET_CUSTOM_APP_VERSIONS_LIST_START](state) {
    return setState(state, {
      inProgress: true,
    });
  },
  [actionTypes.GET_CUSTOM_APP_VERSIONS_LIST_SUCCESS](state, payload) {
    return setState(state, {
      inProgress: false,
      caVersionsList: [...payload.list],
      count: payload.count,
    });
  },

  [actionTypes.GET_CUSTOM_APP_VERSIONS_LIST_FAIL](state, payload) {
    return setState(state, {
      inProgress: false,
      errors: { ...payload.errors },
    });
  },
  // SETUP STRINGS
  [actionTypes.SET_VALUE_FOR_APP_LOGIN_USER_ID_HINT_STRING](state, { language, value: text }) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        strings: {
          ...state.customAppForm.strings,
          loginUserIdHint: {
            ...(state.customAppForm.strings.loginUserIdHint || {}),
            languages: {
              ...(state.customAppForm.strings.loginUserIdHint.languages || {}),
              [language]: {
                text,
              },
            },
          },
        },
      },
    });
  },
  [actionTypes.SET_CUSTOM_APP_CURRENT_LANGUAGE](state, payload) {
    return setState(state, {
      customAppForm: {
        ...state.customAppForm,
        language: payload,
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_LOGIN_TEXT](state, { language, value: text, fieldName }) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        strings: {
          ...state.customAppForm.strings,
          [fieldName]: {
            ...state.customAppForm.strings[fieldName],
            languages: {
              ...state.customAppForm.strings[fieldName].languages,
              [language]: {
                ...(state.customAppForm.strings[fieldName][language] || {}),
                text,
              },
            },
          },
        },
      },
    });
  },

  [actionTypes.SET_VALUE_FOR_APP_LOGIN_TEXT_COLOR_COMMON](state, { value: colour, fieldName }) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        strings: {
          ...state.customAppForm.strings,
          [fieldName]: {
            ...state.customAppForm.strings[fieldName],
            colour,
          },
        },
      },
    });
  },

  [actionTypes.SET_VALUE_FOR_APP_LOGIN_NEXT_STRING](state, { value: text, language }) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        strings: {
          ...state.customAppForm.strings,
          loginNextButton: {
            ...state.customAppForm.loginNextButton,
            languages: {
              ...(state.customAppForm.loginNextButton.languages || {}),
              [language]: {
                text,
              },
            },
          },
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_LOGIN_PASSCODE_HEADER_TRIP_STRING](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        strings: {
          ...state.customAppForm.strings,
          loginPasscodeHeaderTextTrip: payload,
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_LOGIN_PASSCODE_HEADER_STAY_STRING](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        strings: {
          ...state.customAppForm.strings,
          loginPasscodeHeaderTextStay: payload,
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_LOGIN_PASSCODE_HINT_STRING](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        strings: {
          ...state.customAppForm.strings,
          loginPasscodeHint: payload,
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_LOGIN_PASSCODE_SUBMIT_BUTTON_STRING](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        strings: {
          ...state.customAppForm.strings,
          loginPasscodeSubmitButton: payload,
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_LOGIN_PASSCODE_SKIP_BUTTON_STRING](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        strings: {
          ...state.customAppForm.strings,
          loginPasscodeSkipButton: payload,
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_MENU_LOAD_NEW_STRING](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        strings: {
          ...state.customAppForm.strings,
          menuLoadNew: payload,
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_MENU_VIEW_ALL_STRING](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        strings: {
          ...state.customAppForm.strings,
          menuViewAll: payload,
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_MENU_REFRESH_STRING](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        strings: {
          ...state.customAppForm.strings,
          menuRefresh: payload,
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_MENU_SETTINGS_STRING](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        strings: {
          ...state.customAppForm.strings,
          menuSettings: payload,
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_MENU_FAQ_STRING](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        strings: {
          ...state.customAppForm.strings,
          menuFaq: payload,
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_MENU_TERMS_STRING](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        strings: {
          ...state.customAppForm.strings,
          menuTerms: payload,
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_SETTING_TITLE_STRING](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        strings: {
          ...state.customAppForm.strings,
          settingsTitle: payload,
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_MY_ITINERART_TITLE_STRING](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        strings: {
          ...state.customAppForm.strings,
          myItinerariesTitle: payload,
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_ABOUT_US_TITLE_STRING](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        strings: {
          ...state.customAppForm.strings,
          aboutUsTitle: payload,
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_FAQ_TITLE_STRING](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        strings: {
          ...state.customAppForm.strings,
          faqTitle: payload,
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_TERMS_TITLE_STRING](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        strings: {
          ...state.customAppForm.strings,
          termsTitle: payload,
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_GALLERY_TITLE_STRING](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        strings: {
          ...state.customAppForm.strings,
          galleryTitle: payload,
        },
      },
    });
  },
  // SETUP COLORS
  [actionTypes.SET_VALUE_FOR_APP_LOGIN_BORDER_COLOR](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        colors: {
          ...state.customAppForm.colors,
          loginBorderColor: payload,
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_LOGIN_BG_COLOR](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        colors: {
          ...state.customAppForm.colors,
          loginBackground: payload,
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_LOGIN_TEXT_COLOR](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        colors: {
          ...state.customAppForm.colors,
          loginTextColor: payload,
        },
      },
    });
  },

  [actionTypes.SET_VALUE_FOR_APP_DEFAULT_BG_COLOR](state, payload) {
    const colors =
      state.customAppForm.type === "hotel"
        ? {
            defaultBackground: payload,
            loginBackground: payload,
          }
        : {
            defaultBackground: payload,
          };
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        colors: {
          ...state.customAppForm.colors,
          ...colors,
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_ACCENT_COLOR](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        colors: {
          ...state.customAppForm.colors,
          accentColor: payload,
        },
      },
    });
  },

  [actionTypes.SET_VALUE_FOR_APP_INPUT_FIELD_BG_COLOR](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        colors: {
          ...state.customAppForm.colors,
          inputFieldBackgroundColor: payload,
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_INPUT_FIELD_FRAME_COLOR](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        colors: {
          ...state.customAppForm.colors,
          inputFieldFrameColor: payload,
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_INPUT_TEXT_COLOR](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        colors: {
          ...state.customAppForm.colors,
          inputTextColor: payload,
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_INPUT_HINT_COLOR](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        colors: {
          ...state.customAppForm.colors,
          inputHintColor: payload,
        },
      },
    });
  },

  [actionTypes.SET_VALUE_FOR_APP_BUTTON_BG_ACTIVE_COLOR](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        colors: {
          ...state.customAppForm.colors,
          buttonBackgroundColorActive: payload,
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_BUTTON_BG_INACTIVE_COLOR](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        colors: {
          ...state.customAppForm.colors,
          buttonBackgroundColorInactive: payload,
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_BUTTON_TEXT_ACTIVE_COLOR](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        colors: {
          ...state.customAppForm.colors,
          buttonTextColorActive: payload,
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_BUTTON_TEXT_INACTIVE_COLOR](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        colors: {
          ...state.customAppForm.colors,
          buttonTextColorInactive: payload,
        },
      },
    });
  },

  [actionTypes.SET_VALUE_FOR_APP_MENU_BG_COLOR](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        colors: {
          ...state.customAppForm.colors,
          menuBackgroundColour: payload,
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_MENU_ICON_COLOR](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        colors: {
          ...state.customAppForm.colors,
          menuIconColour: payload,
          menuTextColour: payload,
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_MENU_ITINERARY_BG_COLOR](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        colors: {
          ...state.customAppForm.colors,
          menuItineraryBackgroundColour: payload,
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_MENU_DIVIDER_COLOR](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        colors: {
          ...state.customAppForm.colors,
          menuDividerColour: payload,
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_MENU_TEXT_COLOR](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        colors: {
          ...state.customAppForm.colors,
          menuTextColour: payload,
          menuIconColour: payload,
        },
      },
    });
  },

  [actionTypes.SET_COLOR_FOR_APP](state, payload) {
    const { varName, color } = payload;
    const documents = state.customAppForm.menu.documents;

    for (const key in documents) {
      const item = documents[key];
      const styles = item.styles;
      item.styles = {
        ...styles,
        [varName]: color,
      };
      documents[key] = item;
    }

    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        menu: {
          documents,
        },
      },
    });
  },

  [actionTypes.SET_VALUE_FOR_APP_UPPERCASE_MENU_NAMES_SETTING](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        settings: {
          ...state.customAppForm.settings,
          uppercasedMenuNames: payload,
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_LOGIN_BORDERS_SETTING](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        settings: {
          ...state.customAppForm.settings,
          loginBorders: payload,
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_DEFAULT_STATUSBAR_STYLE_SETTING](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        settings: {
          ...state.customAppForm.settings,
          loginStatusbarStyle: payload,
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_DEFAULT_USER_ID_SETTING](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        settings: {
          ...state.customAppForm.settings,
          defaultUserId: payload,
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_BUTTON_SQUARE_CORNERS_SETTING](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        settings: {
          ...state.customAppForm.settings,
          buttonSquareCorners: payload,
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_BUTTON_INPUT_FIELD_SQUARE_CORNERS_SETTING](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        settings: {
          ...state.customAppForm.settings,
          inputFieldSquareCorners: payload,
        },
      },
    });
  },

  [actionTypes.SET_VALUE_FOR_APP_ICON_IMAGE](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        images: {
          ...state.customAppForm.images,
          icon: payload,
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_ICON_TRANSPARENT_IMAGE](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        images: {
          ...state.customAppForm.images,
          iconTransparent: payload,
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_ICON_BACKGROUND_COLOR](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        colors: {
          ...state.customAppForm.colors,
          iconBackgroundColor: payload,
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_LOGO_IMAGE](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        images: {
          ...state.customAppForm.images,
          logo: payload,
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_BACKGROUND_IMAGE](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        images: {
          ...state.customAppForm.images,
          backgroundImage: payload,
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_CUSTOM_FONT](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        fonts: {
          customFont: payload,
        },
      },
    });
  },

  [actionTypes.SET_VALUE_FOR_APP_MENU_DOC_CONTENT](state, payload) {
    const { type, content, language, title, defaultName } = payload;
    const itemLanguages = state.customAppForm.menu.documents[type]?.languages;
    const menuItem = itemLanguages?.[language] || null;
    const item = {
      title: title || itemLanguages[language]?.title || defaultName || menuItem?.name,
      html_content: typeof content === "string" ? content : content || menuItem?.htmlContent || itemLanguages["en"].html_content,
    };
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        menu: {
          documents: {
            ...state.customAppForm.menu.documents,
            [type]: {
              ...state.customAppForm.menu.documents[type],
              languages: {
                ...state.customAppForm.menu.documents[type]?.languages,
                [language]: {
                  ...item,
                },
              },
            },
          },
        },
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_MENU_DOC_NAME](state, payload) {
    const { index, name } = payload;
    const menuItem = state.customAppForm.menuDocuments[index];
    const newItem = {
      ...menuItem,
      name,
    };
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        menuDocuments: [...state.customAppForm.menuDocuments].map((doc, docIndex) => (docIndex === index ? newItem : doc)),
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_TITLE](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        name: payload,
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_DEFAULT_LANGUAGE](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        default_language: payload,
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_LANGUAGES](state, payload) {
    return setState(state, {
      isFormTouched: true,
      customAppForm: {
        ...state.customAppForm,
        languages: payload,
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_BUNDLE_ID](state, payload) {
    return setState(state, {
      customAppForm: {
        ...state.customAppForm,
        bundleId: payload,
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_SEQUENCE_NUMBER](state, payload) {
    return setState(state, {
      isFormTouched: false,
      customAppForm: {
        ...state.customAppForm,
        sequenceNumber: payload,
      },
    });
  },
  [actionTypes.SET_VALUE_FOR_APP_TYPE](state, payload) {
    return setState(state, {
      isFormTouched: false,
      customAppForm: {
        ...state.customAppForm,
        type: payload,
      },
    });
  },
  [actionTypes.REVISE_CUSTOM_APP_START](state) {
    return setState(state, {
      actionType: actionTypes.REVISE_CUSTOM_APP_START,
      inProgress: true,
    });
  },

  [actionTypes.REVISE_CUSTOM_APP_SUCCESS](state) {
    return setState(state, {
      inProgress: false,
      finished: true,
    });
  },
  [actionTypes.REVISE_CUSTOM_APP_FAIL](state, payload) {
    return setState(state, {
      inProgress: false,
      finished: false,
      errors: { ...payload.error },
    });
  },
});

const customAppFormReducer = (state = customAppFormInitialState, action) => reducer(state, action, ReducerActions);

export { customAppFormReducer };
