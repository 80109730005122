import React from "react";
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined";

// eslint-disable-next-line import/no-cycle
import { LeaveBaseButton, LeavePageButton } from "components/ui/Buttons/LeaveBaseButton";

const BackButton = props => <LeaveBaseButton icon={<ArrowBackIosOutlinedIcon fontSize="small" />} text="back" {...props} />;
const BackHistoryButton = props => <LeavePageButton icon={<ArrowBackIosOutlinedIcon fontSize="small" />} text="back" {...props} />;

export { BackButton, BackHistoryButton };
