import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import lodash from "lodash";

import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { BodyText2 } from "components/ui/Typography/Typography";
import { Content } from "components/ui/Content";
import { Input } from "components/ui/Forms";

import { UnsavedChangesMessage } from "feature/panel/_shared/CopyItinerary/UnsavedChangesMessage";
import { OperatorField } from "feature/panel/_shared/OperatorField/OperatorField";

import { useDefaultReducer } from "hooks/useDefaultReducer";

import { setUrl } from "utils/url";

import { checkUserIdTaken } from "store/stays/saga";
import { updateOperators } from "store/auth/actions";

import { DEFAULT_DEBOUNCE_DELAY, DEFAULT_PASSCODE, MAXIMUM_USER_ID_LENGTH, MINIMUM_USER_ID_LENGTH } from "constants/defaults";
import { CREATE_A_COPY, CREATE_COPY_OF, COPYING_STAY_MODAL, ERRORS, GLOBAL_CONTENT, USER_ID_EXPLANATION_MESSAGE } from "constants/content";
import { PANEL_STAYS_EDIT_PATH } from "constants/routes";

import { StayService } from "services/domain/StayService";

import { Logger } from "services/application/Logger";
import { pushErrorNotification, pushSuccessNotification } from "store/app/actions";
import { copyItinerarySuccess } from "constants/notifications";

import { CopyingStayModalList } from "./CopyingStayModalList";

const defaultSections = {
  documents: "documents",
  pois: "pois",
  details: "details",
  directories: "directory",
  daily: "daily",
  voucher: "voucher",
  passcodes: "passcodes",
  notifications: "notifications",
  passcode_groups: "passcode_groups",
  publishers: "publishers",
  translations: "translations",
};

const initialState = {
  [defaultSections.documents]: true,
  [defaultSections.pois]: true,
  [defaultSections.details]: true,
  [defaultSections.directories]: true,
  [defaultSections.daily]: true,
  [defaultSections.voucher]: true,
  [defaultSections.passcodes]: true,
  [defaultSections.notifications]: true,
  [defaultSections.passcode_groups]: true,
  [defaultSections.publishers]: false,
  [defaultSections.translations]: true,
};

const sections = [
  { label: COPYING_STAY_MODAL.actions, key: defaultSections.documents },
  { label: COPYING_STAY_MODAL.pois, key: defaultSections.pois },
  { label: COPYING_STAY_MODAL.gallery, key: defaultSections.details },
  { label: COPYING_STAY_MODAL.directory, key: defaultSections.directories },
  { label: COPYING_STAY_MODAL.daily, key: defaultSections.daily },
  { label: COPYING_STAY_MODAL.vouchers, key: defaultSections.voucher },
  { label: COPYING_STAY_MODAL.passcodes, key: defaultSections.passcode_groups },
  { label: COPYING_STAY_MODAL.notifications, key: defaultSections.notifications },
  { label: COPYING_STAY_MODAL.translations, key: defaultSections.translations },
  // { label: "Publisher", key: defaultSections.publishers },
];

const CopyingStayModal = ({ open, onCancel, hasUnsavedChanges, onSave, showSpinner, item }) => {
  const navigate = useNavigate();

  const stayService = new StayService();

  const dispatch = useDispatch();

  const title = CREATE_COPY_OF(item.propertyName || DEFAULT_PASSCODE);

  const { state, dispatch: dispatchLocal, setValueFor } = useDefaultReducer(initialState);

  const [inProgress, setInProgress] = useState(false);

  const { operators, currentOperatorCode } = useSelector(ReduxState => ReduxState.auth);

  const [availableSections, setAvailableSections] = useState(sections);
  const [newOperatorCode, setOperatorCode] = useState("");
  const [operatorCodeError, setOperatorCodeError] = useState("");
  const [selectedOperator, setOperator] = useState(currentOperatorCode);

  const handleUserIdAvailabilityCheck = async (userId, currentOperator) => {
    const isCurrentOperatorCode = operators.some(({ usedCodes }) => usedCodes && usedCodes.includes(userId));
    const isIdTaken = await checkUserIdTaken(userId, isCurrentOperatorCode, currentOperator);
    const errorMessage = isIdTaken ? ERRORS.userIdTaken : "";
    setOperatorCodeError(errorMessage);
  };

  const checkAvailableUserIds = useRef(
    lodash.debounce((params, operator) => handleUserIdAvailabilityCheck(params, operator), DEFAULT_DEBOUNCE_DELAY),
  );

  const isCopiedToOtherOperator = selectedOperator !== currentOperatorCode;

  const handleChangeOperatorCode = ({ target: { value } }) => {
    const valueWithoutDash = value.replaceAll("-", "");

    if (value.length >= MINIMUM_USER_ID_LENGTH) {
      checkAvailableUserIds.current(valueWithoutDash, selectedOperator);
    }
    if (value.length <= MAXIMUM_USER_ID_LENGTH) {
      setOperatorCode(valueWithoutDash);
    }
  };

  const handleChange = key => {
    setValueFor(key, !state[key]);
  };

  const resetState = () => {
    setOperatorCode("");
    setOperatorCodeError("");
    dispatchLocal({ type: "resetAllValues" });
  };

  const handleOperatorChange = ({ target }) => {
    setOperator(target.value);
  };

  const handleCancelOrClose = () => {
    resetState();
    onCancel();
  };

  const handleConfirm = async () => {
    setInProgress(true);

    const elementsToTransfer = Object.entries(state).reduce((acc, [key, value]) => {
      return value ? [...acc, key] : acc;
    }, []);

    try {
      await stayService.copyStay(
        item.userId || item.operator_code, // Needs to support old itinerary structure
        newOperatorCode.trim(),
        elementsToTransfer,
        isCopiedToOtherOperator,
        selectedOperator,
      );

      dispatch(pushSuccessNotification(copyItinerarySuccess("Stay")));

      const operatorsWithUpdatedCodes = operators.map(operator =>
        operator.code === currentOperatorCode ? { ...operator, used_codes: [...(operator?.used_codes || []), newOperatorCode] } : operator,
      );
      dispatch(updateOperators(operatorsWithUpdatedCodes));

      if (!isCopiedToOtherOperator) {
        navigate(setUrl(PANEL_STAYS_EDIT_PATH, { operator_code: newOperatorCode }, true));
      }
      setInProgress(false);
      handleCancelOrClose();
    } catch (e) {
      setInProgress(false);
      Logger.debug(e);
      dispatch(pushErrorNotification(ERRORS.copyVamoos("Stay")));
    }
  };

  const handleAvailableSections = () => {
    const requiredSections =
      currentOperatorCode !== selectedOperator ? sections.filter(section => section.key !== defaultSections.pois) : sections;

    setAvailableSections(requiredSections);
  };

  useEffect(handleAvailableSections, [currentOperatorCode, selectedOperator]);

  return open ? (
    <ConfirmationModal
      open={open}
      onCancel={handleCancelOrClose}
      onConfirm={handleConfirm}
      title={title}
      fullWidth
      maxWidth="sm"
      confirmLabel={CREATE_A_COPY}
      confirmDisabled={newOperatorCode.length < MINIMUM_USER_ID_LENGTH || !!operatorCodeError}
      showSpinner={inProgress}
    >
      {hasUnsavedChanges ? (
        <UnsavedChangesMessage onSave={onSave} showSpinner={showSpinner} />
      ) : (
        <>
          {operators && operators.length > 1 && (
            <Content>
              <OperatorField
                name="operatorCode"
                label={GLOBAL_CONTENT.operator}
                onChange={handleOperatorChange}
                vamoosId={item?.vamoosId || null}
              />
            </Content>
          )}
          <Content>
            <Input
              name="userID"
              helperText={operatorCodeError || USER_ID_EXPLANATION_MESSAGE}
              value={newOperatorCode}
              label={`${GLOBAL_CONTENT.userId}*`}
              onChange={handleChangeOperatorCode}
              error={!!operatorCodeError}
            />
          </Content>
          <BodyText2>{GLOBAL_CONTENT.include}</BodyText2>
          <CopyingStayModalList sections={availableSections} state={state} onChange={handleChange} />
        </>
      )}
    </ConfirmationModal>
  ) : null;
};

CopyingStayModal.defaultProps = {
  hasUnsavedChanges: false,
  showSpinner: false,
  onSave: () => {},
};

CopyingStayModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  hasUnsavedChanges: PropTypes.bool,
  showSpinner: PropTypes.bool,
  onSave: PropTypes.func,
  item: PropTypes.shape({
    vamoosId: PropTypes.number,
    propertyName: PropTypes.string,
    field1: PropTypes.string,
    userId: PropTypes.string,
    operator_code: PropTypes.string,
  }).isRequired,
};

export { CopyingStayModal };
