import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import Checkbox from "components/_new/Checkbox";
import IconSelectOption from "components/_new/IconSelectOption";
import SelectInput from "components/ui/Inputs/Select";
import Input from "components/ui/Inputs/TextInput";
import { Controller } from "react-hook-form";
import { DOCUMENTS_FILE_TYPES } from "constants/defaults";
import AttachButton from "components/_new/Buttons/AttachButton";
import { colors } from "config/theme/colors";
import { DEFAULT_ACTION_ICON_ID } from "../../../Stays/_shared/initStaysState";

const Button = ({ label = "Button 1", control, name, watch, isSubmitted, setValue }) => {
  const { data: { items: icons } = {} } = useQuery({ queryKey: ["icon?count=500"] });
  const iconsModified = icons
    ?.filter(item => item.section === "navigation")
    ?.map(item => ({
      ...item,
      name: <IconSelectOption src={item.url} />,
    }));

  const checked = watch(name)?.isChecked;

  // const onCheck = checked => {
  //   if (checked) setValue(name, { isPublic: false, icon_id: 282 });
  //   else setValue(name, null);
  // };

  useEffect(() => {
    // if (!checked && setValue) setValue(name, { isChecked: undefined });
  }, [checked]);

  return (
    <div style={{ marginTop: 15 }}>
      <Checkbox label={label} control={control} name={`${name}.isChecked`} />
      <div style={{ borderLeft: `1px solid ${colors.grey10}`, paddingLeft: 15 }}>
        {checked && (
          <>
            {" "}
            <div style={{ display: "grid", gridTemplateColumns: "1fr 100px", gap: 15, marginTop: 15 }}>
              <Input
                control={control}
                name={`${name}.name`}
                label={label}
                rules={{
                  required: "Button title is required",
                }}
              />{" "}
              <SelectInput
                options={iconsModified}
                control={control}
                name={`${name}.icon_id`}
                optionLabelVar="name"
                optionValueVar="id"
                menuPlacement="top"
                isClearable={false}
                defaultValue={DEFAULT_ACTION_ICON_ID}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 15 }}>
              PDF document or URL to show for button:
              <Controller
                control={control}
                rules={{
                  required: "File is required",
                }}
                name={`${name}.file`}
                render={({ field: { onChange, value } }) => (
                  <AttachButton
                    allowedFileTypes={DOCUMENTS_FILE_TYPES}
                    types={["library", "upload", "webpage"]}
                    onConfirm={onChange}
                    onDelete={() => onChange(null)}
                    text="Attach / link"
                    file={value}
                    buttonWrapStyle={{ width: "auto" }}
                    mobileFullScreen
                    hideDescription
                    error={isSubmitted && !value && { message: "File is required" }}
                  />
                )}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Button;
