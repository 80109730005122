import { useMutation } from "@tanstack/react-query";
import { DEFAULT_LIBRARY_TABS, LibraryPopup } from "components/ui/LibraryPopup/LibraryPopup";
import { Spinner } from "components/ui/Spinner/Spinner";
import config from "config/app";
import { debounce } from "lodash";
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { HttpClient } from "services/application/httpClient/httpClient";
import styled from "styled-components";
import { getFilePreviewUrl } from "utils/library";

import LibraryModal from "components/_new/LibraryModal";
import { useRefreshedFileUrl } from "hooks/useRefreshedFileUrl";
import { IMAGES_MIME_TYPES } from "constants/defaults";

const NoImage = styled.div`
  width: 211px;
  height: 211px;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.grey40};

  &&:hover {
    background-color: #dddddd;
  }
`;

const ImgThumbBox = styled.div`
  width: 100%;
  height: 100%;
  border: 0.5px solid white;

  ${({ hasImage, disabled }) =>
    hasImage &&
    `
       cursor: ${disabled ? "default" : "pointer"};

      &:hover {
        filter: brightness(0.8);
      }
    `};

  ${({ isPrimary }) =>
    isPrimary &&
    `
      grid-row: 1/3;
      grid-column: 1/3;
    `};
  ${({ showDarker }) =>
    showDarker &&
    `
      filter: brightness(0.5);
    `};
`;

const ImsThumb = ({ url, isPrimary, isLoading, onClick, disabled }) => {
  return (
    <ImgThumbBox hasImage={!!url} onClick={onClick} isPrimary={isPrimary} showDarker={isLoading} disabled={disabled}>
      <div style={{ width: "100%", backgroundColor: "#eee", height: "100%", padding: 0, margin: 0, border: "none" }}>
        {url ? (
          <img
            src={url}
            loading="lazy"
            decoding="async"
            alt=""
            style={{ width: "100%", height: "100%", objectFit: "cover", gridRow: "1/1", gridColumn: "1/1" }}
          />
        ) : (
          <strong>&nbsp;</strong>
        )}
      </div>
    </ImgThumbBox>
  );
};

const ImsUpload = ({ style, onClick, disabled }) => (
  <div
    style={{
      ...(style ?? {}),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "#eeeeee",
      border: "0.5px solid white",
      cursor: disabled ? "default" : "pointer",
    }}
    onClick={onClick}
  >
    <span className="material-symbols-outlined">upload</span>
  </div>
);

const ImsertGrid = ({ image, onUpdateImage, item, newLibrary, disabled }) => {
  const { headline, content, location, id } = item || {};

  const [imageSuggestions, setImageSuggestions] = useState([null, null, null, null, null]);
  const [isLibraryOpen, setIsLibraryOpen] = useState(false);

  const libraryTabs = [DEFAULT_LIBRARY_TABS.library, DEFAULT_LIBRARY_TABS.files, DEFAULT_LIBRARY_TABS.unsplash];
  const filePreview = image ? getFilePreviewUrl(image) : undefined; // image.thumb_https_url is also available but the thumbnail seems to be too small

  const [previewImage, setPreviewImage] = useState(filePreview === "" ? null : filePreview);

  const refreshedUrl = useRefreshedFileUrl(previewImage);
  const refreshedHighQualityUrl = useRefreshedFileUrl(image?.https_url);

  const updateImageWithURL = (url, previewUrl = undefined) => {
    let filename = "";
    
    try {
      const fileUrl = new URL(url);
      filename = fileUrl.pathname.split("/").pop();
    } catch(e) {
      filename = "untitled_file.jpg"
    }

    onUpdateImage({
      file_name: filename,
      https_url: url,
      file_url: url,
      preview_url: (previewUrl && previewUrl !== "") ? previewUrl : url,
      is_library_file: false,
      type: "image/jpeg",
    })
  }

  useEffect(() => {
    if (refreshedUrl && previewImage !== refreshedUrl) {
      setPreviewImage(refreshedUrl);

      if (!image?.https_url) {
        updateImageWithURL(refreshedUrl);
      }
    }
  }, [refreshedUrl]);

  useEffect(() => {
    if (refreshedHighQualityUrl && refreshedHighQualityUrl !== "") {
      if (!previewImage) {
        setPreviewImage(refreshedUrl || refreshedHighQualityUrl);
      }

      if (refreshedHighQualityUrl !== image?.https_url && !previewImage) {
        updateImageWithURL(refreshedHighQualityUrl, refreshedUrl);
      }
    }
  }, [refreshedHighQualityUrl])

  const imageSearchMutation = useMutation(({ headline, content, location }) => {
    const searchLocation = location ? {
      longitude: location.longitude,
      latitude: location.latitude,
      country: location.country_iso ?? location.country,
    } : {};

    const payload = JSON.stringify({
      text: {
        high: { text: headline ?? '' },
        medium: { text: content ?? '' },
      },
      libraries: { own: 1, shared: 0 },
      location: searchLocation
    });

    return HttpClient.post(`${config.api.imsert.production}/search`, payload);
  },
    {
      onSuccess: res => {
        const newImageSuggestions = res.data?.results?.map(r => {
          const thumbUrl = r.thumbUrl;
          return { thumb: thumbUrl, image: r.imageUrl };
        });

        const output = [null, null, null, null, null];
        for (let idx = 0; idx < 5; idx++) {
          if (newImageSuggestions.length > idx) {
            output[idx] = newImageSuggestions[idx];
          }
        }
        setImageSuggestions(output);
      },
      onError: err => {
        setImageSuggestions([null, null, null, null, null]);
        console.error(`Failed Imsert Response: ${err.toString()}`);
      },
    },
  );

  const debounceImageSearch = useRef(
    debounce(({ headline, content, location, previewImage }) => {
      if (previewImage) {
        return;
      }

      const tempDivElement = document.createElement("div");
      tempDivElement.innerHTML = content;
      const plainContentText = tempDivElement.textContent || tempDivElement.innerText || "";

      if (!disabled) { imageSearchMutation.mutate({ headline, content: plainContentText, location }); }
    }, 1000),
  );

  const handleDebounceSearch = useMemo(() => {
    return () => {
      const fixedHeadline = headline?.startsWith("#") ? headline.substring(1) : headline;
      debounceImageSearch.current({ headline: fixedHeadline, content, location, previewImage });
    };
  }, [headline, content, location, previewImage]);

  useEffect(() => {
    if (!headline && !content && !location) return;

    let previewUrl = getFilePreviewUrl(image);
    if (previewUrl === "") {
      previewUrl = null;
    }

    if (previewImage !== previewUrl) {
      setPreviewImage(previewUrl); // not sure if I want this
    }

    if (!image && imageSuggestions[0] === null) {
      if (!headline && !content && !location) return;
      let plainContentText = content;

      if (plainContentText) {
        const tempDivElement = document.createElement("div");
        tempDivElement.innerHTML = content;
        plainContentText = tempDivElement.textContent || tempDivElement.innerText || "";
      }

      if (!disabled) { imageSearchMutation.mutate({ headline, content: plainContentText, location }); }
    }
  }, [image]);

  useLayoutEffect(() => {
    if (!headline && !content && !location) {
      setImageSuggestions([null, null, null, null, null]);
    } else {
      handleDebounceSearch();
    }
  }, [headline, content, location]);

  const showLoader = imageSearchMutation.isLoading && imageSuggestions[0] === null && !previewImage;

  if (previewImage) {
    const attribution = image?.file_meta?.attribution;

    return (
      <div key={`image-picked-${id}`} style={{ display: "flex", justifyContent: "flex-start", flexDirection: "column", minWidth: 211, opacity: disabled ? 0.4 : 1}}>
        <div style={{ borderRadius: "8px", overflow: "hidden", width: 211, height: 211, position: "relative" }}>
          <img alt="" src={previewImage} loading="lazy" decoding="async" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
          <div
            onClick={() => {
              if (disabled) return;
              setPreviewImage(null);
              onUpdateImage(null);
            }}
            style={{
              cursor: disabled ? "default" : "pointer",
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              top: 16,
              right: 16,
              width: 24,
              height: 24,
              fontSize: 24,
              color: "white",
              borderRadius: 24,
              padding: 4,
              paddingBottom: 8,
              backgroundColor: "#33333388",
            }}
          >
            x
          </div>
        </div>

        {attribution?.author_name && attribution?.author_url ? (
          <p style={{ fontSize: 12, color: "#666", textAlign: "center" }}>
            <a href={attribution.author_url} target="_blank" rel="noreferrer">
              {attribution.author_name}
            </a>
            {" / "}
            <a href={attribution.provider_url} target="_blank" rel="noreferrer">
              {attribution.provider_name}
            </a>
          </p>
        ) : null}
      </div>
    );
  }

  const hasImageSuggestions = imageSuggestions[0] !== null;

  return (
    <div key={`image-suggestion-${id}`} style={{ display: "flex", justifyContent: "flex-start", minWidth: 211, opacity: disabled ? 0.4 : 1 }}>
      {!hasImageSuggestions && !previewImage ? (
        <NoImage
          style={{ cursor: disabled ? "default" : "pointer", }}
          onClick={() => {
            if (disabled) return;
            setIsLibraryOpen(true);
          }}
        >
          {showLoader ? <Spinner size={42} cv="brand" /> : <span className="material-symbols-outlined">upload</span>}
        </NoImage>
      ) : (
        <>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 70px)",
              gridTemplateRows: "repeat(3, 70px)",
              borderRadius: "8px",
              overflow: "hidden",
              width: "211px",
              minWidth: 211,
            }}
          >
            {hasImageSuggestions
              ? imageSuggestions.map((imgData, idx) => (
                <ImsThumb
                  key={`img-thumb-${idx}-${id}`}
                  onClick={() => {
                    if (disabled) return;
                    setPreviewImage(imgData.thumb);
                    updateImageWithURL(imgData.image, imgData.thumb)
                  }}
                  url={imgData?.thumb}
                  isLoading={imageSearchMutation.isLoading}
                  isPrimary={idx === 0}
                  disabled={disabled}
                />
              ))
              : null}
            <ImsUpload onClick={() => !disabled && setIsLibraryOpen(true)} style={hasImageSuggestions ? {} : { gridArea: "1/1/4/4", display: "flex" }} disabled={disabled} />
          </div>
        </>
      )}

      {isLibraryOpen && newLibrary ? (
        <LibraryModal
          title="Add image"
          types={["library", "upload", "unsplash"]}
          onCancel={() => setIsLibraryOpen(false)}
          onConfirm={file => {
            const preview_url = getFilePreviewUrl(file);
            setPreviewImage(preview_url);
            onUpdateImage(file);
            setIsLibraryOpen(false);
          }}
          hideDescription
          mobileFullScreen
          hideUrl
        />
      ) : null}

      {!newLibrary ? <LibraryPopup
        label="Add to storyboard"
        open={isLibraryOpen}
        allowedFileTypes={IMAGES_MIME_TYPES}
        onCancel={() => setIsLibraryOpen(false)}
        onSelect={files => {
          const file = files[0];
          const preview_url = getFilePreviewUrl(file);
          setPreviewImage(preview_url);
          onUpdateImage(file);
          setIsLibraryOpen(false);
        }}
        tabs={libraryTabs}
      /> : null}
    </div>
  );
};

export { ImsertGrid };
