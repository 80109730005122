import React from "react";
import { useDispatch } from "react-redux";

import { Buffer } from "buffer";

import Grid from "@material-ui/core/Grid";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import PageviewOutlinedIcon from "@material-ui/icons/PageviewOutlined";

import { Input } from "components/ui/Forms";
import { ActionButton } from "components/ui/Buttons";

import { useManageTripContext } from "feature/panel/Trips/_shared/ManageTripContext";

import { openPreviewInNewTab } from "utils/library";
import { copyToClipboard } from "utils";

import config from "config/app";
import { setNotification } from "store/app/actions";
import { copySuccess } from "constants/notifications";

import { TRIP_WIZARD_CONTENT } from "constants/content";
import { useMediaQuery } from "@material-ui/core";

const InstantView = () => {
  const desktop = useMediaQuery(theme => {
    return theme.breakpoints.up("lg");
  });

  const dispatch = useDispatch();
  const { userId, passcode } = useManageTripContext();

  const webURL = `${config.webVamoos[config.webVamoosEnv]}/sample-trip`;
  const tripHashInBase64 = Buffer.from(`${userId}-${passcode}`).toString("base64");
  const url = `${webURL}/${tripHashInBase64}`;

  const copyUrlToClipboard = urlAddress => {
    copyToClipboard(urlAddress);
    dispatch(
      setNotification({
        type: "success",
        message: copySuccess(TRIP_WIZARD_CONTENT.general.instantViewLink),
      }),
    );
  };

  return (
    <Grid container spacing={10} wrap="nowrap" direction={desktop ? "row" : "column"}>
      <Grid item xs={12} sm={12} md={12}>
        <Input name="intant-view" value={url} disabled size="large" />
      </Grid>
      <Grid container item direction="row" wrap="nowrap" spacing={10} xs sm md>
        <Grid item>
          <ActionButton onClick={() => copyUrlToClipboard(url)} size="large">
            <FileCopyOutlinedIcon />
            <span>Copy</span>
          </ActionButton>
        </Grid>
        <Grid item>
          <ActionButton onClick={() => openPreviewInNewTab(url)} size="large">
            <PageviewOutlinedIcon />
            <span>Preview</span>
          </ActionButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export { InstantView };
