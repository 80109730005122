import { getMediaPx } from "helpers";
import styled from "styled-components";

export const RowButtons = styled.div`
  display: flex;
  gap: 15px;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    flex-direction: column;
  }
`;
