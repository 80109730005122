import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import { getFilePreviewUrl } from "utils/library";
import { useRefreshedFileUrl } from "hooks/useRefreshedFileUrl";

const BgArea = styled.div`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.grey40};

  ${({ image }) =>
    image &&
    `
      background-image: url("${image}");
      background-position: center;
      background-size: cover;
    `};

  :after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`;

const StyledImageOutlinedIcon = styled(ImageOutlinedIcon)`
  && {
    font-size: ${({ theme }) => theme.setSpacing(14)}px;
    color: ${({ theme }) => theme.colors.grey10};
  }
`;

const BackgroundPreview = ({ image }) => {
  const src = useRefreshedFileUrl(getFilePreviewUrl(image));
  return <BgArea image={src}>{!src && <StyledImageOutlinedIcon />}</BgArea>;
};

BackgroundPreview.defaultProps = {
  image: {},
};

BackgroundPreview.propTypes = {
  image: PropTypes.shape({
    https_url: PropTypes.string,
    web_url: PropTypes.string,
    preview_url: PropTypes.string,
  }),
};

export { BackgroundPreview, BgArea, StyledImageOutlinedIcon };
