import React from "react";
import moment from "moment";
import TableAction from "components/_new/Table/Action";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import config from "../../../../../../config/app";
import { DeleteAction, EditAction } from "../../../../../../components/ui/Tables";
import Switch from "../../../../../../components/_new/Switch";
import { TextLimiter } from "../../../../../../components/ui/TextLimiter";

const defaultNotificationTime = "13:00";
export const periods = [
  { value: "start", label: "Start day" },
  { value: "end", label: "End day" },
  { value: "days_before_start", label: "Days before start" },
  { value: "days_after_start", label: "Days after start" },
  { value: "days_before_end", label: "Days before end" },
  { value: "days_after_end", label: "Days after end" },
];

const START_DAY_INDEX = 0;
const END_DAY_INDEX = 1;
const DAYS_BEFORE_START_INDEX = 2;
const DAYS_AFTER_START_INDEX = 3;
const DAYS_BEFORE_END_INDEX = 4;
const DAYS_AFTER_END_INDEX = 5;

export const noneItem = { value: "manually", label: "Specify manually" };

export const calculateStartingPointValue = (delivery_at_relative_to, delivery_at_days) => {
  if (delivery_at_relative_to === "start" && delivery_at_days === 0) {
    return periods[START_DAY_INDEX].value;
  }

  if (delivery_at_relative_to === "end" && delivery_at_days === 0) {
    return periods[END_DAY_INDEX].value;
  }

  if (delivery_at_days > 0) {
    if (delivery_at_relative_to === "end") {
      return periods[DAYS_AFTER_END_INDEX].value;
    }

    return periods[DAYS_AFTER_START_INDEX].value;
  }

  if (delivery_at_relative_to === "end") {
    return periods[DAYS_BEFORE_END_INDEX].value;
  }

  return periods[DAYS_BEFORE_START_INDEX].value;
};

export const calculateStartingPointChange = (currentNotification, value) => {
  const { delivery_at_days, delivery_at_relative_to, delivery_at, ...rest } = currentNotification || {};

  const deliveryDays = (() => {
    if (value === periods[START_DAY_INDEX].value || value === periods[END_DAY_INDEX].value) {
      return 0;
    }

    const isBeforeDate = value === periods[DAYS_BEFORE_START_INDEX].value || value === periods[DAYS_BEFORE_END_INDEX].value;

    if (!delivery_at_days) {
      return isBeforeDate ? -1 : 1;
    }

    return isBeforeDate ? -delivery_at_days : Math.abs(delivery_at_days);
  })();

  const deliveryAtStartingPoint = /end/i.test(value) ? "end" : "start";

  return {
    ...rest,
    delivery_at_days: deliveryDays,
    delivery_at_relative_to: deliveryAtStartingPoint,
  };
};

export const getDefaultNotificationProperties = (startDate, isTemplate) => {
  return {
    timed: {
      start_at: defaultNotificationTime,
      delivery_at_relative_to: "start",
      delivery_at_days: 0,
      ...(!isTemplate ? { is_active: true } : {}),
    },
    gps: {
      start_at: startDate,
      end_at: moment(startDate)
        .add(1, "day")
        .toISOString(),
      ...(!isTemplate ? { is_active: true } : {}),
    },
  };
};

export const calculateNotificationDate = ({ delivery_at_relative_to, delivery_at_days, departure_date, return_date }) => {
  const deliveryCountStart = delivery_at_relative_to === "start" ? departure_date : return_date;
  return moment(deliveryCountStart)
    .add(delivery_at_days, "day")
    .format(config.dateFormat);
};

export const getNotificationEmptyFields = notification => {
  const requiredFields =
    notification.type === "timed"
      ? ["delivery_at_days", "delivery_at_relative_to", "start_at", "content"]
      : ["content", "start_at", "end_at", ...(notification.location || notification.location_internal_id ? [] : ["latitude", "longitude"])];

  return requiredFields.filter(key => !(key in notification));
};

export const getNotificationDate = ({
  delivery_at_relative_to,
  delivery_at_days,
  is_active,
  departure_date,
  return_date,
  itineraryType,
}) => {
  if (!delivery_at_relative_to) return "";

  const deliveryAtDays = delivery_at_days === 0 ? "" : Math.abs(delivery_at_days);
  const startingPointValue = calculateStartingPointValue(delivery_at_relative_to, delivery_at_days);
  const period = periods.find(period => period.value === startingPointValue).label;
  const notificationDate = calculateNotificationDate({
    delivery_at_relative_to,
    delivery_at_days,
    departure_date,
    return_date,
  });
  return `${deliveryAtDays} ${period} ${is_active && itineraryType === "trip" ? `(${notificationDate})` : ""}`;
};

export const getNotificationDateTimeFormatted = (value, format) => {
  return value
    ? typeof value === "string" && format !== config.momentLocaleInputDateFormat
      ? value
      : moment(value)
          .locale(navigator.language || navigator.languages[0])
          .format(format)
    : "";
};

export const getLocation = ({ location_internal_id, latitude, longitude, locations }) => {
  return location_internal_id
    ? locations.find(loc => loc.id === location_internal_id).name
    : latitude && latitude
    ? `lat: ${latitude}, lng: ${longitude}`
    : "";
};

export const generateColumns = (
  type,
  { handleSwitchAction, handleDeleteAction, handleEditAction, departure_date, return_date, locations, displayMode, itineraryType, disabledActionButtons },
) => {
  const commonColumnsStart = [
    {
      id: "source",
      label: "Source",
      align: "left",
      renderCell: ({ template_id }) => {
        return template_id ? `${template_id}` : `(${itineraryType.charAt(0).toUpperCase() + itineraryType.slice(1)})`;
      },
    },
    {
      id: "content",
      label: "Notification text",
      minWidth: 100,
      align: "left",
      renderCell: item => {
        const isModified = item.hasOwnProperty("template_id") && item.template_id !== null && item.use_global === false;
        return <TextLimiter maxChar={45} text={item.content} isModified={isModified} />;
      },
    },
    {
      id: "url",
      label: "Notification URL",
      minWidth: 100,
      align: "left",
      renderCell: ({ url }) => <TextLimiter maxChar={25} text={url} />,
    },
  ];

  const typeSpecificColumns =
    type === "timed"
      ? [
          {
            id: "notification_date",
            label: "Notification date",
            minWidth: 100,
            align: "left",
            renderCell: item => getNotificationDate({ ...item, departure_date, return_date, itineraryType }),
          },
          {
            id: "start_at",
            label: "Device local time",
            minWidth: 100,
            align: "left",
            renderCell: ({ start_at }) => getNotificationDateTimeFormatted(start_at, config.timeFormat),
          },
        ]
      : [
          {
            id: "start_at",
            label: "Active from",
            minWidth: 100,
            align: "left",
            renderCell: ({ start_at }) => getNotificationDateTimeFormatted(start_at, config.momentLocaleInputDateFormat),
          },
          {
            id: "end_at",
            label: "Active to",
            minWidth: 100,
            align: "left",
            renderCell: ({ end_at }) => getNotificationDateTimeFormatted(end_at, config.momentLocaleInputDateFormat),
          },
          {
            id: "location",
            label: "Location",
            minWidth: 100,
            align: "left",
            renderCell: item => getLocation({ ...item, locations }),
          },
        ];

  const commonColumnsEnd = [
    {
      id: "switch",
      label: "",
      align: "right",
      isAction: true,
      maxWidth: 72,
      renderCell: data => {
        if (!data) return null;

        const emptyFields = getNotificationEmptyFields(data);
        const hasEmptyFields = emptyFields && emptyFields?.length > 0;
        return (
          <div style={{ display: "flex" }}>
            <TableAction
              element={
                <Switch
                  styleWrap={{ opacity: 1 }}
                  noBackground
                  onChange={() => {
                    if (hasEmptyFields) {
                      data.is_active = !data.is_active;
                      handleEditAction(data, displayMode === "trip" && data?.id === data?.template_id);
                    } else {
                      handleSwitchAction(data);
                    }
                  }}
                  checked={data?.is_active}
                  disabled={disabledActionButtons}
                />
              }
            />
            <TableAction
              element={<EditOutlinedIcon />}
              onClick={e => {
                handleEditAction(data, data?.id === data?.template_id);
                e.stopPropagation();
              }}
              disabled={disabledActionButtons}
            />
            <TableAction
              element={<DeleteOutlineOutlinedIcon />}
              onClick={e => {
                e.stopPropagation();
                handleDeleteAction(data);
              }}
              tooltip={data?.id === data?.template_id ? "This notification is a shared notification and cannot be deleted." : ""}
              disabled={data?.id === data?.template_id || disabledActionButtons}
            />
          </div>
        );
      },
    },
  ];
  return [...commonColumnsStart, ...typeSpecificColumns, ...commonColumnsEnd];
};

export const populateNullableNotifications = (sharedNotifications, notifications, language) => {
  return notifications.map(notification => {
    if (!notification.use_global) return notification;

    const sharedNotification = sharedNotifications?.find(sn => sn.id === notification.template_id && sn.type === notification.type);
    if (!sharedNotification) return notification;

    const fieldsToOverride =
      notification.type === "timed"
        ? ["content", "url", "delivery_at_days", "delivery_at_relative_to", "start_at"]
        : ["content", "url", "start_at", "end_at", "latitude", "longitude"];

    const updatedNotification = { ...notification };
    fieldsToOverride.forEach(field => {
      updatedNotification[field] = sharedNotification.localisation?.[language]?.[field] ?? sharedNotification[field];
    });
    return updatedNotification;
  });
};

export const compareNotifications = (notification, sharedNotification) => {
  let notificationsEqualResult = true;
  const timedKeysToCompare = ["type", "content", "url", "delivery_at_relative_to", "delivery_at_days", "start_at", "template_id"];
  const gpsKeysToCompare = ["type", "content", "url", "start_at", "end_at", "latitude", "longitude", "template_id"];
  const keysToCompare = notification.type === "gps" ? gpsKeysToCompare : timedKeysToCompare;

  for (const key of keysToCompare) {
    if (notification.type === "gps" && (key === "start_at" || key === "end_at")) {
      const cleanedDate = moment(sharedNotification[key]);
      const tripDate = moment(notification[key]);

      if (!cleanedDate.isSame(tripDate, "day")) {
        notificationsEqualResult = false;
        break;
      }
    } else {
      if (key === "url" && !notification[key]) {
        if (!!sharedNotification[key] !== !!notification[key]) {
          notificationsEqualResult = false;
          break;
        }
      } else {
        if (sharedNotification[key] !== notification[key]) {
          notificationsEqualResult = false;
          break;
        }
      }
    }
  }

  return notificationsEqualResult;
};
