import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { TableContent } from "components/ui/Tables/TableContent";

import { Spinner } from "../Spinner/Spinner";

const StyledPaper = styled.div`
  && {
    border: ${({ withBorder, theme }) => (withBorder ? `1px solid ${theme.colors.grey10}` : null)};
    box-shadow: ${({ noPaper }) =>
      !noPaper ? "0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 4px 5px 0 rgba(0, 0, 0, 0.06), 0 2px 4px 0 rgba(0, 0, 0, 0.07)" : null};
    border-radius: ${({ theme }) => theme.borderRadius}px;
    background: ${({ theme }) => theme.colors.white};
  }
`;

const TableDataLoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  background: #ffffffd4;
  z-index: 1;
  padding-top: ${({ spinnerPosition }) => spinnerPosition}px;
`;

const TableDataLoader = ({ spinnerPosition }) => {
  return (
    <TableDataLoaderWrapper spinnerPosition={spinnerPosition}>
      <Spinner size={42} cv="brand" />
    </TableDataLoaderWrapper>
  );
};

TableDataLoader.propTypes = {
  spinnerPosition: PropTypes.number.isRequired,
};

const Table = ({ withBorder, noPaper, elevation, children, isDataLoading, setTableWidth, ...rest }) => {
  const [spinnerPosition, setSpinnerPosition] = useState(0);
  const tableRef = useRef(null);

  const loader = isDataLoading ? <TableDataLoader spinnerPosition={spinnerPosition} /> : null;

  useEffect(() => {
    const visiblePartOfTable = tableRef.current?.getBoundingClientRect();
    if (setTableWidth) setTableWidth(visiblePartOfTable.width);
    if (visiblePartOfTable?.top >= 0) {
      if (visiblePartOfTable?.height + visiblePartOfTable?.top <= window.innerHeight) {
        const expectedPosition = visiblePartOfTable.height / 2;

        setSpinnerPosition(expectedPosition - 26);
      } else {
        const tableHeight = visiblePartOfTable?.height - visiblePartOfTable?.top;
        const viewPortHeight = window.innerHeight;
        const expectedPosition = visiblePartOfTable?.top - 256 + (tableHeight < viewPortHeight ? tableHeight / 2 : viewPortHeight / 2);

        setSpinnerPosition(expectedPosition - 26);
      }
    } else {
      const tableHeight = visiblePartOfTable?.height + visiblePartOfTable?.top;
      const viewPortHeight = window.innerHeight;
      const expectedPosition = -visiblePartOfTable?.top + (tableHeight < viewPortHeight ? tableHeight / 2 : viewPortHeight / 2);

      setSpinnerPosition(expectedPosition);
    }
  }, [isDataLoading]);

  return (
    <StyledPaper withBorder={withBorder} noPaper={noPaper} elevation={elevation} ref={tableRef}>
      {loader}
      <TableContent {...rest}>{children}</TableContent>
    </StyledPaper>
  );
};

Table.defaultProps = {
  withBorder: false,
  noPaper: false,
  elevation: 1,
  isDataLoading: false,
};

Table.propTypes = {
  withBorder: PropTypes.bool,
  noPaper: PropTypes.bool,
  isDataLoading: PropTypes.bool,
  elevation: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.array, PropTypes.element]).isRequired,
};

export { Table, StyledPaper };
