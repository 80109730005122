import React, { useState } from "react";
import { useEffectDependenciesOnly } from "hooks/useEffectDependenciesOnly";
import { Grid } from "./style";
import List from "./List";
import MessageArea from "./MessageArea";
import Settings from "../settings";

const Main = ({ chats, messages, watch, control, disabled, hasAdditionalMenu }) => {
  const [currentChat, setCurrentChat] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);

  const toggleOpen = chat => {
    setOpenSettings(false);
    setCurrentChat(chat?.conversation ? chat : null);
  };
  const toggleSettings = () => {
    setCurrentChat(null);
    setOpenSettings(!openSettings);
  };

  useEffectDependenciesOnly(() => {
    if (currentChat) setCurrentChat(chats.find(item => item.conversation === currentChat.conversation));
  }, [chats]);

  return (
    <Grid>
      <List
        onClick={toggleOpen}
        currentChat={currentChat}
        chats={chats}
        messages={messages}
        onSettingsOpen={toggleSettings}
        openSettings={openSettings}
        hasAdditionalMenu={hasAdditionalMenu}
      />
      {openSettings && <Settings control={control} watch={watch} onClose={toggleSettings} disabled={disabled} />}
      {currentChat && (
        <MessageArea
          onBack={toggleOpen}
          currentChat={currentChat}
          messages={messages.filter(item => item.conversation === currentChat.conversation)}
          watch={watch}
          disabled={disabled}
          hasAdditionalMenu={hasAdditionalMenu}
        />
      )}
    </Grid>
  );
};

export default Main;
