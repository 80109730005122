import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import styles from "config/theme";
import { TitleText4 } from "components/ui/Typography/Typography";

const MessageContainer = styled.div`
  width: 50%;
  padding: ${({ theme }) => theme.setSpacing(3)}px ${({ theme }) => theme.setSpacing(10)}px;
  ${props => (props.primary ? "margin-left: 50%" : "margin-right: 50%")};
`;

const BubbleText = styled.p`
  width: 100%;
  background-color: ${props => (props.primary ? styles.colors.withOpacity(styles.colors.brand, 0.3) : styles.colors.grey20)};
  border-radius: ${({ theme }) => theme.setSpacing(2)}px;
  padding: ${({ theme }) => theme.setSpacing(3)}px;
  margin: 8px 0;
`;

const LabelDescription = styled(TitleText4)`
  color: ${styles.colors.grey50};
`;

const MessageBubble = ({ primary, label, text }) => {
  return (
    <MessageContainer primary={primary}>
      <LabelDescription>{label}</LabelDescription>
      <BubbleText primary={primary}>{text}</BubbleText>
    </MessageContainer>
  );
};

MessageBubble.propTypes = {
  primary: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export { MessageBubble, LabelDescription, BubbleText };
