import React, { useContext } from "react";
import PropTypes from "prop-types";

import { BodyText } from "components/ui/Typography/Typography";
import { NotificationForm } from "feature/panel/_shared/Notifications/GpsNotificationForm/NotificationForm";
import { NotificationWrapper, DataDisplayWrapper } from "feature/panel/_shared/Notifications/styledComponents";
import { Switch } from "components/ui/Forms";

const NotificationItem = ({ item, index, context, disabled, allNotifications }) => {
  const { notifications, setValueFor } = useContext(context);
  const { template_id, id, is_active } = item;

  const handleActiveToggle = () => {
    const newList = notifications.map(notification => {
      if (notification.id && notification.id === id) {
        return { ...notification, is_active: !is_active };
      }
      if (notification.template_id && notification.template_id === template_id) {
        return { ...notification, is_active: !is_active };
      }
      return notification;
    });
    setValueFor("notifications", newList);
  };

  return (
    <NotificationWrapper container spacing={10}>
      <DataDisplayWrapper>
        <BodyText>{`Template ${template_id || "none "}`}</BodyText>
        <Switch disabled={disabled} checked={is_active} onChange={handleActiveToggle} />
      </DataDisplayWrapper>
      <NotificationForm disabled={disabled} allNotifications={allNotifications} item={item} index={index} context={context} />
    </NotificationWrapper>
  );
};

NotificationItem.defaultProps = {
  disabled: false,
};

NotificationItem.propTypes = {
  item: PropTypes.shape().isRequired,
  context: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
};

export { NotificationItem };
