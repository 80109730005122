// @ts-check

import {
  FORCE_UPDATE_URL,
  ITINERARY_REMOVE_USER_URL,
  ITINERARY_URL,
  ITINERARY_USERS_URL,
  PASSCODE_AVAILABILITY_CHECK_URL,
} from "constants/api";
import { HttpClient } from "services/application/httpClient/httpClient";
import { setUrl, setUrlParams } from "utils/url";

class TripRepository {

  constructor() {
    /**
     * @type {import("services/application/httpClient/httpClient").HttpClient}
     */
    this.httpClient = HttpClient;
  }

  async getTrip(operator, code) {
    const url = setUrl(ITINERARY_URL + `?_=${Date.now()}`, { operator, code }, true);
    const { data } = await this.httpClient.get(url);
    return data;
  }

  async saveTrip(userId, code, data, operator) {
    const url = setUrl(ITINERARY_URL + `?_=${Date.now()}`, { operator: userId, code }, true);
    const headers = operator ? { "X-Operator-Code": operator } : {};

    const response = await this.httpClient.post(url, data, { headers });
    return response;
  }

  /**
   *
   * @param {string} operator
   * @param {string} code
   * @param {any} params
   * @returns {Promise<any>}
   */
  async getTripUsers(operator, code, { rowsPerPage, page, sortBy = "", order = "desc" }) {
    const requestParams = {
      count: rowsPerPage,
      page,
      order_by: sortBy?.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`),
      order,
    };

    const url = setUrlParams(setUrl(ITINERARY_USERS_URL, { operator, code }, true), requestParams);
    const { data } = await this.httpClient.get(url);
    return data;
  }

  /**
   *
   * @param {string} email
   * @param {"read"|"write"} permission
   * @param {string} operator
   * @param {string} code
   * @returns {Promise<any>}
   */
  async addUserToTrip(email, permission, operator, code) {
    const url = setUrl(ITINERARY_USERS_URL, { operator, code }, true);
    const { data } = await this.httpClient.put(url, {
      email,
      permission,
    });
    return data;
  }

  /**
   *
   * @param {number} userId
   * @param {string} operator
   * @param {string} code
   * @returns {Promise<any>}
   */
  async removeUserFromTrip(userId, operator, code) {
    const url = setUrl(ITINERARY_REMOVE_USER_URL, { operator, code, userId }, true);
    const { data } = await this.httpClient.delete(url);
    return data;
  }

  async checkIfPassocdeIsTaken(userId, passcode) {
    const url = setUrl(
      PASSCODE_AVAILABILITY_CHECK_URL,
      {
        operator_code: userId,
        reference_code: passcode,
      },
      true,
    );

    const headers = { "X-Operator-Code": userId };
    const { data } = await this.httpClient.get(url, { headers });

    return data;
  }

  async sendUpdate(userId, passcode, notification_text) {
    const url = setUrl(
      FORCE_UPDATE_URL,
      {
        operator_code: userId,
        reference_code: passcode,
        resource_type: "itinerary",
      },
      true,
    );

    const { data } = await this.httpClient.post(url, { notification_text });

    return data;
  }
}

export { TripRepository };
