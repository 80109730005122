import React, { useContext, useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Redeem } from "@material-ui/icons";
import styled from "styled-components";
import uuidv4 from "uuid";

import { SortableTable } from "components/ui/SortableTable/SortableTable";
import {
  stayWizardAddVouchersItem,
  stayWizardEditVouchersItem,
  stayWizardRemoveVouchersItem,
  stayWizardSortVouchers,
  stayWizardToggleStatusVouchersItem,
} from "store/stayWizard/actions";
import { BodyText2 } from "components/ui/Typography/Typography";
import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { DeleteAction, EditAction, SwitchAction } from "components/ui/Tables";
import { StickyWrapper } from "components/ui/Forms/FormSection";
import { Message, NoResultsMessage } from "components/ui/Messages";
import { SecondaryButton } from "components/ui/Buttons";

import { initDirectory } from "utils/directories";
import {
  CREATE_CONTENT_LABELS,
  EMPTY_LIST_MESSAGES_BASE,
  EMPTY_VARIANT_SECTION,
  GLOBAL_CONTENT,
  NAMES_OF_RESOURCES_LISTS,
  STAY_WIZARD_CONTENT,
  TABLE_ACTIONS_TITLES,
  VOUCHERS_INFO,
} from "constants/content";
import { useSticky } from "hooks/useSticky";
import { useListOfLanguages } from "hooks/useListOfLanguages";

import { colors } from "config/theme/colors";

import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { LanguageContext } from "components/_shared/LanguageSelector/LanguageContext";

import { AnchorLikeButton, ListRowSquareField } from "../styledComponents";

import { AccessRestrictionSection } from "../DailyDirectoryVoucherShared/AccessRestrictionSection";
import { voucherReducer } from "./VoucherReducer";
import { VoucherDialog } from "./VoucherDialog";

export const HeaderContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
`;

export const ActionsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.setSpacing(10)}px;
`;

export const VouchersTitle = styled.div`
  font-size: ${({ theme }) => theme.fonts.fontSize.h5}px;
  color: ${({ theme }) => theme.colors.black};
`;
export const VouchersTableHeader = styled(Grid)`
  padding: 0 150px 0 76px;
`;

const Vouchers = () => {
  const stickyPosition = "top";
  const stickyOffset = 128;
  const allLanguages = useListOfLanguages();

  const { currentLanguage } = useContext(LanguageContext);

  const defaultLanguageName = useSelector(({ stayWizard, operator }) => {
    if (stayWizard.defaultVariant.language) {
      return allLanguages.find(lang => lang.code === stayWizard.defaultVariant.language)?.name;
    }

    return allLanguages.find(lang => lang.code === operator.currentOperator.defaultLanguageCode)?.name;
  });

  const permissionsService = usePermissionsService();
  const dispatch = useDispatch();
  const headerRef = useRef();
  const isSticky = useSticky(headerRef, stickyPosition, stickyOffset);

  const [creationModalOpen, setCreationModalOpen] = useState(false);
  const [activityType, setActivityType] = useState(null);
  const [deleteModal, setDeleteModal] = useState({ open: false, body: {} });
  const [currentDirectoryId, setCurrentDirectoryId] = useState(null);
  const [voucherForm, dispatchLocal] = useReducer(voucherReducer, initDirectory("voucher", true));

  const vouchers = useSelector(({ stayWizard }) => stayWizard.form.vouchers.items || []);

  const isWiped = useSelector(({ stayWizard }) => stayWizard.form.isWiped);
  const vamoosId = useSelector(({ stayWizard }) => stayWizard.form.vamoosId);
  const editMode = useSelector(({ stayWizard }) => stayWizard.form.vamoosId !== null);

  const canRead = permissionsService.can(PERMISSIONS.actions.read, PERMISSIONS.sections.vamoosList, vamoosId);
  const canCreate = permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.vamoosList, vamoosId);
  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoosId);

  const isStayDefaultLanguage = useSelector(({ stayWizard }) =>
    stayWizard.defaultVariant.language ? stayWizard.defaultVariant.language === currentLanguage.code : true,
  );

  const openEditModal = (voucher = null) => {
    setCreationModalOpen(true);
    setActivityType(voucher === null ? "CREATE" : "EDIT");

    dispatchLocal({
      type: "setAllValues",
      payload: voucher || initDirectory("voucher", false),
    });
  };

  const handleChangeOrder = ({ newIndex, oldIndex }) => {
    dispatch(stayWizardSortVouchers({ newIndex, oldIndex }));
  };

  const handleModalConfirm = () => {
    if (voucherForm.id) {
      dispatch(stayWizardEditVouchersItem({ editedVoucher: voucherForm }));
    } else if (currentDirectoryId) {
      dispatch(
        stayWizardAddVouchersItem({
          newVoucher: {
            ...voucherForm,
            id: uuidv4(),
            parent_id: currentDirectoryId,
          },
        }),
      );
    } else {
      dispatch(
        stayWizardAddVouchersItem({
          newVoucher: {
            ...voucherForm,
            id: uuidv4(),
          },
        }),
      );
    }
    setCreationModalOpen(false);
    setActivityType(null);
  };

  const handleModalCancel = () => {
    setCreationModalOpen(false);
    setActivityType(null);
  };

  const openDeleteModal = directory => {
    setDeleteModal({ body: directory, open: true });
  };

  const handleDeleteConfirm = () => {
    dispatch(stayWizardRemoveVouchersItem({ id: deleteModal.body.id }));
    setDeleteModal({ body: null, open: false });
  };

  const handleDeleteCancel = () => {
    setDeleteModal({ body: null, open: false });
  };

  const onDirectoryClick = directory => {
    setCurrentDirectoryId(directory.id);
  };
  const toggleVoucherStatus = voucherId => {
    dispatch(stayWizardToggleStatusVouchersItem({ id: voucherId }));
  };

  const deleteTitle = deleteModal?.body?.name ? STAY_WIZARD_CONTENT.vouchers.deleteModalTitle(deleteModal?.body?.name) : "";

  const handleVoucherSet = (fieldName, value) => {
    dispatchLocal({
      type: "setValueFor",
      fieldName,
      value,
    });
  };

  const VoucherListRow = ({ item }) => {
    return (
      <>
        <ListRowSquareField>
          <Redeem />
        </ListRowSquareField>
        <AnchorLikeButton
          onClick={() => {
            if (canRead) {
              openEditModal(item, true);
            } else {
              onDirectoryClick(item);
            }
          }}
          disabled={!item.is_list && !canRead}
        >
          <BodyText2 cv={colors.grey100}>{item.name}</BodyText2>
        </AnchorLikeButton>
      </>
    );
  };

  const renderHeader = () => (
    <HeaderContentWrapper>
      <VouchersTitle>{STAY_WIZARD_CONTENT.vouchers.vouchers}</VouchersTitle>
      {!isWiped && (!editMode || canEdit) && (
        <SecondaryButton onClick={() => openEditModal(null, true)} id="add-directory-button">
          <AddIcon />
          {GLOBAL_CONTENT.add}
        </SecondaryButton>
      )}
    </HeaderContentWrapper>
  );

  const actions = ({ item }) => (
    <>
      {!isWiped && (!editMode || canEdit) && (
        <SwitchAction
          clickAction={() => toggleVoucherStatus(item.id)}
          checked={item.is_enabled}
          title={
            item.is_enabled
              ? TABLE_ACTIONS_TITLES.deactivateResource(TABLE_ACTIONS_TITLES.resourceTypes.voucher)
              : TABLE_ACTIONS_TITLES.activateResource(TABLE_ACTIONS_TITLES.resourceTypes.voucher)
          }
        />
      )}
      {!isWiped && (editMode ? canEdit : true) && (
        <EditAction
          clickAction={() => openEditModal(item)}
          tooltip={TABLE_ACTIONS_TITLES.editResource(TABLE_ACTIONS_TITLES.resourceTypes.voucher)}
        />
      )}
      {!isWiped && (editMode ? canEdit : true) && (
        <DeleteAction
          clickAction={() => openDeleteModal(item)}
          tooltip={TABLE_ACTIONS_TITLES.deleteResource(TABLE_ACTIONS_TITLES.resourceTypes.voucher)}
        />
      )}
    </>
  );

  return (
    <>
      <AccessRestrictionSection resource="vouchers" />
      <Grid container justifyContent="center">
        <Grid item xs={12} md={10}>
          <ActionsHeader ref={headerRef}>
            {isSticky && (
              <StickyWrapper stickyVariant="normal" sticky={isSticky} offset={stickyOffset} position={stickyPosition}>
                <Grid container justifyContent="center">
                  <Grid xs={12} md={10} item>
                    {renderHeader()}
                  </Grid>
                </Grid>
              </StickyWrapper>
            )}
            {renderHeader()}
          </ActionsHeader>
          {vouchers.length > 0 ? (
            <>
              <SortableTable
                disabled={!canEdit}
                onSortEnd={handleChangeOrder}
                rowListComponent={VoucherListRow}
                headerComponent={VouchersTableHeader}
                list={vouchers}
                actions={actions}
              />
              <Message type="info" margin={{ top: "24px" }} text={VOUCHERS_INFO} />
            </>
          ) : (
            (editMode ? canEdit : true) && (
              <>
                {!isStayDefaultLanguage && (
                  <NoResultsMessage height="100px">{EMPTY_VARIANT_SECTION(currentLanguage.name, defaultLanguageName)}</NoResultsMessage>
                )}
                <NoResultsMessage height="100px">
                  {EMPTY_LIST_MESSAGES_BASE(CREATE_CONTENT_LABELS.add, NAMES_OF_RESOURCES_LISTS.voucher)}
                </NoResultsMessage>
              </>
            )
          )}
          <VoucherDialog
            open={creationModalOpen}
            directory={voucherForm}
            onCancel={handleModalCancel}
            onConfirm={handleModalConfirm}
            setDirectoryValue={handleVoucherSet}
            currentDirectoriesList={vouchers}
            vouchersList={vouchers}
            currentDirectoryId={currentDirectoryId}
            canEdit={(activityType === "CREATE" && canCreate) || (activityType === "EDIT" && canEdit)}
          />
          {deleteModal.open && (
            <ConfirmationModal onCancel={handleDeleteCancel} open={deleteModal.open} onConfirm={handleDeleteConfirm} title={deleteTitle} />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export { Vouchers };
