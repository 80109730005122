import React from "react";
import uuidv4 from "uuid";
import { feature } from "@ideditor/country-coder";
import { Wrap } from "../Common/style";
import BottomRow from "../components/BottomRow";

const RightClickWindow = ({ position, addButtonText, onAddMarker, disableAddButton, item }) => {
  const { lat, lng } = position;

  const pointGeoJSON = { type: "Feature", geometry: { type: "Point", coordinates: [lng, lat] } };

  const { properties: { nameEn: country } = { nameEn: null } } = feature(pointGeoJSON) || {};

  const handleClick = () => {
    const newMarker = {
      id: uuidv4(),
      coordinates: `${lat.toFixed(7)}, ${lng.toFixed(7)}`,
      country,
      latitude: lat,
      longitude: lng,
      on_weather: true,
      on_maps: true,
      position: item.position,
    };

    if (onAddMarker) onAddMarker(newMarker);
  };

  return (
    <div>
      <Wrap style={{ marginBottom: 10 }}>
        <strong>Coordinates: </strong>
        <span>
          {lat?.toFixed(7)},{lng?.toFixed(7)}
        </span>
      </Wrap>
      <BottomRow longitude={lng} latitude={lat} addButtonText={addButtonText} disableAddButton={disableAddButton} onClick={handleClick} />
    </div>
  );
};

export default RightClickWindow;
