import styled from "styled-components";
import { font, fontSize } from "config/theme/fonts";

export const ListRowSquareField = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  min-width: 72px;
  height: 72px;
`;

export const AnchorLikeButton = styled.button`
  background: none;
  border: none;
  font-size: ${fontSize.body2}px;
  font-family: ${font};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};

  :hover {
    text-decoration: ${({ disabled }) => (disabled ? "none" : "underline")};
  }
  :focus {
    outline: 0;
  }
`;