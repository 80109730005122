import uuidv4 from "uuid";

export const splitTravelPeopleByEmail = travelPeopleToDisplay => {
  const newTravelPeopleToDisplaySplitByEmail = [];
  let emailAfterCommaIsEmpty;
  travelPeopleToDisplay.forEach(t => {
    if (t.email?.includes(",")) {
      const emails = t.email.split(/\s*,\s*/);
      for (let i = 0; i < emails.length; i++) {
         if (emails[i].match(/\w/)) { // to exclude empty emails
          const unique = uuidv4();
          const nestedItem = t?.nested ? t.nested[i] : null;
          const initialEmailIsEmpty = emails[0] === ""; // if user has entered a comma at the beginning and type any characters after the comma
          newTravelPeopleToDisplaySplitByEmail.push({
            id: (i === 0 || initialEmailIsEmpty) ? t.id : nestedItem?.id ?? `${t.id}-${unique}`,
            internal_id: (i === 0 || initialEmailIsEmpty) ? t.internal_id : nestedItem?.internal_id ?? `${t.internal_id}-${unique}`,
            name: t.name,
            email: emails[i].trim(),
            details: nestedItem?.details ?? null,
            is_active: nestedItem?.is_active ?? null,
            tag: nestedItem?.tag ?? null,
          });
         } else {
           emailAfterCommaIsEmpty = true;
         }
      }
      // if user has entered a comma at the beginning
      if (emails.every(str => str === '')) {
        newTravelPeopleToDisplaySplitByEmail.push({ ...t, email: undefined });
      }
    } else {
      newTravelPeopleToDisplaySplitByEmail.push(t);
    }
  });
  return { newTravelPeopleToDisplaySplitByEmail, emailAfterCommaIsEmpty };
};
