import React from "react";

import { ADMIN_USERS_PATH, ADMIN_OPERATORS_PATH, ADMIN_CONNECT_PATH } from "constants/routes";
import { SubMenu } from "components/templates/_shared/SubMenu/SubMenu";
import { ADMIN_MAIN_SUB_NAVIGATION_LABELS } from "constants/content";

const ManageAccountsSubMenu = () => {
  const menu = [
    { label: ADMIN_MAIN_SUB_NAVIGATION_LABELS.operators, url: ADMIN_OPERATORS_PATH },
    { label: ADMIN_MAIN_SUB_NAVIGATION_LABELS.users, url: ADMIN_USERS_PATH },
    { label: ADMIN_MAIN_SUB_NAVIGATION_LABELS.connect, url: ADMIN_CONNECT_PATH },
  ];

  return <SubMenu menu={menu} />;
};

export { ManageAccountsSubMenu };
