import * as actionTypes from "store/adminOperators/actionTypes";

import { reducer, setState, globalReducerActions } from "utils/store";

const adminOperatorsInitialState = {
  errors: {},
  actionType: null,
  inProgress: false,
  finished: false,
  listOfOperators: [],
  operatorsCount: 0,
  currentAdminOperatorCode: null,
};

const ReducerActions = globalReducerActions("adminOperators", adminOperatorsInitialState, {
  [actionTypes.GET_ADMIN_OPERATORS_START](state) {
    return setState(state, {
      actionType: actionTypes.GET_ADMIN_OPERATORS_START,
      inProgress: true,
    });
  },

  [actionTypes.GET_ADMIN_OPERATORS_SUCCESS](state, payload) {
    const { count, items } = payload;
    return setState(state, {
      actionType: actionTypes.GET_ADMIN_OPERATORS_SUCCESS,
      inProgress: false,
      finished: true,
      listOfOperators: items,
      operatorsCount: count,
    });
  },

  [actionTypes.GET_ADMIN_OPERATORS_FAIL](state, payload) {
    return setState(state, {
      actionType: actionTypes.GET_ADMIN_OPERATORS_FAIL,
      inProgress: false,
      finished: false,
      errors: payload,
    });
  },
  [actionTypes.OPERATOR_ACTIVE_STATUS_TOGGLE_START](state) {
    return setState(state, {
      actionType: actionTypes.OPERATOR_ACTIVE_STATUS_TOGGLE_SUCCESS,
      inProgress: true,
      finished: false,
    });
  },
  [actionTypes.OPERATOR_ACTIVE_STATUS_TOGGLE_SUCCESS](state, payload) {
    const { id, isActive } = payload;

    return setState(state, {
      actionType: actionTypes.OPERATOR_ACTIVE_STATUS_TOGGLE_SUCCESS,
      inProgress: false,
      finished: true,
      listOfOperators: state.listOfOperators.map(item => (item.id === id ? { ...item, isActive } : item)),
    });
  },
  [actionTypes.OPERATOR_ACTIVE_STATUS_TOGGLE_FAIL](state, payload) {
    return setState(state, {
      actionType: actionTypes.OPERATOR_ACTIVE_STATUS_TOGGLE_FAIL,
      inProgress: false,
      finished: false,
      errors: { ...payload },
    });
  },
  [actionTypes.SET_CURRENT_ADMIN_OPERATOR_CODE_SELECTED](state, payload) {
    return setState(state, {
      currentAdminOperatorCode: payload,
    });
  },
});

const adminOperatorsReducer = (state = adminOperatorsInitialState, action) => reducer(state, action, ReducerActions);

export { adminOperatorsReducer };
