import CloseIcon from "@mui/icons-material/Close";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import OutlinedButton from "components/_new/Buttons/OutlinedButton";
import Input from "components/ui/Inputs/TextInput";
import { ResponsiveHideFrom, ResponsiveShowFrom } from "feature/panel/Itinerary/responsive";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import MenuLink from "../../components/common/MenuLink";
import { MenuWrap, SearchWrap, Wrap } from "./style";

const VamoosListMenu = ({ searchValue, onSearch, type = "trip", device }) => {
  const navigate = useNavigate();
  const isMobile = device === "mobile";

  const { currentOperator, isFetchingCurrentOperator } = useSelector(state => state.operator);
  
  let showRefactor = (currentOperator?.meta?.enable_refactor && currentOperator?.meta?.show_refactor !== false) ?? process.env.REACT_APP_SHOW_REFACTORED_TRIPS === "true"; 
  if (process.env.REACT_APP_FORCE_NEW_TRIPS === "true") { showRefactor = true }

  const [isOpened, setIsOpened] = useState(false);
  const [showSearchInput, setShowSearchInput] = useState(false);

  const toggleOpen = () => {
    if (!searchValue) setIsOpened(!isOpened);
  };

  useEffect(() => {
    if (!searchValue) setIsOpened(false);
  }, [searchValue]);

  const renderSearchInput = () => (
    <Input
      label="Search"
      value={searchValue}
      onChange={onSearch}
      endAdornment={searchValue
        ? <CloseIcon style={{ cursor: "pointer" }} onClick={() => onSearch("")} />
        : <SearchOutlinedIcon onClick={() => {
          if (!searchValue && isMobile) setShowSearchInput(false);
        }}
        />}
      handleFocus={toggleOpen}
      onBlur={toggleOpen}
    />
  );

  return (
    <Wrap>
      <ResponsiveHideFrom size="md" style={{ textAlign: "right" }}>
        <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
          <SearchWrap isOpened={isOpened}>
            {renderSearchInput()}
          </SearchWrap>
        </div>
      </ResponsiveHideFrom>
      <MenuWrap>
        <MenuLink to="/panel/trips" text={isMobile ? "Trips" : "Vamoos trips"} />
        <MenuLink to="/panel/stays" text={isMobile ? "Stays" : "Vamoos stays"} />
      </MenuWrap>
      <ResponsiveShowFrom size="md">
        {showSearchInput
          ? renderSearchInput()
          : (
            <OutlinedButton
              iconOnlyMobile
              startIcon={<SearchOutlinedIcon />}
              onClick={() => setShowSearchInput(true)}
              type="grey"
              noBorder
            />
          )
        }
      </ResponsiveShowFrom>
      <ResponsiveHideFrom size="md" style={{ textAlign: "right" }}>
        <OutlinedButton disabled={isFetchingCurrentOperator} text={`+ Create ${type}`} type="solid" onClick={() => navigate(showRefactor ? `/panel/${type}/create/general` : `/panel/${type}s/create`)} />
      </ResponsiveHideFrom>
    </Wrap>
  );
};

export default VamoosListMenu;
