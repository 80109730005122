import React, { useState } from "react";
import { createPortal } from 'react-dom';
import AddIcon from "@material-ui/icons/Add";
import styled from "styled-components";
import PropTypes from "prop-types";
import { PrimaryButton } from "components/ui/Buttons";
import { ClickAwayListener, Paper } from "@material-ui/core";
import { BodyText2 } from "components/ui/Typography/Typography";
import { CREATE_CONTENT_LABELS } from "constants/content";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

const SelectWrapper = styled.div`
  position: relative;
`;

const ResultsWrapper = styled(Paper)`
  && {
    position: fixed;
    right: 24px;
    width: 165px;
    top: 120px;
    z-index: 10;
  }
`;

const OptionWrapper = styled.button`
  width: 100%;
  padding: ${({ theme }) => theme.setSpacing(3)}px;
  transition: background-color 0.3s ease;
  cursor: pointer;
  background: none;
  border: none;
  text-align: left;

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.colors.grey10};
    outline: none;
  }
`;

const notificationTypes = [
  { label: "Timed", value: "timed" },
  { label: "GPS", value: "gps" },
  { label: "Force update", value: "forced" },
];

const Actions = ({ onModalPick }) => {
  const permissionsService = usePermissionsService();
  const [addNotificationModalOptionsOpen, setAddNotificationModalOptionsOpen] = useState(false);

  const handleModalSelect = value => {
    setAddNotificationModalOptionsOpen(false);
    onModalPick(value);
  };

  const options = addNotificationModalOptionsOpen && (
    <ResultsWrapper>
      {notificationTypes.map(type => (
        <OptionWrapper key={type.value} onClick={() => handleModalSelect(type.value)}>
          <BodyText2>{type.label}</BodyText2>
        </OptionWrapper>
      ))}
    </ResultsWrapper>
  );

  return permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.operator, PERMISSIONS.resources.notifications) && (
    <ClickAwayListener onClickAway={() => setAddNotificationModalOptionsOpen(false)}>
      <SelectWrapper>
        <PrimaryButton onClick={() => setAddNotificationModalOptionsOpen(true)}>
          <AddIcon />
          <span>{CREATE_CONTENT_LABELS.notificationTemplate}</span>
        </PrimaryButton>
        {createPortal(options, document.getElementById('root'))}
      </SelectWrapper>
    </ClickAwayListener>
  );
};

Actions.propTypes = {
  onModalPick: PropTypes.func.isRequired,
};

export { Actions, OptionWrapper };
