import React, { useEffect, useRef, useState } from "react";
import * as moment from "moment";
import { useNavigate } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import bg from "assets/images/sign_up_bg.jpg";
import { useTitle } from "hooks/useTitle";
import { FullHeightColumn } from "components/ui/Grid/Grid";
import { Content } from "components/ui/Content/Content";
import { BrandSection } from "components/public/Brand/BrandSection";
import { DefaultPageRightSection } from "feature/views/PublicPagesShared/DefaultPageRightSection";
import { DefaultPageLeftSection } from "feature/views/PublicPagesShared/DefaultPageLeftSection";
import { BodyText, H1, LeadText } from "components/ui/Typography/Typography";
import { OutlinedInput } from "@material-ui/core";
import { PrimaryButton, SecondaryButton } from "components/ui/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { setNotification, tryRouteChangeStart } from "store/app/actions";
import { RESEND_VERIFICATION_CODE_URL, VERIFY_EMAIL_URL } from "constants/api";
import { HttpClient } from "services/application/httpClient/httpClient";
import { getCurrentUserStart, verifySuccessStart } from "store/auth/actions";
import { Spinner } from "components/ui/Spinner/Spinner";
import useInterval from "hooks/useInterval";
import { useAuthContext } from "hoc/AuthVerification";
import { PANEL_ROOT_PATH, PANEL_USER_SETTINGS, ROOT_PATH } from "constants/routes";

const validChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ""];

const Verify = () => {
  useTitle("Verify account");

  const { logout } = useAuthContext();
  const navigate = useNavigate();
  const user = useSelector(state => state.auth.user);
  const requiresVerification = useSelector(state => state.auth.requireVerification);
  const emailAddress = user.meta?.email_verification?.email ?? user.email;
  const dispatch = useDispatch();

  const [segments, setSegments] = useState(["", "", "", "", "", ""]);

  const [canRequestCode, setCanRequestCode] = useState(() => {
    return moment(user.confirmationRequestSentAt).diff(moment(), "minute") > 3;
  });

  const inputRefs = Array(segments.length)
    .fill(null)
    .map(() => useRef());
  const hasEnteredCode = segments.filter(s => s !== "" && validChars.includes(s)).length === 6;

  useInterval(() => {
    setCanRequestCode(true);
  }, 3000 * 60);

  useEffect(() => {
    if (!user.id) {
      return navigate(ROOT_PATH);
    }

    if (requiresVerification || user.meta?.email_verification?.email || !user.id) {
      return;
    }

    if (user.operators?.length === 0) {
      dispatch(tryRouteChangeStart(PANEL_USER_SETTINGS));
      navigate(PANEL_USER_SETTINGS);
    } else {
      dispatch(tryRouteChangeStart(PANEL_ROOT_PATH));
      navigate(PANEL_ROOT_PATH);
    }
  }, [requiresVerification, user]);

  useEffect(() => {
    inputRefs[0]?.current?.focus();
  }, []);

  const submitCodeMutation = useMutation(
    values => {
      return HttpClient.post(VERIFY_EMAIL_URL, values);
    },
    {
      onSuccess: async () => {
        dispatch(verifySuccessStart());
      },
      onError: () => dispatch(setNotification({ type: "error", message: "We could not verify your email address." })),
    },
  );

  const resendCodeMutation = useMutation(
    values => {
      return HttpClient.post(RESEND_VERIFICATION_CODE_URL, values);
    },
    {
      onSuccess: async () => {
        dispatch(setNotification({ type: "success", message: "We have sent another code to your inbox." }));
        setCanRequestCode(false);
      },
      onError: () => dispatch(setNotification({ type: "error", message: "We could not send the verification code." })),
    },
  );

  const cancelVerificationMutation = useMutation(
    () => {
      return HttpClient.delete(VERIFY_EMAIL_URL);
    },
    {
      onSuccess: async () => {
        dispatch(getCurrentUserStart());
      },
      onError: () => dispatch(setNotification({ type: "error", message: "You are unable to cancel the verification process." })),
    },
  );

  const isLoading =
    submitCodeMutation.isLoading || submitCodeMutation.isSuccess || resendCodeMutation.isLoading || cancelVerificationMutation.isLoading;

  const handleSubmitPressed = () => {
    submitCodeMutation.mutate({
      code: segments.join(""),
    });
  };

  const handleResendPressed = () => {
    if (!canRequestCode) {
      dispatch(setNotification({ type: "error", message: "You must wait before requesting a code." }));
      return;
    }

    resendCodeMutation.mutate({
      email: emailAddress,
      user_id: user.id,
    });
  };

  const handleCancelPressed = () => {
    cancelVerificationMutation.mutate();
  };

  const onPaste = event => {
    event.preventDefault();

    const pasted = event.clipboardData.getData("text/plain");
    const newSegments = [...segments];

    pasted.split("").forEach((code, idx) => {
      if (idx < newSegments.length && validChars.includes(code)) {
        newSegments[idx] = code;
      }
    });

    const nextInputIdx = Math.min(pasted.length, segments.length - 1);
    setSegments(newSegments);
    inputRefs[nextInputIdx].current.focus();
  };

  const onHandleInputChange = index => {
    return event => {
      if (!validChars.includes(event.target.value)) {
        event.preventDefault();
        return false;
      }

      setSegments([...segments.slice(0, index), event.target.value, ...segments.slice(index + 1)]);

      const isDelete = event.target.value === "";
      let nextInputIdx = index;

      if (isDelete && index > 0) {
        // Delete Pressed
        nextInputIdx = index - 1;
      } else if (!isDelete && index < segments.length - 1) {
        // Digit Entered
        nextInputIdx = index + 1;
      }

      inputRefs[nextInputIdx].current.focus();
    };
  };

  return (
    <Grid container spacing={0} component="article">
      <DefaultPageLeftSection>
        <FullHeightColumn alignContent="flex-start">
          <Grid item xs={12}>
            <Content margin={15}>
              <BrandSection />
            </Content>
          </Grid>
          <Grid item xs={12}>
            <section data-testidv="form-section">
              <Content element="header">
                <H1>Email verification</H1>
              </Content>
              <Content margin={12}>
                <LeadText>
                  We have sent you a code to <strong>{emailAddress}</strong>. <br />
                  <br />
                  Please enter the code below to continue.
                </LeadText>
              </Content>
              <Content margin={12}>
                <div style={{ display: "flex", flex: 1, gap: 8 }}>
                  {segments.map((segment, idx) => (
                    <OutlinedInput
                      style={{ width: 64, height: 64, fontSize: 22 }}
                      key={idx}
                      inputRef={inputRefs[idx]}
                      inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
                      size="small"
                      name={`code-${idx}`}
                      value={segment}
                      variant="outlined"
                      onPaste={onPaste}
                      onInput={onHandleInputChange(idx)}
                    />
                  ))}
                </div>
              </Content>
              <Content style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                {isLoading ? (
                  <Spinner size={28} />
                ) : (
                  <>
                    <PrimaryButton
                      data-testid="verify-button"
                      fullWidth
                      size="large"
                      onClick={handleSubmitPressed}
                      disabled={!hasEnteredCode || submitCodeMutation.isLoading}
                    >
                      Submit
                    </PrimaryButton>

                    <div style={{ height: 8 }} />

                    <BodyText style={{ fontSize: 14 }}>
                      {"If you have not received a code within 5 minutes, please "}
                      {/*  eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                      <strong style={{ cursor: "pointer", textDecoration: "underline" }} onClick={handleResendPressed}>
                        click here
                      </strong>
                      {" and we'll send you a new one. "}

                      {user.meta?.email_verification?.email ? (
                        <>
                          {"You can also choose to "}
                          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                          <strong style={{ cursor: "pointer", color: "red", textDecoration: "underline" }} onClick={handleCancelPressed}>
                            cancel the verification
                          </strong>
                          .
                        </>
                      ) : null}
                    </BodyText>

                    <div style={{ height: 8 }} />
                  </>
                )}
              </Content>
              <SecondaryButton
                onClick={() => {
                  logout();
                }}
              >
                Logout
              </SecondaryButton>
            </section>
          </Grid>
        </FullHeightColumn>
      </DefaultPageLeftSection>
      <DefaultPageRightSection hideNavigation backgroundImage={bg} />
    </Grid>
  );
};

export { Verify };
