import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { SaveOutlined } from "@material-ui/icons";

import { Message } from "components/ui/Messages/Message";
import { PrimaryButton } from "components/ui/Buttons";
import { Spinner } from "components/ui/Spinner/Spinner";

import { UNSAVED_CHANGES_MESSAGE } from "constants/notifications";

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 160px;
  align-items: center;
`;

const UnsavedChangesMessage = ({ showSpinner, onSave }) => (
  <MessageWrapper>
    <Message text={UNSAVED_CHANGES_MESSAGE} type="info" />
    <PrimaryButton onClick={onSave}>
      {!showSpinner ? (
        <>
          <SaveOutlined />
          <span>save</span>
        </>
      ) : (
        <Spinner size={28} cv="white" />
      )}
    </PrimaryButton>
  </MessageWrapper>
);

UnsavedChangesMessage.propTypes = {
  showSpinner: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
};

export { UnsavedChangesMessage };
