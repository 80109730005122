import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import CloseIcon from "@material-ui/icons/Close";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const imageBaseProps = styled(({ width, height, ...rest }) => <div {...rest} />)`
  width: ${({ width }) => width || "50"}px;
  height: ${({ height }) => height || "50"}px;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImagePlaceholderArea = styled(imageBaseProps)`
  && {
    border: 1px solid ${({ theme }) => theme.colors.withOpacity(theme.colors.grey50, 0.5)};
    background-color: ${({ theme }) => theme.colors.withOpacity(theme.colors.grey10, 0.5)};
    svg {
      color: ${({ theme }) => theme.colors.withOpacity(theme.colors.grey50, 0.5)};
    }
  }
`;

const ImagePreview = styled(imageBaseProps)`
  && {
    ${({ image }) =>
      image
        ? `
          background-image: url("${image}");
          background-size: cover;
          background-position: center;
        `
        : null};
  }
`;

const RemoveButton = styled(({ hasError, ...rest }) => <CloseIcon {...rest} />)`
  color: ${({ theme, hasError }) => (hasError ? theme.colors.error : theme.colors.grey50)};
  cursor: pointer;
`;
const RemoveButtonBin = styled(({ hasError, ...rest }) => <DeleteOutlineOutlinedIcon {...rest} />)`
  color: ${({ theme, hasError }) => (hasError ? theme.colors.error : theme.colors.grey50)};
  cursor: pointer;
`;

const ImagePlaceholderWithIcon = ({ Icon, ...props }) => (
  <ImagePlaceholderArea {...props}>
    <Icon />
  </ImagePlaceholderArea>
);

ImagePlaceholderWithIcon.propTypes = {
  Icon: PropTypes.shape().isRequired,
};

export { ImagePreview, ImagePlaceholderWithIcon, RemoveButton, RemoveButtonBin };
