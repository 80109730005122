import styled from "styled-components";
import TableContainer from "@mui/material/TableContainer";
import { font } from "config/theme/fonts";
import { colors } from "config/theme/colors";
import ArrowRightAltOutlinedIcon from "@material-ui/icons/ArrowRightAltOutlined";
import React from "react";

export const TableContainerS = styled(TableContainer)`
  /* border: 1px solid #e8e8e9; */
  /* border-radius: 8px; */
  .MuiCheckbox-indeterminate {
    color: ${colors.brand} !important;
  }
  .MuiTableCell-head {
    color: #8d9095;
    font-family: ${font};
    font-size: 12px;
    &:hover {
      text-decoration: underline;
    }
  }
  .MuiTableCell-root {
    font-family: ${font};
  }
  .Mui-selected {
    background-color: rgba(255, 124, 70, 0.1) !important;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export const SortingArrow = styled(({ order, ...rest }) => <ArrowRightAltOutlinedIcon {...rest} />)`
  && {
    transform: rotate(${({ order }) => (order === "asc" ? `` : `-`)}90deg);
    transition: transform 0.15s ease;
    font-size: ${({ theme }) => theme.setSpacing(4)}px;
    align-self: center;
  }
`;

export const FlexArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
`;
