import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLinkClickHandler } from "react-router-dom";
import { ConfirmationModal } from "../Modals/ConfirmationModal";

const PromptContext = React.createContext();

const usePrompt = (msg, enabled, allowedUrls = []) => {
  const { configure } = useContext(PromptContext);

  useEffect(() => {
    configure(msg, enabled, allowedUrls);
  }, [msg, enabled]);

  useEffect(() => {
    return () => {
      configure(msg, false, allowedUrls);
    };
  }, []);
};

const PromptProvider = ({ children }) => {
  const [show, setShow] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [msg, setMsg] = useState("");
  const [options, setOptions] = useState({ to: "" });
  const [allowed, setAllowed] = useState([]);

  const handleClick = useLinkClickHandler(options.to, {
    replace: options.replace,
    state: options.state,
    target: options.target,
  });

  const showPrompt = clickHandlerOptions => {
    setShow(true);
    setOptions(clickHandlerOptions);
  };

  const hidePrompt = () => {
    setShow(false);
  };

  const configure = (promptMsg = "", promptEnabled = false, allowedUrls = []) => {
    setMsg(promptMsg);
    setEnabled(promptEnabled);
    setAllowed(allowedUrls);
  };

  const confirm = event => {
    hidePrompt();
    setEnabled(false);

    if (options.callback) {
      options.callback();
    } else {
      handleClick(event);
    }
  };

  const contextValue = {
    showPrompt,
    configure,
    enabled,
    allowed,
  };

  return (
    <PromptContext.Provider value={contextValue}>
      {children}
      {enabled && show && (
        <ConfirmationModal
          open={enabled && show}
          title={msg}
          cancelLabel="Cancel"
          confirmLabel="Proceed"
          onCancel={hidePrompt}
          onConfirm={confirm}
        />
      )}
    </PromptContext.Provider>
  );
};

PromptProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { PromptContext, PromptProvider, usePrompt };
