import styled from "styled-components";
import Tabs from "@mui/material/Tabs";

import Tab from "@mui/material/Tab";
import { font } from "config/theme/fonts";
import { colors } from "config/theme/colors";
import { responsiveConfig } from "config/responsive";
import { getMediaPx } from "helpers";
import { getStickyPanelContentStyles } from "../responsiveStyle";

export const TabsS = styled(Tabs)`
  position: relative;
  margin: 15px 0 25px;
  /* border-bottom: 2px solid #eaeaea; */
  // div[role="tablist"] {
  //   border-bottom: 2px solid #eaeaea;
  // }
  &::after {
    content: "";
    height: 2px;
    position: absolute;
    left: 40px;
    right: 40px;
    bottom: 0;
    z-index: -1;
    @media (max-width: 330px) {
      display: none;
      .MuiTabs-indicator {
        display: none !important;
      }
    }
  }
  &.MuiTabs-root {
    min-height: auto;
  }
  .MuiTabs-indicator {
    background-color: ${colors.brand};
    @media (max-width: 330px) {
      display: none;
    }
  }
  .MuiTabs-scroller {
    /* border-bottom: 2px solid #eaeaea; */

    @media (max-width: 600px) {
      height: 48px;
    }
  }
  .MuiTabs-root {
    @media (max-width: 600px) {
      min-height: unset;
    }
  }
  ${getStickyPanelContentStyles()}
  ${p => p.customStyles};
`;

export const TabS = styled(Tab)`
  &.MuiButtonBase-root {
    text-transform: capitalize;
    flex-grow: 1;
    font-family: ${font};
    font-weight: 700;
    max-width: fit-content;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    @media (max-width: 900px) {
      font-size: 14px;
      min-height: auto;
      height: 48px;
      padding: 0;
      max-width: none;
      flex-basis: 0;
      flex-grow: 1;
      border-bottom: 2px solid #eaeaea;
    }
    @media (max-width: 330px) {
      font-size: 18px;
    }
  }
  &.Mui-selected {
    color: #000 !important;
    border-color: ${colors.brand};
  }
`;
export const TabLabel = styled.div`
  text-transform: none;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
