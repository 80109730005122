import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";

import { Input, Select } from "components/ui/Forms";
import { NO_AUTO_PW_INPUT_PROPS } from "constants/forms";

const UserIdField = ({ onChange, selectedOperator, value, ...props }) => {
  const [codes, setCodes] = useState([]);
  // After copy across operator list of passcodes is not belong to selected operator
  const currentOperatorCode = useSelector(state => state.auth.currentOperatorCode);
  const operators = useSelector(state => state.auth.operators);
  const getOperatorCodes = code => {
    return code ? operators.find(item => item.code === code)?.usedCodes : [];
  };

  const setOperatorsCodes = choosenOperator => {
    const availableCodes = getOperatorCodes(choosenOperator || currentOperatorCode);
    const selectedUserId = value && availableCodes.includes(value) ? value : availableCodes[0];

    setCodes(availableCodes);
    if (selectedUserId) {
      onChange({ target: { value: selectedUserId, name: props.name }, shouldTouchTheForm: false });
    }
  };

  const handleSelect = event => {
    onChange({ ...event, shouldTouchTheForm: true });
  };

  useEffect(() => setOperatorsCodes(selectedOperator), [selectedOperator]);

  return (
    <>
      {codes.length > 1 ? (
        <Select onChange={handleSelect} value={value} {...props}>
          {codes.map(code => (
            <MenuItem key={code} value={code}>
              {code}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Input {...props} onChange={onChange} value={value} InputProps={NO_AUTO_PW_INPUT_PROPS} disabled />
      )}
    </>
  );
};

UserIdField.defaultProps = {
  value: "",
  name: "",
  selectedOperator: null,
};

UserIdField.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  value: PropTypes.string,
  selectedOperator: PropTypes.string,
};

export { UserIdField };
