import React, { useState } from "react";
import { isEmpty } from "lodash";
import Input from "components/ui/Inputs/TextInput";
import { useEffectDependenciesOnly } from "hooks/useEffectDependenciesOnly";
import DeleteButton from "components/_new/DnD/DeleteIcon";
import { Wrap } from "./style";

const Item = ({ control, item, onAddMultiple, onKeyPress, onDelete, fieldName, index, disabled, emailUpdate, enableAutofocus, setEnableAutofocus, trigger, peopleErrors }) => {
  const [localEmail, setLocalEmail] = useState('');

  const reValidate = () => {
    if (!isEmpty(peopleErrors) && trigger) trigger();
  };

  const handleDelete = () => {
    onDelete(index, item.id);
    reValidate();
  };

  const handleNameChange = name => {
    setEnableAutofocus(false);
    if (name?.includes("\n")) {
      onAddMultiple(name, index);
    }
    reValidate();
  };

  const handleEmailChange = email => {
    setEnableAutofocus(false);
    setLocalEmail(email);
    reValidate();
  };

  const handleEmailOnBlur = () => emailUpdate(localEmail, index);

  return (
    <Wrap disabled={disabled} key={item.people_id}>
      <Input
        control={control}
        name={`${fieldName}.${index}.name`}
        multiline
        rows={1}
        label="Full name"
        onKeyPress={onKeyPress}
        rules={{ required: true }}
        customOnChange={handleNameChange}
        value={item.name}
        disabled={disabled}
        autoFocus={enableAutofocus}
      />
      <Input control={control} name={`${fieldName}.${index}.email`} label="E-mail" disabled={disabled} customOnChange={handleEmailChange} onBlur={handleEmailOnBlur} />
      {!disabled && <DeleteButton onClick={handleDelete} />}
    </Wrap>
  );
};

export default Item;
