import styled from "styled-components";
import { responsiveConfig } from "../../../../config/responsive";

export const LogoArea = styled.div`
  height: inherit;
  width: auto;
  display: flex;
  align-items: center;

  @media (max-width: ${responsiveConfig.mediaPx.laptop}px) {
    img {
      width: 80px;
    }
  }
`;
