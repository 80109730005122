import { colors } from "config/theme/colors";
import styled from "styled-components";

export const Wrap = styled.div`
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 1;

  .MuiFab-root {
    background-color: ${colors.brand};
  }
`;
