import React, { useContext, useEffect } from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { use100vh } from "react-div-100vh";
import { GhostButton } from "components/ui/Buttons";
import theme from "config/theme";
import { BottomWrap, Content, Overlay, Title, Wrap } from "./style";
import { HideOnDesktop } from "../responsiveStyle";
import Buttons from "./buttons";
import { EditorContext } from "../../../feature/panel/Itinerary";

const BaseModal = ({
  children,
  title,
  hideButtons,
  confirmTitle = "Ok",
  cancelTitle = "Cancel",
  mobileFullScreen,
  modalStyle,
  customCss,
  onConfirm,
  onCancel,
  topLineStyle = {},
  isLoading,
  hideConfirm,
  hideCancel,
  contentStyle,
  showCloseIcon,
  confirmDisabled,
  leftAction,
  shouldCloseOnOverlay,
  showScrollBar,
}) => {
  const { setBaseModalOpen } = useContext(EditorContext) ?? {};
  const height = use100vh();

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    if (!setBaseModalOpen) return;
    setBaseModalOpen(!!children);

    return () => {
      setBaseModalOpen(false);
    };
  }, [children]);

  return (
    <Overlay mobileFullScreen={mobileFullScreen} onClick={shouldCloseOnOverlay && onCancel()}>
      <Wrap
        onClick={e => e.stopPropagation()}
        mobileFullScreen={mobileFullScreen}
        style={{overflow: "visible", display: "grid", flexDirection: "column", gridTemplateRows: "max-content 1fr 50px", ...modalStyle}}
        customCss={customCss}
        height100vh={height}
        id="modal"
      >
        <div
          style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", backgroundColor: "#fff", ...topLineStyle }}
        >
          <Title>{title}</Title>
          {showCloseIcon && (
            <CloseOutlinedIcon
              onClick={onCancel}
              sx={{
                cursor: "pointer",
                color: theme.colors.grey50,
                fontSize: "16px",
              }}
            />
          )}
        </div>
        <Content contentStyle={{display: 'flex', flexDirection: 'column', overflowX: 'visible', ...contentStyle}}>{children}</Content>
        {!hideButtons && (
          <BottomWrap>
            {leftAction}
            <Buttons
              confirmTitle={confirmTitle}
              cancelTitle={cancelTitle}
              onConfirm={onConfirm}
              onCancel={onCancel}
              isLoading={isLoading}
              hideConfirm={hideConfirm}
              hideCancel={hideCancel}
              confirmDisabled={confirmDisabled}
            />
          </BottomWrap>
        )}
      </Wrap>
    </Overlay>
  );
};

export default BaseModal;
