import styled, { css } from "styled-components";
import Badge from "@mui/material/Badge";
import { Paper } from "@material-ui/core";

export const CustomBadge = styled(Badge)`
  & .MuiBadge-badge {
    background-color: ${({ theme, cv }) => theme.colors[cv] || theme.colors.brand};
    font-size: ${({ badgesize }) => badgesize};
    ${({ textcolor }) => textcolor && css`color: ${textcolor};`}
  }
`;

export const SelectWrapper = styled.div`
  position: relative;
`;

export const ResultsWrapper = styled(Paper)`
  && {
    position: absolute;
    bottom: -${({ theme }) => theme.setSpacing(2)}px;
    min-width: 205px;
    z-index: 10;
    transform: translateY(100%);
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
  }
`;

export const OptionWrapper = styled.button`
  width: 100%;
  padding: 10px 15px;
  transition: background-color 0.3s ease;
  cursor: pointer;
  background: none;
  border: none;
  text-align: left;

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.colors.grey10};
    outline: none;
  }
`;

export const ModalWrapper = styled.div`
  margin-top: 15px;
`;

export const DocumentModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;