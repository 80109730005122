import { ERRORS, USER_DETAILS_EMAIL_LABEL, USER_DETAILS_USERNAME_LABEL } from "constants/content";
import { emailValidate } from "utils/validation";

import { USER_DETAILS_EMAIL_KEY, USER_DETAILS_USERNAME_KEY } from "./constants";

export const userDetailsValidator = (key, value) => {
  const errors = {};

  if (key === USER_DETAILS_EMAIL_KEY && !value) errors.value = ERRORS.isRequired(USER_DETAILS_EMAIL_LABEL);
  if (key === USER_DETAILS_EMAIL_KEY && value && !emailValidate(value)) errors.value = ERRORS.invalidEmail;

  if (key === USER_DETAILS_USERNAME_KEY && !value) errors.value = ERRORS.isRequired(USER_DETAILS_USERNAME_LABEL);

  return errors;
};
