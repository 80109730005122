import { DeleteOutline, EditOutlined, PlaylistAddOutlined, RefreshOutlined } from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Menu, MenuItem } from "@mui/material";
import OutlinedButton from "components/_new/Buttons/OutlinedButton";
import config from "config/app";
import { MobileItemTitle, MobileItemWrap } from "feature/panel/Itinerary/helpers";
import moment from "moment";
import React, { useState } from "react";
import { css } from "styled-components";
import PDFStatus from "../PDFStatus";
import { Buttons, Date } from "./style";

const MobileItem = ({
  item,
  onAddToDocuments,
  handleMenuOpen,
  openMenu,
  handleMenuClose,
  handleDownload,
  onEdit,
  toggleDeleteModal,
  onRefresh,
  disabled,
  onClickName,
  isPublisher,
}) => {
  const [open, setOpen] = useState(false);

  const isCompleted = item.job.status === "completed";

  const toggleOpen = () => setOpen(!open);

  return (
    <MobileItemWrap
      customStyles={css`
        grid-template-columns: 1fr ${isCompleted ? "50px" : "200px"};
        gap: 15px;
        display: grid;
        align-items: center;
      `}
    >
      <div>
        <MobileItemTitle onClick={item?.outputs && (() => onClickName(item))}>{item.name}</MobileItemTitle>
        <Date>{moment(item.updated_at).format(config.dateTimeFormat)}</Date>
      </div>
      {isCompleted ? (
        <Buttons open={open}>
          {open && (
            <>
              <OutlinedButton startIcon={<RefreshOutlined />} onClick={() => onRefresh(item)} type="grey" iconOnlyMobile />
              <Menu
                id="downloadMenu"
                anchorEl={openMenu?.target}
                open={openMenu?.target?.id === "download-button"}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={() => handleDownload(openMenu?.data, "web")}>Download web version</MenuItem>
                <MenuItem onClick={() => handleDownload(openMenu?.data, "print")}>Download print version</MenuItem>
              </Menu>
              <OutlinedButton
                startIcon={<DownloadIcon />}
                onClick={e => handleMenuOpen(e, item)}
                id="download-button"
                type="grey"
                iconOnlyMobile
              />

              {!isPublisher &&
                <>
                  <Menu id="addMenu" anchorEl={openMenu?.target} open={openMenu?.target?.id === "add-button"} onClose={handleMenuClose}>
                    <MenuItem onClick={() => onAddToDocuments(openMenu?.data, "travel")}>Add to Travel documents</MenuItem>
                    <MenuItem onClick={() => onAddToDocuments(openMenu?.data, "destination")}>Add to Destination documents</MenuItem>
                  </Menu>
                  <OutlinedButton
                    startIcon={<PlaylistAddOutlined />}
                    onClick={e => handleMenuOpen(e, item)}
                    id="add-button"
                    type="grey"
                    iconOnlyMobile
                  />
                </>
              }

              <OutlinedButton startIcon={<EditOutlined />} onClick={() => onEdit(item)} type="grey" iconOnlyMobile />
              <OutlinedButton startIcon={<DeleteOutline />} onClick={() => toggleDeleteModal(item)} type="grey" iconOnlyMobile />
            </>
          )}
          {!disabled && (
            <OutlinedButton
              startIcon={<MoreHorizIcon style={{ color: open ? "#fff" : "#8d9095" }} />}
              style={{ backgroundColor: open ? "#8d9095" : "#fff" }}
              onClick={toggleOpen}
              type="grey"
              iconOnlyMobile
            />
          )}
        </Buttons>
      ) : (
        <PDFStatus job={item.job} />
      )}
    </MobileItemWrap>
  );
};

export default MobileItem;
