import React, { useEffect, useState } from "react";
import { createGlobalStyle } from "styled-components";
import { MuiThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { SnackbarProvider } from "notistack";

import Routes from "router/Routes";
import { Router } from "router/Router";
import { Notification } from "components/ui/Notification/Notification";
import { SavingCoverProvider } from "components/ui/SavingCover/SavingCoverProvider";
import { WithTheme, WithRedux } from "hoc/providers";
import { AuthVerification } from "hoc/AuthVerification";

import { PromptProvider } from "components/ui/CustomPrompt/CustomPrompt";

import theme from "config/theme";
import { muiTheme } from "config/theme/muiTheme";
import snackbarOptions, { useVariants } from "config/snackbar";
import { dispatch } from "store/store";

import { CreateResponseErrorInterceptor, ResponseInterceptor } from "services/application/httpClient/interceptors";
import { apiClientInstance, HttpClient } from "services/application/httpClient/httpClient";
import { CssVarsProvider, extendTheme } from "@mui/joy/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "assets/styles/main.scss";

import { StorageService } from "services/StorageService";
import { ListIndexService } from "services/ListIndexService";
import { TabSessionService } from "services/TabSessionService";
import { LanguageProvider } from "components/_shared/LanguageSelector/LanguageContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import "moment/min/locales";
import moment from "moment";

const locale = navigator.languages?.[0] || "en-GB";
moment.locale(locale.toLowerCase());

const themeJoy = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        // affects all Joy components that has `color="primary"` prop.
        primary: {
          50: "#000",
          100: "#000",
          200: "#000",
          300: "#000",
          400: "#000",
          500: theme.colors.brand,
          900: "#000",
        },
      },
    },
  },
  fontFamily: {
    display: "Inter var, var(--joy-fontFamily-fallback)",
    body: "Inter, var(--joy-fontFamily-fallback)",
  },
});

const defaultQueryFn = async ({ queryKey }) => {
  const { data } = await HttpClient.get(`${queryKey[0]}`);
  return data;
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      networkMode: "always",
      queryFn: defaultQueryFn,
    },
  },
});

export const GlobalStyles = createGlobalStyle`
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  };
  body {
    margin: 0;
    padding: 0 !important;
    font-family: '${theme.fonts.font}';
    color: ${theme.colors.grey90};
    overflow: auto;
  };
  input {
    font-family: ${theme.fonts.font};

  }
  * {
    box-sizing: border-box;
  };

  a {
    text-decoration: none;
    :hover {
      text-decoration: none;
    }
  }

  .gm-style-iw {
    max-height: 250px !important;
  
    &-d {
      max-height: 250px !important;
    }
  }
  .tox-dialog-wrap__backdrop {
    background-color: rgba(0, 0, 0, 0.5) !important;
  }

  .tox-dialog {
    .tox-dialog__content-js {
      input:active,
      input:focus {
        border-color: ${theme.colors.brand};
      }
    }
    .tox-dialog__footer, 
    .tox-confirm-dialog {
      .tox-button{
        background-color: #FFFFFF00 !important;
        color: ${theme.colors.brand} !important;
        border: none;
        text-transform: uppercase;
        letter-spacing: 1px;

        &:hover {
          color: ${theme.colors.brand} !important;
          background-color: #FFFFFF00 !important;
        }

        &.tox-button--secondary {
          color: rgb(164, 167, 170) !important;
        }
      }
    }
  }
`;

// Have to put response error interceptor here to avoid import cycling of dispatch method
const ResponseErrorInterceptor = CreateResponseErrorInterceptor(dispatch);
apiClientInstance.interceptors.response.use(ResponseInterceptor, ResponseErrorInterceptor);

function App() {
  const storageService = new StorageService();
  const tabSessionService = new TabSessionService(storageService);
  const listIndexService = new ListIndexService(storageService);

  const classes = useVariants();

  const clearStorageElements = async () => {
    listIndexService.clearOriginalIndexes();
  };

  const handleTabIdentify = () => {
    tabSessionService.setActiveTabOperatorAsGlobal();
  };

  const initTableIdentify = () => {
    clearStorageElements();
    document.addEventListener("visibilitychange", handleTabIdentify);

    return () => {
      document.removeEventListener("visibilitychange", handleTabIdentify);
    };
  };

  React.useEffect(initTableIdentify, []);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale.toLowerCase()}>
      <QueryClientProvider client={queryClient}>
        {/* <CssVarsProvider theme={themeJoy}> */}
        <WithRedux>
          <WithTheme>
            <LanguageProvider>
              <MuiThemeProvider theme={muiTheme}>
                <SavingCoverProvider>
                  <SnackbarProvider classes={classes} {...snackbarOptions}>
                    <Notification />
                  </SnackbarProvider>
                  <Router>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <GlobalStyles />
                      <AuthVerification>
                        <PromptProvider>
                          <Routes />
                        </PromptProvider>
                      </AuthVerification>
                    </MuiPickersUtilsProvider>
                  </Router>
                </SavingCoverProvider>
              </MuiThemeProvider>
            </LanguageProvider>
          </WithTheme>
        </WithRedux>
        {/* </CssVarsProvider> */}
      </QueryClientProvider>
    </LocalizationProvider>
  );
}

export default App;
