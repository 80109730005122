export const ROOT_PATH = "/";
export const REGISTER_PATH = "/sign-up";
export const VERIFY_PATH = "/verify";
export const STYLEGUIDE_PATH = "/styleguides";
export const PASSWORD_RESET_PATH = "/password-reset";
export const CONTACT_PATH = "/contact";
export const TERMS_PATH = "/terms";
export const FAQ_PATH = "/faq";

export const NOT_FOUND_PATH = "/404";

// Panel paths
export const PANEL_ROOT_PATH = "/panel";

// /panel/trips
export const PANEL_TRIPS_ROOT_PATH = `${PANEL_ROOT_PATH}/trips`;

// /panel/trips/create
export const PANEL_TRIPS_CREATE_PATH = `${PANEL_TRIPS_ROOT_PATH}/create`;
export const PANEL_TRIPS_CREATE_CONTENT_PATH = `${PANEL_TRIPS_CREATE_PATH}/:content`;

export const PANEL_TRIPS_CREATE_DOCUMENTS_PARAM = "documents";
export const PANEL_TRIPS_CREATE_LOCATIONS_PARAM = "locations";
export const PANEL_TRIPS_CREATE_STORYBOARD_PARAM = "storyboard";
export const PANEL_TRIPS_CREATE_PEOPLE_PARAM = "people";
export const PANEL_TRIPS_CREATE_NOTIFICATIONS_PARAM = "notifications";
export const PANEL_TRIPS_CREATE_FLIGHTS_PARAM = "flights";
export const PANEL_TRIPS_CREATE_CREATIONS_PARAM = "creations";
export const PANEL_TRIPS_CREATE_MESSAGING_PARAM = "messaging";
export const PANEL_TRIPS_CREATE_INSPIRATIONS_PARAM = "inspirations";
export const PANEL_TRIPS_SHARE_ACCESS_PARAM = "shareAccess";

// /panel/trips/:id
export const PANEL_TRIPS_EDIT_PATH = `${PANEL_TRIPS_ROOT_PATH}/:operator_code/:reference_code/edit`;
export const PANEL_TRIPS_EDIT_CONTENT_PATH = `${PANEL_TRIPS_EDIT_PATH}/:content`;

// /panel/stays
export const PANEL_STAYS_PATH = `${PANEL_ROOT_PATH}/stays`;

export const PANEL_STAYS_CREATE_PATH = `${PANEL_STAYS_PATH}/create`;
export const PANEL_STAYS_CREATE_CONTENT_PATH = `${PANEL_STAYS_CREATE_PATH}/:content`;
export const PANEL_STAYS_CREATE_GENERAL = `${PANEL_STAYS_CREATE_PATH}`;
export const PANEL_STAYS_CREATE_ACTIONS = `${PANEL_STAYS_CREATE_PATH}/actions`;
export const PANEL_STAYS_CREATE_POIS = `${PANEL_STAYS_CREATE_PATH}/pois`;
export const PANEL_STAYS_CREATE_GALLERY = `${PANEL_STAYS_CREATE_PATH}/gallery`;
export const PANEL_STAYS_CREATE_DIRECTORIES = `${PANEL_STAYS_CREATE_PATH}/directories`;
export const PANEL_STAYS_CREATE_DAILY = `${PANEL_STAYS_CREATE_PATH}/daily`;
export const PANEL_STAYS_CREATE_VOUCHERS = `${PANEL_STAYS_CREATE_PATH}/vouchers`;
export const PANEL_STAYS_CREATE_PASSCODES = `${PANEL_STAYS_CREATE_PATH}/passcodes`;
export const PANEL_STAYS_CREATE_NOTIFICATIONS = `${PANEL_STAYS_CREATE_PATH}/notifications`;
export const PANEL_STAYS_CREATE_INSPIRATIONS = `${PANEL_STAYS_CREATE_PATH}/inspirations`;
export const PANEL_STAYS_CREATE_DND = `${PANEL_STAYS_CREATE_PATH}/do-not-disturb`;
export const PANEL_STAYS_CREATE_PUBLISHER = `${PANEL_STAYS_CREATE_PATH}/publisher`;
export const PANEL_STAYS_CREATE_SHARE_ACCESS = `${PANEL_STAYS_CREATE_PATH}/shareAccess`;
export const PANEL_STAYS_CREATE_MESSAGING = `${PANEL_STAYS_CREATE_PATH}/messaging`;

export const PANEL_STAYS_GALLERY_PARAM = "gallery";
export const PANEL_STAYS_ACTIONS_PATH = "actions";
export const PANEL_STAYS_PASSCODES_PARAM = "passcodes";
export const PANEL_STAYS_INSPIRATIONS_PARAM = "inspirations";
export const PANEL_STAYS_PUBLISHER_PARAM = "publisher";
export const PANEL_STAYS_MESSAGING_PARAM = "messaging";
export const PANEL_STAYS_DIRECTORIES_PARAM = "directories";
export const PANEL_STAYS_DAILY_PARAM = "daily";
export const PANEL_STAYS_VOUCHERS_PARAM = "vouchers";
export const PANEL_STAYS_NOTIFICATIONS_PARAM = "notifications";
export const PANEL_STAYS_POIS_PARAM = "pois";
export const PANEL_STAYS_DO_NOT_DISTURB_PARAM = "do-not-disturb";
export const PANEL_STAYS_SHARE_ACCESS_PARAM = "shareAccess";

// panel/stays/:operatorCode
export const PANEL_STAYS_EDIT_PATH = `${PANEL_STAYS_PATH}/:operator_code/edit`;
export const PANEL_STAYS_EDIT_CONTENT_PATH = `${PANEL_STAYS_PATH}/:operator_code/edit/:content`;

export const PANEL_STAYS_EDIT_GENERAL = `${PANEL_STAYS_EDIT_PATH}`;
export const PANEL_STAYS_EDIT_ACTIONS = `${PANEL_STAYS_EDIT_PATH}/actions`;
export const PANEL_STAYS_EDIT_POIS = `${PANEL_STAYS_EDIT_PATH}/pois`;
export const PANEL_STAYS_EDIT_GALLERY = `${PANEL_STAYS_EDIT_PATH}/gallery`;
export const PANEL_STAYS_EDIT_DIRECTORIES = `${PANEL_STAYS_EDIT_PATH}/directories`;
export const PANEL_STAYS_EDIT_DAILY = `${PANEL_STAYS_EDIT_PATH}/daily`;
export const PANEL_STAYS_EDIT_VOUCHERS = `${PANEL_STAYS_EDIT_PATH}/vouchers`;
export const PANEL_STAYS_EDIT_PASSCODES = `${PANEL_STAYS_EDIT_PATH}/passcodes`;
export const PANEL_STAYS_EDIT_NOTIFICATIONS = `${PANEL_STAYS_EDIT_PATH}/notifications`;
export const PANEL_STAYS_EDIT_INSPIRATIONS = `${PANEL_STAYS_EDIT_PATH}/inspirations`;
export const PANEL_STAYS_EDIT_DND = `${PANEL_STAYS_EDIT_PATH}/do-not-disturb`;
export const PANEL_STAYS_EDIT_PUBLISHER = `${PANEL_STAYS_EDIT_PATH}/publisher`;
export const PANEL_STAYS_EDIT_MESSAGING = `${PANEL_STAYS_EDIT_PATH}/messaging`;
export const PANEL_STAYS_EDIT_SHARE_ACCESS = `${PANEL_STAYS_EDIT_PATH}/shareAccess`;

export const PANEL_LIBRARY_PATH = `${PANEL_ROOT_PATH}/library`;
export const PANEL_LIBRARY_FOLDER_PATH = `${PANEL_LIBRARY_PATH}/:name`;

export const PANEL_NOTIFICATIONS_PATH = `${PANEL_ROOT_PATH}/notifications`;

export const PANEL_POIS_PATH = `${PANEL_ROOT_PATH}/pois`;
export const PANEL_POIS_ICON_MANAGER_PATH = `${PANEL_POIS_PATH}/icons`;

export const PANEL_INSPIRATIONS_PATH = `${PANEL_ROOT_PATH}/inspirations`;
export const PANEL_INSPIRATIONS_CREATE_PATH = `${PANEL_INSPIRATIONS_PATH}/create`;
export const PANEL_INSPIRATIONS_EDIT_PATH = `${PANEL_INSPIRATIONS_PATH}/:userId/:passcode/edit`;

export const PANEL_SETTINGS_MENUS_LABELS = `${PANEL_ROOT_PATH}/menus-labels`;

export const PANEL_SETTINGS_ROOT = `${PANEL_ROOT_PATH}/settings`;
export const PANEL_SETTINGS_COMPANY = `${PANEL_SETTINGS_ROOT}/company`;
export const PANEL_SETTINGS_ACCOUNTS = `${PANEL_SETTINGS_ROOT}/accounts`;
export const PANEL_SETTINGS_BRANDING = `${PANEL_SETTINGS_ROOT}/branding`;
export const PANEL_SETTINGS_CONNECT = `${PANEL_SETTINGS_ROOT}/connect`;
export const PANEL_SETTINGS_CUSTOM_APPS = `${PANEL_SETTINGS_ROOT}/custom-apps`;
export const PANEL_SETTINGS_REQUEST_TRACKER = `${PANEL_SETTINGS_ROOT}/request-tracker`;

export const PANEL_SETTINGS_PASSCODES = `${PANEL_SETTINGS_ROOT}/passcodes`;

export const PANEL_SETTINGS_CUSTOM_APPS_CREATE = `${PANEL_SETTINGS_CUSTOM_APPS}/create`;
export const PANEL_SETTINGS_CUSTOM_APPS_CREATE_GENERAL = `${PANEL_SETTINGS_CUSTOM_APPS_CREATE}/general`;
export const PANEL_SETTINGS_CUSTOM_APPS_CREATE_LOGO = `${PANEL_SETTINGS_CUSTOM_APPS_CREATE}/logo`;
export const PANEL_SETTINGS_CUSTOM_APPS_CREATE_ELEMENTS = `${PANEL_SETTINGS_CUSTOM_APPS_CREATE}/elements`;
export const PANEL_SETTINGS_CUSTOM_APPS_CREATE_LOGIN = `${PANEL_SETTINGS_CUSTOM_APPS_CREATE}/login`;
export const PANEL_SETTINGS_CUSTOM_APPS_CREATE_MENU = `${PANEL_SETTINGS_CUSTOM_APPS_CREATE}/menu`;
export const PANEL_SETTINGS_CUSTOM_APPS_CREATE_TEXTS = `${PANEL_SETTINGS_CUSTOM_APPS_CREATE}/texts`;
export const PANEL_SETTINGS_CUSTOM_APPS_CREATE_PREVIEW = `${PANEL_SETTINGS_CUSTOM_APPS_CREATE}/preview`;
export const PANEL_SETTINGS_CUSTOM_APPS_CREATE_VERSIONS = `${PANEL_SETTINGS_CUSTOM_APPS_CREATE}/versions`;
export const PANEL_SETTINGS_CUSTOM_APPS_CREATE_INFORMATION = `${PANEL_SETTINGS_CUSTOM_APPS_CREATE}/information`;

export const PANEL_CUSTOM_APPS_EDIT_PATH = `${PANEL_SETTINGS_CUSTOM_APPS}/:id/edit`;
export const PANEL_CUSTOM_APPS_EDIT_PATH_GENERAL = `${PANEL_CUSTOM_APPS_EDIT_PATH}/general`;
export const PANEL_CUSTOM_APPS_EDIT_PATH_LOGO = `${PANEL_CUSTOM_APPS_EDIT_PATH}/logo`;
export const PANEL_CUSTOM_APPS_EDIT_PATH_ELEMENTS = `${PANEL_CUSTOM_APPS_EDIT_PATH}/elements`;
export const PANEL_CUSTOM_APPS_EDIT_PATH_LOGIN = `${PANEL_CUSTOM_APPS_EDIT_PATH}/login`;
export const PANEL_CUSTOM_APPS_EDIT_PATH_MENU = `${PANEL_CUSTOM_APPS_EDIT_PATH}/menu`;
export const PANEL_CUSTOM_APPS_EDIT_PATH_TEXTS = `${PANEL_CUSTOM_APPS_EDIT_PATH}/texts`;
export const PANEL_CUSTOM_APPS_EDIT_PATH_PREVIEW = `${PANEL_CUSTOM_APPS_EDIT_PATH}/preview`;
export const PANEL_CUSTOM_APPS_EDIT_PATH_VERSIONS = `${PANEL_CUSTOM_APPS_EDIT_PATH}/versions`;
export const PANEL_CUSTOM_APPS_EDIT_PATH_INFORMATION = `${PANEL_CUSTOM_APPS_EDIT_PATH}/information`;

export const CUSTOM_APP_LOGO_AND_ICON_PARAM = "logo";
export const CUSTOM_APP_ELEMENTS_PARAM = "elements";
export const CUSTOM_APP_LOGIN_SCREEN_PARAM = "login";
export const CUSTOM_APP_MENU_PARAM = "menu";
export const CUSTOM_APP_TEXTS_PARAM = "texts";
export const CUSTOM_APP_PREVIEW_PARAM = "preview";
export const CUSTOM_APP_VERSION_HISTORY_PARAM = "versions";

export const PANEL_HELP_ROOT = `${PANEL_ROOT_PATH}/help`;
export const PANEL_HELP_USER_GUIDE = `${PANEL_HELP_ROOT}/user-guide`;
export const PANEL_HELP_FAQ = `${PANEL_HELP_ROOT}/faq`;
export const PANEL_HELP_TERMS = `${PANEL_HELP_ROOT}/terms`;
export const PANEL_HELP_CONTACT_US = `${PANEL_HELP_ROOT}/contact-us`;
export const PANEL_HELP_VAMOOS_API = `${PANEL_HELP_ROOT}/vamoos-api`;

export const PANEL_USER_SETTINGS = `${PANEL_ROOT_PATH}/user-settings`;

// ADMIN PANEL

export const ADMIN_ROOT_PATH = "/admin";

export const ADMIN_OPERATORS_PATH = `${ADMIN_ROOT_PATH}/operators`;
export const ADMIN_OPERATOR_DETAILS_PATH = `${ADMIN_OPERATORS_PATH}/:id`;
export const ADMIN_OPERATOR_DETAILS_GENERAL_PATH = `${ADMIN_OPERATOR_DETAILS_PATH}/general`;
export const ADMIN_OPERATOR_DETAILS_STATISTICS_PATH = `${ADMIN_OPERATOR_DETAILS_PATH}/statistics`;
export const ADMIN_OPERATOR_DETAILS_CUSTOM_APPS_PATH = `${ADMIN_OPERATOR_DETAILS_PATH}/custom-app`;

export const ADMIN_USERS_PATH = `${ADMIN_ROOT_PATH}/users`;
export const ADMIN_USER_DETAILS_PATH = `${ADMIN_USERS_PATH}/:id`;

export const ADMIN_CONNECT_PATH = `${ADMIN_ROOT_PATH}/connect`;

export const ADMIN_FEATURES_PATH = `${ADMIN_ROOT_PATH}/features`;
export const ADMIN_FEATURES_EDIT_PATH = `${ADMIN_FEATURES_PATH}/edit`;

export const ADMIN_ICONS_PATH = `${ADMIN_ROOT_PATH}/icons`;
export const ADMIN_EDIT_ICONS_PATH = `${ADMIN_ICONS_PATH}/edit`;
export const ADMIN_CUSTOM_APP_PATH = `${ADMIN_ROOT_PATH}/`;
