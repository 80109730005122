import React from "react";
import styled, { keyframes, css } from "styled-components";
import MuiTableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

const blinkAnimation = keyframes`
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
`;

export const StyledMuiTableRow = styled(({ item, ...rest }) => <MuiTableRow {...rest} />)`
  && {
    transition: ${({ theme }) => theme.transition};
    height: ${({ theme }) => theme.setSpacing(18)}px;
    position: relative;
    opacity: ${({ item }) => (item?.isOverriden ? 0.3 : 1)};
    cursor: ${({ item }) => (item?.isOverriden ? "pointer" : "default")};
    ${({ clickedOverride, item, shouldBlink }) =>
      clickedOverride === item?.id &&
      shouldBlink &&
      css`
        animation: ${blinkAnimation} 0.5s ease-out 2;
      `};

    :hover {
      background: ${({ theme }) => theme.colors.withOpacity(theme.colors.grey10, 0.3)};
    }

    .MuiTableCell-body {
      white-space: nowrap;
    }
  }
`;

export const ActionTableCell = styled(TableCell)`
  && {
    padding: 0;

    :last-child {
      padding-right: 0;
    }
  }
`;
