import React from "react";
import styled from "styled-components";

import { H1, BodyText, BodyText2 } from "components/ui/Typography/Typography";

import svg404 from "assets/images/404.svg";
import { ACCESS_DENIED_TEXT, ERROR_403_ERROR_MESSAGE } from "constants/content";

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transform: translateY(80%);
`;

const ImageWrapper = styled.div`
  position: relative;
  margin-bottom: 40px;
`;

const StyledH1 = styled(H1)`
  && {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: ${({ theme }) => theme.setSpacing(6.5)}px;
    color: ${({ theme }) => theme.colors.white};
  }
`;

const ErrorMessageHeader = styled(BodyText)`
  && {
    max-width: 500px;
    text-align: center;
    margin-bottom: ${({ theme }) => theme.setSpacing(6)}px;
    line-height: 1.2;
  }
`;

const ErrorMessageContent = styled(BodyText2)`
  && {
    max-width: 500px;
    text-align: center;
    line-height: 1.25;
    white-space: break-spaces;
  }
`;

const AccessDenied = () => {
  return (
    <ContentWrapper>
      <ImageWrapper>
        <img src={svg404} alt="403" />
        <StyledH1>403</StyledH1>
      </ImageWrapper>
      <ErrorMessageHeader cv="grey50">{ACCESS_DENIED_TEXT}</ErrorMessageHeader>
      <ErrorMessageContent cv="grey50">{ERROR_403_ERROR_MESSAGE}</ErrorMessageContent>
    </ContentWrapper>
  );
};

export { AccessDenied };
