import React, { useEffect } from "react";
import styled from "styled-components";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import PropTypes from "prop-types";
import uuidv4 from "uuid";
import { firstLetterUpperCase } from "utils/string";

const CustomTreeView = styled(TreeView)`
  && {
    height: ${({ theme }) => theme.setSpacing(54)}px;
    flex-grow: 1;
    max-width: 400px;
  }
`;

const TreeListDisplay = ({ libraryTree, setUploadFolder, defaultPath }) => {
  const handleItemClick = (event, path) => {
    const { target } = event;
    if (target.innerText) {
      setUploadFolder(path);
    }
  };

  const setDefaultPath = () => {
    if (typeof defaultPath === "string" && defaultPath) {
      const path = defaultPath.split("/");
      path.pop();
      const finalPath = path.join("/");
      setUploadFolder(finalPath.length > 0 ? finalPath : defaultPath);
    }
  };

  useEffect(setDefaultPath, [defaultPath]);

  const setLabel = (parent, key) => {
    const keyFormatted = key === "library" ? firstLetterUpperCase(key) : key;

    return `${parent}/${keyFormatted}`.split("/").pop();
  };

  const getTreeItemsFromData = (treeItems, parent = "") => {
    let children;

    if (treeItems !== null && typeof treeItems === "object") {
      return Object.entries(treeItems).map(([name, item]) => {
        const { id, ...restOfItem } = item;
        children = getTreeItemsFromData(restOfItem, `${parent}/${name}`);

        const key = id || uuidv4();
        return (
          <TreeItem
            onClick={event => handleItemClick(event, `${parent}/${name}`)}
            key={key}
            nodeId={String(key)}
            label={setLabel(parent, name)}
            id={key}
          >
            {children}
          </TreeItem>
        );
      });
    }
    return null;
  };
  return (
    <>
      <CustomTreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
        {getTreeItemsFromData(libraryTree)}
      </CustomTreeView>
    </>
  );
};

TreeListDisplay.defaultProps = {
  defaultPath: "",
};

TreeListDisplay.propTypes = {
  libraryTree: PropTypes.shape().isRequired,
  setUploadFolder: PropTypes.func.isRequired,
  defaultPath: PropTypes.string,
};

export { TreeListDisplay, CustomTreeView };
