import { colors } from "config/theme/colors";
import { getMediaPx } from "helpers";
import styled from "styled-components";

export const Wrap = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr 161px;
  align-items: center;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    grid-template-columns: 80px 1fr 48px;
  }
`;

export const Buttons = styled.div`
  display: flex;
`;

export const Name = styled.div`
  cursor: ${p => (p.is_list ? "pointer" : "default")};
  &:hover {
    text-decoration: ${p => (p.is_list ? "underline" : "none")};
  }
`;

export const Breadcrumb = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${p => (p.is_active ? "#000" : colors.grey30)};
  cursor: ${p => (p.is_active ? "default" : "pointer")};
  &:hover {
    text-decoration: ${p => (p.shouldHover ? "underline" : "none")};
  }
`;

export const DirBlock = styled.div`
  padding: 15px;
  border: 1px solid #eeeeee;
  margin-bottom: 15px;
`;
