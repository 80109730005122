import moment from "moment";

export const setDayNumber = days => {
  if (days.length > 0) {
    const dayNumbers = days.map(day => day.meta?.day_number).filter(dayNumber => dayNumber !== undefined);
    return Math.max(0, ...dayNumbers) + 1;
  }
  return 1;
};

export const setPositionNumber = days => {
  if (days.length > 0) {
    const currentDay = days[days.length - 1];
    if (typeof currentDay === "object" && currentDay.position !== undefined) {
      return Number(currentDay.position) + 1;
    }
  }
  return 1;
};

export const getFlightEntryDayNumber = (departureDate, storyboardStartDate) => {
  const diff = moment(departureDate).diff(storyboardStartDate);
  return Math.floor(moment.duration(diff).asDays())
}
