export const GET_PUBLIC_ICONS_START = "GET_PUBLIC_ICONS_START";
export const GET_PUBLIC_ICONS_SUCCESS = "GET_PUBLIC_ICONS_SUCCESS";
export const GET_PUBLIC_ICONS_FAIL = "GET_PUBLIC_ICONS_FAIL";

export const GET_OPERATOR_ICONS_START = "GET_OPERATOR_ICONS_START";
export const GET_OPERATOR_ICONS_SUCCESS = "GET_OPERATOR_ICONS_SUCCESS";
export const GET_OPERATOR_ICONS_FAIL = "GET_OPERATOR_ICONS_FAIL";

export const ADD_NEW_ICON = "ADD_NEW_ICON";
export const DELETE_ICON = "DELETE_ICON";

export const UPDATE_ICONS_START = "UPDATE_ICONS_START";
export const UPDATE_ICONS_SUCCESS = "UPDATE_ICONS_SUCCESS";
export const UPDATE_ICONS_FAIL = "UPDATE_ICONS_FAIL";

export const TOGGLE_ICON_FORM_TOUCHED = "TOGGLE_ICON_FORM_TOUCHED";
