import styled from "styled-components";
import React from "react";

import { ImageBaseProps } from "components/ui/Images/ImageBaseProps";
import { useRefreshedFileUrl } from "hooks/useRefreshedFileUrl";

const Image = styled(({ bgType, ...props }) => <ImageBaseProps {...props} />)`
  && {
    ${({ image, bgType }) =>
      image
        ? `
          background-image: url("${image}");
          background-size: ${bgType || "cover"};
          background-position: center;
          background-repeat: no-repeat;
        `
        : null};
  }
`;

const ImagePreview = ({ image, ...rest }) => {
  const url = useRefreshedFileUrl(image);

  return url ? <Image image={url} {...rest} /> : null;
};

export { ImagePreview };
