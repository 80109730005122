import { useEffect, useState } from "react";
import localforage from "localforage";

const useColumnSelector = (localStorageKey, initialColumns, device, list) => {
  const [storedColumns, setStoredColumns] = useState(null);

  useEffect(() => {
    if (!list) return;

    const handleSelectedColumns = async () => {
      let storedColumns = await localforage.getItem(localStorageKey);

      const compareColumns = (initialCols, storedCols) => {
        if (initialCols.length !== storedCols?.length) return false;

        return initialCols.every((col, index) => col.label === storedCols[index].label && col.key === storedCols[index].key);
      };

      const compareResult = compareColumns(initialColumns?.all, storedColumns?.all);

      if (!storedColumns || !compareResult) {
        storedColumns = {
          all: initialColumns.all,
          defaultDevice: device,
          desktop: initialColumns.all,
          mobile: initialColumns.all,
        };
      } else {
        storedColumns.desktop = storedColumns.desktop || initialColumns.all;
        storedColumns.mobile = storedColumns.mobile || initialColumns.all;
        storedColumns.tablet = storedColumns.tablet || initialColumns.all;
      }
      setStoredColumns(storedColumns);
    };

    handleSelectedColumns();
  }, [list, initialColumns, device]);

  const handleSaveColumns = async (selectedColumns, onUpdate, localStorageKey) => {
    const newStoredTripsColumns = {
      ...storedColumns,
      all: selectedColumns,
      [device]: selectedColumns.filter(col => col.show),
    };
    setStoredColumns(newStoredTripsColumns);

    await localforage.setItem(localStorageKey, newStoredTripsColumns);
    onUpdate(false);
  };

  return { storedColumns, handleSaveColumns };
};

export { useColumnSelector };
