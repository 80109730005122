import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { UnsafeAutocomplete, Switch } from "components/ui/Forms";
import { SearchField } from "components/ui/SearchField";

import { SETTINGS_STAYS } from "constants/content";
import { useListOfCountries } from "hooks/useListOfCountries";
import { filterOptions } from "utils/filters/filterOptions";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { useService } from "hooks/useService";
import { OperatorService } from "services/OperatorService";
import { Logger } from "services/application/Logger";
import { getCurrentOperatorStart } from "store/operator/actions";

const ControlsContainer = styled.div`
  padding-top: ${({ theme }) => `${theme.setSpacing(5)}px`};
  padding-bottom: ${({ theme }) => `${theme.setSpacing(5)}px`};
  padding-left: ${({ theme }) => `${theme.setSpacing(6)}px`};
  padding-right: ${({ theme }) => `${theme.setSpacing(6)}px`};
`;

export const StaysTableControls = ({ onSearchChange, onCountryChange }) => {
  const dispatch = useDispatch();
  const countries = useListOfCountries();

  const operatorService = useService(OperatorService);

  const permissionsService = usePermissionsService();
  const [tableControlItemsWidth, setTableControlsItemsWidth] = useState({
    searchField: 4,
    autoComplete: 3,
    bookingButton: 5,
  });

  const canEditShowbookingButton = permissionsService.can(
    PERMISSIONS.actions.update,
    PERMISSIONS.sections.operator,
    PERMISSIONS.resources.operators,
  );

  const operator = useSelector(state => state.operator.currentOperator);
  const showBookNow = operator?.meta?.show_book_now_in_nested || false;

  const [selectedCountries, setSelectedCountries] = useState([]);
  const [countrySearchValue, setCountrySearchValue] = useState("");

  const filteredCountries = filterOptions(countries, countrySearchValue);

  const setValues = newCountries => {
    setSelectedCountries(newCountries);
    onCountryChange(newCountries.map(country => country.key));
    setCountrySearchValue("");
  };

  const handleCountryChange = selectedItem => {
    const isCountryAlreadySelected = selectedCountries.some(country => country.key === selectedItem.key);
    if (!isCountryAlreadySelected) {
      setValues([selectedItem, ...selectedCountries]);
    }
  };

  const handleRemoveFromSelectedList = ({ key }) => {
    setValues(selectedCountries.filter(country => country.key !== key));
  };

  const handleCountrySearchChange = ({ target }) => {
    const { value } = target;
    setCountrySearchValue(value);
  };

  const onBookingNowChange = async () => {
    try {
      const currentOperator = await operatorService.getCurrentOperator();

      delete currentOperator.isActive;

      currentOperator.showBookNowInNested = !showBookNow;

      await operatorService.updateCurrentOperator(currentOperator);

      dispatch(getCurrentOperatorStart());
    } catch (e) {
      Logger.debug(e);
    }
  };

  const updateControlPanelItemsWidth = () => {
    return window.innerWidth > 1400
      ? {
          searchField: 4,
          autoComplete: 3,
          bookingButton: 5,
        }
      : {
          searchField: 5,
          autoComplete: 3,
          bookingButton: 4,
        };
  };
  const init = () => {
    const updateWidth = () => {
      const initData = updateControlPanelItemsWidth();
      setTableControlsItemsWidth(initData);
    };
    updateWidth();
    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  };
  // eslint-disable-next-line no-undef
  useEffect(init, []);
  return (
    <ControlsContainer>
      <Grid container spacing={5}>
        <Grid item xs={tableControlItemsWidth.searchField}>
          <SearchField onSearchChange={onSearchChange} label={SETTINGS_STAYS.searchField} fullWidth />
        </Grid>
        <Grid item xs={tableControlItemsWidth.autoComplete}>
          <UnsafeAutocomplete
            multiple
            autoComplete="country"
            inputProps={{ autoComplete: "country" }}
            name="country"
            label={SETTINGS_STAYS.countryField}
            onOptionClick={handleCountryChange}
            onChange={handleCountrySearchChange}
            options={filteredCountries}
            value={countrySearchValue}
            selectedList={selectedCountries}
            onDelete={handleRemoveFromSelectedList}
            size="large"
          />
        </Grid>
        <Grid container item xs={tableControlItemsWidth.bookingButton}>
          <Box ml="auto">
            <Switch
              name="bookNow"
              checked={showBookNow}
              label={SETTINGS_STAYS.showBookingNowButton}
              labelPlacement="start"
              onChange={onBookingNowChange}
              disabled={!canEditShowbookingButton}
            />
          </Box>
        </Grid>
      </Grid>
    </ControlsContainer>
  );
};

StaysTableControls.defaultProps = {
  onSearchChange: () => {},
  onCountryChange: () => {},
};

StaysTableControls.propTypes = {
  onSearchChange: PropTypes.func,
  onCountryChange: PropTypes.func,
};
