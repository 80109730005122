import { colors } from "config/theme/colors";
import { getMediaPx } from "helpers";
import styled from "styled-components";

export const Date = styled.div`
  font-size: 14px;
  color: ${colors.grey40};
`;

export const Buttons = styled.div`
  padding: 5px;
  border-radius: 8px;
  box-shadow: ${p => (p.open ? "0 0 10px rgba(0, 0, 0, 0.3)" : "none")};
  display: flex;
  gap: 5px;
  position: absolute;
  right: 10px;
  background-color: #fff;
`;
