import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import styled from "styled-components";
import { TableCell, Tooltip } from "@material-ui/core";

import { Link } from "components/ui/Links/Links";
import { Chip } from "components/ui/Chips";
import { TableRow, useTableContext } from "components/ui/Tables";

import config from "config/app";
import { setUrl, setUrlParams } from "utils/url";
import { ADMIN_OPERATOR_DETAILS_PATH } from "constants/routes";

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: underline;
  }
`;

const ConnectItineraryTableRow = ({ item }) => {
  const { headers } = useTableContext();
  const columnsToRedner = headers.filter(header => header.show);

  const renderCell = key => {
    if (key === "downloads" || key === "downloadsLast30") {
      return (
        <Tooltip title={item[key]}>
          <Chip label={item[key] || 0} />
        </Tooltip>
      );
    }

    if (key === "operatorName") {
      const url = setUrlParams(setUrl(ADMIN_OPERATOR_DETAILS_PATH, { id: item.operatorId }), { readOnly: "true" });

      return (
        <StyledLink to={url} cv="grey100" linkFor="table">
          {item[key]}
        </StyledLink>
      );
    }
    if (["createdAt", "updatedAt"].includes(key)) return moment(item[key]).format(config.dateTimeFormat);
    return item[key];
  };

  return (
    <TableRow item={item}>
      {columnsToRedner.map(({ key }, index) => (
        <TableCell key={`cell-${item[key]}-${item?.id}-${index}`}>{renderCell(key)}</TableCell>
      ))}
    </TableRow>
  );
};

ConnectItineraryTableRow.propTypes = {
  item: PropTypes.shape({
    operatorId: PropTypes.number,
  }).isRequired,
};

export { ConnectItineraryTableRow };
