export const textInputs = [
  {
    key: 1,
    name: "copyright",
    label: "Copyright notice",
  },
  {
    key: 2,
    name: "support_url",
    label: "Support URL",
    type: "url",
  },
  {
    key: 3,
    name: "privacy_policy_url",
    label: "Privacy policy URL",
  },
];

export const screenshotsInputs = [
  {
    key: 0,
    name: "screenshots.operator_code",
    label: "User ID *",
  },
  {
    key: 1,
    name: "screenshots.passcode",
    label: "Passcode *",
  },
];

export const iOSInputs = [
  {
    key: 0,
    name: "ios.developer_account_name",
    label: "Developer account name",
    rules: { required: true },
  },
];

export const androidInputs = [
  {
    key: 0,
    name: "android.developer_account_name",
    label: "Developer account name",
  },
];
