import { getMediaPx } from "helpers";
import styled from "styled-components";
import { colors } from "config/theme/colors";

export const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 25px;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    grid-template-columns: 1fr;
  }
`;

export const InputsGrid = styled.div`
  display: grid;
  grid-template-columns: ${p => (p.showTime && p.showDays ? "1fr 1fr 20px 1fr" : p.showTime ? "1fr 20px 1fr" : "1fr")};
  grid-template-areas: ${p => (p.showTime && p.showDays ? "'days period at time'" : p.showTime ? "'period at time'" : "'period'")};
  gap: 15px;
  align-items: center;
  margin-top: 27px;
  @media (max-width: ${p => getMediaPx(p, "lg")}px) {
    grid-template-columns: ${p => (p.showTime && p.showDays ? "1fr  20px 1fr" : p.showTime ? "1fr 20px 1fr" : "1fr")};
    grid-template-areas: ${p =>
      p.showTime && p.showDays
        ? `
  "period period period"
  "days at time"
  `
        : p.showTime
        ? "'period at time'"
        : "'period'"};
  }
`;

export const Description = styled.div`
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    color: ${colors.grey40};
    font-size: 14px;
  }
`;
