export const OPERATOR_CONFIG = {
  companyName: { label: "Company name", key: "name" },
  companyCode: { label: "Company identifier", key: "code" },
  contactPerson: { label: "Contact person", key: "contact_person" },
  phoneNumber: { label: "Contact phone number", key: "telephone" },
  numberOfBookings: { label: "Number of bookings", key: "number_of_bookings" },
  numberOfPassengers: { label: "Number of passengers", key: "number_of_passengers" },
  numberOfProperties: { label: "Number of properties", key: "number_of_properties" },
  numberOfRooms: { label: "Number of rooms", key: "number_of_rooms" },
  description: { label: "General description of business", key: "description" },
  website: { label: "Company website", key: "website" },
  sourceKnowledge: { label: "How did you hear about us?", key: "source_knowledge_about_us" },
  operatorType: { label: "Operator type", key: "type" },
  operatorStatus: { label: "Operator status", key: "is_active", inactiveLabel: "Inactive", activeLabel: "Active" },
};

export const GENERAL_SECTION_ERROR_NAMES = ["is_active", "username", "email"];
