/* eslint-disable class-methods-use-this */
import { FileWrapper } from "domain/FileWrapper";
import { Operator } from "domain/Operator";
import { UserRole } from "domain/UserRole";
import { OperatorRequestDto } from "dto/OperatorRequestDto";

import { BaseMapper } from "./BaseMapper";
import { LabelMapper } from "./LabelMapper";
// eslint-disable-next-line import/no-cycle
import { UserMapper } from "./UserMapper";

export class OperatorMapper extends BaseMapper {
  constructor(labelMapper = new LabelMapper()) {
    super();
    this.labelMapper = labelMapper;
  }

  /**
   * @param {Operator} domain
   * @returns {OperatorRequestDto} OperatorRequestDto object
   */
  fromDomainToDto(domain) {
    const dto = new OperatorRequestDto();

    dto.name = domain.name;
    dto.is_active = domain.isActive;
    dto.default_language = domain.defaultLanguageCode;
    dto.meta = {
      creation_instant_view_link_label: domain.creationInstantViewLabel ?? "View this itinerary online with interactive maps and weather",
      creation_instant_view_maps_link_label: domain.creationInstantViewMapsLabel ?? "For further details, view the interactive map online",
      poisList: domain.poisList,
      temperature_scale: domain.temperatureScale,
      show_story_board_summary: domain.storyBoardView?.summary,
      show_story_board_daily: domain.storyBoardView?.details,
      contact_person: domain.contactPerson,
      telephone: domain.telephone,
      email: domain.email,
      number_of_bookings: domain.numberOfBookings,
      number_of_passengers: domain.numberOfPassengers,
      description: domain.description,
      website: domain.website,
      additional_languages: domain.additionalLanguageCodes,
      show_book_now_in_nested: domain.showBookNowInNested,
      show_refactor: domain.showRefactor,
    };

    dto.labels = domain.labels.map(label => this.labelMapper.fromDomainToDto(label));

    return dto;
  }

  /**
   * @param {OperatorResponseDto} dto - operator object
   * @returns {Operator} Operator item dto
   */
  fromDtoToDomain(dto) {
    const domain = new Operator();

    domain.id = dto.id;
    domain.code = dto.code;
    domain.name = dto.name;

    if (dto?.logo) {
      domain.logo = dto?.logo && FileWrapper(dto.logo);
    }
    if (dto?.creations_footer) {
      domain.creationsFooter = dto.creations_footer && FileWrapper(dto.creations_footer);
    }
    if (dto?.creations_header) {
      domain.creationsHeader = dto.creations_header && FileWrapper(dto.creations_header);
    }

    domain.usedCodes = dto.used_codes;
    domain.labels = dto?.labels?.map(labelDto => this.labelMapper.fromDtoToDomain(labelDto)) || [];
    domain.documents = dto.documents;
    domain.type = dto.type;
    domain.isActive = dto.is_active;
    domain.meta = dto.meta || {}; // For backward compatibility;
    domain.integrations = dto.integrations || {};
    domain.meta.poisList = dto.meta?.poisList === undefined ? true : dto.meta?.poisList; // Default value is true
    domain.createdAt = dto.created_at;
    domain.updatedAt = dto.updated_at;
    if (dto?.admins?.length) {
      domain.admins = dto.admins.map(adminDto => new UserMapper().fromDtoToDomain(adminDto));
    }
    domain.downloads = dto.downloads;
    domain.created = dto.created;
    domain.operatorCodes = dto.operator_codes;
    domain.termsAcceptedAt = dto.terms_accepted_at;
    domain.role = dto?.role && UserRole(dto.role);
    domain.roleId = dto.role_id;
    domain.overrides = dto.overrides;
    domain.permissions = dto.permissions;
    domain.userIsActive = dto.user_is_active;
    domain.userId = dto.user_id;

    domain.temperatureScale = dto.meta?.temperature_scale ? dto.meta?.temperature_scale : "C";
    domain.defaultLanguageCode = dto.default_language;
    domain.additionalLanguageCodes = dto.meta?.additional_languages;

    domain.storyBoardView = {
      summary: dto.meta?.show_story_board_summary,
      details: dto.meta?.show_story_board_daily,
    };

    domain.poisList = dto.meta?.poisList;
    domain.showBookNowInNested = dto.meta?.show_book_now_in_nested;
    domain.contactPerson = dto.meta?.contact_person;
    domain.telephone = dto.meta?.telephone;
    domain.email = dto.meta?.email;
    domain.numberOfBookings = dto.meta?.number_of_bookings;
    domain.numberOfPassengers = dto.meta?.number_of_passengers;
    domain.description = dto.meta?.description;
    domain.website = dto.meta?.website;

    domain.creationInstantViewLabel =
      dto.meta?.creation_instant_view_link_label ?? "View this itinerary online with interactive maps and weather";
    domain.creationInstantViewMapsLabel =
      dto.meta?.creation_instant_view_maps_link_label ?? "For further details, view the interactive map online";

    return domain;
  }
}
