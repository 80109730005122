import { HttpClient } from "services/application/httpClient/httpClient";

import { POI_URL, POI_DELETE_UPDATE_URL, POI_GET_URL } from "constants/api";
import { DEFAULT_PAGE_SIZE } from "constants/defaults";
import { setUrl } from "utils/url";
import { dispatch } from "store/store";
import { setNotification } from "store/app/actions";

export class PoiRepository {
  /**
   * @type {import("services/application/httpClient/httpClient").HttpClient}
   */
  httpClient;

  /**
   * @type {import("services/application/httpClient/httpClient").HttpClient}
   */

  constructor(httpClient = HttpClient) {
    this.httpClient = httpClient;
  }

  async fetchPoiList(page, rowsPerPage, countries, name, order, orderBy, locations, searchMode) {
    if (searchMode) {
      this.httpClient.cancelRequest();
    }
    const countryParam = countries ? countries.reduce((params, country) => `${params}&country=${country.key}`, "") : "";
    const nameParam = name ? `&name=${name}` : "";
    const locationsParam = locations || "";
    const url = `${POI_URL}?page=${page}&count=${rowsPerPage}${countryParam}${nameParam}${locationsParam}${
      order && orderBy ? `&order_by=${orderBy}&order=${order}` : ""
    }`;

    const { data } = await this.httpClient.get(url);

    return data;
  }

  async fetchAllPois() {
    const { data } = await this.httpClient.get(`${POI_URL}?page=1&count=${DEFAULT_PAGE_SIZE}`);

    return data;
  }

  async addNewPoi(payload) {
    try {
      const { data } = await this.httpClient.post(POI_URL, payload);
      return data;
    } catch (e) {
      const {
        response: { data },
      } = e;
      if (data.error.includes("already used for this country")) {
        dispatch(setNotification({ type: "error", message: "Cannot add POI. A POI with the same name and country already exists" }));
      } else {
        setNotification({ type: "error", message: "Something went wrong" });
      }
    }
  }

  async getPoi(id) {
    const url = setUrl(POI_GET_URL, { id });
    const { data } = await this.httpClient.get(url);

    return data;
  }

  async getPoisByLocations(locations) {
    if (locations.length === 0) {
      return [];
    }

    const locationsParam = locations
      .filter(location => location.latitude <= 90 && location.latitude > -90 && location.longitude <= 180 && location.longitude > -180)
      .reduce((acc, location) => {
        return `${acc}&location=${encodeURIComponent(`${location.latitude},${location.longitude}`)}`;
      }, "");

    const url = `${POI_URL}?page=1&count=${DEFAULT_PAGE_SIZE}${locationsParam}`;
    const {
      data: { items },
    } = await this.httpClient.get(url);

    return items || [];
  }

  async updateExistingPoi(id, payload) {
    const url = setUrl(POI_DELETE_UPDATE_URL, { id });
    const { data } = await this.httpClient.post(url, payload);

    return data;
  }

  async removeExistingPoi(id) {
    const url = setUrl(POI_DELETE_UPDATE_URL, { id });
    const { data } = await this.httpClient.delete(url);

    return data;
  }
}
