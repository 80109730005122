import { formFilePayloadObject } from "utils/library";

export const StoryboardEntryRequestDto = (domain, showDays, copyToOtherOperator = false) => {
  const dto = {};
  dto.headline = domain.headline;
  dto.meta = domain.meta;
  dto.content_type = domain.content_type || "text/html";

  if (!showDays && dto.meta.day_number !== undefined && dto.meta.day_number !== null) {
    delete dto.meta.day_number;
  }

  dto.position = domain.position;
  dto.tag = domain.tag;
  dto.content = domain.content?.replace(/^<span style="font-size: 1rem;">(.*)<\/span>$/, "$1");
  dto.details = domain.details;

  dto.documents = domain.documents.map(doc => {
    const {
      file_id,
      id: documentId,
      file_name,
      preview_url,
      library_node_id,
      web_url,
      https_url,
      s3_url,
      alias_for_id,
      is_library_file,
      type,
      ...restOfdocument
    } = doc;

    if (copyToOtherOperator && s3_url) {
      return { file_url: s3_url, name: file_name };
    }

    if (!copyToOtherOperator && alias_for_id) {
      return { library_node_id: alias_for_id, ...restOfdocument };
    }
    if (!copyToOtherOperator && !!is_library_file && library_node_id) {
      return { library_node_id, ...restOfdocument };
    }

    if (file_id) {
      return { file_id, ...restOfdocument };
    }

    return {
      web_url: web_url || https_url,
      ...restOfdocument,
    };
  });

  if (domain.image) {
    if (domain?.image?.file_meta?.attribution) delete domain.image.file_meta.attribution;

    dto.image = formFilePayloadObject(domain.image, copyToOtherOperator);
  }

  if (domain.location) {
    dto.location_internal_id = domain.location.id;
  }

  if (!dto.meta) {
    dto.meta = {};
  }

  dto.meta.restricted_to_traveller_internal_ids = domain.restricted_to_traveller_internal_ids;
  return dto;
};
