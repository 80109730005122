import React, { useState } from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";

import { Select } from "components/ui/Forms";
import { OPERATOR_TYPE } from "constants/content";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

const OperatorField = ({ onChange, name, vamoosId, ...props }) => {
  const permissionsService = usePermissionsService();
  const currentOperatorCode = useSelector(store => store.auth.currentOperatorCode);
  const operators = useSelector(store => store.auth.user.operators).filter(
    operator =>
      permissionsService.hasUserGrantedPermission(operator, PERMISSIONS.actions.create, PERMISSIONS.sections.vamoosList, vamoosId) &&
      operator.type !== OPERATOR_TYPE.admin,
  );

  const [operatorCode, setOperatorCode] = useState(currentOperatorCode);

  const handleSelect = event => {
    const { value } = event.target;

    setOperatorCode(value);
    onChange(event);
  };

  return (
    <>
      <Select onChange={handleSelect} value={operatorCode} name={name} {...props}>
        {operators.map(operator => (
          <MenuItem key={operator.id} value={operator.code}>
            {operator.code !== "ADMIN" ? `${operator.name} (${operator.code})` : operator.name}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

OperatorField.defaultProps = {
  name: "",
  vamoosId: null,
};

OperatorField.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  vamoosId: PropTypes.number,
};

export { OperatorField };
