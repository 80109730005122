import { APP_NAME } from "constants/app";

const {
  REACT_APP_API_URL_DEVELOPMENT,
  REACT_APP_API_URL_PRODUCTION,
  npm_package_version: NPM_PACKAGE_VERSION,
  REACT_APP_GOOGLE_MAPS_KEY,
  REACT_APP_TINYMCE_API_KEY,
  REACT_APP_WEB_VAMOOS_ENV,
  REACT_APP_CUSTOM_APP_API_URL_DEVELOPMENT,
  REACT_APP_CUSTOM_APP_API_URL_PRODUCTION,
  REACT_APP_IMSERT_API_URL_DEVELOPMENT,
  REACT_APP_IMSERT_API_URL_PRODUCTION,
} = process.env;

export default {
  name: APP_NAME,
  version: NPM_PACKAGE_VERSION,
  env: process.env.NODE_ENV,
  api: {
    development: REACT_APP_API_URL_DEVELOPMENT,
    production: REACT_APP_API_URL_PRODUCTION,
    test: REACT_APP_API_URL_DEVELOPMENT,
    customApp: {
      development: REACT_APP_CUSTOM_APP_API_URL_DEVELOPMENT,
      production: REACT_APP_CUSTOM_APP_API_URL_PRODUCTION,
    },
    imsert: {
      development: REACT_APP_IMSERT_API_URL_DEVELOPMENT,
      production: REACT_APP_IMSERT_API_URL_PRODUCTION,
    }
  },
  inputDateFormat: "DD/MM/YYYY",
  dateFormat: "DD MMM YYYY",
  storyboardDateFormat: "dddd, DD MMMM YYYY",
  apiDateFormat: "YYYY-MM-DD",
  timeFormat: "HH:mm",
  timePlaceholder: "HH:MM",
  dateTimeFormat: "DD MMM YYYY HH:mm",
  momentLocaleInputDateFormat: "L", // moment format, Month numeral, day of month, year -	09/04/1986
  global: {
    formSection: {
      defaultStickyHeader: {
        position: "top",
        offset: 128,
      },
    },
  },
  staysInfo: {
    prod: "https://stays.vamoos.com/",
    uat: "https://stays.uat.vamoos.com/",
  },
  vendors: {
    Google: {
      MAPS_KEY: REACT_APP_GOOGLE_MAPS_KEY,
      MAPS_URL: "https://maps.googleapis.com",
    },
    TinyMCE: {
      API_KEY: REACT_APP_TINYMCE_API_KEY,
    },
  },
  tables: {
    rowsPerPage: [10, 25, 50, 100],
    defaultNumberOfRows: 25,
  },
  modules: {
    pois: {
      ranges: [10, 30, 100, 300, 1000],
      defaultRange: 100,
    },
  },
  webVamoosEnv: REACT_APP_WEB_VAMOOS_ENV,
  webVamoos: {
    production: "https://web.vamoos.com",
    beta: "https://web-beta.vamoos.com",
    staging: "https://web-stg.vamoos.com",
    uat: "https://web-uat.vamoos.com",
  },
};
