import { getDirtyFields } from "utils/forms";

export const getPayload = ({ values, dirtyFields, bundle_id }) => {
  delete values.keyword;
  const { documents, screenshots, keywords, name_override, assignment_letter } = values;

  const androidS3Image = values?.android?.feature_image?.s3;

  return {
    ...getDirtyFields(values, dirtyFields),
    bundle_id,
    keywords: keywords || {},
    name_override,
    screenshots: {
      ...screenshots,
      list_of_screenshots: Array.isArray(screenshots.list_of_screenshots)
        ? screenshots.list_of_screenshots
        : screenshots.list_of_screenshots?.length
        ? screenshots.list_of_screenshots.split(",")
        : screenshots.list_of_screenshots,
    },

    documents: {
      ...(assignment_letter
        ? {
            ios_assignment_letter: assignment_letter.s3 || assignment_letter,
            android_assignment_letter: assignment_letter.s3 || assignment_letter,
          }
        : {}),
    },

    ios: {
      ...values.ios,
      short_description: values.short_description,
      full_description: values.full_description,
    },
    android: {
      ...values.android,
      short_description: values.short_description,
      full_description: values.full_description,
      ...(androidS3Image ? { feature_image: androidS3Image.preview || androidS3Image } : {}),
    },
  };
};
