import { ERRORS } from "constants/content";

export class TrackValidator {
  // eslint-disable-next-line class-methods-use-this
  validate(poiForm) {
    const errors = {};
    const { name, gpxFile } = poiForm;
    if (!name?.length) {
      errors.default = {
        name: ERRORS.isRequired("Name"),
      };
    }

    if (!gpxFile) {
      errors.gpxError = ERRORS.isRequired("GPX File");
    }

    return errors;
  }
}
