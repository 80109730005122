import { createContext } from "react";

export const SignUpContext = createContext({
  passwordConfirmation: "",
  user: {
    username: "",
    email: "",
    password: "",
  },
  operator: {
    code: "",
    name: "",
    terms_accepted_at: false,
    meta: {
      company_type: "",
      number_of_bookings: "",
      number_of_passengers: "",
      source_knowledge_about_us: "",
      contact_person: "",
      telephone: "",
      description: "",
      website: "",
    },
  },
  setPasswordConfirmation: () => {},
  setValueFor: () => {},
  handleChange: () => {},
});
