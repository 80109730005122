import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { CaptionText } from "components/ui/Typography/Typography";
import { Switch } from "components/ui/Forms/Switch";

import { CopyingTripModalListElement } from "feature/panel/Trips/Index/CopyingTripModal/CopyingTripModalListElement";

const List = styled.div`
  width: 100%;
  height: auto;
  margin-top: ${({ theme }) => theme.setSpacing(6)}px;
`;

const CopyingTripModalList = ({ state, sections, onChange }) => (
  <List>
    {sections.map(({ label, key }) => (
      <CopyingTripModalListElement key={key} onChange={() => onChange(key)} value={state[key]} label={label} elementIdentifier={key}>
        {key === "storyboard" && state.storyboard && (
          <Switch
            checked={state.storyboardDocuments}
            label={<CaptionText>Include documents:</CaptionText>}
            onChange={() => onChange("storyboardDocuments")}
            labelPlacement="start"
          />
        )}
      </CopyingTripModalListElement>
    ))}
  </List>
);

CopyingTripModalList.propTypes = {
  state: PropTypes.shape().isRequired,
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
};

export { CopyingTripModalList, List };
