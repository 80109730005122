import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";

import styles from "config/theme";

const Paragraph = styled.p`
  && {
    color: ${styles.colors.grey50};
    margin-right: ${({ theme }) => theme.setSpacing(8)}px;
  }
`;

const TextWithPlaceholder = ({ placeholder, value }) => (
  <Grid container spacing={3}>
    <Grid item xs={6}>
      <Paragraph>{`${placeholder}:`}</Paragraph>
    </Grid>
    <Grid item xs={6}>
      <span>{value}</span>
    </Grid>
  </Grid>
);

TextWithPlaceholder.propTypes = {
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
};

TextWithPlaceholder.defaultProps = {
  value: "",
};

export { TextWithPlaceholder, Paragraph };
