import { MESSAGING } from "constants/defaults";

const { USER_TYPES } = MESSAGING;

/*
  All possible types:
  
  'user-message',
  'notification',
  'email-response',
  'portal-response'
*/
const generateSenderTypeString = (type, name) => {
  switch (type) {
    case USER_TYPES.NOTIFICATION:
      return "Notification";
    case USER_TYPES.USER_MESSAGE:
      return name || "App user";
    default:
      return "User";
  }
};

export { generateSenderTypeString };
