import { useState } from "react";
import { useDispatch } from "react-redux";

import { isObjectEmpty } from "utils/object";
import { validate } from "utils/validators/payloadValidator";

import { setErrors } from "store/app/actions";
import { Logger } from "services/application/Logger";

const usePayloadValidator = module => {
  const [result, setResult] = useState(null);

  const dispatch = useDispatch();

  const checkValidation = payload => {
    const errorsObject = validate(payload, module);

    const validationResult = isObjectEmpty(errorsObject);
    setResult(validationResult);
    if (!validationResult) {
      dispatch(setErrors({ errors: errorsObject, module }));
    }

    Logger.debug("Validation result:", module, errorsObject);

    return validationResult;
  };

  return { result, checkValidation };
};

export { usePayloadValidator };
