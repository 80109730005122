import React from "react";
import Input from "components/ui/Inputs/TextInput";

const WebPage = ({ value, onChange }) => {
  return (
    <div style={{ margin: "15px 0" }}>
      <Input label="Paste the URL of any web page here" value={value} onChange={onChange} />
    </div>
  );
};

export default WebPage;
