import React from "react";
import { BodyText } from "components/ui/Typography/Typography";
import styled from "styled-components";

const NoResultsMessage = styled(({ height, ...rest }) => <BodyText {...rest} />)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    height: ${({ height }) => height || "calc(100vh - 360px)"};
    color: ${({ theme }) => theme.colors.grey50};
  }
`;

export { NoResultsMessage };
