import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import AddIcon from "@material-ui/icons/Add";
import { EditOutlined } from "@material-ui/icons";
import { useSelector } from "react-redux";
import uuidv4 from "uuid";

import { SecondaryButton } from "components/ui/Buttons";
import { StickyHeaderFormSection } from "components/ui/Forms";
import { DocumentsForm } from "feature/panel/Trips/_shared/Documents/DocumentsForm";
import { DocumentsList } from "feature/panel/Trips/_shared/Documents/DocumentsList";
import {
  CREATE_CONTENT_LABELS,
  EMPTY_LIST_MESSAGES_BASE,
  ERRORS,
  MODAL_TITLE_GENERATOR,
  NAMES_OF_RESOURCES_LISTS,
} from "constants/content";
import { NoResultsMessage } from "components/ui/Messages/NoResultsMessage";
import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { IconImage } from "components/ui/Icons";

import { useManageTripContext } from "feature/panel/Trips/_shared/ManageTripContext";

import { useDefaultLabelSettings } from "hooks/useDefaultLabelSettings";
import { firstLetterUpperCase } from "utils/string";
import { PERMISSIONS } from "constants/permissions";
import { usePermissionsService } from "hooks/usePermissionsService";

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const ActionLabel = styled.div`
  margin: 0 24px 0 20px;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
`;

const StyledConfirmationModal = styled(ConfirmationModal)`
  && {
    .MuiDialogContent-root {
      overflow-y: unset;
    }
  }
`;

const DocumentsContent = ({ onChange, list, name, title, icon, onListChange, onRemove, onFilesUpload, onAddDocument }) => {
  const permissionsService = usePermissionsService();
  const {
    travelDocumentsIcon,
    destinationDocumentsIcon,
    travelDocumentsTitle,
    destinationDocumentsTitle,
    editMode,
    vamoos_id,
    is_wiped,
  } = useManageTripContext();
  const [documentFormModal, setDocumentFormModal] = useState({
    open: false,
    body: {
      errors: {},
    },
  });
  const { availableIcons } = useSelector(state => state.operator);
  const conditionsByTypes = {
    destinationDocuments: !destinationDocumentsIcon || !destinationDocumentsTitle,
    travelDocuments: !travelDocumentsIcon || !travelDocumentsTitle,
  };
  const conditionToCompare = conditionsByTypes[name];

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoos_id);

  const [documentLabelAndIconSetting] = useDefaultLabelSettings(name, conditionToCompare);

  const handleAddDocument = () =>
    onAddDocument({
      id: uuidv4(),
      restricted_to_traveller_internal_ids: null,
      name: "",
    });

  const handleCancel = () => {
    setDocumentFormModal({
      open: false,
      body: {
        errors: {},
      },
    });
  };

  const handleConfirm = () => {
    Object.entries(documentFormModal.body).forEach(([key, value]) => {
      const newKey = firstLetterUpperCase(key);
      onChange(newKey, value);
    });
    setDocumentFormModal({
      open: false,
      body: {
        errors: {},
      },
    });
  };

  const onDocumentFormChange = (key, value) => {
    setDocumentFormModal({
      ...documentFormModal,
      body: {
        ...documentFormModal.body,
        [key]: value,
        errors: {
          ...(key === "title" && !value ? { title: ERRORS.cantBeBlank("Title") } : {}),
        },
      },
    });
  };

  const handleOpenModal = () => {
    setDocumentFormModal({
      open: true,
      body: {
        title: title || documentLabelAndIconSetting.label,
        icon: icon || documentLabelAndIconSetting.icon_id,
        errors: {},
      },
    });
  };
  const getAvailableIcon = icon_id => !!availableIcons && availableIcons.find(availableIcon => availableIcon.id === icon_id);

  const selectedIcons = icon ? getAvailableIcon(icon) : getAvailableIcon(Number(documentLabelAndIconSetting.icon_id));

  const isConfirmDisabled =
    (documentFormModal.body.Title === title && documentFormModal.body.Icon === icon) ||
    Object.keys(documentFormModal.body.errors).length > 0;

  const content = list.length ? (
    <DocumentsList list={list} name={name} onRemove={onRemove} onChange={onListChange} onFilesUpload={onFilesUpload} />
  ) : (
    !(editMode && !canEdit) && (
      <NoResultsMessage height="50px">
        {EMPTY_LIST_MESSAGES_BASE(CREATE_CONTENT_LABELS.add, NAMES_OF_RESOURCES_LISTS.document)}
      </NoResultsMessage>
    )
  );

  const travelDocumentsForm = (
    <ActionContainer>
      {!!selectedIcons && <IconImage src={selectedIcons.url} darkened />}
      <ActionLabel>{title || documentLabelAndIconSetting.label}</ActionLabel>
      {!is_wiped && !(editMode && !canEdit) && (
        <SecondaryButton onClick={handleOpenModal} cv="grey40" iconButton>
          <EditOutlined />
        </SecondaryButton>
      )}
    </ActionContainer>
  );

  return (
    <>
      <StickyHeaderFormSection
        editableTitle={travelDocumentsForm}
        headerActions={() =>
          !is_wiped &&
          !(editMode && !canEdit) && (
            <SecondaryButton onClick={handleAddDocument}>
              <AddIcon />
              {CREATE_CONTENT_LABELS.add}
            </SecondaryButton>
          )
        }
      >
        {content}
      </StickyHeaderFormSection>
      {documentFormModal.open && (
        <StyledConfirmationModal
          title={MODAL_TITLE_GENERATOR[name]}
          open={documentFormModal.open}
          onCancel={handleCancel}
          onConfirm={handleConfirm}
          confirmLabel="save"
          confirmDisabled={isConfirmDisabled}
          fullWidth
          maxWidth="sm"
        >
          <DocumentsForm
            title={documentFormModal.body.title}
            icon={documentFormModal.body.icon}
            errors={documentFormModal.body.errors}
            onChange={onDocumentFormChange}
            disabled={is_wiped || (editMode && !canEdit)}
          />
        </StyledConfirmationModal>
      )}
    </>
  );
};

DocumentsContent.defaultProps = {
  list: [],
  title: "",
  icon: "",
};

DocumentsContent.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onListChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onAddDocument: PropTypes.func.isRequired,
  onFilesUpload: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape()),
};

export { DocumentsContent };
