import React from "react";
import AddIcon from "@material-ui/icons/Add";

import { SecondaryButton } from "components/ui/Buttons";
import { useManageTripContext } from "feature/panel/Trips/_shared/ManageTripContext";
import { generateUniqueId } from "utils";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

const LocationsTabRightMenu = () => {
  const { setValueFor, locations, vamoos_id, editMode } = useManageTripContext();
  const permissionsService = usePermissionsService();

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoos_id);

  const handleAddLocation = () => {
    const id = generateUniqueId();
    setValueFor("locations", [
      ...locations,
      {
        id,
        name: "",
        location: "",
        coordinates: "",
        latitude: null,
        longitude: null,
        internal_id: id,
        on_weather: true,
        on_maps: true,
        isNewLocation: true,
      },
    ]);
  };

  return (editMode ? canEdit : true) && (
    <>
      <SecondaryButton onClick={handleAddLocation}>
        <AddIcon />
        ADD
      </SecondaryButton>
    </>
  );
};

export { LocationsTabRightMenu };
