import React from "react";
import { Route, Routes as RouterRoutes, HashRouter } from "react-router-dom";

import * as paths from "constants/routes";

import { PrivateView } from "components/_shared/PrivateView/PrivateView";

import { Terms } from "feature/views/Terms";
import { Contact } from "feature/views/Contact";
import { Home } from "feature/views/Home/Home";
import { Verify } from "feature/views/Verify";
import { Styleguide } from "feature/views/Styleguide/Styleguide";
import { SignUp } from "feature/views/SignUp/SignUp";
import { PasswordReset } from "feature/views/PasswordReset/PasswordReset";

import { TripsIndex } from "feature/panel/Trips/Index/TripsIndex";
import { StaysIndex } from "feature/panel/Stays/Index/StaysIndex";
import { Notifications } from "feature/panel/Notifications/Notifications";
import { Pois } from "feature/panel/Pois/Index/Pois";
import { IconManager } from "feature/panel/Pois/IconManager/IconManager";
import { Library } from "feature/panel/Library/Library";
import { PageNotFound } from "feature/views/PageNotFound/PageNotFound";
import { Faq } from "feature/views/FAQ";
import { Settings } from "feature/panel/Settings/Settings";
import { Help } from "feature/panel/Help/Help";
import { Accounts } from "feature/panel/Settings/Accounts/Accounts";
import { UserSettings } from "feature/panel/UserSettings/UserSettings";
import { PanelRoot } from "feature/panel/PanelRoot/PanelRoot";

import { OperatorsIndex } from "feature/admin/Operators/Index/OperatorsIndex";
import { OperatorsDetails } from "feature/admin/Operators/Details/OperatorsDetails";
import { UsersIndex } from "feature/admin/Users/Index/UsersIndex";
import { UserDetails } from "feature/admin/Users/Details/UserDetails";
import { FeaturesIndex } from "feature/admin/Features/Index/FeaturesIndex";
import { IconsIndex } from "feature/admin/Icons/Index/IconsIndex";
import { EditIcons } from "feature/admin/Icons/Edit/EditIcons";
import { EditFeature } from "feature/admin/Features/Edit/EditFeature";
import { ConnectIndex } from "feature/admin/Connect/Index/ConnectIndex";
import { CreateCustomApp } from "feature/panel/Settings/CustomApps/Create/CreateCustomApp";
import { CustomAppsEdit } from "feature/panel/Settings/CustomApps/Edit/CustomAppsEdit";
import { CustomApps } from "feature/panel/Settings/CustomApps/Index/CustomApps";
import PasscodesPage from "feature/panel/Settings/Passcodes";

import { StaysWizard } from "feature/panel/Stays/StaysWizard/StaysWizard";

import { General } from "feature/panel/Stays/_shared/General/General";
import { Actions } from "feature/panel/Stays/_shared/Actions/Actions";
import { Pois as StayPois } from "feature/panel/Stays/_shared/Pois/Pois";
import { Gallery } from "feature/panel/Stays/_shared/Gallery/Gallery";
import { Directories } from "feature/panel/Stays/_shared/Directories/Directories";
import { DailyActivities } from "feature/panel/Stays/_shared/DailyActivities/DailyActivities";
import { Vouchers } from "feature/panel/Stays/_shared/Vouchers/Vouchers";
import { Passcodes } from "feature/panel/Stays/_shared/Passcodes/Passcodes";
import { Notifications as StayNotifications } from "feature/panel/Stays/_shared/Notifications/Notifications";
import { StayInspirations } from "feature/panel/Stays/_shared/StayInspirations/StayInspirations";
import { DoNotDisturb } from "feature/panel/Stays/_shared/DoNotDisturb/DoNotDisturb";
import { Publisher } from "feature/panel/Stays/_shared/Publisher/Publisher";
import { StayShareAccess } from "feature/panel/Stays/_shared/StayShareAccess/StayShareAccess";
import { MessagingWithStaysContext } from "feature/panel/Stays/_shared/MessagingWithStaysContext";

import { MenusLabels } from "feature/panel/MenusLabels/MenusLabels";
import { General as TripGeneral } from "feature/panel/Trips/_shared/General/General";
import { Documents as TripDocuments } from "feature/panel/Trips/_shared/Documents/Documents";
import { Locations as TripLocations } from "feature/panel/Trips/_shared/Locations/Locations";
import { Storyboard as TripStoryboard } from "feature/panel/Trips/_shared/Storyboard/Storyboard";
import { Notifications as TripNotifications } from "feature/panel/Trips/_shared/Notifications/Notifications";
import { People as TripPeople } from "feature/panel/Trips/_shared/People/People";
import { Flights as TripFlights } from "feature/panel/Trips/_shared/Flights/Flights";
import { Inspirations as TripInspirations } from "feature/panel/Trips/_shared/Inspirations/Inspirations";
import { Creations as TripCreateCreations } from "feature/panel/Trips/_shared/Creations/Creations";
import { MessagingWithTripsContext as TripMessaging } from "feature/panel/Trips/_shared/MessagingWithTripsContext";
import { TripShareAccess } from "feature/panel/Trips/_shared/TripShareAccess/TripShareAccess";

import { Stays as SettingsStays } from "feature/panel/Settings/Stays/Stays";

import { Branding } from "feature/panel/Settings/Branding/Branding";
import { PERMISSIONS } from "constants/permissions";

import { General as CustomAppsGeneral } from "feature/panel/Settings/CustomApps/_shared/General/General";
import { LogoAndIcons } from "feature/panel/Settings/CustomApps/_shared/logoAandIcons.js/LogoAndIcons";
import { ElementsAndColors } from "feature/panel/Settings/CustomApps/_shared/elementsAndColors/ElementsAndColors";
import { LoginScreen } from "feature/panel/Settings/CustomApps/_shared/LoginScreen/LoginScreen";
import { MenuSubpage } from "feature/panel/Settings/CustomApps/_shared/Menu/MenuSubpage";
import { TextsPage } from "feature/panel/Settings/CustomApps/_shared/TextsPage/TextsPage";
import { AllScreens } from "feature/panel/Settings/CustomApps/_shared/AllScreens/AllScreens";
import { usePermissionsService } from "hooks/usePermissionsService";
import { UserGuide } from "feature/panel/Help/UserGuide";
import { Faq as HelpFaq } from "feature/panel/Help/Faq";
import { Terms as HelpTerms } from "feature/panel/Help/Terms/Terms";
import { ContactUs } from "feature/panel/Help/ContactUs/ContactUs";
import { VamoosApi } from "feature/panel/Help/VamoosApi/VamoosApi";

import { General as AdminOperatorGeneral } from "feature/admin/Operators/Details/General/General";
import { Statistics as AdminOperatorStatistics } from "feature/admin/Operators/Details/Statistics/Statistics";
import { CustomAppIndex } from "feature/admin/Operators/Details/customApps/CustomAppIndex";
import { InspirationsList } from "feature/panel/Inspirations/List/InspirationsList";
import { CreateInspiration } from "feature/panel/Inspirations/Create/CreateInspiration";
import { EditInspiration } from "feature/panel/Inspirations/Edit/EditInspiration";
import { General as GeneralSettings } from "feature/panel/Settings/General/General";
import { VersionsHistory } from "feature/panel/Settings/CustomApps/_shared/VersionsHistory/VersionsHistory";
import { TripWizard } from "feature/panel/Trips/TripWizard/TripWizard";
import RequestTracker from "feature/panel/Settings/RequestTracker";
import ItineraryRoot from "feature/panel/Itinerary";

import Information from "../feature/panel/Settings/CustomApps/_shared/Information/index";

const Routes = () => {
  const permissionsService = usePermissionsService();

  return (
    <RouterRoutes>
      <Route exact path={paths.ROOT_PATH} element={<Home />} />
      <Route exact path={paths.REGISTER_PATH} element={<SignUp />} />
      <Route exact path={paths.VERIFY_PATH} element={<Verify />} />
      <Route exact path={paths.STYLEGUIDE_PATH} element={<Styleguide />} />
      <Route exact path={paths.FAQ_PATH} element={<Faq />} />
      <Route exact path={paths.TERMS_PATH} element={<Terms />} />
      <Route exact path={paths.CONTACT_PATH} element={<Contact />} />
      <Route exact path={paths.PASSWORD_RESET_PATH} element={<PasswordReset />} />

      <Route
        path="/panel/:type/create/*"
        element={
          <PrivateView>
            <ItineraryRoot />
          </PrivateView>
        }
      />

      <Route
        path="/panel/itinerary/trip/edit/:operator_code/:reference_code/*"
        element={
          <PrivateView>
            <ItineraryRoot />
          </PrivateView>
        }
      />

      <Route
        path="/panel/itinerary/stay/edit/:reference_code/:operator_code/*"
        element={
          <PrivateView>
            <ItineraryRoot />
          </PrivateView>
        }
      />

      <Route exact path={paths.PANEL_ROOT_PATH} element={<PanelRoot />} />

      <Route
        exact
        path={paths.PANEL_TRIPS_ROOT_PATH}
        element={
          <PrivateView>
            <TripsIndex />
          </PrivateView>
        }
      />

      <Route
        path={`${paths.PANEL_TRIPS_ROOT_PATH}/*`}
        element={
          <PrivateView>
            <TripWizard />
          </PrivateView>
        }
      >
        <Route path="create" element={<TripGeneral />} />
        <Route path="create/documents" element={<TripDocuments />} />
        <Route path="create/locations" element={<TripLocations />} />
        <Route path="create/storyboard" element={<TripStoryboard />} />
        <Route path="create/notifications" element={<TripNotifications />} />
        <Route path="create/people" element={<TripPeople />} />
        <Route path="create/flights" element={<TripFlights />} />
        <Route path="create/inspirations" element={<TripInspirations />} />
        <Route path="create/creations" element={<TripCreateCreations />} />
        <Route path="create/messaging" element={<TripMessaging />} />
        <Route path="create/shareAccess" element={<TripShareAccess />} />

        <Route path=":operator_code/:reference_code/edit" element={<TripGeneral />} />
        <Route path=":operator_code/:reference_code/edit/documents" element={<TripDocuments />} />
        <Route path=":operator_code/:reference_code/edit/locations" element={<TripLocations />} />
        <Route path=":operator_code/:reference_code/edit/storyboard" element={<TripStoryboard />} />
        <Route path=":operator_code/:reference_code/edit/notifications" element={<TripNotifications />} />
        <Route path=":operator_code/:reference_code/edit/people" element={<TripPeople />} />
        <Route path=":operator_code/:reference_code/edit/flights" element={<TripFlights />} />
        <Route path=":operator_code/:reference_code/edit/inspirations" element={<TripInspirations />} />
        <Route path=":operator_code/:reference_code/edit/creations" element={<TripCreateCreations />} />
        <Route path=":operator_code/:reference_code/edit/messaging" element={<TripMessaging />} />
        <Route path=":operator_code/:reference_code/edit/shareAccess" element={<TripShareAccess />} />
      </Route>

      <Route
        exact
        path={paths.PANEL_STAYS_PATH}
        element={
          <PrivateView>
            <StaysIndex />
          </PrivateView>
        }
      />

      <Route
        path={`${paths.PANEL_STAYS_PATH}/*`}
        element={
          <PrivateView>
            <StaysWizard
              hasPermission={permissionsService.can(
                PERMISSIONS.actions.create,
                PERMISSIONS.sections.vamoosList,
                PERMISSIONS.resources.default,
              )}
            />
          </PrivateView>
        }
      >
        <Route path="create" element={<General />} />
        <Route path="create/actions" element={<Actions />} />
        <Route path="create/pois" element={<StayPois />} />
        <Route path="create/gallery" element={<Gallery />} />
        <Route path="create/directories" element={<Directories />} />
        <Route path="create/daily" element={<DailyActivities />} />
        <Route path="create/vouchers" element={<Vouchers />} />
        <Route path="create/passcodes" element={<Passcodes />} />
        <Route path="create/notifications" element={<StayNotifications />} />
        <Route path="create/inspirations" element={<StayInspirations />} />
        <Route path="create/do-not-disturb" element={<DoNotDisturb />} />
        <Route path="create/publisher" element={<Publisher />} />
        <Route path="create/messaging" element={<MessagingWithStaysContext />} />
        <Route path="create/shareAccess" element={<StayShareAccess />} />

        <Route path=":operator_code/edit" element={<General />} />
        <Route path=":operator_code/edit/actions" element={<Actions />} />
        <Route path=":operator_code/edit/pois" element={<StayPois />} />
        <Route path=":operator_code/edit/gallery" element={<Gallery />} />
        <Route path=":operator_code/edit/directories" element={<Directories />} />
        <Route path=":operator_code/edit/daily" element={<DailyActivities />} />
        <Route path=":operator_code/edit/vouchers" element={<Vouchers />} />
        <Route path=":operator_code/edit/passcodes" element={<Passcodes />} />
        <Route path=":operator_code/edit/notifications" element={<StayNotifications />} />
        <Route path=":operator_code/edit/inspirations" element={<StayInspirations />} />
        <Route path=":operator_code/edit/do-not-disturb" element={<DoNotDisturb />} />
        <Route path=":operator_code/edit/publisher" element={<Publisher />} />
        <Route path=":operator_code/edit/messaging" element={<MessagingWithStaysContext />} />
        <Route path=":operator_code/edit/shareAccess" element={<StayShareAccess />} />
      </Route>

      <Route
        path={`${paths.PANEL_LIBRARY_PATH}/*`}
        element={
          <PrivateView>
            <Library
              hasPermission={permissionsService.can(PERMISSIONS.actions.read, PERMISSIONS.sections.operator, PERMISSIONS.resources.library)}
            />
          </PrivateView>
        }
      />

      <Route
        path={paths.PANEL_NOTIFICATIONS_PATH}
        element={
          <PrivateView>
            <Notifications
              hasPermission={permissionsService.can(
                PERMISSIONS.actions.read,
                PERMISSIONS.sections.operator,
                PERMISSIONS.resources.notifications,
              )}
            />
          </PrivateView>
        }
      />

      <Route
        path={paths.PANEL_POIS_PATH}
        element={
          <PrivateView>
            <Pois />
          </PrivateView>
        }
      />

      <Route
        path={paths.PANEL_POIS_ICON_MANAGER_PATH}
        element={
          <PrivateView>
            <IconManager
              hasPermission={permissionsService.can(PERMISSIONS.actions.read, PERMISSIONS.sections.operator, PERMISSIONS.resources.icons)}
            />
          </PrivateView>
        }
      />

      <Route
        exact
        path={paths.PANEL_INSPIRATIONS_PATH}
        element={
          <PrivateView>
            <InspirationsList
              hasPermission={permissionsService.can(
                PERMISSIONS.actions.read,
                PERMISSIONS.sections.operator,
                PERMISSIONS.resources.inspirations,
              )}
            />
          </PrivateView>
        }
      />
      <Route
        exact
        path={paths.PANEL_INSPIRATIONS_CREATE_PATH}
        element={
          <PrivateView>
            <CreateInspiration
              hasPermission={permissionsService.can(
                PERMISSIONS.actions.create,
                PERMISSIONS.sections.operator,
                PERMISSIONS.resources.inspirations,
              )}
            />
          </PrivateView>
        }
      />
      <Route
        exact
        path={paths.PANEL_INSPIRATIONS_EDIT_PATH}
        element={
          <PrivateView>
            <EditInspiration
              hasPermission={permissionsService.can(
                PERMISSIONS.actions.read,
                PERMISSIONS.sections.operator,
                PERMISSIONS.resources.inspirations,
              )}
            />
          </PrivateView>
        }
      />

      <Route
        path={paths.PANEL_SETTINGS_ROOT}
        element={
          <PrivateView>
            <Settings />
          </PrivateView>
        }
      >
        <Route
          path={paths.PANEL_SETTINGS_COMPANY}
          element={
            <GeneralSettings
              hasPermission={permissionsService.can(
                PERMISSIONS.actions.read,
                PERMISSIONS.sections.operator,
                PERMISSIONS.resources.operators,
              )}
            />
          }
        />
        <Route path={paths.PANEL_SETTINGS_CONNECT} element={<SettingsStays />} />
      </Route>

      <Route
        path={paths.PANEL_SETTINGS_MENUS_LABELS}
        element={
          <PrivateView>
            <MenusLabels
              hasPermission={permissionsService.can(
                PERMISSIONS.actions.read,
                PERMISSIONS.sections.operator,
                PERMISSIONS.resources.operators,
              )}
            />
          </PrivateView>
        }
      />

      <Route
        path={paths.PANEL_SETTINGS_BRANDING}
        element={
          <PrivateView>
            <Branding
              hasPermission={permissionsService.can(
                PERMISSIONS.actions.read,
                PERMISSIONS.sections.operator,
                PERMISSIONS.resources.operators,
              )}
            />
          </PrivateView>
        }
      />

      <Route
        path={paths.PANEL_SETTINGS_ACCOUNTS}
        element={
          <PrivateView>
            <Accounts
              hasPermission={permissionsService.can(
                PERMISSIONS.actions.read,
                PERMISSIONS.sections.operator,
                PERMISSIONS.resources.operators,
              )}
            />
          </PrivateView>
        }
      />

      <Route
        exact
        path={paths.PANEL_SETTINGS_CUSTOM_APPS}
        element={
          <PrivateView>
            <CustomApps
              hasPermission={permissionsService.can(
                PERMISSIONS.actions.read,
                PERMISSIONS.sections.operator,
                PERMISSIONS.resources.operators,
              )}
            />
          </PrivateView>
        }
      />

      <Route
        exact
        path={paths.PANEL_SETTINGS_REQUEST_TRACKER}
        element={
          <PrivateView>
            <RequestTracker />
          </PrivateView>
        }
      />
      <Route
        path={paths.PANEL_SETTINGS_PASSCODES}
        element={
          <PrivateView>
            <PasscodesPage />
          </PrivateView>
        }
      />
      <Route
        path={`${paths.PANEL_SETTINGS_CUSTOM_APPS_CREATE}/*`}
        element={
          <PrivateView>
            <CreateCustomApp
              hasPermission={permissionsService.can(
                PERMISSIONS.actions.create,
                PERMISSIONS.sections.operator,
                PERMISSIONS.resources.operators,
              )}
            />
          </PrivateView>
        }
      >
        <Route exact path="general" element={<CustomAppsGeneral />} />
        <Route exact path="logo" element={<LogoAndIcons />} />
        <Route exact path="elements" element={<ElementsAndColors />} />
        <Route exact path="login" element={<LoginScreen />} />
        <Route exact path="menu" element={<MenuSubpage />} />
        <Route exact path="texts" element={<TextsPage />} />
        <Route exact path="preview" element={<AllScreens />} />
        <Route exact path="information" element={<Information />} />
      </Route>

      <Route
        path={`${paths.PANEL_CUSTOM_APPS_EDIT_PATH}/*`}
        element={
          <PrivateView>
            <CustomAppsEdit
              hasPermission={permissionsService.can(
                PERMISSIONS.actions.read,
                PERMISSIONS.sections.operator,
                PERMISSIONS.resources.operators,
              )}
            />
          </PrivateView>
        }
      >
        <Route exact path="general" element={<CustomAppsGeneral />} />
        <Route exact path="logo" element={<LogoAndIcons />} />
        <Route exact path="elements" element={<ElementsAndColors />} />
        <Route exact path="login" element={<LoginScreen />} />
        <Route exact path="menu" element={<MenuSubpage />} />
        <Route exact path="texts" element={<TextsPage />} />
        <Route exact path="preview" element={<AllScreens />} />
        <Route exact path="versions" element={<VersionsHistory />} />
        <Route exact path="information" element={<Information />} />
      </Route>

      <Route
        path={`${paths.PANEL_HELP_ROOT}/*`}
        element={
          <PrivateView>
            <Help />
          </PrivateView>
        }
      >
        <Route path="user-guide" element={<UserGuide />} />
        <Route path="faq" element={<HelpFaq />} />
        <Route path="terms" element={<HelpTerms />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="vamoos-api" element={<VamoosApi />} />
      </Route>

      <Route
        path={paths.PANEL_USER_SETTINGS}
        element={
          <PrivateView>
            <UserSettings />
          </PrivateView>
        }
      />

      <Route
        exact
        path={paths.ADMIN_OPERATORS_PATH}
        element={
          <PrivateView>
            <OperatorsIndex />
          </PrivateView>
        }
        requiredPermissions={[PERMISSIONS.actions.read, PERMISSIONS.sections.admin, PERMISSIONS.resources.operators]}
      />
      <Route
        path={`${paths.ADMIN_OPERATOR_DETAILS_PATH}/*`}
        element={
          <PrivateView>
            <OperatorsDetails />
          </PrivateView>
        }
        requiredPermissions={[PERMISSIONS.actions.read, PERMISSIONS.sections.admin, PERMISSIONS.resources.operators]}
      >
        <Route path="general" element={<AdminOperatorGeneral />} />
        <Route path="statistics" element={<AdminOperatorStatistics />} />
        <Route path="custom-app" element={<CustomAppIndex />} />
      </Route>

      <Route
        exact
        path={paths.ADMIN_USERS_PATH}
        element={
          <PrivateView>
            <UsersIndex />
          </PrivateView>
        }
        requiredPermissions={[PERMISSIONS.actions.read, PERMISSIONS.sections.admin, PERMISSIONS.resources.users]}
      />

      <Route
        exact
        path={paths.ADMIN_USER_DETAILS_PATH}
        element={
          <PrivateView>
            <UserDetails />
          </PrivateView>
        }
        requiredPermissions={[PERMISSIONS.actions.read, PERMISSIONS.sections.admin, PERMISSIONS.resources.users]}
      />

      <Route
        exact
        path={paths.ADMIN_FEATURES_PATH}
        element={
          <PrivateView>
            <FeaturesIndex />
          </PrivateView>
        }
        requiredPermissions={[PERMISSIONS.actions.read, PERMISSIONS.sections.admin, PERMISSIONS.resources.features]}
      />

      <Route
        exact
        path={paths.ADMIN_FEATURES_EDIT_PATH}
        element={
          <PrivateView>
            <EditFeature />
          </PrivateView>
        }
        requiredPermissions={[PERMISSIONS.actions.read, PERMISSIONS.sections.admin, PERMISSIONS.resources.features]}
      />

      <Route
        exact
        path={paths.ADMIN_CONNECT_PATH}
        element={
          <PrivateView>
            <ConnectIndex />
          </PrivateView>
        }
        requiredPermissions={[PERMISSIONS.actions.read, PERMISSIONS.sections.admin, PERMISSIONS.resources.stays]}
      />
      <Route
        exact
        path={paths.ADMIN_ICONS_PATH}
        element={
          <PrivateView>
            <IconsIndex />
          </PrivateView>
        }
        requiredPermissions={[PERMISSIONS.actions.read, PERMISSIONS.sections.admin, PERMISSIONS.resources.icons]}
      />
      <Route
        exact
        path={paths.ADMIN_EDIT_ICONS_PATH}
        element={
          <PrivateView>
            <EditIcons />
          </PrivateView>
        }
        requiredPermissions={[PERMISSIONS.actions.read, PERMISSIONS.sections.admin, PERMISSIONS.resources.icons]}
      />

      <Route path="*" element={<PageNotFound />} />
    </RouterRoutes>
  );
};

export default Routes;
