import { font } from "config/theme/fonts";
import styled from "styled-components";

export const Icon = styled.div`
  border-left: 1px solid #e8e8e9;
  height: 72px;
  width: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.colors.grey50};
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  span {
    font-weight: 700;
  }
`;
