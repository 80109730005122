import React from "react";
import { Wrap } from "./style";

const Label = ({ children, isFocused, value, style, error }) => {
  return (
    <Wrap isFocused={isFocused} style={style} value={value} error={error}>
      {children}
    </Wrap>
  );
};

export default Label;
