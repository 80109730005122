export const sortArrayOfObjects = (array, column, order) => {
  if (!column || !order) return array;
  return array.sort((a, b) => {
    const aValue = a[column];
    const bValue = b[column];

    // Check if the values are null or undefined
    const isANullOrUndefined = aValue === null || aValue === undefined;
    const isBNullOrUndefined = bValue === null || bValue === undefined;

    if (isANullOrUndefined && isBNullOrUndefined) {
      return 0;
    } else if (isANullOrUndefined) {
      return order === "asc" ? -1 : 1;
    } else if (isBNullOrUndefined) {
      return order === "asc" ? 1 : -1;
    }

    // Check if the values are numbers
    const isANumber = typeof aValue === "number";
    const isBNumber = typeof bValue === "number";

    if (isANumber && isBNumber) {
      return order === "asc" ? aValue - bValue : bValue - aValue;
    } else {
      // Convert both values to lowercase before performing string comparison
      const aString = String(aValue).toLowerCase();
      const bString = String(bValue).toLowerCase();

      if (order === "asc") {
        return aString.localeCompare(bString);
      } else if (order === "desc") {
        return bString.localeCompare(aString);
      } else {
        throw new Error("Invalid sorting order. Use 'asc' or 'desc'.");
      }
    }
  });
};

export const filterPOIs = (data, searchValue, selectedCountries) =>
  data?.filter(
    item =>
      item.name.toLowerCase().includes(searchValue?.toLowerCase()) &&
      (selectedCountries.length ? selectedCountries.find(country => country.key === item.countryIso) : true),
  );
