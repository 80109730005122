// @ts-check

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

import { PanelTemplate } from "components/templates/Panel/PanelTemplate";
import { PrimaryButton } from "components/ui/Buttons";
import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";

import { RolesWithUsersList } from "feature/panel/Settings/Accounts/RolesWithUsersList";
import UserActionsContext from "feature/panel/Settings/Accounts/UserActionsContext";
import { Navigation } from "feature/panel/Settings/Navigation";
import { ModifyUserModal } from "feature/panel/Settings/Accounts/ModifyUserModal";
import { validateUserCreate } from "feature/panel/Settings/Accounts/validateUserCreate";

import { OperatorService } from "services/OperatorService";

import { setNotification } from "store/app/actions";

import { ACCOUNT_PAGE_LABELS, CHANGES_SAVED_MESSAGE, PANEL_TRIPS_ACTION_MESSAGES } from "constants/content";
import { isObjectEmpty } from "utils/object";
import { sendDefaultApiErrorMessage } from "utils";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

const initialAccountFields = {
  email: "",
  role_id: "",
};

const Accounts = ({ hasPermission }) => {
  const permissionsService = usePermissionsService();

  const operatorService = new OperatorService();

  const [errors, setErrors] = useState({});
  const [operatorRoles, setOperatorRoles] = useState([]);
  const [operatorUsers, setOperatorUsers] = useState([]);
  const [modifyModal, setModifyModal] = useState({ open: false, body: {} });
  const [deleteModal, setDeleteModal] = useState({ open: false, body: {} });
  const dispatch = useDispatch();
  const handleToggleUserModify = user => {
    const { open } = modifyModal;
    const body = open ? modifyModal.body : user;
    setModifyModal({ body, open: !open });
  };

  const canUpdateOperator = permissionsService.can(
    PERMISSIONS.actions.update,
    PERMISSIONS.sections.operator,
    PERMISSIONS.resources.operators,
  );

  const handleToggleUserDelete = user => {
    const { open } = deleteModal;
    const body = open ? deleteModal.body : user;
    setDeleteModal({ body, open: !open });
  };

  const handleCreateNewAccount = () => handleToggleUserModify(initialAccountFields);

  const getOperatorUsers = async () => {
    try {
      const users = await operatorService.getUsers();

      setOperatorUsers(users.items);
    } catch (error) {
      sendDefaultApiErrorMessage(dispatch, error);
    }
  };

  const inviteOperatorUser = async () => {
    try {
      const status = await operatorService.inviteUser(modifyModal.body);
      if (status.toLowerCase() === "ok") {
        getOperatorUsers();
        dispatch(setNotification({ type: "success", message: CHANGES_SAVED_MESSAGE }));
      }
    } catch (error) {
      sendDefaultApiErrorMessage(dispatch, error);
    }
  };

  const cleanErrors = () => {
    setErrors({});
  };

  const updateOperatorUser = async () => {
    try {
      await operatorService.updateUserRole(modifyModal.body);

      const userNewRole = operatorRoles.find(role => role.id === modifyModal.body.role_id) || {};
      const users = operatorUsers.map(user => (user.id === modifyModal.body.id ? { ...user, role: userNewRole } : user));
      setOperatorUsers(users);
      dispatch(setNotification({ type: "success", message: CHANGES_SAVED_MESSAGE }));
    } catch (error) {
      sendDefaultApiErrorMessage(dispatch, error);
    }
  };

  const handleModalConfirm = () => {
    const formErrors = validateUserCreate(modifyModal.body);
    if (isObjectEmpty(formErrors)) {
      const isEditMode = !!modifyModal.body.id;
      const action = isEditMode ? updateOperatorUser : inviteOperatorUser;
      action();
      handleToggleUserModify();
    }
    setErrors(formErrors);
  };

  const handleValueChange = ({ target }) => { 
    setModifyModal({ open: true, body: { ...modifyModal.body, [target.name]: target.value } });
  };

  const getOperatorRoles = async () => {
    try {
      const roles = await operatorService.getRoles();
      setOperatorRoles(roles.items);
    } catch (error) {
      sendDefaultApiErrorMessage(dispatch, error);
    }
  };

  const handleDeleteUserConfirm = async () => {
    const { id } = deleteModal.body;

    try {
      await operatorService.revokeInvitedUser(id);

      const users = operatorUsers.filter(user => user.id !== id);
      setOperatorUsers(users);
      dispatch(setNotification({ type: "success", message: CHANGES_SAVED_MESSAGE }));
    } catch (error) {
      sendDefaultApiErrorMessage(dispatch, error);
    }
    handleToggleUserDelete();
  };

  const modalTitle = modifyModal.body.id ? ACCOUNT_PAGE_LABELS.editUser : ACCOUNT_PAGE_LABELS.assingNewUser;
  const confirmLabel = modifyModal.body.id ? ACCOUNT_PAGE_LABELS.save : ACCOUNT_PAGE_LABELS.assingUser;
  const contextValues = {
    onEditClick: handleToggleUserModify,
    onDeleteClick: handleToggleUserDelete,
  };
  const contextBar = {
    left: () => null,
    middle: Navigation,
    right: permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.operator, PERMISSIONS.resources.operators) && (
      <PrimaryButton onClick={handleCreateNewAccount}>
        <PersonAddIcon />
        {ACCOUNT_PAGE_LABELS.assingUser}
      </PrimaryButton>
    ),
  };

  useEffect(() => {
    getOperatorRoles();
  }, []);
  useEffect(() => {
    getOperatorUsers();
  }, []);
  useEffect(cleanErrors, [modifyModal.open]);

  return (
    <PanelTemplate contextBar={contextBar} hasPermission={hasPermission && canUpdateOperator}>
      <UserActionsContext.Provider value={contextValues}>
        <RolesWithUsersList roles={operatorRoles} users={operatorUsers} />
      </UserActionsContext.Provider>
      <ModifyUserModal
        onCancel={handleToggleUserModify}
        modifyModal={modifyModal}
        onConfirm={handleModalConfirm}
        title={modalTitle}
        confirmLabel={confirmLabel}
        onChange={handleValueChange}
        errors={errors}
        roles={operatorRoles}
      />
      {deleteModal.open && (
        <ConfirmationModal
          onCancel={handleToggleUserDelete}
          open={deleteModal.open}
          onConfirm={handleDeleteUserConfirm}
          title={`${PANEL_TRIPS_ACTION_MESSAGES.deleteConfirmationBase} user ${deleteModal.body.email || ""}?`}
          confirmLabel="delete"
        />
      )}
    </PanelTemplate>
  );
};

export { Accounts };
