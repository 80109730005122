import { getMediaPx } from "helpers";
import styled from "styled-components";

export const Wrap = styled.div`
  display: grid;
  align-items: center;
  width: 100%;
  height: 100%;
  grid-template-columns: 300px 1fr 200px;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    display: flex;
    justify-content: space-between;
    grid-template-columns: none;
    padding-right: 5px;
  }
`;

export const SearchWrap = styled.div`
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    position: absolute;
    background-color: #fff;
    width: ${p => (p.isOpened ? "calc(100vw - 15px)" : "120px")};
    transition: all 0.2s ease-in-out;
    z-index: 9;
  }
`;

export const MenuWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 35px;
  height: 100%;
  position: relative;
  a {
    height: 100%;
  }
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    gap: 15px;
    justify-content: flex-start;
    padding: 0 25px 0 15px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
