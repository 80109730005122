import React, { useEffect, useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Tooltip from "@mui/material/Tooltip";
import { colors } from "config/theme/colors";
import { copyToClipboard } from "utils";

const Expandable = ({ text, prefix }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  const onCopy = e => {
    e.stopPropagation();
    setIsCopied(true);
    copyToClipboard(text);
  };

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false);
      }, 5000);
    }
  }, [isCopied]);

  return (
    <div style={{ display: "flex", maxWidth: 800, background: colors.grey10, padding: 15, borderRadius: 8, gap: 15 }}>
      <div
        style={{
          whiteSpace: isOpen ? "normal" : "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          lineHeight: isOpen ? "23px" : "30px",
        }}
      >
        {prefix} {text}
      </div>
      <div>
        {isOpen ? (
          <KeyboardArrowUpIcon onClick={toggleOpen} sx={{ color: colors.grey40, cursor: "pointer", marginTop: 0.4 }} />
        ) : (
          <KeyboardArrowDownIcon onClick={toggleOpen} sx={{ color: colors.grey40, cursor: "pointer", marginTop: 0.4 }} />
        )}
        {isOpen && (
          <Tooltip title={isCopied ? "Copied!" : "Copy"}>
            <ContentCopyIcon onClick={onCopy} sx={{ color: colors.grey40, marginTop: 2, cursor: "pointer" }} />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default Expandable;
