import React from "react";
import { EntryForm } from "feature/panel/Trips/_shared/Storyboard/Entries/EntryForm/EntryForm";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { useManageTripContext } from "../../ManageTripContext";

const ListOfEntries = ({ list, onAddToLibrary, isNewEntryAdded, confirmScrollToNewEntry }) => {
  const { vamoos_id, is_wiped, editMode } = useManageTripContext();
  const permissionsService = usePermissionsService();
  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoos_id);
  return list.map((item, index) => (
    <EntryForm
      onAddToLibrary={onAddToLibrary}
      key={item.id}
      item={item}
      index={index}
      isNew={!!item?.isNew && isNewEntryAdded}
      scrollConfirm={confirmScrollToNewEntry}
      disabled={is_wiped || (editMode && !canEdit)}
    />
  ));
};

export { ListOfEntries };
