import AddIcon from "@mui/icons-material/Add";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import DnD from "components/_new/DnD_new";
import Input from "components/ui/Inputs/TextInput";
import TitleRow from "feature/panel/Itinerary/components/TitleRow";
import { Wrapper } from "feature/panel/Itinerary/style";
import { isValidEmail } from "helpers";
import React from "react";
import { useFieldArray } from "react-hook-form";
import uuidv4 from "uuid";

import OutlinedButton from "components/_new/Buttons/OutlinedButton";
import { BackButton } from "./Main/MessageArea/Top/style";
import UserListItem from "./UsersListItem";
import { Inputs } from "./style";

const Settings = ({ control, watch, onClose, disabled }) => {
  const filedName = "messages.meta.messaging_emails_list";

  const { fields, append, remove, replace, move } = useFieldArray({
    control,
    name: filedName,
    keyName: "user_id",
  });

  const members = watch(filedName);

  const shouldShowInputs = () => {
    const isOneDuringChecked = members?.some(item => item.during);
    if (!members?.length || !isOneDuringChecked) {
      return false;
    }
    // else {
    //   if (members.find(item => item.email && !isValidEmail(item.email))) return false;
    // }

    return true;
  };

  const onAdd = () => {
    append({ id: uuidv4(), email: "", before_after: true, during: true });
  };

  const onDragEnd = (_, { source, destination }) => move(source.index, destination.index);

  return (
    <>
      <Wrapper slimTopPadding>
        <TitleRow text="Set email address(es) to activate messaging" isSticky>
          <OutlinedButton iconOnlyMobile text="Add" startIcon={<AddIcon />} onClick={onAdd} disabled={disabled} />
        </TitleRow>

        <div style={{ height: 20 }} />

        {/* <TitleRow
          text="Set email address(es) to activate messaging"
          onClick={onAdd}
          startElement={
            <BackButton onClick={onClose}>
              <ArrowBackIosNewIcon />
            </BackButton>
          }
          buttonProps={{ iconOnlyMobile: true }}
          titleStyles={{ textAlign: "center", marginRight: 0 }}
          disabled={disabled}
        /> */}

        <DnD
          list={fields}
          disabled={disabled}
          element={<UserListItem control={control} filedName={filedName} onDelete={remove} disabled={disabled} />}
          onDragEnd={onDragEnd}
          contentStyle={{ padding: 0 }}
        />

        {shouldShowInputs() && (
          <>
            <div style={{ marginTop: 45 }}>Adjust dates when &quot;during&quot; email is active</div>
            <Inputs>
              <Input
                label="Days before start"
                type="number"
                name="general.meta.messaging_email_during_days_after"
                control={control}
                disabled={disabled}
                numberMinValue={0}
              />
              <Input
                label="Days after end"
                type="number"
                name="general.meta.messaging_email_during_days_before"
                control={control}
                disabled={disabled}
                numberMinValue={0}
              />
            </Inputs>
          </>
        )}
      </Wrapper>
    </>
  );
};

export default Settings;
