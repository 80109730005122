import React from "react";
import Dialog from "@mui/material/Dialog";
import Input from "components/ui/Inputs/TextInput";
import { Title } from "components/ui/Modals/ConfirmationModal";
import { GhostButton, PrimaryButton } from "components/ui/Buttons";
import { Alert } from "@material-ui/lab";
import { CircularProgress } from "@material-ui/core";

const inputs = [
  {
    key: 0,
    name: "name",
    label: "Name",
    rules: { required: true },
  },
  {
    key: 1,
    name: "user_name",
    label: "Username",
    isDisabled: isEditing => isEditing,
    isHidden: isEditing => !isEditing,
    rules: { required: true },
  },
  {
    key: 2,
    name: "email_address",
    label: "Email",
    rules: { required: true },
    type: "email",
  },
  {
    key: 3,
    name: "password",
    label: "Password",
    type: "password",
    isDisabled: isEditing => isEditing,
    isHidden: isEditing => isEditing,
    rules: { required: true },
  },
];

const RTModal = ({ control, onSubmit, modalData, open, onClose, isLoading, onCloseAlert, isEditing }) => {
  return (
    <Dialog open={open} onClose={onClose} transitionDuration={0.1}>
      <div style={{ padding: 25 }}>
        <Title style={{ marginBottom: 15 }}>{isEditing ? "Editing" : "Adding"} user</Title>
        <form onSubmit={onSubmit} style={{ width: 500 }} autoComplete="off">
          {inputs.map(
            item =>
              !item.isHidden?.(isEditing) && (
                <Input control={control} key={item.key} style={{ marginBottom: 25 }} disabled={item.isDisabled?.(isEditing)} {...item} />
              ),
          )}
          {modalData?.error && (
            <Alert style={{ marginBottom: "15px" }} onClose={onCloseAlert} severity="error">
              {modalData?.error}
            </Alert>
          )}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <PrimaryButton type="submit" loading={isLoading} disabled={isLoading}>
                {isEditing ? "Save" : "Add"}
                {isLoading && <CircularProgress color="#fff" size={15} />}
              </PrimaryButton>
            </div>
            <GhostButton onClick={onClose}>Cancel</GhostButton>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default RTModal;
