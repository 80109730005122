import React, { useState } from "react";
import PropTypes from "prop-types";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";

import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import PeopleOutlineOutlined from "@material-ui/icons/PeopleOutlineOutlined";
import AirplanemodeActiveOutlinedIcon from "@material-ui/icons/AirplanemodeActiveOutlined";
import PublicOutlinedIcon from "@material-ui/icons/PublicOutlined";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { LockOutlined } from "@material-ui/icons";

import {
  PANEL_TRIPS_CREATE_PATH,
  PANEL_TRIPS_CREATE_DOCUMENTS_PARAM,
  PANEL_TRIPS_CREATE_INSPIRATIONS_PARAM,
  PANEL_TRIPS_CREATE_CONTENT_PATH,
  PANEL_TRIPS_CREATE_STORYBOARD_PARAM,
  PANEL_TRIPS_CREATE_NOTIFICATIONS_PARAM,
  PANEL_TRIPS_CREATE_LOCATIONS_PARAM,
  PANEL_TRIPS_CREATE_PEOPLE_PARAM,
  PANEL_TRIPS_CREATE_FLIGHTS_PARAM,
  PANEL_TRIPS_CREATE_CREATIONS_PARAM,
  PANEL_TRIPS_CREATE_MESSAGING_PARAM,
  PANEL_TRIPS_SHARE_ACCESS_PARAM,
  PANEL_TRIPS_EDIT_CONTENT_PATH,
  PANEL_TRIPS_EDIT_PATH,
} from "constants/routes";
import { ContextNavigationList } from "components/ui/Lists/ContextNavigationList";

import { setUrl } from "utils/url";
import { checkSectionHasError } from "feature/panel/_shared/helpers";

import { GENERAL_SECTION_ERROR_NAMES } from "feature/panel/Trips/_shared/helpers";
import { ERASE_DATA_DIALOG, TRIP_WIZARD_CONTENT } from "constants/content";

import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-between;
`;

const TripWizardNavigation = ({ onEraseConfirm, isEditMode, vamoosId, isTripActive, isTripDeleted }) => {
  const [eraseModalOpen, setEraseModalOpen] = useState(false);
  const { errors } = useSelector(state => state.trips);
  const { pathname } = useLocation();
  const permissionsService = usePermissionsService();

  const { operator_code, reference_code } = useParams();

  const canReadNotifications = permissionsService.can(
    PERMISSIONS.actions.read,
    PERMISSIONS.sections.operator,
    PERMISSIONS.resources.notifications,
  );

  const canReadInpirations = permissionsService.can(
    PERMISSIONS.actions.read,
    PERMISSIONS.sections.operator,
    PERMISSIONS.resources.inspirations,
  );

  const canEditVamoos = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoosId);

  const normalizedPathname = pathname.replace(/\/$/, "");

  const list = [
    {
      url: isEditMode
        ? setUrl(PANEL_TRIPS_EDIT_CONTENT_PATH, { reference_code, operator_code, content: "" }, true)
        : PANEL_TRIPS_CREATE_PATH,
      label: TRIP_WIZARD_CONTENT.navigation.general,
      icon: <ListAltOutlinedIcon />,
      isActive: isEditMode
        ? normalizedPathname === setUrl(PANEL_TRIPS_EDIT_PATH, { operator_code, reference_code }, true).replace(/\/$/, "")
        : normalizedPathname === PANEL_TRIPS_CREATE_PATH.replace(/\/$/, ""),
      hasError: checkSectionHasError(GENERAL_SECTION_ERROR_NAMES, errors),
    },
    {
      url: isEditMode
        ? setUrl(PANEL_TRIPS_EDIT_CONTENT_PATH, { reference_code, operator_code, content: PANEL_TRIPS_CREATE_DOCUMENTS_PARAM }, true)
        : setUrl(PANEL_TRIPS_CREATE_CONTENT_PATH, { content: PANEL_TRIPS_CREATE_DOCUMENTS_PARAM }),
      label: TRIP_WIZARD_CONTENT.navigation.documents,
      icon: <DescriptionOutlinedIcon />,
      isActive: isEditMode
        ? normalizedPathname === setUrl(PANEL_TRIPS_EDIT_CONTENT_PATH, { operator_code, reference_code, content: "documents" }, true)
        : normalizedPathname === setUrl(PANEL_TRIPS_CREATE_CONTENT_PATH, { content: "documents" }),
      hasError: !!errors.documents,
      disabled: isTripDeleted,
    },
    {
      url: isEditMode
        ? setUrl(PANEL_TRIPS_EDIT_CONTENT_PATH, { reference_code, operator_code, content: PANEL_TRIPS_CREATE_LOCATIONS_PARAM }, true)
        : setUrl(PANEL_TRIPS_CREATE_CONTENT_PATH, { content: PANEL_TRIPS_CREATE_LOCATIONS_PARAM }),
      label: TRIP_WIZARD_CONTENT.navigation.locations,
      icon: <LocationOnOutlinedIcon />,
      isActive: isEditMode
        ? normalizedPathname === setUrl(PANEL_TRIPS_EDIT_CONTENT_PATH, { operator_code, reference_code, content: "locations" }, true)
        : normalizedPathname === setUrl(PANEL_TRIPS_CREATE_CONTENT_PATH, { content: "locations" }),
      hasError: !!errors.locations,
      disabled: isTripDeleted,
    },
    {
      url: isEditMode
        ? setUrl(PANEL_TRIPS_EDIT_CONTENT_PATH, { reference_code, operator_code, content: PANEL_TRIPS_CREATE_STORYBOARD_PARAM }, true)
        : setUrl(PANEL_TRIPS_CREATE_CONTENT_PATH, { content: PANEL_TRIPS_CREATE_STORYBOARD_PARAM }),
      label: TRIP_WIZARD_CONTENT.navigation.storyboard,
      icon: <DashboardOutlinedIcon />,
      isActive: isEditMode
        ? normalizedPathname === setUrl(PANEL_TRIPS_EDIT_CONTENT_PATH, { operator_code, reference_code, content: "storyboard" }, true)
        : normalizedPathname === setUrl(PANEL_TRIPS_CREATE_CONTENT_PATH, { content: "storyboard" }),
      hasError: !!errors.details,
      disabled: isTripDeleted,
    },
    ...(canReadNotifications
      ? [
          {
            url: isEditMode
              ? setUrl(
                  PANEL_TRIPS_EDIT_CONTENT_PATH,
                  { reference_code, operator_code, content: PANEL_TRIPS_CREATE_NOTIFICATIONS_PARAM },
                  true,
                )
              : setUrl(PANEL_TRIPS_CREATE_CONTENT_PATH, { content: PANEL_TRIPS_CREATE_NOTIFICATIONS_PARAM }),
            label: TRIP_WIZARD_CONTENT.navigation.notifications,
            icon: <NotificationsNoneOutlinedIcon />,
            isActive: isEditMode
              ? normalizedPathname ===
                setUrl(PANEL_TRIPS_EDIT_CONTENT_PATH, { operator_code, reference_code, content: "notifications" }, true)
              : normalizedPathname === setUrl(PANEL_TRIPS_CREATE_CONTENT_PATH, { content: "notifications" }),
            hasError: !!errors.notifications || !!errors?.gpsNotifications,
            disabled: isTripDeleted,
          },
        ]
      : []),
    {
      url: isEditMode
        ? setUrl(PANEL_TRIPS_EDIT_CONTENT_PATH, { reference_code, operator_code, content: PANEL_TRIPS_CREATE_PEOPLE_PARAM }, true)
        : setUrl(PANEL_TRIPS_CREATE_CONTENT_PATH, { content: PANEL_TRIPS_CREATE_PEOPLE_PARAM }),
      label: TRIP_WIZARD_CONTENT.navigation.people,
      icon: <PeopleOutlineOutlined />,
      isActive: isEditMode
        ? normalizedPathname === setUrl(PANEL_TRIPS_EDIT_CONTENT_PATH, { operator_code, reference_code, content: "people" }, true)
        : normalizedPathname === setUrl(PANEL_TRIPS_CREATE_CONTENT_PATH, { content: "people" }),
      hasError: !!errors.travellers,
      disabled: isTripDeleted,
    },
    {
      url: isEditMode
        ? setUrl(PANEL_TRIPS_EDIT_CONTENT_PATH, { reference_code, operator_code, content: PANEL_TRIPS_CREATE_FLIGHTS_PARAM }, true)
        : setUrl(PANEL_TRIPS_CREATE_CONTENT_PATH, { content: PANEL_TRIPS_CREATE_FLIGHTS_PARAM }),
      label: TRIP_WIZARD_CONTENT.navigation.flights,
      icon: <AirplanemodeActiveOutlinedIcon />,
      isActive: isEditMode
        ? normalizedPathname === setUrl(PANEL_TRIPS_EDIT_CONTENT_PATH, { operator_code, reference_code, content: "flights" }, true)
        : normalizedPathname === setUrl(PANEL_TRIPS_CREATE_CONTENT_PATH, { content: "flights" }),
      hasError: !!errors.flights,
      disabled: isTripDeleted,
    },
    ...(canReadInpirations
      ? [
          {
            url: isEditMode
              ? setUrl(
                  PANEL_TRIPS_EDIT_CONTENT_PATH,
                  { reference_code, operator_code, content: PANEL_TRIPS_CREATE_INSPIRATIONS_PARAM },
                  true,
                )
              : setUrl(PANEL_TRIPS_CREATE_CONTENT_PATH, { content: PANEL_TRIPS_CREATE_INSPIRATIONS_PARAM }),
            label: TRIP_WIZARD_CONTENT.navigation.inspirations,
            icon: <PublicOutlinedIcon />,
            isActive: isEditMode
              ? normalizedPathname ===
                setUrl(PANEL_TRIPS_EDIT_CONTENT_PATH, { operator_code, reference_code, content: "inspirations" }, true)
              : normalizedPathname === setUrl(PANEL_TRIPS_CREATE_CONTENT_PATH, { content: "inspirations" }),
            hasError: !!errors.inspirations,
            disabled: isTripDeleted,
          },
        ]
      : []),
    {
      url: isEditMode
        ? setUrl(PANEL_TRIPS_EDIT_CONTENT_PATH, { reference_code, operator_code, content: PANEL_TRIPS_CREATE_CREATIONS_PARAM }, true)
        : setUrl(PANEL_TRIPS_CREATE_CONTENT_PATH, { content: PANEL_TRIPS_CREATE_CREATIONS_PARAM }),
      label: TRIP_WIZARD_CONTENT.navigation.creations,
      icon: <GetAppOutlinedIcon />,
      isActive: isEditMode
        ? normalizedPathname === setUrl(PANEL_TRIPS_EDIT_CONTENT_PATH, { operator_code, reference_code, content: "creations" }, true)
        : normalizedPathname === setUrl(PANEL_TRIPS_CREATE_CONTENT_PATH, { content: "creations" }),
      disabled: isTripDeleted,
    },
    {
      url: isEditMode
        ? setUrl(PANEL_TRIPS_EDIT_CONTENT_PATH, { reference_code, operator_code, content: PANEL_TRIPS_CREATE_MESSAGING_PARAM }, true)
        : setUrl(PANEL_TRIPS_CREATE_CONTENT_PATH, { content: PANEL_TRIPS_CREATE_MESSAGING_PARAM }),
      label: TRIP_WIZARD_CONTENT.navigation.messaging,
      icon: <MailOutlineIcon />,
      isActive: isEditMode
        ? normalizedPathname === setUrl(PANEL_TRIPS_EDIT_CONTENT_PATH, { operator_code, reference_code, content: "messaging" }, true)
        : normalizedPathname === setUrl(PANEL_TRIPS_CREATE_CONTENT_PATH, { content: "messaging" }),
      hasError: !!errors.messaging,
      disabled: isTripDeleted,
    },
    {
      url: isEditMode
        ? setUrl(PANEL_TRIPS_EDIT_CONTENT_PATH, { reference_code, operator_code, content: PANEL_TRIPS_SHARE_ACCESS_PARAM }, true)
        : setUrl(PANEL_TRIPS_CREATE_CONTENT_PATH, { content: PANEL_TRIPS_SHARE_ACCESS_PARAM }),
      label: TRIP_WIZARD_CONTENT.navigation.shareAccess,
      icon: <LockOutlined />,
      isActive: isEditMode
        ? normalizedPathname === setUrl(PANEL_TRIPS_EDIT_CONTENT_PATH, { operator_code, reference_code, content: "shareAccess" }, true)
        : normalizedPathname === setUrl(PANEL_TRIPS_CREATE_CONTENT_PATH, { content: "shareAccess" }),
    },
  ];

  const handleEraseDialogToggle = () => setEraseModalOpen(!eraseModalOpen);
  const handleDeleteToggle = () => {
    onEraseConfirm();
    handleEraseDialogToggle();
  };

  const bottomList = [
    { divider: true },
    {
      action: handleEraseDialogToggle,
      icon: <DeleteOutlinedIcon />,
      label: TRIP_WIZARD_CONTENT.navigation.eraseData,
    },
  ];

  const eraseDataButton = isTripActive && <ContextNavigationList list={bottomList} />;

  return (
    <StyledWrapper>
      <ContextNavigationList list={list} />
      {canEditVamoos && eraseDataButton}
      {eraseModalOpen && (
        <ConfirmationModal
          open={eraseModalOpen}
          title={ERASE_DATA_DIALOG.title("itinerary")}
          description={ERASE_DATA_DIALOG.description("itinerary")}
          confirmLabel="Delete"
          onCancel={handleEraseDialogToggle}
          onConfirm={handleDeleteToggle}
        />
      )}
    </StyledWrapper>
  );

  // return <ContextNavigationList list={list} />;
};

TripWizardNavigation.defaultProps = {
  vamoosId: null,
};

TripWizardNavigation.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  onEraseConfirm: PropTypes.func.isRequired,
  isTripActive: PropTypes.bool.isRequired,
  isTripDeleted: PropTypes.bool.isRequired,
  vamoosId: PropTypes.number,
};

export { TripWizardNavigation };
