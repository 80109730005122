// @ts-check
import { isNumber } from "contracts/types";
import { ImageRequestDto } from "./ImageRequestDto";

/**
 * @param {Feature} feature  feature stay object
 * @returns {FeatureRequestDto} Feature stay item
 */

export function FeatureRequestDto(feature) {
  /**
   * @type {FeatureRequestDto}
   */
  const dto = {};

  if (feature?.id && isNumber(feature.id)) dto.id = feature.id;
  if (feature?.icon) dto.icon = ImageRequestDto(feature.icon);
  dto.name = feature.name;
  dto.is_active = feature.isActive;

  return dto;
}
