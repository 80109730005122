import React, { useState, useRef, useEffect } from "react";
import Select, { createFilter } from "react-select";
import Creatable from "react-select/creatable";
import { colors } from "config/theme/colors";
import { matchSorter } from "match-sorter";
import { Wrap, Label } from "./style";

const type = {
  creatable: <Creatable formatCreateLabel={val => val} menuPosition="fixed" />,
  classic: <Select />,
};

const SearchableSelect = ({
  label,
  value,
  creatable,
  onBlur,
  onFocus,
  options,
  placeholder,
  onChange,
  onTabPress,
  nextInputRef,
  disableSelectOnBlur,
  ...props
}) => {
  const [newValue, setNewValue] = useState(null);
  const [opt, setOpt] = useState(options);
  const [input, setInput] = useState();
  const [isInputFocused, setIsFocused] = useState(false);
  const ref = useRef();

  const handleFocus = () => {
    setIsFocused(true);
    ref.current.focus();
  };

  const handleBlur = e => {
    if (onBlur) onBlur(e);

    if (!disableSelectOnBlur) onChange(opt[0] || { label: input, value: input, __isNew__: true });

    if ((!value || opt[0]?.value === value.value) && !newValue && input) {
      onChange(newValue || opt[0] || { label: input, value: input, __isNew__: true });
      setNewValue(null);
    } else {
      onChange(value);
      setNewValue(null);
    }
    setIsFocused(false);
  };
  useEffect(() => {
    if (options) setOpt(matchSorter(options, "", { keys: ["value", "label"] }));
  }, [options]);

  useEffect(() => {
    setInput(null);
  }, [value]);

  return (
    <Wrap>
      <Label isFocused={isInputFocused} hasValue={value} onClick={handleFocus} style={{ zIndex: 4 }}>
        {label || "Label"}
      </Label>
      {React.cloneElement(type[creatable ? "creatable" : "classic"], {
        value,
        onChange: val => {
          onChange(val);
          setNewValue(val);
        },
        ref,
        onBlur: handleBlur,
        filterOptio: createFilter({ ignoreAccents: false }),
        onFocus: handleFocus,
        onKeyDown: e => {
          if (e.key === "Tab" && nextInputRef) {
            nextInputRef.current.focus();
          }
        },
        placeholder,
        onInputChange: inputValue => {
          setOpt(
            matchSorter(options, inputValue, {
              keys: [
                { key: "value", threshold: matchSorter.rankings[input ? "EQUALS" : "CONTAINS"] },
                { key: "label", threshold: matchSorter.rankings.CONTAINS },
              ],
            }),
          );
          setInput(inputValue);
          // if (value) onChange(null);
        },

        options: opt,
        styles: {
          container: provided => ({
            ...provided,
            zIndex: 3,
          }),
          valueContainer: provided => ({
            ...provided,
            height: 48,
          }),
          singleValue: provided => ({
            ...provided,
            height: 48,
            lineHeight: "48px",
          }),
          control: (provided, { isFocused }) => ({
            ...provided,
            border: `1px solid`,
            borderColor: isFocused ? `${colors.brand} !important` : "#C4C4C4 !important",
            boxShadow: "none",

            borderRadius: 10,
          }),
          option: (provided, { isSelected }) => ({
            ...provided,
            backgroundColor: isSelected ? colors.brand : "#fff",
          }),
          menu: provided => ({
            ...provided,
            zIndex: 3,
          }),
        },
        ...props,
      })}
      {/* <Select
        value={value}
        onChange={handleChange}
        options={options}
        onBlur={handleBlur}
        onFocus={handleFocus}
        placeholder=""
        styles={{
          valueContainer: provided => ({
            ...provided,
            height: 48,
          }),
          singleValue: provided => ({
            ...provided,
            height: 48,
            lineHeight: "48px",
          }),
          control: (provided, { isFocused }) => ({
            ...provided,
            border: `1px solid`,
            borderColor: isFocused ? `${colors.brand} !important` : "#C4C4C4 !important",
            boxShadow: "none",

            borderRadius: 10,
          }),
          option: (provided, { isSelected }) => ({
            ...provided,
            backgroundColor: isSelected ? colors.brand : "#fff",
          }),
          menu: provided => ({
            ...provided,
            zIndex: 3,
          }),
        }}
        {...props}
      /> */}
    </Wrap>
  );
};

export default SearchableSelect;
