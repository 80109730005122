import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Checkbox, Input, Switch } from "components/ui/Forms";
import { BodyText3 } from "components/ui/Typography/Typography";
import { Content } from "components/ui/Content";
import { Map } from "components/ui/Maps/Map";

import { CPFieldWrapper } from "feature/panel/_shared/CreationsAndPublishers/CPFieldWrapper";
import { ZoomControlButtons } from "feature/panel/Stays/_shared/Publisher/ZoomControlButtons";
import { formattedPosition } from "feature/panel/_shared/MapWidget_old/helpers";

import { PUBLISHER_CONTENT } from "constants/content";
import { useDispatch, useSelector } from "react-redux";
import { setPublisherModalValue } from "store/stays/actions";

const MapOptionsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.setSpacing(5)}px;
`;

const InputContainer = styled.div`
  flex-grow: 1;
  margin-right: ${({ theme }) => theme.setSpacing(10)}px;
`;

const SwitchMargined = styled(Switch)`
  && {
    margin-right: ${({ theme }) => theme.setSpacing(10)}px;
  }
`;

const GreySection = styled.div`
  display: inline-flex;
  align-items: flex-end;
  width: calc(100% - ${({ theme }) => theme.setSpacing(8)}px);
  padding: ${({ theme }) => theme.setSpacing(6)}px;
  margin-left: ${({ theme }) => theme.setSpacing(8)}px;
  background: ${({ theme }) => theme.colors.withOpacity(theme.colors.grey10, 0.3)};
`;

const MapContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: 46.76%;
  border-radius: ${({ theme }) => theme.setSpacing(2)}px;
  border: solid 1px ${({ theme }) => theme.colors.grey40};
  overflow: hidden;
`;

const FieldContainer = styled(CPFieldWrapper)`
  && {
    margin-right: 0;
  }
`;

const PublisherMap = styled(Map)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
`;

const PublisherMapSection = ({ label, name, defaultLat, defaultLng }) => {
  const dispatch = useDispatch();

  const map = useSelector(({ stays }) => stays.publisherModal.form[name]);
  const { show, headline, show_pois, zoom, latitude, longitude } = map;

  const handleMapShow = () => {
    dispatch(
      setPublisherModalValue({
        field: name,
        value: {
          ...map,
          show: !show,
        },
      }),
    );
  };

  const handlePoisShow = () => {
    dispatch(
      setPublisherModalValue({
        field: name,
        value: {
          ...map,
          show_pois: !show_pois,
        },
      }),
    );
  };

  const handleTitleChange = ({ target }) => {
    dispatch(
      setPublisherModalValue({
        field: name,
        value: {
          ...map,
          headline: target.value,
        },
      }),
    );
  };

  const handleZoomIncrease = () => {
    dispatch(
      setPublisherModalValue({
        field: name,
        value: {
          ...map,
          zoom: zoom + 1,
        },
      }),
    );
  };

  const handleZoomDecrease = () => {
    dispatch(
      setPublisherModalValue({
        field: name,
        value: {
          ...map,
          zoom: zoom - 1,
        },
      }),
    );
  };

  const handlePositionChange = (mapProps, mapObject) => {
    const lat = Number(formattedPosition(mapObject.center.lat()));
    const lng = Number(formattedPosition(mapObject.center.lng()));

    dispatch(
      setPublisherModalValue({
        field: name,
        value: {
          ...map,
          latitude: lat,
          longitude: lng,
        },
      }),
    );
  };

  const handleMapReadyState = (google, gmap) => {
    // eslint-disable-next-line no-param-reassign
    gmap.disableDefaultUI = true;
    // eslint-disable-next-line no-param-reassign
    gmap.scrollwheel = false;
    // eslint-disable-next-line no-param-reassign
    gmap.scaleControl = false;

    if (typeof latitude === "number" && typeof longitude === "number") {
      gmap.panTo({ lat: latitude, lng: longitude });
    } else {
      dispatch(
        setPublisherModalValue({
          field: name,
          value: {
            ...map,
            latitude: defaultLat,
            longitude: defaultLng,
          },
        }),
      );
      gmap.panTo({ lat: defaultLat, lng: defaultLng });
    }
  };

  const content = show && (
    <>
      <InputContainer>
        <Input label={`${label} title`} name={name} value={headline} onChange={handleTitleChange} />
      </InputContainer>

      <>
        <BodyText3>{PUBLISHER_CONTENT.showPois}</BodyText3>
        <SwitchMargined checked={show_pois} onChange={handlePoisShow} />
      </>
    </>
  );

  const mapElement = show && (
    <GreySection>
      <MapContainer>
        <PublisherMap onReady={handleMapReadyState} onDragend={handlePositionChange} zoom={zoom} />
      </MapContainer>
      <ZoomControlButtons
        onPlusClick={handleZoomIncrease}
        onMinusClick={handleZoomDecrease}
        disabledPlus={zoom === 22}
        disabledMinus={zoom === 0}
      />
    </GreySection>
  );

  return (
    <Content>
      <MapOptionsWrapper>
        <FieldContainer label={show ? "" : label} control={<Checkbox checked={show} onChange={handleMapShow} />} />
        {content}
      </MapOptionsWrapper>
      {mapElement}
    </Content>
  );
};

PublisherMapSection.defaultProps = {
  defaultLat: 0,
  defaultLng: 0,
};

PublisherMapSection.propTypes = {
  map: PropTypes.shape({
    show: PropTypes.bool,
    headline: PropTypes.string,
    show_pois: PropTypes.bool,
    show_labels: PropTypes.bool,
    zoom: PropTypes.number,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }).isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  defaultLat: PropTypes.number,
  defaultLng: PropTypes.number,
};

export { PublisherMapSection, MapOptionsWrapper, InputContainer, SwitchMargined, GreySection, MapContainer, FieldContainer };
