const checkIfMessageWasRead = (messagesList, lastReadTimestamp) => {
  if (!Array.isArray(messagesList) && !messagesList.length) {
    return false;
  }

  const lastMessage = messagesList[messagesList.length - 1];
  return lastMessage ? lastMessage.dateReceived >= lastReadTimestamp : false;
};

export { checkIfMessageWasRead };
