import { colors } from "config/theme/colors";
import { getMediaPx } from "helpers";
import styled from "styled-components";

export const Wrap = styled.div`
  padding: 0 25px;
  border-radius: 8px;
  background-color: ${colors.withOpacity(colors.grey10, 0.5)};
  height: 48px;
  color: ${p => (p.selected ? colors.brand : p.active ? "#000" : colors.grey40)};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${p => (p.disabled ? "not-allowed" : "pointer")};
  font-weight: 600;
  opacity: ${p => (p.disabled ? 0.4 : 1)};

  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    padding: 0;
    width: 48px;
  }
`;
