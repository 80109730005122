import React, { useState } from "react";
import { deviceType } from "utils/deviceDetect";
import { TabLabel, TabS, TabsS } from "./style";

const Tabs = ({
  setTab,
  tab,
  tabs = {},
  styleContainer,
  applyExtraTopPadding,
  variant = "scrollable",
  customStyles,
  customHandleChange,
}) => {
  const handleChange = (event, newValue) => {
    setTab(newValue);
    if (customHandleChange) customHandleChange();
  };

  return (
    <TabsS
      value={tab}
      onChange={handleChange}
      style={{
        ...styleContainer,
        ...(applyExtraTopPadding ? { paddingTop: 20 } : {}),
        position: "sticky",
        backgroundColor: "white",
        top: 0,
        zIndex: 10,
      }}
      aria-label="basic tabs"
      variant={variant}
      customStyles={customStyles}
    >
      {Object.entries(tabs).map(item => {
        return <TabS label={<TabLabel>{item[1].label}</TabLabel>} value={item[1].id} key={item[1].id} disabled={item[1].disabled} />;
      })}
    </TabsS>
  );
};

export default Tabs;
