import { colors } from "config/theme/colors";
import { getMediaPx } from "helpers";
import styled from "styled-components";

export const Wrap = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f8;
  border-left: 1px solid ${colors.grey10};
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-weight: 700;
  color: ${colors.grey50};
  cursor: ${p => (p.disabled ? "default" : "pointer")};
  &:hover {
    filter: ${p => (p.disabled ? "none" : "brightness(95%)")};
  }
  svg {
    color: ${colors.grey40};
  }
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    ${p => p.mobileStyle};
  }
  #content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: ${p => (p.disabled ? 0.4 : 1)};
    cursor: ${p => (p.disabled ? "not-allowed" : "pointer")};
  }
`;
