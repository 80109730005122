import { useEffect } from "react";
import { setTitleForDocumet } from "utils/hooks";

export const useTitle = title => {
  const setTitle = () => {
    document.title = setTitleForDocumet(title);
    return () => {
      document.title = setTitleForDocumet();
    };
  };

  useEffect(setTitle, [title]);
};
