import React from "react";
import { BodyText2 } from "components/ui/Typography/Typography";
import { Wrap } from "./style";

const data = [
  {
    key: 0,
    label: "Name",
    render: ({ name }) => name,
  },
  {
    key: 1,
    label: "Location",
    render: ({ location }) => location,
  },
  {
    key: 2,
    label: "Coordinates",
    render: ({ latitude, longitude }) => `${latitude}, ${longitude}`,
  },
];

const labels = {
  location: "Location",
  pois: "POI",
  route: "Route",
  poi: "POI",
  track: "Track",
};

const CommonInfoWindow = ({ item }) => {
  return (
    <div>
      <BodyText2 style={{ marginBottom: 5, fontSize: 14, textTransform: "uppercase" }}>
        <strong>{labels[item.type]}</strong>
      </BodyText2>
      {data.map(({ key, label, render }) => {
        return (
          <>
            <Wrap key={key}>
              <strong>{label}: </strong>
              <span>{render(item)}</span>
            </Wrap>
          </>
        );
      })}
    </div>
  );
};

export default CommonInfoWindow;
