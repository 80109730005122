import React from "react";
import { ContextBarTitle } from "components/templates/Panel/ContextBarTitle";
import OutlinedButton from "components/_new/Buttons/OutlinedButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "components/ui/Links/Link";

const DefaultTopSecondMenu = ({ title, actions, type, onCancel, canEdit, saveButton, isDirty }) => {
  const handleCancel = () => isDirty && onCancel && onCancel();

  return (
    <>
      <Link to={`/panel/${type === "stay" ? "stays" : "trips"}`} onClick={e => e.preventDefault()}>
        <OutlinedButton
          text={isDirty ? "Cancel" : "Go back"}
          startIcon={isDirty ? <CloseIcon /> : <ArrowBackIosIcon style={{ fontSize: 16 }} />}
          onClick={handleCancel}
          type="grey"
          noBorder
        />
      </Link>

      <ContextBarTitle title={title} />

      <div style={{ display: "flex", gap: 15 }}>
        {actions}
        {canEdit && saveButton}
      </div>
    </>
  );
};

export default DefaultTopSecondMenu;
