import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Spinner } from "components/ui/Spinner/Spinner";

const backgroundImage = bgImage => `
  background-image: url("${bgImage}");
  background-position: center;
  background-size: cover;
  position: relative;
`;

const blurredBgImage = bgImage => `
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    filter: blur(3px); 
    background-image: url("${bgImage}");
    background-position: center;
    background-size: cover;
  }
`;

const bgDimmed = (theme, bgImage) => `
  ${backgroundImage(bgImage)}
  
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${theme.colors.black};
    opacity: 0.5;
    z-index: 1;
  }
`;

const bottomScreenDimmed = (theme, bgImage) => `
  ${backgroundImage(bgImage)}

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(${theme.colors.white}00 49%, ${theme.colors.black} 99%);
    z-index: 1;
  }
`;

const PresentationContainer = styled.div`
  z-index: 10;
  position: relative;
  height: 100%;
  overflow: auto;
`;
const PhoneWrapper = styled.div`
  width: 252px;
  height: 545px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const PhonePreviewWrapper = styled.div`
  border-radius: 21px;
  overflow: hidden;
`;
const PhoneHeader = styled.div`
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ theme, fontColor }) => fontColor || theme.colors.black};
  min-height: 55px;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px 5px;
  z-index: 2;
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
  overflow: auto;
  max-height: 545px;
  z-index: 2;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ fontColor }) => fontColor};
  position: relative;

  ${({ bgImage, blurImage }) => {
    if (bgImage && !blurImage) {
      return backgroundImage(bgImage);
    } else if (bgImage && blurImage) {
      return blurredBgImage(bgImage);
    } else {
      return "";
    }
  }};
  ${({ bgImageDarken, theme }) => (bgImageDarken ? bgDimmed(theme) : "")}
  ${({ bgImageBottomDimming, theme }) => (bgImageBottomDimming ? bottomScreenDimmed(theme) : "")}
`;

const LeftSlot = styled.div`
  flex-basis: 20%;
  text-align: left;
  z-index: 2;
`;

const MiddleSlot = styled.div`
  text-align: center;
  z-index: 2;
`;
const RightSlot = styled.div`
  flex-basis: 20%;
  z-index: 2;
`;

const LoadingWrapper = styled.div`
  position: absolute;
  background: rgba(255, 255, 255, 0.3);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PhonePresentation = ({ loading, children }) => {
  return (
    <PhonePreviewWrapper>
      <PhoneWrapper>
        {loading && (
          <LoadingWrapper>
            <Spinner size={28} />
          </LoadingWrapper>
        )}
        {children}
      </PhoneWrapper>
    </PhonePreviewWrapper>
  );
};

PhonePresentation.defaultProps = {
  children: null,
  loading: false,
};

PhonePresentation.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.element,
};

export {
  PhonePresentation,
  PresentationContainer,
  PhonePreviewWrapper,
  PhoneWrapper,
  PhoneHeader,
  ContentWrapper,
  LeftSlot,
  MiddleSlot,
  RightSlot,
  backgroundImage,
  blurredBgImage,
  bgDimmed,
  bottomScreenDimmed,
};
