import styled from "styled-components";
import theme from "config/theme";

export const LoggingActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${theme.setSpacing(6)}px;
  .MuiFormControlLabel-root {
    flex-shrink: 0;
    margin-left: 0;
  }

  a {
    flex-shrink: 0;
  }
`;

export const AuthWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${theme.setSpacing(3)}px 0;
  flex-direction: column;
  gap: ${theme.setSpacing(2)}px;
`;

export const OrWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const OrText = styled.span`
  position: relative;
  text-align: center;
  flex: 0.3 0 auto;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    height: 1px;
    background-color: #d1d3d5;
    width: 100%;
  }

  &::before {
    right: 75%;
  }

  &::after {
    left: 75%;
  }
`;
