import React, { useState } from "react";
import Pill from "components/_new/Pill";
import { colors } from "config/theme/colors";
import OutlinedButton from "components/_new/Buttons/OutlinedButton";
import EditIcon from "@mui/icons-material/Edit";
import LanguageModal from "./languageModal";
import { Wrap } from "./style";
import LanguageSelector from "./languageSelector";

const TopSecondMenu = ({ menu, hasAdditionalMenu, ...rest }) => {
  return (
    <>
      <Wrap hasAdditionalMenu={hasAdditionalMenu}>{menu}</Wrap>
      {hasAdditionalMenu && <LanguageSelector {...rest} />}
    </>
  );
};

export default TopSecondMenu;
