import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";

import { BUNDLE_LIST_CUSTOM_APP_URL } from "constants/api";
import { CUSTOM_APPS, NO_RESULTS_FOUND_MESSAGE } from "constants/content";
import { PANEL_SETTINGS_CUSTOM_APPS_CREATE_GENERAL } from "constants/routes";
import { useTableHandlers } from "hooks/useTableHandlers";
import { getCustomAppsStart } from "store/customApps/actions";

import { Navigation } from "feature/panel/Settings/Navigation";
import { PanelTemplate } from "components/templates/Panel/PanelTemplate";
import { PrimaryButton } from "components/ui/Buttons";
import { NoResultsMessage } from "components/ui/Messages";
import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";

import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

import { CustomAppsTable } from "./CustomAppsTable";

const CustomApps = ({ hasPermission }) => {
  const permissionsService = usePermissionsService();

  const tableHandlers = useTableHandlers(BUNDLE_LIST_CUSTOM_APP_URL, getCustomAppsStart, {
    defaultOrderBy: "id",
    defaultOrder: "desc",
  });

  const { forceQuery } = tableHandlers;
  const navigate = useNavigate();
  const { currentOperatorCode } = useSelector(state => state.auth);
  const customApps = useSelector(state => state.customApps.customApps);
  const inProgress = useSelector(state => state.customApps.inProgress);
  const count = useSelector(state => state.customApps.count);

  const handleCreateApp = () => navigate(PANEL_SETTINGS_CUSTOM_APPS_CREATE_GENERAL);

  const canCreateCustomApp = permissionsService.can(
    PERMISSIONS.actions.create,
    PERMISSIONS.sections.operator,
    PERMISSIONS.resources.operators,
  );
  const canUpdateCustomApp = permissionsService.can(
    PERMISSIONS.actions.update,
    PERMISSIONS.sections.operator,
    PERMISSIONS.resources.operators,
  );

  const contextBar = {
    left: () => null,
    middle: Navigation,
    right: () =>
      canCreateCustomApp ? (
        <PrimaryButton onClick={handleCreateApp} id="add-directory-button">
          <AddIcon />
          {CUSTOM_APPS.createPageHeaders.title}
        </PrimaryButton>
      ) : null,
  };

  useEffect(forceQuery, [currentOperatorCode]);

  const content = () => {
    if (inProgress && !customApps.length) return <LoadingScreen />;
    if (!customApps.length) return <NoResultsMessage>{NO_RESULTS_FOUND_MESSAGE}</NoResultsMessage>;
    return <CustomAppsTable customApps={customApps} count={count} tableHandlers={tableHandlers} canEdit={canUpdateCustomApp} />;
  };

  return (
    <PanelTemplate contextBar={contextBar} hasPermission={hasPermission}>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          {content()}
        </Grid>
      </Grid>
    </PanelTemplate>
  );
};

export { CustomApps };
