import React from "react";
import MuiSwitch from "@mui/material/Switch";
import { ConditionalWrapper } from "components/ui/Inputs/conditionalWrapper";
import { Controller } from "react-hook-form";

const Switch = ({ label, checked, control, name, onChange, disabled, wrap, styleWrap = {}, handleChange }) => {
  const renderInput = ({ error, value, onChange, handleChange }) => (
    <div style={{ display: "flex", alignItems: "center", opacity: disabled ? 0.3 : 1, ...styleWrap }}>
      <div style={{ whiteSpace: wrap ? "normal" : "nowrap" }}>{label}</div>

      <MuiSwitch
        color="warning"
        label={label}
        checked={value}
        disabled={disabled}
        inputProps={{ "aria-label": "controlled" }}
        onChange={e => {
          onChange(e.target.checked);
          if (handleChange) handleChange(e.target);
        }}
        onClick={e => e.stopPropagation()}
      />
    </div>
  );

  return (
    <ConditionalWrapper
      condition={control}
      wrapper={() => (
        <Controller
          control={control}
          name={name}
          defaultValue={false}
          render={({ field: { onChange, value }, fieldState: { error } }) =>
            renderInput({
              error,
              onChange,
              value,
              handleChange,
            })
          }
        />
      )}
    >
      {renderInput({ onChange, value: checked, handleChange })}
    </ConditionalWrapper>
  );
};

export default Switch;
