import styled from "styled-components";
import MuiTabs from "@material-ui/core/Tabs";

const Tabs = styled(MuiTabs)`
  && {
    .MuiTabs-flexContainer {
      position: relative;

      :after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        position: absolute;
        background-color: ${({ theme }) => theme.colors.grey10};
        bottom: 0px;
        left: 0;
      }
    }
    .MuiTabs-indicator {
      background-color: ${({ theme }) => theme.colors.brand};
    }
  }
`;

export { Tabs };
