import React, { useContext } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";

import { EditAction, DeleteAction, TableActionsContainer } from "components/ui/Tables/TableRowActions";
import { BodyText } from "components/ui/Typography/Typography";
import UserActionsContext from "feature/panel/Settings/Accounts/UserActionsContext";
import { Chip } from "components/ui/Chips";
import { OPERATOR_USER, TABLE_ACTIONS_TITLES } from "constants/content";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { LightLink } from "components/ui/Links/Links";
import { setUrl } from "utils/url";
import { PANEL_STAYS_EDIT_SHARE_ACCESS, PANEL_TRIPS_EDIT_CONTENT_PATH, PANEL_TRIPS_SHARE_ACCESS_PARAM } from "constants/routes";

const User = styled.div`
  && {
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-template-rows: min-content min-content;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey10};
    width: 100%;

    :last-of-type {
      border: none;
    }
  }
`;

const CenteringBox = styled.div`
  display: flex;
  align-items: center;
  height: 72px;
`;

const InvitationStatusChip = styled(Chip)`
  && {
    height: ${({ theme }) => theme.setSpacing(6)}px;
    margin-left: ${({ theme }) => theme.setSpacing(6)}px;
  }
`;

const UserItinerariesPermissions = styled.div`
  padding: 0 0 22px 0;
`;

const OperatorUser = ({ user }) => {
  const permissionsService = usePermissionsService();
  const { onEditClick, onDeleteClick } = useContext(UserActionsContext);
  const { user: loggedUser } = useSelector(state => state.auth);

  const { id, email, is_skeletal, role, additionalVamoosAccess } = user;

  const handleEditClick = () => onEditClick({ id, email, role_id: role.id });
  const handleDeleteClick = () => onDeleteClick(user);

  const isUserMe = loggedUser && loggedUser.id === id;
  const actionButtonsTitle = isUserMe ? OPERATOR_USER.noOwnAccountManipulation : undefined;
  const invitationNotConfirmedLabel = is_skeletal && <InvitationStatusChip label={OPERATOR_USER.invitedLabel} />;

  return (
    <User>
      <CenteringBox>
        <BodyText>{email}</BodyText>
        {invitationNotConfirmedLabel}
      </CenteringBox>
      <CenteringBox title={actionButtonsTitle}>
        {!isUserMe && (
          <TableActionsContainer>
            {permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.operator, PERMISSIONS.resources.operators) && (
              <EditAction
                clickAction={handleEditClick}
                tooltip={TABLE_ACTIONS_TITLES.editResource(TABLE_ACTIONS_TITLES.resourceTypes.user)}
                noBackground
                noBorder
              />
            )}
            {permissionsService.can(PERMISSIONS.actions.delete, PERMISSIONS.sections.operator, PERMISSIONS.resources.operators) && (
              <DeleteAction
                clickAction={handleDeleteClick}
                tooltip={TABLE_ACTIONS_TITLES.deleteResource(TABLE_ACTIONS_TITLES.resourceTypes.user)}
                noBackground
                noBorder
              />
            )}
          </TableActionsContainer>
        )}
      </CenteringBox>
      {additionalVamoosAccess?.length > 0 && (
        <UserItinerariesPermissions>
          {additionalVamoosAccess.map(({ operator_code, reference_code }, index) => (
            <>
              {index !== 0 && ", "}
              {reference_code === "DEFAULT" ? (
                <LightLink
                  to={setUrl(PANEL_STAYS_EDIT_SHARE_ACCESS, {
                    operator_code,
                  })}
                >
                  {`${operator_code}`}
                </LightLink>
              ) : (
                <LightLink
                  to={setUrl(PANEL_TRIPS_EDIT_CONTENT_PATH, {
                    operator_code,
                    reference_code,
                    content: PANEL_TRIPS_SHARE_ACCESS_PARAM,
                  })}
                >
                  {`${operator_code} - ${reference_code}`}
                </LightLink>
              )}
            </>
          ))}
        </UserItinerariesPermissions>
      )}
    </User>
  );
};

OperatorUser.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    is_skeletal: PropTypes.bool,
    role: PropTypes.shape({ id: PropTypes.number }).isRequired,
    additionalVamoosAccess: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
};

export { OperatorUser, User, CenteringBox, InvitationStatusChip };
