import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import OutlinedButton from "components/_new/Buttons/OutlinedButton";
import Input from "components/ui/Inputs/TextInput";
import { useForm } from "react-hook-form";
import BaseModal from "components/_new/ModalBase";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HttpClient } from "services/application/httpClient/httpClient";
import { useParams } from "react-router-dom";

import { BackButton, Description, DescriptionWrap, Title, Wrap } from "./style";

const Top = ({ onClick, device_type, friendly_name, conversation, watch, disabled }) => {
  const queryClient = useQueryClient();

  const [open, setOpen] = useState(false);

  const { operator_code, reference_code } = useParams();

  const { control, reset, handleSubmit } = useForm();

  const { mutate, isLoading } = useMutation(
    body => HttpClient.post(`/itinerary/${operator_code}/${reference_code}/messaging/${conversation}`, body),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: ["chatList"] });
        await queryClient.invalidateQueries({ queryKey: ["messages"] });
        reset({ friendly_name: "" });
        setOpen(false);
      },
    },
  );

  const field1 = watch("general.field1");
  const field3 = watch("general.field3");

  const toggleOpen = () => setOpen(!open);

  useEffect(() => {
    reset({ friendly_name: friendly_name || device_type });
  }, [open]);

  return (
    <Wrap>
      {open && (
        <BaseModal title="Edit conversation name" onConfirm={handleSubmit(mutate)} onCancel={toggleOpen} isLoading={isLoading}>
          <Input autoFocus control={control} name="friendly_name" rules={{ required: true }} style={{ marginTop: 15 }} />
        </BaseModal>
      )}
      <Title>
        <BackButton onClick={onClick}>
          <ArrowBackIosIcon />
        </BackButton>
        {friendly_name || device_type}
        {!disabled && (
          <OutlinedButton startIcon={<EditIcon />} type="grey" style={{ marginLeft: 15 }} onClick={toggleOpen} iconOnlyMobile />
        )}
      </Title>
      <DescriptionWrap>
        <Description>
          <span>Trip: </span>
          {field1} {field3}
        </Description>
        <Description>
          <span>Device type: </span>
          {device_type}
        </Description>
      </DescriptionWrap>
    </Wrap>
  );
};

export default Top;
