import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { GhostButton, PrimaryButton } from "components/ui/Buttons";
import { CONTACT_PATH, TERMS_PATH, FAQ_PATH, REGISTER_PATH, ROOT_PATH, PASSWORD_RESET_PATH } from "constants/routes";
import { NavigationItem } from "components/public/Navigation/NavigationItem";

const NavigationSection = styled.nav`
  width: 100%;
  margin-top: ${({ theme }) => theme.setSpacing(10)}px;
  display: flex;
  justify-content: space-around;
`;

const NAVIGATION_ITEMS_LIST = [
  { redirectUrl: CONTACT_PATH, label: "contact" },
  { redirectUrl: TERMS_PATH, label: "terms" },
  { redirectUrl: FAQ_PATH, label: "faq" },
  { redirectUrl: REGISTER_PATH, label: "sign up" },
  { redirectUrl: ROOT_PATH, label: "log in" },
];

const Navigation = () => {
  const { pathname } = useLocation();
  const navigationItems = NAVIGATION_ITEMS_LIST.map(({ redirectUrl, label }) => {
    const isActive = redirectUrl === pathname || (redirectUrl === ROOT_PATH && pathname === PASSWORD_RESET_PATH);
    const buttonElement = isActive ? <PrimaryButton>{label}</PrimaryButton> : <GhostButton cv="white">{label}</GhostButton>;
    return (
      <NavigationItem to={redirectUrl} key={redirectUrl}>
        {buttonElement}
      </NavigationItem>
    );
  });
  return <NavigationSection>{navigationItems}</NavigationSection>;
};

export { NavigationSection, Navigation };
