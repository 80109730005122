import { takeEvery, put } from "redux-saga/effects";

import { handleErrorMessages } from "utils/store";
import { FORGOT_PASSWORD_URL } from "constants/api";
import { PASSWORD_RESET } from "constants/content";
import { HttpClient } from "services/application/httpClient/httpClient";

import {
  getAdminUsersSuccess,
  getAdminUsersFail,
  resetUserPasswordPush,
  resetUserPasswordFinish,
  getAdminUsersOperatorsSuccess,
} from "store/adminUsers/actions";
import * as actionTypes from "store/adminUsers/actionTypes";
import { setNotification } from "store/app/actions";

import notifications from "constants/notifications";
import { OperatorService } from "services/OperatorService";
import { UserMapper } from "mappers/UserMapper";

function* getAdminUsers({ payload }) {
  const userMapper = new UserMapper();
  const { url, searchMode } = payload;
  if (searchMode) {
    HttpClient.cancelRequest();
  }

  try {
    const response = yield HttpClient.get(url);

    if (response) {
      const { items, total_matches } = response.data;
      yield put(
        getAdminUsersSuccess({
          items: items.map(user => userMapper.fromDtoToDomain(user)),
          count: total_matches,
        }),
      );
    }
  } catch (e) {
    if (!HttpClient.isCancelError(e)) {
      yield put(getAdminUsersFail({ errors: handleErrorMessages(e) }));
    }
  }
}

function* resetUserPassword({ payload }) {
  const { id, email } = payload;

  try {
    yield put(resetUserPasswordPush({ id }));
    const response = yield HttpClient.post(FORGOT_PASSWORD_URL, { email });
    if (response) yield put(setNotification({ type: "success", message: PASSWORD_RESET.sendSuccess }));
  } catch {
    yield put(setNotification({ type: "error", message: PASSWORD_RESET.noAssociationError }));
  } finally {
    yield put(resetUserPasswordFinish({ id }));
  }
}

function* getAdminUsersOperatorsItems({ payload }) {
  const operatorService = new OperatorService();

  const { url } = payload;

  const data = yield operatorService.getAdminOperatorList(url);
  try {
    if (data) {
      const { items } = data;
      yield put(
        getAdminUsersOperatorsSuccess({
          operators: items,
        }),
      );
    }
  } catch (e) {
    if (!HttpClient.isCancelError(e)) {
      yield put(setNotification({ type: "error", message: notifications.resource("operators").get.fail }));
    }
  }
}

export function* adminUsersSaga() {
  yield takeEvery(actionTypes.GET_ADMIN_USERS_START, getAdminUsers);
  yield takeEvery(actionTypes.RESET_USER_PASSWORD_START, resetUserPassword);
  yield takeEvery(actionTypes.GET_ADMIN_USERS_OPERATORS, getAdminUsersOperatorsItems);
}
