import React from "react";
import { Grid } from "@material-ui/core";
import { useLocation } from "react-router-dom";

import { BgColorOtherPages } from "./BgColorOtherPages";
import { AccentColorBox } from "./AccentColor/AccentColorBox";
import { ButtonColorsSetupCard } from "./ButtonColorsSetupCard";
import { TextFieldsProperties } from "./TextFieldsProperties";

const ElementsAndColors = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isHistoryVersion = queryParams.get("versionHistory");

  return (
    <Grid>
      <Grid>
        <BgColorOtherPages isHistoryVersion={isHistoryVersion} />
      </Grid>
      <Grid>
        <AccentColorBox isHistoryVersion={isHistoryVersion} />
      </Grid>
      <Grid>
        <ButtonColorsSetupCard isHistoryVersion={isHistoryVersion} />
      </Grid>
      <Grid>
        <TextFieldsProperties isHistoryVersion={isHistoryVersion} />
      </Grid>
    </Grid>
  );
};

export { ElementsAndColors };
