import React, { useState } from "react";
import { PanelTemplate } from "components/templates/Panel/PanelTemplate";
import { Navigation } from "feature/panel/Settings/Navigation";
import { Grid } from "@material-ui/core";
import { PrimaryButton } from "components/ui/Buttons";
import { useForm } from "react-hook-form";
import { useMutation, useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { HttpClient } from "services/application/httpClient/httpClient";
import { Table, TableHeader, TableBody, TablePagination } from "components/ui/Tables";
import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { dispatch } from "store/store";
import { setNotification } from "store/app/actions";
import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";
import { ContentWrapper } from "../_shared/styledComponents";
import RTModal from "./modal";
import TableActions from "./tableActions";
import GreyAreaPlaceholder from "../../../../components/ui/GreyAreaPlaceholder/index";

const columns = [
  { label: "Name", key: "name", show: true },
  { label: "Username", key: "user_name", show: true },
  { label: "E-Mail", key: "email_address", show: true },
];

const defaultValues = {
  name: "",
  user_name: "",
  email_address: "",
  password: "",
};

const RequestTracker = () => {
  const queryClient = useQueryClient();

  const { control, handleSubmit, reset } = useForm({ defaultValues });
  const [modalData, setModalData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [userToDelete, setUserToDelete] = useState(null);
  const { mutate: activate, isLoading: isActivating } = useMutation(
    () => {
      return HttpClient.post(`/request_tracker/activate`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["rtUsers"] });
      },
    },
  );
  const { mutate: postUser, isLoading } = useMutation(
    body => {
      return HttpClient.post(`/request_tracker/users`, [body]);
    },
    {
      onSuccess: () => {
        dispatch(setNotification({ type: "success", message: isEditing ? "User edited" : "User added" }));
        setShowModal(false);
        reset(defaultValues);
        queryClient.invalidateQueries({ queryKey: ["rtUsers"] });
      },
      onError: err => {
        setModalData({ error: err.response.data.error });
      },
    },
  );
  const { mutate: deleteUser, isLoading: isDeleteLoading } = useMutation(
    () => {
      return HttpClient.delete(`/request_tracker/users/${userToDelete.email_address}`);
    },
    {
      onSuccess: () => {
        dispatch(setNotification({ message: "User was deleted successfully", type: "success" }));
        queryClient.invalidateQueries({ queryKey: ["rtUsers"] });
        setIsDeleting(false);
        setUserToDelete(null);
      },
    },
  );

  const { data, isLoading: usersLoading, isFetching, isRefetching: isUsersRefetching, fetchNextPage } = useInfiniteQuery({
    queryKey: ["rtUsers", rowsPerPage],
    queryFn: ({ pageParam = 1, queryKey }) => {
      return HttpClient.get(`/request_tracker/users?page=${pageParam}&count=${queryKey[1]}`);
    },
    getNextPageParam: (lastPage, pages) => lastPage.data.page + 1,
  });

  const pages = data?.pages || [];
  const users = pages[pages.length - 1]?.data?.items;
  const currentPage = pages[pages.length - 1]?.data?.page;
  const isActive = users?.length > 0;

  const handleOpenModal = () => setShowModal(true);

  const contextBar = {
    left: () => null,
    middle: Navigation,
    right: () =>
      isActive && (
        <PrimaryButton style={{ marginBottom: 15 }} onClick={handleOpenModal}>
          Add User
        </PrimaryButton>
      ),
  };

  const onSubmit = values => postUser(values);

  const handleCloseModal = () => {
    setShowModal(false);
    setIsEditing(false);
    reset(defaultValues);
  };
  const handleCloseAlert = () => setModalData(null);
  const handleOpenDelete = user => {
    setIsDeleting(true);

    setUserToDelete(user);
  };
  const handleCloseDelete = () => setIsDeleting(false);
  const handleEdit = user => {
    setIsEditing(true);
    handleOpenModal();
    reset(user);
  };

  const renderActions = ({ item }) => <TableActions item={item} handleEdit={handleEdit} handleDelete={handleOpenDelete} />;

  return (
    <PanelTemplate contextBar={contextBar}>
      <ConfirmationModal
        open={isDeleting}
        onCancel={handleCloseDelete}
        onConfirm={deleteUser}
        title={`Are you sure you want to delete ${userToDelete?.name}?`}
        showSpinner={isDeleteLoading || isUsersRefetching}
      />
      <RTModal
        control={control}
        onSubmit={handleSubmit(onSubmit)}
        modalData={modalData}
        open={showModal}
        onClose={handleCloseModal}
        onCloseAlert={handleCloseAlert}
        isLoading={isLoading || isUsersRefetching}
        isEditing={isEditing}
      />
      <Grid container>
        <ContentWrapper style={{ width: 960 }}>
          {isActive ? (
            <Table
              headers={columns}
              list={users || []}
              actions={renderActions}
              isDataLoading={usersLoading || isFetching}
              onChangePage={page => fetchNextPage({ pageParam: page + 1 })}
              onChangeRowsPerPage={rows => setRowsPerPage(rows)}
            >
              <TableHeader withActions />
              <TableBody />
              <TablePagination count={pages[pages.length - 1]?.data?.total_matches || 0} page={currentPage - 1} />
            </Table>
          ) : usersLoading ? (
            <LoadingScreen />
          ) : (
            <GreyAreaPlaceholder>Request Tracker integration has not been activated yet</GreyAreaPlaceholder>
          )}
          {!isActive && !usersLoading && (
            <div style={{ display: "flex", justifyContent: "center", marginTop: 15 }}>
              <PrimaryButton onClick={activate} disabled={users?.length > 0 || usersLoading || isActivating}>
                {isActivating ? "Loading" : "Activate now"}
              </PrimaryButton>
            </div>
          )}
        </ContentWrapper>
      </Grid>
    </PanelTemplate>
  );
};

export default RequestTracker;
