import React, { useState } from "react";
import PropTypes from "prop-types";
import config from "config/app";

import { DEFAULT_LIBRARY_TABS, LibraryPopup } from "components/ui/LibraryPopup/LibraryPopup";
import { StoryboardFile } from "domain/StoryboardFile";

import { OVERRIDE_VAMOOS_ID_ITINERARY_URL } from "constants/api";
import { HttpClient } from "services/application/httpClient/httpClient";
import { setUrl } from "utils/url";

import { StoryboardComponent } from "domain/StoryboardComponent";

import { StayOverride } from "domain/StayOverride";
import { useForm } from "react-hook-form";

const AddFromLibrary = ({ libraryOpen, toggleShowLibrary, onSelectToStoryboard, onSelectPublicStay, onSelectFlight, tripStartDate }) => {
  const defaultValues = {
    carrier_code: null,
    carrier_flight_number: "",
  };
  const form = useForm({ defaultValues });

  const [showManualForm, setShowManualForm] = useState(false);

  const handleLibraryClose = () => {
    setShowManualForm(false);
    form.reset(defaultValues);
    toggleShowLibrary(false);
  };

  const getFileData = async fileUrl => {
    const { data } = await HttpClient.get(fileUrl);

    return StoryboardComponent(data);
  };

  const getOverritedStay = async vamoosId => {
    const url = setUrl(OVERRIDE_VAMOOS_ID_ITINERARY_URL, { vamoos_id: vamoosId });
    const { data } = await HttpClient.get(url);

    return StayOverride(data);
  };

  const onSelectFileFromLibrary = async files => {
    files
      .filter(file => file.type === StoryboardFile.FILE_TYPE)
      .forEach(async file => {
        const component = await getFileData(file.https_url);
        onSelectToStoryboard(component);
      });

    toggleShowLibrary(false);
  };

  const handlePublicStay = async stay => {
    const currentStay = await getOverritedStay(stay.vamoosId);
    onSelectPublicStay(currentStay);
  };

  const handleFlights = async flightData => {
    await onSelectFlight({
      fs: flightData.airlineCode,
      number: flightData.flightNumber,
      date: flightData.noDateFormat ? flightData.flightDate : flightData.flightDate.format(config.apiDateFormat),
      selectedFlights: flightData.selectedFlights,
    });
  };

  return (
    <div>
      <LibraryPopup
        label="Add to storyboard"
        open={libraryOpen}
        onCancel={handleLibraryClose}
        onSelect={onSelectFileFromLibrary}
        onSelectStays={handlePublicStay}
        showManualForm={showManualForm}
        setShowManualForm={setShowManualForm}
        form={form}
        flightSectionConfig={{
          tripStartDate,
          onSelectFlight: handleFlights,
        }}
        allowedFileTypes={[StoryboardFile.FILE_TYPE]}
        tabs={[DEFAULT_LIBRARY_TABS.library, DEFAULT_LIBRARY_TABS.stay, DEFAULT_LIBRARY_TABS.flights]}
      />
    </div>
  );
};

AddFromLibrary.defaultProps = {
  onSelectToStoryboard: () => {},
  onSelectPublicStay: () => {},
  onSelectFlight: () => {},
};

AddFromLibrary.propTypes = {
  libraryOpen: PropTypes.bool.isRequired,
  toggleShowLibrary: PropTypes.func.isRequired,
  onSelectToStoryboard: PropTypes.func,
  onSelectPublicStay: PropTypes.func,
  onSelectFlight: PropTypes.func,
  tripStartDate: PropTypes.string.isRequired,
};

export { AddFromLibrary };
