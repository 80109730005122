import styled from "styled-components";
import { Paper as PaperMui } from "@material-ui/core";
import { LeadText } from "components/ui/Typography/Typography";

export const ContentWrapper = styled.div`
  max-width: 960px;
  margin: auto;
`;

export const Paper = styled(PaperMui)`
  && {
    max-width: 4000px;
    margin: auto;
    padding: ${({ theme }) => theme.setSpacing(10)}px ${({ theme }) => theme.setSpacing(10)}px;
  }
`;

export const FieldValueWrapper = styled.div`
  display: flex;
  align-items: center;

  *:first-of-type {
    margin-right: ${({ theme }) => theme.setSpacing(3)}px;
  }
`;

export const Header = styled(LeadText)`
  && {
    margin-bottom: ${({ theme }) => theme.setSpacing(5)}px;
  }
`;
