import { ADMIN_OPERATORS_PATH, PANEL_SETTINGS_CUSTOM_APPS_CREATE, PANEL_STAYS_PATH, PANEL_TRIPS_ROOT_PATH } from "constants/routes";
import { isString } from "contracts/types";

const checkRedirectHappensInsideVamoosBuilder = rootPathToCompare => (currentUrl, redirectUrl) => {
  if (!isString(rootPathToCompare) || !isString(currentUrl) || !redirectUrl) return false;

  const redirectPath = isString(redirectUrl) ? redirectUrl : redirectUrl.pathname;

  const isUserInsideBuilder = currentUrl.includes(rootPathToCompare) && currentUrl !== rootPathToCompare;
  const isUserNavigatingInsideBuilder = redirectPath.includes(rootPathToCompare) && redirectPath !== rootPathToCompare;
  return isUserInsideBuilder && isUserNavigatingInsideBuilder;
};

const isInternalTripRedirect = checkRedirectHappensInsideVamoosBuilder(PANEL_TRIPS_ROOT_PATH);
const isInternalStayRedirect = checkRedirectHappensInsideVamoosBuilder(PANEL_STAYS_PATH);
const isInternalAdminOperatorRedirect = checkRedirectHappensInsideVamoosBuilder(ADMIN_OPERATORS_PATH);
const isInternalCustomAppRedirect = checkRedirectHappensInsideVamoosBuilder(PANEL_SETTINGS_CUSTOM_APPS_CREATE);

export { 
  checkRedirectHappensInsideVamoosBuilder, 
  isInternalStayRedirect, 
  isInternalTripRedirect ,
  isInternalAdminOperatorRedirect,
  isInternalCustomAppRedirect
}
