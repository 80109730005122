import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { TableCell } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

import { useTableContext } from "components/ui/Tables/TableContext";
import { ActionTableCell } from "components/ui/Tables/TableRow";
import { TextLimiter } from "components/ui/TextLimiter";
import { StyledMuiTableRow } from "components/ui/Tables/_shared/styledComponents";
import { TableActionsContainer } from "components/ui/Tables/TableRowActions";

import { setUrl, setParams, setUrlParams } from "utils/url";
import { CA_VERSIONS_HISTORY } from "constants/content";
import { DEFAULT_CELL_MAX_CHARACTERS } from "constants/defaults";

import { firstLetterUpperCase } from "utils/string";
import config from "config/app";

import {
  CUSTOM_APP_STATUS_ACCEPTED,
  CUSTOM_APP_STATUS_APPROVED,
  CUSTOM_APP_STATUS_DRAFT,
} from "../constants/customAppStatus";

const VersionsHistoryTableRow = ({ item }) => {
  const navigate = useNavigate();
  const { url, urlParams, actions: Actions, headers } = useTableContext();

  const renderCell = header => {
    const { key } = header;

    let content = null;
    switch (key) {
      case "created_at": {
        content = () => moment(item.createdAt).format(config.dateTimeFormat);
        break;
      }
      case "updated_at": {
        content = () => moment(item.updatedAt).format(config.dateTimeFormat);
        break;
      }
      case "status": {
        content = () => {
          if (item.status === CUSTOM_APP_STATUS_APPROVED) {
            return firstLetterUpperCase(CUSTOM_APP_STATUS_ACCEPTED);
          }

          return firstLetterUpperCase(item.status);
        };
        break;
      }
      default: {
        content = () => <TextLimiter maxChar={DEFAULT_CELL_MAX_CHARACTERS} text={`${item[header.key]}`} />;
      }
    }

    return <TableCell key={header.key}>{content()}</TableCell>;
  };

  const cellsToRender = headers.filter(header => header.show).map(header => renderCell(header));
  const handleEditVersion = () => {
    const versionUrl = setUrlParams(setUrl(url, setParams(item, urlParams)), { versionHistory: "true", version: item.sequenceNumber });
    navigate(versionUrl, { replace: true });
  };
  return (
    <StyledMuiTableRow item={item}>
      {cellsToRender}
      <ActionTableCell align="right">
        <TableActionsContainer>
          <Actions
            onClick={handleEditVersion}
            isActive={item.status !== CUSTOM_APP_STATUS_DRAFT}
            tooltip={CA_VERSIONS_HISTORY.editPreviousVersion}
          />
        </TableActionsContainer>
      </ActionTableCell>
    </StyledMuiTableRow>
  );
};

VersionsHistoryTableRow.propTypes = {
  item: PropTypes.shape().isRequired,
};

export { VersionsHistoryTableRow };
