import React, { useState, useMemo } from "react";
import Tabs from "components/_new/Tabs";
import { Wrapper } from "feature/panel/Itinerary/style";
import MapWidget from "feature/panel/_shared/MapWidget";
import { useFieldArray, useForm } from "react-hook-form";
import uuidv4 from "uuid";
import { setLocationsFilter } from "utils/dataConverters";
import { useQuery } from "@tanstack/react-query";
import { useTableHandlers } from "hooks/useTableHandlers";
import ListOfLocations from "./ListOfLocations";
import LocationPois from "./Pois";
import { createSearch, getTabs } from "./helpers";

// eslint-disable-next-line
let trvId = 0, trvCache = {};
const uuidToTrvId = uuid => trvCache[uuid] ?? (trvCache[uuid] = ++trvId);

const Locations = ({ form: { control, watch, setValue, formState: { errors }, trigger }, canEdit, isWiped }) => {
  const [tab, setTab] = useState("locations");

  const name = "locations.locations";
  const poisName = "locations.pois";

  const { fields: locations, append, update, remove, move } = useFieldArray({
    control,
    name,
    keyName: "rf_id",
  });
  const watchLocations = watch(name);
  const editMode = false; // change in future
  const pois = watch(poisName) || []; // in future pois that comes with itinerary

  const locationsHasFile = watchLocations?.find(item => item.file);

  const settings = locations.length > 0 ? { center: { lat: locations[0].latitude, lng: locations[0].longitude }, zoom: 4 } : {};
  const noLocationsWithValidCoordinates = !setLocationsFilter(locations);
  const nestedPublicStays = [...locations].filter(location => location.nested).map(location => location.nested.vamoos_id);
  const { rowsPerPage, page, handleChangePage, handleChangeRowsPerPage } = useTableHandlers();

  const { data: { items: allPois, total_matches = 0 } = [] } = useQuery({
    queryKey: [`/poi?page=${page}&count=${rowsPerPage}${createSearch(locations)}`],
    refetchOnMount: true,
    enabled: Boolean(locations?.length > 0 && !locations.find(item => !item.coordinates)),
    keepPreviousData: true,
  });

  const { data: { items: stays } = [] } = useQuery({ queryKey: ["/itinerary/stays?&count=5000"] });

  const allPublicStays = stays?.filter(stay => !nestedPublicStays.includes(stay.vamoos_id));

  const activatedPois = useMemo(() => {
    if (noLocationsWithValidCoordinates) {
      return [];
    }
    if (editMode) {
      return allPois?.filter(poi =>
        pois.some(({ id }) => id === poi.id) ? pois.find(({ id }) => id === poi.id).is_on : poi.is_default_on,
      );
    }

    return allPois?.filter(poi => (pois.some(({ id }) => id === poi.id) ? !poi.is_default_on : poi.is_default_on));
  }, [allPois, editMode, noLocationsWithValidCoordinates, pois]);

  const handleAdd = ({ coordinates = "", on_maps = true, on_weather = true, longitude, latitude, name = "", location = "", nested, position }) => {
    append({ id: uuidToTrvId(uuidv4()), name, nested, location, coordinates, on_maps, on_weather, longitude, latitude, file: null, position });
  };

  const onDelete = index => remove(index);

  const onDragEnd = (_, { source, destination }) => move(source.index, destination.index);

  return (
    <Wrapper slimTopPadding>
      <div style={{ height: 20 }} />

      <MapWidget
        data={{
          stays: allPublicStays,
          pois: activatedPois,
        }}
        // mapContainerStyle={{ marginBottom: 15 }}
        locations={locations}
        defaultSwitchesEnabled={["pois"]}
        settings={settings}
        switchControls={["pois", "stays"]}
        searchPlaceholder="Search location"
        infoWindowsProps={{
          stays: {
            isAdding: true,
            onAddMarker: handleAdd,
          },
          search: {
            addButtonText: "Add Location",
            onAddMarker: handleAdd,
            title: "Location",
          },
          rightClick: {
            addButtonText: "Add Location",
            onAddMarker: handleAdd,
          },
        }}
        searchByLocation
        disabled={!canEdit || isWiped}
      />
      <div style={{ height: 20 }} />

      <Tabs tabs={getTabs(locations)} tab={tab} setTab={setTab} />
      {tab === "locations" ? (
        <ListOfLocations
          control={control}
          onAdd={handleAdd}
          data={locations}
          name={name}
          onDragEnd={onDragEnd}
          onDelete={onDelete}
          disabled={!canEdit || isWiped}
          watch={watch}
          update={update}
          setValue={setValue}
          hasFile={locationsHasFile}
          errors={errors}
          trigger={trigger}
        />
      ) : (
        <LocationPois
          allPois={allPois}
          overridenPois={pois}
          setValue={setValue}
          name={poisName}
          totalMatches={total_matches}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          disabled={!canEdit || isWiped}
        />
      )}
    </Wrapper>
  );
};

export default Locations;
