import React from "react";
import Input from "components/ui/Inputs/TextInput";
import Checkbox from "components/_new/Checkbox";
import DeleteButton from "components/_new/DnD/DeleteIcon";
import { Grid } from "./style";

const UserListItem = ({ index, control, filedName, onDelete, disabled }) => {
  return (
    <Grid>
      <Input control={control} name={`${filedName}.${index}.email`} rules={{ required: true }} label="E-mail" disabled={disabled} />
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 15 }}>
        <Checkbox control={control} name={`${filedName}.${index}.before_after`} label="Before / after" disabled={disabled} />
        <Checkbox control={control} name={`${filedName}.${index}.during`} label="During" disabled={disabled} />
      </div>
      {!disabled && <DeleteButton onClick={() => onDelete(index)} />}
    </Grid>
  );
};

export default UserListItem;
