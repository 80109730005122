import React from "react";
import PropTypes from "prop-types";

import Paper from "@material-ui/core/Paper";

import { Table, TableHeader, TableBody, TablePagination } from "components/ui/Tables";

import { TableRowActions } from "./TableRowActions";
import { UsersTableRow } from "./UsersTableRow";
import columns from "./TableOfUsersColumns.json";

const TableOfUsers = ({ users, tableHandlers, count, isDataLoading }) => {
  const { handleChangeOrder, handleChangePage, handleChangeRowsPerPage, rowsPerPage, page } = tableHandlers;
  return (
    <Paper>
      <Table
        headers={columns}
        list={users}
        actions={TableRowActions}
        onChangeOrder={handleChangeOrder}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        isDataLoading={isDataLoading}
        noPaper
      >
        <TableHeader withActions />
        <TableBody rowComponent={UsersTableRow} />
        <TablePagination count={count} page={page - 1} rowsPerPage={rowsPerPage} />
      </Table>
    </Paper>
  );
};

TableOfUsers.defaultProps = {
  isDataLoading: false,
};

TableOfUsers.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  tableHandlers: PropTypes.shape({
    handleChangeOrder: PropTypes.func,
    handleChangePage: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func,
    rowsPerPage: PropTypes.number,
    page: PropTypes.number,
  }).isRequired,
  count: PropTypes.number.isRequired,
  isDataLoading: PropTypes.bool,
};

export { TableOfUsers };
