import { getMediaPx } from "helpers";
import styled from "styled-components";

export const Wrap = styled.div`
  display: grid;
  grid-template-columns: ${p => (p.disabled ? "1fr 1fr" : "1fr 1fr 80px")};
  gap: 15px;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-left: 15px;
  padding-right: ${p => (p.disabled ? "15px" : "0")};
  
  .MuiInputBase-multiline {
    padding-top: 0;
    padding-bottom: 0;
    height: 48px;
  }
  @media (max-width: ${p => getMediaPx(p, "sm")}px) {
    grid-template-columns: 1fr;
    padding: 15px;
    .MuiInputBase-multiline {
      height: 40px;
    }
  }
`;
