import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import styled from "styled-components";

import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { FieldValueWrapper } from "feature/panel/Settings/_shared/styledComponents";
import { IconImage } from "components/ui/Icons";
import { Select } from "components/ui/Forms";

const SelectWrapper = styled.div`
  width: 300px;
`;

const EditActionNameDialog = ({ open, onConfirm, onCancel, onChange, iconId }) => {
  const { availableIcons } = useSelector(state => state.operator);
  const navigationIcons = availableIcons.filter(icon => icon.section === "navigation");

  return open ? (
    <ConfirmationModal open={open} title="Edit icon" confirmLabel="Update" onConfirm={onConfirm} onCancel={onCancel}>
      <SelectWrapper>
        <Select name="icon-edit" value={iconId || ""} onChange={onChange}>
          {navigationIcons.map(icon => (
            <MenuItem key={icon.id} value={icon.id}>
              <FieldValueWrapper>
                <IconImage src={icon.url} darkened />
                <span>{icon.name}</span>
              </FieldValueWrapper>
            </MenuItem>
          ))}
        </Select>
      </SelectWrapper>
    </ConfirmationModal>
  ) : null;
};

EditActionNameDialog.defaultProps = {
  iconId: null,
};

EditActionNameDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  iconId: PropTypes.number,
};

export { EditActionNameDialog, SelectWrapper };
