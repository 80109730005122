import React from "react";

import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";

import { CUSTOM_APP_ADMIN, GLOBAL_CONTENT } from "constants/content";

const RevertToDraftConfirmation = ({ open,  onConfirmModal, onCancelModal }) => {
  return (
    <>
      {open && (
        <ConfirmationModal
          open={open}
          confirmLabel={GLOBAL_CONTENT.proceed}
          title={CUSTOM_APP_ADMIN.reversionPopup.title}
          onConfirm={onConfirmModal}
          onCancel={onCancelModal}
        >
          <>{CUSTOM_APP_ADMIN.reversionPopup.text}</>
        </ConfirmationModal>
      )}
    </>
  );
};

export { RevertToDraftConfirmation };
