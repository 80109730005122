import React, { useState } from "react";
import { useFieldArray } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import ItineraryTitle from "feature/panel/Itinerary/components/Title";
import OutlinedButton from "components/_new/Buttons/OutlinedButton";
import Filter4Icon from "@mui/icons-material/Filter4";
import RateReviewIcon from "@mui/icons-material/RateReview";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TableAction from "components/_new/Table/Action";
import { ResponsiveHideFrom, ResponsiveShowFrom } from "feature/panel/Itinerary/responsive";
import { css } from "styled-components";
import { objectToArray } from "helpers";
import { Bottom, Top, Wrap } from "./style";
import Item from "./item";
import FeaturesModal from "./modal";
import { LanguageLock } from "../../../../../Stays/_shared/LanguageLock";

const Features = ({ features, disabled, onCheck, handleSave }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);

  const { data: { items = [] } = { data: { items: [] } } } = useQuery({ queryKey: ["/feature/stay"] });
  const toggleExpand = () => setIsExpanded(!isExpanded);
  const toggleModal = () => setIsOpen(!isOpen);

  return (
    <Wrap>
      {isOpen && <FeaturesModal features={features} handleSave={handleSave} onClose={toggleModal} />}
      <Top>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
          <ItineraryTitle style={{ margin: 0, padding: "0 16px", width: "auto" }}>Features</ItineraryTitle>
          <ResponsiveHideFrom size="lg">
            {disabled && <LanguageLock style={{ width: "100%" }} />}
          </ResponsiveHideFrom>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: 15, justifyContent: "flex-end" }}>
          <ResponsiveHideFrom
            size="lg"
            customStyles={css`
              display: flex;
              align-items: center;
              gap: 15px;
            `}
          >
            <OutlinedButton
              text="Set top 4"
              startIcon={<Filter4Icon />}
              type="grey"
              disabled={!features || Object.keys(features).length === 0 || disabled}
              onClick={toggleModal}
            />
            <OutlinedButton
              text="Request new feature"
              startIcon={<RateReviewIcon />}
              type="grey"
              disabled={disabled}
              onClick={() => (window.location.href = "mailto:support@vamoos.com")}
            />
          </ResponsiveHideFrom>
          <ResponsiveShowFrom
            size="lg"
            customStyles={css`
              display: flex;
              align-items: center;
              justify-content: flex-end;
              gap: 15px;
            `}
          >
            <OutlinedButton
              startIcon={<Filter4Icon />}
              type="grey"
              disabled={!features || Object.keys(features).length === 0 || disabled}
              onClick={toggleModal}
            />
            <OutlinedButton
              startIcon={<RateReviewIcon />}
              type="grey"
              disabled={disabled}
              onClick={() => (window.location.href = "mailto:support@vamoos.com")}
            />
          </ResponsiveShowFrom>
        </div>
        <TableAction element={isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />} onClick={toggleExpand} />
      </Top>
      {isExpanded && (
        <Bottom>
          <ResponsiveShowFrom size="lg">
            <div style={{ padding: "15px 15px 0" }}>
              {disabled && <LanguageLock style={{ width: "100%" }} />}
            </div>
          </ResponsiveShowFrom>
          {items?.map(item => (
            <Item key={item.id} {...item} selected={features} onCheck={onCheck} disabled={disabled} />
          ))}
        </Bottom>
      )}
    </Wrap>
  );
};

export default Features;
