import React from "react";
import Grid from "@material-ui/core/Grid";
import bg from "assets/images/sign_up_bg.jpg";
import { useTitle } from "hooks/useTitle";
import { FullHeightColumn } from "components/ui/Grid/Grid";
import { Content } from "components/ui/Content/Content";
import { BrandSection } from "components/public/Brand/BrandSection";
import { FormSection } from "feature/views/SignUp/FormSection";
import { DefaultPageRightSection } from "feature/views/PublicPagesShared/DefaultPageRightSection";
import { DefaultPageLeftSection } from "feature/views/PublicPagesShared/DefaultPageLeftSection";

const SignUp = () => {
  useTitle("Sign up");

  return (
    <Grid container spacing={0} component="article">
      <DefaultPageLeftSection>
        <FullHeightColumn alignContent="flex-start">
          <Grid item xs={12}>
            <Content margin={15}>
              <BrandSection />
            </Content>
          </Grid>
          <Grid item xs={12}>
            <FormSection />
          </Grid>
        </FullHeightColumn>
      </DefaultPageLeftSection>
      <DefaultPageRightSection backgroundImage={bg} />
    </Grid>
  );
};

export { SignUp };
