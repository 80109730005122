import { hexToRgb } from "utils/colors";

const colors = {
  brand: "#ff7c46",
  brandHover: "#ea5634",
  grey100: "#1b222b",
  grey90: "#313840",
  grey80: "#494e55",
  grey70: "#5f646a",
  grey60: "#767a80",
  grey50: "#8d9095",
  grey40: "#a4a7aa",
  grey30: "#babcbf",
  grey20: "#d1d3d5",
  grey10: "#e8e8e9",
  lightGreyBg: "rgba(232, 232, 233, 0.3)",
  descriptionBackground: "#f4f4f4",
  black: "#000000",
  white: "#ffffff",
  success: "#3cb32e",
  warning: "#f7a80d",
  error: "#ff3e30",
  errorLight: "#f5313d",
  redLight: "#FCE9E9",
  rootMuiError: "#f44336",
  lightBrand: "#ffd7c7",
  info: "#2b96cb",
  withOpacity: (hexColor, opacity) => `rgba(${hexToRgb(hexColor).join(", ")}, ${opacity})`,
};

const colors_new = {
  greyText: "#BDBDBD",
};
export { colors, colors_new };
