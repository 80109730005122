import { RadioButtonChecked } from "@material-ui/icons";

import styled from "styled-components";

import { PreviewCardSection } from "../../sharedComponents";

const StyledPreviewCardSection = styled(PreviewCardSection)`
  align-items: center;
  min-height: 200px;
`;

const PreviewWrapper = styled.div`
  max-width: 345px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const PreviewIconTile = styled.div`
  width: 110px;
  height: 110px;
  flex-basis: 110px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 5px 5px 0;
`;

const MobileSwitchIcon = styled.div`
  && {
    width: ${({ theme }) => theme.setSpacing(12.5)}px;
    height: ${({ theme }) => theme.setSpacing(7.5)}px;
    border-radius: ${({ theme }) => theme.setSpacing(4)}px;
    background-color: ${({ accentColor }) => accentColor};

    &::before {
      width: ${({ theme }) => theme.setSpacing(6.5)}px;
      height: ${({ theme }) => theme.setSpacing(6.5)}px;
      border-radius: ${({ theme }) => theme.setSpacing(4)}px;
      margin: ${({ theme }) => theme.setSpacing(0.5)}px ${({ theme }) => theme.setSpacing(0.5)}px ${({ theme }) => theme.setSpacing(0.5)}px
        auto;
      content: "";
      display: block;
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
`;

const StyledMobileRadioButton = styled(RadioButtonChecked)`
  && {
    color: ${({ accentColor }) => accentColor};
    font-size: ${({ theme }) => theme.setSpacing(8)}px;
  }
`;

const BackButtonContainer = styled.div`
  && {
    color: ${({ accentColor }) => accentColor};
    display: flex;
    align-items: center;
    text-transform: capitalize;

    .MuiSvgIcon-root {
      font-size: ${({ theme }) => theme.setSpacing(5)}px;
      margin-right: -${({ theme }) => theme.setSpacing(1.5)}px;
    }
  }
`;

const MobileToggleControl = styled.div`
  && {
    width: ${({ theme }) => theme.setSpacing(21)}px;
    height: ${({ theme }) => theme.setSpacing(6)}px;
    font-size: ${({ theme }) => theme.setSpacing(2.5)}px;
    border: 1px solid ${({ color }) => color};
    border-radius: ${({ theme }) => theme.setSpacing(1)}px;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;

    div {
      flex-basis: ${({ theme }) => theme.setSpacing(13)}px;
      line-height: ${({ theme }) => theme.setSpacing(7.5)}px;
      text-align: center;
      vertical-align: middle;

      &:first-of-type {
        background-color: ${({ color }) => color};
        color: white;
      }
      &:last-of-type {
        color: ${({ color }) => color};
      }
    }
  }
`;

const ButtonIconImg = styled.img`
  width: ${({ theme }) => theme.setSpacing(5.5)}px;
  height: ${({ theme }) => theme.setSpacing(5.5)}px;
  margin: ${({ theme }) => theme.setSpacing(1)}px auto;
`;

const MobileSendButton = styled.div`
  background-color: ${({ color }) => color};
  width: ${({ theme }) => theme.setSpacing(16)}px;
  height: ${({ theme }) => theme.setSpacing(8)}px;
  border-radius: ${({ theme }) => theme.setSpacing(4)}px;
  text-align: center;
`;

const UserInfoContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.setSpacing(4.5)}px ${({ theme }) => theme.setSpacing(9)}px;
  font-size: ${({ theme }) => theme.setSpacing(3)}px;
  margin: 0 ${({ theme }) => theme.setSpacing(1.5)}px ${({ theme }) => theme.setSpacing(1.5)}px 0;
`;

const UserInfoHeader = styled.div`
  font-weight: bold;
  margin-bottom: ${({ theme }) => theme.setSpacing(2)}px;
`;

const UserInfoContent = styled.div`
  background-color: ${({ color }) => color};
  color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.setSpacing(1.5)}px;
  border-radius: ${({ theme }) => theme.setSpacing(1)}px;
`;

const ChatPreview = styled.div`
  background-image: url(${({ bgImage }) => bgImage});
  background-position-y: -500px;
  margin: 0 ${({ theme }) => theme.setSpacing(1.5)}px ${({ theme }) => theme.setSpacing(1.5)}px 0;
  width: 100%;
  height: ${({ theme }) => theme.setSpacing(25)}px;
  display: flex;
  align-items: center;
`;
const ChatMessage = styled.div`
  background-color: ${({ color }) => color};
  color: ${({ theme }) => theme.colors.white};
  width: 80%;
  margin: ${({ theme }) => theme.setSpacing(1.5)}px auto;
  border-radius: ${({ theme }) => theme.setSpacing(2)}px;
  padding: ${({ theme }) => theme.setSpacing(2)}px;
  font-size: ${({ theme }) => theme.setSpacing(3)}px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  span {
    color: ${({ theme }) => theme.colors.grey60};
    font-size: ${({ theme }) => theme.setSpacing(2.5)}px;
  }
`;
const InputPreview = styled.div`
  border-radius: ${({ theme }) => theme.setSpacing(1)}px;
  border: 1px solid ${({ color }) => color};
  color: ${({ theme }) => theme.colors.grey60};
  padding: ${({ theme }) => theme.setSpacing(1.5)}px;
  font-size: ${({ theme }) => theme.setSpacing(3)}px;
`;

export {
  PreviewWrapper,
  PreviewIconTile,
  MobileSwitchIcon,
  BackButtonContainer,
  MobileToggleControl,
  ButtonIconImg,
  MobileSendButton,
  UserInfoContainer,
  UserInfoContent,
  UserInfoHeader,
  ChatMessage,
  ChatPreview,
  InputPreview,
  StyledMobileRadioButton,
  StyledPreviewCardSection,
};
