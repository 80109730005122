import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useFieldArray } from "react-hook-form";
import PropTypes from "prop-types";
import moment from "moment";
import uuidv4 from "uuid";
import { isEmpty } from "lodash";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AddIcon from "@mui/icons-material/Add";
import { Tooltip } from "@mui/material";
import BaseModal from "components/_new/ModalBase";
import { Container, FilterContainer, ControlButtons, AndWrapper, AndText } from "./style";
import OutlinedButton from "../../../../../components/_new/Buttons/OutlinedButton";
import FilterItem from "./FilterItem";
import filterFields from "../../../../../constants/filterFields.json";
import { ERRORS } from "../../../../../constants/content";
import ErrorBlock from "../../../../../components/_new/ErrorBlock";
import { ResponsiveHideFrom, ResponsiveShowFrom } from "../../../Itinerary/responsive";
import Badge from "../../../../../components/_new/Badge";

const Filter = ({ handleChangeParams, openFilterModal, setOpenFilterModal, type, form, filterCount, setFilterCount, isLoading }) => {
  // TODO add country to json file when the backend is ready to handle the country
  // "country": {
  //   "label": "Country",
  //     "operators": [
  //     {"label": "includes", "value": "includes"}
  //   ],
  //     "type": "select"
  // },
  const [isSelectFocused, setIsSelectFocused] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const { errors } = useSelector(state => state.trips);

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    watch,
    reset,
    resetField,
    formState: { errors: validationErrors },
  } = form;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "filters",
  });

  const addFilter = () => {
    // to use first unused filter by default
    const firstUnusedFilter = Object.keys(filterFields[type]).find(
      name =>
        !watch("filters")
          ?.map(f => f.column)
          .includes(name),
    );

    append([
      {
        id: uuidv4(),
        column: firstUnusedFilter ?? "",
        operator: filterFields[type][firstUnusedFilter]?.operators?.value ?? "",
      },
    ]);
  };

  const onSubmit = data => {
    const queryParams = { type };
    const filters = data.filters;

    for (let i = 0; i < filters.length; i++) {
      if (filters[i].operator === "between") {
        // subtract and add used because the backend only returns values before and after the dates without including them
        const dateFrom = moment(filters[i].dateFrom)
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        const dateTo = moment(filters[i].dateTo)
          .add(1, "days")
          .format("YYYY-MM-DD");
        queryParams[`f[${filters[i].column}]`] = [`[gt]${dateFrom}`, `[lt]${dateTo}`];
      } else {
        const dateValue = moment(filters[i].dateValue).format("YYYY-MM-DD");
        const userInputValue = filters[i].userInputValue ? filters[i].userInputValue : dateValue;
        queryParams[`f[${filters[i].column}]`] = `[${filters[i].operator}]${userInputValue}`;
      }
    }
    setFilterCount(Object.keys(queryParams).length - 1);
    handleChangeParams(queryParams, true);
    setShowFilter(false);
    if (openFilterModal) setOpenFilterModal(false);
  };

  return (
    <>
      <Container>
        <ResponsiveHideFrom size="md" customStyles={{ display: "flex", justifyContent: "end" }}>
          <Badge badgeContent={filterCount}>
            <OutlinedButton text="Filter" startIcon={<FilterAltOutlinedIcon />} onClick={() => setShowFilter(!showFilter)} />
          </Badge>
        </ResponsiveHideFrom>
        {showFilter || openFilterModal ? (
          <BaseModal
            title="Filter"
            contentStyle={{ overflowY: "auto" }}
            modalStyle={{ width: 800 }}
            confirmTitle="Apply"
            isLoading={isLoading}
            onConfirm={handleSubmit(onSubmit)}
            onCancel={() => {
              setShowFilter(false);
              setOpenFilterModal(false);
            }}
            mobileFullScreen
          >
            <FilterContainer>
              {fields?.map((field, index) => {
                return (
                  <>
                    <FilterItem
                      key={field.id}
                      control={control}
                      fields={fields}
                      index={index}
                      remove={remove}
                      watch={watch}
                      reset={reset}
                      setValue={setValue}
                      filterFields={filterFields[type]}
                      setShowFilter={setShowFilter}
                      handleChangeParams={handleChangeParams}
                      getValues={getValues}
                      resetField={resetField}
                      hasError={!!validationErrors?.filters?.[index]}
                      setOpenFilterModal={setOpenFilterModal}
                      setFilterCount={setFilterCount}
                      filterCount={filterCount}
                      handleFocus={() => setIsSelectFocused(true)}
                      handleBlur={() => setIsSelectFocused(false)}
                      selectedFilters={watch("filters").map(f => f.column)}
                    />
                    {index !== fields.length - 1 && fields.length > 1 && (
                      <ResponsiveShowFrom size="md">
                        <AndWrapper>
                          <AndText>And</AndText>
                        </AndWrapper>
                      </ResponsiveShowFrom>
                    )}
                  </>
                );
              })}
              {!isEmpty(errors) && (
                <ErrorBlock text={errors[0]?.message || ERRORS.unknownError} show={!isEmpty(errors)} noHide style={{ marginTop: 15 }} />
              )}
              <ControlButtons>
                <Tooltip
                  title={fields.length >= Object.keys(filterFields[type]).length ? `You cannot add more filters for ${type}s` : null}
                >
                  <span>
                    <OutlinedButton
                      text="Add"
                      startIcon={<AddIcon />}
                      onClick={addFilter}
                      noBorder
                      disabled={fields.length >= Object.keys(filterFields[type]).length}
                    />
                  </span>
                </Tooltip>
                {/* <OutlinedButton
                  text="Apply"
                  onClick={handleSubmit(onSubmit)}
                  type="solid"
                  submit="submit"
                  style={{ padding: "0 50px" }}
                  isLoading={isLoading}
                /> */}
              </ControlButtons>
            </FilterContainer>
          </BaseModal>
        ) : null}
      </Container>
    </>
  );
};

Filter.propTypes = {
  handleChangeParams: PropTypes.func.isRequired,
  openFilterModal: PropTypes.bool.isRequired,
  setOpenFilterModal: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  form: PropTypes.shape({}).isRequired,
  filterCount: PropTypes.number.isRequired,
  setFilterCount: PropTypes.func.isRequired,
};

export default Filter;
