import { BrandSection } from "domain/BrandSection";
import { BrandSectionRequestDto } from "dto/BrandSectionRequestDto";

export class BrandSectionMapper {
  /**
   *
   * @param {import(types/dto).BrandSectionDto} dto
   * @returns {BrandSection}
   */
  // eslint-disable-next-line class-methods-use-this
  fromDtoToDomain(dto) {
    /** @type {BrandSection} */
    const domain = new BrandSection();

    domain.id = dto.id;
    domain.tag = dto.tag;
    domain.operatorId = dto.operator_id;
    domain.name = dto.name;
    domain.description = dto.description;
    domain.file = dto.file;
    domain.position = dto.position;
    domain.remoteUrl = dto.remote_url;
    domain.iconId = dto.icon_id;
    domain.isFolder = dto.is_folder;
    domain.path = dto.path;
    domain.aliasForId = dto.alias_for_id;
    domain.createdBy = dto.created_by;
    domain.createdAt = dto.created_at;
    domain.updatedAt = dto.updated_at;
    domain.meta = dto.meta;
    domain.mimeType = dto.mime_type;

    return domain;
  }

  /**
   *
   * @param {BrandSection} domain
   * @returns {BrandSectionRequestDto}
   */
  // eslint-disable-next-line class-methods-use-this
  fromDomainToDto(domain) {
    const dto = new BrandSectionRequestDto();

    dto.name = domain.name;
    dto.is_public = !!domain?.file?.is_public || true;

    if (domain.iconId) {
      dto.icon_id = domain.iconId;
    }

    if (domain?.file?.is_library_file && domain?.file?.library_node_id) {
      dto.library_node_id = domain?.file?.library_node_id;
    }

    if (domain?.aliasForId) {
      dto.library_node_id = parseInt(domain?.aliasForId, 10);
    }

    if (!domain?.file?.is_library_file && !domain?.file?.library_node_id && domain?.file?.file_id) {
      dto.file_id = domain?.file?.file_id;
    }

    if (!domain?.file?.is_library_file && domain?.file?.file_url) {
      dto.file_url = domain?.file?.file_url;
    }

    if (!domain?.aliasForId && domain?.file?.s3_url) {
      dto.file_url = domain?.file?.s3_url;
    }

    return dto;
  }
}
