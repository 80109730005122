import React from "react";
import { PANEL_TRIPS_ROOT_PATH, PANEL_STAYS_PATH } from "constants/routes";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PanelRoot = () => {
  const { listOfTrips } = useSelector(state => state.trips);
  const { tripRedirectChecked } = useSelector(state => state.app);

  const to = tripRedirectChecked && !listOfTrips.length ? PANEL_STAYS_PATH : PANEL_TRIPS_ROOT_PATH;

  return <Navigate to={to} />;
};

export { PanelRoot };
