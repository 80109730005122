import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import AddIcon from "@material-ui/icons/Add";

import { BodyText2 } from "components/ui/Typography/Typography";
import { SecondaryButton } from "components/ui/Buttons";
import { Input } from "components/ui/Forms";
import { RemoveButton } from "feature/panel/_shared/Sortable/StyledComponents/shared";
import { GLOBAL_CONTENT } from "constants/content";

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${({ theme }) => theme.setSpacing(6)}px 0;
`;

const InputsContainer = styled(({ hasError, ...rest }) => <div {...rest} />)`
  width: 640px;
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.setSpacing(6)}px;
  margin-bottom: ${({ theme }) => theme.setSpacing(4)}px;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey10};
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.14);
  box-sizing: border-box;
  ${({ theme, hasError }) => (hasError ? `background-color: ${theme.colors.withOpacity(theme.colors.error, 0.2)}` : null)}
`;
const InputWrapper = styled.div`
  width: ${({ width }) => width}px;
  margin-right: ${({ theme }) => theme.setSpacing(6)}px;
`;

const PasscodeInputFields = ({ onPasscodeChange, onPasscodeAdd, onPasscodeDelete, passcodes, errors, disabled }) => {
  const passcodesList = passcodes.map(({ id, passcode, description }, index) => {
    const passcodeError = errors.passcodes && errors.passcodes[index] ? errors.passcodes[index].passcode : null;
    return (
      <InputsContainer key={id} hasError={!!passcodeError}>
        <InputWrapper width="236">
          <Input
            id={`passcode-${id}`}
            name="passcode"
            label="Passcode"
            size="small"
            value={passcode}
            onChange={e => onPasscodeChange(id, e)}
            error={!!passcodeError}
            helperText={passcodeError}
            fullWidth
            disabled={disabled}
          />
        </InputWrapper>
        <InputWrapper width="284">
          <Input
            id={`description-${id}`}
            name="description"
            label="Description"
            size="small"
            value={description}
            onChange={e => onPasscodeChange(id, e)}
            fullWidth
            disabled={disabled}
          />
        </InputWrapper>
        {!disabled && <RemoveButton onClick={() => onPasscodeDelete(id)} />}
      </InputsContainer>
    );
  });

  return (
    <>
      <HeaderWrapper>
        <BodyText2>Passcodes</BodyText2>
        {!disabled && (
          <SecondaryButton onClick={onPasscodeAdd} size="small" cv="grey40">
            <AddIcon />
            {GLOBAL_CONTENT.add}
          </SecondaryButton>
        )}
      </HeaderWrapper>
      {passcodesList}
    </>
  );
};

PasscodeInputFields.defaultProps = {
  errors: {},
};

PasscodeInputFields.propTypes = {
  onPasscodeChange: PropTypes.func.isRequired,
  onPasscodeAdd: PropTypes.func.isRequired,
  onPasscodeDelete: PropTypes.func.isRequired,
  passcodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      passcode: PropTypes.string,
      description: PropTypes.string,
    }),
  ).isRequired,
  errors: PropTypes.shape(),
};

export { PasscodeInputFields, HeaderWrapper, InputsContainer, InputWrapper };
