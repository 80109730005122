import { isObject } from "contracts/types";

export const isObjectEmpty = value => typeof value === "object" && value !== null && !Object.keys(value).length;
export const objectHasKeys = (object, arrayToComapre = []) =>
  isObject(object) && Array.isArray(arrayToComapre) ? arrayToComapre.every(el => Object.keys(object).some(e => el.includes(e))) : false;

export const addToObject = object => (name, value) => ({ ...object, [`${name}`]: value });

export const removeFromObject = (object, name) => {
  if (isObject(object)) {
    const { [name]: _, ...restOfobject } = object;
    return restOfobject;
  }
  return object;
};

export const getDeepestObjectFragment = (obj, fragments) => {
  let output = {};
  if (isObject(obj) && Array.isArray(fragments))
    fragments.forEach((fragment, i) => {
      if (isObject(obj[fragment]) && isObject(obj[fragment][fragments[i + 1]])) {
        const newFragmentsList = [...fragments];
        newFragmentsList.shift();
        output = getDeepestObjectFragment(obj[fragment], newFragmentsList);
      } else if (isObject(obj[fragment])) {
        output = obj[fragment];
      }
    });
  return output;
};
