import React from "react";
import { useSelector } from "react-redux";

import { InformationField } from "feature/panel/Settings/_shared/InformationField";

import { StyledLink } from "feature/admin/_shared/StyledComponents";

import { ADMIN_OPERATOR_DETAILS_GENERAL_PATH } from "constants/routes";
import { OPERATOR_TYPE, USER_DETAILS_OPERATORS_LABEL } from "constants/content";
import { setUrl } from "utils/url";

const OperatorsInfo = () => {
  const operators = useSelector(state => state.adminUserDetails.user?.operators);
  const operatorsList = operators?.filter(operatorItem => operatorItem.type !== OPERATOR_TYPE.admin) || [];
  const getUrl = id => setUrl(ADMIN_OPERATOR_DETAILS_GENERAL_PATH, { id });

  const operatorList = (
    <>
      {operatorsList?.map((operator, index) => (
        <span key={`admin-user-operator-${operator.id}`}>
          <StyledLink to={getUrl(operator.id)} cv="grey100" linkfor="table">
            {operator.name}
          </StyledLink>
          {index === operatorsList.length - 1 ? "" : ", "}
        </span>
      ))}
    </>
  );

  return (
    <InformationField title={USER_DETAILS_OPERATORS_LABEL}>
      <div>{operatorList}</div>
    </InformationField>
  );
};

export { OperatorsInfo, StyledLink };
