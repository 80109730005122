import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { ArrowIcon } from "components/ui/Breadcrumbs/styledComponents";
import { AnchorLikeButton } from "feature/panel/Stays/_shared/styledComponents";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const BreadcrumbElement = styled(AnchorLikeButton)`
  && {
    display: flex;
    align-items: center;
    padding: 0;
    font-size: ${({ theme }) => theme.fonts.fontSize.h5}px;
    color: ${({ theme }) => theme.colors.grey50};

    :last-of-type {
      color: ${({ theme }) => theme.colors.black};
      pointer-events: none;
      & > div {
        display: none;
      }
    }
  }
`;

const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 ${({ theme }) => theme.setSpacing(3)}px;
`;

const DirectoryBreadcrumbs = ({ onBreadcrumbChange, breadcrumbs }) => {
  const breadcrumbsElements = breadcrumbs.map(({ id, tag, name }) => (
    <BreadcrumbElement key={`${id}-${tag}-${name}`} onClick={() => onBreadcrumbChange(tag)}>
      {name}
      <ArrowWrapper>
        <ArrowIcon />
      </ArrowWrapper>
    </BreadcrumbElement>
  ));

  return (
    <Wrapper>
      {breadcrumbsElements}
    </Wrapper>
  );
};

DirectoryBreadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({})
  ).isRequired,
  onBreadcrumbChange: PropTypes.func.isRequired,
};

export { DirectoryBreadcrumbs, Wrapper, BreadcrumbElement, ArrowWrapper };
