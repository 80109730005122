import { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";

const SESSION_STORAGE_KEY = "vlock_session_id";

const defaultOptions = {
  connectionString: process.env.REACT_APP_LOCK_WEBSOCKET_URL ?? "wss://56zoabwvbh.execute-api.eu-west-1.amazonaws.com/$default",
};

/** @typedef {'request_lock'|'release_lock'|'takeover_lock'} WebsocketAction */
/** @typedef {'lock_status_changed'|'echo'} WebsocketEvent */

/** @typedef {{ own_lock: boolean, meta_data: object }} WebsocketLockStatusChangedPayload */
/** @typedef {{ action: WebsocketEvent, payload: WebsocketLockStatusChangedPayload | object  }}  WebsocketPayload */

/**
 * Generates a Lock ID from a given type and identifier
 *
 * @param {string} type The type of the resource you wish to lock
 * @param {string} id The identifier of the resource you wish to lock
 * @returns {string}
 */
export const generateLockId = (type, id) => {
  const prependTokenEnv = process.env.REACT_APP_LOCK_ID_PREPEND_TOKEN;
  const shouldPrependToken = prependTokenEnv && prependTokenEnv !== 'false';

  return shouldPrependToken ?
    `vm:token_${prependTokenEnv}:${type}:${id}` :
    `vm:${type}:${id}`;
};

/**
 * Sends a message to the websocket
 *
 * @param {WebSocket} websocket the websocket
 * @param {WebsocketAction} action the action to perform
 * @param {object|undefined} params the parameters to be sent for the action
 */

const _sendMessage = (websocket, action, params) => {
  const message = JSON.stringify({
    action: "sendMessage",
    message: JSON.stringify({
      action,
      ...(params ?? {}),
    }),
  });

  if (!websocket) {
    console.log(`No websocket exists to send a message... Aborting.`);
    return;
  }

  websocket.send(message);
};

const ping = websocketRef => {
  if (!websocketRef.current) {
    console.warn("You are trying to ping without a websocket. Aborting request.");
    return;
  }

  _sendMessage(websocketRef.current, "ping", {});
};
/**
 * The callback for receiving a websocket event from the lock service.
 *
 * @callback onEventReceived
 * @param {object} message
 */

/**
 * The callback for receiving an event with respect to a lock update.
 *
 * @callback onLockStatusChanged
 * @param {{ own_lock: boolean, meta_data: object }} payload
 */

/**
 * Interacts with the Vamoos locking service to ensure that you can request, release and takeover
 * resource locks.
 *
 * @param {object} options The options for the hook
 * @param {string} options.lockId The ID to use for the lock
 * @param {function} [options.onConnect] The function to be triggered upon a successful connection being established
 * @param {function} [options.onDisconnect] The function to be triggered upon the connection being terminated
 * @param {onLockStatusChanged} [options.onLockStatusChanged] The function to be triggered when the lock has been updated
 * @param {string} [options.connectionString] The websocket URL (defaults to `process.env.LOCK_WEBSOCKET_URL`)
 */
function useResourceLock({ connectionString = defaultOptions.connectionString, onConnect, onDisconnect, onLockStatusChanged, lockId }) {
  const websocketRef = useRef(null);
  const forcingReconnect = useRef(false);
  const isLockRequested = useRef(false);
  const requestLockIntervalId = useRef();
  const previousMetaData = useRef();

  const [mounted, setMounted] = useState(false);
  const [isConnecting, setConnecting] = useState(false);
  const [isConnected, setConnected] = useState(false);

  const requestLock = metaData => {
    if (!websocketRef.current) {
      console.log("You are trying to request a lock without a websocket. Aborting request.");
      return;
    }
    previousMetaData.current = metaData;
    isLockRequested.current = true;

    _sendMessage(websocketRef.current, "request_lock", {
      lock_id: lockId,
      session_id: sessionStorage.getItem(SESSION_STORAGE_KEY),
      meta_data: metaData,
    });
  };

  const initSocket = () => {
    if (websocketRef.current) {
      console.log("You cannot modify the options for the ResourceLock hook once created.");
      return;
    }

    setConnecting(true);
    websocketRef.current = new WebSocket(connectionString);
    window.wsLock = websocketRef.current;

    websocketRef.current.onopen = () => {
      console.log(`Connection to ${connectionString} established.`);
      forcingReconnect.current = false;

      const sessionId = sessionStorage.getItem(SESSION_STORAGE_KEY) ?? uuidv4();
      sessionStorage.setItem(SESSION_STORAGE_KEY, sessionId);

      setConnected(true);
      setConnecting(false);

      requestLockIntervalId.current = setInterval(() => {
        if (isLockRequested.current) {
          ping(websocketRef);
        }
      }, 1000 * 60 * 5);

      if (onConnect) {
        onConnect();
      }
    };

    websocketRef.current.onclose = () => {
      console.log(`Connection to ${connectionString} terminated.`);

      forcingReconnect.current = true;
      websocketRef.current = undefined;

      if (requestLockIntervalId.current) {
        clearInterval(requestLockIntervalId.current);
        requestLockIntervalId.current = undefined;
      }

      setConnected(false);
      if (onDisconnect) {
        onDisconnect();
      }
    };
  };

  useEffect(() => {
    if (mounted && !isConnecting && !isConnected) initSocket();
  }, [isConnected, isConnecting, mounted]);

  useEffect(() => {
    initSocket();

    const websocketCurrent = websocketRef.current;

    setMounted(true);

    return () => {
      if (forcingReconnect.current) return;
      isLockRequested.current = false;
      websocketCurrent.close();
    };
  }, []);

  useEffect(() => {
    if (!websocketRef.current || websocketRef.current.onmessage) return;

    websocketRef.current.onmessage = e => {
      /** @type { WebsocketPayload } */
      const messageData = JSON.parse(e.data);

      if (!messageData.action && !messageData.payload) {
        console.log("Websocket Data indicated an error, ignoring...");
        return;
      }

      const payload = JSON.parse(messageData.payload);

      switch (messageData.action) {
        case "lock_status_changed":
          if (onLockStatusChanged) onLockStatusChanged(payload);
          break;
        default:
          console.log(`We received an unhandled event from the websocket: ${messageData.action}. Ignoring.`);
      }
    };
  }, [onLockStatusChanged]);

  const releaseLock = () => {
    if (!websocketRef.current) {
      console.log("You are trying to release a lock without a websocket. Aborting request.");
    }

    _sendMessage(websocketRef.current, "release_lock", {
      lock_id: lockId,
      session_id: sessionStorage.getItem(SESSION_STORAGE_KEY),
    });
  };

  const takeoverLock = metaData => {
    if (!websocketRef.current) {
      console.log("You are trying to takeover a lock without a websocket. Aborting request.");
    }

    _sendMessage(websocketRef.current, "takeover_lock", {
      lock_id: lockId,
      session_id: sessionStorage.getItem(SESSION_STORAGE_KEY),
      meta_data: metaData,
    });
  };

  return { requestLock, releaseLock, takeoverLock, isConnecting, isConnected };
}

export default useResourceLock;
