import React from "react";
import { NavLink } from "react-router-dom";
import { MainNavigation } from "../MainNavigation";
import { Menu, Overlay } from "./style";

const menus = [
  {
    title: "Vamoos list",
    to: "/panel/itinerary",
  },
  {
    title: "Library",
    to: "/",
  },
  {
    title: "Shared",
    to: "/",
  },
  {
    title: "Settings",
    to: "/",
  },
  {
    title: "Help",
    to: "/",
  },
];

const MobileMenu = ({ open }) => {
  return (
    <>
      <Overlay open={open} className={open ? "animation-overlay" : ""} />
      <Menu open={open} className={open ? "animation" : ""}>
        <MainNavigation isMobile />
      </Menu>
    </>
  );
};

export default MobileMenu;
