import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem, FormControlLabel } from "@material-ui/core";

import { ContentWrapper, Paper, Header } from "feature/panel/Settings/_shared/styledComponents";
import { changeCompanyRefactorOptIn, changeCompanyStoryboardView, changeCompanyTemperatureScale, switchCompanyPoisList } from "store/operator/actions";
import { InformationField } from "feature/panel/Settings/_shared/InformationField";
import { Radio, Select, Switch } from "components/ui/Forms";
import { FieldChangeModal } from "feature/panel/Settings/_shared/FieldChangeModal";
import { SettingsWrapper } from "feature/panel/Settings/styledComponents";
import { STORYBOARD_SHOW_ICONS_DISPLAYABLE_VALUES } from "constants/storyboard";
import { GENERAL_SETTINGS_LABELS, MENU_LABELS_ICONS_CAPTIONS } from "constants/content";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { useQuery } from "@tanstack/react-query";

const RadioButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const getTemperatureScaleDisplayableValue = mark => (mark === "F" ? GENERAL_SETTINGS_LABELS.fahrenheit : GENERAL_SETTINGS_LABELS.celsius);

const GeneralSettings = () => {
  const permissionsService = usePermissionsService();
  const [editModal, setEditModal] = useState({ open: false, body: { value: "" } });
  const { currentOperator } = useSelector(state => state.operator);
  const dispatch = useDispatch();

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.operator, PERMISSIONS.resources.operators);
  let refactorEnabled = currentOperator?.meta?.enable_refactor;
  if (process.env.REACT_APP_FORCE_NEW_TRIPS === "true") { refactorEnabled = true }

  const { storyboardView, temperatureScale, poiListOption, refactorOptIn } = MENU_LABELS_ICONS_CAPTIONS;

  const handleEditionToggle = fieldName => {
    const { open } = editModal;
    const body = open ? editModal.body : fieldName;
    setEditModal({ body, open: !open });
  };

  const onCompanyPoisListChange = () => {
    dispatch(switchCompanyPoisList());
  };

  const onCompanyTemperatureScaleChange = value => {
    dispatch(changeCompanyTemperatureScale(value));
  };

  const onCompanyRefactorOptInChange = () => {
    dispatch(changeCompanyRefactorOptIn());
  }

  const onCompanyStoryBoardViewChange = value => {
    dispatch(
      changeCompanyStoryboardView({
        summary: [GENERAL_SETTINGS_LABELS.summaryAndDetails, GENERAL_SETTINGS_LABELS.summary].includes(value),
        details: [GENERAL_SETTINGS_LABELS.summaryAndDetails, GENERAL_SETTINGS_LABELS.details].includes(value),
      }),
    );
  };

  const editOperatorMetaValue = (fieldName, value) => {
    if (fieldName === "poisList") {
      return onCompanyPoisListChange();
    }
    if (fieldName === "temperature_scale") {
      return onCompanyTemperatureScaleChange(value);
    }
    if (fieldName === "storyBoardView") {
      return onCompanyStoryBoardViewChange(value);
    }
    if (fieldName === "refactorOptIn") {
      return onCompanyRefactorOptInChange();
    }
    return null;
  };

  const handleEditConfirm = () => {
    const { fieldName, value } = editModal.body;
    editOperatorMetaValue(fieldName, value);
    handleEditionToggle();
  };

  const handleEditChange = ({ target }) => {
    setEditModal({
      ...editModal,
      body: {
        ...editModal.body,
        value: target.value,
      },
    });
  };

  const storyBoardViewValueEnums = () => {
    if (Number(currentOperator.storyBoardView?.details) === 1 && Number(currentOperator.storyBoardView?.summary) === 0)
      return GENERAL_SETTINGS_LABELS.details;
    if (Number(currentOperator.storyBoardView?.details) === 0 && Number(currentOperator.storyBoardView?.summary) === 1)
      return GENERAL_SETTINGS_LABELS.summary;
    return GENERAL_SETTINGS_LABELS.summaryAndDetails;
  };

  const modalContent = (() => {
    const { fieldName, value } = editModal.body;
    if (fieldName === "storyBoardView")
      return (
        <Select label={GENERAL_SETTINGS_LABELS.storyboardView} name="storyBoardView" value={value} onChange={handleEditChange} fullWidth>
          {STORYBOARD_SHOW_ICONS_DISPLAYABLE_VALUES.map(({ value: option, label }) => (
            <MenuItem value={option} key={option}>
              {label}
            </MenuItem>
          ))}
        </Select>
      );

    if (fieldName === "temperature_scale")
      return (
        <RadioButtonsWrapper>
          <FormControlLabel
            label={GENERAL_SETTINGS_LABELS.celsius}
            control={<Radio color="primary" checked={value === "C"} onChange={() => handleEditChange({ target: { value: "C" } })} />}
          />
          <FormControlLabel
            label={GENERAL_SETTINGS_LABELS.fahrenheit}
            control={<Radio color="primary" checked={value === "F"} onChange={() => handleEditChange({ target: { value: "F" } })} />}
          />
        </RadioButtonsWrapper>
      );
    return null;
  })();

  return (
    <SettingsWrapper>
      <Paper>
        <ContentWrapper>
          <Header>{GENERAL_SETTINGS_LABELS.generalSettings}</Header>
          <InformationField
            title={storyboardView}
            content={storyBoardViewValueEnums()}
            onEditClick={
              canEdit
                ? () =>
                    handleEditionToggle({
                      fieldName: "storyBoardView",
                      value: storyBoardViewValueEnums(),
                    })
                : null
            }
          />
          <InformationField
            title={temperatureScale}
            content={getTemperatureScaleDisplayableValue(currentOperator.temperatureScale)}
            onEditClick={
              canEdit
                ? () =>
                    handleEditionToggle({
                      fieldName: "temperature_scale",
                      value: currentOperator.temperatureScale,
                    })
                : null
            }
          />
          <InformationField
            title={poiListOption}
            editSlot={
              <Switch
                disabled={!canEdit}
                checked={!!Number(currentOperator.poisList)}
                onChange={() => editOperatorMetaValue("poisList", !Number(currentOperator.poisList))}
              />
            }
          />
          {/* { refactorEnabled ? (
            <InformationField
              title={refactorOptIn}
              editSlot={
                <Switch
                  checked={currentOperator.meta?.show_refactor !== false}
                  onChange={() => editOperatorMetaValue("refactorOptIn")}
                />
              }
            />
          ) : null } */}
        </ContentWrapper>
      </Paper>
      <FieldChangeModal
        open={editModal.open}
        label={editModal.body.label || ""}
        onCancel={handleEditionToggle}
        onConfirm={handleEditConfirm}
      >
        {modalContent}
      </FieldChangeModal>
    </SettingsWrapper>
  );
};

export { GeneralSettings, RadioButtonsWrapper, SettingsWrapper };
