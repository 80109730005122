import React from "react";
import { Message } from "components/ui/Messages/Message";
import BaseModal from "components/_new/ModalBase";

const SaveModal = ({ onCancel, onConfirm }) => {
  return (
    <BaseModal title="Edit creation" confirmTitle="Save" onConfirm={onConfirm} onCancel={onCancel}>
      <Message text="You currently have unsaved changes. In order to continue with this operation you need to save first." type="info" />
    </BaseModal>
  );
};

export default SaveModal;
