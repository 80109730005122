import React from "react";
import { css } from "styled-components";
import { FileUpload } from "components/ui/LibraryPopup/Components";
import { StaySection } from "components/ui/LibraryPopup/Components/Stay/StaySection";
import FilesTab from "./Files";
import WebPage from "./WebPage";
import UnsplashSection from "./UnsplashSection";
import AddFlightForm from "../../../feature/panel/Itinerary/pages/Trips/Flights/AddFlightModal/form";

export const modals = {
  library: {
    label: "Library",
    component: <FilesTab />,
    customCss: css`
      display: grid;
      /* grid-template-rows: 40px auto 60px; */
      /* gap: 35px; */
      max-width: 800px;
      @media (max-width: 600px) {
        /* grid-template-rows: 35px 65vh 98px 35px 60px auto 70px; */
      }
    `,
    contentStyle: css`
      display: grid;
      /* grid-template-rows: 58px 65px 55px 55px 1fr; */
    `,
  },
  stays: {
    label: "Stays",
    component: <StaySection />,
    customCss: css`
      @media (max-width: 600px) {
        display: grid;
        grid-template-rows: 30px 40px auto 70px;
      }
    `,
  },
  flights: {
    label: "Flights",
    component: <AddFlightForm />,
    customCss: css`
      display: grid;
    `,
    contentStyle: css`
      display: grid;
      overflow: visible;
    `,
  },

  upload: {
    label: "Upload file",
    component: <FileUpload />,
    customCss: css`
      max-width: 800px;
    `,
  },
  webpage: {
    label: "Web page",
    component: <WebPage />,
  },
  unsplash: {
    label: "Unsplash",
    component: <UnsplashSection />,
    customCss: css`
      display: grid;
    `,
    contentStyle: css`
      display: grid;
    `,
  },
};
