import React from "react";
import theme from "config/theme";
import styled from "styled-components";
import { Checkbox as MuiCheckbox, Radio as MuiRadio } from "@material-ui/core";

const Checkbox = styled(({ value, checked, label, style, labelStyle = {}, ...props }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", ...style }}>
      <MuiCheckbox color="default" {...props} checked={value || checked || false} indeterminate={props?.indeterminate} />
      {label && <div style={{...labelStyle}}>{label}</div>}
    </div>
  );
})`
  && {
    color: ${theme.colors.grey40};
    margin-right: ${theme.setSpacing(1)}px;
    padding: 0;

    &.Mui-checked {
      color: ${theme.colors.brand};
    }

    &.Mui-disabled {
      color: rgba(0, 0, 0, 0.26);
    }

    span {
      padding: 0;
    }
  }
`;

const Radio = styled(props => <MuiRadio color="default" {...props} />)`
  && {
    color: ${theme.colors.grey40};

    &.Mui-checked {
      color: ${theme.colors.brand};
    }
  }
`;

export { Checkbox, Radio };
