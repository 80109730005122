import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { usePopupHandlers } from "hooks/usePopupHandlers";
import { SecondaryButton } from "components/ui/Buttons";
import { EditOutlined } from "@material-ui/icons";
import { Autocomplete } from "components/ui/Forms/Autocomplete";
import { Switch } from "components/ui/Forms";
import { TitleText3 } from "components/ui/Typography/Typography";
import { Message } from "components/ui/Messages";
import { Link } from "react-router-dom";
import { PANEL_SETTINGS_COMPANY } from "constants/routes";
import { GLOBAL_CONTENT, LANGUAGE_SELECTOR } from "constants/content";
import { Logger } from "services/application/Logger";

const LanguageSelectorEditButton = styled(SecondaryButton)`
  && {
    margin: 0 5px;
  }
`;

const LanguagesSwitches = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
`;

const LanguageSwitchWrapper = styled.div`
  height: 40px;
  border: 1px solid ${({ theme }) => theme.colors.brand};
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 0 13px 0 25px;
`;

const Section = styled.div`
  margin: 32px -24px 0 -24px;
  padding: 32px 24px;
  border-top: 1px solid ${({ theme }) => theme.colors.grey20};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey20};
`;

const SectionTitle = styled(TitleText3)`
  && {
    margin: 0 0 22px 0;
  }
`;

export const SettingsLink = styled(Link)`
  && {
    color: ${({ theme }) => theme.colors.brand};
    font-weight: bold;
  }
`;

const MessageContent = () => (
  <>
    {LANGUAGE_SELECTOR.toAddAdditionalLanguages}
    <SettingsLink to={PANEL_SETTINGS_COMPANY}>{LANGUAGE_SELECTOR.languageSettings}</SettingsLink>
  </>
);

const LocalLanguageModal = ({ languages, onLanguagesEdit }) => {
  const langModal = usePopupHandlers();
  const [selectedDefaultLanguage, setSelectedDefaultLanguage] = useState(null);
  const [selectedLocalLanguages, setSelectedLocalLaguages] = useState([]);

  const onConfirm = () => {
    onLanguagesEdit({
      defaultLanguage: selectedDefaultLanguage,
      localLanguages: selectedLocalLanguages.filter(
        selected => languages.find(lang => lang.code === selected.code).isActive !== selected.isActive,
      ),
    });
    langModal.close();
  };

  useEffect(() => {
    setSelectedDefaultLanguage(languages.find(lang => lang.isDefault));
    setSelectedLocalLaguages(languages);
  }, [JSON.stringify(languages), langModal.isOpen]);

  return (
    <>
      <LanguageSelectorEditButton cv="grey50" iconButton onClick={() => langModal.open()}>
        <EditOutlined />
      </LanguageSelectorEditButton>
      <ConfirmationModal
        open={langModal.isOpen}
        title={LANGUAGE_SELECTOR.languages}
        confirmLabel={GLOBAL_CONTENT.save}
        onCancel={langModal.close}
        onConfirm={onConfirm}
      >
        <Autocomplete
          value={selectedDefaultLanguage}
          options={languages.filter(({ isActive }) => isActive)}
          getOptionLabel={option => option.name}
          getOptionSelected={option => option.code === selectedDefaultLanguage?.code}
          onChange={(event, value) => {
            setSelectedDefaultLanguage(value);
          }}
          filterSelectedOptions
          label={LANGUAGE_SELECTOR.defaultLanguage}
        />
        <Section>
          <SectionTitle>{LANGUAGE_SELECTOR.additionalLanguages}</SectionTitle>
          <LanguagesSwitches>
            {selectedLocalLanguages
              ?.filter(lang => lang.code !== selectedDefaultLanguage?.code)
              .map(lang => (
                <LanguageSwitchWrapper key={lang.code}>
                  <Switch
                    name={lang.name}
                    label={lang.name}
                    labelPlacement="start"
                    checked={lang.isActive}
                    onChange={() => {
                      setSelectedLocalLaguages(
                        selectedLocalLanguages.map(stayLang => {
                          Logger.debug({
                            stayLang,
                            lang,
                          });
                          Logger.debug(stayLang.code, stayLang.code === lang.code ? !stayLang.isActive : stayLang.isActive);
                          return {
                            ...stayLang,
                            isActive: stayLang.code === lang.code ? !stayLang.isActive : stayLang.isActive,
                          };
                        }),
                      );
                    }}
                  />
                </LanguageSwitchWrapper>
              ))}
          </LanguagesSwitches>
          <Message margin={{ top: "32px" }} text={<MessageContent />} type="info" />
        </Section>
      </ConfirmationModal>
    </>
  );
};

LocalLanguageModal.defaultProps = {
  languages: null,
  onLanguagesEdit: () => {},
};

LocalLanguageModal.propTypes = {
  languages: PropTypes.arrayOf(PropTypes.shape({})),
  onLanguagesEdit: PropTypes.func,
};

export { LocalLanguageModal };
