import React, { useContext } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Switch } from "components/ui/Forms";
import { BodyText } from "components/ui/Typography/Typography";
import { NotificationForm } from "feature/panel/_shared/Notifications/TimedNotificationForm/NotificationForm";
import { NotificationWrapper, DataDisplayWrapper } from "feature/panel/_shared/Notifications/styledComponents";

import { calculateNotificationDate } from "utils/notifications";

const ActiveStatusSwitch = styled(Switch)`
  &&&& {
    position: relative;
    left: ${({ theme }) => theme.setSpacing(3)}px;
  }
`;

export const StrongLabel = styled.strong`
  padding-left: ${({ theme }) => theme.setSpacing(1)}px;
`;

const NotificationItem = ({ item, context, disabled, allNotifications }) => {
  const { setValueFor, departure_date, return_date, notifications, type } = useContext(context);
  const { start_at, delivery_at_days, delivery_at_relative_to, is_active, template_id, id } = item;

  const notificationDate = calculateNotificationDate({
    delivery_at_days,
    delivery_at_relative_to,
    departure_date,
    return_date,
  });

  const handleActiveToggle = () => {
    const newList = notifications.map(notification => {
      if (notification.id && notification.id === id) {
        return { ...notification, is_active: !is_active };
      }
      if (notification.template_id && notification.template_id === template_id) {
        return { ...notification, is_active: !is_active };
      }
      return notification;
    });
    setValueFor("notifications", newList);
  };
  const isTrip = type === "trip";
  const titleDelimiter = isTrip && is_active ? ":" : "";
  return (
    <NotificationWrapper>
      <DataDisplayWrapper>
        <BodyText>
          {`Template ${template_id || "none "}${titleDelimiter}`}
          {isTrip && is_active && (
            <>
              <StrongLabel>{notificationDate}</StrongLabel>
              <StrongLabel>{start_at}</StrongLabel>
            </>
          )}
        </BodyText>
        <ActiveStatusSwitch disabled={disabled} checked={is_active || false} onChange={handleActiveToggle} />
      </DataDisplayWrapper>
      <NotificationForm disabled={disabled} item={item} context={context} allNotifications={allNotifications} />
    </NotificationWrapper>
  );
};

NotificationItem.defaultProps = {
  disabled: false,
};

NotificationItem.propTypes = {
  item: PropTypes.shape().isRequired,
  context: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool,
};

export { NotificationItem, ActiveStatusSwitch };
