import React, { useContext } from "react";
import PropTypes from "prop-types";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { SortableListItem } from "components/ui/Lists/SortableListItem";
import uuidv4 from "uuid";
import { Checkbox } from "components/ui/Forms";
import { ColumnSelectorContext } from "components/_shared/ColumnSelector/ColumnSelectorContext";

const ListOfColumnsItem = ({ column, index }) => {
  const { toggleColumnSelection, resolveDisabled, toggleCheck } = useContext(ColumnSelectorContext);

  const { key, label } = column;

  const handleChecked = () => (toggleColumnSelection ? toggleColumnSelection(key) : false);
  const handleDisabled = () => (resolveDisabled ? resolveDisabled(key) : false);

  return (
    <SortableListItem key={uuidv4()} index={index} destination="popup">
      <FormControlLabel
        label={label}
        control={<Checkbox checked={handleChecked()} onChange={e => toggleCheck(key, e.target.checked)} disabled={handleDisabled()} />}
      />
    </SortableListItem>
  );
};

ListOfColumnsItem.propTypes = {
  index: PropTypes.number.isRequired,
  column: PropTypes.shape().isRequired,
};

export { ListOfColumnsItem };
