import { Button } from "feature/panel/_shared/MapWidget_old/styledComponents";
import React from "react";
import AddIcon from "@material-ui/icons/Add";
import uuidv4 from "uuid";
import { isFunction } from "contracts/types";
import CommonInfoWindow from "../Common";
import { Row } from "./style";
import { getCountryName } from "../../../MapWidget_old/helpers";
import CoordinatesButton from "../components/CoordinatesButton/index";
import BottomRow from "../components/BottomRow/index";

const SearchInfoWindow = ({ item, addButtonText, onAddMarker, label, address_components, disableAddButton, onWindowInfoClose }) => {
  const { name, latitude, longitude, location } = item;

  const addNewLocation = () => {
    const newMarker = {
      id: uuidv4(),
      country: getCountryName(address_components),
      name,
      location,
      coordinates: `${latitude}, ${longitude}`,
      latitude,
      longitude,
      on_weather: true,
      on_maps: true,
      position: item.position,
    };

    if (onAddMarker && isFunction(onAddMarker)) {
      onAddMarker(newMarker, onWindowInfoClose);
    }
  };

  return (
    <div>
      <CommonInfoWindow item={item} label={label} />
      <BottomRow
        latitude={latitude}
        longitude={longitude}
        disableAddButton={disableAddButton}
        addButtonText={addButtonText}
        onClick={addNewLocation}
      />
    </div>
  );
};

export default SearchInfoWindow;
