import { DEFAULT_PAGE_SIZE } from "constants/defaults";

// user resource
export const REGISTRATION_USER_WITH_OPERATOR_URL = "/user/register_with_operator";
export const REGISTRATION_USER_URL = "/user/register";

export const CREATE_OPERATOR_URL = "/operator/create";

export const LOGIN_URL = "/user/login";
export const LOGOUT_URL = "/user/logout";
export const VERIFY_EMAIL_URL = "/user/verify_email";
export const RESEND_VERIFICATION_CODE_URL = "/user/resend_verification_code";

export const AUTH_LOGIN_URL = "/auth/login";

export const USER_INFO_URL = "/user/me";
export const FORGOT_PASSWORD_URL = "/user/forgot_password";
export const EMAIL_AVAILABILITY_CHECK_URL = "/user/check/email/:email";
export const USERNAME_AVAILABILITY_CHECK_URL = "/user/check/username/:username";
export const COMPANY_CODE_AVAILABILITY_CHECK_URL = "/operator/check/operator_code/:operator_code";

export const ITINERARY_URL = "/itinerary/:operator/:code";
export const LOCALISED_ITINERARY_URL = "/itinerary/:operator/:code/language/:languageCode";
export const SET_DEFAULT_ITINERARY_LANGUAGE_URL = "/itinerary/:operator/:code/fallback_language";
export const GET_ITINERARIES_URL = "/itinerary";
export const GET_NESTED_ITINERARIES_URL = "/itinerary/nested";
export const GET_PUBLIC_STAYS_URL = "/itinerary/stays";
export const OVERRIDE_VAMOOS_ID_ITINERARY_URL = "/itinerary/overrides/:vamoos_id";
export const REQUEST_STAY_CONNECT_STATUS = "/itinerary/:operatorCode/:passcode/request_listing";
export const ITINERARY_USERS_URL = "/itinerary/:operator/:code/users";
export const ITINERARY_REMOVE_USER_URL = "/itinerary/:operator/:code/users/:userId";
export const ITINERARY_DELETE_PASSCODE_URL = "/itinerary/:operator/:code/passcode";

// Messaging URL's
export const GET_CONVERSATIONS_FOR_OPERATOR_URL = `/itinerary/:operator_code/:reference_code/messaging?count=${DEFAULT_PAGE_SIZE}&page=1`;
export const OPERATE_ON_MESSAGES_FOR_CONVERSATION_URL = "/itinerary/:operator_code/:reference_code/messaging/:conversation";
export const OPERATE_ON_MESSAGES_URL = "/itinerary/:operator_code/:reference_code/messages";

export const NOTIFICATION_LIST_URL = "/notification/list/:type";
export const NOTIFICATION_ALL_URL = "/notification/list";
export const NOTIFICATION_CREATE_URL = "/notification";
export const NOTIFICATION_ELEMENT_URL = "/notification/:id";

export const GET_ICONS = `/icon?page=1&count=${DEFAULT_PAGE_SIZE}`;
export const GET_OPERATOR_ICONS = "/icon";
export const GET_PUBLIC_ICONS = "/icon/public";
export const UPDATE_POI_ICON = "/icon/:id";

export const CURRENT_OPERATOR_URL = "/operator";
export const OPERATOR_ROLES_URL = `${CURRENT_OPERATOR_URL}/roles`;
export const OPERATOR_USER_INVITE_URL = `${CURRENT_OPERATOR_URL}/invite`;
export const OPERATOR_USER_REVOKE_URL = `${OPERATOR_USER_INVITE_URL}/:id`;
export const OPERATOR_USER_UPDATE_URL = `${OPERATOR_USER_INVITE_URL}/:id`;
export const OPERATOR_USERS_URL = `${CURRENT_OPERATOR_URL}/users?page=1&count=${DEFAULT_PAGE_SIZE}`;
export const OPERATOR_USERS_SUSPEND_URL = `${CURRENT_OPERATOR_URL}/suspend/:id`;
export const OPERATOR_BRANDING_URL = `${CURRENT_OPERATOR_URL}/branding`;
export const OPERATOR_BRANDS_URL = `${CURRENT_OPERATOR_URL}/branding?page=1&count=${DEFAULT_PAGE_SIZE}`;
export const OPERATOR_BRAND_BY_ID_URL = `${OPERATOR_BRANDING_URL}/:branding_profile_id`;

export const FILE_S3_INIT_URL = "/file/upload_url";
export const LIBRARY_FILE_URL = "/file";
export const LIBRARY_POPUP_FILES_URL = "/file?path=:path";
export const LIBRARY_FILE_LIST_URL = "/file/list";
export const LIBRARY_NODE_UPDATE_DELETE_URL = "/file/:id";
export const LIBRARY_NODE_ID_URL = "/file/:id";
export const SEARCH_NODES_URL = `file/search?q=:query&count=${DEFAULT_PAGE_SIZE}&page=1`;
export const LIBRARY_FILES_TREE = `/file/folders?count=${DEFAULT_PAGE_SIZE}&page=1`;
export const LIBRARY_ROOT_PATH = "/library";
export const FILE_REFRESH_PATH = "/file/renew";

export const INSPIRATIONS_GET_URL = "/inspiration";
export const INSPIRATION_URL = "/inspiration/:operator_code/:reference_code";

export const POI_URL = "/poi";
export const POI_GET_URL = `${POI_URL}/:id`;
export const POI_DELETE_UPDATE_URL = `${POI_URL}/:id`;
export const POI_ICONS_COUNT = `${POI_URL}/icon_count`;

export const CREATRIONS_URL = "/creation/:operator_code/:reference_code";
export const DELETE_CREATRION = `${CREATRIONS_URL}/:id`;

export const FLIGHTS = "/flight";
export const FLIGHTS_AIRLINES = `${FLIGHTS}/airlines`;
export const FLIGHTS_LEGS = `${FLIGHTS}/find_legs/:fs/:number/:date`;
export const FLIGHTS_LEGS_DETAILS = `${FLIGHTS}/find/:fs/:number/:from/:to/:date`;
export const FLIGHTS_MAP_URL = `${FLIGHTS}/map/:from-to`;

export const STAYS_FEATURES_URL = "/feature/stay";
export const USER_ID_AVAILABILITY_CHECK_URL = "/operator/check/operator_code/:operator_code";
export const PASSCODE_AVAILABILITY_CHECK_URL = "/itinerary/:operator_code/:reference_code/passcode_check";

export const DND_URL = `${ITINERARY_URL}/dnd`;
export const DND_APPROVAL_URL = `${DND_URL}/:id`;

export const FORCE_UPDATE_URL = "/:resource_type/:operator_code/:reference_code/force-update";

export const ITINERARY_UPDATE_URL = "/:resource_type/:operator_code/:reference_code/push";

export const API_KEYS_URL = "/user/me/keys";

export const RECENT_LOCATIONS_URL = "location/recent";
/**
 * CUSTOM APP
 */
export const CUSTOM_APP_DEFINITION_LIST_URL = "/definition/";
export const CUSTOM_APP_DEFINITION_BY_ID_URL = `${CUSTOM_APP_DEFINITION_LIST_URL}:id`;
export const CUSTOM_APP_DEFINITION_SUBMIT_URL = `${CUSTOM_APP_DEFINITION_LIST_URL}:id/submit`;
export const CUSTOM_APP_DEFINITION_DISCARD_URL = `${CUSTOM_APP_DEFINITION_LIST_URL}:id/discard`;
export const CUSTOM_APP_DEFINITION_ACCEPT_URL = `${CUSTOM_APP_DEFINITION_LIST_URL}:id/accept`;
export const CUSTOM_APP_DEFINITION_APPROVE_URL = `${CUSTOM_APP_DEFINITION_LIST_URL}:id/approve`;
export const CUSTOM_APP_DEFINITION_REJECT_URL = `${CUSTOM_APP_DEFINITION_LIST_URL}:id/reject`;
export const CUSTOM_APP_DEFINITION_REVERT_URL = `${CUSTOM_APP_DEFINITION_LIST_URL}:id/draft`;
export const CUSTOM_APP_DEFINITION_LIVE_STATUS_URL = `${CUSTOM_APP_DEFINITION_LIST_URL}:id/live`;

export const BUNDLE_LIST_CUSTOM_APP_URL = "/bundle/";
export const BUNDLE_DATA_CUSTOM_APP_URL = "/bundle/:id";
export const BUNDLE_VERSIONS_LIST_URL = "/bundle/:bundleId/versions";

/*
 * ADMIN PANEL
 */
export const ADMIN_ROOT = "/admin";
export const GET_ADMIN_OPERATORS_URL = `${ADMIN_ROOT}/operator/list`;
export const ADMIN_OPERATOR_DETAILS_URL = `${ADMIN_ROOT}/operator/:id`;
export const GET_ADMIN_USERS_URL = `${ADMIN_ROOT}/user/list`;
export const GET_ADMIN_USER_DETAILS_URL = `${ADMIN_ROOT}/user/:id`;
export const UPDATE_ADMIN_FEATURES = `${ADMIN_ROOT}/feature/stay/update`;
export const ADMIN_ICON_URL = `${ADMIN_ROOT}/poi/:id`;
export const ADMIN_CONNECT_STAYS_URL = `${ADMIN_ROOT}/stays`;
export const ADMIN_TOGGLE_CONNECT_STAYS_URL = `${ADMIN_ROOT}/stays/:id`;

/**
 * contact us
 */
export const CONTACT_US_URL = "/user/contact";

export const BULK_ACTIONS = "itinerary/bulk_actions";