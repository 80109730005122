import React from "react";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";

import { PasscodeRules } from "feature/panel/Stays/_shared/Passcodes/PasscodeRules";
import { PasscodeInputFields } from "feature/panel/Stays/_shared/Passcodes/PasscodeInputFields";
import { SortableBarElement } from "feature/panel/_shared/Sortable/SortableBarElement";

import { getHash } from "utils";
import { PASSCODES_LABELS } from "constants/content";

const PASSCODES_FIELDS = {
  passcodes: "passcodes",
  display_rules: "display_rules",
  name: "name",
};

const initNewPasscode = () => ({
  id: getHash(),
  passcode: "",
  description: "",
});

const PasscodeCategory = ({ index, category, isActive, onCategoryChange, onToggleActive, onCategoryDelete, disabled }) => {
  const { name, id, display_rules, passcodes } = category;

  const errors = useSelector(({ stayWizard }) => stayWizard.errors.passcodes);

  const handleCategoryChange = (fieldName, value) => onCategoryChange({ ...category, [fieldName]: value });
  const handlePasscodeObjectChange = (passcodeId, fieldName, value) => {
    const newPasscodesList = passcodes.map(passcode => (passcode.id === passcodeId ? { ...passcode, [fieldName]: value } : passcode));
    handleCategoryChange(PASSCODES_FIELDS.passcodes, newPasscodesList);
  };
  const handleNameChange = ({ target }) => handleCategoryChange(PASSCODES_FIELDS.name, target.value);
  const handlePasscodeChange = (passcodeId, { target }) => {
    const regex = /(%|\/)/i;
    const validPasscode = target.value.replace(regex, ""); // Prevent % usage

    handlePasscodeObjectChange(passcodeId, target.name, validPasscode);
  };
  const handleClickRotatedArrow = () => onToggleActive(id);
  const handleDelete = () => onCategoryDelete(id);
  const handlePasscodeDelete = passcodeId => {
    const newPasscodesList = passcodes.filter(passcode => passcode.id !== passcodeId);
    handleCategoryChange(PASSCODES_FIELDS.passcodes, newPasscodesList);
  };
  const handleRuleChange = (ruleName, value) => {
    const newRules = { ...display_rules, [ruleName]: value };
    handleCategoryChange(PASSCODES_FIELDS.display_rules, newRules);
  };
  const handlePasscodeAdd = () => {
    const newPasscode = initNewPasscode();
    const newPasscodesList = [...passcodes, newPasscode];
    handleCategoryChange(PASSCODES_FIELDS.passcodes, newPasscodesList);
  };

  return (
    <SortableBarElement
      isActive={isActive}
      index={index}
      title={name}
      onExpandToggle={handleClickRotatedArrow}
      onItemDelete={handleDelete}
      onTitleChange={handleNameChange}
      titleLabel={PASSCODES_LABELS.itemTitle}
      context="passcode_groups"
      errorFieldName="name"
      errors={errors}
      item={category}
      disabled={disabled}
    >
      <PasscodeRules disabled={disabled} rules={display_rules} onRuleChange={handleRuleChange} />
      <PasscodeInputFields
        onPasscodeChange={handlePasscodeChange}
        onPasscodeAdd={handlePasscodeAdd}
        onPasscodeDelete={handlePasscodeDelete}
        passcodes={passcodes}
        errors={errors?.[id]}
        disabled={disabled}
      />
    </SortableBarElement>
  );
};

PasscodeCategory.propTypes = {
  index: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  category: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string,
    display_rules: PropTypes.shape({}),
    passcodes: PropTypes.arrayOf(
      PropTypes.shape({})
    )
  }).isRequired,
  onToggleActive: PropTypes.func.isRequired,
  onCategoryChange: PropTypes.func.isRequired,
  onCategoryDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export { PasscodeCategory };
