import React, { useState, useReducer } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

import { BodyText } from "components/ui/Typography/Typography";
import { Table, TableHeader, TableBody, TablePagination } from "components/ui/Tables";
import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { PreviewModal } from "components/ui/Modals/PreviewModal";
import { CustomTableRow } from "feature/panel/Library/_shared/CustomTableRow/CustomTableRow";
import { FolderModal, FOLDER_MODAL_TYPE } from "feature/panel/Library/_shared/FolderModal/FolderModal";
import { LibraryTableActions } from "feature/panel/Library/_shared/LibraryTable/LibraryTableActions";
import { callFn } from "utils";
import { PANEL_TRIPS_ACTION_MESSAGES, TABLE_NAMES } from "constants/content";
import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";
import { columns } from "./tableColumns";

const NoContentWrapper = styled.div`
  min-height: calc(100vh - 208px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const previewInitState = {
  file: null,
  name: null,
  id: null,
};

function previewReducer(state, action) {
  switch (action.type) {
    case "open_popup":
      return {
        ...state,
        file: action.payload.file,
        name: action.payload.name,
        id: action.payload.id,
      };
    case "close_popup":
    default:
      return previewInitState;
  }
}

const LibraryTable = ({ list, noContentText, tableHandlers, count, isLoading, onRemoveItem, onEditItem }) => {
  const [filePreview, previewDispatch] = useReducer(previewReducer, previewInitState);
  const [openConfirationModal, setOpenConfirationModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [modalTitle, setModalTitle] = useState("");

  const { handleChangePage, handleChangeRowsPerPage, handleChangeOrder, page, rowsPerPage } = tableHandlers;

  const handleUpdate = (name, path, url) => {
    onEditItem(selectedItem, name, path, url);
  };

  const handleEdit = item => {
    setSelectedItem(item);
    setOpenEditModal(true);
    setModalTitle(`Edit ${item.is_folder ? "folder" : "file"}`);
  };

  const handleDelete = item => {
    setSelectedItem(item);
    setOpenConfirationModal(true);
  };

  const handleDeleteCancel = () => {
    setOpenConfirationModal(false);
    setSelectedItem({});
  };

  const handleDeleteConfirm = () => {
    onRemoveItem(selectedItem);
    handleDeleteCancel();
  };

  const handleCloseEditDialog = () => {
    setOpenEditModal(false);
    setSelectedItem({});
  };

  const onClickAction = callFn({
    edit: handleEdit,
    delete: handleDelete,
  });

  const deleteMessage = (
    <span>
      {PANEL_TRIPS_ACTION_MESSAGES.deleteConfirmationBase} {selectedItem.name}
      {selectedItem.is_folder ? "folder? Make sure the folder is empty before deleting!" : " file?"}
      <p>Any existing trips or stays using it will retain a copy of the current version of the file.</p>
      <p>
        If you intend to replace this file and uses of it within your trips or stays, please instead edit the library entry where you can
        update the file it is pointing to.
      </p>
    </span>
  );

  const onPreviewAction = payload => {
    previewDispatch({ type: "open_popup", payload });
  };

  if (isLoading && !list.length) {
    return <LoadingScreen />;
  }

  return (
    <>
      {!isLoading && !list.length ? (
        <NoContentWrapper>
          <BodyText cv="grey50">{noContentText}</BodyText>
        </NoContentWrapper>
      ) : (
        <Grid container justifyContent="center">
          <Grid item xs={12} md={12}>
            <Table
              headers={columns}
              list={list}
              actions={props => <LibraryTableActions onClickAction={onClickAction} {...props} />}
              onChangeOrder={handleChangeOrder}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              isDataLoading={isLoading}
            >
              <TableHeader withActions actionsProps={{ style: { width: "150px" } }} tableId={TABLE_NAMES.library} />
              <TableBody rowComponent={props => <CustomTableRow onClickAction={onPreviewAction} {...props} />} />
              <TablePagination count={count} page={page - 1} rowsPerPage={rowsPerPage} tableId={TABLE_NAMES.library} />
            </Table>
          </Grid>
        </Grid>
      )}
      <PreviewModal
        file={filePreview.file}
        name={filePreview.name}
        id={filePreview.id}
        preventDialogFullWidth
        onClose={() => {
          previewDispatch({ type: "close_popup" });
        }}
      />
      {openEditModal && (
        <FolderModal
          title={modalTitle}
          isOpen={openEditModal}
          onSave={handleUpdate}
          value={selectedItem.name || ""}
          path={selectedItem.path || ""}
          type={selectedItem.is_folder ? FOLDER_MODAL_TYPE.folder : FOLDER_MODAL_TYPE.file}
          isFolder={selectedItem.is_folder}
          originalItem={selectedItem}
          onClose={handleCloseEditDialog}
          mode="edit"
        />
      )}
      {openConfirationModal && (
        <ConfirmationModal
          open={openConfirationModal}
          title="Delete confirmation"
          confirmLabel="Delete"
          description={deleteMessage}
          onCancel={handleDeleteCancel}
          onConfirm={handleDeleteConfirm}
        />
      )}
    </>
  );
};

LibraryTable.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  noContentText: PropTypes.string.isRequired,
  tableHandlers: PropTypes.shape({
    handleChangePage: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func,
    handleChangeOrder: PropTypes.func,
    handleChangeQuery: PropTypes.func,
    handleSearch: PropTypes.func,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
  }).isRequired,
  count: PropTypes.number.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  onEditItem: PropTypes.func.isRequired,
};

export { LibraryTable, NoContentWrapper };
