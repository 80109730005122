import React, { useState, useMemo, useContext } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";

import listColumns from "feature/panel/Trips/_shared/Creations/listColumns";

import { Table, TableHeader, TableBody, TablePagination } from "components/ui/Tables";
import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { PreviewModal } from "components/ui/Modals/PreviewModal";
import { Message } from "components/ui/Messages";

import { TableRowActions } from "feature/panel/_shared/CreationsAndPublishers/TableRowActions";
import { ListActionsContext } from "feature/panel/_shared/CreationsAndPublishers/ListActionsContext";
import { TableRow } from "feature/panel/_shared/CreationsAndPublishers/TableRow";

import { handleDownload, useDeleteHandler } from "feature/panel/_shared/CreationsAndPublishers/handlers";

import { useTableHandlers } from "hooks/useTableHandlers";
import { useJobStatus } from "hooks/useJobStatus";

import {
  getStaysPublishersStart,
  clearStaysPublisherJob,
  refreshStaysPublisherStart,
  deleteStaysPublisherStart,
  closePublisherModal,
  createStaysPublisherStart,
  createOrUpdateStayValidationFail,
} from "store/stays/actions";

import config from "config/app";
import {
  CREATE_CONTENT_LABELS,
  EMPTY_LIST_MESSAGES_BASE,
  GLOBAL_CONTENT,
  NAMES_OF_RESOURCES_LISTS,
  TABLE_ACTIONS_TITLES,
} from "constants/content";
import { setUrlParams } from "utils/url";
import { isObjectEmpty } from "utils/object";

import { CreationsService } from "services/CreationsService";
import { PublisherService } from "services/PublisherService";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { UNSAVED_CHANGES_MESSAGE } from "constants/notifications";
import { StayValidator } from "services/domain/StayValidator";
import { setStayWizardTouched, stayWizardSetErrors } from "store/stayWizard/actions";
import { LanguageContext } from "components/_shared/LanguageSelector/LanguageContext";
import { useService } from "hooks/useService";
import { StayService } from "services/domain/StayService";

import { PublisherCreateModal } from "./PublisherCreateModal";
import { updateStayOriginalIndexes } from "../helpers";


const TableWrapper = styled.div`
  .MuiTable-root {
    .MuiTableCell-head {
      &:first-of-type {
        width: 90px;
      }
    }
  }
`;

const UnsavedChangesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  button {
    margin-top: 16px;
  }
`;

const PublishersList = ({ onEditStart, url }) => {
  const permissionsService = usePermissionsService();
  const dispatch = useDispatch();
  const { operator_code } = useParams();
  const [selectedPublisher, setSelectedPublisher] = useState(null);
  const [refreshPublisherPopup, setRefreshPublisherPopup] = useState({ open: false, body: null });

  const { currentLanguage } = useContext(LanguageContext);
  const stayService = useService(StayService);

  const isStayDefaultLanguage = useSelector(
    ({ stayWizard }) => stayWizard.defaultVariant.language ? stayWizard.defaultVariant.language === currentLanguage.code : true
  )

  const publisher = useSelector(({ stays }) => stays.publisherModal.form);
  const wizardForm = useSelector(({ stayWizard }) => stayWizard.form);
  const vamoosId = useSelector(({ stayWizard }) => stayWizard.form.vamoosId);
  const propertyName = wizardForm?.propertyName;
  const coordinates = wizardForm?.coordinates;
  const isStayFormTouched = useSelector(({ stayWizard }) => stayWizard.touched);
  const publishers = useSelector(state => state.stays.publishers);
  const publishersCount = useSelector(state => state.stays.publishersCount);

  const [inProgress, setInProgress] = useState(false);

  const editMode = vamoosId !== null;

  const isWiped = useSelector(({ stayWizard }) => stayWizard.form.isWiped);

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoosId);

  const { handleChangePage, handleChangeRowsPerPage, handleChangeOrder, page, rowsPerPage } = useTableHandlers(
    url,
    getStaysPublishersStart,
  );

  const { modalToggle, modal, confirm, confirmText } = useDeleteHandler(operator_code, "DEFAULT", ({ reference_code, id }) => {
    return deleteStaysPublisherStart({
      reference_code,
      operator_code,
      id,
      url: setUrlParams(url, {
        page: publishers.length === 1 && page > 1 ? page - 1 : page,
        count: rowsPerPage,
      }),
    });
  });

  useJobStatus(publishers, (id, status) => {
    dispatch(
      clearStaysPublisherJob({
        id,
        status,
        url: setUrlParams(url, {
          page,
          count: rowsPerPage,
        }),
      }),
    );
  });

  const publishersList = useMemo(
    () =>
      publishers.map((creation, index) => ({
        ...creation,
        number: index + 1,
        time: moment(creation.created_at).format(config.dateFormat),
      })),
    [publishers],
  );

  const handleRefresh = item => {
    const data = CreationsService.setupRefreshCreationPayload(item);
    dispatch(refreshStaysPublisherStart({ url, data }));
  };

  const handleModalConfirm = () => {
    const data = PublisherService.preparePublisherPayload(publisher, propertyName, coordinates);
    dispatch(
      createStaysPublisherStart({
        data,
        operator_code,
        url: setUrlParams(url, {
          page,
          count: rowsPerPage,
        }),
      }),
    );
    dispatch(closePublisherModal());
  };

  const handlePageSizeChange = newSize => {
    if (page > 1 && (page - 1) * newSize > publishersCount) {
      handleChangeRowsPerPage(newSize);
      handleChangePage(Math.ceil(publishersCount / newSize) - 1);
    } else {
      handleChangeRowsPerPage(newSize);
    }
  };

  const handleSaveStay = async () => {
    const wizardErrors = StayValidator(wizardForm, isStayDefaultLanguage);

    dispatch(stayWizardSetErrors(wizardErrors));

    if (isObjectEmpty(wizardErrors)) {
      setInProgress(true);
      updateStayOriginalIndexes(wizardForm);

      wizardForm.newOnly = false;
      
      await stayService.saveStayVariant(wizardForm.userId, wizardForm, null, currentLanguage?.code);

      setInProgress(false);

      dispatch(setStayWizardTouched(false));

      if (refreshPublisherPopup.open) {
        handleRefresh(refreshPublisherPopup.body);
        setRefreshPublisherPopup({ open: false, body: null });
      }
    } else {
      dispatch(stayWizardSetErrors(wizardErrors));
      dispatch(createOrUpdateStayValidationFail());
    }
  };

  const onConfirm = async () => {
    await handleSaveStay();
  };

  const onCancel = () => {
    setRefreshPublisherPopup({ open: false, body: null });
  };

  const listActionsValues = {
    handleDelete: modalToggle,
    handleEdit: onEditStart,
    handleDownload,
    handleRefresh,
  };

  const content = (() => {
    if (!publishersList.length) {
      const showAddNewPublisherMessage = editMode ? canEdit : true;

      return showAddNewPublisherMessage ? (
        <Message type="info" text={EMPTY_LIST_MESSAGES_BASE(CREATE_CONTENT_LABELS.publisher, NAMES_OF_RESOURCES_LISTS.publisher)} />
      ) : null;
    }
    
    return (
      <>
        <Table
          headers={listColumns}
          list={publishersList}
          actions={({ item }) => (
            <TableRowActions
              item={item}
              context="publisher"
              canEdit={!isWiped && canEdit}
              canDelete={!isWiped && canEdit}
              hasUnsavedChanges={isStayFormTouched}
              onSelectRefreshItem={setRefreshPublisherPopup}
            />
          )}
          onChangeRowsPerPage={handlePageSizeChange}
          onChangeOrder={handleChangeOrder}
          onChangePage={handleChangePage}
          count={publishersCount}
          noPaper
          withBorder
        >
          <TableHeader withActions />
          <TableBody rowComponent={props => <TableRow onClickName={setSelectedPublisher} {...props} />} />
          <TablePagination />
        </Table>
        <PreviewModal
          file={selectedPublisher?.outputs?.print?.file}
          name={selectedPublisher?.name}
          onClose={() => setSelectedPublisher(null)}
        />
      </>
    );
  })();

  return (
    <TableWrapper>
      <ListActionsContext.Provider value={listActionsValues}>{content}</ListActionsContext.Provider>
      {modal.open && (
        <ConfirmationModal
          open={modal.open}
          title={confirmText}
          confirmLabel={GLOBAL_CONTENT.delete}
          onCancel={modalToggle}
          onConfirm={confirm}
        />
      )}
      <PublisherCreateModal onConfirm={handleModalConfirm} />
      {refreshPublisherPopup.open && (
        <ConfirmationModal
          open={refreshPublisherPopup.open}
          title={TABLE_ACTIONS_TITLES.refreshResource(TABLE_ACTIONS_TITLES.resourceTypes.creation)}
          confirmLabel={GLOBAL_CONTENT.save}
          onCancel={onCancel}
          onConfirm={onConfirm}
          confirmDisabled={!isStayFormTouched}
          showSpinner={inProgress}
          maxWidth="md"
        >
          <UnsavedChangesWrapper>
            <Message text={UNSAVED_CHANGES_MESSAGE} type="info" />
          </UnsavedChangesWrapper>
        </ConfirmationModal>
      )}
    </TableWrapper>
  );
};

PublishersList.propTypes = {
  onEditStart: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
};

export { PublishersList, TableWrapper };
