import React from "react";
import { useFieldArray } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import Table from "components/_new/Table";
import { Message } from "components/ui/Messages";
import TitleRow from "feature/panel/Itinerary/components/TitleRow";
import { Wrapper } from "feature/panel/Itinerary/style";
import { LanguageLock } from "feature/panel/Stays/_shared/LanguageLock";
import { useTableHandlers } from "hooks/useTableHandlers";
import MobileRow from "../../Trips/Locations/Pois/MobileItem";
import columns from "../../Trips/Locations/Pois/columns";
import { createSearch } from "../../Trips/Locations/helpers";
import { extractCoordinates } from "../../../../../../utils";
import {
  CREATE_ONE_LABEL,
  DEFINE_LOCATION_LABEL,
  NO_HOTEL_LOCATION,
  NO_POIS_FOUND_FOR_LOCATIONS,
  STAY_POIS
} from "../../../../../../constants/content";
import { StyledLink } from "./style";
import { PANEL_POIS_PATH } from "../../../../../../constants/routes";
import { HttpClient } from "../../../../../../services/application/httpClient/httpClient";
import { LoadingScreen } from "../../../../../../components/ui/LoadingScreen/LoadingScreen";

const POIs = ({ form: { control, watch, setValue }, canEdit, isWiped, isDefaultStayLanguage }) => {
  const { fields: pois = [], append, update, remove, replace } = useFieldArray({
    control,
    name: "locations.pois",
    keyName: "rf_id",
  });
  const tableId = "stayPois";
  const tableHandlers = useTableHandlers("", () => {}, { params: { type: tableId } });
  const { page, rowsPerPage } = tableHandlers;
  const { reference_code, operator_code } = useParams();

  const stayLocation = watch(isDefaultStayLanguage ? "locations.locations" : "main.locations.locations") || [];
  const isEnabled = Boolean(
    stayLocation?.length > 0 &&
    stayLocation[0].coordinates &&
    stayLocation[0].coordinates !== ''
  );

  const { data: stayPoisData, isLoading } = useQuery({
    queryKey: isEnabled ? ["stayPois", rowsPerPage, page, operator_code, reference_code] : null,
    queryFn: () => HttpClient.get(`/poi?page=${page}&count=${rowsPerPage}${createSearch(stayLocation.map(item => {
      const { latitude, longitude } = extractCoordinates(item?.coordinates);
      return { latitude, longitude };
    }))}`),
    refetchOnMount: true,
    enabled: isEnabled,
    onSuccess: (res) => {
      if (res.data?.items?.length) {
        const mainPois = isDefaultStayLanguage ? pois : watch("main.locations.pois");
        const updatedPois = res.data.items.map(sharedPoi => {
          const foundPoi = mainPois.find(poi => poi.id === sharedPoi.id);
          sharedPoi.is_on = foundPoi ? foundPoi.is_on : sharedPoi.is_default_on;
          const hasLocalisedVersion = !isDefaultStayLanguage && !isEmpty(sharedPoi?.localisation) && sharedPoi.localisation[watch("general.language")];

          return hasLocalisedVersion
            ? {
              ...sharedPoi,
              ...sharedPoi.localisation[watch("general.language")],
            }
            : { ...sharedPoi };
        });
        setValue("locations.pois", updatedPois);
      } else {
        setValue("locations.pois", []);
      }
    },
  });

  const onChange = (row, index) => update(index, { ...row, is_on: !row.is_on });

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <Wrapper slimTopPadding>
          {isEnabled ?
            <>
              <TitleRow isSticky text={<span>POIs {!isDefaultStayLanguage && <LanguageLock />}</span>} />

              <div style={{ height: 20 }} />

              {pois?.length ? (
                <>
                  <Table
                    rows={pois}
                    columns={columns({ onChange, disabled: !canEdit || isWiped || !isDefaultStayLanguage })}
                    renderRowMobile={<MobileRow onChange={onChange} isChecked={item => item.is_on} />}
                    {...tableHandlers}
                    totalMatches={stayPoisData?.data?.total_matches}
                    isSorting={false}
                    tableId={tableId}
                  />
                  <div style={{ marginTop: "40px" }}>
                    <Message type="info" text={STAY_POIS.toAddMessage} />
                  </div>
                </>
              ) : (
                <Message type="info" text={NO_POIS_FOUND_FOR_LOCATIONS}>
                  <StyledLink to={PANEL_POIS_PATH}>{CREATE_ONE_LABEL}</StyledLink>
                </Message>
              )}
            </>
            :
            <div style={{ padding: "75px 0px" }}>
              <Message type="info" text={NO_HOTEL_LOCATION}>
                <StyledLink to={window.location.pathname.replace("pois", "general")}>{DEFINE_LOCATION_LABEL}</StyledLink>
              </Message>
            </div>
          }
        </Wrapper>
        )}
    </>
  );
};

export default POIs;
