import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import { Paper } from "feature/panel/Help/styledComponents";

import columns from "feature/panel/UserSettings/TableOfApiKeysColumns.json";

import { Table, TableHeader, TableBody } from "components/ui/Tables";
import { DeleteAction, ShowAction, TableActionsContainer } from "components/ui/Tables/TableRowActions";
import { BodyText2 } from "components/ui/Typography/Typography";
import { Spinner } from "components/ui/Spinner/Spinner";

import { getApiKeysStart, deleteApiKeyStart, setNotification } from "store/app/actions";

import { copySuccess, copyFailed } from "constants/notifications";
import { NO_API_KEYS_MESSAGE } from "constants/content";

import { copyToClipboard } from "utils";

const StyledPaper = styled(Paper)`
  && {
    margin-top: 40px;
  }
`;

const TablePaper = styled(StyledPaper)`
  && {
    padding: 0px;
  }
`;

const MessageArea = styled.div`
  width: 100%;
  text-align: center;
`;

const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TableOfApiKeys = () => {
  const { apiKeys, inProgress, finished } = useSelector(state => state.app);

  const resourceName = "API key";

  const dispatch = useDispatch();

  const getApiKeys = () => {
    dispatch(getApiKeysStart());
  };

  const dispatchNotification = (type, message) => {
    dispatch(
      setNotification({
        type,
        message,
      }),
    );
  };

  const bodyCellActions = ({ item: { id, api_key } }) => (
    <TableActionsContainer>
      <ShowAction
        clickAction={() =>
          copyToClipboard(
            api_key,
            () => dispatchNotification("success", copySuccess(resourceName)),
            () => dispatchNotification("error", copyFailed(resourceName)),
          )
        }
        tooltip="Copy key"
      />
      <DeleteAction clickAction={() => dispatch(deleteApiKeyStart({ id }))} tooltip="Delete key" />
    </TableActionsContainer>
  );

  const renderContent = (() => {
    if (inProgress && !finished)
      return (
        <StyledPaper>
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        </StyledPaper>
      );
    if (!inProgress && finished && !apiKeys.length)
      return (
        <StyledPaper>
          <MessageArea>
            <BodyText2>{NO_API_KEYS_MESSAGE}</BodyText2>
          </MessageArea>
        </StyledPaper>
      );
    return (
      <TablePaper>
        <Table list={apiKeys} headers={columns.columns} actions={bodyCellActions} noPaper>
          <TableHeader withActions />
          <TableBody />
        </Table>
      </TablePaper>
    );
  })();

  useEffect(getApiKeys, []);

  return renderContent;
};

export { TableOfApiKeys, StyledPaper, TablePaper, MessageArea, SpinnerWrapper };
