import { NOTIFICATION_TYPES } from "constants/content"

export const LIST_COLUMNS = {
  [NOTIFICATION_TYPES.gps]: [
    { label: "Template", key: "id", show: true, size: "small", sortable: true },
    { label: "Notification text", key: "content", show: true, sortable: true },
    { label: "Notification URL", key: "url", show: true, sortable: true },
    { label: "Active from", key: "start_at", show: true, sortable: false },
    { label: "Active to", key: "end_at", show: true, sortable: false },
    { label: "Location (latitude, longitude)", key: "location", show: true, sortable: false },
  ],
  [NOTIFICATION_TYPES.timed]: [
    { label: "Template", key: "id", show: true, size: "small", sortable: true },
    { label: "Notification text", key: "content", show: true, sortable: true },
    { label: "Notification URL", key: "url", show: true, sortable: true },
    { label: "Notification Period", key: "delivery_at_days_delivery_at_relative_to", show: true, sortable: false },
    { label: "Device local time", key: "start_at", show: true, sortable: false },
  ],
  [NOTIFICATION_TYPES.forced]: [
    { label: "Template", key: "id", show: true, size: "small", sortable: true },
    { label: "Notification text", key: "content", show: true, sortable: true },
  ],
}