import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import uuidv4 from "uuid";

import { Tab } from "components/ui/Tabs/Tab";
import { Tabs } from "components/ui/Tabs/Tabs";
import { Content } from "components/ui/Content";
import { useManageTripContext } from "feature/panel/Trips/_shared/ManageTripContext";
import { DocumentsContent } from "feature/panel/Trips/_shared/Documents/DocumentsContent";

import { toCamelCase } from "utils/string";
import { formatDisplayableLibraryPopupValues } from "utils/library";
import { setFileNameAfterUpload } from "utils/validation";
import { ListIndexService } from "services/ListIndexService";
import { StorageService } from "services/StorageService";

const Documents = () => {
  const listIndexService = new ListIndexService(new StorageService());
  const [currentTab, setCurrentTab] = useState(0);

  const {
    setValueFor,
    destinationDocuments,
    destinationDocumentsTitle,
    destinationDocumentsIcon,
    travelDocuments,
    travelDocumentsTitle,
    travelDocumentsIcon,
  } = useManageTripContext();

  const lists = {
    destinationDocuments,
    travelDocuments,
  };

  const handleChange = (type, key, value) => {
    setValueFor(toCamelCase(type, key), value);
  };

  const handleDestinationChanges = (key, value) => handleChange("destinationDocuments", key, value);
  const handleTravelChanges = (key, value) => handleChange("travelDocuments", key, value);

  const handleRemoveDocument = (type, id) => {
    const list = lists[type];
    const newList = list.filter(item => item.id !== id);
    listIndexService.removeFromOriginalIndexes(id, type);
    setValueFor(type, newList);
  };

  const handleChangeFileInputValue = (type, id, item) => {
    const list = lists[type].map(el => (el.id === id ? item : el));
    setValueFor(type, list);
  };

  const handleAddButtonClick = (type, value) => {
    setValueFor(type, [...lists[type], value]);
  };

  const handleFilesUpload = (type, id, files) => {
    const newFiles = formatDisplayableLibraryPopupValues(files);
    let list = lists[type].map(el => (el.id === id ? { ...el, ...newFiles[0], name: setFileNameAfterUpload(newFiles[0], el.name) } : el));
    const newDocumentsCreatedFromAdditionalFiles = newFiles.splice(1).map(file => ({
      id: uuidv4(),
      name: file.file_name,
      restricted_to_traveller_internal_ids: null,
      ...file,
    }));
    list = [...list, ...newDocumentsCreatedFromAdditionalFiles];
    setValueFor(type, list);
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={10}>
        <Content margin={10}>
          <Tabs value={currentTab} onChange={(event, newValue) => setCurrentTab(newValue)}>
            <Tab label="Travel documents" />
            <Tab label="Destination documents" />
          </Tabs>
        </Content>
      </Grid>
      <Grid item xs={12} md={10}>
        {currentTab === 0 ? (
          <DocumentsContent
            onChange={handleTravelChanges}
            list={travelDocuments}
            title={travelDocumentsTitle}
            icon={travelDocumentsIcon}
            name="travelDocuments"
            sectionTitle="Travel documents"
            onRemove={id => handleRemoveDocument("travelDocuments", id)}
            onFilesUpload={(id, files) => handleFilesUpload("travelDocuments", id, files)}
            onListChange={(id, item) => handleChangeFileInputValue("travelDocuments", id, item)}
            onAddDocument={value => handleAddButtonClick("travelDocuments", value)}
          />
        ) : (
          <DocumentsContent
            onChange={handleDestinationChanges}
            list={destinationDocuments}
            title={destinationDocumentsTitle}
            icon={destinationDocumentsIcon}
            sectionTitle="Destination documents"
            name="destinationDocuments"
            onRemove={id => handleRemoveDocument("destinationDocuments", id)}
            onFilesUpload={(id, files) => handleFilesUpload("destinationDocuments", id, files)}
            onListChange={(id, item) => handleChangeFileInputValue("destinationDocuments", id, item)}
            onAddDocument={value => handleAddButtonClick("destinationDocuments", value)}
          />
        )}
      </Grid>
    </Grid>
  );
};

export { Documents };
