import React, { useEffect, useMemo, useReducer, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import uuidv4 from "uuid";
import { useSelector, useDispatch } from "react-redux";

import { useManageTripContext } from "feature/panel/Trips/_shared/ManageTripContext";

import listColumns from "feature/panel/Trips/_shared/Creations/listColumns";

import { handleDownload, useDeleteHandler } from "feature/panel/_shared/CreationsAndPublishers/handlers";
import { ListActionsContext } from "feature/panel/_shared/CreationsAndPublishers/ListActionsContext";
import { TableRowActions } from "feature/panel/_shared/CreationsAndPublishers/TableRowActions";
import { TableRow } from "feature/panel/_shared/CreationsAndPublishers/TableRow";

import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { Table, TableHeader, TableBody, TablePagination } from "components/ui/Tables";
import { PreviewModal } from "components/ui/Modals/PreviewModal";

import {
  CREATE_CONTENT_LABELS,
  CREATION_NOTIFICATIONS,
  EMPTY_LIST_MESSAGES_BASE,
  GLOBAL_CONTENT,
  NAMES_OF_RESOURCES_LISTS,
  TABLE_ACTIONS_TITLES,
} from "constants/content";
import { UNSAVED_CHANGES_MESSAGE } from "constants/notifications";

import { getTripCreationsStart, deleteTripCreationStart, refreshTripCreationStart, clearTripCreationJob } from "store/trips/actions";
import { setNotification } from "store/app/actions";
import { CREATE_OR_UPDATE_TRIP_START, CREATE_OR_UPDATE_TRIP_SUCCESS } from "store/trips/actionTypes";

import { useTableHandlers } from "hooks/useTableHandlers";
import { useJobStatus } from "hooks/useJobStatus";

import { isObject } from "contracts/types";
import { Message } from "components/ui/Messages";
import { CreationsService } from "services/CreationsService";
import { preUpdateTripPayload } from "utils/dataConverters";
import { usePayloadValidator } from "hooks/usePayloadValidator";
import { createTripHandler } from "feature/panel/Trips/_shared/helpers";
import { PERMISSIONS } from "constants/permissions";
import { usePermissionsService } from "hooks/usePermissionsService";

const TableWrapper = styled.div`
  .MuiTable-root {
    .MuiTableCell-head {
      &:first-of-type {
        width: 90px;
      }
    }
  }
`;

const UnsavedChangesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  button {
    margin-top: 16px;
  }
`;

const previewInitState = {
  file: null,
  name: null,
  id: null,
};

const previewReducer = (state, { type, payload }) => {
  switch (type) {
    case "open_popup":
      return { ...state, ...payload };
    case "close_popup":
    default:
      return previewInitState;
  }
};

const CreationsList = ({ handleEditStart, url }) => {
  const dispatch = useDispatch();
  const [creationPreview, previewDispatch] = useReducer(previewReducer, previewInitState);
  const [refreshCreationPopup, setRefreshCreationPopup] = useState({ open: false, body: null });
  const [isRefreshInProgress, setIsRefreshInProgress] = useState(false);

  const { listOfCreations, creationsCount, isTripFormTouched, inProgress, finished, actionType } = useSelector(state => state.trips);
  const { timedNotificationsTemplates, gpsNotificationsTemplates } = useSelector(state => state.notifications);

  const permissionsService = usePermissionsService();

  const tripsForm = useManageTripContext();
  const { userId, destinationDocuments, travelDocuments, setValueFor, currentPasscode } = tripsForm;
  const { handleChangePage, handleChangeRowsPerPage, handleChangeOrder } = useTableHandlers(url, getTripCreationsStart);
  const { modalToggle, modal, confirm, confirmText } = useDeleteHandler(userId, currentPasscode, deleteTripCreationStart);
  const { checkValidation } = usePayloadValidator("trips");

  const saveTripInProgress = inProgress && actionType === CREATE_OR_UPDATE_TRIP_START;

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, tripsForm.vamoos_id);

  useJobStatus(listOfCreations, (id, status) => {
    dispatch(clearTripCreationJob({ id, status, url }));
  });

  const creationsList = useMemo(
    () =>
      listOfCreations.map((creation, index) => ({
        ...creation,
        number: index + 1,
        time: `${creation.time} ${creation.date}`,
      })),
    [listOfCreations],
  );

  const handleRefresh = item => {
    const data = CreationsService.setupRefreshCreationPayload(item);
    dispatch(refreshTripCreationStart({ url, data }));
  };

  const handlePreview = file => {
    if (!isObject(file)) return null;

    const { name, id, outputs } = file;

    previewDispatch({ type: "open_popup", payload: { file: outputs.web.file, name, id } });
  };

  const handleAdd = (item, documentType) => {
    // eslint-disable-next-line no-console
    const documentsNameEnums = {
      travel: "travelDocuments",
      destination: "destinationDocuments",
    };
    const documentList = {
      travel: travelDocuments,
      destination: destinationDocuments,
    };
    const { s3_url, https_url, id: file_id } = item.outputs.web.file;
    const { id } = item.outputs.web;

    setValueFor(documentsNameEnums[documentType], [
      ...documentList[documentType],
      {
        id: uuidv4(),
        name: item.name,
        preview_url: https_url,
        library_node_id: id,
        file_id,
        file_name: s3_url.split("/").pop(),
      },
    ]);
    dispatch(
      setNotification({
        type: "success",
        message: CREATION_NOTIFICATIONS[documentType](item.name),
      }),
    );
  };

  const listActionsValues = {
    handleDelete: modalToggle,
    handleEdit: handleEditStart,
    handleDownload,
    handleRefresh,
    handleAdd,
  };

  const handleUpdateTrip = async () => {
    const formData = { ...tripsForm, passcode: tripsForm.passcode.trim(), userId: tripsForm.userId.trim() };
    const payload = await preUpdateTripPayload(formData, [...timedNotificationsTemplates, ...gpsNotificationsTemplates]);
    createTripHandler(payload, checkValidation, dispatch);
  };

  const onCancel = () => {
    setRefreshCreationPopup({ open: false, body: null });
  };

  const onConfirm = () => {
    setIsRefreshInProgress(true);
    handleUpdateTrip();
  };

  const listColumnsItems = (() => {
    return listColumns.filter(item => item.key !== "number");
  })();

  useEffect(() => {
    if (isRefreshInProgress && finished && actionType === CREATE_OR_UPDATE_TRIP_SUCCESS) {
      handleRefresh(refreshCreationPopup.body);
      setRefreshCreationPopup({ open: false, body: null });
      setIsRefreshInProgress(false);
    }
  }, [isRefreshInProgress, finished, actionType]);

  const canAddNewCreation = !tripsForm.is_wiped && canEdit;

  const emptyCreationsListMessage = canAddNewCreation ? (
    <Message type="info" text={EMPTY_LIST_MESSAGES_BASE(CREATE_CONTENT_LABELS.creation, NAMES_OF_RESOURCES_LISTS.creation)} />
  ) : null;

  const content = creationsList.length ? (
    <>
      <Table
        headers={listColumnsItems}
        list={creationsList}
        actions={({ item }) => (
          <TableRowActions
            item={item}
            context="creation"
            hasUnsavedChanges={isTripFormTouched}
            onSelectRefreshItem={setRefreshCreationPopup}
            canCreate={!tripsForm.is_wiped && canEdit}
            canEdit={!tripsForm.is_wiped && canEdit}
            canDelete={!tripsForm.is_wiped && canEdit}
          />
        )}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onChangeOrder={handleChangeOrder}
        count={creationsCount}
      >
        <TableHeader withActions />
        <TableBody rowComponent={props => <TableRow onClickName={handlePreview} hideIndexColumn {...props} />} />
        <TablePagination />
      </Table>
      <PreviewModal
        file={creationPreview.file}
        name={creationPreview.name}
        id={creationPreview.id}
        onClose={() => previewDispatch({ type: "close_popup" })}
      />
    </>
  ) : (
    emptyCreationsListMessage
  );

  return (
    <TableWrapper>
      <ListActionsContext.Provider value={listActionsValues}>{content}</ListActionsContext.Provider>
      {modal.open && (
        <ConfirmationModal open={modal.open} title={confirmText} confirmLabel="Delete" onCancel={modalToggle} onConfirm={confirm} />
      )}
      {refreshCreationPopup.open && (
        <ConfirmationModal
          open={refreshCreationPopup.open}
          title={TABLE_ACTIONS_TITLES.refreshResource(TABLE_ACTIONS_TITLES.resourceTypes.creation)}
          confirmLabel={GLOBAL_CONTENT.save}
          onCancel={onCancel}
          onConfirm={onConfirm}
          confirmDisabled={!isTripFormTouched}
          showSpinner={saveTripInProgress}
          maxWidth="md"
        >
          <UnsavedChangesWrapper>
            <Message text={UNSAVED_CHANGES_MESSAGE} type="info" />
          </UnsavedChangesWrapper>
        </ConfirmationModal>
      )}
    </TableWrapper>
  );
};

CreationsList.propTypes = {
  handleEditStart: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
};

export { CreationsList, TableWrapper };
