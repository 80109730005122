import iso6391 from "iso-639-1";

const useListOfLanguages = () => {
  const codes = iso6391.getAllCodes();

  return codes.map(code => ({
    code,
    name: iso6391.getName(code),
  }));
};

const useListOfLanguagesObject = () => {
  const codes = iso6391.getAllCodes();

  const result = {};

  codes.forEach(
    code =>
      (result[code] = {
        name: iso6391.getName(code),
      }),
  );
  return result;
};

export { useListOfLanguages, useListOfLanguagesObject };
