import { setSpacing } from "config/theme/spacing";

const setLineHeight = value => value + setSpacing(2);

const font = "Montserrat";

const fontWeight = {
  bold: 700,
  semiBold: 600,
  default: 400,
};

const fontSize = {
  h1: setSpacing(18),
  h2: setSpacing(15),
  h3: setSpacing(12),
  h4: setSpacing(9),
  h5: setSpacing(6),
  h6: setSpacing(5),
  title: setSpacing(9),
  title2: setSpacing(5),
  title3: setSpacing(4),
  title4: setSpacing(3),
  lead: setSpacing(6),
  body: setSpacing(5),
  body2: setSpacing(4),
  body3: setSpacing(3),
  caption: setSpacing(3),
};

const lineHeight = {
  h1: setLineHeight(fontSize.h1),
  h2: setLineHeight(fontSize.h2),
  h3: setLineHeight(fontSize.h3),
  h4: setLineHeight(fontSize.h4),
  h5: setLineHeight(fontSize.h5),
  h6: setLineHeight(fontSize.h6),
  title: setLineHeight(fontSize.title),
  title2: setLineHeight(fontSize.title2),
  title3: setLineHeight(fontSize.title3),
  title4: setLineHeight(fontSize.title4),
  lead: setLineHeight(fontSize.lead),
  body: setLineHeight(fontSize.body),
  body2: setLineHeight(fontSize.body2),
  body3: setLineHeight(fontSize.body3),
  caption: setLineHeight(fontSize.caption),
};

export { font, fontWeight, fontSize, lineHeight };
