import React, { useState, useEffect } from "react";
import styled from "styled-components";

import columns from "feature/panel/UserSettings/TableOfApiKeysColumns.json";

import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";

import { useSelector, useDispatch } from "react-redux";

import { Table, TableHeader, TableBody } from "components/ui/Tables";
import { DeleteAction, TableActionsContainer } from "components/ui/Tables/TableRowActions";

import { Paper } from "feature/panel/Help/styledComponents";
import { USER_OPERATOR_MESSAGE } from "constants/content";
import { deleteUserFromOperatorStart } from "store/app/actions";

import { isEmpty } from "lodash";

const StyledPaper = styled(Paper)`
  && {
    margin-top: 40px;
  }
`;

const TablePaper = styled(StyledPaper)`
  && {
    padding: 0px;
  }
`;

const MessageArea = styled.div`
  width: 100%;
  text-align: center;
`;

const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TableOfOperators = () => {
  const [parsedOperators, setParsedOperators] = useState([]);
  const [deleteModal, setDeleteModal] = useState({ open: false, body: {} });
  const { operators, currentOperatorCode } = useSelector(state => state.auth);
  const { finished, inProgress, errors } = useSelector(state => state.app);
  const dispatch = useDispatch();

  const handleDeleteConfirmation = ({ item }) => {
    setDeleteModal({ open: true, body: { ...item } });
  };

  const handleDeleteOperatorConfirm = () => {
    const { code, id, userId } = deleteModal.body;

    dispatch(deleteUserFromOperatorStart({ code, userId, id }));
    setDeleteModal({ open: false, body: {} });
  };

  const handleDeleteOperatorCancel = () => {
    setDeleteModal({ open: false, body: {} });
  };

  const parseOperatorsData = () => {
    const parsedOperatorList = operators.map(({ code, name, role, user_id, id, ...rest }) => ({
      id,
      code,
      name: code !== "ADMIN" ? `${name} (${code})` : name,
      roleName: role?.name,
      user_id,
      ...rest,
    }));

    setParsedOperators(parsedOperatorList);
  };

  const deletedResourceName = deleteModal.body && deleteModal.body.name;

  const confirmationModalClose = () => {
    if (finished || !isEmpty(errors)) setDeleteModal({ open: false, body: {} });
  };

  const rowActionsCell = ({ item }) => {
    const { code } = item;

    return (
      <TableActionsContainer>
        <DeleteAction
          clickAction={() => handleDeleteConfirmation({ item })}
          disabled={code === currentOperatorCode}
          tooltip="Remove my access for this account"
        />
      </TableActionsContainer>
    );
  };

  useEffect(() => {
    if (operators?.length === 0) return;
    parseOperatorsData();
  }, [operators]);

  useEffect(confirmationModalClose, [errors, finished]);

  return (
    <>
      <TablePaper>
        <Table list={parsedOperators} headers={columns.operatorColumns} actions={rowActionsCell} noPaper>
          <TableHeader withActions />
          <TableBody />
        </Table>
      </TablePaper>
      {deleteModal.open && (
        <ConfirmationModal
          open={deleteModal.open}
          title={USER_OPERATOR_MESSAGE.deleteConfirmation(deletedResourceName)}
          confirmLabel="Confirm"
          showSpinner={inProgress}
          onCancel={handleDeleteOperatorCancel}
          onConfirm={handleDeleteOperatorConfirm}
        />
      )}
    </>
  );
};

export { TableOfOperators, StyledPaper, TablePaper, MessageArea, SpinnerWrapper };
