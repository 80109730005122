const separateAssets = arrayOfAssets => {
  const separatedAssets = { folders: [], files: [] };
  if (!(arrayOfAssets instanceof Array)) {
    return separatedAssets;
  };

  arrayOfAssets.forEach(each => {
    const nodeType = each.is_folder ? separatedAssets.folders : separatedAssets.files;
    nodeType.push(each);
  });
  return separatedAssets;
};

export { separateAssets };
