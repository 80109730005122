import { getMediaPx } from "helpers";
import styled from "styled-components";

export const Grid = styled.div`
  padding-top: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    grid-template-columns: 1fr;
  }
`;
