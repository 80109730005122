import React from "react";
import Input from "components/ui/Inputs/TextInput";
import DateTimeInput from "components/ui/Inputs/DateTimeInput";
import moment from "moment";
import { Grid } from "./style";

const ManualForm = ({ control }) => {
  return (
    <Grid>
      <Input control={control} name="departure_airport_code" label="Departure airport (IATA/ICAO)" rules={{ required: true }} />
      <Input control={control} name="departure_terminal" label="Departure terminal" />
      <DateTimeInput control={control} name="departure_at" label="Departure at" type="time" defaultValue={moment(new Date())} />
      <Input control={control} name="arrival_airport_code" label="Arrival airport (IATA/ICAO)" rules={{ required: true }} />
      <Input control={control} name="arrival_terminal" label="Arrival terminal" />
      <DateTimeInput control={control} name="arrival_at" label="Arrival at" type="time" defaultValue={moment(new Date())} />
    </Grid>
  );
};

export default ManualForm;
