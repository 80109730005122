import React from "react";
import { BodyText2 } from "components/ui/Typography/Typography";
import { Button, InfoWindowActions } from "feature/panel/_shared/MapWidget_old/styledComponents";
import { GLOBAL_CONTENT, LOCATIONS_LABELS } from "constants/content";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import styled from "styled-components";
import { colors } from "config/theme/colors";
import config from "config/app";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { isFunction } from "contracts/types";
import uuidv4 from "uuid";
import { useSelector } from "react-redux";

const StaysMapInfoWindow = ({ item, handleOpenEditModal, onAddMarker, isAdding, passItemOnMarkerAdd, onWindowInfoClose }) => {
  const permissionsService = usePermissionsService();
  const currentOperatorCode = useSelector(state => state.auth.currentOperatorCode);

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.operator, PERMISSIONS.resources.operators);

  const openStayInfoPage = ({ name, vamoos_id }) => {
    const host = `https://${currentOperatorCode?.toLowerCase()}.stays.vamoos.com/`;
    const formattedName = name.replaceAll(" ", "_");
    const vamoosId = vamoos_id;
    const link = `${host}${formattedName}/${vamoosId}`;

    window.open(link, "_blank");
  };

  const addStayToTripLocations = () => {
    const newMarker = {
      id: uuidv4(),
      country: "",
      name: item.name,
      location: "",
      coordinates: `${item.latitude}, ${item.longitude}`,
      latitude: item.latitude,
      longitude: item.longitude,
      on_weather: true,
      on_maps: true,
      isNewNestedLocation: true,
      nested: {
        vamoos_id: item.vamoos_id || item.vamoosId,
        type: "stay",
        operator_code: item.operator_code || item.operatorCode,
        reference_code: item.reference_code || item.referenceCode,
      },
    };

    if (onAddMarker && isFunction(onAddMarker)) {
      onAddMarker(passItemOnMarkerAdd ? item : newMarker, onWindowInfoClose);
    }

    // if (!clearMarkerDataAfterAdding) {
    //   setShowActionButtons(false);
    //   clearSearchWithMarker(googleMapObj, map);
    // }
  };

  const { operator_code, name, vamoos_id } = item;
  return (
    <StyledWrapper>
      <BodyText2>
        <strong>{`${operator_code ? `${operator_code} - ` : ""}${name}`}</strong>
      </BodyText2>
      <InfoWindowActions>
        <Button type="button" onClick={() => openStayInfoPage({ name, vamoos_id })}>
          <span>{LOCATIONS_LABELS.preview}</span>
        </Button>
        {canEdit && !isAdding && (
          <Button primary component="button" onClick={() => handleOpenEditModal(item)}>
            <EditOutlinedIcon />
            <span>{GLOBAL_CONTENT.edit}</span>
          </Button>
        )}
        {isAdding && (
          <Button primary component="button" onClick={addStayToTripLocations}>
            <EditOutlinedIcon />
            <span>{GLOBAL_CONTENT.addStay}</span>
          </Button>
        )}
      </InfoWindowActions>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  width: 100%;
  min-width: 160px;
  background: ${colors.white};
  color: ${colors.black};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  span {
    letter-spacing: 1px;
  }
`;

export default StaysMapInfoWindow;
