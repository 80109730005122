// @ts-check

/**
 * @param {import("types/dto").PoisItineraryResponseDto} poisDto  feature stay object
 * @returns {PoisItinerary} Feature stay item
 */

export function PoisItnierary(poisDto) {
  /**
   * @type {PoisItinerary}
   */
  const domain = {};

  domain.country = poisDto.country;
  domain.countryIso = poisDto.country_iso;
  domain.createdAt = poisDto.created_at;
  domain.description = poisDto.description;
  domain.icon = poisDto.icon;
  domain.icon_id = poisDto.icon_id;
  domain.id = poisDto.id;
  domain.isOn = poisDto.is_on;
  domain.itineraryId = poisDto.itinerary_id;
  domain.latitude = poisDto.latitude;
  domain.longitude = poisDto.longitude;
  domain.libraryNode = poisDto.library_node;
  domain.meta = poisDto.meta;
  domain.name = poisDto.name;
  domain.operatorId = poisDto.operator_id;
  domain.updatedAt = poisDto.updated_at;
  domain.location = poisDto.location;
  domain.position = poisDto.position;
  domain.createdBy = poisDto.created_by;
  domain.timezone = poisDto.timezone;
  domain.isDefaultOn = poisDto.is_default_on;
  domain.poiRange = poisDto.poi_range;

  return domain;
}
