import { getMediaPx } from "helpers";
import styled from "styled-components";

export const Wrap = styled.div`
  border-right: 1px solid #eaeaea;
  display: grid;
  grid-template-rows: ${p => (p.hasAdditionalMenu ? "minmax(300px, calc(100vh - 250px)) 50px" : "minmax(300px, calc(100vh - 179px)) 50px")};
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    display: ${p => (!p.open ? "grid" : "none")};
    grid-template-rows: ${p =>
      p.hasAdditionalMenu ? "minmax(300px, calc(100svh - 295px)) 50px;" : "minmax(300px, calc(100svh - 210px)) 50px;"};
  }
`;
