import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const GoogleAuth = ({ handleSignIn, setSpinner, nonce }) => {
  const { composerData } = useSelector(state => state.auth);
  const [isGoogleScriptLoaded, setIsGoogleScriptLoaded] = useState(false);

  const handleGoogleSignIn = useCallback(async (response) => {
    setSpinner(true);
    const signInUserBody = {
      method: composerData?.auth.google.name,
      client_id: response.client_id,
      id_token: response.credential,
      nonce,
    };

    await handleSignIn('google', signInUserBody);
  }, [composerData?.auth.google.name, handleSignIn]);

  useEffect(() => {
    const googleScript = document.querySelector('script[src="https://accounts.google.com/gsi/client"]');

    if (googleScript && !!window?.google?.accounts) {
      setIsGoogleScriptLoaded(true);
      return;
    }
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    script.onload = () => {
      setIsGoogleScriptLoaded(true);
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (!composerData || !isGoogleScriptLoaded) return;
    let isMounted = true;
    window.google.accounts.id.initialize({
      client_id: composerData?.auth.google.systemClientId,
      callback: handleGoogleSignIn,
      nonce,
    });

    if (isMounted) {
      window.google.accounts.id.renderButton(
        document.getElementById("googleButton"),
        { theme: "standard", size: "medium", logo_alignment: "center", locale: 'en' }
      );
    }
    return () => {
      isMounted = false;
    };
  }, [composerData, handleGoogleSignIn, isGoogleScriptLoaded]);

  return (
    <>
      <div id="googleButton" />
    </>
  )
}

GoogleAuth.propTypes = {
  handleSignIn: PropTypes.func.isRequired,
  setSpinner: PropTypes.func.isRequired,
  nonce: PropTypes.string.isRequired,
};

export default GoogleAuth;
