import MuiTab from "@material-ui/core/Tab";
import styled from "styled-components";

const LeftAlignedTab = styled(MuiTab)`
  && {
    font-size: ${({ theme }) => theme.fonts.fontSize.body2}px;
    font-weight: ${({ theme }) => theme.fonts.fontWeight.semiBold};
    color: ${({ theme }) => theme.colors.grey100};
    text-transform: none;
    min-width: auto;
    margin-right: 40px;
    padding: 24px 0px;
    & span {
        align-items: baseline;
    }
  }
`;

export { LeftAlignedTab };
