import React from "react";
import styled from "styled-components";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ReorderIcon from "@material-ui/icons/Reorder";

const resolveBackgroundColor = (props, transparency) => {
  const { hasError, isActive, theme } = props;
  if (hasError) return theme.colors.withOpacity(theme.colors.error, transparency || 0.2);
  return isActive ? "transparent" : theme.colors.withOpacity(theme.colors.grey10, transparency || 0.2);
};

const resolveBorderColor = props => {
  const { hasError, isActive, theme } = props;
  return !hasError ? `${isActive ? "1px" : "0 0 0 1px"} solid ${theme.colors.grey10}` : `0 0 0 1px solid ${theme.colors.error}`;
};

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ReorderButton = styled(({ isActive, hasError, ...rest }) => <div {...rest} />)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ theme }) => theme.setSpacing(10)}px;
  min-height: ${({ theme }) => theme.setSpacing(10)}px;
  height: ${({ theme }) => theme.setSpacing(20)}px;
  background-color: ${props => resolveBackgroundColor(props)};
  border-radius: ${({ theme }) => theme.borderRadius}px;
  box-sizing: border-box;
  cursor: pointer;
  ${({ theme, isActive, hasError }) => (isActive && !hasError ? `border: 1px solid ${theme.colors.grey10}` : null)};
`;

const StyledReorderIcon = styled(({ hasError, ...rest }) => <ReorderIcon {...rest} />)`
  && {
    color: ${({ theme, hasError }) => (hasError ? theme.colors.error : theme.colors.grey50)};
  }
`;

const ContentArea = styled.div`
  width: calc(100% - ${({ theme, disabled }) => disabled ? 0 : theme.setSpacing(12)}px);
  display: flex;
`;

const HeaderContentArea = styled(({ hasError, isActive, ...rest }) => <div {...rest} />)`
  border-top-left-radius: ${({ theme }) => theme.borderRadius}px;
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius}px;

  ${({ theme, disabled }) => disabled && `
    border-top-right-radius: ${theme.borderRadius}px;
    border-bottom-right-radius: ${theme.borderRadius}px;
    border-bottom-left-radius: ${theme.borderRadius}px;
  `}

  min-height: ${({ theme }) => theme.setSpacing(10)}px;
  height: ${({ theme }) => theme.setSpacing(20)}px;
  background-color: ${props => resolveBackgroundColor(props)};
  margin-bottom: ${({ theme }) => theme.setSpacing(2)}px;
  padding: 0 ${({ theme }) => theme.setSpacing(3)}px;
  width: calc(100% - ${({ theme, disabled }) => disabled ? 0 : theme.setSpacing(18)}px);
  display: flex;
  justify-content: ${({ justify }) => justify || "space-between"};
  align-items: ${({ align }) => align || "center"};
  box-sizing: border-box;

  ${({ theme, isActive, hasError }) =>
    isActive && !hasError
      ? `
        border: 1px solid ${theme.colors.grey10};
        `
      : null}

  > * {
    margin: 0 ${({ theme }) => theme.setSpacing(3)}px !important;
  }

  ${({ isActive, disabled }) =>
    isActive
      ? `
      ${disabled ? `
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      ` : `
        border-right: 0px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      `}
    `
      : null}
`;

const ChildrenContentArea = styled(({ hasError, ...rest }) => <div {...rest} />)`
  width: calc(100% - ${({ theme, disabled }) => disabled ? 0 : theme.setSpacing(12)}px);
  margin-left: ${({ theme, disabled }) => disabled ? 0 : theme.setSpacing(12)}px;
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius}px;
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius}px;
  margin-top: -${({ theme }) => theme.setSpacing(2)}px;
  margin-bottom: ${({ theme }) => theme.setSpacing(2)}px;
  padding: ${({ theme }) => theme.setSpacing(6)}px;
  background-color: ${({ theme, hasError }) => theme.colors.withOpacity(theme.colors[hasError ? "error" : "grey10"], 0.3)};
  transition: height 0.2s ease-in-out;
  overflow: hidden;
`;

const CloseButtonArea = styled(({ hasError, isActive, ...rest }) => <div {...rest} />)`
  width: 72px;
  height: 80px;
  background-color: ${props => resolveBackgroundColor(props)};
  border-left: 1px solid ${({ theme, hasError }) => (hasError ? theme.colors.withOpacity(theme.colors.error, 0.2) : theme.colors.grey10)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: ${({ theme }) => theme.borderRadius}px;
  border-bottom-right-radius: ${({ theme, isActive }) => (isActive ? 0 : theme.borderRadius)}px;
  box-sizing: border-box;
  border: ${props => resolveBorderColor(props)};
`;

const RotatedArrowIcon = styled(({ shouldRotate, ...rest }) => <ExpandMoreIcon {...rest} />)`
  && {
    transition: transform 0.15s ease-in-out;
    ${({ shouldRotate }) => (shouldRotate ? "transform: rotate(180deg);" : null)};
    cursor: pointer;
  }
`;

export {
  StyledWrapper,
  ReorderButton,
  StyledReorderIcon,
  ContentArea,
  HeaderContentArea,
  ChildrenContentArea,
  CloseButtonArea,
  RotatedArrowIcon,
};
