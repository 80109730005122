import React from "react";
import { Outlet } from "react-router-dom";
import { PanelTemplate } from "components/templates/Panel/PanelTemplate";
import { Navigation } from "feature/panel/Help/Navigation";

const Help = () => {
  const contextBar = {
    left: () => null,
    middle: Navigation,
    right: () => null,
  };

  return (
    <PanelTemplate contextBar={contextBar}>
      <Outlet />
    </PanelTemplate>
  );
};

export { Help };
