import React from "react";
import styled from "styled-components";
import { SortableElement } from "react-sortable-hoc";

const StyledLi = styled(({ destination, ...props }) => <li {...props} />)`
  z-index: ${({ destination }) => (destination && destination === "popup" ? 1999 : 1)};
  list-style-type: none;
  .MuiFormControlLabel-root {
    margin-left: 0;
  }
`;

const SortableListItem = SortableElement(({ children, item, ...rest }) => <StyledLi {...rest}>{children}</StyledLi>);

export { SortableListItem };
