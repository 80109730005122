import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { ADMIN_FEATURES_CONTENT, GLOBAL_CONTENT } from "constants/content";
import React from "react";

const DeleteConfirmation = ({ open, count, onConfirmModal, onCancelModal }) => {
  return (
    <>
      {open && (
        <ConfirmationModal
          open={open}
          confirmLabel={GLOBAL_CONTENT.proceed}
          title={ADMIN_FEATURES_CONTENT.deleteConfitmationTitle}
          onConfirm={onConfirmModal}
          onCancel={onCancelModal}
        >
          <>{ADMIN_FEATURES_CONTENT.deleteConfirmation(count)}</>
        </ConfirmationModal>
      )}
    </>
  );
};

export { DeleteConfirmation };
