import { FILE_REFRESH_PATH } from "constants/api";
import moment from "moment";
import { HttpClient } from "services/application/httpClient/httpClient";

export class UrlRefreshService {  
  static refresh(url) {
    if (!url) {
      return Promise.resolve(url);
    }

    const expirationTimestamp = UrlRefreshService.getExpirationTimestampFromUrl(url);
    
    if (!expirationTimestamp) {
      return Promise.resolve(url);
    }

    const now = Number(
      new Date()
        .getTime()
        .toString()
        .slice(0, 10),
    );

    const isExpired = now - Number(expirationTimestamp) > 0;

    if (!isExpired) {
      return Promise.resolve(url);
    }

    return HttpClient.post(FILE_REFRESH_PATH, { urls: [url] }).then(({ data }) => Promise.resolve(data[0]));
  }

  static getExpirationTimestampFromUrl = url => {
    const { searchParams } = new URL(url);

    if (searchParams.has("Expires")) {
      return searchParams.get("Expires");
    }

    if (searchParams.get("X-Amz-Date")) {
      const expiryDate = moment(searchParams.get("X-Amz-Date")).utc();
      const expiresSeconds = Number(searchParams.get("X-Amz-Expires"));
      const nowUtc = moment().utc();
      
      if (expiryDate.add(expiresSeconds, 'seconds').unix() < nowUtc.unix()) {
        return 1;
      }
    }

    return null;
  }
}