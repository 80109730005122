import { TableFooter, TableRow } from "@material-ui/core";
import styled from "styled-components";
import TablePagination from "@mui/material/TablePagination";
import config from "config/app";
import { colors } from "config/theme/colors";
import { HideOnLaptop, MobileOnly } from "feature/panel/Itinerary/responsive";
import React, { useEffect, useMemo, useState } from "react";
import { deviceType } from "../../../utils/deviceDetect";
import { saveResultPerPage } from "../../../utils/saveTableSettings";
import { getTaleVarFromLS } from "../../ui/Tables/getTableVarFromLS";
import TableDesktop from "./Desktop/desktop";
import TableMobile from "./Mobile";

const PaginationStyled = styled(TablePagination)`
  p {
    font-size: 12px;
    color: "rgba(0, 0, 0, 0.54)";
  }

  input {
    font-size: 12px;
    color: "rgba(0, 0, 0, 0.54)";
  }
  .MuiTablePagination-root {
    border: none !important;
  }
`;

const Table = ({
  columns,
  rows,
  onRowClick,
  renderRow,
  renderRowMobile,
  rowsPerPage,
  totalMatches,
  page = 0,
  handleChangePage,
  handleChangeRowsPerPage,
  checkboxSelection,
  checked,
  setChecked,
  onSort,
  isSorting,
  checkboxVarName,
  tableId,
  refactoredHandleChangeOrder,
  containerStyle,
}) => {
  const [sorting, setSorting] = useState({});
  const device = deviceType();

  useEffect(() => {
    const resultsPerPage = getTaleVarFromLS("resultsPerPage", tableId);
    if (resultsPerPage) handleChangeRowsPerPage(resultsPerPage, tableId);
  }, [totalMatches]);

  useEffect(() => {
    if (!device) return;
    const tables = JSON.parse(localStorage.getItem("v_tables"));
    const column = tables?.[device]?.[tableId]?.ordering?.column;
    const order = tables?.[device]?.[tableId]?.ordering?.order;

    if (refactoredHandleChangeOrder) refactoredHandleChangeOrder({ column, order }, tableId);
    setSorting({ [column]: order });
  }, [device]);

  const onCheck = id => {
    if (checked?.find(item => item === id)) setChecked(checked.filter(item => item !== id));
    else setChecked([...checked, id]);
  };

  const onCheckAll = () => {
    const checkedCount = checked?.length;
    const allChecked = checkedCount === rows.length;

    if (allChecked) setChecked([]);
    else setChecked(rows.map(item => item[checkboxVarName || "id"]));
  };

  const handleSorting = (column, order) => {
    setSorting({ [column]: order });
    onSort({ [column]: order });
  };

  const PaginatedFooter = () => (
    <TableFooter>
      <TableRow>
        <PaginationStyled
          style={{
            color: "rgba(0, 0, 0, 0.54)",
          }}
          rowsPerPageOptions={config.tables.rowsPerPage}
          count={totalMatches}
          page={page - 1}
          rowsPerPage={rowsPerPage}
          onPageChange={(_, page) => handleChangePage(page)}
          onRowsPerPageChange={e => {
            handleChangeRowsPerPage(e.target.value);
            saveResultPerPage(e.target.value, tableId);
          }}
        />
      </TableRow>
    </TableFooter>
  );

  return (
    <>
      {/* <Paper> */}
      <div style={{ border: `1px solid ${colors.grey10}`, borderBottom: 0, backgroundColor: "#fff" }}>
        <HideOnLaptop>
          <TableDesktop
            columns={columns}
            rows={rows}
            onRowClick={onRowClick}
            renderRow={renderRow}
            checkboxSelection={checkboxSelection}
            onCheckAll={onCheckAll}
            checked={checked}
            onCheck={onCheck}
            sorting={sorting}
            onSort={handleSorting}
            isSorting={isSorting}
            checkboxVarName={checkboxVarName}
            FooterCmp={totalMatches > 0 ? <PaginatedFooter /> : null}
            containerStyle={containerStyle}
          />
        </HideOnLaptop>
        <MobileOnly>
          <TableMobile
            columns={columns}
            rows={rows}
            onRowClick={onRowClick}
            renderRowMobile={renderRowMobile}
            FooterCmp={totalMatches > 0 ? <PaginatedFooter /> : null}
          />
        </MobileOnly>
      </div>
    </>
  );
};

export default Table;
