import styled from "styled-components";
import MuiStepper from "@material-ui/core/Stepper";

const Stepper = styled(MuiStepper)`
  && {
    padding: 0;
    height: 24px;

    .MuiStepIcon-active {
      color: ${({ theme }) => theme.colors.brand};
    }

    .MuiStepIcon-completed {
      color: ${({ theme }) => theme.colors.success};
    }

    .MuiStep-horizontal {
      padding: 0;
    }

    .MuiStepConnector-root {
      padding: 0 8px;
    }

    .MuiStepLabel-iconContainer {
      padding: 0;
    }
  }
`;
const StepperContent = styled.div``;
const StepperActions = styled.div``;

export { Stepper, StepperContent, StepperActions };
