import React from "react";
import ImageIcon from "@mui/icons-material/Image";
import { ChipWithRedirect } from "components/ui/Chips/ChipWithRedirect";
import { ImageOutlined } from "@material-ui/icons";
import { Wrap, ImageWrap } from "./style";
import AttachButton from "../Buttons/AttachButton";

const LogoSelect = ({ img, onConfirm, onDelete, disabled, error, hideUrl }) => {
  const src = img?.preview_url || img?.https_url || img?.file?.https_url || img?.url;

  return (
    <Wrap>
      <ImageWrap>{img ? <img src={src} alt="logo" /> : <ImageIcon />}</ImageWrap>
      {img ? (
        <ChipWithRedirect icon={<ImageOutlined />} onDelete={() => onDelete(null)} item={img} disabled={disabled} />
      ) : (
        <AttachButton onConfirm={onConfirm} disabled={disabled} text="Add image" error={error} hideUrl={hideUrl} />
      )}
    </Wrap>
  );
};

export default LogoSelect;
