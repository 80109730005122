import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ContextNavigationLink } from "components/ui/Links/Links";

import {
  PANEL_SETTINGS_COMPANY,
  PANEL_SETTINGS_ACCOUNTS,
  PANEL_SETTINGS_CONNECT,
  PANEL_SETTINGS_CUSTOM_APPS,
  PANEL_SETTINGS_BRANDING,
  PANEL_SETTINGS_REQUEST_TRACKER,
  PANEL_SETTINGS_PASSCODES,
} from "constants/routes";
import { SETTINGS_NAVIGATION } from "constants/content";
import { PERMISSIONS } from "constants/permissions";
import { usePermissionsService } from "hooks/usePermissionsService";

const StyledWrapper = styled.div`
  display: flex;
  height: 100%;
  width: auto;
  justify-content: center;
  align-items: center;
`;

const Navigation = ({ location }) => {
  const { pathname } = location;

  const permissionsService = usePermissionsService();

  const isUserAllowedToUpdateUsers = permissionsService.can(
    PERMISSIONS.actions.update,
    PERMISSIONS.sections.operator,
    PERMISSIONS.resources.operators,
  );

  const isUserAllowedToReadOperator = permissionsService.can(
    PERMISSIONS.actions.read,
    PERMISSIONS.sections.operator,
    PERMISSIONS.resources.operators,
  );

  return (
    <StyledWrapper>
      {isUserAllowedToReadOperator && (
        <ContextNavigationLink to={PANEL_SETTINGS_COMPANY} isActive={PANEL_SETTINGS_COMPANY === pathname}>
          {SETTINGS_NAVIGATION.general}
        </ContextNavigationLink>
      )}

      {isUserAllowedToReadOperator && (
        <ContextNavigationLink to={PANEL_SETTINGS_BRANDING} isActive={PANEL_SETTINGS_BRANDING === pathname}>
          {SETTINGS_NAVIGATION.branding}
        </ContextNavigationLink>
      )}

      {isUserAllowedToReadOperator && isUserAllowedToUpdateUsers && (
        <ContextNavigationLink to={PANEL_SETTINGS_ACCOUNTS} isActive={PANEL_SETTINGS_ACCOUNTS === pathname}>
          {SETTINGS_NAVIGATION.users}
        </ContextNavigationLink>
      )}

      <ContextNavigationLink to={PANEL_SETTINGS_CONNECT} isActive={PANEL_SETTINGS_CONNECT === pathname}>
        {SETTINGS_NAVIGATION.connect}
      </ContextNavigationLink>

      {isUserAllowedToReadOperator && (
        <ContextNavigationLink to={PANEL_SETTINGS_CUSTOM_APPS} isActive={PANEL_SETTINGS_CUSTOM_APPS === pathname}>
          {SETTINGS_NAVIGATION.customApps}
        </ContextNavigationLink>
      )}
      {isUserAllowedToReadOperator && (
        <ContextNavigationLink to={PANEL_SETTINGS_PASSCODES} isActive={PANEL_SETTINGS_PASSCODES === pathname}>
          Passcodes
        </ContextNavigationLink>
      )}

      {/* <ContextNavigationLink to={PANEL_SETTINGS_REQUEST_TRACKER} isActive={PANEL_SETTINGS_REQUEST_TRACKER === pathname}>
        {SETTINGS_NAVIGATION.requestTracker}
      </ContextNavigationLink> */}
    </StyledWrapper>
  );
};

Navigation.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
};

export { Navigation, StyledWrapper };
