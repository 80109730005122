import React from "react";
import styled from "styled-components";

import { ExceptionsTemplate } from "components/templates/Exceptions/ExceptionsTemplate";
import { H1, BodyText } from "components/ui/Typography/Typography";

import { PAGE_NOT_FOUND_TEXT } from "constants/content";

import svg404 from "assets/images/404.svg";

const ContentWrapper = styled.div`
  width: 100%;
  height: calc(100vh - ${({ theme }) => theme.setSpacing(26)}px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ImageWrapper = styled.div`
  position: relative;
  margin-bottom: 40px;
`;

const StyledH1 = styled(H1)`
  && {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: ${({ theme }) => theme.setSpacing(6.5)}px;
    color: ${({ theme }) => theme.colors.white};
  }
`;

const PageNotFound = () => (
  <ExceptionsTemplate>
    <ContentWrapper>
      <ImageWrapper>
        <img src={svg404} alt="404" />
        <StyledH1>404</StyledH1>
      </ImageWrapper>
      <BodyText cv="grey50">{PAGE_NOT_FOUND_TEXT}</BodyText>
    </ContentWrapper>
  </ExceptionsTemplate>
);

export { PageNotFound, ContentWrapper, ImageWrapper, StyledH1 };
