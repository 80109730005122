import React from "react";
import PropTypes from "prop-types";

import { SignUpForm } from "./SignUpForm";
import { InformationalModal } from "../../../components/ui/Modals/InformationalModal";
import { GLOBAL_CONTENT } from "../../../constants/content";

const CreateOperatorModal = ({ open, onClose }) => (
  <InformationalModal
    open={open}
    header={GLOBAL_CONTENT.registerBusinessAccount}
    onCancel={onClose}
    fullWidth
    maxWidth="sm"
  >
    <SignUpForm createOperator />
  </InformationalModal>
);

CreateOperatorModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export { CreateOperatorModal };