import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { get } from "lodash";

import TableCell from "@material-ui/core/TableCell";

import { TableRow, useTableContext } from "components/ui/Tables";

const StyledTableCell = styled(TableCell)`
  ${({ total }) => (total === "true" ? "font-weight: 600 !important;" : "")}
`;

const StatsTableRow = ({ item }) => {
  const { headers } = useTableContext();

  const renderCellValue = key => {
    const value = get(item, key);
    return value || value === 0 ? value : "-";
  };

  return (
    <TableRow item={item}>
      {headers.map(({ key }) => (
        <StyledTableCell total={(!!item?.isTotal).toString()} key={`${key}-${item.code}`}>
          {renderCellValue(key)}
        </StyledTableCell>
      ))}
    </TableRow>
  );
};

StatsTableRow.propTypes = {
  item: PropTypes.shape({}).isRequired,
};

export { StatsTableRow };
