import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { font } from "config/theme/fonts";
import { PUBLISHER_CONTENT } from "constants/content";

import { ContentDivider, PickerButton, SizePicker } from "feature/panel/Stays/_shared/Publisher/styledComponents";
import { Checkbox, Switch } from "components/ui/Forms";

const FieldWrapper = styled(FormControlLabel)`
  && {
    .MuiFormControlLabel-label {
      font-size: ${({ theme }) => theme.fonts.fontSize.body2}px;
      font-family: ${font};
    }
  }
`;

const DirectoryRow = ({ directory, onValueChange, onActivateToggle }) => {
  const { name, active, page_break_before, show_image, image_size } = directory;
  const handleNewPageChange = () => onValueChange("page_break_before", !page_break_before);
  const handleShowImagesChange = () => onValueChange("show_image", !show_image);
  const handleImageSizeLargeSet = () => onValueChange("image_size", "large");
  const handleImageSizeSmallSet  = () => onValueChange("image_size", "small");

  return (
    <ContentDivider>
      <FieldWrapper
        label={name}
        control={(
          <Checkbox
            checked={active}
            onChange={onActivateToggle}
          />
        )}
      />
      <Switch checked={page_break_before} disabled={!active} onChange={handleNewPageChange} />
      <Switch checked={show_image} disabled={!active} onChange={handleShowImagesChange} />
      <SizePicker disabled={!show_image} disableRipple>
        <PickerButton
          size="small"
          selected={image_size==="large"}
          onClick={handleImageSizeLargeSet}
        >
          {PUBLISHER_CONTENT.large}
        </PickerButton>
        <PickerButton
          size="small"
          selected={image_size==="small"}
          onClick={handleImageSizeSmallSet}
        >
          {PUBLISHER_CONTENT.small}
        </PickerButton>
      </SizePicker>
    </ContentDivider>
  );
};

DirectoryRow.propTypes = {
  directory: PropTypes.shape({
    name: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    page_break_before: PropTypes.bool.isRequired,
    show_image: PropTypes.bool.isRequired,
    image_size: PropTypes.oneOf(["small", "large"]).isRequired,
  }).isRequired,
  onValueChange: PropTypes.func.isRequired,
  onActivateToggle: PropTypes.func.isRequired,
};

export { DirectoryRow, FieldWrapper };
