import { useQueryClient } from "@tanstack/react-query";
import BaseModal from "components/_new/ModalBase";
import Switch from "components/_new/Switch";
import SelectInput from "components/ui/Inputs/Select";
import { Message } from "components/ui/Messages";
import { colors } from "config/theme/colors";
import { LANGUAGE_SELECTOR } from "constants/content";
import { PANEL_SETTINGS_COMPANY } from "constants/routes";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { HttpClient } from "services/application/httpClient/httpClient";
import { encodeParameter } from "utils/url";
import { getData, modifyData, prepareDataForSubmit } from "../../helpers";
import { getEncodedParametrURL } from "./languageSelector";
import { SettingsLink } from "./style";

const LanguageModal = ({
  languages,
  routing,
  onClose,
  setSelectedLanguage,
  data,
  itineraryLanguages,
  setItineraryLanguages,
  defaultItineraryLanguages,
  refetchAll,
  isRefetchAllLoading,
  mainLanguage,
  activeLanguages,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();
  const { operator_code } = useParams();
  const {
    control,
    reset,
    watch,
    formState: { isDirty },
  } = useForm();
  const defaultLanguageWatch = watch("default_language");

  const onChangeLanguage = (lang, checked) =>
    setItineraryLanguages({ ...(itineraryLanguages || {}), [lang]: { ...(itineraryLanguages?.[lang] || {}), active: checked } });

  const onSubmit = async () => {
    setIsLoading(true);
    const changedLanguages = [];
    const url = routing?.fallback
      .split("/")
      .slice(0, -1)
      .join("/");
    const encodedUrl = getEncodedParametrURL(url);

    Object.entries(itineraryLanguages)?.forEach(([key, value]) => {
      if (defaultItineraryLanguages[key]?.active !== value.active) changedLanguages.push({ [key]: value });
    });

    await HttpClient.post(`/itinerary/${encodeParameter(operator_code)}/DEFAULT/fallback_language`, { language: defaultLanguageWatch });

    const sequentialRequests = changedLanguages.reduce(async (previousPromise, item) => {
      await previousPromise;

      const key = Object.keys(item)[0];
      const value = item[key];

      if (value.active) {
        let body;

        if (defaultItineraryLanguages.hasOwnProperty(key)) {
          const originalData = await HttpClient.get(`${encodedUrl}/${key}`);
          const modifiedItineraryData = modifyData(originalData.data);
          const dataToPrepare = getData(modifiedItineraryData);
          const originalBody = prepareDataForSubmit(dataToPrepare);
          body = {
            ...originalBody,
            type: "stay",
            new_only: false,
            source: "portal",
            vamoos_id: data.vamoos_id,
          };
        } else {
          body = {
            type: "stay",
            source: "portal",
            meta: {
              show_directory: data.meta.show_directory,
              show_vouchers: data.meta.show_vouchers,
              show_daily_activities: data.meta.show_daily_activities,
            },
            field1: null,
            vamoos_id: data.vamoos_id,
            is_active: true,
            new_only: false,
            background: null,
            documents: {
              travel: [],
              destination: [],
            },
            notifications: [],
            directories: [],
            departure_date: data.departure_date,
            return_date: data.return_date,
          }
        }

        await HttpClient.post(`${encodedUrl}/${key}`, body);
      } else {
        await HttpClient.delete(`${encodedUrl}/${key}`);
      }
    }, Promise.resolve());
    await sequentialRequests;

    refetchAll();
    await queryClient.invalidateQueries({ queryKey: ["trip"] });
    if (isDirty) setSelectedLanguage(defaultLanguageWatch);
    setIsLoading(false);
    onClose();
  };

  const onCancel = () => {
    setItineraryLanguages(defaultItineraryLanguages);
    onClose();
  };

  useEffect(() => {
    reset({ default_language: mainLanguage });
  }, [mainLanguage]);

  return (
    <BaseModal
      title="Languages"
      topLineStyle={{ marginBottom: 20 }}
      modalStyle={{ overflow: "visible", minWidth: "auto", maxWidth: 600 }}
      contentStyle={{ overflow: "visible" }}
      confirmTitle="Save"
      onConfirm={onSubmit}
      onCancel={onCancel}
      isLoading={isLoading || isRefetchAllLoading}
    >
      <SelectInput
        autoFocus
        label="Default language"
        name="default_language"
        options={activeLanguages}
        optionLabelVar="name"
        optionValueVar="code"
        control={control}
        isClearable={false}
        styleContainer={{ margin: "5px 0 0" }}
      />

      <div style={{ margin: "25px 0 15px 0", borderTop: `1px solid ${colors.grey10}`, paddingTop: 20 }}>Additional languages</div>
      <div style={{ display: "flex", alignItems: "center", gap: 15, marginBottom: 15, flexWrap: "wrap" }}>
        {languages
          ?.filter(item => !item.isDefault)
          ?.map(item => (
            <div
              style={{
                border: `1px solid ${itineraryLanguages?.[item.code]?.active ? colors.brand : colors.grey10}`,
                borderRadius: 50,
                padding: "0 15px",
                boxSizing: "border-box",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "auto"
              }}
            >
              <Switch
                label={item.name}
                checked={itineraryLanguages?.[item.code]?.active}
                onChange={checked => onChangeLanguage(item.code, checked)}
              />
            </div>
          ))}
      </div>
      <Message
        type="info"
        text={
          <>
            {LANGUAGE_SELECTOR.toAddAdditionalLanguages}{" "}
            <SettingsLink to={PANEL_SETTINGS_COMPANY}> {LANGUAGE_SELECTOR.languageSettings}</SettingsLink>
          </>
        }
      />
    </BaseModal>
  );
};

export default LanguageModal;
