// @ts-check
export class OperatorRequestDto {
  /** @type {string} */
  name;

  /** @type {ImageRequestDto} */
  creations_footer;

  /** @type {ImageRequestDto} */
  creations_header;

  /** @type {ImageRequestDto} */
  logo;

  /** @type {LabelResponseDto[]} */
  labels;

  /** @type {boolean} */
  is_active;

  /** @type {string} */
  terms_accepted_at;

  /** @type {string} */
  type;

  /** @type {object} */
  meta;
}
