import styled from "styled-components";
import { BaseButton } from "components/ui/Buttons/BaseButton";
import { setButtonAttr } from "utils/styles/buttons";

const GhostButton = styled(BaseButton)`
  && {
    color: ${({ theme, cv, cva, active }) => {
      if (active) return theme.colors[cva] || theme.colors.brand;
      return theme.colors[cv] || theme.colors.brand;
    }};
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
    background: transparent;
    padding: ${({ theme, size }) => {
      if (size === "large") return theme.setSpacing(3);
      if (size === "medium") return theme.setSpacing(2);
      if (size === "small") return theme.setSpacing(1);
      return theme.setSpacing(2);
    }}px;
    line-height: ${({ size }) => setButtonAttr("line-height", size)};
    transition: ${({ theme }) => theme.transition};
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

    :hover {
      color: ${({ theme, cvh }) => theme.colors[cvh] || theme.colors.brandHover};
      background: ${({ theme, cvh }) => (cvh ? theme.colors.withOpacity(theme.colors[cvh], 0.1) : "transparent")};
      box-shadow: none;
    }

    :disabled {
      color: ${({ theme }) => theme.colors.gray20};
    }
  }
`;

export { GhostButton };
