import { createTheme } from "@mui/material";
import { font } from "config/theme/fonts";
import { spacing } from "config/theme/spacing";
import { overrides } from "./overrides";
import { breakpoints } from "./breakpoints";

const muiTheme = createTheme({
  overrides,
  spacing,
  breakpoints,
  typography: {
    fontFamily: font,
  },
});

export { muiTheme };
