import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Grid, Paper } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

import { ADMIN_FEATURES_EDIT_PATH } from "constants/routes";
import { CREATE_CONTENT_LABELS, ADMIN_FEATURES_CONTENT } from "constants/content";

import { AdminTemplate } from "components/templates/Admin/AdminTemplate";
import { StickyHeaderFormSection } from "components/ui/Forms";
import { SecondaryButton } from "components/ui/Buttons";
import { Spinner, SpinnerWrapper } from "components/ui/Spinner/Spinner";
import { NoResultsMessage } from "components/ui/Messages/NoResultsMessage";

import { SettingsSubMenu } from "feature/admin/_shared/SettingsSubMenu";

import { GET_FEATURES_STAYS_START } from "store/adminFeatures/actionTypes";
import { getFeaturesStaysStart } from "store/adminFeatures/actions";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

import { isObjectEmpty } from "utils/object";

import { FeatureSection } from "./FeatureSection";

const HeaderWrapper = styled.div`
  margin: 40px 0 0 0;
`;

const FeaturesIndex = () => {
  const permissionsService = usePermissionsService();
  const features = useSelector(state => state.adminFeatureStays.features);
  const showSpinner = useSelector(
    state => state.adminFeatureStays.inProgress && state.adminFeatureStays.actionType === GET_FEATURES_STAYS_START,
  );
  const showEmptyListInfo = useSelector(
    state =>
      state.adminFeatureStays.finished &&
      state.adminFeatureStays.actionType === GET_FEATURES_STAYS_START &&
      isObjectEmpty(state.adminFeatureStays.features),
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isCreatePermitted = permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.admin, PERMISSIONS.resources.features);

  const isEditPermitted = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.admin, PERMISSIONS.resources.features);

  const contextBar = {
    left: null,
    middle: SettingsSubMenu,
    right: null,
  };

  const handleEdit = () => {
    navigate(ADMIN_FEATURES_EDIT_PATH);
  };

  const renderList = () => {
    const featuresKeys = Object.keys(features)
      .filter(key => features[key].isActive)
      .sort((a, b) => features[a].position - features[b].position);

    return (
      <>
        {featuresKeys.map(key => (
          <FeatureSection key={features[key].id} feature={features[key]} isEditPermitted={isEditPermitted} />
        ))}
      </>
    );
  };

  const renderedContent = () => {
    if (showSpinner)
      return (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      );
    if (showEmptyListInfo) return <NoResultsMessage>{ADMIN_FEATURES_CONTENT.noFeaturesDefined}</NoResultsMessage>;
    return renderList();
  };

  const getFetaures = () => {
    dispatch(getFeaturesStaysStart());
  };

  const EditButton = () =>
    isEditPermitted || isCreatePermitted ? (
      <SecondaryButton onClick={handleEdit}>
        <EditIcon />
        {CREATE_CONTENT_LABELS.edit}
      </SecondaryButton>
    ) : null;

  const stickyContainer = ({ children }) => <Grid md={8}>{children}</Grid>;

  useEffect(getFetaures, []);

  return (
    <AdminTemplate contextBar={contextBar}>
      <Grid container justifyContent="center">
        <Grid item sm={10}>
          <Paper>
            <Grid container justifyContent="center">
              <Grid item md={8}>
                <HeaderWrapper>
                  <StickyHeaderFormSection
                    title={ADMIN_FEATURES_CONTENT.listOfFeatures}
                    headerActions={EditButton}
                    stickyVariant="expanded"
                    stickyContainer={stickyContainer}
                  >
                    {renderedContent()}
                  </StickyHeaderFormSection>
                </HeaderWrapper>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </AdminTemplate>
  );
};

export { FeaturesIndex };
