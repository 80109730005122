import React, { cloneElement } from "react";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { colors } from "config/theme/colors";
import { css } from "styled-components";
import Drop from "./drop";
import Drag from "./drag";
import { reorder } from "./helpers";
import "./style.css";

const DnD = ({ list = [], onDragEnd, element, wrapStyle, contentStyle, disabled, idVar, secondaryElement, insideModal = false }) => {
  // const handleDrop = droppedItem => {
  //   // Ignore drop outside droppable container
  //   if (!droppedItem.destination) return;
  //   const updatedList = [...list];
  //   // Remove dragged item
  //   const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
  //   // Add dropped item
  //   updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
  //   // Update State
  //   onDragEnd(updatedList);
  // };

  const handleDragEnd = result => {
    const { type, source, destination } = result;
    if (!destination) return;

    const sourceId = source.droppableId;
    const destinationId = destination.droppableId;
    // Reordering items
    if (type === "droppable-item") {
      // If drag and dropping within the same item
      if (sourceId === destinationId) {
        const updatedOrder = reorder(list.find(item => item[idVar || "id"] === sourceId).items, source.index, destination.index);
        const updatedList = list.map(item => (item[idVar || "id"] !== sourceId ? item : { ...item, items: updatedOrder }));
        onDragEnd(updatedList);
      } else {
        const sourceOrder = list.find(item => item[idVar || "id"] === sourceId).items;
        const destinationOrder = list.find(item => item[idVar || "id"] === destinationId).items;

        const [removed] = sourceOrder.splice(source.index, 1);
        destinationOrder.splice(destination.index, 0, removed);

        destinationOrder[removed] = sourceOrder[removed];
        delete sourceOrder[removed];

        const updatedList = list.map(item =>
          item.id === sourceId
            ? { ...item, items: sourceOrder }
            : item[idVar || "id"] === destinationId
              ? { ...item, items: destinationOrder }
              : item,
        );

        onDragEnd(updatedList);
      }
    }

    // Reordering list
    if (type === "droppable-core") {
      const updatedList = reorder(list, source.index, destination.index);

      onDragEnd(updatedList, { source, destination });
    }
  };
  
  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Drop id="droppable" type="droppable-core">
        {list.map((item, index) => {
          const itemKey = item.id || item[0] || item[idVar];
          return (
            <Drag
              draggableId={itemKey.toString()}
              itemKey={itemKey}
              wrapStyle={wrapStyle}
              contentStyle={contentStyle}
              disabled={disabled}
              insideModal={insideModal}
              index={index}
            >
              <div style={{ width: "100%" }}>
                {cloneElement(element, {
                  item,
                  index,
                })}
                <Drop key={itemKey} id={itemKey} type="droppable-item">
                  {item.items?.length === 0 ? (
                    <div style={{ width: "100%", height: 8 }} />
                  ) : null}
                  {item.items?.map((innerItem, index) => {
                    const innerItemKey = innerItem.id || innerItem[0] || innerItem[idVar];
                    return (
                      <Drag
                        draggableId={(innerItemKey).toString()}
                        itemKey={innerItemKey}
                        key={innerItemKey}
                        wrapStyle={wrapStyle}
                        contentStyle={contentStyle}
                        disabled={disabled}
                        insideModal={insideModal}
                        index={index}
                      >
                        {cloneElement(secondaryElement, { item: innerItem, index: innerItem.index })}
                      </Drag>
                    );
                  })}
                </Drop>
              </div>
            </Drag>
          )
        })}
      </Drop>
    </DragDropContext>
  );
};

export default DnD;
