import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { BodyText2 } from "components/ui/Typography/Typography";
import { Content } from "components/ui/Content";
import { Header } from "feature/panel/Help/styledComponents";

const CategoryExplanation = styled(BodyText2)`
  && {
    display: block;
    margin-bottom: ${({ theme }) => theme.setSpacing(4)}px;

    :last-of-type {
      margin-bottom: 0;
    }
  }
`;

const CategoryWrapper = styled.div`
  margin-top: ${({ theme }) => theme.setSpacing(10)}px;
`;

const TextWrapper = styled.div`
  display: flex;

  ol {
    margin: 0;
    list-style-type: lower-alpha;
  }
`;
const TextCounter = styled(BodyText2)`
  && {
    margin-right: ${({ theme }) => theme.setSpacing(2)}px;
  }
`;

const TermsCategory = ({ header, explanations, terms }) => (
  <Content>
    <Header>{header}</Header>
    {explanations.map((explanation, index) => (
      <CategoryExplanation key={index}>{explanation}</CategoryExplanation>
    ))}
    <CategoryWrapper margin={10}>
      {terms.map((term, i) => {
        return (
          <React.Fragment key={i}>
            <Header>{term.header}</Header>
            <Content margin={10} key={term.header}>
              {term.texts.map((text, index) => (
                <TextWrapper key={text}>
                  {term.enumerable && <TextCounter>{`${index + 1}.`}</TextCounter>}
                  <BodyText2 dangerouslySetInnerHTML={{ __html: text }} />
                </TextWrapper>
              ))}
            </Content>
          </React.Fragment>
        );
      })}
    </CategoryWrapper>
  </Content>
);

TermsCategory.propTypes = {
  header: PropTypes.string.isRequired,
  terms: PropTypes.array.isRequired,
  explanations: PropTypes.array.isRequired,
};

export { TermsCategory, CategoryWrapper };
