import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { BreadcrumbsMenuArea, ArrowIcon } from "components/ui/Breadcrumbs/styledComponents";
import { Link } from "components/ui/Links/Links";
import { BodyText } from "components/ui/Typography/Typography";
import MenuItem from "@material-ui/core/MenuItem";

const MenuLink = styled(Link)`
  && {
    display: block;
    width: 100%;
  }
`;

const BreadcrumbsMenu = ({ list }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const ITEM_HEIGHT = 48;

  const handleClickBreadcrumbsMenuButton = e => setAnchorEl(e.currentTarget);

  const handleCloseBreadcrumbsMenu = event => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) return;
    setAnchorEl(null);
  };

  return (
    list.length > 0 && (
      <BreadcrumbsMenuArea>
        <IconButton
          aria-label="more parent's folders"
          aria-controls="Breadcrumbs-menu"
          aria-haspopup="true"
          onClick={handleClickBreadcrumbsMenuButton}
        >
          <MoreHorizIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          id="Breadcrumbs-menu"
          open={Boolean(anchorEl)}
          onClose={handleCloseBreadcrumbsMenu}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: 250,
            },
          }}
        >
          {list.map(item => (
            <MenuItem key={item.url}>
              <MenuLink to={item.url} noDecoration cv="grey50">
                <BodyText cv="grey100">{decodeURI(item.label)}</BodyText>
              </MenuLink>
            </MenuItem>
          ))}
        </Menu>
        <ArrowIcon />
      </BreadcrumbsMenuArea>
    )
  );
};

BreadcrumbsMenu.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export { BreadcrumbsMenu };
