import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";

import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { Table, TableHeader, TableBody } from "components/ui/Tables";
import { TravellersRestrictionAction, DeleteAction, TableActionsContainer } from "components/ui/Tables/TableRowActions";
import { NoResultsMessage } from "components/ui/Messages/NoResultsMessage";

import listColumns from "feature/panel/Trips/_shared/Flights/listColumns";
import { useManageTripContext } from "feature/panel/Trips/_shared/ManageTripContext";
import { FlightDetailsModal } from "feature/panel/Trips/_shared/Flights/FlightDetailsModal";
import { FlightsListRow } from "feature/panel/Trips/_shared/Flights/FlightsListRow";

import {
  CREATE_CONTENT_LABELS,
  EMPTY_LIST_MESSAGES_BASE,
  INDIVIDUAL_SETTINGS,
  NAMES_OF_RESOURCES_LISTS,
  PANEL_TRIPS_ACTION_MESSAGES,
  TABLE_ACTIONS_TITLES,
} from "constants/content";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import {
  TravellersRestrictionModal
} from "../../../../../components/_shared/TravellersRestrictionModal/TravellersRestrictionModal";
import { getRestrictionInfo } from "../../../../../helpers";
import { Message } from "../../../../../components/ui/Messages";

const TableWrapper = styled.div`
  .MuiTable-root {
    .MuiTableBody-root {
      .MuiTableRow-root {
        cursor: pointer;
      }
    }
  }
`;

const flightSortingComparisonDataMapper = (column, object) => {
  const { carrier_fs_code, carrier_flight_number, carrier, departure_airport, arrival_airport, departure_at_utc, arrival_at_utc, live_departure_at_utc, live_arrival_at_utc } =
    object || {};
  const mapper = {
    flight_number: `${carrier_fs_code}${carrier_flight_number}`,
    carrier: carrier ? carrier.name : "",
    departure_airport: departure_airport ? departure_airport.name : "",
    arrival_airport: arrival_airport ? arrival_airport.name : "",
    departure_at_utc: live_departure_at_utc ?? departure_at_utc,
    arrival_at_utc: live_arrival_at_utc ?? arrival_at_utc,
  };
  return mapper[column];
};

const flightSortingComparison = (a, b, column, order) => {
  const elA = String(flightSortingComparisonDataMapper(column, a)).toLowerCase();
  const elB = String(flightSortingComparisonDataMapper(column, b)).toLowerCase();
  const compresionTypes = {
    asc: elA > elB,
    desc: elA < elB,
  };
  return compresionTypes[order] ? 1 : -1;
};

const FlightsListSection = () => {
  const { currentOperator } = useSelector(state => state.operator);
  const { setValueFor, listOfFlights, vamoos_id, editMode, is_wiped, travelPeople, errors } = useManageTripContext();
  const [deleteModal, setDeleteModal] = useState({ open: false, body: {} });
  const [detailsModal, setDetailsModal] = useState({ open: false, body: {} });
  const [travellerRestrictionModal, setTravellerRestrictionModal] = useState({ open: false, body: {} });
  const [warningModal, setWarningModal] = useState(null);

  const permissionsService = usePermissionsService();

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoos_id);

  const handleChangeOrder = ({ column, order }) => {
    const sortedListOfFlights = listOfFlights.sort((a, b) => flightSortingComparison(a, b, column, order));

    setValueFor("listOfFlights", sortedListOfFlights);
  };

  const handleDeleteToggle = flight => {
    const { open } = deleteModal;
    const body = open ? deleteModal.body : flight;
    setDeleteModal({ body, open: !open });
  };

  const handleTravellersRestrictionToggle = flight => {

    if (errors?.travellers?.validationErrors) {
      setWarningModal("applyWarning");
      return;
    }

    if (flight) {
      const { hasRestrictions, mergeTravelPeople } = getRestrictionInfo(travelPeople, errors, flight, listOfFlights);
      const showWarningModal = mergeTravelPeople?.length < 2 && !hasRestrictions;
      if (showWarningModal) {
        setWarningModal("enableWarning");
        return;
      }
    }

    const { open } = travellerRestrictionModal;
    const body = open ? travellerRestrictionModal.body : flight;
    setTravellerRestrictionModal({ body, open: !open });
  };

  const handleDeleteConfirm = () => {
    setValueFor(
      "listOfFlights",
      listOfFlights.filter(flight => flight.id !== deleteModal.body.id),
    );
    handleDeleteToggle();
  };

  const rowActions = ({ item: flight }) => {
    // to add numberOfTravelers based on travelPeopleToDisplay instead of travelPeople
    const { personalisedTravellerIds } = getRestrictionInfo(travelPeople, errors, flight, listOfFlights);
    const disabled = is_wiped || (editMode && !canEdit);

    return (
      <TableActionsContainer>
        {(currentOperator?.meta?.show_personalisation !== false) ? (
          <TravellersRestrictionAction
            clickAction={event => {
              event.stopPropagation();
              handleTravellersRestrictionToggle(flight);
            }}
            tooltip={
              flight.restricted_to_traveller_internal_ids === null ? TABLE_ACTIONS_TITLES.addRestrictions : TABLE_ACTIONS_TITLES.editRestrictions
            }
            numberOfTravelers={personalisedTravellerIds?.length ?? null}
          />
        ) : null}
            <DeleteAction
              clickAction={event => {
                event.stopPropagation();
                handleDeleteToggle(flight);
              }}
              tooltip={TABLE_ACTIONS_TITLES.deleteResource(TABLE_ACTIONS_TITLES.resourceTypes.flight)}
              disabled={disabled}
              style={disabled ? { display: "none" } : {}}
            />
      </TableActionsContainer>
    );
  };

  rowActions.propTypes = {
    item: PropTypes.shape().isRequired,
  };

  const handleFlightDetailsToggle = flight => {
    const { open } = detailsModal;
    const body = open ? detailsModal.body : flight;
    setDetailsModal({ body, open: !open });
  };

  const deleteConfirmationText = (() => {
    const { body } = deleteModal;
    const { carrier_fs_code, carrier_flight_number, departure_airport, arrival_airport } = body;
    const { deleteConfirmationBase } = PANEL_TRIPS_ACTION_MESSAGES;
    return carrier_fs_code && carrier_flight_number && departure_airport && arrival_airport
      ? `
        ${deleteConfirmationBase} flight ${carrier_fs_code} ${carrier_flight_number}
        from ${departure_airport.city} to ${arrival_airport.city}?
      `
      : "";
  })();

  if (!listOfFlights.length && editMode && !canEdit) return null;
  if (!listOfFlights.length && editMode && canEdit)
    return <NoResultsMessage>{EMPTY_LIST_MESSAGES_BASE(CREATE_CONTENT_LABELS.flight, NAMES_OF_RESOURCES_LISTS.flight)}</NoResultsMessage>;

  listOfFlights.sort((a, b) => {
    const dateA = new Date(a.live_departure_at_utc ?? a.departure_at_utc);
    const dateB = new Date(b.live_departure_at_utc ?? b.departure_at_utc);
    return dateA - dateB;
  });

  return (
    <TableWrapper>
      <Table
        headers={listColumns}
        list={listOfFlights}
        actions={rowActions}
        onRowClick={handleFlightDetailsToggle}
        onChangeOrder={handleChangeOrder}
      >
        <TableHeader withActions />
        <TableBody rowComponent={FlightsListRow} />
      </Table>

      {deleteModal.open && (
        <ConfirmationModal
          open={deleteModal.open}
          title={deleteConfirmationText}
          confirmLabel="Delete"
          onCancel={handleDeleteToggle}
          onConfirm={handleDeleteConfirm}
        />
      )}
      <FlightDetailsModal open={detailsModal.open} flight={detailsModal.body} onClose={handleFlightDetailsToggle} />
      <TravellersRestrictionModal
        open={travellerRestrictionModal.open}
        data={travellerRestrictionModal.body}
        onClose={handleTravellersRestrictionToggle}
        onToggle={updatedData =>
          setTravellerRestrictionModal(prevState => ({
            open: prevState.open,
            body: updatedData,
          }))
        }
        name="listOfFlights"
      />
      <ConfirmationModal
        open={warningModal}
        title={INDIVIDUAL_SETTINGS.title}
        description={
          <>
            <Message text={warningModal === "applyWarning" ? INDIVIDUAL_SETTINGS.applyWarningDesc : INDIVIDUAL_SETTINGS.enableWarningDesc} type="info" />
          </>
        }
        confirmLabel="OK"
        onConfirm={() => setWarningModal(null)}
      />
    </TableWrapper>
  );
};

export { FlightsListSection, flightSortingComparisonDataMapper, flightSortingComparison };
