import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Input } from "components/ui/Forms/Inputs";
import { LIBRARY_UPLOAD } from "constants/content";
import { isValidUrl } from "feature/panel/Settings/CustomApps/_shared/TextsPage/helpers";

const StyledWrapper = styled.div`
  width: 100%;

  .MuiFormControl-root {
    background-color: white;
    border-radius: 8px;
  }

  .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.grey40};
    transition: ${({ theme }) => theme.transition};
  }

  :hover,
  &.Mui-focused {
    .MuiSvgIcon-root {
      color: ${({ theme }) => theme.colors.brandHover};
    }
  }

  && {
    .MuiFormLabel-root {
      pointer-events: none;
    }
  }
`;

const SectionWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 75%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
`;

const WebpageUpload = ({ setPermitUpload, setUploadAssets }) => {
  const [urlError, setUrlError] = useState(null);

  const validateWebUrlInput = value => {
    if (value.trim().length < 1) {
      setPermitUpload(false);
      setUrlError("Enter valid url");
      return false;
    }
    setPermitUpload(true);
    setUploadAssets({ webpageUrl: value });
    setUrlError(null);
    return true;
  };

  const handleValueChange = ({ target }) => {
    const { value } = target;

    validateWebUrlInput(value);
  };

  return (
    <SectionWrapper>
      <StyledWrapper style={{ margin: "15px 0" }}>
        <Input label={LIBRARY_UPLOAD.pasteUrl} name="search" noShadow onChange={handleValueChange} />
      </StyledWrapper>
    </SectionWrapper>
  );
};

WebpageUpload.propTypes = {
  setPermitUpload: PropTypes.func.isRequired,
  setUploadAssets: PropTypes.func.isRequired,
};

export { WebpageUpload };
