export class PoiRequestDto {
  /** @type {string} */
  name;

  /** @type {string} */
  location;

  /** @type {number} */
  latitude;

  /** @type {number} */
  longitude;

  /** @type {number} */
  position;

  /** @type {string} */
  description;

  /** @type {string} */
  country;

  /** @type {number} */
  icon_id;

  /** @type {string} */
  timezone;

  /** @type {boolean} */
  is_default_on;

  /** @type {number} */
  poi_range;

  /** @type {object} */
  file;

  /** @type {object} */
  meta;

  /** @type {"poi"|"track"|"waypoint"} */
  type;

  /** @type {number} */
  parent_id;

  /** @type {array} */
  children = [];
}
