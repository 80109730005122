import React from "react";
import PropTypes from "prop-types";
import MuiTableBody from "@material-ui/core/TableBody";

import { DefaultTableRow } from "components/ui/Tables/DefaultTableRow";
import { useTableContext } from "components/ui/Tables/TableContext";
import TableCell from "@material-ui/core/TableCell";
import { TableRow } from "@material-ui/core";

const TableBody = ({ rowComponent, shouldCheckSaveLock, placeholderText = "No records found", withCheckbox, selectedItems, setSelectedItems, setAllPageCheckboxesSelected }) => {
  const { list } = useTableContext();

  const isEmpty = list?.length <= 0;

  const RenderedRowComponent = (item, index) =>
    rowComponent ? (
      React.createElement(rowComponent, { item, index, key: item.vamoosId || item.id, shouldCheckSaveLock, withCheckbox, selectedItems, setSelectedItems, setAllPageCheckboxesSelected })
    ) : (
      <DefaultTableRow key={item.vamoosId || item.id || item.email_address} item={item} />
    );

  return (
    <MuiTableBody>
      {isEmpty ? (
        <TableRow>
          <TableCell colSpan={12} style={{ textAlign: "center", color: " #8d9095" }}>
            {placeholderText}
          </TableCell>
        </TableRow>
      ) : (
        list.map((item, index) => RenderedRowComponent(item, index))
      )}
    </MuiTableBody>
  );
};

TableBody.defaultProps = {
  rowComponent: undefined,
  withCheckbox: false,
  selectedItems: { allItemsSelected: false, vamoosIds: [] },
  setSelectedItems: () => {},
  setAllPageCheckboxesSelected: () => {},
};

TableBody.propTypes = {
  rowComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.node]),
  item: PropTypes.shape().isRequired,
  withCheckbox: PropTypes.bool,
  selectedItems: PropTypes.shape(),
  setSelectedItems: PropTypes.func,
  setAllPageCheckboxesSelected: PropTypes.func,
};

export { TableBody };
