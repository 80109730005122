import { getMediaPx } from "helpers";
import styled from "styled-components";

export const Inputs = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 15px;
  max-width: 40vw;

  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    max-width: 100vw;
    flex-direction: column;
  }
`;
