import { Grid } from "@material-ui/core";
import { CreateNewFolderOutlined, DeleteOutline, PeopleAltOutlined } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import Badge from "components/_new/Badge";
import AttachButton from "components/_new/Buttons/AttachButton";
import OutlinedButton from "components/_new/Buttons/OutlinedButton";
import DnD from "components/_new/DnD_new";
import TravellersModal from "components/_new/TravellersModal";
import SelectInput from "components/ui/Inputs/Select";
import Input from "components/ui/Inputs/TextInput";
import config from "config/app";
import { LIBRARY_ROOT_PATH } from "constants/api";
import { ERRORS } from "constants/content";
import { StoryboardFile } from "domain/StoryboardFile";
import { TextInputEditor } from "feature/panel/Settings/CustomApps/_shared/LoginScreen/TextInputEditor";
import { ImsertGrid } from "feature/panel/Trips/_shared/Storyboard/Entries/EntryForm/ImsertGrid";
import { useService } from "hooks/useService";
import { debounce } from "lodash";
import moment from "moment";
import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { useDispatch } from "react-redux";
import { StoryboardService } from "services/StoryboardService";
import { HttpClient } from "services/application/httpClient/httpClient";
import { pushErrorNotification, pushSuccessNotification } from "store/app/actions";
import { deviceType } from "utils/deviceDetect";
import uuidv4 from "uuid";
import { useEffectDependenciesOnly } from "hooks/useEffectDependenciesOnly";
import { DEFAULT_ACTION_ICON_ID } from "../../../../../Stays/_shared/initStaysState";
import ConfirmDeleteModal from "./confirmDeleteModal";
import DocumentItem from "./dndItem";
import FileExistModal from "./fileExistModal";
import AddToLibraryModal from "./modal";
import PreventAddToLibraryModal from "./preventAddToLibraryModal";
import { BottomLine, Buttons, GridWrap, InputsGrid, InputWrapper, Title, Wrap, Wrapper } from "./style";

const StoryboardItem = forwardRef(
  (
    {
      control,
      index,
      itemId,
      setValue,
      showDays,
      disabled,
      watchName,
      watch,
      locations,
      remove,
      replace,
      getValues,
      forwardKey,
      isDirty,
      changedDayItemId,
      setChangedDayItemId,
      trigger,
      storyboardErrors,
      errors,
      clearErrors,
    },
    ref,
  ) => {
    const dayNumberRef = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedPeopleIds, setSelectedPeopleIds] = useState(null);

    const dispatch = useDispatch();

    const item = watch(`${watchName}.${index}`);
    const dayNumber = watch(`${watchName}.${index}.meta.day_number`);
    const position = watch(`${watchName}.${index}.position`);
    const items = getValues(watchName);
    const departureDate = watch("general.departure_date");
    const device = deviceType();
    const isMobile = device === "mobile";
    const image = item.image;

    const storyboardService = useService(StoryboardService);
    const watchNameDocuments = `${watchName}.${index}.documents`;

    const { fields: documents, append, remove: removeDocument, move } = useFieldArray({
      control,
      name: watchNameDocuments,
      keyName: "doc_id",
    });

    const [isTravellersOpen, setIsTravellersOpen] = useState(false);
    const [isOpened, setIsOpened] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showPreventModal, setShowPreventModal] = useState(false);
    const [fileExistModal, setFileExistModal] = useState(false);
    const [fileData, setFileData] = useState(null);
    const [addButtonClicked, setAddButtonClicked] = useState(false);

    const people = watch("people.travellers");
    const restrictedTravellerIds = watch(`${watchName}.${index}.meta.restricted_to_traveller_internal_ids`);

    const onDocumentAdd = file => {
      setAddButtonClicked(false);
      append({ id: uuidv4(), name: file ? file.file_name : "", file: file ?? null, icon_id: DEFAULT_ACTION_ICON_ID });
    };

    const onMultiDocUpload = files => {
      for (const file of files) {
        onDocumentAdd(file);
      }
    };

    const onDragEnd = (list, { source, destination }) => move(source.index, destination.index);

    const toggleOpen = (e) => {
      if (e.target.closest('button') || !isMobile) {
        return;
      }

      setIsOpened(!isOpened);
    }

    const onDelete = e => {
      e.stopPropagation();
      const indexToDelete = items?.findIndex(item => item.id === itemId);

      remove(indexToDelete);
    };
    const toggleShowModal = () => setShowModal(!showModal);
    const togglePreventModal = () => setShowPreventModal(!showPreventModal);

    const toggleAddToLibrary = () => {
      if (isDirty) {
        setShowPreventModal(true);
      } else {
        toggleShowModal();
      }
    };
    const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);
    const toggleTravellersModal = () => setIsTravellersOpen(!isTravellersOpen);
    const toggleFileExistModal = () => {
      if (fileExistModal) setIsLoading(false);
      setFileExistModal(!fileExistModal);
    };

    const onTravellersChange = arr => {
      setValue(`${watchName}.${index}.meta.restricted_to_traveller_internal_ids`, arr, { shouldDirty: true });
      toggleTravellersModal();
    };

    const onAddToLibrary = async ({ path, name }, preventCheckForExistingFile) => {
      setIsLoading(true);
      setFileData({ path, name });

      const finalPath = path || fileData.path;
      const finalName = name || fileData.name;

      let isFileExists = false;
      if (!preventCheckForExistingFile) {
        const res = await HttpClient.get(`/file?path=${encodeURIComponent(path)}`);
        const files = res.data || [];

        isFileExists = Boolean(files.find(item => item.name === finalName));
      }

      if (isFileExists) {
        toggleFileExistModal();
      } else {
        try {
          await storyboardService.uploadToLibrary(
            new StoryboardFile(finalName, item).convertToNativeFile(),
            finalPath.replace(new RegExp(`^${LIBRARY_ROOT_PATH}`), ""),
            item?.image?.https_url || item?.image?.previewUrl || item?.image?.preview_url || null,
          );
          setFileData(null);
          setFileExistModal(false);
          setShowModal(false);

          dispatch(pushSuccessNotification("Storyboard component has been successfully saved"));
        } catch (e) {
          if (e.response?.data?.error?.includes("already exist")) {
            dispatch(pushErrorNotification(ERRORS.fileNameAlreadyExists));
          } else {
            dispatch(pushErrorNotification(ERRORS.unknownError));
          }
        } finally {
          setIsLoading(false);
        }
      }
    };

    const onDayChange = useCallback((dayValue) => {
      setValue("_internal.busy", false);
      if (!dayValue || dayValue === dayNumber) return;
      
      const dayNum = dayValue ? +dayValue : 0;
      const itemOldPosition = item.position;
      let itemNewPosition;
      let updatedItems;

      const findDayIndex = items?.findIndex(findItem => findItem.meta.day_number === dayNum && findItem.id !== item.id);
      if (findDayIndex !== -1) {
        const lastSameDayPosition = Math.max(
          ...items.filter(otherItem => otherItem.meta.day_number === dayNum && otherItem.id !== item.id).map(otherItem => otherItem.position));
        const isIncreasing = itemOldPosition < lastSameDayPosition;

        updatedItems = items.map(mappedItem => {
          if (mappedItem.id === item.id) {
            itemNewPosition = isIncreasing ? lastSameDayPosition : lastSameDayPosition + 1;
            return { ...mappedItem, position: itemNewPosition };
          }
          return {
            ...mappedItem,
            position: isIncreasing
              ? (mappedItem.position <= lastSameDayPosition && mappedItem.position > itemOldPosition) ? mappedItem.position - 1 : mappedItem.position
              : (mappedItem.position > lastSameDayPosition && mappedItem.position < itemOldPosition) ? mappedItem.position + 1 : mappedItem.position
          };
        })
      } else {
        updatedItems = [...items]
          .sort((a, b) => a.meta.day_number - b.meta.day_number)
          .map((item, index) => ({ ...item, position: index + 1 }));

        const foundIndex = updatedItems.findIndex(x => x.id === item.id);
        itemNewPosition = foundIndex + 1;
      }

      if (itemNewPosition && itemNewPosition !== itemOldPosition) setChangedDayItemId(item.id);
      replace(updatedItems);
    }, [items]);

    // added useMemo and useCallback because with the previous implementation if user used two or more numbers for Day Number then onDayChange would be called twice or more, scrolling each time
    const debouncedOnDayChange = useMemo(() => {
      return (day) => {
        setValue("_internal.busy", true);
        return debounce(() => onDayChange(day), 750)()
      }
    }, [onDayChange]);

    useEffect(() => {
      if (!restrictedTravellerIds || !people.length) return;

      if (restrictedTravellerIds?.length === 0) {
        setSelectedPeopleIds([]);
      } else {
        const selectedPeopleIdsFromSelected = people
          .filter(person => person.travellersData.some(traveller => restrictedTravellerIds?.includes(traveller.internal_id)))
          .map(person => person.id);
        setSelectedPeopleIds(selectedPeopleIdsFromSelected);
      }
    }, [people, restrictedTravellerIds]);

    return (
      <>
        {isTravellersOpen && (
          <TravellersModal
            people={people}
            onConfirm={onTravellersChange}
            toggleModal={toggleTravellersModal}
            disabled={disabled}
            selectedPeopleIds={selectedPeopleIds}
            setSelectedPeopleIds={setSelectedPeopleIds}
          />
        )}
        {showPreventModal && <PreventAddToLibraryModal onCancel={togglePreventModal} />}
        {showDeleteModal && <ConfirmDeleteModal onConfirm={onDelete} onCancel={toggleDeleteModal} />}
        {showModal && <AddToLibraryModal onConfirm={onAddToLibrary} onCancel={toggleShowModal} isLoading={isLoading} />}
        {fileExistModal && <FileExistModal onConfirm={() => onAddToLibrary({}, true)} onCancel={toggleFileExistModal} />}
        <Wrap key={item.id} ref={ref} id={`item-${item.id}`}>
          <Title onClick={toggleOpen}>
            {showDays ? (
              <div>
                Day {dayNumber}
                {dayNumber > 0 && (
                  <>
                    :{" "}
                    <span>
                      {moment(departureDate)
                        .add("days", dayNumber - 1)
                        .format(config.storyboardDateFormat)}
                      <strong>{item.headline && ` -  ${item.headline.substring(0, 30)}${item.headline.length > 30 ? "..." : ""}`}</strong>
                    </span>
                  </>
                )}
              </div>
            ) : (
              `#${position}`
            )}
              <Buttons>
                <Badge badgeContent={selectedPeopleIds?.length ?? null} showZero>
                  {" "}
                  <OutlinedButton
                    startIcon={<PeopleAltOutlined />}
                    onClick={toggleTravellersModal}
                    tooltip="Restrict visibility"
                    type="grey"
                    iconOnlyMobile
                    style={{ margin: "0 -8px 0 0" }}
                  />
                </Badge>
                {!disabled &&
                  <OutlinedButton
                    startIcon={<CreateNewFolderOutlined />}
                    onClick={toggleAddToLibrary}
                    iconOnlyMobile
                    tooltip="Add to library"
                    type="grey"
                  />
                }
                {!disabled && <OutlinedButton text="Delete" startIcon={<DeleteOutline />} onClick={onDelete} iconOnlyMobile type="grey" />}
              </Buttons>
          </Title>
          <div style={{ height: 20 }} />
          {isOpened ? (
            <GridWrap>
              <Grid style={{ flexGrow: 0 }} item lg md={4} sm={6} xs={12}>
                <ImsertGrid
                  disabled={disabled}
                  newLibrary
                  item={item}
                  image={image}
                  onUpdateImage={image => {
                    setValue(`${watchName}.${index}.image`, image, { shouldDirty: true });
                  }}
                />
              </Grid>

              <InputWrapper>
                <InputsGrid style={{gridTemplateColumns: showDays ? '1fr 1fr 1fr' : '1fr 1fr'}}>
                  { showDays ? (
                    <Input
                      label="Day number"
                      control={control}
                      name={`${watchName}.${index}.meta.day_number`}
                      disabled={disabled}
                      type="number"
                      forwardKey={`${forwardKey}-day`}
                      customOnChange={debouncedOnDayChange}
                      numberMinValue={0}
                      ref={dayNumberRef}
                    />
                  ) : null }

                  <Input label="Title" control={control} name={`${watchName}.${index}.headline`} disabled={disabled} autoFocus={!!ref} forwardKey={`${forwardKey}-headline`} />
                  <SelectInput
                    label="Location"
                    control={control}
                    name={`${watchName}.${index}.location`}
                    options={[{ id: "none", name: "None" }, ...(locations || [])]}
                    optionValueVar="id"
                    optionLabelVar="name"
                    isClearable={false}
                    disabled={disabled}
                    noSort
                  />
                </InputsGrid>
                <Wrapper>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextInputEditor
                        label="Additional information"
                        onChange={onChange}
                        value={value}
                        toolbar="bold italic underline link addPhone fontsizeselect undo redo"
                        disabled={disabled}
                        index={index}
                        maxHeight={100000}
                      />
                    )}
                    name={`${watchName}.${index}.content`}
                  />
                </Wrapper>
                <Wrapper>
                  <BottomLine>
                    <div>Background image:</div>
                    <Controller
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <AttachButton
                          text="Add image"
                          types={["library", "upload", "unsplash"]}
                          file={value}
                          onDelete={() => onChange(null)}
                          onConfirm={onChange}
                          disabled={disabled}
                          buttonWrapStyle={{ width: "auto" }}
                          hideDescription
                          hideUrl
                        />
                      )}
                      name={`${watchName}.${index}.image`}
                    />
                  </BottomLine>
                  <BottomLine>
                    <div>Documents:</div>
                    {!disabled && (
                      <OutlinedButton
                        text="Add"
                        type="grey"
                        startIcon={<AddIcon />}
                        onClick={() => {
                          onDocumentAdd(null);
                          setAddButtonClicked(true);
                        }}
                      />
                    )}
                  </BottomLine>
                  <DnD
                    list={item?.documents || []}
                    disabled={disabled}
                    onDragEnd={onDragEnd}
                    contentStyle={{ padding: 0 }}
                    element={
                      <DocumentItem
                        control={control}
                        setValue={setValue}
                        watch={watch}
                        storyBoardItemId={itemId}
                        watchNameDocuments={watchNameDocuments}
                        disabled={disabled}
                        onDelete={removeDocument}
                        onMultiUpload={onMultiDocUpload}
                        multiUpload
                        trigger={trigger}
                        storyboardErrors={storyboardErrors}
                        errors={errors}
                        storyboardItemIndex={index}
                        clearErrors={clearErrors}
                        addButtonClicked={addButtonClicked}
                        setAddButtonClicked={setAddButtonClicked}
                      />
                    }
                  />
                </Wrapper>
              </InputWrapper>
            </GridWrap>
          ) : (
            // TODO?
            <></>
            // <div style={{ display: "flex" }} onClick={toggleOpen}>
            //   <Image image={imageSrc} style={{ width: 100, height: 100 }}>
            //     {!image && <ImageIcon />}
            //   </Image>
            //   <div style={{ color: colors_new.greyText, marginLeft: 15, fontSize: 16 }}>{headline}</div>
            // </div>
          )}
        </Wrap>
      </>
    );
  },
);

export default StoryboardItem;
