import { colors } from "config/theme/colors";
import styled from "styled-components";

export const Wrap = styled.div`
  border: 1px solid ${colors.grey10};
  border-bottom: none;
  height: 56px;
  padding-left: 137px;
  display: grid;
  grid-template-columns: minmax(100px, 300px) 1fr;
  font-size: 12px;
  font-family: "Montserrat";
  color: ${colors.grey30};
  align-items: center;
`;
