import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import BaseModal from "components/_new/ModalBase";
import Tabs from "components/_new/Tabs";
import { HttpClient } from "services/application/httpClient/httpClient";
import SelectInput from "components/ui/Inputs/Select";
import Input from "components/ui/Inputs/TextInput";
import { setNotification } from "store/app/actions";
import Checkbox from "components/_new/Checkbox";
import { encodeParameter } from "utils/url";
import Switch from "../../../components/_new/Switch";
import { BottomSwitches, SwitchTip, SwitchWrapper } from "./style";
import { TRIP_WIZARD_CONTENT, UPDATE_MODAL } from "../../../constants/content";
import { LanguageContext } from "../../../components/_shared/LanguageSelector/LanguageContext";

const UpdateModal = ({
  passcode,
  operatorCode,
  onClose,
  language,
  onSubmitCustom,
  customTitle,
  isLoading,
  totalMatches,
  selectedItems,
  setChecked,
  checked,
  itinType,
  languages,
}) => {
  const dispatch = useDispatch();
  const { control, handleSubmit, reset, watch } = useForm();
  const [tab, setTab] = useState("template");
  const currentLanguage = languages?.find(lng => lng?.code === language);

  const { data: operator } = useQuery({ queryKey: ["operator"] });

  const { data: { data: { items } } = { data: { items: [] } } } = useQuery({
    queryKey: ["forcedNotifications"],
    queryFn: () => HttpClient.get("/notification/list/forced?&order=desc"),
  });

  const templateNotificationWatch = watch("template");
  const templateNotification = templateNotificationWatch && items?.find(item => item.id === templateNotificationWatch);

  const { mutate } = useMutation(
    body => {
      return HttpClient.post(`/itinerary/${encodeParameter(operatorCode)}/${encodeParameter(passcode)}/push`, {
      ...body
      });
    },
    {
      onSuccess: () => {
        dispatch(setNotification({ message: "Updated successfully", type: "success" }));
        onClose();
      },
      onError: () => dispatch(setNotification({ type: "error", message: TRIP_WIZARD_CONTENT.notifications.forceUpdateError })),
    },
  );

  const onSubmit = values => {
    const notification_text = values.bespoke ||
      templateNotification.localisation?.[language]?.content ||
      templateNotification.localisation?.[operator.default_language]?.content ||
      templateNotification.content;

    const notificationBody = {
      notification_text,
      force: watch("toggles.forceUpdate"),
      ...(itinType === "stay" && { check_dates: watch("toggles.sendOnlyToActive"), }),
      ...(itinType === "trip" && { send_email: watch("toggles.sendEmail"), }),
      ...(watch("toggles.useCurrentLanguage") && { language: currentLanguage.code }),
    }

    if (onSubmitCustom) onSubmitCustom(notificationBody);
    else mutate(notificationBody);
  };

  useEffect(() => {
    reset();
  }, [tab]);

  return (
    <BaseModal
      title={customTitle ?? "Send notification"}
      confirmTitle="Send"
      onConfirm={handleSubmit(onSubmit)}
      onCancel={onClose}
      modalStyle={{ overflow: "visible" }}
      contentStyle={{ overflow: "visible" }}
      isLoading={isLoading}
      mobileFullScreen
      leftAction={
        selectedItems?.allItemsSelected &&
        selectedItems?.vamoosIds?.length !== totalMatches && (
          <Checkbox
            checked={checked}
            handleChange={() => {
              setChecked(!checked);
            }}
            label={`Apply to all ${totalMatches} entries matching current criteria`}
            labelStyle={{ fontSize: 12, lineHeight: "normal", marginLeft: -5 }}
          />
        )
      }
    >
      <div style={{ overflow: "hidden" }}>
        <Tabs
          tabs={[
            { label: "Notification template", id: "template" },
            { label: "Bespoke notification", id: "bespoke" },
          ]}
          tab={tab}
          setTab={tab => setTab(tab)}
        />
      </div>
      {tab === "template" ? (
        <SelectInput
          control={control}
          autoFocus
          name="template"
          label="Select template"
          optionLabelVar="content"
          optionValueVar="id"
          options={items}
          isClearable={false}
          rules={{ required: tab === "template" }}
        />
      ) : (
        <Input
          autoFocus
          control={control}
          name="bespoke"
          label="Notification text"
          multiline
          minRows={2}
          maxRows={5}
          rules={{ required: tab === "bespoke" }}
        />
      )}
      <BottomSwitches>
        <SwitchWrapper>
          <Switch
            control={control}
            noBackground
            name="toggles.forceUpdate"
            label={UPDATE_MODAL.forceUpdateRefactored}
          />
          <SwitchTip>{UPDATE_MODAL.forceUpdateRefactoredTip}</SwitchTip>
        </SwitchWrapper>
        {itinType === "stay" ?
          (
            <>
              <SwitchWrapper>
                <Switch
                  control={control}
                  noBackground
                  name="toggles.sendOnlyToActive"
                  label={UPDATE_MODAL.sendOnlyToActive}
                />
                <SwitchTip>{UPDATE_MODAL.sendOnlyToActiveTip}</SwitchTip>
              </SwitchWrapper>
              <SwitchWrapper>
                <Switch
                  control={control}
                  noBackground
                  name="toggles.useCurrentLanguage"
                  label={UPDATE_MODAL.useCurrentLanguage}
                />
                <SwitchTip>{UPDATE_MODAL.useLanguage(currentLanguage?.name)}</SwitchTip>
              </SwitchWrapper>
            </>
          ) : (
            <SwitchWrapper>
              <Switch
                control={control}
                noBackground
                name="toggles.sendEmail"
                label={UPDATE_MODAL.sendEmail}
              />
              <SwitchTip>{UPDATE_MODAL.sendEmailTip}</SwitchTip>
            </SwitchWrapper>
          )}
      </BottomSwitches>
    </BaseModal>
  );
};

export default UpdateModal;
