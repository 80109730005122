import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { EditOutlined } from "@material-ui/icons";
import { GhostButton } from "components/ui/Buttons";
import { BodyText, BodyText2 } from "components/ui/Typography/Typography";

const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey10};
  padding: ${({ theme }) => theme.setSpacing(3)}px 0 ${({ theme }) => theme.setSpacing(3)}px 0;

  :last-of-type {
    border-bottom: 0;
  }
`;

const ValuesWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const TitleText = styled(BodyText2)`
  &&& {
    display: flex;
    min-width: 320px;
    color: ${({ theme }) => theme.colors.grey50};
  }
`;

const ContentTextWrapper = styled(BodyText)`
  display: flex;
  max-width: 576px;
`;

const InformationField = ({ title, content, children, onEditClick, editSlot, disabled }) => {
  const contentValue = content ? <ContentTextWrapper>{content}</ContentTextWrapper> : children;

  return (
    <FieldWrapper>
      <ValuesWrapper>
        <TitleText>{title}</TitleText>
        {contentValue}
      </ValuesWrapper>
      {editSlot}
      {onEditClick && !disabled && (
        <GhostButton onClick={onEditClick} cv="grey50" cvh="grey60" iconButton>
          <EditOutlined />
        </GhostButton>
      )}
    </FieldWrapper>
  );
};

InformationField.defaultProps = {
  content: null,
  children: null,
  onEditClick: undefined,
  editSlot: undefined,
};

InformationField.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.element,
  onEditClick: PropTypes.func,
  editSlot: PropTypes.node,
};

export { InformationField, FieldWrapper, ValuesWrapper, TitleText, ContentTextWrapper };
