import BaseModal from "components/_new/ModalBase";
import React from "react";

const PreventAddToLibraryModal = ({ onCancel }) => {
  return (
    <BaseModal
      title="In order to add this entry to the library you first have to save your changes. Please save your changes and try again."
      onCancel={onCancel}
      onConfirm={onCancel}
      modalStyle={{ maxWidth: 600 }}
      confirmTitle="Back"
      hideCancel
    />
  );
};

export default PreventAddToLibraryModal;
