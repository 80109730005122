import React, { useContext } from "react";
import { Container } from "./style";
import { NoResultsMessage } from "../../../../../components/ui/Messages";
import { EMPTY_VARIANT_SECTION } from "../../../../../constants/content";
import { EditorContext } from "../../index";

const EmptyListMessages = ({ watch, emptyListBaseMessage }) => {
  const { stayLanguages } = useContext(EditorContext);

  const getLanguageName = languageCode => stayLanguages?.find(lng => lng.code === languageCode)?.name;
  const currentLanguageName = getLanguageName(watch("general.language"));
  const defaultLanguageName = getLanguageName(watch("main.general.language"));
  const showEmptyVariantSection = !!watch("main") && currentLanguageName !== defaultLanguageName;

  return (
    <Container>
      {showEmptyVariantSection &&
        <NoResultsMessage style={{ height: "100px" }}>
          {EMPTY_VARIANT_SECTION(currentLanguageName, defaultLanguageName)}
        </NoResultsMessage>}
      <NoResultsMessage style={{ height: "100px" }}>{emptyListBaseMessage}</NoResultsMessage>
    </Container>
  )
};

export default EmptyListMessages;