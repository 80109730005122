import { colors, colors_new } from "config/theme/colors";
import { font } from "config/theme/fonts";
import styled from "styled-components";

export const Wrap = styled.div`
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 15px;
  padding-bottom: 15px;
  opacity: ${p => (p.isOverriden ? 0.3 : 1)};
`;

export const Title = styled.div`
  font-family: ${font};
  font-size: 16px;
  font-weight: 700;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
`;

export const SecondaryText = styled.div`
  font-family: ${font};
  color: ${colors_new.greyText};
  font-weight: 600;
  margin-top: 3px;
`;
