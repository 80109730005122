import React from "react";
import styled from "styled-components";
import { withDynamicElement } from "hoc/withDynamicElement";

const styledContent = styled.div`
  width: inherit;
  height: inherit;
  ${({ theme, margin, noMargin }) => {
    if (!noMargin) {
      return `margin-bottom: ${margin ? theme.setSpacing(margin) : theme.setSpacing(8)}px`;
    }
    return null;
  }}
`;

const StyledFixedContent = styled.div`
  width: 100%;
  max-width: inherit;
  position: fixed;
  z-index: ${({ index }) => index || 1};
`;

const FlexGrow = styled(({ grow, ...rest }) => <div {...rest} />)`
  flex-grow: ${({ grow }) => grow};
`;

const Content = withDynamicElement(styledContent);
const FixedContent = withDynamicElement(StyledFixedContent);

export { Content, FixedContent, FlexGrow };
