import { getMediaPx } from "helpers";
import styled from "styled-components";

export const Grid = styled.div`
  display: grid;
  grid-template-rows: 180px 14fr 1fr;
  height: ${p => (p.hasAdditionalMenu ? "calc(100vh - 195px)" : "calc(100vh - 130px)")};
  /* padding-bottom: 15px; */

  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    grid-template-rows: 1fr 14fr 1fr;
    height: ${p => (p.hasAdditionalMenu ? " calc(100svh - 235px)" : " calc(100svh - 170px)")};
  }
`;
