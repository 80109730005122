export const getTabs = locations => ({
  locations: {
    label: "Locations",
    id: "locations",
  },
  pois: {
    label: "POIs",
    disabled: locations?.length < 1,
    id: "pois",
  },
});

export const createSearch = arr => {
  let result = "";

  arr?.forEach(item => {
    result += `&location=${encodeURIComponent(`${item.latitude}, ${item.longitude}`)}`;
  });

  return result;
};
