import { getMediaPx } from "helpers";
import styled from "styled-components";

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    grid-template-columns: 1fr;
  }
`;
