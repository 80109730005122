import { commonInputCss } from "components/ui/Inputs/TextInput/style";
import styled from "styled-components";

export const Wrap = styled.div`
  .MuiFormLabel-root {
    margin: -7px 0 0 15px;
    background-color: #fff;
    padding: 0 5px;
    font-size: 14px;
    width: fit-content;
  }
  .MuiAccordionSummary-root {
    min-height: 40px;
  }
  .MuiAccordionSummary-content {
    margin: 0;
  }
  .MuiPaper-elevation {
    box-shadow: none;
  }
`;
