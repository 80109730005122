import { AdjustOutlined, PeopleAltOutlined } from "@material-ui/icons";
import {
  DescriptionOutlined,
  DownloadOutlined,
  FlightOutlined,
  ImageOutlined,
  LocationOnOutlined,
  LockOutlined,
  SortByAlphaOutlined,
  VpnKeyOutlined,
} from "@mui/icons-material";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import PublicIcon from "@mui/icons-material/Public";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import SplitscreenIcon from "@mui/icons-material/Splitscreen";
import React from "react";
import Actions from "../../pages/Stays/Actions";
import DND from "../../pages/Stays/DND";
import DailyActivities from "../../pages/Stays/DailyActivities";
import Directory from "../../pages/Stays/Directory";
import Gallery from "../../pages/Stays/Gallery";
import GeneralStay from "../../pages/Stays/General";
import POIs from "../../pages/Stays/POIs";
import Passcodes from "../../pages/Stays/Passcodes";
import Vouchers from "../../pages/Stays/Vouchers";
import Creations from "../../pages/Trips/Creations";
import Documents from "../../pages/Trips/Documents";
import Flights from "../../pages/Trips/Flights";
import General from "../../pages/Trips/General";
import Inspirations from "../../pages/Trips/Inspiration";
import Locations from "../../pages/Trips/Locations";
import Message from "../../pages/Trips/Message";
import Notifications from "../../pages/Trips/Notifications";
import People from "../../pages/Trips/People";
import ShareAccess from "../../pages/Trips/ShareAccess";
import Storyboard from "../../pages/Trips/Storyboard";

export const navigations = {
  trip: [
    {
      key: 0,
      label: "General",
      icon: <ListAltOutlinedIcon />,
      path: "general",
      element: <General />,
    },
    {
      key: 1,
      label: "Documents",
      icon: <DescriptionOutlined />,
      path: "documents",
      element: <Documents />,
    },
    {
      key: 2,
      label: "Locations",
      icon: <RoomOutlinedIcon />,
      path: "locations",
      element: <Locations />,
    },
    // {
    //   key: 22,
    //   label: "Locations NEW",
    //   icon: <RoomOutlinedIcon />,
    //   path: "locations_new",
    //   element: <LocationsNew />,
    // },
    {
      key: 3,
      label: "Storyboard",
      icon: <SplitscreenIcon />,
      path: "storyboard",
      element: <Storyboard />,
    },
    {
      key: 4,
      label: "Notifications",
      icon: <NotificationsNoneIcon />,
      path: "notifications",
      element: <Notifications />,
    },
    {
      key: 5,
      label: "People",
      icon: <PeopleAltOutlined />,
      path: "people",
      element: <People />,
    },
    {
      key: 6,
      label: "Flights",
      icon: <FlightOutlined />,
      path: "flights",
      element: <Flights />,
    },
    {
      key: 7,
      label: "Inspirations",
      icon: <PublicIcon />,
      path: "inspirations",
      element: <Inspirations />,
    },
    {
      key: 8,
      label: "Creations",
      icon: <DownloadOutlined />,
      path: "creations",
      element: <Creations />,
      existingItineraryOnly: true,
    },
    {
      key: 9,
      label: "Messaging",
      icon: <MailOutlineIcon />,
      path: "messages",
      element: <Message />,
    },
    {
      key: 10,
      label: "Share access",
      icon: <LockOutlined />,
      path: "share_access",
      element: <ShareAccess />,
    },
  ],
  stay: [
    {
      key: 0,
      label: "General",
      icon: <ListAltOutlinedIcon />,
      path: "general",
      element: <GeneralStay />,
    },
    {
      key: 1,
      label: "Actions",
      icon: <AdjustOutlined />,
      path: "actions",
      element: <Actions />,
    },
    {
      key: 2,
      label: "POIs",
      icon: <LocationOnOutlined />,
      path: "pois",
      element: <POIs />,
    },
    {
      key: 3,
      label: "Gallery",
      icon: <ImageOutlined />,
      path: "gallery",
      element: <Gallery />,
    },
    {
      key: 4,
      label: "Directory",
      icon: <SortByAlphaOutlined />,
      path: "directories",
      element: <Directory />,
    },
    {
      key: 5,
      label: "Daily activities",
      icon: <QueryBuilderIcon />,
      path: "activities",
      element: <DailyActivities />,
    },
    {
      key: 5,
      label: "Vouchers/menus",
      icon: <CardGiftcardIcon />,
      path: "vouchers",
      element: <Vouchers />,
    },
    {
      key: 6,
      label: "Passcodes",
      icon: <VpnKeyOutlined />,
      path: "passcode_groups",
      element: <Passcodes />,
      defaultLangOnly: true,
    },
    {
      key: 7,
      label: "Notifications",
      icon: <NotificationsNoneIcon />,
      path: "notifications",
      element: <Notifications />,
    },
    {
      key: 8,
      label: "Inspirations",
      icon: <PublicIcon />,
      path: "inspirations",
      element: <Inspirations type="stay" />,
    },
    {
      key: 12,
      label: "DND",
      icon: <SellOutlinedIcon />,
      path: "dnd",
      element: <DND />,
      defaultLangOnly: true,
    },
    {
      key: 9,
      label: "Publisher",
      icon: <DownloadOutlined />,
      path: "creations",
      element: <Creations isPublisher />,
      defaultLangOnly: true,
      existingItineraryOnly: true,
    },
    {
      key: 10,
      label: "Messaging",
      icon: <MailOutlineIcon />,
      path: "messages",
      element: <Message />,
      defaultLangOnly: true,
    },
    {
      key: 11,
      label: "Share access",
      icon: <LockOutlined />,
      path: "share_access",
      element: <ShareAccess />,
      defaultLangOnly: true,
    },
  ],
};
