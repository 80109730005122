// @ts-check

class Operator {
  /** @type {number} */
  id;

  /** @type {string} */
  code;

  /** @type {string} */
  name;

  /** @type {FileWrapper} */
  logo;

  /** @type {string[]} */
  usedCodes;

  /** @type {FileWrapper} */
  creationsFooter;

  /** @type {FileWrapper} */
  creationsHeader;

  /** @type {Label[]} */
  labels;

  /** @type {object} */
  documents;

  /** @type {string} */
  type;

  /** @type {boolean} */
  isActive;

  /** @type {object} */
  meta = {};

  /** @type {string} */
  createdAt;

  /** @type {string} */
  updatedAt;

  /** @type {User[]} */
  admins;

  /** @type {object} */
  downloads;

  /** @type {object} */
  created;

  /** @type {object} */
  operatorCodes;

  /** @type {string} */
  termsAcceptedAt;

  /** @type {object} */
  role;

  /** @type {number} */
  roleId;

  /** @type {object} */
  overrides;

  /** @type {object} */
  permissions;

  /** @type {boolean} */
  user_is_active;

  /** @type {number} */
  user_id;

  /** @type {object} */
  storyBoardView;

  /** @type {string} */
  constactPerson;

  /** @type {string} */
  telephone;

  /** @type {number} */
  numberOfBookings;
}

export { Operator };
