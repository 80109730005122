import React from "react";
import { useSelector } from "react-redux";

const useDefaultLabelSettings = (screen, compareFormula, callback) => {
  const [settings, setSettings] = React.useState({});
  const { currentOperator } = useSelector(state => state.operator);

  const setDefaultSettings = selectedSettings => {
    if (selectedSettings && compareFormula) {
      setSettings(selectedSettings);
      if (typeof callback === "function") callback(selectedSettings);
    }
  };

  const setLabelSettingsForScreen = labels => {
    const iconObject = labels.find(label => label.screen === screen);
    setDefaultSettings(iconObject);
  };

  const init = () => {
    const { labels } = currentOperator;
    if (labels && labels.length) {
      setLabelSettingsForScreen(labels);
    }
  };

  React.useEffect(init, [currentOperator, screen]);
  return [settings, setSettings];
};

export { useDefaultLabelSettings };
