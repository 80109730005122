import React from "react";
import moment from "moment/min/moment-with-locales";
import { Wrap } from "./style";
import { getDateFormat } from "../../../../utils/getDateFormat";

const Toolbar = ({ date, setOpenView, openView }) => {
  return <Wrap onClick={() => setOpenView(openView === "year" ? "date" : "year")}>{moment(date).format(getDateFormat())}</Wrap>;
};

export default Toolbar;
