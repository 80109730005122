import styled from "styled-components";
import { colors } from "config/theme/colors";
import { getMediaPx } from "helpers";

export const TopLine = styled.div`
  display: grid;
  grid-template-columns: 1fr 161px;
  gap: 15px;
  align-items: center;
  padding-left: 15px;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    grid-template-columns: 1fr 65px;
    zoom: 0.93;
  }
`;

export const ExpandWrap = styled.div`
  @media (max-width: ${p => getMediaPx(p, "xs")}px) {
    zoom: 0.93;
  }
`;

export const PermissionsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 15px;
  margin-top: 25px;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }
`;

export const PermissionItem = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 15px;
  }
`;

export const PasscodeContainer = styled.div`
  position: relative;

  &:last-of-type {
    border-bottom: 1px solid ${p => (p.hasError ? colors.error : colors.grey10)} !important;
  }
`;

export const Passcode = styled.div`
  min-height: 80px;
  border: 1px solid ${p => (p.hasError ? colors.error : colors.grey10)};
  border-bottom: none;
  display: grid;
  grid-template-columns: 1fr 1fr 80px;
  gap: 15px;
  align-items: ${p => (p.hasError ? "flex-start" : "center")};
  padding-left: 15px;
  padding-top: ${p => p.hasError && "5px"};
  padding-bottom: ${p => p.hasError && "5px"};

  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    min-height: unset;
    grid-template-columns: 1fr;
    position: relative;
    padding: 10px 15px;
  }
`;
