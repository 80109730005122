export const REGISTER_USER_WITH_OPERATOR_START = "REGISTER_USER_WITH_OPERATOR_START";
export const REGISTER_USER_WITH_OPERATOR_SUCCESS = "REGISTER_USER_WITH_OPERATOR_SUCCESS";
export const REGISTER_USER_WITH_OPERATOR_FAIL = "REGISTER_USER_WITH_OPERATOR_FAIL";

export const REGISTER_USER_START = "REGISTER_USER_START";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAIL = "REGISTER_USER_FAIL";

export const VERIFY_SUCCESS_START = "VERIFY_SUCCESS_START";
export const VERIFY_SUCCESS_COMPLETE = "VERIFY_SUCCESS_COMPLETE";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";

export const GET_CURRENT_USER_START = "GET_CURRENT_USER_START";
export const GET_CURRENT_USER_SUCCESS = "GET_CURRENT_USER_SUCCESS";
export const GET_CURRENT_USER_FAIL = "GET_CURRENT_USER_FAIL";

export const EDIT_CURRENT_USER_START = "EDIT_CURRENT_USER_START";
export const EDIT_CURRENT_USER_SUCCESS = "EDIT_CURRENT_USER_SUCCESS";

export const SET_CURRENT_OPERATOR_CODE = "SET_CURRENT_OPERATOR_CODE";
export const RESET_USER_ACTION_TYPE = "RESET_USER_ACTION_TYPE";

export const SET_SINGLE_ERROR = "SET_SINGLE_ERROR";

export const UPDATE_OPERATORS = "UPDATE_OPERATORS";

export const SET_COMPOSER_DATA = "SET_COMPOSER_DATA";

export const SET_IS_INITIAL_AUTH = "SET_IS_INITIAL_AUTH";

export const REGISTER_OPERATOR_SUCCESS = "REGISTER_OPERATOR_SUCCESS";

export const SET_CREATED_VAMOOS_LIST = "SET_CREATED_VAMOOS_LIST";