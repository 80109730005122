import React from "react";
import { MobileItemTitle, MobileItemWrap } from "feature/panel/Itinerary/helpers";
import { Controller } from "react-hook-form";
import DeleteButton from "components/_new/DnD/DeleteIcon";
import Switch from "components/_new/Switch";

const MobileItem = ({ item, onDelete, user }) => {

  return (
    <MobileItemWrap>
      {user.email !== item.user.email && <DeleteButton onClick={onDelete ? () => onDelete({ user: item?.user }) : null} />}
      <MobileItemTitle>{item?.user.email}</MobileItemTitle>
      <div style={{ display: "flex", gap: 15 }}>
        <Switch checked={item.vamoos_permissions === "r" || item.vamoos_permissions === "cru"} label="Read" disabled />
        <Switch checked={item.vamoos_permissions === "w" || item.vamoos_permissions === "cru"} label="Write" disabled />
      </div>
    </MobileItemWrap>
  );
};

export default MobileItem;
