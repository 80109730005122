import { responsiveConfig } from "config/responsive";
import { colors } from "config/theme/colors";
import { getMediaPx } from "helpers";
import styled from "styled-components";

export const Wrap = styled.div`
  height: 56px;
  width: 100vw;
  display: none;
  padding: 0 15px;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${p => (Object.keys(p.errors).length ? colors.error : colors.grey10)};
  z-index: 11;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    display: flex;
    position: sticky;
    top: 120px;
    z-index: 10;
    background-color: #fff;
  }

  @media (max-width: ${p => getMediaPx(p, "xs")}px) {
    justify-content: space-between;
    * {
      zoom: 0.93;
    }
  }
`;

export const Overlay = styled.div`
  position: absolute;
  width: 100vw;
  top: 0;
  bottom: 0;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 9;
  backdrop-filter: blur(2px);
`;
