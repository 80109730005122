import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { Input } from "components/ui/Forms";

import { SortableListElement, RemoveButton } from "components/_shared/SortableListElement/SortableListElement";
import { SortableListElementContentWithError } from "components/_shared/SortableListElementContentWithError/SortableListElementContentWithError";

import { isObjectEmpty } from "utils/object";

import { ERRORS } from "constants/content";
import { StyledTextInput } from "feature/panel/_shared/Messaging/Components/SendMessageBar";
import { StyledTextarea } from "../Storyboard/Entries/EntryForm/EntryDetailsForm";

const PersonItem = ({ person, index, onPersonChange, onPersonDelete, errors, disabled, people }) => {
  const personRef = useRef(null);
  const { internal_id, name, email } = person;
  const [error, setError] = useState({});
  const [isInputActive, setIsInputActive] = useState(false);

  // set errorMessages for the input fields
  useEffect(() => {
    if (!errors) {
      setError({});
      return;
    }

    let errorForThisPerson = errors.validationErrors?.find(e => e?.traveller?.name === name);
    const isMultipleNames = people.filter(p => p.name === name).length > 1;
    // if name not unique portal display two different entries
    if (isMultipleNames) errorForThisPerson = errors.validationErrors?.find(e => e?.traveller?.internal_id === internal_id);

    if (errorForThisPerson) {
          let { isEmailValid, emailValid, isEmailUnique } = errorForThisPerson.email;
          const { isMultipleEmail } = errorForThisPerson.email;
          const { isNameValid, nameLengthValid, isNameUnique } = errorForThisPerson.name;

          // to set correct error for the traveller with multiple emails
          if (isMultipleEmail) {
            const emails = email?.split(/\s*,\s*/);
            errors.validationErrors.forEach(error => {
              const matchingEmail = emails?.includes(error.traveller.email);
              if (matchingEmail) {
                emailValid = emailValid && error.email.emailValid;
                isEmailUnique = isEmailUnique && error.email.isEmailUnique;
                isEmailValid = isEmailValid && error.email.isEmailValid;
              }
            });
          }

          const nameError = !isNameValid
            ? !isNameUnique
              ? ERRORS.notUniqueFullName
              : nameLengthValid
              ? ERRORS.emptyFullName
              : ERRORS.fullNameOverMaxCharacters
            : String.fromCharCode(160);
          const emailError = !isEmailValid
            ? emailValid
              ? ERRORS.notUniqueEmail
              : isMultipleEmail
              ? ERRORS.someInvalidEmail
              : ERRORS.invalidEmail
            : String.fromCharCode(160);
          // code 160 === &nbsp;

          const errorMessages = {
            nameError,
            emailError,
          };
          setError(errorMessages);
    } else {
      setError({});
    }
  }, [errors]);

  const handleChangePerson = ({ target }) => {
    const { name, value } = target;
    const newPerson = { ...person, [name]: value };
    onPersonChange(newPerson);
  };

  const handleDeletePerson = () => {
    onPersonDelete(internal_id);
  };

  useEffect(() => {
    if (!personRef.current.value) {
      personRef.current.focus();
      personRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  // delete prop withError to hide the red background on SortableListElement in case of an error
  return (
    <SortableListElement disabled={disabled} index={index} key={internal_id}>
      <SortableListElementContentWithError error={!isInputActive && !isObjectEmpty(error) ? ERRORS.invalidDataOnly : undefined}>
        {/* <Input
          label="Full name"
          type="text"
          value={name}
          onChange={handleChangePerson}
          name={`name-${id}`}
          size="small"
          inputRef={personRef}
          error={checkErrorExistFor("name", error)}
          helperText={error.name}
          disabled={disabled}
        /> */}
        <StyledTextarea
          label="Full name"
          name="name"
          value={name}
          nextInputRef={personRef}
          onChange={handleChangePerson}
          errorMessages={error?.nameError}
          isInputActive={isInputActive}
          setIsInputActive={setIsInputActive}
        />
        <StyledTextarea
          label="Email"
          name="email"
          value={email}
          onChange={handleChangePerson}
          errorMessages={error?.emailError}
          isInputActive={isInputActive}
          setIsInputActive={setIsInputActive}
        />
        {!disabled && <RemoveButton onClick={handleDeletePerson} />}
      </SortableListElementContentWithError>
    </SortableListElement>
  );
};

PersonItem.propTypes = {
  person: PropTypes.shape({
    internal_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  onPersonChange: PropTypes.func.isRequired,
  onPersonDelete: PropTypes.func.isRequired,
  errors: PropTypes.shape().isRequired,
  disabled: PropTypes.bool.isRequired,
  people: PropTypes.arrayOf(PropTypes.object).isRequired
};

export { PersonItem };
