import React, { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";

import { CustomSelect } from "feature/panel/_shared/Notifications/styledComponents";
import { coordinatesValidator } from "feature/panel/_shared/Notifications/GpsNotificationForm/coordinatesValidator";

import { Input, Textarea } from "components/ui/Forms";
import { DatePicker } from "components/ui/Forms/DatePickers";
import { Content } from "components/ui/Content";
import { ContentArea } from "components/templates/_shared/TopBar/TopBar";
import { Message } from "components/ui/Messages";
import { GLOBAL_CONTENT, NOTIFICATIONS_LABELS, ERRORS } from "constants/content";
import { useManageTripContext } from "feature/panel/Trips/_shared/ManageTripContext";
import { getContent } from "../helpers";

const NotificationForm = ({ item, index, context, disabled, allNotifications }) => {
  const [coordinates, setCoordinates] = useState("");
  const [coordinatesText, setCoordinatesText] = useState({ text: "", error: false });
  const [isNotificationsLoaded, setIsNotificationsLoaded] = useState(false);
  const [initNotification, setInitNotification] = useState(false);
  const { template_id, location_id, location_internal_id, url, content, start_at, end_at, id } = item;
  const { notifications, locations, setValueFor, type, errors } = useContext(context);
  const { language } = useManageTripContext();
  const currentTemplate = allNotifications?.find(item => item.id === template_id);

  const setNotificationWithLocation = (notifiacation, locationId) => {
    return { ...notifiacation, location_id: locationId };
  };

  const setNotificationWithNewLocation = (notifiacation, locationId) => {
    return { ...notifiacation, location_internal_id: locationId };
  };

  const setNotificationWithCoordinates = (notifiacation, value) => {
    const { latitude: lat, longitude: long, text, error } = coordinatesValidator(value);
    setCoordinatesText({ text: value ? text : "", error });

    if (value !== "") {
      return {
        ...notifiacation,
        latitude: lat,
        longitude: long,
      };
    }
    return notifiacation;
  };

  const clearCoordinatesFromNotification = ({ longitude: _, latitude: __, ...notifiaction }) => notifiaction;
  const clearLocationFromNotification = ({ location_internal_id: _, ...notifiaction }) => notifiaction;

  const setNewNotificationData = (notification, attribute, value) => {
    if (attribute === "location_id" || attribute === "coordinates") {
      let newNotification;
      newNotification = clearCoordinatesFromNotification(notification);
      newNotification = clearLocationFromNotification(newNotification);

      if (attribute === "location_id") {
        const isNewLocation = locations.filter(({ internal_id }) => internal_id && internal_id === value).length === 1;
        if (value === 0) return newNotification;
        if (isNewLocation) return setNotificationWithNewLocation(newNotification, value);
        return setNotificationWithLocation(newNotification, value);
      }

      if (attribute === "coordinates") {
        return setNotificationWithCoordinates(newNotification, value);
      }
    }

    return { ...notification, [attribute]: value };
  };

  const changeNotificationValue = (attributeName, value) => {
    const newList = notifications.map(notification => {
      if (notification.id && notification.id === id) {
        return setNewNotificationData(notification, attributeName, value);
      }
      if (notification.template_id && notification.template_id === template_id) {
        return setNewNotificationData(notification, attributeName, value);
      }
      return notification;
    });

    if (initNotification) {
      setValueFor("notifications", newList);
    }
  };

  const handleInputChange = ({ target }) => changeNotificationValue(target.name, target.value);
  const calculateDateValue = value => (value && value.isValid() ? value.toISOString() : null);
  const handleActiveToDateChange = value => changeNotificationValue("end_at", calculateDateValue(value));
  const handleActiveFromDateChange = value => changeNotificationValue("start_at", calculateDateValue(value));

  const handleOutdatedActiveToCheck = () => {
    const fromDate = moment(start_at);
    const toDate = moment(end_at);
    if (fromDate && toDate && fromDate.isAfter(toDate)) {
      handleActiveToDateChange(fromDate.clone());
    }
  };

  const handleOutdatedActiveFromCheck = () => {
    const fromDate = moment(start_at);
    const toDate = moment(end_at);
    if (fromDate && toDate && fromDate.isAfter(toDate)) {
      handleActiveFromDateChange(toDate.clone());
    }
  };
  const refreshDateToAfterDateFromChange = () => {
    const dateTo = moment(start_at).add(1, "day");

    handleActiveToDateChange(dateTo);
  };

  const initForm = () => {
    setInitNotification(true);
  };

  useEffect(refreshDateToAfterDateFromChange, [start_at]);
  useEffect(initForm, []);

  const locationsList = useMemo(
    () =>
      locations.length ? (
        locations.map(locationOption => (
          <MenuItem
            key={locationOption.id}
            value={locationOption.id}
            selected={locationOption.id === location_internal_id || locationOption.id === location_id}
          >
            {locationOption.name}
          </MenuItem>
        ))
      ) : (
        <MenuItem disabled>No locations available</MenuItem>
      ),
    [locations, location_id, location_internal_id],
  );

  const locationsInput = type !== "stay" && (
    <Grid item xs={6}>
      <CustomSelect
        size="small"
        label={NOTIFICATIONS_LABELS.location}
        value={location_internal_id || location_id || 0}
        disabled={!!coordinates || disabled}
        onChange={handleInputChange}
        name="location_id"
        id={`location-nr${index}`}
        helperText={errors?.gpsNotifications?.[template_id]?.coordinates}
        error={errors?.gpsNotifications?.[template_id]}
      >
        <MenuItem value={0}>
          <em>{GLOBAL_CONTENT.none}</em>
        </MenuItem>
        {locationsList}
      </CustomSelect>
    </Grid>
  );

  const handleCoordinatesChange = ({ target }) => {
    const { value } = target;
    setCoordinates(value);
    changeNotificationValue("coordinates", value);
  };

  const tryCoordinatesAutocomplete = () => {
    if (item.latitude && item.longitude) {
      handleCoordinatesChange({ target: { value: `${item.latitude}, ${item.longitude}` } });
    }
  };

  const contentValue = getContent(currentTemplate, content, language);

  useEffect(tryCoordinatesAutocomplete, []);

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} lg={6}>
        <Textarea
          rows={6}
          label={NOTIFICATIONS_LABELS.notificationText}
          id={`content-nr${index}`}
          name="content"
          value={contentValue || ""}
          onChange={handleInputChange}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Content margin={6}>
          <Input
            id={`notification-url-nr${index}`}
            name="url"
            size="small"
            label={NOTIFICATIONS_LABELS.notificationUrl}
            value={url}
            onChange={handleInputChange}
            fullWidth
            disabled={disabled}
          />
        </Content>
        <Grid container alignContent="space-between" spacing={6}>
          <Grid item xs={6}>
            <DatePicker
              label={NOTIFICATIONS_LABELS.activeFrom}
              onChange={handleActiveFromDateChange}
              value={moment(start_at)}
              size="small"
              fullWidth
              onBlur={handleOutdatedActiveToCheck}
              autoOk
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              label={NOTIFICATIONS_LABELS.activeTo}
              onChange={handleActiveToDateChange}
              value={moment(end_at)}
              minDate={moment(start_at)}
              onBlur={handleOutdatedActiveFromCheck}
              size="small"
              fullWidth
              autoOk
              disabled={disabled}
            />
          </Grid>
        </Grid>
        <Grid container alignContent="space-between" spacing={6}>
          {locationsInput}
          <Grid item xs={6}>
            <Input
              size="small"
              label={NOTIFICATIONS_LABELS.coords}
              disabled={!!location_internal_id || disabled}
              name={`coordinates-nr${index}`}
              value={coordinates}
              onChange={handleCoordinatesChange}
              helperText={coordinatesText.text || errors?.gpsNotifications?.[template_id]?.coordinates}
              error={coordinatesText.error || errors?.gpsNotifications?.[template_id]}
            />
          </Grid>
        </Grid>
      </Grid>
      {errors?.gpsNotifications?.[template_id] && (
        <ContentArea hasError>
          <Message type="error" text={ERRORS.gpsNotificationError} />
        </ContentArea>
      )}
    </Grid>
  );
};

NotificationForm.defaultProps = {
  disabled: false,
};

NotificationForm.propTypes = {
  item: PropTypes.shape().isRequired,
  context: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
};

export { NotificationForm };
