import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";

const cutText = (text, maxChar, isModified) => {
  const dotsLength = 3;
  const modifiedLength = "(modified)".length + 1;
  const textLength = isModified ? text.length + modifiedLength : text.length;
  return textLength > maxChar && textLength > dotsLength ? `${text.substring(0, isModified ? maxChar - (dotsLength + modifiedLength): maxChar - dotsLength)}...` : text;
};

const cutTextInTheMiddle = (text, maxChar) => {
  if (typeof text === "string" && text.length > maxChar) {
    const textPartLength = Math.round(maxChar / 2);
    const startText = text.substring(0, textPartLength);
    const endText = text.substring(text.length - (textPartLength - 3), text.length);
    return `${startText}...${endText}`;
  }

  return text;
};

const TextLimiter = ({ text, maxChar, dotsPosition, isModified, wrap }) =>
  typeof text === "string" && (
    <Tooltip title={text}>
      <span style={{ textWrap: wrap ? "wrap" : "nowrap" }}>
        {isModified && <span style={{ color: "#babcbf", textWrap: "nowrap" }}>(modified) </span>}
        {dotsPosition === "end" && cutText(text, maxChar, isModified)}
        {dotsPosition === "middle" && cutTextInTheMiddle(text, maxChar)}
      </span>
    </Tooltip>
  );

TextLimiter.defaultProps = {
  dotsPosition: "end",
  text: null,
};

TextLimiter.propTypes = {
  text: PropTypes.string,
  maxChar: PropTypes.number.isRequired,
  dotsPosition: PropTypes.oneOf(["end", "middle"]),
};

export { TextLimiter, cutText, cutTextInTheMiddle };
