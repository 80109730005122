import { responsiveConfig } from "config/responsive";
import { colors } from "config/theme/colors";
import { getMediaPx } from "helpers";
import styled from "styled-components";

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  margin-bottom: 25px;
`;

export const ItemGrid = styled.div`
  display: grid;
  grid-template-columns: ${p => (p.isNested ? "30px" : "20px")} 1fr 1fr 1fr minmax(320px, auto);
  gap: 15px;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 0;
  border-radius: 8px;
  @media (max-width: ${p => getMediaPx(p, "lg")}px) {
    grid-template-columns: 1fr;
    padding: 15px;
  }
`;

export const IconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  svg {
    color: #bdbdbd;
  }
  @media (max-width: ${p => getMediaPx(p, "lg")}px) {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: ${colors.redLight};
    width: 32px;
    height: 32px;
    border-radius: 100%;
    svg {
      color: #eb5757;
    }
  }
`;

export const ButtonsWrap = styled.div`
  display: grid;
  grid-template-columns: 80px 80px minmax(80px, auto) 80px;
`;

export const CheckboxesWrap = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const NumberS = styled.div`
  @media (max-width: 1400px) {
    display: none;
  }
`;

export const ToggleButton = styled.div`
  display: none;
  font-size: 14px;
  color: ${colors.brand};
  @media (max-width: ${p => getMediaPx(p, "lg")}px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Dropdown = styled.div`
  display: contents;
  @media (max-width: ${p => getMediaPx(p, "lg")}px) {
    display: ${({ isOpen }) => (isOpen ? "contents" : "none")};
  }
`;

export const FileActionWrap = styled.div`
  position: relative;
  width: 100%;
  #close {
    position: absolute;
    top: 0;
    right: 0;
    width: 25px;
    height: 25px;
    z-index: 3;
    background-color: ${colors.withOpacity(colors.error, 0.3)};
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    border-bottom-left-radius: 10px;
  }
  &:hover {
    #close {
      opacity: 1;
    }
  }

  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    width: 100%;
    #close {
      width: 35px;
      height: 35px;
      opacity: 1;
    }
  }
`;
