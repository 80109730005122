// @ts-check

/**
 * @param {import("types/dto").ItineraryLocationResponseDto} locationDto  feature stay object
 * @returns {ItineraryLocation} Feature stay item
 */

export function LocationItnierary(locationDto) {
    /**
     * @type {ItineraryLocation}
     */
    const domain = {};
  
    domain.countryIso = locationDto.country_iso; 
    domain.createdAt = locationDto.created_at; 
    domain.id = locationDto.id; 
    domain.itineraryId = locationDto.itinerary_id; 
    domain.latitude = locationDto.latitude; 
    domain.locPosition = locationDto.loc_position; 
    domain.longitude = locationDto.longitude; 
    domain.meta = locationDto.meta;  
    domain.name = locationDto.name;  
    domain.onMaps = locationDto.on_maps;  
    domain.onWeather = locationDto.on_weather;  
    domain.operatorId = locationDto.operator_id;  
    domain.updatedAt = locationDto.updated_at;  
    domain.location = locationDto.location;  
    domain.position = locationDto.position;  
    domain.country = locationDto.country;  
    domain.description = locationDto.description;  
    domain.iconId = locationDto.icon_id;  
    domain.libraryNode = locationDto.library_node;  
    domain.icon = locationDto.icon;  
    domain.timezone = locationDto.timezone;  
    domain.nested = locationDto.nested;  
  
    return domain;
  }
  