import React from "react";
import { Wrap } from "./style";

const DnDHeader = ({ content, wrapStyle }) => {
  return (
    <Wrap style={wrapStyle}>
      {content || (
        <>
          <div>Title</div>
          <div>Days</div>
        </>
      )}
    </Wrap>
  );
};

export default DnDHeader;
