import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import moment from "moment";

import { SharedNavigation } from "feature/panel/_shared/Navigations/SharedNavigation";
import { Actions } from "feature/panel/Notifications/Actions/Actions";
import { NotificationsList } from "feature/panel/Notifications/NotificationsList";
import { localisedNotificationFormValidator } from "feature/panel/Notifications/notificationFormValidator";

import { PanelTemplate } from "components/templates/Panel/PanelTemplate";
import { Content } from "components/ui/Content";
import { Tabs } from "components/ui/Tabs/Tabs";
import { Tab } from "components/ui/Tabs/Tab";

import { CHANGES_SAVED_MESSAGE, NAMES_OF_RESOURCES_LISTS, NOTIFICATION_TYPES } from "constants/content";
import { isObjectEmpty } from "utils/object";
import { NotificationsService } from "services/NotificationsService";
import { setNotification } from "store/app/actions";
import { setDefaultApiErrorMessage } from "utils";
import { useQueryClient } from "@tanstack/react-query";
// eslint-disable-next-line import/no-named-as-default
import NotificationModal from "../Itinerary/pages/Trips/Notifications/NotificationModal";
import { getDefaultNotificationProperties, noneItem } from "../Itinerary/pages/Trips/Notifications/helpers";

const tabs = [
  {
    type: NOTIFICATION_TYPES.timed,
    label: NAMES_OF_RESOURCES_LISTS.notifications.timed,
  },
  {
    type: NOTIFICATION_TYPES.gps,
    label: NAMES_OF_RESOURCES_LISTS.notifications.gps,
  },
  {
    type: NOTIFICATION_TYPES.forced,
    label: NAMES_OF_RESOURCES_LISTS.notifications.forced,
  },
];

const Notifications = ({ hasPermission }) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  /** @type {NotificationsService} */
  const notificationService = new NotificationsService();

  const [currentTab, setCurrentTab] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [notificationToModify, setNotificationToModify] = useState({});
  const [errors, setErrors] = useState({});
  const [skipValidation, setSkipValidation] = useState(false);
  const isEditMode = !!notificationToModify.id;

  const listRef = useRef();
  const handleModalClose = () => {
    setNotificationToModify({});
    setErrors({});
    setSkipValidation(false);
  };

  const handleOpenCreationModal = type => {
    setNotificationToModify({
      content: "",
      type,
      ...(type !== "forced"
        ? {
            ...getDefaultNotificationProperties(moment().format("YYYY-MM-DD"), true)[type],
            is_default_on: false,
            url: "",
          }
        : {}),
    });
  };

  const handleUpdateNotificationValue = (name, value, language) => {

    if (language.isDefault) {
      setNotificationToModify({
        ...notificationToModify,
        [name]: value,
      });
    } else {
      setNotificationToModify({
        ...notificationToModify,
        localisation: {
          ...notificationToModify.localisation,
          [language.code]: {
            ...notificationToModify.localisation?.[language.code],
            [name]: value,
          },
        },
      });
    }
  };

  const handleCreateNotification = async data => {
    try {
      setLoading(true);

      await notificationService.createNotification(data);
      await queryClient.invalidateQueries({ queryKey: [`${tabs[currentTab].type}Notifications`], refetchType: "all" });
      setLoading(false);
      handleModalClose();
      await dispatch(setNotification({ type: "success", message: "Notification added successfully" }));
      if (data.type === tabs[currentTab]?.type) {
        listRef.current.refresh();
      }
    } catch (error) {
      setLoading(false);
      await dispatch(setNotification({ type: "error", message: setDefaultApiErrorMessage(error) }));
    }
  };
  const handleEditNotification = async data => {
    try {
      setLoading(true);
      await notificationService.updateNotification(data);
      await queryClient.invalidateQueries({ queryKey: [`${tabs[currentTab].type}Notifications`], refetchType: "all" });
      setLoading(false);
      handleModalClose();
      await dispatch(setNotification({ type: "success", message: CHANGES_SAVED_MESSAGE }));
      if (data.type === tabs[currentTab]?.type) {
        listRef.current.refresh();
      }
    } catch (error) {
      setLoading(false);
      await dispatch(setNotification({ type: "error", message: setDefaultApiErrorMessage(error) }));
    }
  };

  const handleOpenEditionModal = (notification, onEditClick) => {
    const updatedNotification = {
      ...notification,
      ...(notification.type !== "forced"
        ? {
            is_default_on: onEditClick ? notification.is_default_on ?? false : !notification.is_default_on ?? true,
          }
        : {}),
    };
    setNotificationToModify(updatedNotification);
    if (onEditClick && updatedNotification.type !== "forced")
      setSkipValidation(!updatedNotification.is_default_on && !updatedNotification.start_at);
  };

  const handleFormConfirm = data => {
    setSkipValidation(false);
    const errorsList = localisedNotificationFormValidator(data);
    if (!isObjectEmpty(errorsList)) {
      setErrors(errorsList);
    } else if (isEditMode) {
      handleEditNotification(data);
    } else {
      handleCreateNotification(data);
    }
  };

  const contextBar = {
    left: () => null,
    middle: SharedNavigation,
    right: () => <Actions onModalPick={handleOpenCreationModal} />,
  };

  return (
    <PanelTemplate hasPermission={hasPermission} languageSelector contextBar={contextBar}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={12}>
          <Content margin={10}>
            <Tabs value={currentTab} onChange={(event, newValue) => setCurrentTab(newValue)}>
              {tabs.map(tab => (
                <Tab key={tab.type} label={tab.label} />
              ))}
            </Tabs>
          </Content>
          <NotificationsList
            updateDefaultActiveStatus={handleEditNotification}
            onEditClick={handleOpenEditionModal}
            activeType={tabs[currentTab].type}
          />
          {!!notificationToModify.type && (
            <NotificationModal
              type={notificationToModify.type}
              onClose={handleModalClose}
              onNotificationAdd={handleFormConfirm}
              modalTitle={`${isEditMode ? "Edit" : "New"} ${
                notificationToModify.type === "gps" ? notificationToModify.type.toUpperCase() : notificationToModify.type
              } notification`}
              isLoading={isLoading}
              confirmLabel={isEditMode ? "Update" : "Add"}
              notification={notificationToModify}
              editMode={isEditMode}
              locations={[noneItem]}
              displayMode="template"
              setNotificationToModify={setNotificationToModify}
              skipValidation={skipValidation}
              setSkipValidation={setSkipValidation}
            />
          )}
        </Grid>
      </Grid>
    </PanelTemplate>
  );
};

Notifications.defaultProps = {
  hasPermission: true,
};

Notifications.propTypes = {
  hasPermission: PropTypes.bool,
};

export { Notifications };
