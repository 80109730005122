import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import _ from "lodash";
import BaseModal from "components/_new/ModalBase";
import Input from "components/ui/Inputs/TextInput";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import TreeList from "components/_new/TreeList";
import Accordion from "components/_new/Accordion";
import FolderIcon from "@mui/icons-material/Folder";
import { INPUT_BORDER_COLOR, INPUT_BORDER_RADIUS } from "components/ui/Inputs/config";
import { colors } from "config/theme/colors";
import { LIBRARY_ROOT_PATH } from "../../../../../../../constants/api";

const AddToLibraryModal = ({ onConfirm, onCancel, isLoading }) => {
  const [expanded, setExpanded] = useState(false);
  const [folders, setFolders] = useState({});
  const [defaultExpandedItems, setDefaultExpandedItems] = useState(null);

  const { control, watch, handleSubmit, setValue, reset } = useForm();

  const { data } = useQuery({
    queryKey: ["/file/folders?count=5000&page=1"],
    refetchOnMount: true,
    cacheTime: 0,
    onSuccess: data => {
      setFolders(data?.items);

      // check that addToLibraryPath (e.g. /library/nested1/nested2) exists in the library folders
      const addToLibraryPath = localStorage.getItem("addToLibraryPath");
      const modifiedPath = addToLibraryPath?.replace(/\//g, ".")?.slice(1);
      const folderExist = _.get(data?.items, modifiedPath) !== undefined;

      if (!addToLibraryPath || !folderExist) {
        localStorage.removeItem("addToLibraryPath");
        setDefaultExpandedItems([]);
        return;
      }

      const parts = addToLibraryPath.split("/").filter(part => part !== "");
      const convertedPathIntoExpandedItems = [`/${parts[0]}`];
      for (let i = 1; i < parts.length; i++) {
        convertedPathIntoExpandedItems.push(parts.slice(0, i + 1).join("/"));
      }
      setDefaultExpandedItems(convertedPathIntoExpandedItems);
      setExpanded(true);
    },
  });

  const renderItem = (item, prev) => {
    const label = item[0];
    if (Object.keys(item[1]).length) {
      return (
        <TreeItem itemId={(prev || "") + "/" + item[0]} label={label}>
          {Object.entries(item[1]).map(child => renderItem(child, (prev ? prev + "/" : "") + item[0]))}
        </TreeItem>
      );
    } else {
      return (
        <TreeItem
          itemId={(prev || "") + "/" + item[0]}
          label={
            <div style={{ display: "flex", alignItems: "center" }}>
              <FolderIcon style={{ color: colors.grey40, fontSize: 18, marginRight: 10 }} />
              {label}
            </div>
          }
        />
      );
    }
  };

  const handleSetFolder = (_, itemId) => {
    setValue("path", itemId === LIBRARY_ROOT_PATH ? itemId : "/" + itemId);
  };
  const toggleExpand = () => setExpanded(!expanded);

  const onSubmit = data => {
    const { path, name } = data;
    localStorage.setItem("addToLibraryPath", path);
    onConfirm({ path, name }, false);
  };

  useEffect(() => {
    reset({ path: LIBRARY_ROOT_PATH });
  }, []);

  useEffect(() => () => setDefaultExpandedItems(null), []);

  return (
    <BaseModal
      title="Add to library"
      confirmTitle="Add"
      onConfirm={handleSubmit(onSubmit)}
      onCancel={onCancel}
      modalStyle={{ width: 600, overflow: "visible" }}
      contentStyle={{ overflowY: "scroll" }}
      mobileFullScreen
      isLoading={isLoading}
    >
      <Input autoFocus name="name" label="Name" control={control} style={{ marginTop: 25 }} rules={{ required: true }} />
      <Accordion
        onChange={toggleExpand}
        expanded={expanded}
        title={
          watch("path")
            ?.split("/")
            .join(" > ")
            .substring(3) || "Choose folder"
        }
        styleContainer={{ marginTop: 25, border: `1px solid ${INPUT_BORDER_COLOR}`, borderRadius: INPUT_BORDER_RADIUS }}
        styleSummary={{ borderRadius: INPUT_BORDER_RADIUS }}
        inputLikeLabel="Folder"
      >
        {Object.keys(folders)?.length > 0 && defaultExpandedItems && (
          <TreeList onItemFocus={handleSetFolder} type="addToLobrary" defaultExpandedItems={defaultExpandedItems} setExpanded={setExpanded}>
            {Object.entries(folders).map(item => renderItem(item))}
          </TreeList>
        )}
      </Accordion>
    </BaseModal>
  );
};

export default AddToLibraryModal;
