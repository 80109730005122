import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import moment from "moment/moment";

import AddIcon from "@material-ui/icons/Add";
import Table from "components/_new/Table";
import { Message, NoResultsMessage } from "../../../../../../components/ui/Messages";

import { generateColumns } from "./helpers";
import { useTableHandlers } from "../../../../../../hooks/useTableHandlers";
import { CustomTableRow } from "./CustomTableRow";
import { useEffectDependenciesOnly } from "../../../../../../hooks/useEffectDependenciesOnly";
import MobileItem from "./MobileItem";
import TitleRow from "../../../components/TitleRow";
import OutlinedButton from "../../../../../../components/_new/Buttons/OutlinedButton";
import { CREATE_ONE_LABEL, NO_TEMPLATE_TEXT } from "../../../../../../constants/content";
import { StyledLink } from "../../Stays/POIs/style";
import { PANEL_NOTIFICATIONS_PATH } from "../../../../../../constants/routes";

const NotificationTable = forwardRef(
  (
    {
      isGlobal,
      tripNotifications,
      sharedNotifications,
      updateNotifications,
      setShowModal,
      setEditMode,
      setNotificationToModify,
      remove,
      showModal,
      departure_date,
      return_date,
      locations,
      tab,
      clickedRow,
      setClickedRow,
      onRowClick,
      onCreate,
      itineraryType,
      canEdit,
      isWiped,
    },
    ref,
  ) => {
    const tableHandles = useTableHandlers();

    useEffectDependenciesOnly(() => {
      setTimeout(() => {
        setClickedRow(null);
      }, 2000);
    }, [clickedRow]);

    const getNotifications = isGlobal => {
      const tripNotificationsByCurrentType = tripNotifications.filter(notification => notification.type === tab);

      const sortFunc = (a, b) => {
        if (!a.isOverridden && b.isOverridden) return -1;
        if (a.isOverridden && !b.isOverridden) return 1;

        if (a.is_active && !b.is_active) return -1;
        if (!a.is_active && b.is_active) return 1;

        return 0;
      };

      return isGlobal
        ? sharedNotifications
            .map(sharedNotification => ({
              ...sharedNotification,
              isOverridden: Boolean(
                tripNotificationsByCurrentType.find(notification => notification.template_id === sharedNotification.id),
              ),
            }))
            .sort(sortFunc)
        : tripNotificationsByCurrentType.sort(sortFunc);
    };
    const notifications = getNotifications(isGlobal);

    const isGlobalStatus = isGlobal ? "Shared notification settings" : `${itineraryType.charAt(0).toUpperCase() + itineraryType.slice(1)} specific settings`;
    const startIndex = (tableHandles.page - 1) * tableHandles.rowsPerPage;
    const notificationRows = notifications.slice(startIndex, startIndex + tableHandles.rowsPerPage);

    const toggleIsActiveStatus = notification => {
      notification.is_active = !notification.is_active;
      updateNotifications(notification);
    };

    const onDelete = notification => {
      const indexToRemove = tripNotifications.findIndex(tn => tn.id === notification.id);
      remove(indexToRemove);
    };

    const onEdit = (notification, isCopyMode) => {
      const updatedNotification = notification;
      if (isCopyMode) {
        updatedNotification.isCopyMode = true;
        if (updatedNotification.type === "gps") {
          updatedNotification.start_at = updatedNotification.start_at ?? departure_date;
          updatedNotification.end_at =
            updatedNotification.end_at ??
            moment(departure_date)
              .add(1, "day")
              .toISOString();
        }
      }

      setShowModal(!showModal);
      setEditMode(true);
      setNotificationToModify(updatedNotification);
    };

    const commonColumnProps = {
      handleSwitchAction: notification => toggleIsActiveStatus(notification),
      handleDeleteAction: notification => onDelete(notification),
      handleEditAction: (notification, isCopyMode) => onEdit(notification, isCopyMode),
      departure_date,
      return_date,
      locations,
      itineraryType,
      displayMode: "trip",
      disabledActionButtons: !canEdit || isWiped,
    };

    const columns = generateColumns(tab, commonColumnProps);

    return (
      <div>
        <TitleRow text={isGlobalStatus} isSticky offsetTabs extraTabPadding ref={isGlobal ? null : ref} disabled={!canEdit || isWiped}>
          {!isGlobal ? <OutlinedButton text="Add" startIcon={<AddIcon />} onClick={onCreate} style={{ maxWidth: 400 }} /> : null}
        </TitleRow>
        <div style={{ paddingTop: 20, paddingBottom: 20 }}>
          {notifications.length === 0 ? (
            isGlobal ? (
              <Message type="info" text={NO_TEMPLATE_TEXT(tab)}>
                <StyledLink to={PANEL_NOTIFICATIONS_PATH}>{CREATE_ONE_LABEL}</StyledLink>
              </Message>
            ) : (
              <NoResultsMessage style={{ height: "100%", paddingTop: "16px" }}>{`No ${isGlobalStatus.toLowerCase()} `}</NoResultsMessage>
            )
          ) : (
            <Table
              renderRow={<CustomTableRow clickedRow={clickedRow} onClick={onRowClick} columns={columns} />}
              columns={columns}
              rows={isGlobal ? notificationRows : notifications}
              {...tableHandles}
              {...(isGlobal && { totalMatches: notifications?.length })}
              renderRowMobile={
                <MobileItem
                  type={tab}
                  clickedRow={clickedRow}
                  onRowClick={onRowClick}
                  disabledActionButtons={!canEdit || isWiped}
                  {...{
                    handleSwitchAction: notification => toggleIsActiveStatus(notification),
                    handleDeleteAction: notification => onDelete(notification),
                    handleEditAction: (notification, isCopyMode) => onEdit(notification, isCopyMode),
                    departure_date,
                    return_date,
                    locations,
                    itineraryType,
                  }}
                />
              }
            />
          )}
        </div>
      </div>
    );
  },
);

NotificationTable.defaultProps = {
  clickedRow: null,
};

NotificationTable.propTypes = {
  isGlobal: PropTypes.bool.isRequired,
  tripNotifications: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  sharedNotifications: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  updateNotifications: PropTypes.func.isRequired,
  setShowModal: PropTypes.func.isRequired,
  setEditMode: PropTypes.func.isRequired,
  setNotificationToModify: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  departure_date: PropTypes.string.isRequired,
  return_date: PropTypes.string.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  tab: PropTypes.string.isRequired,
  clickedRow: PropTypes.number,
  onRowClick: PropTypes.func.isRequired,
  setClickedRow: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
};

export default NotificationTable;
