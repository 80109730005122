import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import moment from "moment";

import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";

import { Content } from "components/ui/Content/Content";
import { BodyText, BodyText2, CaptionText, LeadText } from "components/ui/Typography/Typography";
import { Select, Textarea, TimePicker } from "components/ui/Forms";
import { StyledWrapper } from "components/ui/Forms/FormSection";

import { getInspirationsStart } from "store/inspirations/actions";

import { setUrlParams } from "utils/url";

import { INSPIRATIONS_GET_URL } from "constants/api";
import { GLOBAL_CONTENT, INSPIRATIONS, NOTIFICATION_TYPES, NO_INSPIRATIONS_MESSAGE, PANEL_TRIPS_CREATE_TEXTS } from "constants/content";
import { DEFAULT_PAGE_SIZE } from "constants/defaults";
import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";
import { DaySelector } from "components/ui/Forms/DaySelector";
import { Message } from "components/ui/Messages";
import {
  API_NOTIFICATION_FIELDS,
  DISPLAYABLE_STARTING_POINT_OPTIONS,
  calculateSelectedDayValue,
  calculateStartingPointChange,
  calculateStartingPointValue,
  calculateNotificationDate,
} from "utils/notifications";
import config from "config/app";
import { StrongLabel } from "feature/panel/_shared/Notifications/TimedNotificationForm/NotificationItem";

const AtWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NotificationSettingsWrapper = styled.div`
  label {
    font-size: 12px !important;
  }
`;

const ItineraryInspirations = ({ contextObject, context, disabled }) => {
  const dispatch = useDispatch();

  const { setValueFor, notifications, inspiration_vamoos_id, departure_date, return_date, errors } = contextObject();

  const [inspirationId, setInspirationId] = useState("");
  const [isInspirationInitalised, setIsInspirationInitialised] = useState(false);

  const { listOfInspirations, inProgress } = useSelector(state => state.inspirations);

  const inspirationNotification = notifications.find(({ type }) => type === NOTIFICATION_TYPES.inspiration);
  const delivery_at_days = inspirationNotification?.delivery_at_days || 0;
  const start_at = inspirationNotification?.start_at;
  const delivery_at_relative_to = inspirationNotification?.delivery_at_relative_to || null;
  const content = inspirationNotification?.content || "";
  const startingPointValue = calculateStartingPointValue(delivery_at_relative_to, delivery_at_days);

  const notificationDate = calculateNotificationDate({
    delivery_at_days,
    delivery_at_relative_to,
    departure_date,
    return_date,
  });

  const shouldShowNotificationDate = inspirationId && startingPointValue !== DISPLAYABLE_STARTING_POINT_OPTIONS.disabled.value;

  const shouldShowDaySelector =
    startingPointValue &&
    startingPointValue !== DISPLAYABLE_STARTING_POINT_OPTIONS.startDay.value &&
    startingPointValue !== DISPLAYABLE_STARTING_POINT_OPTIONS.endDay.value &&
    startingPointValue !== DISPLAYABLE_STARTING_POINT_OPTIONS.disabled.value;

  const changeWholeInspiration = (newInspiration, formTouched = true) => {
    const newList = notifications.map(notification =>
      notification.type === NOTIFICATION_TYPES.inspiration ? newInspiration : notification,
    );

    setValueFor(API_NOTIFICATION_FIELDS.notifications, newList, formTouched);
  };

  const changeInspirationValue = (attributeName, value) => {
    const newInspiration = { ...inspirationNotification, [attributeName]: value };

    changeWholeInspiration(newInspiration);
  };

  const getAllInspirations = () => {
    const url = setUrlParams(INSPIRATIONS_GET_URL, { page: 1, count: DEFAULT_PAGE_SIZE });
    dispatch(getInspirationsStart({ url }));
  };

  const handleInspirationIdChange = ({ target: { value } }) => {
    setValueFor("inspiration_vamoos_id", value);
  };

  const inspirationIdStateChange = () => {
    setInspirationId(inspiration_vamoos_id);

    const newInspirationNotification = {
      ...inspirationNotification,
      is_active: !!inspiration_vamoos_id,
      inspiration_vamoos_id,
      delivery_at_days: inspiration_vamoos_id ? delivery_at_days : undefined,
      delivery_at_relative_to: inspiration_vamoos_id ? delivery_at_relative_to : undefined,
    };

    changeWholeInspiration(newInspirationNotification, isInspirationInitalised);
    setIsInspirationInitialised(true);
  };

  const handleMessageChange = ({ target: { value } }) => {
    changeInspirationValue(API_NOTIFICATION_FIELDS.content, value);
  };

  const handleDayChange = ({ target: { value } }) => {
    const day = calculateSelectedDayValue(value, startingPointValue);
    changeInspirationValue(API_NOTIFICATION_FIELDS.delivery_at_days, day);
  };

  const handleStartingPointChange = ({ target: { value } }) => {
    const newInspirationNotification = calculateStartingPointChange(inspirationNotification, value);

    changeWholeInspiration(newInspirationNotification);
  };

  const handleNotificationTimeUpdate = momentValue => {
    const time = momentValue && momentValue.isValid() ? momentValue.format(config.timeFormat) : null;
    changeInspirationValue(API_NOTIFICATION_FIELDS.start_at, time);
  };

  useEffect(getAllInspirations, []);
  useEffect(inspirationIdStateChange, [inspiration_vamoos_id]);

  const mainContent = (() => {
    if (inProgress) return <LoadingScreen />;
    if (!listOfInspirations || listOfInspirations.length === 0) return <Message type="info" text={NO_INSPIRATIONS_MESSAGE} />;
    return (
      <Grid container justifyContent="space-between" spacing={10}>
        <Grid item xs={12} md={12} lg={5}>
          <Content>
            <Select
              disabled={disabled}
              name="inspiration"
              value={inspirationId}
              label={INSPIRATIONS.chooseInspirations}
              onChange={handleInspirationIdChange}
            >
              <MenuItem value="">
                <em>{GLOBAL_CONTENT.none}</em>
              </MenuItem>
              {listOfInspirations.map(inspirationsItem => (
                <MenuItem key={inspirationsItem.id} value={inspirationsItem.vamoos_id}>
                  {inspirationsItem.name}
                </MenuItem>
              ))}
            </Select>
          </Content>

          <Content noMargin>
            <Textarea
              name="inspiration_content"
              label={INSPIRATIONS.messageContent}
              rows={4}
              value={content}
              onChange={handleMessageChange}
              disabled={!inspirationId || startingPointValue === DISPLAYABLE_STARTING_POINT_OPTIONS.disabled.value || disabled}
              error={!!errors?.inspirations?.content}
              helperText={errors?.inspirations?.content}
              isRequired
            />
          </Content>
        </Grid>
        <Grid item md={12} lg={7}>
          <Grid item xs={12}>
            <NotificationSettingsWrapper>
              <Content>
                <BodyText2>{PANEL_TRIPS_CREATE_TEXTS.inspirationsForm.text1}</BodyText2>
              </Content>
              <Content>
                <Grid container spacing={6}>
                  {shouldShowDaySelector && (
                    <Grid item sm={12} md={6} lg={3}>
                      <DaySelector
                        label={INSPIRATIONS.numberOfDays}
                        value={delivery_at_days}
                        placeholder="0"
                        onChange={handleDayChange}
                        disabled={!inspirationId || disabled}
                      />
                    </Grid>
                  )}
                  <Grid item sm={12} md={6} lg={5}>
                    <Select
                      value={startingPointValue}
                      label={INSPIRATIONS.period}
                      onChange={handleStartingPointChange}
                      name="inspiration_start_day"
                      disabled={!inspirationId || disabled}
                    >
                      {Object.values(DISPLAYABLE_STARTING_POINT_OPTIONS).map(({ value, label }) => (
                        <MenuItem value={value} key={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  {shouldShowNotificationDate && (
                    <>
                      <Grid item sm={4} md={2} lg={1}>
                        <AtWrapper>
                          <BodyText2>{GLOBAL_CONTENT.at}</BodyText2>
                        </AtWrapper>
                      </Grid>
                      <Grid item sm={8} md={10} lg={3}>
                        <TimePicker
                          fullWidth
                          label={INSPIRATIONS.deviceLocalTime}
                          name="inspiration_start_time"
                          value={moment(start_at, config.timeFormat)}
                          onChange={handleNotificationTimeUpdate}
                          disabled={!inspirationId || disabled}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <CaptionText cv="grey50">{PANEL_TRIPS_CREATE_TEXTS.inspirationsForm.text2}</CaptionText>
                  </Grid>
                </Grid>
              </Content>
            </NotificationSettingsWrapper>
          </Grid>
        </Grid>
      </Grid>
    );
  })();

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={10}>
        <StyledWrapper>
          <Content margin={9}>
            <Grid container alignItems="center">
              <LeadText>
                {INSPIRATIONS.title}
                {context === "trips" && shouldShowNotificationDate && ":"}
              </LeadText>
              {context === "trips" && (
                <BodyText>{shouldShowNotificationDate && <StrongLabel>{`${notificationDate} ${start_at || ""}`}</StrongLabel>}</BodyText>
              )}
            </Grid>
          </Content>
          {mainContent}
        </StyledWrapper>
      </Grid>
    </Grid>
  );
};

ItineraryInspirations.defaultProps = {
  disabled: false,
};

ItineraryInspirations.propTypes = {
  contextObject: PropTypes.func.isRequired,
  context: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export { ItineraryInspirations, AtWrapper };
