import { convertToFileObject } from "utils/dataConverters";
import { formFilePayloadObject } from "utils/library";
import { Poi } from "../domain/Poi";
import { PoiRequestDto } from "../dto/PoiRequestDto";

export class PoiMapper {
  /**
   *
   * @param {import(types/dto.d).PoiResponseDto} dto
   * @returns {Poi}
   */
  // eslint-disable-next-line class-methods-use-this
  fromDtoToDomain(dto) {
    const domain = new Poi();

    domain.id = dto.id;
    domain.name = dto.name;
    domain.location = dto.location;
    domain.latitude = dto.latitude;
    domain.longitude = dto.longitude;
    domain.position = dto.position;
    domain.description = dto.description;
    domain.country = dto.country;
    domain.countryIso = dto.country_iso;
    domain.iconId = dto.icon_id;
    domain.icon = dto.icon;
    domain.createdBy = dto.created_by;
    domain.operatorId = dto.operator_id;
    domain.timezone = dto.timezone;
    domain.itineraryId = dto.itinerary_id;
    domain.createdAt = dto.created_at;
    domain.updatedAt = dto.updated_at;
    domain.file = convertToFileObject(dto.library_node);
    domain.isDefaultOn = dto.is_default_on;
    domain.isOn = dto.is_on;
    domain.poiRange = dto.poi_range;
    domain.meta = dto.meta;
    domain.localisation = dto.localisation
      ? Object.keys(dto.localisation).reduce((localisation, code) => {
          return {
            ...localisation,
            [code]: {
              name: dto.localisation[code]?.name,
              description: dto.localisation[code]?.description,
              file: convertToFileObject(dto.localisation[code]?.library_node),
            },
          };
        }, {})
      : {};

    domain.type = dto.type;
    domain.children = dto.children?.map(child => this.fromDtoToDomain(child)) || [];
    domain.aliasFor = dto.alias_for ? this.fromDtoToDomain(dto.alias_for) : null;
    domain.aliasForId = dto.alias_for_id;
    domain.parentId = dto.parent_id;

    return domain;
  }

  /**
   *
   * @param {Poi} domain
   * @returns {PoiRequestDto}
   */
  // eslint-disable-next-line class-methods-use-this
  fromDomainToDto(domain) {
    const dto = new PoiRequestDto();

    if (domain.aliasForId) {
      dto.alias_for_id = domain.aliasForId;
    } else {
      dto.type = domain.type;
      dto.latitude = domain.latitude !== undefined ? domain.latitude : null;
      dto.longitude = domain.longitude !== undefined ? domain.longitude : null;
      dto.parent_id = domain.parentId;

      if (domain.type === "waypoint") {
        dto.children = undefined;
      } else {
        dto.name = domain.name !== undefined ? domain.name : null;
        dto.location = domain.location !== undefined ? domain.location : null;
        dto.description = domain.description !== undefined ? domain.description : null;
        dto.icon_id = domain.icon_id || domain.iconId !== undefined ? domain.iconId : null;
        dto.position = domain.position !== undefined ? domain.position : null;

        dto.localisation = domain.localisation
          ? Object.keys(domain.localisation).reduce((localisation, code) => {
              return {
                ...localisation,
                [code]: {
                  file: formFilePayloadObject(domain.localisation[code]?.file) || undefined,
                  name: domain.localisation[code]?.name || undefined,
                  description: domain.localisation[code]?.description || undefined,
                },
              };
            }, {})
          : undefined;

        dto.file = formFilePayloadObject(domain.file) || null;
        dto.is_default_on = !!domain.isDefaultOn;
        dto.timezone = domain.timezone !== undefined ? domain.timezone : null;
        dto.poi_range = domain.poi_range || domain.poiRange !== undefined ? domain.poiRange : null;
        dto.meta = domain.meta || {};
      }
      if (domain.type === "track" || domain.type === "route") {
        dto.children = domain.children?.map(child => this.fromDomainToDto(child)) || [];

        dto.latitude = domain.meta.waypoints[0]?.latitude;
        dto.longitude = domain.meta.waypoints[0]?.longitude;
      }
    }

    return dto;
  }
}
