import styled from "styled-components";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { colors } from "config/theme/colors";
import { commonInputCss } from "../TextInput/style";

export const DatePickerS = styled(DatePicker)`
  ${commonInputCss};
  width: 100%;
  .MuiPickersDay-root {
    font-family: "Montserrat" !important;
  }
`;

export const TimePickerS = styled(TimePicker)`
  ${commonInputCss};
  width: 100%;
  .MuiButtonBase-root {
    color: ${colors.brand} !important;
  }
`;

export const DateTimePickerS = styled(DateTimePicker)`
  ${commonInputCss};
  width: 100%;
`;
