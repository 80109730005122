import { responsiveConfig } from "config/responsive";
import { colors } from "config/theme/colors";
import { getMediaPx } from "helpers";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const Wrap = styled(NavLink)`
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  color: ${p => (p.error ? `${colors.error} !important` : "rgb(27, 34, 43)")};
  cursor: pointer;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
  background-color: ${p => (p.error ? `${colors.withOpacity(colors.error, 0.2)} !important` : "#fff")};
  span {
    margin-left: 15px;
  }
  .MuiSvgIcon-root {
    color: ${p => (p.error ? `${colors.error} !important` : "rgb(141, 144, 149)")};
  }
  &.active {
    background-color: rgba(255, 124, 70, 0.1);
    color: ${colors.brand};
    .MuiSvgIcon-root {
      color: ${colors.brand};
    }
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    color: rgb(141, 144, 149);
    height: 98%;
    padding-left: 0;
    background-color: #fff !important;

    span {
      margin-left: 10px;
    }
    &.active {
      background-color: #fff;
    }
  }
`;
