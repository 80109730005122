import React from "react";
import PropTypes from "prop-types";
import { GoogleApiWrapper, Map as Gmap } from "google-maps-react";
import config from "config/app";

const MapComponent = ({ children, ...rest }) => <Gmap {...rest}>{children}</Gmap>;

MapComponent.defaultProps = { children: null };

MapComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.object, PropTypes.func]),
  google: PropTypes.shape().isRequired,
};

const Map = GoogleApiWrapper({
  apiKey: config.vendors.Google.MAPS_KEY,
  libraries: ["places"]
})(MapComponent);

export { Map, MapComponent };
