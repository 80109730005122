import React, { useCallback, useEffect, useState } from "react";
import { useQueries, useQueryClient } from "@tanstack/react-query";
import Pill from "components/_new/Pill";
import { colors } from "config/theme/colors";
import OutlinedButton from "components/_new/Buttons/OutlinedButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { encodeParameter } from "utils/url";
import LanguageModal from "./languageModal";
import { ResponsiveHideFrom, ResponsiveShowFrom } from "../../responsive";
import { Wrap } from "./style";

export const getEncodedParametrURL = (url, index = 2) => {
  if (!url) return "";
  const splited = url.split("/");
  splited[index] = encodeParameter(splited[index]);
  return splited.join("/");
};

export const useQueriesWithRefetch = (...queries) => {
  const results = useQueries(...queries);

  const refetchAll = useCallback(() => {
    results.forEach(result => result.refetch());
  }, [results]);
  const isRefetchAllLoading = results.some(result => result.isLoading);

  return {
    queries,
    results,
    refetchAll,
    isRefetchAllLoading,
  };
};

const LanguageSelector = ({
  mainLanguage,
  selectedLanguage,
  languages,
  onLanguageChange,
  data,
  isSavingStay,
  isDefaultStayLanguage,
  isStayVariantLoading,
  vamoosId,
}) => {
  const routingLanguages = data?.routing?.language;
  const defaultLanguage = data?.routing?.fallback?.split("/").slice(-1)[0];

  const [isOpen, setIsOpen] = useState(false);
  const [defaultItineraryLanguages, setDefaultItineraryLanguages] = useState(null);
  const [itineraryLanguages, setItineraryLanguages] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { refetchAll, results, isRefetchAllLoading } = useQueriesWithRefetch({
    queries: Object.entries(routingLanguages || {}).map(([_, url]) => ({
      queryKey: [getEncodedParametrURL(url)],
      enabled: Boolean(defaultLanguage),
      refetchOnMount: true,
      onSuccess: res => {
        setItineraryLanguages({
          ...(itineraryLanguages || {}),
          [res.language]: {
            active: res.is_active,
            return_date: res.return_date,
            departure_date: res.departure_date,
            vamoos_id: res.vamoos_id,
          },
        });
        setDefaultItineraryLanguages({
          ...(itineraryLanguages || {}),
          [res.language]: {
            active: res.is_active,
            return_date: res.return_date,
            departure_date: res.departure_date,
            vamoos_id: res.vamoos_id,
          },
        });
        setIsLoading(false);
      },
      onError: () => {
        setIsLoading(false);
      }
    })),
  });

  const toggleModal = () => setIsOpen(!isOpen);

  // Added this and isLoading setState because in some cases itineraryLanguages was not set correctly and Select in LanguageModal showed wrong values
  useEffect(() => {
    if (!itineraryLanguages) return;
    setIsLoading(false);

    return () => {
      setIsLoading(true);
    };
  }, [itineraryLanguages])

  return (
    <>
      {isOpen && !isLoading && (
        <LanguageModal
          data={data}
          languages={languages}
          routing={data?.routing}
          onClose={toggleModal}
          setSelectedLanguage={onLanguageChange}
          defaultItineraryLanguages={defaultItineraryLanguages}
          itineraryLanguages={itineraryLanguages}
          setItineraryLanguages={setItineraryLanguages}
          refetchAll={refetchAll}
          isRefetchAllLoading={isRefetchAllLoading}
          mainLanguage={mainLanguage}
          activeLanguages={languages?.filter(item => itineraryLanguages?.[item.code]?.active)}
        />
      )}
      <Wrap style={{ justifyContent: "center", gap: 15, boxShadow: "none", borderBottom: `1px solid ${colors.grey10}` }}>
        {languages
          .sort((a, b) => (b.code === mainLanguage) - (a.code === mainLanguage) || b.isDefault - a.isDefault)
          .map(item => (
            <>
              <ResponsiveHideFrom size="md">
                <Pill
                  text={item.name}
                  key={item.code}
                  selected={selectedLanguage === item.code}
                  active={itineraryLanguages?.[item.code]?.active}
                  onClick={() => onLanguageChange(item.code)}
                  disabled={isSavingStay || isStayVariantLoading || (isDefaultStayLanguage && !vamoosId)}
                />
              </ResponsiveHideFrom>
              <ResponsiveShowFrom size="md">
                <Pill
                  text={item.code?.toUpperCase() ?? "??"}
                  key={item.code}
                  selected={selectedLanguage === item.code}
                  onClick={() => onLanguageChange(item.code)}
                  disabled={isSavingStay || isStayVariantLoading || (isDefaultStayLanguage && !vamoosId)}
                  active={itineraryLanguages?.[item.code]?.active}
                />
              </ResponsiveShowFrom>
            </>
          ))}
        <OutlinedButton type="grey" startIcon={<EditOutlinedIcon />} onClick={toggleModal} disabled={isSavingStay || !vamoosId} />
      </Wrap>
    </>
  );
};

export default LanguageSelector;
