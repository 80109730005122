/* eslint-disable class-methods-use-this */
import { CUSTOM_APPS, DEFAULT_TERMS_CONTENT } from "constants/content";
import { isObject } from "contracts/types";
import { CustomApp } from "domain/CustomApp";
import { VamoosImage } from "domain/VamoosImage";
import { CustomAppRequestDto } from "dto/CustomAppRequestDto";

const colorVars = [
  { domain: "accentColor", dto: "accent_color" },
  { domain: "defaultBackground", dto: "default_background" },
  { domain: "loginBackground", dto: "login_background" },
  { domain: "loginBorderColor", dto: "login_border_color" },
  { domain: "loginTextColor", dto: "login_text_color" },
  { domain: "loginNavigationColor", dto: "login_navigation_color" },
  { domain: "inputFieldBackgroundColor", dto: "input_field_background_color" },
  { domain: "inputFieldFrameColor", dto: "input_field_frame_color" },
  { domain: "buttonTextColorActive", dto: "button_text_color_active" },
  { domain: "buttonBackgroundColorActive", dto: "button_background_color_active" },
  { domain: "buttonTextColorInactive", dto: "button_text_color_inactive" },
  { domain: "buttonBackgroundColorInactive", dto: "button_background_color_inactive" },
  { domain: "menuBackgroundColour", dto: "menu_background_colour" },
  { domain: "menuIconColour", dto: "menu_icon_colour" },
  { domain: "menuTextColour", dto: "menu_text_colour" },
  { domain: "menuItineraryBackgroundColour", dto: "menu_itinerary_background_colour" },
  { domain: "menuDividerColour", dto: "menu_divider_colour" },
  { domain: "iconBackgroundColor", dto: "icon_background_color" },
  { domain: "inputHintColor", dto: "input_hint_color" },
  { domain: "inputTextColor", dto: "input_text_color" },
];

const stringsVars = [
  { domain: "loginText1", dto: "login_text_1" },
  { domain: "loginText2", dto: "login_text_2" },
  { domain: "loginText3", dto: "login_text_3" },
  { domain: "loginText4", dto: "login_text_4" },
  { domain: "loginText5", dto: "login_text_5" },
  { domain: "loginNextButton", dto: "login_next_button" },
  { domain: "loginPasscodeHeaderTextStay", dto: "login_passcode_header_text_stay" },
  { domain: "loginPasscodeHeaderTextTrip", dto: "login_passcode_header_text_trip" },
  { domain: "loginPasscodeHint", dto: "login_passcode_hint" },
  { domain: "loginPasscodeSubmitButton", dto: "login_passcode_submit_button" },
  { domain: "loginUserIdHint", dto: "login_user_id_hint" },
  { domain: "loginPasscodeSkipButton", dto: "login_passcode_skip_button" },
  { domain: "settingsTitle", dto: "settings_title" },
  { domain: "myItinerariesTitle", dto: "my_itineraries_title" },
  { domain: "aboutUsTitle", dto: "about_us_title" },
  { domain: "faqTitle", dto: "faq_title" },
  { domain: "termsTitle", dto: "terms_title" },
  { domain: "galleryTitle", dto: "gallery_title" },
];

const desctructure = (fields, existingFields, type) => {
  const result = {};

  for (const key in fields) {
    if (!existingFields.find(item => item[type] === key)) result[key] = fields[key];
  }

  return result;
};

export class CustomAppMapper {
  /**
   * @param {CustomApp} domain
   * @returns {CustomAppRequestDto}
   */
  fromDomainToDto(domain) {
    const dto = new CustomAppRequestDto();

    dto.type = domain.type;
    dto.name = domain.name;
    dto.languages = domain.languages;
    dto.default_language = domain.default_language;

    if (domain?.bundleId) {
      dto.bundle_id = domain.bundleId;
    }

    // const isLoginBorderPresent = domain.colors.loginBorderColor.toUpperCase() !== domain.colors.loginBackground.toUpperCase();

    dto.colors = {
      accent_color: domain.colors.accentColor,
      default_background: domain.colors.defaultBackground,
      login_background: domain.colors.loginBackground,
      login_border_color: domain.colors.loginBorderColor,
      login_text_color: domain.colors.loginTextColor,
      login_navigation_color:
        domain.colors.loginNavigationColor === domain.colors.loginBackground
          ? domain.colors.accentColor
          : domain.colors.loginNavigationColor,
      input_field_background_color: domain.colors.inputFieldBackgroundColor,
      input_field_frame_color: domain.colors.inputFieldFrameColor,
      input_text_color: domain.colors.inputTextColor,
      input_hint_color: domain.colors.inputFieldFrameColor,
      link_color: undefined,
      button_text_color_active: domain.colors.buttonTextColorActive,
      button_background_color_active: domain.colors.buttonBackgroundColorActive,
      button_text_color_inactive: domain.colors.buttonTextColorInactive,
      button_background_color_inactive: domain.colors.buttonBackgroundColorInactive,
      menu_background_colour: domain.colors.menuBackgroundColour,
      menu_text_colour: domain.colors.menuTextColour,
      menu_icon_colour: domain.colors.menuIconColour,
      menu_itinerary_background_colour: domain.colors.menuItineraryBackgroundColour,
      menu_divider_colour: domain.colors.menuDividerColour,
      icon_background_color: domain.colors.iconBackgroundColor,
      // ...(desctructure(domain.colors, colorVars, "domain") || {}),
    };

    dto.strings = {};

    if (domain?.strings?.loginText1) {
      dto.strings.login_text_1 = domain.strings.loginText1;
    }
    if (domain?.strings?.loginText2) {
      dto.strings.login_text_2 = domain.strings.loginText2;
    }
    if (domain?.strings?.loginText3) {
      dto.strings.login_text_3 = domain.strings.loginText3;
    }
    if (domain?.strings?.loginText4) {
      dto.strings.login_text_4 = domain.strings.loginText4;
    }
    if (domain?.strings?.loginText5) {
      dto.strings.login_text_5 = domain.strings.loginText5;
    }
    if (domain?.strings?.loginNextButton?.languages) {
      dto.strings.login_next_button = domain.strings.loginNextButton;
    }
    if (domain?.strings?.loginPasscodeHeaderTextStay?.length) {
      dto.strings.login_passcode_header_text_stay = domain.strings.loginPasscodeHeaderTextStay;
    }
    if (domain?.strings?.loginPasscodeHeaderTextTrip?.length) {
      dto.strings.login_passcode_header_text_trip = domain.strings.loginPasscodeHeaderTextTrip;
    }
    if (domain?.strings?.loginPasscodeHint?.length) {
      dto.strings.login_passcode_hint = domain.strings.loginPasscodeHint;
    }
    if (domain?.strings?.loginPasscodeSubmitButton?.length) {
      dto.strings.login_passcode_submit_button = domain.strings.loginPasscodeSubmitButton;
    }
    if (domain?.strings?.loginUserIdHint?.languages) {
      dto.strings.login_user_id_hint = domain?.strings?.loginUserIdHint;
    }
    if (domain?.strings?.loginPasscodeSkipButton?.length) {
      dto.strings.login_passcode_skip_button = domain.strings.loginPasscodeSkipButton;
    }
    if (domain?.strings?.settingsTitle?.length) {
      dto.strings.settings_title = domain.strings.settingsTitle;
    }
    if (domain?.strings?.myItinerariesTitle?.length) {
      dto.strings.my_itineraries_title = domain.strings.myItinerariesTitle;
    }
    if (domain?.strings?.aboutUsTitle?.length) {
      dto.strings.about_us_title = domain.strings.aboutUsTitle;
    }
    if (domain?.strings?.faqTitle?.length) {
      dto.strings.faq_title = domain.strings.faqTitle;
    }
    if (domain?.strings?.termsTitle?.length) {
      dto.strings.terms_title = domain.strings.termsTitle;
    }
    if (domain?.strings?.galleryTitle?.length) {
      dto.strings.gallery_title = domain.strings.galleryTitle;
    }

    dto.strings = {
      ...dto.strings,
      ...(desctructure(domain.strings, stringsVars, "domain") || {}),
    };

    dto.settings = {
      uppercased_menu_names: domain.settings.uppercasedMenuNames,
      login_statusbar_style: domain.settings.loginStatusbarStyle,
    };

    // if (isLoginBorderPresent && domain.type !== "stay") {
    //   dto.colors.login_border_color = domain.colors.loginBorderColor;
    //   dto.settings.login_borders = "tblr";
    // } else {
    //   dto.settings.login_borders = "none";
    // }

    dto.settings.default_user_id = domain.settings.defaultUserId ? domain.settings.defaultUserId : undefined;

    dto.fonts = {
      custom_font: domain.fonts.customFont,
    };

    dto.images = {};

    if (domain.images?.icon?.s3Url || domain?.images?.icon?.fileUrl || domain?.images?.icon?.previewUrl) {
      dto.images.icon = domain.images?.icon?.s3Url || domain.images?.icon?.fileUrl || domain.images?.icon?.previewUrl || "";
    }

    if (domain.images?.iconTransparent?.s3Url || domain?.images?.iconTransparent?.fileUrl || domain?.images?.iconTransparent?.previewUrl) {
      dto.images.icon_transparent =
        domain.images?.iconTransparent?.s3Url ||
        domain.images?.iconTransparent?.fileUrl ||
        domain.images?.iconTransparent?.previewUrl ||
        "";
    }

    if (domain.images?.logo?.s3Url || domain?.images?.logo?.fileUrl || domain?.images?.logo?.previewUrl) {
      dto.images.logo = domain.images?.logo?.s3Url || domain.images?.logo?.fileUrl || domain.images?.logo?.previewUrl || "";
    }
    if (domain.images?.backgroundImage?.s3Url || domain?.images?.backgroundImage || domain?.images?.backgroundImage?.previewUrl) {
      dto.images.backgroundImage =
        domain.images?.backgroundImage?.s3Url ||
        domain.images?.backgroundImage?.fileUrl ||
        domain.images?.backgroundImage?.previewUrl ||
        "";
    }

    dto.menu = domain.menu;

    dto.menu_documents = domain.menuDocuments?.map(doc => {
      const content = doc?.name === CUSTOM_APPS.termsAndCondition.title ? doc.htmlContent + DEFAULT_TERMS_CONTENT : doc.htmlContent;

      return {
        name: doc.name,
        styles: domain.colors.textsColors,
        html_content: content,
      };
    });

    return dto;
  }

  /**
   *
   * @param {CustomAppRequestDto} dto
   * @returns {CustomApp}
   */
  fromDtoToDomain(dto) {
    const domain = new CustomApp();

    // eslint-disable-next-line no-underscore-dangle
    if (dto?._id) {
      // eslint-disable-next-line no-underscore-dangle
      domain.id = dto._id;
    }
    if (dto?.status) {
      domain.status = dto.status;
    }
    if (dto?.sequence_number) {
      domain.sequenceNumber = dto.sequence_number;
    }
    if (dto?.operator_id) {
      domain.operatorId = dto.operator_id;
    }
    if (dto?.updated_at) {
      domain.updatedAt = dto.updated_at;
    }
    if (dto?.created_at) {
      domain.createdAt = dto.created_at;
    }
    domain.type = dto?.type === "hotel" ? "stay" : dto?.type || "";
    domain.name = dto.name;
    domain.bundleId = dto.bundle_id;
    domain.default_language = dto.default_language;
    domain.languages = dto.languages?.[0] ? dto.languages : [];

    domain.colors = {
      accentColor: dto?.colors?.accent_color || "#FF7C46",
      defaultBackground: dto?.colors?.default_background || "#2E2F2E",
      loginBackground: dto?.colors?.login_background || "#2E2F2E",
      loginBorderColor: dto?.colors?.login_border_color || dto?.colors?.login_border_color || "#FFFFFF",
      loginTextColor: dto?.colors?.login_text_color || "#2E2F2E",
      loginNavigationColor: dto?.colors?.login_navigation_color || dto?.colors?.login_navigation_color || "#FFFFFF",
      inputFieldBackgroundColor: dto?.colors?.input_field_background_color || "#FFFFFF",
      inputFieldFrameColor: dto?.colors?.input_field_frame_color || "#FFFFFF",
      inputTextColor: dto?.colors?.input_text_color || "#FFFFFF",
      inputHintColor: dto?.colors?.input_hint_color || "#2E2F2E",
      linkColor: dto?.colors?.link_color || "#FFFFFF",
      buttonBackgroundColorActive: dto?.colors?.button_background_color_active || "#FFFFFF",
      buttonTextColorActive: dto?.colors?.button_text_color_active || "#FFFFFF",
      buttonTextColorInactive: dto?.colors?.button_text_color_inactive || "#FF7C46",
      buttonBackgroundColorInactive: dto?.colors?.button_background_color_inactive || "#9E9D9E",
      menuBackgroundColour: dto?.colors?.menu_background_colour || "#2E2F2E",
      menuItineraryBackgroundColour: dto?.colors?.menu_itinerary_background_colour || "#2E2F2E",
      menuDividerColour: dto?.colors?.menu_divider_colour || "#FFFFFF",
      iconBackgroundColor: dto?.colors?.icon_background_color || "#FFFFFFFF",
      ...(desctructure(dto.colors, colorVars, "dto") || {}),
    };

    if (dto?.colors?.menu_text_colour && dto?.colors?.menu_icon_colour) {
      domain.colors.menuTextColour = dto?.colors?.menu_text_colour || "#FFFFFF";
      domain.colors.menuIconColour = dto?.colors?.menu_icon_colour || "#FFFFFF";
    }

    domain.strings = {
      loginText1: isObject(dto?.strings?.login_text_1)
        ? { ...dto?.strings?.login_text_1 }
        : { text: dto?.strings?.login_text_1, colour: "#FFFFFF" },
      loginText2: isObject(dto?.strings?.login_text_2)
        ? { ...dto?.strings?.login_text_2 }
        : { text: dto?.strings?.login_text_2, colour: "#FFFFFF" },
      loginText3: isObject(dto?.strings?.login_text_3)
        ? { ...dto?.strings?.login_text_3 }
        : { text: dto?.strings?.login_text_3, colour: "#FFFFFF" },
      loginText4: isObject(dto?.strings?.login_text_4)
        ? { ...dto?.strings?.login_text_4 }
        : { text: dto?.strings?.login_text_4, colour: "#FFFFFF" },
      loginText5: isObject(dto?.strings?.login_text_5)
        ? { ...dto?.strings?.login_text_5 }
        : { text: dto?.strings?.login_text_5 || "", colour: "#FFFFFF" },
      loginUserIdHint: dto?.strings?.login_user_id_hint || "",
      loginNextButton: dto?.strings?.login_next_button || "",
      loginPasscodeHeaderTextStay: dto?.strings?.login_passcode_header_text_stay || "",
      loginPasscodeHeaderTextTrip: dto?.strings?.login_passcode_header_text_trip || "",
      loginPasscodeHint: dto?.strings?.login_passcode_hint || "",
      loginPasscodeSubmitButton: dto?.strings?.login_passcode_submit_button || "",
      loginPasscodeSkipButton: dto?.strings?.login_passcode_skip_button || "",
      settingsTitle: dto?.strings?.settings_title || "",
      myItinerariesTitle: dto?.strings?.my_itineraries_title || "",
      aboutUsTitle: dto?.strings?.about_us_title || "",
      faqTitle: dto?.strings?.faq_title || "",
      termsTitle: dto?.strings?.terms_title || "",
      galleryTitle: dto?.strings?.gallery_title || "",
      ...(desctructure(dto.strings, stringsVars, "dto") || {}),
    };
    domain.settings = {
      uppercasedMenuNames: dto?.settings?.uppercased_menu_names || false,
      loginBorders: dto?.settings?.login_borders || "none",
      loginStatusbarStyle: dto?.settings?.login_statusbar_style || "default",
      defaultUserId: dto?.settings?.default_user_id || "",
    };
    domain.fonts = {
      customFont: dto?.fonts?.custom_font || "",
    };

    domain.images = { icon: null, logo: null, backgroundImage: null };
    if (dto?.versionsList) {
      domain.versionsList = [];
    }

    if (dto?.images?.icon) {
      if (isObject(dto.images.icon)) {
        domain.images.icon = VamoosImage(
          {
            preview_url: dto?.images?.icon.https,
            file_url: dto?.images?.icon.https,
            s3: dto?.images?.icon.s3,
            name: dto?.images?.icon.s3.split("/").pop(),
          },
          null,
        );
      } else {
        domain.images.icon = VamoosImage(
          {
            preview_url: dto?.images?.icon,
            file_url: dto?.images?.icon,
            name: dto?.images?.icon.split("/").pop(),
          },
          null,
        );
      }
    }

    if (dto?.images?.icon_transparent) {
      if (isObject(dto.images.icon_transparent)) {
        domain.images.iconTransparent = VamoosImage(
          {
            preview_url: dto?.images?.icon_transparent.https,
            file_url: dto?.images?.icon_transparent.https,
            s3: dto?.images?.icon_transparent.s3,
            name: dto?.images?.icon_transparent.s3.split("/").pop(),
          },
          null,
        );
      } else {
        domain.images.iconTransparent = VamoosImage(
          {
            preview_url: dto?.images?.icon_transparent,
            file_url: dto?.images?.icon_transparent,
            name: dto?.images?.icon_transparent.split("/").pop(),
          },
          null,
        );
      }
    }

    if (dto?.images?.logo) {
      if (isObject(dto.images.logo)) {
        domain.images.logo = VamoosImage(
          {
            preview_url: dto?.images?.logo.https,
            file_url: dto?.images?.logo.https,
            s3: dto?.images?.logo.s3,
            name: dto?.images?.logo.s3.split("/").pop(),
          },
          null,
        );
      } else {
        domain.images.logo = VamoosImage(
          {
            preview_url: dto?.images?.logo,
            file_url: dto?.images?.logo,
            name: dto?.images?.logo.split("/").pop(),
          },
          null,
        );
      }
    }

    if (dto?.images?.backgroundImage) {
      if (isObject(dto.images.backgroundImage)) {
        domain.images.backgroundImage = VamoosImage(
          {
            preview_url: dto?.images?.backgroundImage.https,
            file_url: dto?.images?.backgroundImage.https,
            s3: dto?.images?.backgroundImage.s3,
            name: dto?.images?.backgroundImage.s3.split("/").pop(),
          },
          null,
        );
      } else {
        domain.images.backgroundImage = VamoosImage(
          {
            preview_url: dto?.images?.backgroundImage,
            file_url: dto?.images?.backgroundImage,
            name: dto?.images?.backgroundImage.split("/").pop(),
          },
          null,
        );
      }
    }

    domain.menu = dto.menu;
    // domain.menu =
    //   dto?.menu?.map(doc => {
    //     const content =
    //       doc?.name === CUSTOM_APPS.termsAndCondition.title ? doc.html_content.replace(DEFAULT_TERMS_CONTENT, "") : doc.html_content;

    //     return {
    //       name: doc.name,
    //       styles: doc.styles,
    //       htmlContent: content,
    //     };
    //   }) || [];

    return domain;
  }
}
