import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import theme from "config/theme";
import styled from "styled-components";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";

import { setInputAttr } from "utils/styles";

const { inputs } = theme.forms;

const StyledList = styled.ul`
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  list-style-type: none;
  padding: 0;
`;

const StyledFormHelperText = styled(FormHelperText)`
  margin-bottom: 15px;
  && {
    font-size: ${theme.fonts.fontSize.caption}px;
    line-height: ${theme.fonts.fontSize.caption + theme.setSpacing(1)}px;
    margin-bottom: 15px;
  }
`;

const StyledInputLabel = styled(InputLabel)`
  && {
    /* color: ${theme.colors.grey40}; */
    color: rgba(0, 0, 0, 0.6);
    font-size: ${({ size }) => setInputAttr("font-size", size)}px;
    /* transform: translate(${({ size }) => setInputAttr("transform-label", size, "before")}) scale(1); */
    transform: ${props =>
      props.value !== null && props.value !== undefined && props.value !== ""
        ? "translate(14px, -6px) scale(0.75)"
        : "translate(14px, 14px) scale(1)"} ;
    z-index: 2;
    pointer-events: none;
    background-color: #fff;

    &.Mui-focused {
      color: ${theme.colors.brand};
    }

    &.Mui-error {
      color: ${theme.colors.error};
    }

    &.MuiInputLabel-shrink {
      transform: translate(${({ size }) => setInputAttr("transform-label", size, "after")}) scale(0.75);
      z-index: 2;
    }
  }
`;

const InputField = forwardRef(
  (
    {
      errorMessages,
      id,
      error,
      label,
      name,
      helperText,
      size,
      labelShrink,
      InputProps,
      InputLabelProps,
      fullWidth,
      hideControls,
      isRequired,
      nextInputRef,
      setIsInputActive,
      isInputActive,
      ...rest
    },
    ref,
  ) => {
    const inputId = id || `${name}-field`;
    const [labelWidth, setLabelWidth] = React.useState(0);
    const labelRef = React.useRef(null);

    const handleInputFocus = () => {
      setIsInputActive(true);
    };
    const handleInputBlur = () => {
      setIsInputActive(false);
    };

    React.useEffect(() => {
      if (labelRef && labelRef.current) setLabelWidth(labelRef.current.offsetWidth);
    }, []);

    return (
      <>
        <FormControl error={error} fullWidth={fullWidth}>
          {label && (
            <StyledInputLabel htmlFor={inputId} size={size} ref={labelRef} shrink={labelShrink} value={rest.value}>
              {isRequired ? `${label}*` : label}
            </StyledInputLabel>
          )}
          <OutlinedInput
            id={inputId}
            labelWidth={labelWidth}
            notched={labelShrink}
            inputProps={{ name, ...InputProps }}
            inputRef={nextInputRef}
            ref={ref}
            {...rest}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
          />

          {helperText && (
            <StyledFormHelperText id={`${inputId}-helperText`} component="div">
              {helperText}
            </StyledFormHelperText>
          )}

          {errorMessages?.length > 0 && !isInputActive && (
            <StyledFormHelperText id={`${inputId}-helperText`} component="div">
              <StyledList>
                {Array.isArray(errorMessages) &&
                  errorMessages.map(errorMessage => (errorMessage ? <li key={errorMessage}>{errorMessage}</li> : null))}
                {typeof errorMessages === "string" && !isInputActive && <li>{errorMessages}</li>}
              </StyledList>
            </StyledFormHelperText>
          )}
        </FormControl>
      </>
    );
  },
);

InputField.defaultProps = {
  id: undefined,
  errorMessages: [],
  error: false,
  label: null,
  helperText: "",
  size: "medium",
  labelShrink: undefined,
  hideControls: false,
  InputProps: null,
  InputLabelProps: null,
  fullWidth: true,
  isRequired: false,
  setIsInputActive: () => {},
  isInputActive: false,
};

InputField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  errorMessages: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  error: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
  size: PropTypes.string,
  hideControls: PropTypes.bool,
  labelShrink: PropTypes.bool,
  fullWidth: PropTypes.bool,
  isRequired: PropTypes.bool,
  InputProps: PropTypes.shape(),
  InputLabelProps: PropTypes.shape(),
  setIsInputActive: PropTypes.func,
  isInputActive: PropTypes.bool,
};

const Input = styled(({ size, noShadow, InputLabelProps, forwardRef, ...rest }) => {
  return <InputField variant={inputs.variant} size={size} forwardRef={forwardRef} setIsInputActive={rest.setIsInputActive} isInputActive={rest.isInputActive} {...rest} />;
})`
  && {
    height: ${({ size }) => setInputAttr("height", size)}px;
    border-radius: ${theme.borderRadius}px;

    fieldset {
      border-radius: ${theme.borderRadius}px;
      border-color: ${p => (p.errorMessages ? "red" : theme.colors.grey40)};
      transition: ${theme.transition};
      height: ${({ size }) => setInputAttr("height", size) + 5}px;
    }

    ${({ hideControls }) =>
      hideControls &&
      `
        input[type='number'] {
          -moz-appearance: textfield;
        
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }
    `}

    input,
    textarea {
      height: ${({ size }) => setInputAttr("input-height", size)}px;
      line-height: ${({ size }) => setInputAttr("input-height", size)}px;
      font-size: ${({ size }) => setInputAttr("font-size", size)}px;
      padding: ${({ size }) => setInputAttr("padding", size)};
      background: ${theme.colors.white};
      border-radius: ${theme.borderRadius}px;
    }

    textarea:disabled {
      background-color: transparent;
    }

    textarea {
      height: auto;
    }
    :focus {
      outline: 0;
    }

    :hover {
      fieldset {
        border-color: ${theme.colors.brand};
      }
    }

    & input:disabled {
      background-color: ${theme.colors.withOpacity(theme.colors.grey10, 0.2)};
    }

    input::placeholder {
      font-size: ${({ size }) => setInputAttr("font-size", size)}px;
    }

    .Mui-error,
    .Mui-error.Mui-focused {
      fieldset {
        border-color: ${theme.colors.error};
      }
    }

    &.Mui-focused {
      fieldset {
        border-color: ${theme.colors.brand};
      }
      :hover {
        fieldset {
          color: ${theme.colors.brandHover};
          border-color: ${theme.colors.brandHover};
        }
      }
    }
    ${({ noShadow }) =>
      !noShadow
        ? `&.Mui-focused {
          box-shadow: ${theme.shadows.box};
        }`
        : null}

    &.MuiFormControl-marginDense {
      margin-top: 0;
      margin-bottom: 0;
    }

    .MuiFormHelperText-root {
      font-size: ${theme.setSpacing(3)}px;
      line-height: ${theme.setSpacing(4)}px;
      margin: ${theme.setSpacing(1)}px;
    }
  }
`;

const Textarea = styled(props => {
  return <Input multiline {...props} />;
})`
  && {
    min-height: 48px;
    height: auto;
    padding: ${theme.setSpacing(2)}px 0 0 0;
    fieldset {
      height: auto;
    }

    &.Mui-disabled {
      background-color: ${theme.colors.withOpacity(theme.colors.grey10, 0.2)};
    }
  }
`;

export { Textarea, Input, InputField, StyledList, StyledFormHelperText, StyledInputLabel };
