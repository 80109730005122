import { colors } from "config/theme/colors";
import React from "react";
import { NavLink } from "react-router-dom";

const MenuLink = ({ to, text }) => {
  return (
    <NavLink
      to={to}
      style={({ isActive }) => {
        return {
          color: isActive ? colors.brand : "#000",
          borderBottom: `3px solid ${isActive ? colors.brand : "transparent"}`,
          whiteSpace: "nowrap",
          fontSize: 16,
          textTransform: "uppercase",
          fontWeight: 600,
          display: "flex",
          alignItems: "center",
        };
      }}
    >
      {text}
    </NavLink>
  );
};

export default MenuLink;
