import React from "react";
import PropTypes from "prop-types";

import { FlexGrow } from "components/ui/Content";
import { ChipWithRedirect } from "components/ui/Chips/ChipWithRedirect";
import { Input } from "components/ui/Forms";
import { AttachFilesButton } from "components/ui/Buttons/AttachFilesButton";

import { SortableListElement, RemoveButton } from "components/_shared/SortableListElement/SortableListElement";
import { 
  SortableListElementContentWithError 
} from "components/_shared/SortableListElementContentWithError/SortableListElementContentWithError";
import { IconsSelect } from "feature/panel/_shared/IconsSelect";
import { useItemOriginalIndex } from "hooks/useItemOriginalIndex";

import { formatDisplayableLibraryPopupValues } from "utils/library";
import { checkErrorExistFor, setFileNameAfterUpload } from "utils/validation";
import { isObjectEmpty } from "utils/object";

import { DOCUMENTS_FILE_TYPES } from "constants/defaults";
import { ERRORS, TRIP_WIZARD_CONTENT } from "constants/content";
import { useActiveIcon } from "hooks/useActiveIcon";

const EntryDocumentsListItem = ({ file, index, onRemove, onChange, errors, defaultIcon, dayId, disabled }) => {
  const name = "day";
  const { id, name: title, icon_id, file_name: fileName, web_url } = file;
  const activeIcon = useActiveIcon(file);
  const originalIndex = useItemOriginalIndex(id, `documentsForDay-nr${dayId}`, errors);
  const error = errors[originalIndex] || {};
  const handleAttachFile = files => {
    const [newImage] = formatDisplayableLibraryPopupValues(files);
    onChange(id, { ...file, ...newImage, name: setFileNameAfterUpload(newImage, file.name) });
  };

  const selectedIcon = icon_id || defaultIcon;

  const handleChangeFileTitle = event => {
    const { value } = event.target;
    onChange(id, { ...file, name: value });
  };

  const handleChangeIcon = event => {
    const { value } = event.target;
    onChange(id, { ...file, icon_id: value });
  };

  const handleDeleteAttachment = () => {
    onChange(id, { id, icon_id, name: title });
  };

  const renderFileDeleter = () => (
    <ChipWithRedirect disabled={disabled} onDelete={handleDeleteAttachment} item={file} icon={activeIcon} maxChar={15} />
  )

  const renderFileUploader = () => (
    <AttachFilesButton
      size="small"
      name={`${name}-document-file-nr${index}`}
      onSelect={handleAttachFile}
      allowedFileTypes={DOCUMENTS_FILE_TYPES}
      error={error.file}
      disabled={disabled}
    />
  );
  return (
    <SortableListElement index={index} withError={!isObjectEmpty(error)}>
      <SortableListElementContentWithError error={!isObjectEmpty(error) ? ERRORS.invalidDocumentFile : undefined}>
        <IconsSelect
          size="small"
          name={`${name}-document-nr${index}-icon`}
          selected={selectedIcon}
          onSelect={handleChangeIcon}
          fullWidth={false}
          disabled={disabled}
        />
        <FlexGrow grow={1}>
          <Input
            label={TRIP_WIZARD_CONTENT.storyboard.title}
            value={title}
            onChange={handleChangeFileTitle}
            name={`${name}-document-nr${index}`}
            size="small"
            error={checkErrorExistFor("name", error)}
            helperText={error.name}
            disabled={disabled}
          />
        </FlexGrow>
        <div>{fileName || web_url ? renderFileDeleter() : renderFileUploader()}</div>
        {!disabled && <RemoveButton onClick={() => onRemove(id)} />}
      </SortableListElementContentWithError>
    </SortableListElement>
  );
};

EntryDocumentsListItem.propTypes = {
  file: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.shape().isRequired,
  dayId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  defaultIcon: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  disabled: PropTypes.bool.isRequired,
};

export { EntryDocumentsListItem };
