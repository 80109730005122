import MuiTab from "@material-ui/core/Tab";
import styled from "styled-components";

const Tab = styled(MuiTab)`
  && {
    font-size: ${({ theme }) => theme.fonts.fontSize.body2}px;
    font-weight: ${({ theme }) => theme.fonts.fontWeight.semiBold};
    color: ${({ theme }) => theme.colors.grey100};
    text-transform: none;
  }
`;

export { Tab };
