import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import MenuItem from "@material-ui/core/MenuItem";

import { Tabs } from "components/ui/Tabs/Tabs";
import { Select, Switch, Textarea } from "components/ui/Forms";
import { Content } from "components/ui/Content";
import { Tab } from "components/ui/Tabs/Tab";
import { TextLimiter } from "components/ui/TextLimiter";
import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { GLOBAL_CONTENT, NOTIFICATION_TYPES, UPDATE_MODAL } from "constants/content";
import { Grid } from "@material-ui/core";
import { LS_FORCE_UPDATE_LAST_STATE, LS_SEND_ONLY_TO_ACTIVE_LAST_STATE } from "constants/localstorage";
import { LanguageContext } from "components/_shared/LanguageSelector/LanguageContext";
import { NotificationsService } from "services/NotificationsService";
import { Logger } from "services/application/Logger";

const NOTIFICATION_TABS = {
  template: "template",
  bespoke: "bespoke",
};

const SwitchesContainer = styled(Grid)`
  margin: 24px 0 8px 0;
`;

const SwitchTip = styled.div`
  font-size: 11px;
`;

const ForceUpdateModal = ({
  open,
  onCancel,
  onConfirm,
  configurable,
  forceUpdateDisabled,
  masterLanguage,
  tripLanguage,
  initialNotificationText,
  isLoading,
  customTitle,
}) => {
  const [notificationTab, setNotificationTab] = useState(NOTIFICATION_TABS.template);
  const [selectedTemplateId, setSelectedTemplateId] = useState("");
  const [bespokeNotificationText, setBespokeNotificationText] = useState(initialNotificationText ?? "");

  const [templates, setTemplates] = useState([]);

  const storedForceUpdateState = localStorage.getItem("vamoosForceUpdateLastState");
  const storedSetSendOnlyToActiveState = localStorage.getItem("vamoosSendOnlyToActiveLastState");

  const { currentLanguage } = useContext(LanguageContext);

  const [isForceUpdate, setIsForceUpdate] = useState(() => {
    if (forceUpdateDisabled) {
      return false;
    }
    return storedForceUpdateState === null ? true : storedForceUpdateState === "true";
  });

  const [isSendOnlyToActive, setIsSendOnlyToActive] = useState(
    storedSetSendOnlyToActiveState === null ? false : storedSetSendOnlyToActiveState === "true",
  );

  const [isSendToCurrentLanguage, setIsSendToCurrentLanguage] = useState(false);

  const handleChangeNotificationText = ({ target }) => setBespokeNotificationText(target.value);
  const handleTabChange = (event, newValue) => {
    const newNotificationTab = Object.values(NOTIFICATION_TABS)[newValue];
    setNotificationTab(newNotificationTab);
  };

  const handleConfirm = () => {
    const selectedTemplate = templates.find(template => template.id === selectedTemplateId);

    if (notificationTab === NOTIFICATION_TABS.template) {
      if (isSendToCurrentLanguage) {
        onConfirm(
          selectedTemplate.localisation?.[currentLanguage.code]?.content ||
            selectedTemplate.localisation?.[masterLanguage]?.content ||
            selectedTemplate.content,
          isForceUpdate,
          isSendOnlyToActive,
          isSendToCurrentLanguage,
        );
      } else {
        onConfirm(
          selectedTemplate.localisation?.[tripLanguage]?.content || selectedTemplate.content,
          isForceUpdate,
          isSendOnlyToActive,
          isSendToCurrentLanguage,
        );
      }
    } else {
      onConfirm(bespokeNotificationText, isForceUpdate, isSendOnlyToActive, isSendToCurrentLanguage);
    }
  };

  const handleForceUpdateSwitch = ({ target }) => {
    setIsForceUpdate(target.checked);
    localStorage.setItem(LS_FORCE_UPDATE_LAST_STATE, target.checked);
  };

  const handleSendOnlyToActiveSwitch = ({ target }) => {
    setIsSendOnlyToActive(target.checked);
    localStorage.setItem(LS_SEND_ONLY_TO_ACTIVE_LAST_STATE, target.checked);
  };

  const tryResettingModalSettings = () => {
    if (!open) {
      setNotificationTab(NOTIFICATION_TABS.template);
      setSelectedTemplateId("");
      setBespokeNotificationText("");
    } else {
      setBespokeNotificationText(initialNotificationText ?? "");
    }
  };

  const getForcedNotificationTemplates = async () => {
    const notificationService = new NotificationsService();

    try {
      const data = await notificationService.getAllNotifications();

      setTemplates(data.filter(notification => notification.type === NOTIFICATION_TYPES.forced).map(item => ({ ...item })));
    } catch (e) {
      Logger.debug(e);
    }
  };
  const renderTemplateSelector = () => (
    <Select
      label={UPDATE_MODAL.selectTemplate}
      name="template"
      value={selectedTemplateId}
      onChange={e => setSelectedTemplateId(e.target.value)}
    >
      {templates.length ? (
        templates.map(template => (
          <MenuItem key={template.id} value={template.id} selected={template.id === selectedTemplateId}>
            <TextLimiter
              text={
                isSendToCurrentLanguage
                  ? template.localisation?.[currentLanguage.code]?.content ||
                    template.localisation?.[masterLanguage]?.content ||
                    template.content
                  : template.localisation?.[tripLanguage]?.content || template.content
              }
              maxChar={65}
            />
          </MenuItem>
        ))
      ) : (
        <MenuItem disabled>{UPDATE_MODAL.noTemplates}</MenuItem>
      )}
    </Select>
  );

  const renderBespokeNotificationInput = () => (
    <Textarea
      name="notification_text"
      value={bespokeNotificationText}
      label={UPDATE_MODAL.notificationText}
      rows={2}
      onChange={handleChangeNotificationText}
    />
  );

  const confirmDisabled = notificationTab === NOTIFICATION_TABS.template ? !selectedTemplateId : !bespokeNotificationText.length;
  const selectedTabIndex = Object.values(NOTIFICATION_TABS).findIndex(tab => tab === notificationTab);
  useEffect(tryResettingModalSettings, [open]);

  useEffect(() => {
    getForcedNotificationTemplates();
  }, []);

  return (
    <ConfirmationModal
      title={customTitle ? customTitle : configurable ? UPDATE_MODAL.title : GLOBAL_CONTENT.forceUpdate}
      open={open}
      onCancel={onCancel}
      onConfirm={handleConfirm}
      confirmLabel={GLOBAL_CONTENT.send}
      confirmDisabled={confirmDisabled}
      fullWidth
      maxWidth={configurable ? "md" : "sm"}
      isLoading={isLoading}
    >
      <Content margin={6}>
        <Tabs value={selectedTabIndex} onChange={handleTabChange} aria-label="location tabs">
          <Tab label={UPDATE_MODAL.notificationTemplate} />
          <Tab label={UPDATE_MODAL.bespokeNotification} />
        </Tabs>
      </Content>
      {notificationTab === NOTIFICATION_TABS.template ? renderTemplateSelector() : renderBespokeNotificationInput()}
      {configurable && (
        <SwitchesContainer container>
          <Grid xs={4} item>
            <Switch
              checked={isForceUpdate}
              onChange={handleForceUpdateSwitch}
              labelPlacement="start"
              label={UPDATE_MODAL.forceUpdate}
              disabled={forceUpdateDisabled}
            />
            <SwitchTip>{UPDATE_MODAL.forceUpdateTip}</SwitchTip>
          </Grid>
          <Grid xs={4} item>
            <Switch
              checked={isSendOnlyToActive}
              onChange={handleSendOnlyToActiveSwitch}
              labelPlacement="start"
              label={UPDATE_MODAL.sendOnlyToActive}
            />
            <SwitchTip>{UPDATE_MODAL.sendOnlyToActiveTip}</SwitchTip>
          </Grid>
          <Grid xs={4} item>
            <Switch
              checked={isSendToCurrentLanguage}
              onChange={() => {
                setIsSendToCurrentLanguage(!isSendToCurrentLanguage);
              }}
              labelPlacement="start"
              label={UPDATE_MODAL.useCurrentLanguage}
            />
            <SwitchTip>{UPDATE_MODAL.useLanguage(currentLanguage.name)}</SwitchTip>
          </Grid>
        </SwitchesContainer>
      )}
    </ConfirmationModal>
  );
};

ForceUpdateModal.defaultProps = {
  configurable: false,
  masterLanguage: null,
  isLoading: false,
  customTitle: null,
};

ForceUpdateModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  configurable: PropTypes.bool,
  forceUpdateDisabled: PropTypes.bool.isRequired,
  masterLanguage: PropTypes.string,
  isLoading: PropTypes.bool,
  customTitle: PropTypes.string,
};

export { ForceUpdateModal };
