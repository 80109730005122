import React, { useEffect, useRef } from "react";
import { colors } from "config/theme/colors";
import { Grid } from "./style";
import Top from "./Top";
import MessageItem from "./MessageItem";
import Send from "./Send";

const MessageArea = ({ onBack, currentChat, messages, watch, disabled, hasAdditionalMenu }) => {
  const scrollable = useRef(null);

  useEffect(() => scrollable.current.scrollIntoView({ block: "end" }), [messages]);

  return (
    <Grid hasAdditionalMenu={hasAdditionalMenu}>
      <Top onClick={onBack} watch={watch} {...currentChat} disabled={disabled} />
      <div style={{ height: "100%", overflow: "auto", padding: 15 }}>
        {messages.reverse().map(item => (
          <MessageItem {...item} isUser={item.type === "user-message"} userName={currentChat.name} />
        ))}

        <div ref={scrollable} />
      </div>
      <Send currentChat={currentChat} disabled={disabled} />
    </Grid>
  );
};

export default MessageArea;
