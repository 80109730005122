import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Switch } from "components/ui/Forms";
import { BodyText } from "components/ui/Typography/Typography";

const OptionWrapper = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.setSpacing(3)}px;
`;

const FeatureIcon = styled.img`
  display: block;
  max-width: ${({ theme }) => theme.setSpacing(6.5)}px;
  max-height: ${({ theme }) => theme.setSpacing(6.5)}px;
  margin-right: ${({ theme }) => theme.setSpacing(6)}px;
`;

const FeatureNameWrapper = styled.div`
  margin-right: auto;
`;

const FeatureRow = ({ feature, onSwitch, disabled }) => {
  return (
    <OptionWrapper>
      {feature.icon ? <FeatureIcon alt={feature.name} src={feature?.icon?.fileUrl} /> : null}
      <FeatureNameWrapper>
        <BodyText>{feature.name}</BodyText>
      </FeatureNameWrapper>
      <Switch checked={feature.isActive} onChange={() => onSwitch(feature.name)} disabled={disabled} />
    </OptionWrapper>
  );
};

FeatureRow.propTypes = {
  feature: PropTypes.shape({
    icon: PropTypes.shape({
      fileUrl: PropTypes.string,
    }),
    name: PropTypes.string,
    isActive: PropTypes.bool,
  }).isRequired,
  onSwitch: PropTypes.func.isRequired,
};

export { FeatureRow };
