import React, { useContext } from "react";
import PropTypes from "prop-types";

import { NotificationItem } from "feature/panel/_shared/Notifications/TimedNotificationForm/NotificationItem";
import { NoTemplatesMessage } from "feature/panel/_shared/Notifications/NoTemplatesMessage";
import { NOTIFICATION_TYPES } from "constants/content";
import { useQuery } from "@tanstack/react-query";
import { HttpClient } from "services/application/httpClient/httpClient";

const TimedNotificationForm = ({ context, disabled }) => {
  const { notifications } = useContext(context);
  const { data: allNotifications } = useQuery({
    queryKey: ["timedNotifications"],
    queryFn: () => HttpClient.get("/notification/list/timed?&order=desc"),
  });

  const timedNotificationsList = notifications.filter(notification => notification.type === NOTIFICATION_TYPES.timed);
  return timedNotificationsList.length ? (
    timedNotificationsList.map(notification => (
      <NotificationItem
        disabled={disabled}
        key={notification.id}
        item={notification}
        context={context}
        allNotifications={allNotifications?.data?.items || []}
      />
    ))
  ) : (
    <NoTemplatesMessage notificationType="timed" />
  );
};

TimedNotificationForm.defaultProps = {
  disabled: false,
};

TimedNotificationForm.propTypes = {
  context: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool,
};

export { TimedNotificationForm };
