// @ts-check

import { ERRORS, MIME_FILE_TYPES } from "constants/content";
import { GPX_MIME_TYPES, IMAGES_MIME_TYPES, MAX_IMG_SIZE_MB } from "constants/defaults";
import { bytesToMegaBytes } from "utils/library";
import { GpxFileValidator } from "./domain/GpxFileValidator";

export class FileUploadService {
  /**
   *
   * @param {*} allowedFileTypes
   * @param {*} maxResolution
   * @param {*} maxSize
   */
  constructor(allowedFileTypes = IMAGES_MIME_TYPES, maxResolution = null, maxSize = MAX_IMG_SIZE_MB, minResolution = null, onlyResolution) {
    this.allowedFileTypes = allowedFileTypes;
    this.maxResolution = maxResolution;
    this.minResolution = minResolution;
    this.onlyResolution = onlyResolution;
    this.maxSize = maxSize;
  }

  /**
   *
   * @param {string} name
   * @returns {string}
   */
  // eslint-disable-next-line class-methods-use-this
  getNameWithoutExtention(name) {
    return name
      .split(".")
      .slice(0, -1)
      .join(".");
  }

  /**
   *
   * @param {object[]} libraryFiles
   * @returns {Promise<{validFiles: object[], errors: string[] }>}
   */
  async validateUploadedFiles(files, libraryFiles) {
    const validFiles = [];
    const errors = [];

    await Promise.all(
      files.map(async file => {
        if (!(await this.validateFileType(file, this.allowedFileTypes))) {
          errors.push("WRONG_TYPE_ERROR");
          return;
        }

        if (GPX_MIME_TYPES.some(type => this.allowedFileTypes.includes(type)) && !(await this.validateGpxWaypointsCount(file))) {
          errors.push("WRONG_WAYPOINTS_COUNT");
          return;
        }

        if (!this.validateFileSize(file, this.maxSize)) {
          errors.push("WRONG_SIZE_ERROR");
          return;
        }

        if (libraryFiles && !this.validateDuplicatedFiles(file, libraryFiles)) {
          errors.push("DUPLICATED_FILE_ERROR");
          return;
        }

        if (this.maxResolution) {
          if (!(await this.validateImageMaxResolution(file))) {
            errors.push("WRONG_RESOLUTION_ERROR");
            return;
          }
        }

        if (this.minResolution) {
          if (!(await this.validateImageMinResolution(file))) {
            errors.push("WRONG_RESOLUTION_MIN_ERROR");
            return;
          }
        }

        if (this.onlyResolution) {
          if (!(await this.validateOnlyResolution(file))) {
            errors.push("ONLY_RESOLUTION_ERROR");
            return;
          }
        }

        validFiles.push(file);
      }),
    );

    const errorsCount = errors.reduce((counters, error) => {
      return {
        ...counters,
        [error]: counters[error] !== undefined ? counters[error] + 1 : 1,
      };
    }, {});

    return {
      validFiles,
      errors: Object.keys(errorsCount).map(error => {
        switch (error) {
          case "WRONG_TYPE_ERROR":
            return ERRORS.wrongFileType(
              errorsCount[error],
              this.allowedFileTypes.map(mime => MIME_FILE_TYPES[mime] || mime),
            );
          case "WRONG_WAYPOINTS_COUNT":
            return ERRORS.fileContainsToManyWaypoints(errorsCount[error]);
          case "WRONG_SIZE_ERROR":
            return ERRORS.filesTooHeavy(errorsCount[error], this.maxSize);
          case "DUPLICATED_FILE_ERROR":
            return ERRORS.filesDuplicated(errorsCount[error]);
          case "WRONG_RESOLUTION_ERROR":
            return ERRORS.imageInvalidResolution(errorsCount[error], this.maxResolution);
          case "WRONG_RESOLUTION_MIN_ERROR":
            return ERRORS.imageInvalidMinResolution(errorsCount[error], this.minResolution);
          case "ONLY_RESOLUTION_ERROR":
            return ERRORS.imageInvalidOnlyResolution(errorsCount[error], this.onlyResolution);
          default:
            return undefined;
        }
      }),
    };
  }

  /**
   *
   * @param {object[]} selectedFiles
   * @returns {Promise<{ validFiles: object[], errors: string[] }>}
   */
  async validateFiles(selectedFiles) {
    const data = this.maxResolution ? this.validateImagesMaxResolution(selectedFiles) : { validFiles: selectedFiles || [], errors: [] };

    return data;
  }

  /**
   * @private
   * @param {object} file
   * @param {array} allowedTypes
   * @returns {Promise<boolean>}
   */
  // eslint-disable-next-line class-methods-use-this
  async validateFileType(file, allowedTypes) {
    if (file.file.type) {
      return allowedTypes.includes(file.file.type);
    }

    if (GPX_MIME_TYPES.some(type => allowedTypes.includes(type))) {
      const gpxFileValidator = new GpxFileValidator();

      return gpxFileValidator.validate(file.file);
    }

    return true;
  }

  // eslint-disable-next-line class-methods-use-this
  async validateGpxWaypointsCount(file) {
    const gpxFileValidator = new GpxFileValidator();

    return gpxFileValidator.validateWaypointsCount(file.file);
  }

  /**
   * @private
   * @param {number} maxSize
   * @returns {boolean}
   */
  // eslint-disable-next-line class-methods-use-this
  validateFileSize(file, maxSize) {
    return parseFloat(bytesToMegaBytes(file.file.size)) <= maxSize;
  }

  /**
   * @private
   * @param {*} file
   * @param {object[]} libraryFiles
   * @returns {boolean}
   */
  validateDuplicatedFiles(file, libraryFiles) {
    return !libraryFiles?.some(f => {
      return f.includes(this.getNameWithoutExtention(file.file.name));
    });
  }

  /**
   * @private
   * @param {object[]} file
   * @returns {Promise<boolean>}
   */
  async validateImageMaxResolution(file) {
    const [maxWidth, maxHeight] = this.maxResolution.split("x");
    const fileResolution = await this.getFileResolution(file);

    return this.validateFileResolution(fileResolution, maxWidth, maxHeight);
  }

  async validateImageMinResolution(file) {
    const [maxWidth, maxHeight] = this.minResolution.split("x");
    const fileResolution = await this.getFileResolution(file);

    return fileResolution.width > maxWidth && fileResolution.height > maxHeight;
  }

  async validateOnlyResolution(file) {
    const [width, height] = this.onlyResolution.split("x");
    const fileResolution = await this.getFileResolution(file);

    return fileResolution.width === +width && fileResolution.height === +height;
  }

  /**
   * @private
   * @param {object[]} files
   * @returns {Promise<{ validFiles: object[], errors: string[] }>}
   */
  async validateImagesMaxResolution(files) {
    const [maxWidth, maxHeight] = this.maxResolution.split("x");
    const allFilesResolutions = await this.getFilesResolution(files);

    const results = this.validateFilesResolution(files, allFilesResolutions, maxWidth, maxHeight);

    const errors = results?.invalid?.length ? [ERRORS.imageInvalidResolution(results.invalid?.length, this.maxResolution)] : [];

    return { validFiles: results?.valid || [], errors };
  }

  /**
   *
   * @param {object[]} files
   * @param {object[]} fileDimensionSizeList
   * @param {number} maxWidth
   * @param {number} maxHeight
   * @returns {{valid: object[], invalid: object[]}}
   */
  // eslint-disable-next-line class-methods-use-this
  validateFilesResolution(files, fileDimensionSizeList, maxWidth, maxHeight) {
    return files.reduce(
      (acc, file) => {
        const fileResolution = fileDimensionSizeList[file.id];

        if (fileResolution.width < maxWidth && fileResolution.height < maxHeight) {
          acc.valid.push(file);
        } else {
          acc.invalid.push(file);
        }
        return acc;
      },
      { valid: [], invalid: [] },
    );
  }

  // eslint-disable-next-line class-methods-use-this
  validateFileResolution(fileResolution, maxWidth, maxHeight) {
    return fileResolution.width < maxWidth && fileResolution.height < maxHeight;
  }

  /**
   * @private
   * @param {object} file
   * @returns {Promise<any>}
   */
  // eslint-disable-next-line class-methods-use-this
  async extractImageResolution(file) {
    return new Promise((resolve, reject) => {
      const image = new Image();

      const handleImageResolution = () => {
        resolve({ width: image.width, height: image.height }); // WTF?!?!?!
        image.removeEventListener("load", handleImageResolution);
      };
      const handleImageError = () => {
        reject(null);
        image.removeEventListener("load", handleImageResolution);
      };

      image.addEventListener("load", handleImageResolution);
      image.addEventListener("error", handleImageError);

      image.src = file?.https_url || (file?.file && URL.createObjectURL(file?.file)) || "";
    });
  }

  /**
   *
   * @param {object} file
   * @returns {Promise<any>}
   */
  async getFileResolution(file) {
    return this.extractImageResolution(file);
  }

  /**
   *
   * @param {object} files
   * @returns {Promise<any>}
   */
  async getFilesResolution(files) {
    const filesList = files.map(async file => ({
      [file.id]: await this.extractImageResolution(file),
    }));
    const imagesResolutions = await Promise.all(filesList);

    return Object.assign({}, ...imagesResolutions);
  }
}
