import styled from "styled-components";
import Button from "@mui/material/Button";
import { colors } from "config/theme/colors";
import { font } from "config/theme/fonts";
import { responsiveConfig } from "config/responsive";
import { getMediaPx } from "helpers";

const btnColors = {
  default: {
    border: colors.brand,
    text: colors.brand,
    icon: colors.brand,
  },
  grey: {
    border: "#8d9095",
    text: "#000",
    icon: "#8d9095",
    hover: {
      color: colors.brand,
      border: colors.brand,
    },
  },
  solid: {
    border: colors.brand,
    text: "#fff",
    icon: "#fff",
    background: colors.brand,
    hover: {
      color: colors.brand,
    },
  },
};

export const ButtonS = styled(Button)`
  &.MuiButton-root {
    min-width: auto;
    height: 48px;
    width: ${({ text }) => (text ? "auto" : 48)}px;
    padding: 0 ${({ text }) => (text ? 12 : 0)}px;
    color: ${({ type }) => btnColors[type].text};
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: ${font};
    font-weight: 600;
    border: ${({ type, noBorder }) => (noBorder ? "none" : `1px solid ${btnColors[type].border}`)};
    border-radius: 8px;
    transition: all 0.2s ease-in-out;
    opacity: ${p => (p.disabled ? 0.3 : 1)};
    background-color: ${({ type }) => btnColors[type].background || "transparent"};
    span {
      font-weight: 600;
      white-space: nowrap;
    }
    #text {
      margin-left: 8px;
    }

    .MuiButton-startIcon {
      margin-right: -2px;
    }
    svg {
      color: ${({ type, disabled }) => disabled ? (type === "solid" ? btnColors["solid"].icon : btnColors["grey"].icon ): btnColors[type].icon};
      font-size: 24px;
      transition: all .2s ease-in-out;
    }
    &:hover {
      color: ${({ type }) => btnColors[type].hover?.color || btnColors[type].color};;
      border: ${({ noBorder }) => noBorder && "none"};
      border-color: ${({ type, noBorder }) => (noBorder ? "#fff" : btnColors[type].hover?.border || btnColors[type].border)};
      /* box-shadow: ${({ type, noBorder }) => (noBorder ? "none" : "0px 8px 16px -4px rgba(234, 86, 52, 0.4)")}; */
      svg {
        color: ${({ type }) => btnColors[type].hover?.color || btnColors[type].color};

      }
    }
    @media (max-width: ${p => getMediaPx(p, "md")}px) {
      height: 48px;
      width: ${({ iconOnlyMobile }) => iconOnlyMobile && 48}px;
      &:hover {
        background-color: ${({ type }) => btnColors[type].background || "transparent"};
        svg {
        color: ${({ type }) => btnColors[type].icon};
      }

      }
      #text {
        display: ${({ iconOnlyMobile }) => (iconOnlyMobile ? "none" : "block")};
      }
    }
  }
`;
