import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import MenuIcon from "@material-ui/icons/Menu";

import presentationBg from "assets/images/ca_menu_bg.jpg";
import summaryIcon from "assets/images/previewIcons/197.png";
import travelDoc from "assets/images/previewIcons/189.png";
import postsIcon from "assets/images/previewIcons/191.png";
import mapsIcon from "assets/images/previewIcons/193.png";
import { ReactComponent as ShareIcon } from "assets/images/previewIcons/share.svg";

import { getFilePreviewUrl } from "utils/library";
import { useRefreshedFileUrl } from "hooks/useRefreshedFileUrl";
import { HOMESCREEN_LABELS } from "constants/content";

import { PhonePresentation, bottomScreenDimmed } from "../PhonePresentation";

const HomePagePreviewWrapper = styled.div`
  padding: 20px;
  height: calc(100% - 10px);
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  margin: 5px;
  position: relative;
  z-index: 2;
`;

const Logo = styled.img`
  max-height: 100px;
  max-width: 170px;
  width: auto;
  height: auto;
  margin: 0 0 20px 0;
`;

const StyledMenuIcon = styled(MenuIcon)`
  && {
    color: ${({ theme }) => theme.colors.white};
    position: absolute;
    left: ${({ theme }) => theme.setSpacing(3.75)}px;
    top: ${({ theme }) => theme.setSpacing(3.75)}px;
  }
`;

const ColonWrapper = styled.div`
  margin-top: ${({ theme }) => theme.setSpacing(1.5)}px;
`;
const DaysCountDown = styled.div`
  display: flex;
  justify-content: space-between;
  justify-content: space-between;
  width: 100%;
  margin: 0 ${({ theme }) => theme.setSpacing(8)}px ${({ theme }) => theme.setSpacing(16)}px;

  div {
    display: flex;
    flex-direction: column;

    small {
      font-size: ${({ theme }) => theme.setSpacing(2)}px;
    }
    span {
      font-size: ${({ theme }) => theme.setSpacing(7)}px;
    }
  }
`;
const FieldsContent = styled.div`
  margin: 60px 30px -10px;
  text-align: justify;
`;

const BottomIconContainer = styled.div`
  font-size: 9px;
  display: flex;
  flex-direction: row;
  margin-bottom: -40px;
  margin-top: 50px;

  div {
    min-width: 60px;
    flex-basis: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
const IconImg = styled.img`
  display: block;
  width: 20px;
  height: 20px;
  margin-bottom: 12px;
`;

const StyledShareIcon = styled(ShareIcon)`
  fill: ${({ theme }) => theme.colors.white};
  width: 30px;
  height: 30px;
  position: absolute;
  top: 15px;
  right: 10px;
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;
  background-color: ${({ bgColor }) => (bgColor || "")};
  ${({ borderColor }) => (borderColor ? `border: 8px solid ${borderColor};` : null)};
  ${({ bgImage, theme }) => (bgImage ? bottomScreenDimmed(theme, bgImage) : "")}
  height: 100%;
`;

const HomeScreenPreview = () => {
  const [logoLoaded, setLogoLoaded] = useState(false);
  const [bgLoaded, setBgLoaded] = useState(false);

  const bgColor = useSelector(state => state.customAppForm.customAppForm.colors.loginBackground);
  const logo = useSelector(state => state.customAppForm.customAppForm.images.logo);

  const handleLogoLoad = () => {
    setLogoLoaded(true);
  };
  const handleBgLoad = () => {
    setBgLoaded(true);
  };

  const logoPreview = useRefreshedFileUrl(getFilePreviewUrl(logo));

  const logoImage = new Image();
  const backgroundImage = new Image();

  logoImage.src = logoPreview;
  backgroundImage.src = presentationBg;

  useEffect(() => {
    logoImage.src = logoPreview;
    backgroundImage.src = presentationBg;

    if (logo && logoPreview) {
      logoImage.addEventListener("load", handleLogoLoad);
      backgroundImage.addEventListener("load", handleBgLoad);
    }
    return () => {
      logoImage.removeEventListener("load", handleLogoLoad);
      backgroundImage.removeEventListener("load", handleBgLoad);
    };
  }, [logoPreview, presentationBg]);

  const previewLoading = logo && !logoLoaded;

  const content = (
    <HomePagePreviewWrapper>
      <StyledMenuIcon />
      {logoPreview && <Logo alt={HOMESCREEN_LABELS.logoCaption} src={logoImage.src} />}
      <StyledShareIcon />
      <FieldsContent>
        {HOMESCREEN_LABELS.line1}
        <br />
        {HOMESCREEN_LABELS.line1}
      </FieldsContent>
      <DaysCountDown>
        <div>
          <span>{HOMESCREEN_LABELS.daysAmount}</span>
          <small>{HOMESCREEN_LABELS.days}</small>
        </div>
        <ColonWrapper>:</ColonWrapper>
        <div>
          <span>{HOMESCREEN_LABELS.hoursAmount}</span>
          <small>{HOMESCREEN_LABELS.hours}</small>
        </div>
        <ColonWrapper>:</ColonWrapper>
        <div>
          <span>{HOMESCREEN_LABELS.minAmount}</span>
          <small>{HOMESCREEN_LABELS.minutes}</small>
        </div>
        <ColonWrapper>:</ColonWrapper>
        <div>
          <span>{HOMESCREEN_LABELS.secAmount}</span>
          <small>{HOMESCREEN_LABELS.seconds}</small>
        </div>
      </DaysCountDown>
      <BottomIconContainer>
        <div>
          <IconImg src={summaryIcon} alt="Summary" />
          <span>{HOMESCREEN_LABELS.summary}</span>
        </div>
        <div>
          <IconImg src={travelDoc} alt="Summary" />
          <span>{HOMESCREEN_LABELS.travelDoc}</span>
        </div>
        <div>
          <IconImg src={postsIcon} alt="Summary" />
          <span>{HOMESCREEN_LABELS.posts}</span>
        </div>
        <div>
          <IconImg src={mapsIcon} alt="Summary" />
          <span>{HOMESCREEN_LABELS.maps}</span>
        </div>
      </BottomIconContainer>
    </HomePagePreviewWrapper>
  );

  return (
    <PhonePresentation loading={previewLoading}>
      <ContentWrapper bgColor={bgColor} bgImage={backgroundImage.src}>
        {content}
      </ContentWrapper>
    </PhonePresentation>
  );
};

export { HomeScreenPreview };
