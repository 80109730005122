import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { ConditionalWrapper } from "components/ui/Inputs/conditionalWrapper";
import Badge from "components/_new/Badge";
import { Wrap } from "./style";

const TableAction = ({ element, onClick, tooltip, badgeContent, noClick, disabled, wrapStyle, mobileStyle }) => {
  return (
    <ConditionalWrapper
      condition={tooltip}
      wrapper={children => (
        <Tooltip
          title={tooltip}
          placement="bottom"
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -35],
                  },
                },
              ],
            },
          }}
        >
          {children}
        </Tooltip>
      )}
    >
      <Badge
        badgeContent={badgeContent}
        showZero
        sx={{
          "& .MuiBadge-badge": {
            top: 23,
            right: 23,
          },
        }}
      >
        <Wrap style={wrapStyle} onClick={e => !disabled && onClick && onClick(e)} disabled={disabled} mobileStyle={mobileStyle}>
          <div id="content">{element}</div>
        </Wrap>
      </Badge>
    </ConditionalWrapper>
  );
};

export default TableAction;
