import { put, select, takeEvery, takeLatest } from "redux-saga/effects";

import * as actionTypes from "store/customAppForm/actionTypes";
import { handleErrorMessages } from "utils/store";
import {
  getCustomAppByIdSuccess,
  getCustomAppByIdFail,
  checkBundleIdAvailableSuccess,
  checkUserIdAvailabilityForCAFail,
  setBundleIdUniquenesFlag,
  saveNewCustomAppFail,
  saveNewCustomAppSuccess,
  submitCustomAppDefinitionForReviewSuccess,
  dismissCustomAppSuccess,
  dismissCustomAppFail,
  getCustomAppVersionsListFail,
  getCustomAppVersionsListSuccess,
  updateCustomAppDefinitionSuccess,
  updateCustomAppDefinitionFail,
  getCustomAppByIdStart,
  reviseCustomAppSuccess,
  reviseCustomAppFail,
  submitCustomAppDefinitionForReviewFail,
} from "store/customAppForm/actions";
import { CUSTOM_APPS, ERRORS, CUSTOM_APP_NAVIGATION } from "constants/content";
import {
  BUNDLE_DATA_CUSTOM_APP_URL,
  CUSTOM_APP_DEFINITION_DISCARD_URL,
  CUSTOM_APP_DEFINITION_BY_ID_URL,
  CUSTOM_APP_DEFINITION_LIST_URL,
  CUSTOM_APP_DEFINITION_SUBMIT_URL,
  BUNDLE_VERSIONS_LIST_URL,
} from "constants/api";
import { setUrl } from "utils/url";

import { customAppValidator, customAppBaseValidator } from "feature/panel/Settings/CustomApps/_shared/customAppValidator";
import { isObjectEmpty } from "utils/object";
import { hideSavingCover, setNotification, showSavingCover, tryRouteChangeStart } from "store/app/actions";
import notifications from "constants/notifications";
import { PANEL_CUSTOM_APPS_EDIT_PATH, PANEL_CUSTOM_APPS_EDIT_PATH_GENERAL, PANEL_SETTINGS_CUSTOM_APPS } from "constants/routes";
import { HttpClient } from "services/application/httpClient/httpClient";
import { CustomAppMapper } from "mappers/CustomAppMapper";
import { Logger } from "services/application/Logger";

const getErrorSections = validationErrors => {
  const sections = [];

  if (validationErrors?.information) {
    sections.push(CUSTOM_APP_NAVIGATION.information);
  }

  if (validationErrors?.name || validationErrors?.settings?.defaultUserId) {
    sections.push(CUSTOM_APP_NAVIGATION.general);
  }
  if (validationErrors?.images?.logo || validationErrors?.images?.icon) {
    sections.push(CUSTOM_APP_NAVIGATION.logoAndIcons);
  }
  if (
    validationErrors?.images?.backgroundImage ||
    validationErrors?.strings?.loginNextButton ||
    validationErrors?.strings?.loginUserIdHint
  ) {
    sections.push(CUSTOM_APP_NAVIGATION.loginScreen);
  }
  if (validationErrors?.menuDocuments?.length) {
    sections.push(CUSTOM_APP_NAVIGATION.texts);
  }
  return sections.join(", ");
};

function setAllDocumentsForUpdate(documents) {
  const termsAndConditionPresent = documents.terms;
  const faqPresent = documents.faq;
  const aboutPresent = documents.about;
  const newDocumentsList = { ...documents };

  if (!termsAndConditionPresent) {
    newDocumentsList.terms = {
      name: CUSTOM_APPS.termsAndCondition.title,
      htmlContent: "",
    };
  }
  if (!faqPresent) {
    newDocumentsList.faq = {
      name: CUSTOM_APPS.faq.title,
      htmlContent: "",
    };
  }
  if (!aboutPresent) {
    newDocumentsList.about = {
      name: CUSTOM_APPS.about.title,
      htmlContent: "",
    };
  }

  return newDocumentsList;
}

function* getCustomAppDetails({ payload }) {
  const customAppMapper = new CustomAppMapper();
  try {
    const url = setUrl(CUSTOM_APP_DEFINITION_BY_ID_URL, { id: payload });
    const response = yield HttpClient.get(url);
    if (response?.data) {
      const data = customAppMapper.fromDtoToDomain(response.data);

      if (Object.keys(data.menu).length) {
        // const colors = data.menuDocuments[0].styles;
        // data.colors.textsColors = { ...colors };
      } else {
        data.colors.textsColors = {
          backgroundColor: "#2E2F2E",
          textColor: "#FFFFFF",
          headlineColor: "#FFFFFF",
        };
      }
      const documents = setAllDocumentsForUpdate(data.menu.documents);

      data.menu.documents = { ...documents };

      yield put(getCustomAppByIdSuccess({ ...data }));
    }
  } catch (e) {
    const error = handleErrorMessages(e);
    put(getCustomAppByIdFail({ error }));
  }
}

function* getCustomAppVersionsList({ payload }) {
  const customAppMapper = new CustomAppMapper();
  try {
    const url = setUrl(BUNDLE_VERSIONS_LIST_URL, { bundleId: payload.bundleId });
    const { data } = yield HttpClient.get(url);
    const list = data.entries.map(dto => customAppMapper.fromDtoToDomain(dto));

    yield put(getCustomAppVersionsListSuccess({ list, count: data.count }));
  } catch (e) {
    yield put(getCustomAppVersionsListFail({ errors: handleErrorMessages(e) }));
  }
}

function* saveNewCustomApp({ payload }) {
  const customAppMapper = new CustomAppMapper();
  const validationErrors = customAppBaseValidator(payload, false, true);
  yield put(showSavingCover());

  try {
    if (isObjectEmpty(validationErrors)) {
      const requestData = customAppMapper.fromDomainToDto(payload);
      const { data } = yield HttpClient.put(CUSTOM_APP_DEFINITION_LIST_URL, requestData);

      if (Object.keys(payload?.dirtyFields || {}).length) yield HttpClient.post(`/information/${payload.bundleId}/add`, payload.info);

      // eslint-disable-next-line dot-notation
      yield put(saveNewCustomAppSuccess({ id: data["_id"], currentPage: payload.currentPage, clearErrors: payload.clearErrors }));
      yield put(setNotification({ type: "success", message: notifications.resource("customApp").create.success }));

      yield put(hideSavingCover());
    } else {
      yield put(saveNewCustomAppFail({ errors: validationErrors }));

      const message = `${notifications.resource("customApp").create.fail}. Check ${getErrorSections(validationErrors)} section.`;

      yield put(setNotification({ type: "error", message }));
      yield put(hideSavingCover());
    }
  } catch (e) {
    const errors = handleErrorMessages(e);
    yield put(saveNewCustomAppFail({ errors }));
    yield put(setNotification({ type: "error", message: notifications.resource("customApp").create.fail }));
    yield put(hideSavingCover());
  }
}

function* saveCustomAppSuccess({ payload }) {
  const { id, currentPage } = payload;
  if (id) {
    const url = setUrl(currentPage ? `${PANEL_CUSTOM_APPS_EDIT_PATH}/${currentPage}` : PANEL_CUSTOM_APPS_EDIT_PATH_GENERAL, { id });
    if (payload.clearErrors) payload.clearErrors();

    yield put(tryRouteChangeStart(url));
  }
}

function* reloadUpdatedCustomApp({ payload }) {
  if (payload?.id) {
    yield put(getCustomAppByIdStart(payload?.id));
  }
}

function* updateCustomAppDefinition({ payload }) {
  const customAppMapper = new CustomAppMapper();
  const validationErrors = customAppValidator(payload, true);

  yield put(showSavingCover());

  Logger.debug("Update custom app errors:", validationErrors);

  try {
    if (isObjectEmpty(validationErrors)) {
      const requestData = customAppMapper.fromDomainToDto(payload);
      const url = setUrl(CUSTOM_APP_DEFINITION_BY_ID_URL, { id: payload.id });

      yield HttpClient.post(`/information/${payload.infoId}`, payload.info);

      yield HttpClient.post(url, requestData);

      yield put(updateCustomAppDefinitionSuccess({ id: payload.id }));
      yield put(setNotification({ type: "success", message: notifications.resource("customApp").update.success }));
      yield put(hideSavingCover());
    } else {
      yield put(updateCustomAppDefinitionFail({ errors: validationErrors }));
      yield put(
        setNotification({
          type: "error",
          message: `${notifications.resource("customApp").update.fail}. Check ${getErrorSections(validationErrors)} section.`,
        }),
      );
      yield put(hideSavingCover());
    }
  } catch (e) {
    Logger.debug(e);

    const errors = handleErrorMessages(e);
    yield put(updateCustomAppDefinitionFail({ errors }));
    yield put(setNotification({ type: "error", message: notifications.resource("customApp").update.fail }));
    yield put(hideSavingCover());
  }
}

function* submitCustomAppForReview({ payload }) {
  yield put(showSavingCover());
  try {
    const data = yield select(state => state.customAppForm.customAppForm);

    const errors = customAppValidator({ ...data, info: payload.info, dirtyFields: payload.dirtyFields });

    Logger.debug("Custom app errors:", errors);

    if (isObjectEmpty(errors)) {
      const url = setUrl(CUSTOM_APP_DEFINITION_SUBMIT_URL, { id: payload.id });
      yield HttpClient.post(url);
      yield put(submitCustomAppDefinitionForReviewSuccess({ id: payload.id }));
      yield put(setNotification({ type: "success", message: CUSTOM_APPS.notifications.submit }));

      yield put(tryRouteChangeStart(PANEL_SETTINGS_CUSTOM_APPS));
      yield put(hideSavingCover());
    } else {
      yield put(submitCustomAppDefinitionForReviewFail({ errors }));
      yield put(
        setNotification({ type: "error", message: `${CUSTOM_APPS.notifications.submitFail}. Check ${getErrorSections(errors)} section.` }),
      );
      yield put(hideSavingCover());
    }
  } catch (e) {
    Logger.debug(e);

    const message =
      e.response?.data.message === CUSTOM_APPS.notifications.submitIncompleteDef
        ? CUSTOM_APPS.notifications.submitIncompleteDef
        : CUSTOM_APPS.notifications.submitFail;
    yield put(submitCustomAppDefinitionForReviewFail({ errors: { submit: e.response?.data.message } }));
    yield put(setNotification({ type: "error", message }));
    yield put(hideSavingCover());
  }
}

function* dismissCustomApp({ payload }) {
  yield put(showSavingCover());
  try {
    const url = setUrl(CUSTOM_APP_DEFINITION_DISCARD_URL, { id: payload.id });
    yield HttpClient.post(url);
    yield put(dismissCustomAppSuccess());
    yield put(setNotification({ type: "success", message: CUSTOM_APPS.notifications.dismiss }));
    yield put(hideSavingCover());
  } catch (e) {
    const errors = handleErrorMessages(e);
    yield put(dismissCustomAppFail({ errors }));
    yield put(setNotification({ type: "error", message: CUSTOM_APPS.notifications.dismissError }));
    yield put(hideSavingCover());
  }
}

function* checkUserIdAvailability({ payload }) {
  const { operators } = yield select(state => state.auth);
  const isCurrentOperatorCode = operators.some(
    ({ usedCodes }) => usedCodes && usedCodes.some(code => code.toLowerCase() === payload.toLowerCase()),
  );

  const errorMessage = isCurrentOperatorCode ? null : ERRORS.caInvalidUserId;

  yield put(checkUserIdAvailabilityForCAFail({ error: errorMessage }));
}

function* checkBudnleIdAvaliability({ payload }) {
  HttpClient.cancelRequest();
  try {
    const url = setUrl(BUNDLE_DATA_CUSTOM_APP_URL, { id: payload.bundleId });
    const response = yield HttpClient.get(url);

    if (response?.data) {
      yield put(checkBundleIdAvailableSuccess({ bundleId: ERRORS.isUnique(CUSTOM_APPS.tableHeaders.title) }));
      yield put(setBundleIdUniquenesFlag({ value: false }));
    }
  } catch (e) {
    yield put(checkBundleIdAvailableSuccess({ bundleId: null }));
  }
}

function* reviseCustomApp({ payload }) {
  const customAppMapper = new CustomAppMapper();
  yield put(showSavingCover());
  try {
    const requestData = customAppMapper.fromDomainToDto(payload);

    const { data } = yield HttpClient.put(CUSTOM_APP_DEFINITION_LIST_URL, requestData);
    // eslint-disable-next-line dot-notation
    yield put(reviseCustomAppSuccess({ id: data["_id"] }));
    yield put(setNotification({ type: "success", message: CUSTOM_APPS.notifications.reviseSuccess }));
    yield put(hideSavingCover());
  } catch (e) {
    const error = handleErrorMessages(e);

    yield put(reviseCustomAppFail({ error }));
    yield put(
      setNotification({
        type: "error",
        message: e.response.status === 403 ? CUSTOM_APPS.notifications.reviseDraftError : CUSTOM_APPS.notifications.reviseSuccess,
      }),
    );
    yield put(hideSavingCover());
  }
}

export function* customAppFormSaga() {
  yield takeEvery(actionTypes.GET_CUSTOM_APP_BY_ID_START, getCustomAppDetails);
  yield takeEvery(actionTypes.CHECK_USER_ID_FOR_CUSTOM_APP_AVAILABILITY_START, checkUserIdAvailability);
  yield takeEvery(actionTypes.SAVE_NEW_CUSTOM_APP_START, saveNewCustomApp);
  yield takeEvery(actionTypes.SAVE_NEW_CUSTOM_APP_SUCCESS, saveCustomAppSuccess);
  yield takeEvery(actionTypes.UPDATE_CUSTOM_APP_DEFINITION_START, updateCustomAppDefinition);
  yield takeEvery(actionTypes.DISMISS_CUSTOM_APP_START, dismissCustomApp);
  yield takeEvery(actionTypes.REVISE_CUSTOM_APP_START, reviseCustomApp);
  yield takeEvery(actionTypes.REVISE_CUSTOM_APP_SUCCESS, saveCustomAppSuccess);
  yield takeEvery(actionTypes.UPDATE_CUSTOM_APP_DEFINITION_SUCCESS, reloadUpdatedCustomApp);
  yield takeEvery(actionTypes.SUBMIT_CUSTOM_APP_DEFINITION_FOR_REVIEW_START, submitCustomAppForReview);
  yield takeLatest(actionTypes.CHECK_BUNDLE_ID_AVAILABLE_START, checkBudnleIdAvaliability);
  yield takeLatest(actionTypes.GET_CUSTOM_APP_VERSIONS_LIST_START, getCustomAppVersionsList);
}
