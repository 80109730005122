import { takeEvery, put, call } from "redux-saga/effects";

import * as actionTypes from "store/icons/actionTypes";
import { hideSavingCover, setNotification, showSavingCover } from "store/app/actions";
import {
  updateIconsSuccess,
  updateIconsFail,
  getPublicIconsSuccess,
  getPublicIconsFail,
  getOperatorIconsSuccess,
  getOperatorIconsFail,
  toggleIconFormTouched,
} from "store/icons/actions";

import { GET_PUBLIC_ICONS, ADMIN_ICON_URL, GET_OPERATOR_ICONS } from "constants/api";
import { DEFAULT_PAGE_SIZE } from "constants/defaults";
import { setUrlParams, setUrl } from "utils/url";
import { Icon } from "domain/Icon";
import { IconRequestDto } from "dto/IconRequestDto";
import { ADMIN_ICONS_CONTENT } from "constants/content";
import { HttpClient } from "services/application/httpClient/httpClient";
import { handleErrorMessages } from "utils/store";

async function sendIconData(id, data, action) {
  const url = setUrl(ADMIN_ICON_URL, { id });
  await HttpClient[action](url, data);
}

function* getOperatorIcons() {
  try {
    const response = yield HttpClient.get(setUrlParams(GET_OPERATOR_ICONS, { section: "poi", page: 1, count: DEFAULT_PAGE_SIZE }));
    const { items } = response.data;
    yield put(getOperatorIconsSuccess({ items }));
  } catch (e) {
    if (!HttpClient.isCancelError(e)) {
      yield put(getOperatorIconsFail(e));
    }
  }
}

function* getPublicIcons() {
  try {
    const response = yield HttpClient.get(setUrlParams(GET_PUBLIC_ICONS, { section: "poi", page: 1, count: DEFAULT_PAGE_SIZE }));
    const { items } = response.data;
    const icons = items?.map(item => Icon(item));
    yield put(getPublicIconsSuccess({ items: icons }));
  } catch (e) {
    if (!HttpClient.isCancelError(e)) {
      yield put(getPublicIconsFail({ errors: handleErrorMessages(e) }));
    }
  }
}

function* updateIcons({ payload }) {
  yield put(showSavingCover());

  const { changedIcons, addedIcons, deletedIcons } = payload;
  try {
    yield Promise.all(
      deletedIcons.map(icon => {
        return sendIconData(icon.id, IconRequestDto(icon), "delete");
      }),
    );

    yield Promise.all(
      changedIcons.map(icon => {
        return sendIconData(icon.id, IconRequestDto(icon), "post");
      }),
    );

    yield Promise.all(
      addedIcons.map(icon => {
        return sendIconData("", IconRequestDto(icon), "put");
      }),
    );

    yield put(toggleIconFormTouched(false))
    yield put(updateIconsSuccess());
    yield put(
      setNotification({
        type: "success",
        message: ADMIN_ICONS_CONTENT.success,
      }),
    );
    yield call(getPublicIcons);

    yield put(hideSavingCover());
  } catch (e) {
    if (!HttpClient.isCancelError(e)) {
      yield put(updateIconsFail({ errors: handleErrorMessages(e) }));
      yield put(
        setNotification({
          type: "error",
          message: ADMIN_ICONS_CONTENT.failure,
        }),
      );
    }
    yield put(hideSavingCover());
  }
}

export function* iconsSaga() {
  yield takeEvery(actionTypes.GET_PUBLIC_ICONS_START, getPublicIcons);
  yield takeEvery(actionTypes.GET_OPERATOR_ICONS_START, getOperatorIcons);
  yield takeEvery(actionTypes.UPDATE_ICONS_START, updateIcons);
}
