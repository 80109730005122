import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Switch from "components/_new/Switch";
import TableAction from "components/_new/Table/Action";
import { colors } from "config/theme/colors";
import DeleteDirectoryModal from "feature/panel/Itinerary/components/DeleteDirectoryModal";
import { ResponsiveHideFrom, ResponsiveShowFrom } from "feature/panel/Itinerary/responsive";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import DirectoryModal from "../../../components/Modal/modal";
import { Buttons } from "../../Trips/Creations/MobileItem/style";
import { Buttons as ButtonsWrap, NameWrap, Wrap } from "./style";

const Item = ({ index, directories, replace, update, remove, control, watch }) => {
  const [isItemModalOpen, setIsItemModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const item = watch(`directories.voucher.${index}`);

  const toggleButtons = () => setIsOpen(!isOpen);
  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);
  return (
    <Wrap>
      {showDeleteModal && (
        <DeleteDirectoryModal type="voucher" name={item.name} onConfirm={() => remove(index)} onCancel={toggleDeleteModal} />
      )}

      {isItemModalOpen && (
        <DirectoryModal
          item={item}
          onClose={() => setIsItemModalOpen(false)}
          directories={directories}
          replace={replace}
          update={update}
          index={index}
          type="voucher"
        />
      )}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CardGiftcardIcon />
      </div>
      <NameWrap>{item.name}</NameWrap>
      <ButtonsWrap>
        <ResponsiveShowFrom size="md">
          <Buttons style={{ gap: 0, height: 50, top: 15, padding: 0 }}>
            <ResponsiveShowFrom size="md">
              <TableAction
                element={
                  <Controller
                    control={control}
                    name={`directories.voucher.${index}.is_enabled`}
                    render={({ field: { onChange, value } }) => <Switch onChange={onChange} checked={value} />}
                  />
                }
                onClick={() => setIsItemModalOpen(true)}
                wrapStyle={{ backgroundColor: "#fff", border: 0, height: 48 }}
              />
            </ResponsiveShowFrom>
            {isOpen && (
              <>
                <TableAction
                  element={<EditOutlined />}
                  onClick={() => setIsItemModalOpen(true)}
                  wrapStyle={{ width: 48, height: 48, backgroundColor: "#fff", border: `1px solid ${colors.grey10}` }}
                />
                <TableAction
                  element={<DeleteOutline />}
                  onClick={() => remove(index)}
                  wrapStyle={{ width: 48, height: 48, backgroundColor: "#fff", border: `1px solid ${colors.grey10}` }}
                />
              </>
            )}

            <TableAction
              element={<MoreHorizIcon />}
              onClick={toggleButtons}
              wrapStyle={{ width: 48, height: 48, backgroundColor: "#fff", border: `1px solid ${colors.grey10}` }}
            />
          </Buttons>
        </ResponsiveShowFrom>
        <ResponsiveHideFrom size="md">
          <TableAction
            element={
              <Controller
                control={control}
                name={`directories.voucher.${index}.is_enabled`}
                render={({ field: { onChange, value } }) => <Switch onChange={onChange} checked={value} />}
              />
            }
            onClick={() => setIsItemModalOpen(true)}
          />
        </ResponsiveHideFrom>
        <ResponsiveHideFrom size="md">
          <TableAction element={<EditOutlined />} onClick={() => setIsItemModalOpen(true)} />
        </ResponsiveHideFrom>
        <ResponsiveHideFrom size="md">
          <TableAction element={<DeleteOutline />} onClick={toggleDeleteModal} />
        </ResponsiveHideFrom>
      </ButtonsWrap>
    </Wrap>
  );
};

export default Item;
