const isCustom = (currentTemplate, content, language) => {
  const currentTemplateTranslatedContent = currentTemplate?.localisation?.[language]?.content;

  for (const key in currentTemplate?.localisation) {
    if (currentTemplate?.localisation[key].content === content) {
      return false;
    }
  }

  return currentTemplateTranslatedContent !== content && currentTemplate?.content !== content;
};

export const getContent = (currentTemplate, content, language) => {
  const currentTemplateTranslatedContent = currentTemplate?.localisation?.[language]?.content;

  return isCustom(currentTemplate, content, language) ? content : currentTemplateTranslatedContent || content;
};
