import React, { useContext } from "react";
import PropTypes from "prop-types";

import { TableContext } from "components/ui/Tables/TableContext";
import { ActionTableCell, StyledMuiTableRow } from "components/ui/Tables/_shared/styledComponents";

const TableRow = ({ item, tableWidth, children, clickedOverride, shouldBlink }) => {
  const { actions: Actions, onRowClick } = useContext(TableContext);

  const handleRowClick = () => onRowClick(item);

  return (
    <StyledMuiTableRow
      item={item}
      clickedOverride={clickedOverride}
      tableWidth={tableWidth}
      onClick={handleRowClick}
      shouldBlink={shouldBlink}
    >
      <>
        {children}

        {Actions && (
          <ActionTableCell align="right">
            <Actions item={item} />
          </ActionTableCell>
        )}
      </>
    </StyledMuiTableRow>
  );
};

TableRow.propTypes = {
  item: PropTypes.shape().isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
};

export { TableRow, StyledMuiTableRow, ActionTableCell };
