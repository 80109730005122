import React from "react";
import PropTypes from "prop-types";
import TableCell from "@material-ui/core/TableCell";

import { Link } from "components/ui/Links/Links";

const LinkedTableRowCell = ({ url, children, firstItemWithCheckbox }) => (
  <TableCell style={{ paddingLeft: firstItemWithCheckbox ? "4px" : "" }}>
    {children && (
      <Link to={url} cv="grey100" linkFor="table" noDecoration>
        {children}
      </Link>
    )}
  </TableCell>
);

LinkedTableRowCell.defaultProps = {
  children: null,
  firstItemWithCheckbox: false,
};

LinkedTableRowCell.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.string]),
  url: PropTypes.string.isRequired,
  firstItemWithCheckbox: PropTypes.bool,
};

export { LinkedTableRowCell };
