import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { Breadcrumbs } from "@mui/joy";
import { FilesList } from "components/ui/LibraryPopup/Components/FileUpload/FilesList";
import { separateAssets } from "components/ui/LibraryPopup/helpers/separateAssets";
import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";
import { Message } from "components/ui/Messages";
import { SearchField } from "components/ui/SearchField";
import { colors } from "config/theme/colors";
import { LIBRARY_ROOT_PATH } from "constants/api";
import { DOCUMENTS_FILE_TYPES } from "constants/defaults";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { NavigationStack } from "services/application/NavigationStack";
import styled from "styled-components";

import { GLOBAL_CONTENT } from "constants/content";
import { useService } from "hooks/useService";
import { Logger } from "services/application/Logger";
import { LibraryService } from "services/LibraryService";

import { getLibraryPathJSON } from "utils/library";
import { FoldersList } from "./FoldersList";

const MessageContainer = styled.div`
  padding: ${({ theme }) => theme.setSpacing(4)}px;
  padding-bottom: 0;
`;

const SearchContainer = styled.div`
  padding: ${({ theme }) => theme.setSpacing(6)}px;

  div:first-child {
    width: ${({ theme }) => theme.setSpacing(66)}px;
    height: auto;
  }

  label {
    z-index: 1;
  }
`;

const StyledSearch = styled(SearchField)`
  && {
    background-color: ${colors.white};
  }
`;

const InnerContainer = styled.div`
  width: 100%;
`;

const FilesContainer = styled.div`
  padding-top: ${({ theme }) => theme.setSpacing(6)}px;
  padding-left: ${({ theme }) => theme.setSpacing(6)}px;
`;

const ControlBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${colors.grey20};
  border-bottom: 1px solid ${colors.grey20};
  height: ${({ theme }) => theme.setSpacing(9)}px;
`;

const NavigationBar = styled.div`
  width: 50%;
  padding-left: ${({ theme }) => theme.setSpacing(6)}px;
  display: flex;
  align-items: center;
  user-select: none;
`;

const BackButton = styled.span`
  font-size: ${({ theme }) => theme.setSpacing(3)}px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
`;

const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const CurrentPath = styled.span`
  padding-left: 40%;
  white-space: nowrap;
`;

const navigationStack = new NavigationStack();

const LibrarySection = ({ allowedFileTypes, handleMiniatureClick, selectedAssets, fileUploadService }) => {
  const libraryService = useService(LibraryService);

  const currentOperatorCode = useSelector(state => state.auth.currentOperatorCode);
  const libraryPathJSON = getLibraryPathJSON(currentOperatorCode);

  const [libraryItems, setLibraryItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPath, setCurrentPath] = useState(
    libraryPathJSON?.operator === currentOperatorCode ? libraryPathJSON.path : LIBRARY_ROOT_PATH,
  );

  const [searchValue, setSearchValue] = useState("");
  const [invalidFilesError, setInvalidFilesError] = useState([]);
  const remoteUrlsAreAvailable = allowedFileTypes.some(type => DOCUMENTS_FILE_TYPES.includes(type));

  const fetchLibraryItems = async path => {
    try {
      setIsLoading(true);
      const items = await libraryService.getAllItemsFromDirectory(path);

      items.sort((a, b) => {
        if (a.name.localeCompare(b.name) < 0) {
          return -1;
        }
        if (a.name.localeCompare(b.name) > 0) {
          return 1;
        }
        return 0;
      });

      setLibraryItems(items);
    } catch (e) {
      Logger.debug(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = async query => {
    try {
      setIsLoading(true);
      const { items } = await libraryService.searchItems(query);

      setLibraryItems(items);
    } catch (e) {
      Logger.debug(e);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedSearch = useRef(debounce(query => handleSearch(query), 300));
  const handleBackButton = () => {
    const path = currentPath
      .split("/")
      .slice(0, -1)
      .join("/");

    localStorage.setItem("libraryPath", JSON.stringify({ operator: currentOperatorCode, path }));
    setCurrentPath(path);
  };

  const handleFolderClick = ({ path }) => {
    localStorage.setItem("libraryPath", JSON.stringify({ operator: currentOperatorCode, path }));
    setCurrentPath(path);
  };

  const handleFilesUpdate = filesArray => {
    return filesArray.map(file => {
      const active = selectedAssets.map(selected => selected.id).includes(file.id);

      return { ...file, active };
    });
  };

  const renderBackButton = () => {
    return (
      <NavigationBar>
        <BackButtonContainer role="button" tabIndex={0} onKeyDown={handleBackButton} onClick={handleBackButton}>
          <NavigateBeforeIcon />
          <BackButton>{GLOBAL_CONTENT.back}</BackButton>
        </BackButtonContainer>
        <CurrentPath>{navigationStack.path?.split("/").pop()}</CurrentPath>
      </NavigationBar>
    );
  };

  const libraryFileValidation = async () => {
    const { errors } = await fileUploadService.validateFiles(selectedAssets);

    setInvalidFilesError(errors);
  };

  useEffect(() => {
    fetchLibraryItems(currentPath);
  }, [currentPath]);

  useEffect(() => {
    if (searchValue) {
      debouncedSearch.current(searchValue);
    } else {
      fetchLibraryItems(currentPath);
    }
  }, [searchValue]);

  useEffect(() => {
    libraryFileValidation();
  }, [selectedAssets]);

  const { folders, files } = separateAssets(libraryItems);

  const updatedFiles = handleFilesUpdate(files).filter(node =>
    node.file ? allowedFileTypes.includes(node.file.mime_type) : remoteUrlsAreAvailable && node.remote_url,
  );
  const getBreadcrumbs = () => {
    const breadcrumbs = currentPath?.slice(0, -2).split("/");
    return breadcrumbs.slice(1);
  };

  const onClickBreadCrumb = breadcrumb => {
    const findIndex = getBreadcrumbs().findIndex(item => item === breadcrumb);
    const urlString = getBreadcrumbs()
      .slice(0, findIndex + 1)
      .join("/");
    localStorage.setItem("libraryPath", JSON.stringify({ operator: currentOperatorCode, path: "/" + urlString }));
    setCurrentPath("/" + urlString);
  };

  const isActiveBreadcrumb = breadcrumb => getBreadcrumbs()[getBreadcrumbs()?.length - 1] === breadcrumb;

  return (
    <InnerContainer data-testid="library-section">
      <SearchContainer>
        <StyledSearch onSearchChange={setSearchValue} InputProps={{ "data-testid": "library-section-search" }} />
      </SearchContainer>
      <ControlBar>
        {searchValue || LIBRARY_ROOT_PATH === currentPath ? null : renderBackButton()}{" "}
        {getBreadcrumbs()?.length > 1 && (
          <Breadcrumbs sx={{ paddingRight: 4 }} separator=">">
            {getBreadcrumbs().map(item => (
              <div
                style={{
                  fontSize: 14,
                  color: isActiveBreadcrumb(item) ? colors.brand : "#000",
                  cursor: "pointer",
                  textTransform: "capitalize",
                }}
                onClick={() => onClickBreadCrumb(item)}
              >
                {item}
              </div>
            ))}
          </Breadcrumbs>
        )}
      </ControlBar>
      {invalidFilesError.length > 0 &&
        invalidFilesError.map(error => (
          <MessageContainer key={error}>
            <Message type="error" onDelete={() => fileUploadService.resetValidation()} text={error} />
          </MessageContainer>
        ))}
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <FilesContainer>
          <FoldersList folders={folders} handleFolderClick={handleFolderClick} />
          <FilesList files={updatedFiles} handleMiniatureClick={handleMiniatureClick} selectable webSource />
        </FilesContainer>
      )}
    </InnerContainer>
  );
};

LibrarySection.propTypes = {
  handleMiniatureClick: PropTypes.func,
  allowedFileTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedAssets: PropTypes.arrayOf(PropTypes.shape()),
  fileUploadService: PropTypes.shape({
    validateFiles: PropTypes.func,
    getErrors: PropTypes.func,
    resetValidation: PropTypes.func,
  }).isRequired,
};

LibrarySection.defaultProps = {
  handleMiniatureClick: () => {},
  selectedAssets: [],
};

export { ControlBar, FilesContainer, LibrarySection, StyledSearch };

