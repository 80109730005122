import * as actionTypes from "store/operator/actionTypes";
import { createAction } from "utils/store";

export const getCurrentOperatorStart = createAction(actionTypes.GET_CURRENT_OPERATOR_DATA_START);
export const getCurrentOperatorSuccess = createAction(actionTypes.GET_CURRENT_OPERATOR_DATA_SUCCESS);
export const getCurrentOperatorFail = createAction(actionTypes.GET_CURRENT_OPERATOR_DATA_FAIL);

export const updateCompanyDataStart = createAction(actionTypes.UPDATE_COMPANY_DATA_START);
export const updateCompanyDataSuccess = createAction(actionTypes.UPDATE_COMPANY_DATA_SUCCESS);
export const updateCompanyDataFail = createAction(actionTypes.UPDATE_COMPANY_DATA_FAIL);

export const getIconsListStart = createAction(actionTypes.GET_ICONS_LIST_START);
export const getIconsListSuccess = createAction(actionTypes.GET_ICONS_LIST_SUCCESS);
export const getIconsListFail = createAction(actionTypes.GET_ICONS_LIST_FAIL);

export const switchCompanyPoisList = createAction(actionTypes.SWITCH_COMPANY_POIS_LIST);
export const changeCompanyTemperatureScale = createAction(actionTypes.CHANGE_COMPANY_TEMPERATURE_SCALE);
export const changeCompanyRefactorOptIn = createAction(actionTypes.CHANGE_COMPANY_REFACTOR_OPT_IN);
export const changeCompanyStoryboardView = createAction(actionTypes.CHANGE_COMPANY_STORYBOARD_VIEW);
export const changeCompanyName = createAction(actionTypes.CHANGE_COMPANY_NAME);
export const changeCompanyContactPerson = createAction(actionTypes.CHANGE_COMPANY_CONTACT_PERSON);
export const changeCompanyTelephone = createAction(actionTypes.CHANGE_COMPANY_TELEPHONE);
export const changeCompanyEmail = createAction(actionTypes.CHANGE_COMPANY_EMAIL);
export const changeCompanyNumberOfBookings = createAction(actionTypes.CHANGE_COMPANY_NUMBER_OF_BOOKINGS);
export const changeCompanyNumberOfPassengers = createAction(actionTypes.CHANGE_COMPANY_NUMBER_OF_PASSENGERS);
export const changeCompanyDescription = createAction(actionTypes.CHANGE_COMPANY_DESCRIPTION);
export const changeCompanyWebsite = createAction(actionTypes.CHANGE_COMPANY_WEBSITE);
export const changeCompanyLanguage = createAction(actionTypes.CHANGE_COMPANY_LANGUAGE);
export const changeCompanyAdditionalLanguages = createAction(actionTypes.CHANGE_COMPANY_ADDITIONAL_LANGUAGES);
