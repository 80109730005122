import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";
import { TableHead, TableRow, TableCell } from "@material-ui/core";
import ArrowRightAltOutlinedIcon from "@material-ui/icons/ArrowRightAltOutlined";

import { ActionTableCell } from "components/ui/Tables/_shared/ActionTableCell";
import { TableContext } from "components/ui/Tables/TableContext";
import { deviceType } from "utils/deviceDetect";
import { getTaleVarFromLS } from "./getTableVarFromLS";
import { Checkbox } from "../Forms";

const SortingArrow = styled(({ order, ...rest }) => <ArrowRightAltOutlinedIcon {...rest} />)`
  && {
    transform: rotate(${({ order }) => (order === "asc" ? `` : `-`)}90deg);
    transition: transform 0.15s ease;
    font-size: ${({ theme }) => theme.setSpacing(4)}px;
  }
`;

const SortingButton = styled.button`
  border: 0;
  color: ${({ theme }) => theme.colors.grey50};
  padding: 0;
  outline: none;
  background: none;
  font-family: "Montserrat";
  font-size: ${({ theme }) => theme.setSpacing(3)}px;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const FlexArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: ${({ align }) => align || "flex-start"};
`;

const Label = styled.span`
  font-size: 12px;
  white-space: nowrap;
`;

const TableHeader = ({ withActions, withCheckbox, actionsLabel: ActionsLabel, actionsProps, tableId, selectedItems, setSelectedItems, allPageCheckboxesSelected, setAllPageCheckboxesSelected }) => {
  const { headers, handleChangeOrder, sortingOrder, sortingBy, refactoredHandleChangeOrder } = useContext(TableContext);
  const { listOfTrips, tripsCount } = useSelector(state => state.trips);
  const noPadding = typeof ActionsLabel !== "string" && typeof ActionsLabel !== "undefined";
  const [anyPageCheckboxesSelected, setAnyPageCheckboxesSelected] = useState(false);

  useEffect(() => {
    const itemsIdOnCurrentPage = listOfTrips.map(item => item.vamoos_id);
    const found = selectedItems.vamoosIds.some(id => itemsIdOnCurrentPage.includes(id));
    setAnyPageCheckboxesSelected(found);
  }, [selectedItems, listOfTrips]);

  useEffect(() => {
    const ordering = getTaleVarFromLS("ordering", tableId);

    if (ordering) {
      if (tableId === "notifications") {
        refactoredHandleChangeOrder({ column: ordering.column, order: ordering.order }, tableId);
      } else {
        handleChangeOrder(ordering.column, tableId, ordering.order);
      }
    } else {
      if (tableId === "notifications") refactoredHandleChangeOrder({ column: "id", order: "desc" }, tableId);
    }
  }, []);

  const device = deviceType();

  const data = Array.isArray(headers) ? headers : headers?.[device] || headers?.all;

  const toggleCheck = value => {
    const itemsIdOnCurrentPage = listOfTrips.filter(item => item.is_active).map(item => item.vamoos_id);
    const allSelected = itemsIdOnCurrentPage.every(id => selectedItems.vamoosIds.includes(id));

    if (value && !allSelected) {
      const notSelectedItemsId = listOfTrips
        .filter(item => item.is_active)
        .filter(item => !selectedItems.vamoosIds.includes(item.vamoos_id))
        .map(item => item.vamoos_id);

      setSelectedItems(prevState => ({
        allItemsSelected: [...prevState.vamoosIds, ...notSelectedItemsId].length === tripsCount,
        vamoosIds: [...prevState.vamoosIds, ...notSelectedItemsId],
      }));

      setAllPageCheckboxesSelected(itemsIdOnCurrentPage.every(id => [...selectedItems.vamoosIds, ...notSelectedItemsId].includes(id)));
    } else {
      setSelectedItems(prevState => ({
        allItemsSelected: false,
        vamoosIds: prevState.vamoosIds.filter(id => !itemsIdOnCurrentPage.includes(id)),
      }));
      setAllPageCheckboxesSelected(false);
    }
  };

  return (
    <>
      <TableHead>
        <TableRow>
          {withCheckbox && (
            <>
              <ActionTableCell noPadding>
                  <Checkbox
                    checked={selectedItems.allItemsSelected || allPageCheckboxesSelected}
                    indeterminate={(selectedItems.allItemsSelected || allPageCheckboxesSelected)? false : anyPageCheckboxesSelected}
                    onChange={e => toggleCheck(e.target.checked)}
                    style={{ width: "30px", paddingLeft: "8px" }}
                  />
              </ActionTableCell>
            </>
          )}
          {data
            ?.filter(header => header.show)
            .map(({ label, key, show, sortable, align, ...props }, index) => (
              <TableCell key={label} {...props} style={{ paddingLeft: (withCheckbox && index === 0) ? "4px" : "" }}>
                {sortable && (
                  <FlexArea align={align}>
                    <SortingButton
                      type="button"
                      href="#"
                      onClick={() => {
                        if (tableId === "notifications") {
                          refactoredHandleChangeOrder({ column: key, order: sortingOrder === "asc" ? "desc" : "asc" }, tableId);
                        } else {
                          handleChangeOrder(key, tableId)
                        }
                      }}
                    >
                      {label}
                    </SortingButton>
                    {sortingBy === key && <SortingArrow order={sortingOrder} />}
                  </FlexArea>
                )}
                {!sortable && <Label>{label}</Label>}
              </TableCell>
            ))}
          {withActions && (
            <ActionTableCell noPadding={noPadding} align="right" {...actionsProps}>
              {ActionsLabel}
            </ActionTableCell>
          )}
        </TableRow>
      </TableHead>
    </>
  );
};
TableHeader.defaultProps = {
  withActions: false,
  actionsLabel: "",
  actionsProps: {},
  withCheckbox: false,
  allPageCheckboxesSelected: false,
  selectedItems: { allItemsSelected: false, vamoosIds: [] },
  setSelectedItems: () => {},
  setAllPageCheckboxesSelected: () => {},
};

TableHeader.propTypes = {
  withActions: PropTypes.bool,
  actionsLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.element]),
  actionsProps: PropTypes.shape(),
  withCheckbox: PropTypes.bool,
  allPageCheckboxesSelected: PropTypes.bool,
  selectedItems: PropTypes.shape(),
  setSelectedItems: PropTypes.func,
  setAllPageCheckboxesSelected: PropTypes.func,
};

export { TableHeader, SortingArrow, FlexArea, SortingButton };
