import styled, { css } from "styled-components";
import { Spinner } from "components/ui/Spinner/Spinner";
import { getMediaPx } from "helpers";
import { responsiveConfig } from "../../../config/responsive";

export const StyledWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${({ theme, whiteBg }) => (whiteBg ? theme.colors.white : theme.colors.withOpacity(theme.colors.grey10, 0.5))};

  // To remove white background below 100vh in mobile view
  ${({ isMobile }) =>
    !isMobile &&
    css`
      max-height: 100vh;
    `}
`;

export const MainContentWrapper = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: ${({ theme, noMargin, hasStickyPanel }) => (noMargin || hasStickyPanel ? 0 : 20)}px;
  position: relative;
  margin-top: ${({ hasStickyPanel }) => (hasStickyPanel ? "40px" : 0)};
`;

export const ContentWrapper = styled.div``;

export const LoadingScreenWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background: ${({ theme }) => theme.colors.withOpacity(theme.colors.white, 0.9)};
  transition: all 0.25s ease-in-out;
  position: fixed;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const StyledSpinner = styled(Spinner)`
  && {
    margin-bottom: ${({ theme }) => theme.setSpacing(6)}px;
  }
`;

// PanelTopBar

export const ToolbarSection = styled.div`
  && {
    width: ${({ width }) => `${width}px` || "auto"};
    margin-left: ${({ theme, marginLeft }) => theme.setSpacing(marginLeft) || 0}px;
    margin-right: ${({ theme, marginRight }) => theme.setSpacing(marginRight) || 0}px;
    margin-top: ${({ theme, marginTop }) => theme.setSpacing(marginTop) || 0}px;
    margin-bottom: ${({ theme, marginBottom }) => theme.setSpacing(marginBottom) || 0}px;
    height: 100%;
    display: flex;
    align-items: center;
    @media (max-width: ${p => getMediaPx(p, "md")}px) {
      width: auto !important;
      max-width: 35vw;
    }
  }
`;

export const MainNavigationArea = styled.div`
  height: inherit;
  width: auto;
  display: flex;

  @media (max-width: 1040px) {
    a {
      padding: 0 ${({ theme }) => theme.setSpacing(3)}px !important;
    }
  }

  @media (max-width: ${responsiveConfig.mediaPx.laptop}px) {
    display: none;
  }

  a {
    white-space: nowrap;
  }
`;

export const ActionsArea = styled.div`
  height: inherit;
  width: auto;
  display: flex;
  align-items: center;
`;

export const MessageWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  max-width: 1200px;

  @media (max-width: 1330px) {
    margin: auto ${({ theme }) => theme.setSpacing(12)}px;
  }
`;
