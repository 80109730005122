export class LocalisationService {
  /**
   *
   * @param {Object} language
   */
  constructor(language) {
    this.language = language;
  }

  /**
   * Method for get localised property of resource
   *
   * @param {*} resource
   * @param {Object} language
   * @param {boolean} language.isDefault
   *
   * @returns {*}
   */
  localise(resource, property) {
    const { localisation } = resource;
    if (this.language.isDefault) {
      return resource[property];
    }

    return localisation?.[this.language.code]?.[property];
  }

  isPropertyLocalised(resource, property) {
    const { localisation } = resource;

    if (this.language.isDefault) {
      return true;
    }

    return !!localisation?.[this.language.code]?.[property];
  }

  set(resource, setter) {
    if (this.language.isDefault) {
      return setter(resource);
    }

    return {
      ...resource,
      localisation: {
        ...resource.localisation,
        [this.language.code]: setter(resource.localisation[this.language.code] || {}),
      },
    };
  }
}
