import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";

import { getIconsListStart, updateCompanyDataStart } from "store/operator/actions";

import { ContentWrapper, FieldValueWrapper, Paper, Header } from "feature/panel/Settings/_shared/styledComponents";
import { FieldChangeModal } from "feature/panel/Settings/_shared/FieldChangeModal";
import { Content } from "components/ui/Content";
import { Input, Select } from "components/ui/Forms";
import { ButtonsList } from "feature/panel/MenusLabels/ButtonsListCategory/ButtonsList/ButtonsList";
import { IconImage } from "components/ui/Icons";
import { LanguageSelector } from "components/_shared/LanguageSelector/LanguageSelector";
import { LanguageContext } from "components/_shared/LanguageSelector/LanguageContext";

import { LocalisationService } from "services/LocalisationService";
import { usePopupHandlers } from "hooks/usePopupHandlers";

import { LabelMapper } from "mappers/LabelMapper";

const ButtonsListCategory = () => {
  const dispatch = useDispatch();
  const { currentLanguage } = useContext(LanguageContext);

  const editModal = usePopupHandlers();

  const [localCurrentLanguage, setLocalCurrentLanguage] = useState(currentLanguage);
  const { availableIcons, currentOperator } = useSelector(state => state.operator);
  const defaultLanguageCode = useSelector(({ operator }) => operator.currentOperator.defaultLanguageCode);
  const navigationIcons = availableIcons.filter(icon => icon.section === "navigation");

  const localisationService = new LocalisationService(localCurrentLanguage);

  const label = localisationService.localise(editModal.props, "label");

  const getIconsList = () => {
    dispatch(getIconsListStart());
  };

  const switchLanguage = (lang) => {
    setLocalCurrentLanguage({
      ...lang,
      isDefault: lang.code === defaultLanguageCode
    });
  }

  const onEditClick = field => {
    editModal.open(field);
    switchLanguage(currentLanguage);
  };

  const editConfirm = () => {
    const labelMapper = new LabelMapper();

    const newLabelsList = currentOperator.labels.map(
      operatorLabel => labelMapper.fromDomainToDto(operatorLabel.screen === editModal.props.screen ? editModal.props : operatorLabel)
    );
    const payload = { fieldName: "labels", value: newLabelsList };
    dispatch(updateCompanyDataStart(payload));

    editModal.close();
  };

  const onChange = ({ target }) => {
    if(target.name === "iconId") {
      editModal.setProps({
        ...editModal.props,
        iconId: target.value,
      });
    } else {
      editModal.setProps(
        localisationService.set(editModal.props, (button) => ({
          ...button,
          [target.name]: target.name === "label" ? target.value.substring(0, 64) : target.value,
        }))
      );
    }
  };

  useEffect(getIconsList, []);

  useEffect(() => {
    switchLanguage(currentLanguage);
  }, [currentLanguage])

  return (
    <Paper>
      <ContentWrapper>
        <Header> Buttons</Header>
        <ButtonsList onEditClick={onEditClick} />
      </ContentWrapper>
      <FieldChangeModal
        open={editModal.isOpen}
        label={editModal.props.label || ""}
        onCancel={editModal.close}
        onConfirm={editConfirm}
      >
        <Content>
          <LanguageSelector currentLanguage={localCurrentLanguage} onChange={switchLanguage} />
        </Content>
        <Content>
          <Input
            label="Label name"
            name="label"
            value={label || ""}
            labelShrink={localCurrentLanguage.isDefault ? undefined : (editModal.props.label || undefined)}
            placeholder={localCurrentLanguage.isDefault ? "" : editModal.props.label}
            onChange={onChange}
            maxLength={64}
            fullWidth
          />
        </Content>
        <Select label="Icon" name="iconId" value={editModal.props.iconId || ""} onChange={onChange} fullWidth>
          {navigationIcons.map(icon => (
            <MenuItem key={icon.id} value={icon.id}>
              <FieldValueWrapper>
                <IconImage src={icon.url} darkened />
                <span>{icon.name}</span>
              </FieldValueWrapper>
            </MenuItem>
          ))}
        </Select>
      </FieldChangeModal>
    </Paper>
  );
};

export { ButtonsListCategory };
