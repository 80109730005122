import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Logotype } from "components/templates/_shared/Logotype/Logotype";
import { TopBar } from "components/templates/_shared/TopBar/TopBar";

const StyledWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.white};
`;

const ContentWrapper = styled.div`
  margin-top: ${({ theme }) => theme.setSpacing(16)}px;
  padding: ${({ theme }) => theme.setSpacing(10)}px;
  width: 100%;
  min-height: calc(100vh - ${({ theme }) => theme.setSpacing(16)}px);
`;

const ExceptionsTemplate = ({ children }) => {
  return (
    <StyledWrapper>
      <TopBar>
        <Logotype />
      </TopBar>
      <ContentWrapper>{children}</ContentWrapper>
    </StyledWrapper>
  );
};

ExceptionsTemplate.defaultProps = {
  children: null,
};

ExceptionsTemplate.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.object]),
};

export { ExceptionsTemplate, StyledWrapper, ContentWrapper };
