import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";

import { LeadText } from "components/ui/Typography/Typography";
import { Input, Textarea } from "components/ui/Forms";
import { Content } from "components/ui/Content";

import { UserIdField } from "feature/panel/_shared/UserIdField/UserIdField";

import { InspirationGallery } from "feature/panel/Inspirations/_shared/InspirationGallery";
import { useManageInspirationContext } from "feature/panel/Inspirations/_shared/ManageInspirationContext";

import { isString } from "contracts/types";
import { clearErrorsFor } from "store/app/actions";
import { INSPIRATIONS_LABEL } from "constants/content";

const FormWrapper = styled(Content)`
  padding-bottom: ${({ theme }) => theme.setSpacing(6)}px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey10};
`;

const ManagerWrapper = styled.div`
  margin: auto;
  max-width: 1280px;
`;

const ManageInspiration = ({ errors, canEdit }) => {
  const {
    reference_code,
    operator_code,
    name,
    contact_email,
    contact_phone,
    notification_text,
    setValueFor,
  } = useManageInspirationContext();

  const dispatch = useDispatch();

  const handleInputChange = ({ target, shouldTouchTheForm = true }) => {
    const { name: fieldName, value } = target;
    setValueFor(fieldName, value, shouldTouchTheForm);
  };

  const clearErrors = () => {
    return () => {
      dispatch(clearErrorsFor("inspirations"));
    };
  };

  useEffect(clearErrors, []);

  return (
    <ManagerWrapper>
      <FormWrapper margin={10}>
        <Content margin={9}>
          <LeadText>{INSPIRATIONS_LABEL.generalInfo}</LeadText>
        </Content>
        <Grid container spacing={10}>
          <Grid xs={12} item>
            <Input
              name="name"
              onChange={handleInputChange}
              value={name}
              label={INSPIRATIONS_LABEL.labelRequired}
              error={!!errors.name}
              helperText={errors.name}
              disabled={!canEdit}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <UserIdField
              name="operator_code"
              value={operator_code}
              onChange={handleInputChange}
              label={INSPIRATIONS_LABEL.userID}
              disabled={!canEdit}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Input
              name="reference_code"
              onChange={handleInputChange}
              value={reference_code}
              label={INSPIRATIONS_LABEL.passcode}
              error={isString(errors) ? errors.length > 0 : !!errors.reference_code}
              helperText={isString(errors) ? errors : errors.reference_code}
              disabled={!canEdit}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Input
              type="email"
              name="contact_email"
              onChange={handleInputChange}
              value={contact_email}
              label={INSPIRATIONS_LABEL.contactEmail}
              error={!!errors.contact_email}
              helperText={errors.contact_email}
              disabled={!canEdit}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Input
              name="contact_phone"
              onChange={handleInputChange}
              value={contact_phone}
              label={INSPIRATIONS_LABEL.phone}
              error={!!errors.contact_phone}
              helperText={errors.contact_phone}
              disabled={!canEdit}
            />
          </Grid>
          <Grid item xs={12}>
            <Textarea
              name="notification_text"
              onChange={handleInputChange}
              value={notification_text}
              label={INSPIRATIONS_LABEL.notification}
              rows={4}
              helperText={INSPIRATIONS_LABEL.notificationHelperMsg}
              disabled={!canEdit}
            />
          </Grid>
        </Grid>
      </FormWrapper>
      <InspirationGallery canEdit={canEdit} errors={errors} />
    </ManagerWrapper>
  );
};

ManageInspiration.propTypes = {
  errors: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]).isRequired,
  canEdit: PropTypes.bool.isRequired,
};

export { ManageInspiration, FormWrapper, ManagerWrapper };
