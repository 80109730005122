export const isValidUrl = url => {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
};

const validateAndReplaceHref = html => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");

  const anchors = doc.querySelectorAll("a");
  anchors.forEach(anchor => {
    const href = anchor.getAttribute("href");
    if (href && !isValidUrl(href)) {
      anchor.setAttribute("href", "/not-found");
    }
  });

  return doc.documentElement.outerHTML;
};

export const createMarkupForPreview = content => ({ __html: content ? validateAndReplaceHref(content) : "" });
