import React, { useContext } from "react";
import { useHref, useLinkClickHandler, matchPath } from "react-router-dom";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";

import { tryRouteChangeStart } from "store/app/actions";
import { PromptContext } from "../CustomPrompt/CustomPrompt";

const StyledLink = styled.button``;

const Link = React.forwardRef(({
  onClick,
  replace = false,
  state,
  target,
  to,
  ...rest
}, ref) => {
  const dispatch = useDispatch();

  const { showPrompt, enabled, allowed } = useContext(PromptContext);

  const href = useHref(to);
  const handleClick = useLinkClickHandler(to, {
    replace,
    state,
    target
  });

  const handleRedirectTry = () => dispatch(tryRouteChangeStart(href));

  const extendedHandler = (event) => {
    if(enabled && !allowed.some(path => !!matchPath(path, href))) {
      showPrompt({
        to,
        replace,
        state,
        target
      });
    } else  {
      handleClick(event);
      handleRedirectTry();
    }
    event.preventDefault();
  }

  return (
    <StyledLink 
      as="a"
      {...rest}
      href={href}
      onClick={event => {
        if (!event.defaultPrevented) {
          extendedHandler(event);
        }
      }}
      ref={ref}
      target={target}
    />
  );
});

Link.defaultProps = {
  onClick: null,
  replace: null,
  state: null,
  target: null,
}

Link.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  onClick: PropTypes.func,
  replace: PropTypes.bool,
  state: PropTypes.shape({}),
  target: PropTypes.string,
};

export { Link };
