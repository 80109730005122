import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormControlLabel } from "@material-ui/core";
import { useSelector } from "react-redux";

import { SortableListItem } from "components/ui/Lists";
import { Checkbox } from "components/ui/Forms/Controls";
import { colors } from "config/theme/colors";

import { TopFourContext } from "./TopFourContext";

const StyledFormControlLabel = styled(FormControlLabel)`
  &&& {
    && {
      .MuiTypography-body1 {
        font-family: Montserrat !important;
        letter-spacing: 0;
      }
    }
  }
`;

const repeatedGroupsIds = [32, 31];

const FeaturesListItem = ({ feature, index, disabled }) => {
  const { toggleCheck, isFeatureDisabled, isFeatureChecked } = useContext(TopFourContext);

  const { id, name, featureGroupId } = feature;
  const featureGroupName = useSelector(state => state.stays.features.find(item => item.id === featureGroupId))?.name;

  return (
    <SortableListItem key={id} index={index} destination="popup" disabled={disabled}>
      <StyledFormControlLabel
        label={
          repeatedGroupsIds.includes(featureGroupId) ? (
            <div>
              {name} <span style={{ color: colors.grey30 }}>({featureGroupName})</span>
            </div>
          ) : (
            name
          )
        }
        control={
          <Checkbox
            checked={isFeatureChecked(id)}
            onChange={e => toggleCheck(id, e.target.checked)}
            disabled={isFeatureDisabled(id) || disabled}
          />
        }
      />
    </SortableListItem>
  );
};

FeaturesListItem.defaultProps = {
  disabled: false,
};

FeaturesListItem.propTypes = {
  index: PropTypes.number.isRequired,
  feature: PropTypes.shape().isRequired,
  disabled: PropTypes.bool,
};

export { FeaturesListItem };
