// @ts-check

import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useParams, Outlet } from "react-router-dom";
import { HistoryOutlined } from "@material-ui/icons";

import { usePrompt } from "components/ui/CustomPrompt/CustomPrompt";
import { PanelTemplate } from "components/templates/Panel/PanelTemplate";
import { ContextBarTitle } from "components/templates/Panel/ContextBarTitle";
import { BackHistoryButton, CancelHistoryButton, PrimaryButton } from "components/ui/Buttons";
import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";

import { updateStayOriginalIndexes } from "feature/panel/Stays/_shared/helpers";

import {
  PANEL_STAYS_CREATE_CONTENT_PATH,
  PANEL_STAYS_CREATE_PATH,
  PANEL_STAYS_EDIT_CONTENT_PATH,
  PANEL_STAYS_EDIT_PATH,
  PANEL_STAYS_PATH,
} from "constants/routes";

import { useTitle } from "hooks/useTitle";
import { usePermissionsService } from "hooks/usePermissionsService";

import { getIconsListStart } from "store/operator/actions";
import { checkUserIdTaken } from "store/stays/saga";
import {
  clearStayWizard,
  fillDefaultVariantWizard,
  fillStayWizard,
  initStayWizardUserId,
  stayWizardSetErrors,
} from "store/stayWizard/actions";
import { createOrUpdateStayValidationFail } from "store/stays/actions";

import { createDefaultGpsNotifications, createDefaultTimedNotifications, filterOutExistingNotifications } from "utils/notifications";
import { isObjectEmpty } from "utils/object";
import { saveStayDisabledValidate } from "utils/validation";
import { GLOBAL_CONTENT, NOTIFICATION_TYPES, PAGE_TITLE_LABEL, SITE_LEAVE_WARNING_MESSAGES, STAY_WIZARD_CONTENT } from "constants/content";
import { setUrl } from "utils/url";
import {
  tryRouteChangeStart,
  changeOperatorStart,
  pushErrorNotification,
  showSavingCover,
  hideSavingCover,
  pushSuccessNotification,
} from "store/app/actions";
import { getCurrentUserStart, updateOperators } from "store/auth/actions";

import notifications from "constants/notifications";

import { PERMISSIONS } from "constants/permissions";
import { LanguageContext } from "components/_shared/LanguageSelector/LanguageContext";
import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { useListOfLanguages } from "hooks/useListOfLanguages";

import { StayService } from "services/domain/StayService";
import { StayVariantService } from "services/domain/StayVariantService";
import { NotificationsService } from "services/NotificationsService";

import { Stay } from "domain/Stay";

import { useService } from "hooks/useService";
import { usePopupHandlers } from "hooks/usePopupHandlers";
import { Logger } from "services/application/Logger";
import { LocalisationService } from "services/LocalisationService";
import { EventBus } from "services/application/EventBus";

import { useExtendedNavigate } from "hooks/useExtendedNavigate";
import { StayHasBeenCopied } from "events/StayHasBeenCopied";

import { setLock } from "feature/panel/_shared/helpers";
import useResourceLock, { generateLockId } from "hooks/useResourceLock";

import { StaysWizardNavigation } from "./StaysWizardNavigation/StaysWizardNavigation";
import { CopyingStayModal } from "../CopyingStayModal/CopyingStayModal";
import { StayValidator } from "../../../../services/domain/StayValidator";
import { StaysWizardActions } from "./StaysWizardActions/StaysWizardActions";
import { transformNotificationTemplateData } from "../../../../domain/Trip";
import { populateNullableNotifications } from "../../Itinerary/pages/Trips/Notifications/helpers";

export const useStayLanguages = () => {
  const allLanguages = useListOfLanguages();

  return useSelector(
    ({ stayWizard, operator }) => {
      try {
        const languages = [...(operator.currentOperator?.additionalLanguageCodes || []), operator.currentOperator.defaultLanguageCode];

        return languages.map(languageCode => ({
          ...allLanguages.find(({ code }) => languageCode === code),
          isActive: stayWizard.defaultVariant.variants.find(variant => variant.language === languageCode)?.isActive || false,
          isMaster: stayWizard.defaultVariant.variants.find(variant => variant.language === languageCode)?.isMaster || false,
          variantExists: stayWizard.defaultVariant.variants.find(variant => variant.language === languageCode),
          isDefault:
            stayWizard.defaultVariant.variants.length === 0
              ? languageCode === operator.currentOperator.defaultLanguageCode
              : stayWizard.defaultVariant.variants.find(variant => variant.language === languageCode)?.isDefault || false,
        }));
      } catch (e) {
        Logger.debug(e);
        return [];
      }
    },
    (left, right) => {
      return JSON.stringify(left) === JSON.stringify(right);
    },
  );
};

const StaysWizard = () => {
  const params = useParams();
  const { operator_code: userId } = params;
  const content = params["*"]
    .replace("/edit", "/")
    .split("/")
    .pop();

  const allLanguages = useListOfLanguages();

  const store = useStore();

  const isEditMode = userId !== undefined;
  const dispatch = useDispatch();

  /** @type {StayService} */
  const stayService = useService(StayService);

  /** @type {StayVariantService} */
  const stayVariantService = useService(StayVariantService);

  /** @type {NotificationsService} */
  const notificationService = useService(NotificationsService);

  const navigate = useExtendedNavigate();
  const permissionsService = usePermissionsService();

  const { currentLanguage, setLanguage } = useContext(LanguageContext);

  const copyModal = usePopupHandlers();

  const [originalStay, setOriginalStay] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [showCanLocaliseModal, setShowCanLocaliseModal] = useState(false);
  const [modal, setModal] = useState(null);
  // Locks state
  const [lockOwner, setLockOwner] = useState(null);
  const [lockMeta, setLockMeta] = useState(null);

  const operatorDefaultLanguageCode = useSelector(({ operator }) => operator.currentOperator.defaultLanguageCode);
  const isMasterStayActive = useSelector(({ stayWizard }) => stayWizard.defaultVariant.isActive);

  const wizardForm = useSelector(({ stayWizard }) => stayWizard.form);
  const vamoosId = useSelector(({ stayWizard }) => stayWizard.form.vamoosId);
  const isWiped = useSelector(({ stayWizard }) => stayWizard.form.isWiped);
  const departureDate = useSelector(({ stayWizard }) => stayWizard.form.departureDate);

  const isStayFormTouched = useSelector(({ stayWizard }) => stayWizard.touched);

  const { currentOperatorCode, operators, user } = useSelector(state => state.auth);
  const isOperatorCodeUsed = useSelector(state => state.auth.operators.some(({ usedCodes }) => usedCodes.includes(currentOperatorCode)));
  const errors = useSelector(state => state.stays.errors);
  const stayDefaultUserId = useSelector(({ stayWizard }) => stayWizard.defaultVariant.userId);
  const hasLanguageVariants = useSelector(({ stayWizard }) => !!stayWizard.defaultVariant.variants.length);

  const stayLanguages = useStayLanguages();

  const isStayDefaultLanguage = useSelector(({ stayWizard }) =>
    stayWizard.defaultVariant.vamoosId && stayWizard.defaultVariant.language
      ? stayWizard.defaultVariant.language === currentLanguage.code
      : true,
  );

  const stayDefaultLanguage = useSelector(({ stayWizard }) =>
    stayWizard.defaultVariant.vamoosId ? stayWizard.defaultVariant.language : currentLanguage.code,
  );

  const pageTitle = isEditMode ? PAGE_TITLE_LABEL.editStay(stayDefaultUserId) : PAGE_TITLE_LABEL.createStay;

  const defaultLanguageName = allLanguages.find(({ code }) => operatorDefaultLanguageCode === code)?.name;

  useTitle(pageTitle);

  usePrompt(SITE_LEAVE_WARNING_MESSAGES.createTitle(SITE_LEAVE_WARNING_MESSAGES.contexts.vamoosEditor), isStayFormTouched, [
    PANEL_STAYS_CREATE_PATH,
    PANEL_STAYS_CREATE_CONTENT_PATH,
    PANEL_STAYS_EDIT_PATH,
    PANEL_STAYS_EDIT_CONTENT_PATH,
  ]);

  const canEditItinerary = permissionsService.can(
    PERMISSIONS.actions.update,
    PERMISSIONS.sections.vamoosList,
    isEditMode ? wizardForm.vamoosId : null,
  );

  const canReadCurrentItinerary = isEditMode
    ? isLoading ||
      !wizardForm?.vamoosId ||
      (!isLoading && permissionsService.can(PERMISSIONS.actions.read, PERMISSIONS.sections.vamoosList, wizardForm?.vamoosId))
    : true;

  const canCreateItinerary = permissionsService.can(
    PERMISSIONS.actions.create,
    PERMISSIONS.sections.vamoosList,
    PERMISSIONS.resources.default,
  );

  const saveStayDisabled = saveStayDisabledValidate(userId, !!errors?.operator_code, isStayFormTouched);

  /**
   * @todo Could be extracted to service
   * @param {*} stay
   * @param {*} templates
   * @returns
   */
  const createNewTimedNotificationsFromStay = (stay, templates) => {
    const listOfNewTimedNotifications = filterOutExistingNotifications(templates, stay.notifications.timed.items);
    return createDefaultTimedNotifications(listOfNewTimedNotifications);
  };

  /**
   * @todo could be extracted to service-
   * @param {*} stay
   * @param {*} templates
   * @returns
   */
  const createNewGpsNotificationsFromStay = (stay, templates) => {
    const listOfNewGpsNotifications = filterOutExistingNotifications(templates, stay.notifications.gps.items);
    return createDefaultGpsNotifications(listOfNewGpsNotifications, departureDate);
  };

  /**
   * @todo Could be extracted to service
   * @param {*} stay
   * @param {*} templates
   * @returns
   */
  const createNotifications = (stay, templates) => {
    const localisationService = new LocalisationService({
      code: stay.language,
      isDefault: operatorDefaultLanguageCode === stay.language,
    });

    const localisedTemplates = templates.map(template => ({
      ...template,
      content: localisationService.isPropertyLocalised(template, "content")
        ? localisationService.localise(template, "content")
        : template.content,
      url: localisationService.isPropertyLocalised(template, "url") ? localisationService.localise(template, "url") : template.url,
    }));

    const timedNotifications = createNewTimedNotificationsFromStay(
      stay,
      localisedTemplates.filter(notification => notification.type === NOTIFICATION_TYPES.timed),
    );

    const gpsNotifications = createNewGpsNotificationsFromStay(
      stay,
      localisedTemplates.filter(notification => notification.type === NOTIFICATION_TYPES.gps),
    );

    const notificationsFromPayload = [...stay.notifications.timed.items, ...stay.notifications.gps.items];
    const finalNotificationList = [...notificationsFromPayload, ...timedNotifications, ...gpsNotifications];

    return {
      timed: {
        items: finalNotificationList.filter(notification => notification.type === NOTIFICATION_TYPES.timed),
      },
      gps: {
        items: finalNotificationList.filter(notification => notification.type === NOTIFICATION_TYPES.gps),
      },
    };
  };

  const createNotificationsSwitchEditorSupport = (stay, notificationTemplates, languageCode) => {
    const populatedNotifications = populateNullableNotifications(notificationTemplates, [...stay?.notifications.timed.items, ...stay?.notifications.gps.items], languageCode);
    stay.notifications = {
      timed: {
        items: populatedNotifications.filter(notification => notification.type === NOTIFICATION_TYPES.timed),
      },
      gps: {
        items: populatedNotifications.filter(notification => notification.type === NOTIFICATION_TYPES.gps),
      },
    }
    const templatesToInclude = notificationTemplates.filter(template => ![...stay?.notifications.timed.items, ...stay?.notifications.gps.items].some(notification => notification.template_id === template.id));

    return {
      timed: {
        ...stay.notifications.timed,
        items: [
          ...stay.notifications.timed.items,
          ...templatesToInclude.filter(notification => notification.type === NOTIFICATION_TYPES.timed)
        ],
      },
      gps: {
        ...stay.notifications.gps,
        items: [
          ...stay.notifications.gps.items,
          ...templatesToInclude.filter(notification => notification.type === NOTIFICATION_TYPES.gps)
        ],
      },
    };
  };

  /**
   * @param {?string} code
   */
  const getStayVariantDetails = async (stayUserId, code = null) => {
    let variants = [];

    /** @type {import("domain/Stay").Stay} */
    let masterStay = new Stay();

    /** @type {import("domain/Stay").Stay} */
    let variant = new Stay();

    /** @type {object} */
    let stayMasterLanguage = null;

    dispatch(stayWizardSetErrors({}));

    const notificationTemplates = await notificationService.getAllNotifications();
    const transformedTemplates = transformNotificationTemplateData(notificationTemplates, code || currentLanguage.code, true);

    if (stayUserId) {
      Logger.debug("Master stay fetching...");
      masterStay = await stayService.getStay(stayUserId);

      if (masterStay === null) {
        dispatch(pushErrorNotification(STAY_WIZARD_CONTENT.fetchingError));
        setIsLoading(false);
        return;
      }

      masterStay.notifications = createNotificationsSwitchEditorSupport(masterStay, transformedTemplates, (code || currentLanguage.code));
      // masterStay.notifications = createNotifications(masterStay, notificationTemplates);
      variants = await stayService.getVariantsStatus(stayUserId, masterStay.variants);

      masterStay.variants = variants;

      dispatch(fillDefaultVariantWizard(masterStay));

      stayMasterLanguage = allLanguages.find(lang => lang.code === masterStay?.language);

      if (!originalStay) {
        setLanguage(
          stayMasterLanguage || {
            code: operatorDefaultLanguageCode,
            isDefault: true,
            isMaster: true,
          },
        );
      }

      if (code !== null && code !== masterStay.language) {
        Logger.debug(`Given variant (${code}) fetching...`);
        variant = await stayVariantService.getStayVariant(stayUserId, code);

        if (variant === null) {
          if (variants.length === 0) {
            variant = masterStay;
          } else {
            variant = new Stay(stayUserId, vamoosId);
            variant.isActive = false;
            variant.language = code;
            variant.variants = variants;
          }
        }
      } else {
        variant = masterStay;
      }
    } else {
      variant = new Stay();
      variant.language = code;
    }

    variant.notifications = createNotificationsSwitchEditorSupport(variant, transformedTemplates, (code || currentLanguage.code));
    // variant.notifications = createNotifications(variant, transformedTemplates);

    setOriginalStay(variant);
    dispatch(fillStayWizard(variant));

    setIsLoading(false);

    return masterStay;
  };

  const tryRedirectToEditMode = () => {
    const url = setUrl(
      PANEL_STAYS_EDIT_CONTENT_PATH,
      { operator_code: wizardForm.userId, content: content === "create" ? "" : content },
      true,
    );
    dispatch(tryRouteChangeStart(url));

    navigate(url);
  };

  const handleSaveStay = async () => {
    try {
      const wizardErrors = StayValidator(wizardForm, isStayDefaultLanguage);

      dispatch(stayWizardSetErrors(wizardErrors));

      Logger.debug("Wizard errors:", wizardErrors);

      if (isObjectEmpty(wizardErrors)) {
        setIsSaving(true);
        updateStayOriginalIndexes(wizardForm); // @todo Should be encapsulated to service
        setOriginalStay(wizardForm);
        wizardForm.newOnly = !isEditMode;

        dispatch(showSavingCover());

        if (isStayDefaultLanguage || wizardForm.variants.length === 0) {
          await stayService.saveStayMasterVariant(wizardForm.userId, wizardForm, null, currentLanguage?.code);
        } else {
          await stayService.saveStayVariant(wizardForm.userId, wizardForm, null, currentLanguage?.code);
        }

        dispatch(pushSuccessNotification(notifications.resource("stays").update.success));

        if (isEditMode) {
          if (userId === wizardForm.userId) {
            await getStayVariantDetails(userId, currentLanguage?.code);
          } else {
            await getStayVariantDetails(wizardForm.userId, currentLanguage?.code);
            tryRedirectToEditMode();
          }
        } else {
          await getStayVariantDetails(wizardForm.userId, currentLanguage?.code);

          const operatorsWithUpdatedCodes = operators.map(item =>
            item.code === currentOperatorCode ? { ...item, used_codes: [...(item?.usedCodes || []), wizardForm.userId] } : item,
          );

          store.dispatch(updateOperators(operatorsWithUpdatedCodes));
          store.dispatch(getCurrentUserStart());

          tryRedirectToEditMode();
        }

        setIsSaving(false);
        dispatch(hideSavingCover());
      } else {
        dispatch(createOrUpdateStayValidationFail());
      }
    } catch (e) {
      setIsSaving(false);
      dispatch(hideSavingCover());
      if (e.response?.status === 403) {
        dispatch(createOrUpdateStayValidationFail());
      }

      if (e.response?.status === 400) {
        dispatch(createOrUpdateStayValidationFail());
      }

      Logger.debug(e);
    }
  };

  const handleEraseStayToggle = async () => {
    try {
      if (isMasterStayActive) {
        await stayService.deactivateStay(userId);
      } else {
        await stayService.activateStay(userId);
      }
      dispatch(pushSuccessNotification(notifications.resource("stays").update.success));
      getStayVariantDetails(userId, null);
    } catch (e) {
      dispatch(pushSuccessNotification(notifications.resource("stays").update.fail));
      Logger.debug(e);
    }
  };

  const triggerCopyStay = () => {
    if (copyModal.isOpen) {
      copyModal.close();
    } else {
      copyModal.open(originalStay);
    }
  };

  const backButtonHandler = () => {
    dispatch(tryRouteChangeStart(PANEL_STAYS_PATH));
    navigate(PANEL_STAYS_PATH);
  };

  const contextBar = {
    left: () =>
      isStayFormTouched ? (
        <CancelHistoryButton role="link" clickHandler={backButtonHandler} />
      ) : (
        <BackHistoryButton role="link" clickHandler={backButtonHandler} />
      ),
    middle: () => <ContextBarTitle title={pageTitle} />,
    right: () => {
      if (isWiped) {
        return null;
      }
      if (!isLoading && !isMasterStayActive) {
        return canEditItinerary ? (
          <PrimaryButton onClick={handleEraseStayToggle}>
            <HistoryOutlined />
            <span>{GLOBAL_CONTENT.restore}</span>
          </PrimaryButton>
        ) : null;
      }
      return (
        <StaysWizardActions
          onSave={handleSaveStay}
          showSpinner={isSaving}
          disabled={saveStayDisabled}
          triggerCopyVamoos={triggerCopyStay}
          hideUpdate={hasLanguageVariants && !stayLanguages.find(lang => lang.code === currentLanguage.code)?.variantExists}
          forceUpdateDisabled={isStayFormTouched}
          isEditMode={isEditMode}
          vamoosId={wizardForm.vamoosId}
          userId={userId}
          masterLanguage={stayDefaultLanguage}
        />
      );
    },
  };

  const wizardNavigation = (
    <StaysWizardNavigation
      onEraseConfirm={handleEraseStayToggle}
      isStayActive={isMasterStayActive}
      isStayDeleted={isWiped}
      isEditMode={isEditMode}
      isStayMaster={isStayDefaultLanguage}
    />
  );

  const onLanguageEdit = async ({ defaultLanguage, localLanguages }) => {
    setIsLoading(true);
    await stayService.setStayFallbackLanguage(userId, defaultLanguage.code);

    try {
      await stayService.setStayVariantsStatus(userId, localLanguages);
    } catch (e) {
      Logger.debug(e);
    } finally {
      await getStayVariantDetails(userId, currentLanguage.code);
      setIsLoading(false);
    }
  };

  const onLanguageSwitch = async lang => {
    if (isEditMode) {
      setIsLoading(true);
      await getStayVariantDetails(userId, lang.code);

      return true;
    }

    setShowCanLocaliseModal(true);

    return false;
  };

  const { requestLock, takeoverLock, isConnected } = useResourceLock({
    lockId: generateLockId("stay", vamoosId),
    onLockStatusChanged: async payload => {
      setLockMeta(payload.meta_data);
      setLockOwner(payload.own_lock);
      if (payload.meta_data.isTakeover) {
        await getStayVariantDetails(userId, currentLanguage?.code);
      }
    },
  });

  useEffect(() => {
    if (vamoosId && user && isConnected) {
      requestLock({
        owner_name: user.username,
        owner_id: user.id,
      });
    }
  }, [vamoosId, user, isConnected]);

  useEffect(() => {
    dispatch(getIconsListStart());
  }, []);

  useEffect(() => {
    if (!isEditMode) {
      setLanguage(stayLanguages.find(lang => lang.code === operatorDefaultLanguageCode));
    }
  }, [isEditMode]);

  /* If there is no stay with the same userId as operator code - set operator code as userId */
  useEffect(() => {
    checkUserIdTaken(currentOperatorCode, isOperatorCodeUsed, currentOperatorCode).then(isUserIdAlreadyTaken => {
      if (!isUserIdAlreadyTaken) {
        dispatch(initStayWizardUserId(currentOperatorCode));
      }
    });
  }, []);

  useEffect(() => {
    setOriginalStay(null);
  }, [userId]);

  useEffect(() => {
    const clearOriginalStay = event => {
      if (!event) return;
      const { detail } = event || {};
      const { id, copyToOtherOperator } = detail || {};

      if (!copyToOtherOperator) {
        dispatch(clearStayWizard());
        setOriginalStay(null);
        setIsLoading(true);
        getStayVariantDetails(id, null);
      }
    };

    EventBus.on(StayHasBeenCopied, clearOriginalStay);
    return () => {
      EventBus.remove(StayHasBeenCopied, clearOriginalStay);
    };
  }, []);

  useEffect(() => {
    const isCurrentOperatorCode = operators?.find(operator => operator.usedCodes.includes(userId))?.code === currentOperatorCode;

    if (isEditMode && !isCurrentOperatorCode) {
      const stayCurrentOperator = operators?.find(operator => {
        const usedCodes = operator.usedCodes.map(code => code.toLowerCase());

        return usedCodes.includes(userId.toLowerCase());
      });
      if (stayCurrentOperator) {
        dispatch(changeOperatorStart({ code: stayCurrentOperator?.code, isSelectedOperatorActive: stayCurrentOperator?.isActive }));
      } else {
        getStayVariantDetails(userId, null);
      }
    } else if (operatorDefaultLanguageCode) {
      getStayVariantDetails(userId, null);
    }

    return () => {
      dispatch(clearStayWizard());
    };
  }, [currentOperatorCode, operatorDefaultLanguageCode]);

  const handleTakeover = () =>
    takeoverLock({
      owner_name: user.username,
      owner_id: user.id,
      isTakeover: true,
    });

  const onCancel = () => {
    if (lockMeta) {
      navigate("/panel/stays");
    }
  };

  return (
    <PanelTemplate
      languageSelector
      editableLanguages={isEditMode}
      localLanguages={stayLanguages}
      onLanguagesChange={onLanguageEdit}
      onLanguageSwitch={onLanguageSwitch}
      contextBar={contextBar}
      hasPermission={isEditMode ? canReadCurrentItinerary : canCreateItinerary}
      navigation={wizardNavigation}
      canSwitchLanguage={isEditMode}
    >
      {!isEditMode || (isEditMode && !isLoading) ? <Outlet /> : <LoadingScreen />}
      {copyModal.isOpen && (
        <CopyingStayModal
          onCancel={triggerCopyStay}
          open={copyModal.isOpen}
          item={copyModal.props}
          hasUnsavedChanges={isStayFormTouched}
          onSave={handleSaveStay}
          showSpinner={isSaving}
        />
      )}
      <ConfirmationModal
        open={showCanLocaliseModal}
        title={SITE_LEAVE_WARNING_MESSAGES.switchLanguage(defaultLanguageName)}
        onConfirm={() => setShowCanLocaliseModal(false)}
      />
      <ConfirmationModal
        onConfirm={handleTakeover}
        onCancel={onCancel}
        title={`Stay is being edited by ${lockMeta?.owner_name}`}
        confirmLabel="Force take over"
        cancelLabel="Go back"
        open={!lockOwner && lockMeta?.owner_id}
      />
    </PanelTemplate>
  );
};

StaysWizard.defaultProps = {
  hasPermission: true,
};

export { StaysWizard };
