import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { H6, CaptionText } from "components/ui/Typography/Typography";
import { Content } from "components/ui/Content";
import { ERRORS } from "constants/content";
import { OperatorUser } from "feature/panel/Settings/Accounts/OperatorUser";

const RoleName = styled(H6)`
  && {
    margin-bottom: ${({ theme }) => theme.setSpacing(5)}px;
  }
`;

const RoleWithUsersElement = ({ role, users }) => {
  const usersList = users.map(user => (
    <OperatorUser key={user.id} user={user} />
  ));

  return (
    <Content margin={10}>
      <Content margin={5}>
        <RoleName>{role.name}</RoleName>
        <CaptionText>
          {role.description || ERRORS.noRoleDescription}
        </CaptionText>
      </Content>
      {usersList}
    </Content>
  );
};

RoleWithUsersElement.propTypes = {
  role: PropTypes.shape({}).isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export { RoleWithUsersElement, RoleName };
