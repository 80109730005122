import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";

import { usePrompt } from "components/ui/CustomPrompt/CustomPrompt";
import { PanelTemplate } from "components/templates/Panel/PanelTemplate";
import { BackHistoryButton, CancelHistoryButton } from "components/ui/Buttons";
import { ContextBarTitle } from "components/templates/Panel/ContextBarTitle";

import { useExtendedNavigate } from "hooks/useExtendedNavigate";
import { CUSTOM_APPS, SITE_LEAVE_WARNING_MESSAGES } from "constants/content";
import { PANEL_SETTINGS_CUSTOM_APPS } from "constants/routes";
import * as paths from "constants/routes";

import { setNotification, tryRouteChangeStart } from "store/app/actions";
import {
  checkBundleIdAvailableStart,
  clearCustomAppForm,
  saveNewCustomAppStart,
  setValueForBundleId,
  setIsFormTouched,
} from "store/customAppForm/actions";
import { SAVE_NEW_CUSTOM_APP_START, SAVE_NEW_CUSTOM_APP_SUCCESS } from "store/customAppForm/actionTypes";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@tanstack/react-query";

import notifications from "constants/notifications";
import { HttpClient } from "services/application/httpClient/httpClient";

import { getPayload } from "../_shared/payload";

import { Navigation } from "./Navigation";
import { CustomAppTypeSelectModal } from "../_shared/General/CustomAppTypeSelectModal";
import { CustomAppsFormActions } from "../_shared/CustomAppsFormActions";

const CreateCustomApp = ({ hasPermission }) => {
  const {
    control,
    handleSubmit,
    formState: { isDirty, errors, dirtyFields },
    watch,
    getValues,
    setError,
    reset,
  } = useForm({
    defaultValues: {
      default_language: "en",
      languages: [],
      name_override: "",
      copyright: "",
      support_url: "",
      privacy_policy_url: "",
      keywords: "",
      android_image: "",
      android_assignment_letter: "",
      developer_account_name_android: "",
      short_description_android: "",
      full_description_android: "",
      own_developer_account: "",
      developer_account_name: "",
      short_description: "",
      full_description: "",
      review_contact: "",
      screenshots: {
        operator_code: "",
        passcode: "",
        list_of_screenshots: "",
      },
      ios_assignment_letter: "",
    },
  });
  const { pathname } = useLocation();
  const navigate = useExtendedNavigate();
  const dispatch = useDispatch();

  const inProgress = useSelector(state => state.customAppForm.inProgress);
  const finished = useSelector(state => state.customAppForm.finished);
  const actionType = useSelector(state => state.customAppForm.actionType);
  const isFormTouched = useSelector(state => state.customAppForm.isFormTouched);
  const customAppForm = useSelector(state => state.customAppForm.customAppForm);
  const redirectPath = useSelector(state => state.app?.redirectPath);
  const defaultLanguage = useSelector(state => state.customAppForm.customAppForm.default_language);
  const [selectTypeModalOpen, setSelectTypeModalOpen] = useState(false);
  const { data: defaultInfo } = useQuery({ queryKey: ["/information/default"], refetchOnMount: true });
  const { bundleId } = customAppForm;

  usePrompt(SITE_LEAVE_WARNING_MESSAGES.createTitle(SITE_LEAVE_WARNING_MESSAGES.contexts.vamoosEditor), isFormTouched, [
    paths.PANEL_SETTINGS_CUSTOM_APPS_CREATE_GENERAL,
    paths.PANEL_SETTINGS_CUSTOM_APPS_CREATE_LOGO,
    paths.PANEL_SETTINGS_CUSTOM_APPS_CREATE_ELEMENTS,
    paths.PANEL_SETTINGS_CUSTOM_APPS_CREATE_LOGIN,
    paths.PANEL_SETTINGS_CUSTOM_APPS_CREATE_MENU,
    paths.PANEL_SETTINGS_CUSTOM_APPS_CREATE_TEXTS,
    paths.PANEL_SETTINGS_CUSTOM_APPS_CREATE_PREVIEW,
    paths.PANEL_SETTINGS_CUSTOM_APPS_CREATE_INFORMATION,
  ]);

  const backButtonHandler = () => {
    dispatch(tryRouteChangeStart(PANEL_SETTINGS_CUSTOM_APPS));
    navigate(PANEL_SETTINGS_CUSTOM_APPS);
  };

  const currentPage = pathname.split("/").pop();

  const onSave = values => {
    dispatch(
      saveNewCustomAppStart({
        ...customAppForm,
        currentPage,
        info: getPayload({ values, dirtyFields, bundle_id: bundleId }),
        dirtyFields,
      }),
    );
  };

  const contextBar = {
    left: () =>
      isFormTouched ? (
        <CancelHistoryButton role="link" clickHandler={backButtonHandler} />
      ) : (
        <BackHistoryButton role="link" clickHandler={backButtonHandler} />
      ),
    middle: () => <ContextBarTitle title={CUSTOM_APPS.createPageHeaders.title} />,
    right: () =>
      hasPermission ? (
        <CustomAppsFormActions
          isFormTouched={isFormTouched}
          appStatus="draft"
          onSave={handleSubmit(onSave)}
          saveSpinner={inProgress && actionType === SAVE_NEW_CUSTOM_APP_START}
          isSubmitButtonDisabled
          isDismissButtonDisabled
        />
      ) : null,
  };

  const checkCustomAppUniqueness = () => {
    if (customAppForm.name.length > 2) {
      const newBundleId = `com.vamoos.apps.${customAppForm.name.replaceAll(/[^A-Za-z0-9]/g, "").toLocaleLowerCase()}`;

      dispatch(setValueForBundleId(newBundleId));
      dispatch(checkBundleIdAvailableStart({ bundleId: newBundleId }));
    }
  };

  const init = () => {
    dispatch(clearCustomAppForm());
    setSelectTypeModalOpen(true);
    dispatch(setValueForBundleId("com.vamoos.apps"));

    return () => {
      dispatch(clearCustomAppForm());
    };
  };

  const handleModalClose = () => setSelectTypeModalOpen(false);
  const handleSavingCustomApp = () => {
    if (actionType === SAVE_NEW_CUSTOM_APP_START && finished) {
      dispatch(clearCustomAppForm());
    }
  };

  const handleSavedCustomApp = () => {
    if (actionType === SAVE_NEW_CUSTOM_APP_SUCCESS && redirectPath && !redirectPath.startsWith(paths.PANEL_SETTINGS_CUSTOM_APPS_CREATE)) {
      navigate(redirectPath);
    }
  };

  const selectTypeModal = <CustomAppTypeSelectModal openPopup={selectTypeModalOpen} onModalClose={handleModalClose} />;

  useEffect(init, []);
  useEffect(checkCustomAppUniqueness, [customAppForm.name]);
  useEffect(handleSavingCustomApp, [finished, actionType]);
  useEffect(handleSavedCustomApp, [redirectPath]);

  useEffect(() => {
    dispatch(setIsFormTouched(isDirty));
  }, [isDirty]);

  useEffect(() => {
    if (defaultInfo) {
      const {
        android: { full_description, short_description },
      } = defaultInfo;
      reset({
        ...defaultInfo,
        name_override: defaultInfo.name_override || { languages: { [defaultLanguage || "en"]: "App store display name" } },
        keywords: defaultInfo.keywords || [],
        short_description,
        full_description,
        assignment_letter: defaultInfo.documents?.ios_assignment_letter,
      });
    }
  }, [defaultInfo]);

  return (
    <PanelTemplate contextBar={contextBar} navigation={<Navigation />} hasPermission={hasPermission}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={10}>
          <Outlet context={{ control, watch, getValues, setError }} />
          {selectTypeModalOpen && selectTypeModal}
        </Grid>
      </Grid>
    </PanelTemplate>
  );
};

CreateCustomApp.propTypes = {
  hasPermission: PropTypes.bool.isRequired,
};

export { CreateCustomApp };
