import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import moment from "moment";

import { AuthButtons, SpinnerWrapper } from "./style";

import { HttpClient } from "../../../../../services/application/httpClient/httpClient";
import { dispatch } from "../../../../../store/store";
import { setNotification } from "../../../../../store/app/actions";
import { USER_INFO_URL, AUTH_LOGIN_URL } from "../../../../../constants/api";
import { TabSessionService } from "../../../../../services/TabSessionService";
import { Spinner } from "../../../../../components/ui/Spinner/Spinner";
import { AUTH, ERRORS } from "../../../../../constants/content";
import AppleAuth from "./AppleAuth";
import GoogleAuth from "./GoogleAuth";
import { useAuthContext } from "../../../../../hoc/AuthVerification";
import { setIsInitialAuth } from "../../../../../store/auth/actions";

const SSOAuth = ({ onSuccessHandler, tabSessionSerivce }) => {
  const [userEmail, setUserEmail] = useState(null);
  const [authToken, setAuthToken] = useState(null);
  const [spinner, setSpinner] = useState(false);

  const { token } = useSelector(state => state.auth);
  const { logout } = useAuthContext();
  const mainSignUpScreenShowPeriod = 30; // in seconds

  let state = localStorage.getItem('state');
  if (!state) {
    state = uuidv4();
    localStorage.setItem('state', state);
  }

  let nonce = localStorage.getItem('nonce');
  if (!nonce) {
    nonce = uuidv4();
    localStorage.setItem('nonce', nonce);
  }

  // user authentication
  const { mutate } = useMutation(
    body => {
      return HttpClient.post(AUTH_LOGIN_URL, body);
    },
    {
      onSuccess: async ({ data: { user, key } }) => {
        localStorage.setItem("rememberMeVamoos", true);
        await tabSessionSerivce.setUserEmail(user.primary_email);
        await tabSessionSerivce.setToken(key);

        setUserEmail(user.primary_email);
        setAuthToken(key);
      },
      onError: ({ response }) => {
        const errorMessage = response?.error || response?.data?.error || ERRORS.unknownError;
        dispatch(setNotification({ type: "error", message: errorMessage }));
        setSpinner(false);
      },
    },
  );

  useEffect(() => {
    let isMounted = true;
    if (userEmail) return;
    let email;

    const getUserEmail = async () => {
      email = await tabSessionSerivce.getUserEmail();
      if (isMounted) {
        setAuthToken(authToken);
      }
    };
    getUserEmail();

    if (email && token) {
      setUserEmail(email);
      const getUserToken = async () => {
        const authToken = await tabSessionSerivce.getToken();
        if (isMounted) {
          setAuthToken(authToken);
        }
      };
      getUserToken();
    }

    return () => {
      isMounted = false;
    };
  }, [userEmail, token]);

  // get information about authenticated user
  const getUser = async () => {
    const response = await HttpClient.get(USER_INFO_URL);
    return response.data;
  };

  const { data: authUserData, isError, error: authDataError } = useQuery({
    queryKey: [`authUserData`, userEmail],
    queryFn: () => getUser(),
    cacheTime: 0,
    enabled: !!userEmail,
    retry: 1,
    retryDelay: 1000,
  });

  useEffect(() => {
    if (!authUserData) return;

    const differenceSeconds = Math.abs(moment.utc().diff(moment(authUserData.created_at), 'seconds'));

    if (differenceSeconds <= mainSignUpScreenShowPeriod) dispatch(setIsInitialAuth(true));

    const handleAsync = async () => {
      await onSuccessHandler(authUserData, authToken, differenceSeconds <= mainSignUpScreenShowPeriod);
    };
    handleAsync();
  }, [authUserData]);

  useEffect(() => {
    if (!isError) return;
    const errorMessage = authUserData?.error || authDataError?.response?.data?.error?.message || AUTH.unknownErrorReauth;
    dispatch(setNotification({ type: "error", message: errorMessage }));

    logout(false);
    setSpinner(false);
  }, [authDataError]);

  const handleSignIn = useCallback(async (type, signInUserBody) => {
    await tabSessionSerivce.setAuthMethod(type);
    mutate(signInUserBody);
  }, []);

  useEffect(() => {
    return () => {
      setUserEmail(null);
      setAuthToken(null);
    };
  }, []);

  const renderSpinner = () => {
    return (
      <SpinnerWrapper>
        <Spinner size={32} cv="brand" />
      </SpinnerWrapper>
    );
  };

  return (
    <>
      <AuthButtons>
        {spinner ? (
          renderSpinner()
        ) : (
          <>
            <GoogleAuth handleSignIn={handleSignIn} setSpinner={setSpinner} nonce={nonce} />
            <AppleAuth handleSignIn={handleSignIn} setSpinner={setSpinner} nonce={nonce} state={state} />
          </>
        )}
      </AuthButtons>
    </>
  );
};

SSOAuth.propTypes = {
  onSuccessHandler: PropTypes.func.isRequired,
  tabSessionSerivce: PropTypes.instanceOf(TabSessionService).isRequired,
};

export default SSOAuth;
