import React from "react";
import CheckboxMui from "@mui/material/Checkbox";
import { ConditionalWrapper } from "components/ui/Inputs/conditionalWrapper";
import { Controller } from "react-hook-form";
import { FormControlLabelS } from "./style";

const Checkbox = ({
  control,
  name,
  rules,
  label,
  labelStyle = {},
  wrapStyle,
  onChange,
  handleChange,
  disabled,
  defaultValue,
  checked,
  indeterminate,
}) => {
  const renderInput = ({ error, value, onChange } = {}) => {
    return (
      <FormControlLabelS
        label={label}
        labelStyle={labelStyle}
        style={wrapStyle}
        disabled={disabled}
        control={
          <CheckboxMui
            onChange={e => {
              if (onChange) onChange(e.target.checked);
              if (handleChange) handleChange(e.target.checked);
            }}
            {...(!control ? { checked } : {})}
            indeterminate={indeterminate}
          />
        }
        // checked={value}
      />
    );
  };
  // return (
  //   <CheckboxMui
  //     onChange={e => {
  //       if (onChange) onChange(e.target.checked);
  //       if (handleChange) handleChange(e.target.checked);
  //     }}
  //     checked={checked}
  //   />
  // );
  return (
    <ConditionalWrapper
      condition={control}
      wrapper={() => (
        <FormControlLabelS
          label={label}
          labelStyle={labelStyle}
          style={wrapStyle}
          disabled={disabled}
          control={
            <Controller
              control={control}
              name={name}
              rules={rules}
              defaultValue={defaultValue}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <CheckboxMui
                  checked={!!value}
                  onChange={e => {
                    if (handleChange) handleChange(e.target.checked);

                    onChange(e.target.checked);
                  }}
                  disabled={disabled}
                />
              )}
            />
          }
        />
      )}
    >
      {renderInput({ onChange })}
    </ConditionalWrapper>
  );
};

export default Checkbox;
