import React from "react";
import { Grid } from "@material-ui/core";

import { FormSection } from "components/ui/Forms";

import { ADMIN_USER_DETAILS } from "constants/content";

import { UserInfo } from "./UserInfo";
import { OperatorsInfo } from "./OperatorsInfo";

const General = () => {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={10}>
        <FormSection title={ADMIN_USER_DETAILS.operatorInfo}>
          <OperatorsInfo />
        </FormSection>
        <FormSection title={ADMIN_USER_DETAILS.userInfo}>
          <UserInfo />
        </FormSection>
      </Grid>
    </Grid>
  );
};

export { General };
