import React from "react";
import styled from "styled-components";
import { Logo } from "components/ui/Logo/Logo";

import { ROOT_PATH } from "constants/routes";
import { Link } from "components/ui/Links/Link";

const StyledWrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: ${({ theme }) => theme.setSpacing(10)}px;
  width: 100%;
  height: ${({ theme }) => theme.setSpacing(10)}px;
`;

const BrandSection = () => (
  <StyledWrapper>
    <Link to={ROOT_PATH}>
      <Logo type="text" size="small" />
    </Link>
  </StyledWrapper>
);

export { StyledWrapper, BrandSection };
