import React, { useState } from "react";
import styled from "styled-components";
import { Grid } from "@material-ui/core";
import { getMediaPx } from "helpers";

import { useListOfCountries } from "hooks/useListOfCountries";
import { SearchField } from "components/ui/SearchField";
import { UnsafeAutocomplete } from "components/ui/Forms";
import { SETTINGS_STAYS } from "constants/content";
import { filterOptions } from "utils/filters/filterOptions";
import SelectInput from "components/ui/Inputs/Select";
import { responsiveConfig } from "config/responsive";

const ControlsContainer = styled.div`
  margin: 15px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    grid-template-columns: 1fr;
    gap: 15px;
  }
`;

const PublicStaysControl = ({ onSearchChange, onCountryChange }) => {
  const countries = useListOfCountries();

  const [selectedCountries, setSelectedCountries] = useState([]);
  const [countrySearchValue, setCountrySearchValue] = useState("");

  const filteredCountries = filterOptions(countries, countrySearchValue);

  const setValues = newCountries => {
    setSelectedCountries(newCountries);
    onCountryChange(newCountries.map(country => country.key));
    setCountrySearchValue("");
  };

  const handleCountryChange = selectedItem => {
    setValues(selectedItem);
  };

  return (
    <ControlsContainer>
      <SearchField onSearchChange={onSearchChange} label={SETTINGS_STAYS.searchField} fullWidth />

      <SelectInput
        options={filteredCountries}
        value={selectedCountries}
        label={SETTINGS_STAYS.countryField}
        onChange={handleCountryChange}
        optionValueVar="key"
        isMulti
      />
    </ControlsContainer>
  );
};

export { PublicStaysControl };
