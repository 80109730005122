import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";

import { LabelledInputForm } from "feature/panel/_shared/Messaging/Components/LabelledInputForm";
import { MessagingEmailElement } from "feature/panel/_shared/Messaging/Components/MessagingEmailElement";
import { Header } from "feature/panel/Stays/_shared/DoNotDisturb/DoNotDisturb";

import { BodyText2, LeadText } from "components/ui/Typography/Typography";
import { StyledLeadText } from "components/ui/Forms/FormSection";
import { SortableList } from "components/ui/Lists";
import { SecondaryButton } from "components/ui/Buttons/SecondaryButton";
import { NoResultsMessage } from "components/ui/Messages/NoResultsMessage";

import { setNewOrder } from "utils/sortable";
import { createNewMessagingEmail } from "utils/dataConverters";
import { emailValidate } from "utils/validation";
import { PERMISSIONS } from "constants/permissions";
import { usePermissionsService } from "hooks/usePermissionsService";
import { Message } from "components/ui/Messages";
import { GLOBAL_CONTENT } from "constants/content";

const FullWidthContainer = styled(Grid)`
  overflow-y: auto;
  height: calc(100vh - ${({ theme }) => theme.setSpacing(32)}px);
  padding: ${({ $messagesPresent }) => ($messagesPresent ? "80px 0px" : "80px 40px")};
`;

const SpacedTitle = styled(LeadText)`
  && {
    margin-bottom: ${({ theme }) => theme.setSpacing(8)}px;
  }
`;

const BottomContainer = styled.div`
  display: flex;
`;

const EmailListHeader = styled(Header)`
  justify-content: space-between;
  width: 100%;
`;

const DaysSelectorTitle = styled(BodyText2)`
  && {
    margin-bottom: ${({ theme }) => theme.setSpacing(7)}px;
    margin-top: ${({ theme }) => theme.setSpacing(8)}px;
  }
`;

const SettingsWindow = ({ contextHandler, messagesPresence }) => {
  const permissionsService = usePermissionsService();
  const { setValueFor, meta, errors, vamoos_id: vamoosId, editMode } = contextHandler();

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoosId);

  const updateMeta = (fieldName, value) => setValueFor("meta", { ...meta, [fieldName]: value });

  const handleChangeOrder = ({ newIndex, oldIndex }) => {
    const messaging_emails_list = setNewOrder(newIndex, oldIndex, meta.messaging_emails_list);
    updateMeta("messaging_emails_list", messaging_emails_list);
  };

  const onEmailCreate = () => {
    const messaging_emails_list = Array.isArray(meta.messaging_emails_list)
      ? [...meta.messaging_emails_list, createNewMessagingEmail()]
      : [createNewMessagingEmail()];

    updateMeta("messaging_emails_list", messaging_emails_list);
  };

  const onEmailChange = updatedEmail => {
    const messaging_emails_list = meta.messaging_emails_list.map(email => (email.id === updatedEmail.id ? updatedEmail : email));
    updateMeta("messaging_emails_list", messaging_emails_list);
  };

  const onEmailDelete = emailId => {
    const messaging_emails_list = meta.messaging_emails_list.filter(({ id }) => id !== emailId);
    updateMeta("messaging_emails_list", messaging_emails_list);
  };

  const activeEmailDatesSetupSection = () => (
    <>
      <DaysSelectorTitle>Adjust dates when &quot;during&quot; email is active</DaysSelectorTitle>
      <BottomContainer>
        <LabelledInputForm
          labelText="Days before start:"
          field="messaging_email_during_days_before"
          contextData={{ meta, setValueFor }}
          disabled={!canEdit}
        />
        <LabelledInputForm
          labelText="Days after end:"
          field="messaging_email_during_days_after"
          contextData={{ meta, setValueFor }}
          disabled={!canEdit}
        />
      </BottomContainer>
    </>
  );

  const isDatesSetupDisplayed = () => {
    const { messaging_emails_list } = meta;

    return Array.isArray(messaging_emails_list) ? messaging_emails_list.some(email => email.during && emailValidate(email.email)) : false;
  };

  const getErrors = id => (errors && errors.messaging && errors.messaging[id]) || null;

  return (
    <FullWidthContainer container justifyContent="center" $messagesPresent={messagesPresence}>
      <Grid xs={12} md={messagesPresence ? 11 : 10} item>
        <EmailListHeader>
          <StyledLeadText>Set email address(es) to activate messaging</StyledLeadText>
          {canEdit && (
            <SecondaryButton onClick={onEmailCreate}>
              <AddIcon />
              {GLOBAL_CONTENT.add}
            </SecondaryButton>
          )}
        </EmailListHeader>
        {Array.isArray(meta.messaging_emails_list) && meta.messaging_emails_list.length ? (
          <SortableList lockAxis="y" onSortEnd={handleChangeOrder} lockToContainerEdges useDragHandle>
            {meta.messaging_emails_list.map((email, index) => (
              <MessagingEmailElement
                key={email.id}
                emailData={email}
                index={index}
                onEmailChange={onEmailChange}
                onEmailDelete={() => onEmailDelete(email.id)}
                errors={getErrors(email.id)}
                disabled={!canEdit}
              />
            ))}
          </SortableList>
        ) : (
          (editMode ? canEdit : true) && (
            <NoResultsMessage height="calc(100vh - 370px)">Please click &quot;ADD&quot; to start adding emails</NoResultsMessage>
          )
        )}
        {errors.messaging?.during && <Message text={errors.messaging.during} type="error" margin={{ top: "24px" }} />}
        {isDatesSetupDisplayed() && activeEmailDatesSetupSection()}
      </Grid>
    </FullWidthContainer>
  );
};

SettingsWindow.defaultProps = {
  messagesPresence: false,
};

SettingsWindow.propTypes = {
  contextHandler: PropTypes.func.isRequired,
  messagesPresence: PropTypes.bool,
};

export { SettingsWindow, SpacedTitle };
