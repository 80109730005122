import React from "react";
import FolderPill from "components/_new/FolderPill";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import { colors } from "config/theme/colors";
import { FolderRow } from "./style";

const Folders = ({ handleFolderClick, currentPath, folders, isLoading }) => {
  return (
    <FolderRow>
      {/* {!folders?.length && (
        <div style={{ display: "flex", alignItems: "center", color: colors.grey50, marginTop: 15 }}>
          <ContentPasteSearchIcon style={{ marginRight: 5 }} /> No folders to display
        </div>
      )} */}
      {folders?.map(item => (
        <FolderPill
          name={item.name}
          onClick={() => handleFolderClick(item)}
          isLoading={isLoading && item.path === currentPath}
          key={item.id}
        />
      ))}
    </FolderRow>
  );
};

export default Folders;
