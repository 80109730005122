import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { InfoOutlined } from "@material-ui/icons";
import styled from "styled-components";

import { PanelTopBar } from "components/templates/Admin/PanelTopBar";
import { AccessDenied } from "components/templates/_shared/AccessDenied/AccessDenied";

import { ContextBar } from "components/templates/_shared/ContextBar/ContextBar";
import { Navigation } from "components/templates/_shared/Navigation/Navigation";
import { StyledWrapper, MainContentWrapper, MessageWrapper } from "components/templates/_shared/styledComponents";
import { Message } from "components/ui/Messages";

import { OPERATOR_TYPE, SUSPENDED_ACCOUNT_MESSAGE, USER_WITHOUT_OPERATOR_MESSAGE } from "constants/content";
import { setCurrentAdminOperatorSelect } from "store/adminOperators/actions";
import { changeOperatorStart } from "store/app/actions";

const AppContainer = styled(Grid)`
  height: 100vh;
  position: absolute;
`;

const TopBarContainer = styled(Grid)`
  position: sticky;
  top: 0;
  z-index: 3;
  flex-basis: 0 !important;
`;

const ContentContainer = styled(Grid)`
  overflow: hidden;
  flex-basis: 0;
  flex-grow: 1;
`;

const AdminTemplate = ({ children, contextBar, navigation, noMargin, whiteBg }) => {
  const dispatch = useDispatch();
  const [isAdminPanelLoaded, setIsAdminPanelLoaded] = useState(false);
  const currentOperatorCode = useSelector(state => state.auth.currentOperatorCode);
  const operators = useSelector(state => state.auth.operators);
  const currentAdminOperatorCode = useSelector(state => state.adminOperators.currentAdminOperatorCode);
  const location = useLocation();
  const isAnyOperatorAssigned = operators.length > 0;
  const selectedOperator = operators?.find(({ code }) => code === currentOperatorCode);
  const isOperatorActive = selectedOperator?.isActive;
  const type = selectedOperator?.type;
  const isOperatorAdmin = type === OPERATOR_TYPE.admin;

  const init = () => {
    if (currentOperatorCode !== currentAdminOperatorCode && isOperatorAdmin) {
      dispatch(setCurrentAdminOperatorSelect(currentOperatorCode));
    }
    if (
      !isAdminPanelLoaded &&
      currentOperatorCode !== currentAdminOperatorCode &&
      !isOperatorAdmin &&
      location.pathname.startsWith("/admin")
    ) {
      dispatch(changeOperatorStart({ code: currentAdminOperatorCode, isSelectedOperatorActive: true }));
      setIsAdminPanelLoaded(true);
    }
  };

  useEffect(init, []);

  const inactiveAccountMessage = (
    <MessageWrapper>
      <Message type="warning" text={SUSPENDED_ACCOUNT_MESSAGE} />
    </MessageWrapper>
  );

  const operatorMissingMessage = (
    <MessageWrapper>
      <Message type="warning" icon={<InfoOutlined />} text={USER_WITHOUT_OPERATOR_MESSAGE} />
    </MessageWrapper>
  );

  return (
    <StyledWrapper whiteBg={!!navigation || whiteBg}>
      <AppContainer container direction="column" wrap="nowrap" spacing={0}>
        <TopBarContainer item xs={12}>
          <PanelTopBar />
          {isOperatorActive && isOperatorAdmin && contextBar && typeof contextBar === "object" && (
            <ContextBar leftSlot={contextBar.left} middleSlot={contextBar.middle} rightSlot={contextBar.right} />
          )}
        </TopBarContainer>
        <ContentContainer container item xs={12} wrap="nowrap">
          {isOperatorActive && isOperatorAdmin && navigation && <Navigation content={navigation} />}
          {isOperatorActive && isOperatorAdmin && <MainContentWrapper noMargin={noMargin}>{children}</MainContentWrapper>}
          {!isOperatorActive && isOperatorAdmin && inactiveAccountMessage}
          {!isAnyOperatorAssigned && operatorMissingMessage}
          {isOperatorActive && !isOperatorAdmin && <AccessDenied />}
        </ContentContainer>
      </AppContainer>
    </StyledWrapper>
  );
};

AdminTemplate.defaultProps = {
  contextBar: null,
  children: null,
  navigation: null,
  noMargin: false,
  whiteBg: false,
};

AdminTemplate.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.object]),
  contextBar: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.object]),
  navigation: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.object]),
  noMargin: PropTypes.bool,
  whiteBg: PropTypes.bool,
};

export { AdminTemplate };
