import { setSpacing } from "config/theme/spacing";

const buttons = {
  letterSpacing: "1px",
  smaller: {
    height: setSpacing(7),
    fontSize: setSpacing(2.5),
    lineHeight: setSpacing(7),
    padding: {
      horizontal: setSpacing(2),
      vertical: 0,
    },
  },

  small: {
    height: setSpacing(8),
    fontSize: setSpacing(3),
    lineHeight: setSpacing(8),
    padding: {
      horizontal: setSpacing(2),
      vertical: 0,
    },
  },

  medium: {
    height: setSpacing(10),
    fontSize: setSpacing(4),
    lineHeight: setSpacing(10),
    padding: {
      horizontal: setSpacing(3),
      vertical: 0,
    },
  },

  large: {
    height: setSpacing(12),
    fontSize: setSpacing(4),
    lineHeight: setSpacing(12),
    padding: {
      horizontal: setSpacing(4),
      vertical: 0,
    },
  },

  ghostButton: {
    small: {
      lineHeight: 1.33,
    },
    medium: {
      lineHeight: "normal",
    },
    large: {
      lineHeight: 1.5,
    },
  },
};

export { buttons };
