import React, { useState } from "react";
import BaseModal from "components/_new/ModalBase";
import { useMutation } from "@tanstack/react-query";
import { HttpClient } from "services/application/httpClient/httpClient";
import moment from "moment";
import { useForm } from "react-hook-form";
import ErrorBlock from "components/_new/ErrorBlock";
import AddFlightForm from "./form";
import { setNotification } from "../../../../../../../store/app/actions";
import { dispatch } from "../../../../../../../store/store";

const prettifyError = error => {
  const matches = error.match(/([a-zA-Z_]+)\s*:\s*([^,\n]+)/g);

  if (!matches) {
    return error;
  }

  const prettifiedError = error.replace(/([a-zA-Z_]+)\s*:\s*([^,\n]+)/g, (match, key, value) => {
    const prettifiedKey = key.replace(/_/g, " ");
    return `${prettifiedKey.charAt(0).toUpperCase() + prettifiedKey.slice(1)}: ${value}`;
  });

  return prettifiedError;
};

const AddFlightModal = ({ onClose, append, flights, setRestrictedFlightsDataByPerson, departureDate }) => {
  const form = useForm();
  const [showManualForm, setShowManualForm] = useState(false);
  const [selectedFlightLegs, setSelectedFlightLegs] = useState([]);
  const [errorText, setErrorText] = useState("");

  const { mutate, isLoading } = useMutation(
    ({ carrier, flight_number, flight_date, departure_airport, arrival_airport, departure_date_local } = {}) => {
      return HttpClient.get(
        `/flight/find/${carrier}/${flight_number}/${departure_airport}/${arrival_airport}/${moment(
          flight_date || departure_date_local,
        ).format("YYYY-MM-DD")}/`,
      );
    },
    {
      onSuccess: res => {
        if (!flights.some(flight => flight.id === res.data[0].id)) {
          setRestrictedFlightsDataByPerson(prevState => ({
            ...prevState,
            [res.data[0].id]: null,
          }));

          append({
            ...res.data[0],
            restricted_to_traveller_internal_ids: null,
          });
        }
        dispatch(setNotification({ type: "success", message: "Flight has been saved to the list" }));
        onClose();
      },
    },
  );

  const { mutate: addFlightManually, isLoading: addingManuallyLoading } = useMutation(
    body => {
      return HttpClient.post("/flight/manual", body);
    },
    {
      onSuccess: res => {
        setRestrictedFlightsDataByPerson(prevState => ({
          ...prevState,
          [res.data.id]: null,
        }));
        append({
          ...res.data,
          restricted_to_traveller_internal_ids: null,
        });
        onClose();
      },
      onError: err => setErrorText(prettifyError(err.response.data.error)),
    },
  );

  const handleConfirm = async values => {
    if (selectedFlightLegs.length) {
      const promises = [];
      for (const flight of selectedFlightLegs) {
        promises.push(mutate(flight));
      }
      await Promise.all(promises);
    } else {
      const flightDate = moment(form.getValues().flight_date).format("YYYY-MM-DD");
      const departureTime = form.getValues().departure_at;
      const arrivalTime = form.getValues().arrival_at;

      const departureAtFormatted = moment(flightDate)
        .add(departureTime.hours(), "hours")
        .add(departureTime.minutes(), "minutes")
        .add(departureTime.seconds(), "seconds");

      let arrivalAtFormatted = moment(flightDate)
        .add(arrivalTime.hours(), "hours")
        .add(arrivalTime.minutes(), "minutes")
        .add(arrivalTime.hours(), "seconds");

      if (moment(arrivalAtFormatted).unix() < moment(departureAtFormatted).unix()) {
        arrivalAtFormatted = moment(arrivalAtFormatted).add(1, "days");
      }

      const departureAtResult = moment(departureAtFormatted).format("YYYY-MM-DDTHH:mm:ss[Z]");
      const arrivatAtResult = moment(arrivalAtFormatted).format("YYYY-MM-DDTHH:mm:ss[Z]");

      const {
        departure_airport_code,
        departure_terminal,
        arrival_airport_code,
        arrival_terminal,
        adjust_airport_local_times,
        flight_number: carrier_flight_number,
        airline: carrier_code,
      } = values;

      addFlightManually({
        departure_airport_code,
        departure_terminal,
        arrival_airport_code,
        arrival_terminal,
        adjust_airport_local_times,
        carrier_code,
        carrier_flight_number,
        arrival_at: arrivatAtResult,
        departure_at: departureAtResult,
      });
    }
  };

  const getFlightIdentifier = flight => `${flight.carrier}_${flight.flight_number}_${flight.departure_airport}_${flight.arrival_airport}`;
  const handleFlightSelect = (isSelected, flight) => {
    const flightIdentifier = getFlightIdentifier(flight);

    setSelectedFlightLegs(prevSelectedFlights => {
      if (isSelected) {
        return prevSelectedFlights.some(selected => getFlightIdentifier(selected) === flightIdentifier)
          ? prevSelectedFlights
          : [...prevSelectedFlights, flight];
      } else {
        return prevSelectedFlights.filter(selected => getFlightIdentifier(selected) !== flightIdentifier);
      }
    });
  };

  return (
    <BaseModal
      title="Add flight"
      confirmTitle="Add flight"
      onCancel={onClose}
      onConfirm={form.handleSubmit(handleConfirm)}
      modalStyle={{ overflow: "visible" }}
      contentStyle={{ overflow: "visible" }}
      isLoading={isLoading || addingManuallyLoading}
      confirmDisabled={!showManualForm && !selectedFlightLegs.length}
      mobileFullScreen
    >
      <AddFlightForm
        onFlightSelect={handleFlightSelect}
        showManualForm={showManualForm}
        setShowManualForm={setShowManualForm}
        departureDate={departureDate}
        {...form}
      />
      <ErrorBlock text={errorText} show={errorText} onClose={() => setErrorText(null)} style={{ marginTop: 15 }} />
    </BaseModal>
  );
};

export default AddFlightModal;
