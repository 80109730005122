import React, { useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import Input from "components/ui/Inputs/TextInput";
import { GLOBAL_CONTENT } from "constants/content";
import { IMAGES_MIME_TYPES, DOCUMENTS_FILE_TYPES, IMAGES_MIME_TYPES_NO_GIF } from "constants/defaults";
import { DEFAULT_LIBRARY_TABS } from "components/ui/LibraryPopup/LibraryPopup";
import { ActionButton, AttachFilesButton } from "components/ui/Buttons";
import { VamoosImage } from "domain/VamoosImage";
import { StyledTextarea } from "feature/panel/Trips/_shared/Storyboard/Entries/EntryForm/EntryDetailsForm";
import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { Chip } from "@material-ui/core";
import { colors } from "config/theme/colors";
import { useSelector } from "react-redux";
import { LanguageLock } from "feature/panel/Stays/_shared/LanguageLock";
import { Tooltip } from "@mui/material";

import { SectionTitle } from "../sharedComponents";
import { Hint, Section } from "./style";
import { screenshotsInputs, textInputs } from "./inputs";
import LanguageSelectorCutsomApps from "../components/LanguageSelector";
import doc from "../../../../../../assets/documents/assigment_letter.docx";

const Information = () => {
  const { control, watch, getValues, setValue, setError } = useOutletContext();

  const [open, setOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [missedLanguages, setMissedLanguages] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState(null);
  const defaultLanguage = useSelector(state => state.customAppForm.customAppForm.default_language);

  const errors = useSelector(({ customAppForm }) => customAppForm.errors);

  const androidImage = watch("android.feature_image");

  const assignmentLetter = watch("assignment_letter");
  const keywords = watch("keywords");
  const keyword = watch("keyword");
  const nameOverride = watch("name_override");

  const isLanguagesEqual = currentLanguage !== defaultLanguage;

  const getFileName = file => {
    const finalFile = file.s3 || file;
    const arr = finalFile.split("/");
    const length = arr.length;

    return arr[length - 1];
  };

  const deleteKeyword = currentKeyword => {
    const lang = currentLanguage || "en";
    const filtered = keywords.languages[lang].filter(item => item !== currentKeyword);
    const newKeywords = { languages: { ...keywords.languages, [lang]: filtered } };

    setValue("keywords", newKeywords);
  };
  const onConfirm = () => {
    const newKeyword = getValues("keyword");
    if (newKeyword)
      setValue("keywords", {
        languages: {
          ...(keywords?.languages || {}),
          [currentLanguage || "en"]: [...(keywords?.languages?.[currentLanguage || "en"] || []), newKeyword],
        },
      });
    setValue("keyword", null);
    setOpen(false);
  };

  const getScreenShotsValue = value => {
    if (Array.isArray(value))
      return value
        ?.map(item => item + "\n")
        .join("")
        .slice(0, -1);

    return value
      ?.split(",")
      ?.map(item => item + "\n")
      .join("")
      .slice(0, -1);
  };

  const handleChangeLanguage = lang => setCurrentLanguage(lang);

  const getLanguageInputError = error =>
    error?.message?.[currentLanguage] && (error?.message?.[currentLanguage] || "This field is required");

  const groupErrors = errors => {
    const result = [];

    errors.forEach(item => {
      const findIndex = result.findIndex(findItem => findItem.field === item.field);

      const isMessageObject = message => typeof message === "object";

      if (findIndex !== -1) {
        const foundItem = result[findIndex];
        result[findIndex] = {
          ...foundItem,
          message: isMessageObject(foundItem.message)
            ? {
                ...foundItem.message,
                ...item.message,
              }
            : item.message,
        };
      } else {
        result.push(item);
      }
    });
    return result;
  };

  useEffect(() => {
    if (errors.information?.length) {
      let langs = [];
      groupErrors(errors.information).forEach(item => {
        setError(item.field || item, { message: item.message });
        if (item.missedLanguages) {
          langs = [...langs, ...item.missedLanguages];
        }
      });

      setMissedLanguages(langs);
    } else {
      setMissedLanguages([]);
    }
  }, [errors]);

  return (
    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
      <div>
        <SectionTitle style={{ marginBottom: 15 }}>App store information</SectionTitle>
        <LanguageSelectorCutsomApps currentLanguage={currentLanguage} onChange={handleChangeLanguage} missedLanguages={missedLanguages} />

        {/* {isLanguagesEqual && <LanguageLock message="Some inputs can only be edited in default language" style={{ marginBottom: 35 }} />} */}
        <Section style={{ marginTop: 15 }}>
          <Input
            control={control}
            name="name_override"
            label="App store display name"
            style={{ marginBottom: 15 }}
            modifyValue={value => value?.languages?.[currentLanguage || "en"]}
            modifyError={error => getLanguageInputError(error)}
            modifiedOnChange={e => ({ languages: { ...(nameOverride.languages || {}), [currentLanguage || "en"]: e.target.value } })}
            currentLanguage={currentLanguage}
            rules={{
              validate: {
                minLength: value => {
                  if (value.languages[currentLanguage || "en"]?.length < 2) return "Min length is 2 characters";
                },
              },
            }}
          />

          <Controller
            control={control}
            name="short_description"
            rules={{ required: true, maxLength: { value: 80, message: "Max length 80 letters" } }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <StyledTextarea
                  label="Short description* "
                  onChange={e => onChange({ languages: { ...value.languages, [currentLanguage || "en"]: e.target.value } })}
                  value={value?.languages?.[currentLanguage || "en"] || ""}
                  errorMessages={getLanguageInputError(error)}
                  style={{ marginBottom: getLanguageInputError(error) ? 0 : 15 }}
                />
              </>
            )}
          />

          <Controller
            control={control}
            name="full_description"
            rules={{ required: true, minLength: { value: 10, message: "Min length 10 letters" } }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <StyledTextarea
                  label="Full description* "
                  onChange={e => onChange({ languages: { ...value.languages, [currentLanguage || "en"]: e.target.value } })}
                  value={value?.languages?.[currentLanguage || "en"] || ""}
                  errorMessages={getLanguageInputError(error)}
                />
              </>
            )}
          />
        </Section>

        <Section>
          <SectionTitle>Keywords</SectionTitle>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              {Array.isArray(keywords?.languages?.[currentLanguage || "en"]) &&
                keywords?.languages?.[currentLanguage || "en"]?.map((item, index) => (
                  <Tooltip title={item}>
                    <Chip
                      label={<div style={{ maxWidth: 200, overflow: "hidden", textOverflow: "ellipsis" }}>{item}</div>}
                      key={index}
                      onDelete={() => deleteKeyword(item)}
                      style={{ marginRight: 5, marginBottom: 5 }}
                    />
                  </Tooltip>
                ))}
            </div>
            <ActionButton onClick={() => setOpen(true)}>Add</ActionButton>
          </div>
          <ConfirmationModal
            title="Add keyword"
            confirmLabel="Add"
            onCancel={() => setOpen(false)}
            onConfirm={onConfirm}
            open={open}
            confirmDisabled={!keyword}
          >
            <Input control={control} label="Keyword" name="keyword" style={{ marginBottom: 15, width: 300 }} />
          </ConfirmationModal>
        </Section>

        <Section>
          <SectionTitle style={{ marginBottom: 5 }}>Android feature image</SectionTitle>
          <Hint style={{ marginBottom: 25 }}>
            If your app is selected for featuring on Google Play, your featured image is used to promote the app on tablets, phones, and the
            web
          </Hint>

          <Controller
            control={control}
            name="android.feature_image"
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <AttachFilesButton
                  // label={`${GLOBAL_CONTENT.attach}/${GLOBAL_CONTENT.link}`}
                  size="small"
                  onSelect={e => {
                    const image = VamoosImage(e[0]);
                    setImagePreview(image.previewUrl);
                    onChange({ s3: image.s3Url || image.fileUrl, preview: image.previewUrl });
                  }}
                  allowedFileTypes={IMAGES_MIME_TYPES_NO_GIF}
                  tabs={[DEFAULT_LIBRARY_TABS.library, DEFAULT_LIBRARY_TABS.files]}
                  returnEncoded={false}
                  hint="PNG or JPEG, up to 1MB, and 1,024 px by 500 px"
                  maxFileSize={1}
                  onlyResolution="1024x500"
                  error={error?.message}
                />
              );
            }}
          />
          {androidImage && <img src={androidImage?.https || androidImage.preview} style={{ width: 200, marginTop: 10 }} alt="android" />}

          <br />
        </Section>
      </div>
      <div>
        <Section>
          <SectionTitle style={{ marginBottom: 15 }}>General</SectionTitle>
          {textInputs.map(item => (
            <Input {...item} key={item.key} control={control} style={{ marginBottom: 15 }} />
          ))}

          <SectionTitle style={{ marginBottom: 5 }}>Screenshots</SectionTitle>
          <Hint style={{ marginBottom: 20 }}>
            We generate screenshots for the app store from a demo Vamoos Trip on your account. Please provide the login details and a list
            of screens you’d like to feature (max 7)
          </Hint>

          {screenshotsInputs.map(item => (
            <Input {...item} key={item.key} control={control} style={{ marginBottom: 15 }} />
          ))}
          <Controller
            control={control}
            name="screenshots.list_of_screenshots"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <StyledTextarea
                  label="Screenshots"
                  onChange={e => onChange(e.target.value.split(/\r?\n/))}
                  value={getScreenShotsValue(value)}
                  helperText="Screenshot per line"
                  errorMessages={error && (error?.message || "This field is required")}
                />
              </>
            )}
          />
        </Section>

        <Section>
          <SectionTitle style={{ marginBottom: 5 }}>Developer information</SectionTitle>
          <Hint>
            We require authorisation to release apps on your behalf, please complete the assignment letter and upload below. Please also
            provide the name of your organisation Apple Developer Account.
          </Hint>

          <SectionTitle style={{ fontSize: 14, margin: "15px 0 10px 0" }}>iOS developer account</SectionTitle>

          <Input control={control} name="ios.developer_account_name" label="Developer account name" style={{ marginBottom: 15 }} />

          <SectionTitle style={{ fontSize: 14, margin: "25px 0 10px 0" }}>Assignment letter</SectionTitle>

          <Controller
            control={control}
            name="assignment_letter"
            render={({ field: { onChange }, fieldState: { error } }) => (
              <AttachFilesButton
                label={`${GLOBAL_CONTENT.attach}/${GLOBAL_CONTENT.link}`}
                size="small"
                onSelect={e => {
                  onChange(e[0]?.file_url);
                }}
                allowedFileTypes={DOCUMENTS_FILE_TYPES}
                tabs={[DEFAULT_LIBRARY_TABS.library, DEFAULT_LIBRARY_TABS.files]}
                returnEncoded={false}
                error={error?.message}
                hint={
                  <>
                    Please complete the{" "}
                    <a href={doc} style={{ color: colors.brand }} download>
                      document at this link
                    </a>{" "}
                    and upload it above.
                  </>
                }
              />
            )}
          />
          {assignmentLetter && (
            <a
              href={assignmentLetter?.https || assignmentLetter}
              target="_blank"
              rel="noreferrer"
              style={{ display: "block", marginTop: 10 }}
            >
              {getFileName(assignmentLetter)}
            </a>
          )}
        </Section>
      </div>
    </div>
  );
};

export default Information;
