import styled from "styled-components";

export const Text = styled.span`
  font-size: ${({ theme }) => theme.setSpacing(3)}px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
`;

export const Wrap = styled.div`
  display: flex;
  align-items: center;
  transition: all 0.4s ease-in-out;
  &:hover {
    .MuiSvgIcon-root {
      // transform: translateX(-3px);
    }
  }
  cursor: pointer;
  .MuiSvgIcon-root {
    font-size: 14px;
    transition: all 0.2s ease-in-out;
  }
`;
