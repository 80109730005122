import { isFunction } from "contracts/types";
import { ERRORS } from "constants/content";
import { setNotification } from "store/app/actions";

export const callFn = functionsStore => {
  return (fn, ...args) => {
    return isFunction(functionsStore[fn])(...args);
  };
};

// eslint-disable-next-line no-bitwise
export const generateUniqueId = () => new Date().getTime() & 0xffffffff;

export const getHash = () =>
  Math.random()
    .toString(36)
    .substring(2, 15) +
  Math.random()
    .toString(36)
    .substring(2, 15);

export const extractCoordinates = coordinates => {
  const spreadTypes = [", ", ",", " "];
  const hasValidSpread = spreadTypes.some(t => coordinates.includes(t));
  if (hasValidSpread) {
    const spread = spreadTypes.filter(type => coordinates.includes(type))[0];
    const [latitude, longitude] = coordinates.split(spread);
    return {
      latitude: Number(latitude) || latitude,
      longitude: Number(longitude) || longitude,
    };
  }

  return {
    latitude: Number(coordinates) || coordinates,
    longitude: "",
  };
};

export const setDefaultApiErrorMessage = ({ response }) => {
  return typeof response?.data?.error === "string" ? response.data.error : ERRORS.unknownError;
};

export const sendDefaultApiErrorMessage = (dispatch, error) => {
  dispatch(setNotification({ type: "error", message: setDefaultApiErrorMessage(error) }));
};

export const copyToClipboard = (value, successCallback = () => {}, failureCallback = () => {}) => {
  navigator.clipboard
    .writeText(value)
    .then(() => isFunction(successCallback)())
    .catch(() => isFunction(failureCallback)());
};
