export const UPDATE_ADMIN_OPERATOR_DETAILS_START = "UPDATE_ADMIN_OPERATOR_DETAILS_START";
export const UPDATE_ADMIN_OPERATOR_DETAILS_SUCCESS = "UPDATE_ADMIN_OPERATOR_DETAILS_SUCCESS";
export const UPDATE_ADMIN_OPERATOR_DETAILS_FAIL = "UPDATE_ADMIN_OPERATOR_DETAILS_FAIL";

export const GET_OPERATOR_DETAILS_START = "GET_OPERATOR_DETAILS_START";
export const GET_OPERATOR_DETAILS_SUCCESS = "GET_OPERATOR_DETAILS_SUCCESS";
export const GET_OPERATOR_DETAILS_FAIL = "GET_OPERATOR_DETAILS_FAIL";

export const SET_VALUE_FOR_OPERATOR_START = "SET_VALUE_FOR_OPERATOR_START";
export const SET_ERRORS_FOR_OPERATOR_DETAILS = "SET_ERRORS_FOR_OPERATOR_DETAILS";

export const SET_OPERATOR_DETAILS_FORM_TOUCHED = "SET_OPERATOR_DETAILS_FORM_TOUCHED";

export const GET_CUSTOM_APP_LIST_FOR_ADMIN_OPERATOR_START = "GET_CUSTOM_APP_LIST_FOR_ADMIN_OPERATOR_START";
export const GET_CUSTOM_APP_LIST_FOR_ADMIN_OPERATOR_SUCCESS = "GET_CUSTOM_APP_LIST_FOR_ADMIN_OPERATOR_SUCCESS";
export const GET_CUSTOM_APP_LIST_FOR_ADMIN_OPERATOR_FAIL = "GET_CUSTOM_APP_LIST_FOR_ADMIN_OPERATOR_FAIL";

export const ACCEPT_CUSTOM_APP_START = "ACCEPT_CUSTOM_APP_START";
export const ACCEPT_CUSTOM_APP_SUCCESS = "ACCEPT_CUSTOM_APP_SUCCESS";
export const ACCEPT_CUSTOM_APP_FAIL = "ACCEPT_CUSTOM_APP_FAIL";

export const REJECT_CUSTOM_APP_START = "REJECT_CUSTOM_APP_START";
export const REJECT_CUSTOM_APP_SUCCESS = "REJECT_CUSTOM_APP_SUCCESS";
export const REJECT_CUSTOM_APP_FAIL = "REJECT_CUSTOM_APP_FAIL";

export const REVERT_TO_DRAFT_CUSTOM_APP_START = "REVERT_TO_DRAFT_CUSTOM_APP_START";
export const REVERT_TO_DRAFT_CUSTOM_APP_SUCCESS = "REVERT_TO_DRAFT_CUSTOM_APP_SUCCESS";
export const REVERT_TO_DRAFT_CUSTOM_APP_FAIL = "REVERT_TO_DRAFT_CUSTOM_APP_FAIL";

export const MAKE_LIVE_CUSTOM_APP_START = "MAKE_LIVE_CUSTOM_APP_START";
export const MAKE_LIVE_CUSTOM_APP_SUCCESS = "MAKE_LIVE_CUSTOM_APP_SUCCESS";
export const MAKE_LIVE_CUSTOM_APP_FAIL = "MAKE_LIVE_CUSTOM_APP_FAIL";

export const GET_CUSTOM_APP_BUNDLE_VERSIONS_START = "GET_CUSTOM_APP_BUNDLE_VERSIONS_START";
export const GET_CUSTOM_APP_BUNDLE_VERSIONS_SUCCESS = "GET_CUSTOM_APP_BUNDLE_VERSIONS_SUCCESS";
export const GET_CUSTOM_APP_BUNDLE_VERSIONS_FAIL = "GET_CUSTOM_APP_BUNDLE_VERSIONS_FAIL";
