import { colors } from "./colors";

export const overrides = {
  MuiPickersToolbar: {
    toolbar: {
      backgroundColor: colors.brand,
    },
  },
  MuiPickersDay: {
    day: {
      color: colors.brand,
    },
    daySelected: {
      backgroundColor: colors.brand,
      "&:hover": {
        backgroundColor: colors.brandHover,
      },
    },
    current: {
      color: colors.brand,
    },
  },
  MuiPickerDTTabs: {
    tabs: {
      backgroundColor: colors.brand,
    },
  },
  MuiPickersClock: {
    pin: {
      backgroundColor: colors.brand,
    },
  },
  MuiPickersClockPointer: {
    pointer: {
      backgroundColor: colors.brand,
    },
    noPoint: {
      backgroundColor: colors.brand,
    },
    thumb: {
      backgroundColor: colors.brand,
      borderColor: colors.brand,
    },
  },
  MuiButton: {
    textPrimary: {
      color: colors.brand,
    },
  },
};
