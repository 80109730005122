import React from "react";
import { Content } from "components/ui/Content/Content";
import { H1, LeadText } from "components/ui/Typography/Typography";
import { SignUpForm } from "feature/views/SignUp/SignUpForm";
import { GLOBAL_CONTENT } from "../../../constants/content";

const FormSection = () => (
  <section data-testidv="form-section">
    <Content element="header">
      <H1>Sign up</H1>
    </Content>
    <Content margin={12}>
      <LeadText>{GLOBAL_CONTENT.registerBusinessAccount}</LeadText>
    </Content>
    <SignUpForm />
  </section>
);

export { FormSection };
