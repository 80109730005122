
export const updateRestrictedIds = (restrictableSectionsMapper, watch, setValue, person, idList, isAddMode, isLastPersonDeletion) =>{
  restrictableSectionsMapper.forEach(({ key, hasMetaProperty }) => {
    const updatedData = watch(key).map(item => {
      const newItem = { ...item };
      const restrictedIds = hasMetaProperty ? newItem?.meta?.restricted_to_traveller_internal_ids : newItem?.restricted_to_traveller_internal_ids;
      const shouldUpdateRestrictedIds = isLastPersonDeletion ? restrictedIds?.length >= 0: restrictedIds?.length > 0;
      if (restrictedIds && shouldUpdateRestrictedIds) {
        let updatedRestrictedIds;
        if (isAddMode) {
          // add new ids to all restrictable sections
          const found = restrictedIds.find(id => id === person.travellersData[0].internal_id)
          if (found) {
            updatedRestrictedIds = [...restrictedIds, ...idList];
          }
        } else {
          // remove unused ids from all restrictable sections
          // set null if all travellers have been removed and this is called by onDelete func
          updatedRestrictedIds = isLastPersonDeletion ? null : restrictedIds.filter(id => !idList.includes(id));
        }

        if (updatedRestrictedIds !== undefined) {
          if (hasMetaProperty) {
            newItem.meta.restricted_to_traveller_internal_ids = updatedRestrictedIds;
          } else {
            newItem.restricted_to_traveller_internal_ids = updatedRestrictedIds;
          }
        }
      }
      return newItem;
    });
    setValue(key, updatedData);
  });
};