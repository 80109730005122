import React from "react";
import styled from "styled-components";
import { BaseButton } from "components/ui/Buttons/BaseButton";

const PrimaryButton = styled(props => <BaseButton cv="brand" {...props} />)`
  && {
    color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.brand};

    :hover {
      background: ${({ theme }) => theme.colors.brandHover};
      border: 1px solid ${({ theme }) => theme.colors.brandHover};
    }

    :disabled {
      background: ${({ theme }) => theme.colors.grey20};
      color: ${({ theme }) => theme.colors.white};
      border: 1px solid ${({ theme }) => theme.colors.grey20};
    }
  }
`;

export { PrimaryButton };
