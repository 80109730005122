import styled from "styled-components";
import { colors } from "config/theme/colors";
import { getMediaPx } from "helpers";

export const Wrap = styled.div`
  background-color: #fff;
  padding: 0 5px;
  color: ${p => (p.error ? colors.error : p.isFocused ? colors.brand : "#4f4f4f")};
  font-size: ${({ isFocused, value }) => (isFocused || value ? "12px" : "1rem")};
  position: absolute;
  z-index: ${p => (p.isFocused || p.value ? 3 : 0)};
  transform: ${({ isFocused, value }) => `translate(${isFocused || value ? 12 : 13}px, ${isFocused || value ? -9 : 15}px)`};
  transition: all 0.2s ease-in-out;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    transform: translate(15px, ${p => (p.isFocused || p.value ? -8 : 15)}px);
    font-size: ${p => (p.isFocused || p.value ? 12 : 16)}px;
  }
`;
