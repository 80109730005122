import * as actionTypes from "store/stays/actionTypes";
import { createAction } from "utils/store";

export const createOrUpdateStayValidationFail = createAction(actionTypes.CREATE_OR_UPDATE_STAY_VALIDATION_FAIL);

export const copyStayStart = createAction(actionTypes.COPY_STAY_START);

export const getStaysFeaturesStart = createAction(actionTypes.GET_STAYS_FEATURES_START);
export const getStaysFeaturesSuccess = createAction(actionTypes.GET_STAYS_FEATURES_SUCCESS);
export const getStaysFeaturesFail = createAction(actionTypes.GET_STAYS_FEATURES_FAIL);

export const getStaysDndStart = createAction(actionTypes.GET_STAYS_DND_START);
export const getStaysDndSuccess = createAction(actionTypes.GET_STAYS_DND_SUCCESS);
export const getStaysDndFail = createAction(actionTypes.GET_STAYS_DND_FAIL);

export const setStaysDndApprovalStart = createAction(actionTypes.SET_STAYS_DND_APPROVAL_START);
export const setStaysDndApprovalSuccess = createAction(actionTypes.SET_STAYS_DND_APPROVAL_SUCCESS);
export const setStaysDndApprovalFail = createAction(actionTypes.SET_STAYS_DND_APPROVAL_FAIL);

export const requestStayConnectStatusStart = createAction(actionTypes.REQUEST_STAY_CONNECT_STATUS_START);
export const requestStayConnectStatusSuccess = createAction(actionTypes.REQUEST_STAY_CONNECT_STATUS_SUCCESS);
export const requestStayConnectStatusFail = createAction(actionTypes.REQUEST_STAY_CONNECT_STATUS_FAIL);

export const checkIsUserIdAvailableStart = createAction(actionTypes.CHECK_USER_ID_AVAILABILITY_START);
export const checkIsUserIdAvailableSuccess = createAction(actionTypes.CHECK_USER_ID_AVAILABILITY_SUCCESS);

export const createStaysPublisherStart = createAction(actionTypes.CREATE_STAYS_PUBLISHER_START);
export const createStaysPublisherSuccess = createAction(actionTypes.CREATE_STAYS_PUBLISHER_SUCCESS);
export const createStaysPublisherFail = createAction(actionTypes.CREATE_STAYS_PUBLISHER_FAIL);

export const getStaysPublishersStart = createAction(actionTypes.GET_STAYS_PUBLISHERS_START);
export const getStaysPublishersSuccess = createAction(actionTypes.GET_STAYS_PUBLISHERS_SUCCESS);
export const getStaysPublishersFail = createAction(actionTypes.GET_STAYS_PUBLISHERS_FAIL);

export const clearStaysPublisherJob = createAction(actionTypes.CLEAR_STAYS_PUBLISHER_JOB);

export const refreshStaysPublisherStart = createAction(actionTypes.REFRESH_STAYS_PUBLISHER_START);
export const refreshStaysPublisherSuccess = createAction(actionTypes.REFRESH_STAYS_PUBLISHER_SUCCESS);
export const refreshStaysPublisherFail = createAction(actionTypes.REFRESH_STAYS_PUBLISHER_FAIL);

export const deleteStaysPublisherStart = createAction(actionTypes.DELETE_STAYS_PUBLISHER_START);
export const deleteStaysPublisherSuccess = createAction(actionTypes.DELETE_STAYS_PUBLISHER_SUCCESS);
export const deleteStaysPublisherFail = createAction(actionTypes.DELETE_STAYS_PUBLISHER_FAIL);

// Publisher modal

export const openPublisherModal = createAction(actionTypes.OPEN_PUBLISHER_MODAL);
export const closePublisherModal = createAction(actionTypes.CLOSE_PUBLISHER_MODAL);

export const setPublisherModalAllValues = createAction(actionTypes.SET_PUBLISHER_MODAL_ALL_VALUES);
export const setPublisherModalValue = createAction(actionTypes.SET_PUBLISHER_MODAL_VALUE);
export const setPublisherModalError = createAction(actionTypes.SET_PUBLISHER_MODAL_ERROR);