import { TableContainer } from "@material-ui/core";
import TableMui from "@mui/material/Table";

import React, { cloneElement } from "react";
import MobileTableItem from "./Item";

const TableMobile = ({ rows, columns, renderRowMobile, FooterCmp = null }) => {
  return (
    <div>
      {rows?.map((item, index) =>
        renderRowMobile ? (
          cloneElement(renderRowMobile, { item, key: item.id, index })
        ) : (
          <MobileTableItem item={item} columns={columns} key={item.id} index={index} />
        ),
      )}
      {FooterCmp ? (
        <TableContainer>
          <TableMui>
            {FooterCmp}
          </TableMui>
        </TableContainer>
      ) : null}
    </div>
  );
};

export default TableMobile;
