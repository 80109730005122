import { firstLetterUpperCase } from "utils/string";

const successMessage = (resource, action) => `${resource} has been successfully ${action}d`;
const failedMessage = (resource, action) => `Attempting to ${action} ${resource} failed`;

const actionTypes = {
  create: "create",
  update: "update",
  delete: "delete",
  regenerate: "regenerate",
  get: "get",
};

const getFailedMessage = resource => failedMessage(resource, actionTypes.get);
const createSuccessMessage = resource => successMessage(resource, actionTypes.create);
const createFailedMessage = resource => failedMessage(resource, actionTypes.create);
const updateSuccessMessage = resource => successMessage(resource, actionTypes.update);
const updateFailedMessage = resource => failedMessage(resource, actionTypes.update);
const deleteSuccessMessage = resource => successMessage(resource, actionTypes.delete);
const deleteFailedMessage = resource => failedMessage(resource, actionTypes.delete);
const refreshSuccessMessage = resource => successMessage(resource, actionTypes.regenerate);
const refreshFailedMessage = resource => failedMessage(resource, actionTypes.regenerate);

const trips = "Vamoos";
const stays = "Stay";
const creation = "Creation";
const publisher = "Publisher document";
const inspiration = "Inspiration";
const user = "User";
const apiKey = "API key";
const account = "account";
const poi = "POI";
const operator = "Operator";
const userDetails = "User details";
const notifications = "notifications";
const operators = "Operators list";
const customApp = "Custom App";

const genericErrorMessage = "Oops! Something went wrong";

const resources = {
  trips,
  creation,
  publisher,
  stays,
  inspiration,
  user,
  apiKey,
  account,
  poi,
  operator,
  userDetails,
  notifications,
  operators,
  customApp,
};

export const flightsAddedMessage = flightsNumber =>
  flightsNumber === 1 ? "Flight has been saved to the list" : `${flightsNumber} flights have been saved to the list`;
export default {
  general: {
    500: genericErrorMessage,
    400: genericErrorMessage,
  },
  logout: "Signed out successfully",
  resource: resource => ({
    get: {
      fail: getFailedMessage(resources[resource]),
    },
    create: {
      success: createSuccessMessage(resources[resource]),
      fail: createFailedMessage(resources[resource]),
    },
    update: {
      success: updateSuccessMessage(resources[resource]),
      fail: updateFailedMessage(resources[resource]),
    },
    delete: {
      success: deleteSuccessMessage(resources[resource]),
      fail: deleteFailedMessage(resources[resource]),
    },
    refresh: {
      success: refreshSuccessMessage(resources[resource]),
      fail: refreshFailedMessage(resources[resource]),
    },
  }),
};

export const copySuccess = resource => `${firstLetterUpperCase(resource)} copied`;
export const copyItinerarySuccess = itinerary => `${itinerary} has been successfully copied`;
export const copyFailed = resource => `Failed to copy ${resource}`;
export const UNSAVED_CHANGES_MESSAGE =
  "You currently have unsaved changes. In order to continue with this operation you need to save first.";
