import React, { memo } from "react";
import PropTypes from "prop-types";
import AddIcon from "@material-ui/icons/Add";

import { CREATE_CONTENT_LABELS } from "constants/content";

import { SecondaryButton } from "components/ui/Buttons";

export const AddButton = memo(({ onClick }) => (
  <SecondaryButton onClick={onClick}>
    <AddIcon />
    {CREATE_CONTENT_LABELS.add}
  </SecondaryButton>
));

AddButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
