import { decodeFromUrl } from "utils/url";
import { LibraryService } from "./LibraryService";

export class StoryboardService {
  constructor(libraryService = new LibraryService()) {
    this.libraryService = libraryService;
  }

  async uploadToLibrary(file, folderPath, previewImage) {
    const { file_url } = await this.libraryService.uploadFileToS3(file);

    const fileName = decodeFromUrl(file_url.split("/").pop());

    const result = await this.libraryService.addFile(folderPath, file_url, file_url, fileName, {
      is_public: false,
      meta: previewImage ? { preview_url: previewImage } : {},
    });

    return result;
  }
}
