import { BrandMapper } from "mappers/BrandMapper";
import { DefaultBrandMapper } from "mappers/DefaultBrandMapper";
import { BrandRepository } from "repositories/BrandRepository";

export class BrandService {
  /**
   *
   * @param {BrandMapper} mapper
   * @param {BrandRepository} respository
   */
  constructor(mapper = new BrandMapper(), defaultBrandMapper = new DefaultBrandMapper(), respository = new BrandRepository()) {
    /** @type {BrandMapper} */
    this.brandMapper = mapper;

    /** @type {DefaultBrandMapper} */
    this.defaultBrandMapper = defaultBrandMapper;

    /** @type {BrandRepository} */
    this.brandRepository = respository;
  }

  /**
   *
   * @returns {Brand[]}
   */
  async getBrandsList() {
    const brandsDto = await this.brandRepository.getBrandsList();
    const brands = brandsDto?.map(branding => this.brandMapper.fromDtoToDomain(branding));

    return brands;
  }

  /**
   *
   * @param {number} id
   * @returns {Brand}
   */
  async getBrandById(id) {
    const brandDto = await this.brandRepository.getBrandById(id);

    return this.brandMapper.fromDtoToDomain(brandDto);
  }

  /**
   *
   * @returns {Brand}
   */
  async getDefaultBrandData() {
    const data = await this.brandRepository.getCurrentOperator();

    return this.defaultBrandMapper.fromDtoToDomain(data);
  }

  /**
   *
   * @param {string} name
   * @returns {Brand}
   */
  async createNewBrand(name) {
    return this.brandRepository.createNewBrand(name);
  }

  /**
   *
   * @param {Brand} data
   * @returns {Brand}
   */
  async updateBrand(data) {
    const payload = this.brandMapper.fromDomainToDto(data);
    const updatedData = await this.brandRepository.updateBrand(data.id, payload);

    return this.brandMapper.fromDtoToDomain(updatedData);
  }

  /**
   *
   * @param {Brand} data
   * @returns {Brand}
   */
  async updateDefaultBrand(data) {
    const payload = this.defaultBrandMapper.fromDomainToDto(data);
    const updatedData = await this.brandRepository.updateDefaultBrand(payload);

    return this.brandMapper.fromDtoToDomain(updatedData);
  }
}
