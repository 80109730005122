import { SettingsApplicationsOutlined } from "@mui/icons-material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import OutlinedButton from "components/_new/Buttons/OutlinedButton";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { HttpClient } from "services/application/httpClient/httpClient";
import Main from "./Main";
import Settings from "./settings";
import { LoadingScreen } from "../../../../../../components/ui/LoadingScreen/LoadingScreen";

const Message = ({ form: { control, watch }, canEdit, isWiped, hasAdditionalMenu }) => {
  const { operator_code, reference_code, ...rest } = useParams();
  const [showSettings, setShowSettings] = useState(false);
  const queryClient = useQueryClient();

  const { data: messaging, isLoading: isMessagingLoading } = useQuery({
    queryKey: ["chatList", operator_code, reference_code],
    queryFn: () => HttpClient.get(`/itinerary/${operator_code}/${reference_code}/messaging?count=5000&page=1`),
    select: res => res?.data?.items.sort((a, b) => b.last_msg_at - a.last_msg_at),
    refetchOnMount: true,
    enabled: Boolean(reference_code),
  });

  const { data: { data: messages } = { data: [] }, isLoading: isMessagesLoading } = useQuery({
    queryKey: ["messages", operator_code, reference_code],
    queryFn: () => HttpClient.get(`/itinerary/${operator_code}/${reference_code}/messages`),
    refetchOnMount: true,
    enabled: Boolean(reference_code),
  });

  const hasMessages = messaging?.length > 0;
  const toggleSettings = () => setShowSettings(!showSettings);

  useEffect(() => {
    const invalidateQueries = async () => {
      await queryClient.invalidateQueries(["chatList"]);
      await queryClient.invalidateQueries(["messages"]);
    };

    return () => {
      invalidateQueries();
    };
  }, [queryClient]);

  return (
    <>
      {(isMessagingLoading || isMessagesLoading) ? (
        <LoadingScreen />
      ) : (
        <>
          {!showSettings && !hasMessages && (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
              No messages to be displayed.
              <OutlinedButton
                text="Settings"
                type="grey"
                style={{ marginTop: 15 }}
                startIcon={<SettingsApplicationsOutlined />}
                onClick={toggleSettings}
              />
            </div>
          )}
          {showSettings && <Settings control={control} watch={watch} disabled={!canEdit || isWiped} hasAdditionalMenu={hasAdditionalMenu} />}
          {hasMessages && (
            <Main
              chats={messaging}
              messages={messages?.items}
              watch={watch}
              control={control}
              disabled={!canEdit || isWiped}
              hasAdditionalMenu={hasAdditionalMenu}
            />
          )}
        </>
      )}
    </>
  );
};

export default Message;
