import { font } from "config/theme/fonts";
import styled from "styled-components";
import { getMediaPx } from "../../../../../helpers";
import { colors } from "../../../../../config/theme/colors";

export const Container = styled.div`
  display: inline-block;
  width: 100%;

  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    display: block;
  }
`;
export const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 1rem 0;
  margin-bottom: 0;
  padding: 1rem 0;
  font-family: ${font};
  background-color: #ffffff;
  /* transition: all 0.4s ease-in-out;
  position: sticky;
  top: 0;
  z-index: 3; */

  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    padding: 0 10px 0 0;
    gap: 20px;
    margin-bottom: 0;
  }
`;

export const ControlButtons = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;

  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    margin-top: -20px;
    padding-top: 1rem;
  }
`;

export const InputsGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(30%, 1fr) minmax(20%, 1fr) minmax(40%, 1.5fr) 3%;
  gap: 10px;
  align-items: flex-start;

  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    grid-template-columns: 1fr;
    position: relative;
  }
`;

export const DateTimeInputs = styled.div`
  display: grid;
  align-items: flex-start;
  grid-template-columns: minmax(140px, 0.5fr) 5px minmax(140px, 0.5fr);
  gap: 10px;
`;

export const DashWrap = styled.div`
  align-self: center;
  margin-bottom: ${({ hasError }) => (hasError ? "17px" : "0")};

  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    margin-bottom: ${({ hasError }) => (hasError ? "29px" : "0")};
  }
`;

export const IconWrap = styled.div`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: ${({ hasError }) => (hasError ? "19px" : "0")};
  transition: all 0.2s ease-in-out;
  svg {
    color: #bdbdbd;
  }

  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: ${colors.redLight};
    width: 32px;
    height: 32px;
    border-radius: 100%;
    margin-bottom: 0;
    svg {
      color: #eb5757;
    }
  }
`;

export const AndWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const AndText = styled.span`
  position: relative;
  text-align: center;
  flex: 0.3 0 auto;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    height: 1px;
    background-color: ${colors.grey20};
    width: 100%;
  }

  &::before {
    right: 75%;
  }

  &::after {
    left: 75%;
  }
`;
