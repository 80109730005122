import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Send } from "@material-ui/icons";

import { Paper, ContentWrapper } from "feature/panel/Help/styledComponents";
import { LeadText } from "components/ui/Typography/Typography";
import { Input, Textarea } from "components/ui/Forms";
import { SecondaryButton } from "components/ui/Buttons";
import { setNotification } from "store/app/actions";
import { isObjectEmpty } from "utils/object";
import { validateContactUsForm } from "feature/panel/Help/ContactUs/validateContactUsForm";
import { sendDefaultApiErrorMessage } from "utils";
import { HttpClient } from "services/application/httpClient/httpClient";
import { CONTACT_US_URL } from "constants/api";

const Wrapper = styled(ContentWrapper)`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.setSpacing(10)}px;
`;

const Header = styled(LeadText)`
  && {
    display: block;
    margin: 0 auto ${({ theme }) => theme.setSpacing(8)}px auto;
  }
`;

const initialFormState = {
  full_name: "",
  email: "",
  subject: "",
  phone_number: "",
  message: "",
};

const sendContactMessage = form => {
  return HttpClient.post(CONTACT_US_URL, form);
};

const ContactUs = ({ component: Component }) => {
  const [form, setForm] = useState(initialFormState);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  const handleMessageSend = e => {
    e.preventDefault();
    const newErrorsList = validateContactUsForm(form);
    setErrors(newErrorsList);
    if (isObjectEmpty(newErrorsList)) {
      sendContactMessage(form)
        .then(() => {
          setForm(initialFormState);
          dispatch(
            setNotification({
              type: "success",
              message: "Message sent successfully",
            }),
          );
        })
        .catch(error => sendDefaultApiErrorMessage(dispatch, error));
    }
  };
  const handleFormValueChange = ({ target }) => {
    setForm({
      ...form,
      [target.name]: target.value,
    });
  };
  const { full_name, email, subject, phone_number, message } = form;

  return (
    <Component>
      <Wrapper>
        <Header>Contact us</Header>
        <form onSubmit={handleMessageSend} style={{ width: "100%" }}>
          <InputWrapper>
            <Input
              value={full_name}
              onChange={handleFormValueChange}
              label="Full name"
              name="full_name"
              error={!!errors.full_name}
              helperText={errors.full_name || ""}
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              value={email}
              onChange={handleFormValueChange}
              label="Contact email"
              name="email"
              error={!!errors.email}
              helperText={errors.email || ""}
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              value={subject}
              onChange={handleFormValueChange}
              label="Subject"
              name="subject"
              error={!!errors.subject}
              helperText={errors.subject || ""}
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              value={phone_number}
              onChange={handleFormValueChange}
              label="Contact phone number"
              name="phone_number"
              error={!!errors.phone_number}
              helperText={errors.phone_number || ""}
            />
          </InputWrapper>
          <InputWrapper>
            <Textarea
              value={message}
              onChange={handleFormValueChange}
              label="Message"
              name="message"
              error={!!errors.message}
              helperText={errors.message || ""}
              rows={4}
            />
          </InputWrapper>
          <SecondaryButton type="submit">
            <Send />
            SEND MESSAGE
          </SecondaryButton>
        </form>
      </Wrapper>
    </Component>
  );
};

ContactUs.defaultProps = {
  component: Paper,
};

ContactUs.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export { ContactUs, Wrapper, InputWrapper, Header };
