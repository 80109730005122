import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import { responsiveConfig } from "../../../../config/responsive";

const StyledAppBar = styled(AppBar)`
  && {
    background-color: ${({ theme }) => theme.colors.grey100};
    position: sticky;
    top: 0px;
    z-index: 0;
  }
`;

const ContentArea = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.setSpacing(16)}px;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  @media (max-width: ${responsiveConfig.mediaPx.laptop}px) {
    padding: 0 10px 0 15px;
  }
`;

const TopBar = ({ children }) => (
  <StyledAppBar position="static" elevation={0}>
    <ContentArea>{children}</ContentArea>
  </StyledAppBar>
);

TopBar.defaultProps = {
  children: null,
};

TopBar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.object]),
};

export { TopBar, StyledAppBar, ContentArea };
