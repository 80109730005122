import { colors } from "config/theme/colors";
import { spacing, setSpacing } from "config/theme/spacing";
import { paddings } from "config/theme/sizes";
import { buttons } from "config/theme/buttons";
import { forms } from "config/theme/forms";
import { breakpoints } from "config/theme/breakpoints";

import * as fonts from "config/theme/fonts";

const shadows = {
  box: `0px ${setSpacing(2)}px ${setSpacing(4)}px -${spacing}px rgba(234, 86, 52, 0.4)`,
  map: "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
  bar: "0px 4px 4px 0px rgba(0, 0, 0, 0.24), 0px 0px 4px 0 rgba(0, 0, 0, 0.12)",
  folder: "0px 2px 4px 0 rgba(0, 0, 0, 0.14)",
  infoBar: "0px 8px 16px -4px rgba(0, 0, 0, 0.1)",
};

const transition = "all 0.2s ease-in-out;";

const borderRadius = spacing * 2;

export default {
  shadows,
  borderRadius,
  fonts,
  colors,
  paddings,
  spacing,
  setSpacing,
  transition,
  buttons,
  forms,
  breakpoints,
};
