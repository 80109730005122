import { useMutation, useQueryClient } from "@tanstack/react-query";
import Checkbox from "components/_new/Checkbox";
import BaseModal from "components/_new/ModalBase";
import Input from "components/ui/Inputs/TextInput";
import { Message } from "components/ui/Messages/Message";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { HttpClient } from "services/application/httpClient/httpClient";
import { setNotification } from "store/app/actions";
import { encodeParameter } from "utils/url";
import { checkboxesPublisher, prepareDataToSubmit } from "./helpers";
import PublisherStep2 from "./publisherStep2";

const PublisherModal = ({ onClose, directories, data, isDirty, onSave, isEditing, location }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { reference_code, operator_code } = useParams();
  const { control, watch, handleSubmit, reset, setValue } = useForm({
    defaultValues: { show_dates: false, sections: { poi_list: false } },
  });

  const [cacheLastPageNotes, setCacheLastPageNotes] = useState(null);
  const [cacheMap, setCacheMap] = useState(null);
  const [cacheMap2, setCacheMap2] = useState(null);
  const [cacheMap3, setCacheMap3] = useState(null);

  const [step, setStep] = useState(1);
  // const front_page_notes = watch("sections.notes_front");
  // const last_page_notes = watch("sections.notes_end");
  const cover = watch("sections.cover");
  const directory = watch("sections.directory");
  const map1 = watch("sections.map1");
  const map2 = watch("sections.map2");
  const map3 = watch("sections.map3");
  const poi_list = watch("sections.poi_list");
  const last_page_notes = watch("sections.notes_end");
  const id = watch("id");

  const { mutate, isLoading } = useMutation(
    body => {
      return HttpClient.post(`/creation/${encodeParameter(operator_code)}/${encodeParameter(reference_code)}`, { ...body, ...(id ? { id } : {}) });
    },
    {
      onSuccess: async () => {
        dispatch(setNotification({ type: "success", message: `Publisher document ${id ? "updated" : "created"} successfully` }));
        await queryClient.invalidateQueries({ queryKey: ["creations"] });
        onClose();
      },
    },
  );

  const shouldRenderInput = item => {
    return item.hasOwnProperty("shouldShow") ? item.shouldShow({ directories }) : true;
  };

  const handleClose = () => {
    if (step === 2) setStep(1);
    else onClose();
  };

  const onSubmit = values => {
    if (directory && step !== 2) setStep(2);
    else {
      delete values.savedDirectoryNodes;
      mutate(
        prepareDataToSubmit(
          {
            ...values,
            sections: {
              ...values.sections,
            },
          },
          "publisher",
        ),
      );
    }
  };

  useEffect(() => {
    if (data) {
      data.savedDirectoryNodes = data.sections?.directory?.nodes;
      if (data.sections?.directory?.nodes) data.sections.directory.nodes = [];
      reset({ ...data });
    }
  }, [data]);

  // useEffect(() => {
  //   const notes_end = data?.sections?.notes_end;

  //   if (last_page_notes && typeof last_page_notes !== "object" && (notes_end || cacheLastPageNotes)) {
  //     setValue("sections.notes_end.headline", (notes_end || cacheLastPageNotes).headline);
  //     setValue("sections.notes_end.content", (notes_end || cacheLastPageNotes).content);
  //   }
  // }, [last_page_notes]);

  useEffect(() => {
    const map = data?.sections?.map1;

    if (map1 && typeof map1 !== "object" && (map || cacheMap)) {
      setValue("sections.map1.headline", (map || cacheMap).headline);
      setValue("sections.map1.options", (map || cacheMap).options);
    }
  }, [map1]);

  useEffect(() => {
    const map = data?.sections?.map2;

    if (map2 && typeof map2 !== "object" && (map || cacheMap)) {
      setValue("sections.map2.headline", (map || cacheMap).headline);
      setValue("sections.map2.options", (map || cacheMap).options);
    }
  }, [map2]);

  useEffect(() => {
    const map = data?.sections?.map3;

    if (map3 && typeof map3 !== "object" && (map || cacheMap)) {
      setValue("sections.map3.headline", (map || cacheMap).headline);
      setValue("sections.map3.options", (map || cacheMap).options);
    }
  }, [map3]);

  useEffect(() => {
    if (poi_list) setValue("sections.poi_list.order_by", "type");
  }, [poi_list]);

  useEffect(() => {
    if (cover && !cover?.imagesize) setValue("sections.cover.imagesize", "reduced");
  }, [cover]);

  return (
    <BaseModal
      title={
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
          {isEditing ? "Edit publisher document" : "Generate new publisher document"}{" "}
          <span style={{ fontWeight: 400, fontSize: 14 }}>{directory && `Step ${step} of 2`}</span>
        </div>
      }
      confirmTitle={directory ? "Proceed" : "Save"}
      cancelTitle={step === 2 ? "Back" : "Cancel"}
      onCancel={handleClose}
      onConfirm={isDirty ? onSave() : handleSubmit(onSubmit)}
      contentStyle={{ overflowY: "auto" }}
      modalStyle={{ width: 800 }}
      confirmDisabled={isLoading}
      mobileFullScreen
    >
      {isDirty ? (
        <Message
          text="You currently have unsaved changes. In order to continue with this operation you need to save first."
          type="info"
          margin={{ top: "15px" }}
        />
      ) : step === 1 ? (
        <>
          <Input
            autoFocus
            control={control}
            name="name"
            label="File name"
            style={{ margin: "15px 0" }}
            rules={{ required: "File name is required" }}
          />

          <div>Include following sections in document:</div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {checkboxesPublisher({ control, cover, directory, setValue, map1, map2, map3, poi_list, last_page_notes, location }).map(
              item =>
                shouldRenderInput(item) && (
                  <>
                    <Checkbox
                      key={item.name}
                      control={control}
                      name={item.name}
                      label={item.label}
                      wrapStyle={{ marginTop: 15 }}
                      labelStyle={{ fontSize: 18, lineHeight: "normal" }}
                      handleChange={val => {
                        if (!val) {
                          if (item.name === "sections.notes_end") {
                            setCacheLastPageNotes(last_page_notes);
                          }
                          if (item.name === "sections.map1") {
                            setCacheMap(map1);
                          }
                          if (item.name === "sections.map2") {
                            setCacheMap2(map2);
                          }
                          if (item.name === "sections.map3") {
                            setCacheMap3(map3);
                          }
                        }
                      }}
                    />
                    {item.additional}
                  </>
                ),
            )}
          </div>
        </>
      ) : (
        <PublisherStep2 control={control} directories={directories} setValue={setValue} watch={watch} data={data} />
      )}
    </BaseModal>
  );
};

export default PublisherModal;
