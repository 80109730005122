import React from "react";
import styled from "styled-components";
import MuiChip from "@material-ui/core/Chip";
import { setColorVariant } from "utils/styles/colors";
import { getMediaPx } from "helpers";
import { colors } from "config/theme/colors";

const Chip = styled(({ cv, ...rest }) => <MuiChip variant="outlined" {...rest} />)`
  && {
    border: 1px solid ${colors.grey30};
    color: ${({ cv }) => setColorVariant(cv || "grey50")};
    max-width: 200px;
    .MuiChip-icon {
      color: ${({ theme }) => theme.colors.grey100};
    }
    @media (max-width: ${p => getMediaPx(p, "md")}px) {
      height: 48px;
      border-radius: 8px;
      max-width: none;
      width: 100%;
    }
  }
`;

export { Chip };
