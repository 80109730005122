import React from "react";
import PropTypes from "prop-types";

import { DocumentModalWrapper } from "./style";
import Item from "../../../Itinerary/pages/Trips/Documents/item";
import SelectInput from "../../../../../components/ui/Inputs/Select";

const documentType = {
  travel: {
    value: "travel",
    label: "Travel document",
  },
  destination: {
    value: "destination",
    label: "Destination document",
  },
};

const DocumentBulkActionModal = ({ form }) => {
  return (
    <DocumentModalWrapper>
      <SelectInput
        control={form.control}
        options={Object.values(documentType).map(item => ({ label: item.label, value: item.value }))}
        label="Document type"
        name="document_type"
        isClearable={false}
        rules={{ required: true }}
        styleContainer={{ width: "100%" }}
      />
      <Item
        control={form.control}
        watch={form.watch}
        inputNameTab={form.watch("document_type")}
        labelStyle={{ backgroundColor: "#FFFFFF" }}
        hideActions
        inputNames={{ title: "name", file: "file" }}
        setValue={form.setValue}
        wrapStyle={{ gridTemplateColumns: "1fr", paddingLeft: 0 }}
        useNormalButton
      />
    </DocumentModalWrapper>
  );
};

DocumentBulkActionModal.propTypes = {
  form: PropTypes.shape().isRequired,
};
export default DocumentBulkActionModal;
