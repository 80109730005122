import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFieldArray, useForm } from "react-hook-form";
import { Wrapper } from "feature/panel/Itinerary/style";
import ItineraryTitle from "feature/panel/Itinerary/components/Title";
import Table from "components/_new/Table";
import { useMutation, useQuery } from "@tanstack/react-query";
import { TableWrap } from "feature/panel/Itinerary/helpers";
import { HttpClient } from "services/application/httpClient/httpClient";
import { encodeParameter } from "utils/url";
import InviteBlock from "./inviteBlock";
import MobileItem from "./mobileItem";
import columns from "./columns";
import { Message } from "../../../../../../components/ui/Messages";
import { dispatch } from "../../../../../../store/store";
import { setNotification } from "../../../../../../store/app/actions";
import { TRIP_WIZARD_CONTENT } from "../../../../../../constants/content";
import { useTableHandlers } from "../../../../../../hooks/useTableHandlers";

const ShareAccess = ({ form: { control, watch }, canEdit, isWiped, user }) => {
  const { control: usersControl, setValue } = useForm();
  const { fields } = useFieldArray({
    control: usersControl,
    name: "users",
    keyName: "rf_id",
  });

  const { type, operator_code, reference_code } = useParams();
  const tableId = "shareAccess";
  const tableHandlers = useTableHandlers("", () => {}, { params: { type: tableId } });
  const { page, rowsPerPage } = tableHandlers;

  const { refetch, data: shareAccessUsersData } = useQuery({
    queryKey: ["shareAccessUsers", rowsPerPage, page],
    queryFn: () =>
      HttpClient.get(
        `/itinerary/${encodeParameter(operator_code)}/${encodeParameter(reference_code)}/users?&count=${rowsPerPage}&page=${page}`,
      ),
    onSuccess: res => {
      setValue("users", res.data.items);
    },
    refetchOnMount: !!reference_code,
  });

  const { mutate, isLoading } = useMutation(
    id => {
      return HttpClient.delete(`/itinerary/${operator_code}/${reference_code}/users/${id}`);
    },
    {
      onSuccess: () => {
        refetch();
        dispatch(setNotification({ type: "success", message: TRIP_WIZARD_CONTENT.permissions.notifications.removedUser }));
      },
      onError: ({
        response: {
          data: { error },
        },
      }) => dispatch(setNotification({ type: "error", message: error ?? TRIP_WIZARD_CONTENT.permissions.notifications.removeUserError })),
    },
  );

  return (
    <Wrapper>
      <ItineraryTitle style={{ paddingLeft: 0 }}>Invite user</ItineraryTitle>
      {reference_code ? (
        <>
          <InviteBlock control={control} watch={watch} disabled={!canEdit || isWiped} />

          {fields?.length > 0 && (
            <>
              <ItineraryTitle style={{ marginTop: 35 }}>Invited users</ItineraryTitle>{" "}
              <TableWrap>
                <Table
                  columns={columns({ onDelete: ({ user: { id } }) => mutate(id), isLoading, user })}
                  rows={fields}
                  renderRowMobile={<MobileItem onDelete={({ user: { id } }) => mutate(id)} user={user} />}
                  {...tableHandlers}
                  totalMatches={shareAccessUsersData?.data?.total_matches}
                  tableId={tableId}
                />
              </TableWrap>
            </>
          )}
        </>
      ) : (
        <Message
          type="warning"
          text={`Share access section is only available for already existing ${type === "stay" ? "stays" : "trips"}`}
        />
      )}
    </Wrapper>
  );
};

export default ShareAccess;
