import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";

import Input from "components/ui/Inputs/TextInput";
import SelectInput from "components/ui/Inputs/Select";
import DateTimeInput from "components/ui/Inputs/DateTimeInput";
import Switch from "../../../../../../../components/_new/Switch";
import BaseModal from "../../../../../../../components/_new/ModalBase";
import { InputsRow, SwitchWrapper, Container, LanguageSelectorWrapper, AtWrap } from "./style";

import { calculateStartingPointChange, calculateStartingPointValue, noneItem, periods } from "../helpers";
import { extractCoordinates } from "../../../../../../../utils";
import { transformUrl } from "../../../../../../../utils/dataConverters";
import { LanguageContext } from "../../../../../../../components/_shared/LanguageSelector/LanguageContext";
import { LocalisationService } from "../../../../../../../services/LocalisationService";
import { LanguageSelector } from "../../../../../../../components/_shared/LanguageSelector/LanguageSelector";
import config from "../../../../../../../config/app";
import { isObjectEmpty } from "../../../../../../../utils/object";
import { ResponsiveHideFrom } from "../../../../responsive";

const NotificationModal = ({
  type,
  onClose,
  modalTitle,
  isLoading,
  confirmLabel,
  onNotificationAdd,
  notification,
  editMode,
  locations,
  displayMode,
  setNotificationToModify,
  skipValidation,
  setSkipValidation,
  itineraryType,
}) => {
  const { control, reset, watch, setValue, clearErrors, handleSubmit, formState, trigger } = useForm({ mode: "onChange" });
  const [showCoordinates, setShowCoordinates] = useState(typeof notification.location_internal_id !== "number");
  const [showNumberOfDays, setShowNumberOfDays] = useState(notification.delivery_at_days !== 0);
  const minNumberOfDays = 0;
  const maxNumberOfDays = 400;

  const { currentLanguage, additionalLanguages } = useContext(LanguageContext);
  const defaultLanguageCode = useSelector(({ operator }) => operator.currentOperator.defaultLanguageCode);
  const [localCurrentLanguage, setLocalCurrentLanguage] = useState(currentLanguage);
  const [initialValuesSet, setInitialValuesSet] = useState(false);

  const localisationService = new LocalisationService(localCurrentLanguage);
  const currentNotification = watch();
  const isActive = displayMode === "template" ? "is_default_on" : "is_active";

  // set initial values
  useEffect(() => {
    const startingPointValue = calculateStartingPointValue(notification.delivery_at_relative_to, notification.delivery_at_days);
    const startingCoordinates = `${notification.latitude}, ${notification.longitude}`;
    if (notification.hasOwnProperty("start_at")) delete notification.delivery_at;

    reset({
      ...notification,
      ...(type === "timed"
        ? {
            delivery_at_relative_to: notification.hasOwnProperty("delivery_at_relative_to") ? startingPointValue : null,
            delivery_at_days: notification.hasOwnProperty("delivery_at_days") ? Math.abs(notification.delivery_at_days) : null,
          }
        : {}),
      ...(type === "gps"
        ? {
            start_at: notification.start_at ?? undefined, // to display initial start_at Select as numbers
          }
        : {}),
      ...(notification.latitude && notification.longitude && { coordinates: startingCoordinates }),
      ...(displayMode === "template" && {
        content: localisationService.localise(notification, "content"),
        ...(type !== "forced" ? { url: localisationService.localise(notification, "url") } : {}),
      }),
      ...(displayMode === "template" &&
        additionalLanguages.length > 0 && {
          localisation: {
            ...notification.localisation,
            [defaultLanguageCode]: {
              content: notification.content,
              ...(type !== "forced" ? { url: notification.url } : {}),
            },
          },
        }),
    });

    setInitialValuesSet(true);
  }, []);

  // form validation for old notifications, to prompt the user to enter all necessary information (turn on and edit mode)
  useEffect(() => {
    if (!editMode || !initialValuesSet || skipValidation) return;
    const validateForm = async () => {
      await trigger();
    };
    validateForm();
  }, [trigger, editMode, initialValuesSet]);

  // This is for the old version templates.
  // To change skipValidation value when one of the required fields is changed to a non-nullable value.
  useEffect(() => {
    if (displayMode === "trip" || type === "forced") return;
    let valuesToCheck;

    if (type === "timed") {
      valuesToCheck = [currentNotification.delivery_at_days, currentNotification.delivery_at_relative_to, currentNotification.start_at];
    } else if (type === "gps") {
      valuesToCheck = [currentNotification.start_at, currentNotification.end_at, currentNotification.coordinates];
    }

    const hasValue = valuesToCheck.some(value => !!value);
    if (hasValue) setSkipValidation(false);
  }, [displayMode, type, currentNotification]);

  // set localised content when the language changes
  useEffect(() => {
    if (isEmpty(currentNotification)) return;
    reset({
      ...currentNotification,
      content:
        currentNotification.localisation[localCurrentLanguage.code]?.content ?? localisationService.localise(notification, "content"),
      ...(type !== "forced"
        ? { url: currentNotification.localisation[localCurrentLanguage.code]?.url ?? localisationService.localise(notification, "url") }
        : {}),
    });
  }, [localCurrentLanguage]);

  const setLocalisedValue = prevLanguageCode => {
    setValue(`localisation.${prevLanguageCode}`, {
      ...watch(`localisation.${prevLanguageCode}`),
      content: watch("content"),
      ...(type !== "forced" ? { url: watch("url") } : {}),
    });
  };

  const switchLanguage = lang => {
    setLocalisedValue(localCurrentLanguage.code);
    setLocalCurrentLanguage({
      ...lang,
      isDefault: lang.code === defaultLanguageCode,
    });
  };

  const onChangePeriod = value => {
    const valuesWithoutNumberOfDays = ["start", "end", "disabled"];
    const periodWithoutDays = valuesWithoutNumberOfDays.includes(value);
    if (periodWithoutDays) clearErrors("delivery_at_days");
    setShowNumberOfDays(!periodWithoutDays);
    const updatedNotification = calculateStartingPointChange(watch(), value);
    setValue(`delivery_at_days`, Math.abs(updatedNotification.delivery_at_days));
  };

  const onChangeLocation = value => {
    setShowCoordinates(value === noneItem.value);
    if (value !== noneItem.value) {
      clearErrors("coordinates");
      if (currentNotification?.coordinates) setValue("coordinates", undefined);
      if (currentNotification?.latitude) setValue("latitude", undefined);
      if (currentNotification?.longitude) setValue("longitude", undefined);
    }
  };

  // toggle validation status
  const onToggle = target => {
    setSkipValidation(!target.checked && !currentNotification.start_at); // used only the start_at property as it is included in the timed and GPS notifications.
    const validateForm = async () => {
      await trigger();
    };
    validateForm();
  };

  const onSubmit = data => {
    if (!localCurrentLanguage.isDefault && displayMode === "template") {
      if (data.localisation?.[localCurrentLanguage.code]) {
        data.localisation[localCurrentLanguage.code].content = data.content;
        data.localisation[localCurrentLanguage.code].url = data.url;
      } else {
        data = {
          ...data,
          localisation: {
            ...data?.localisation,
            [localCurrentLanguage.code]: {
              content: data.content,
              url: data.url,
            },
          },
        };
      }

      data.content = data.localisation[defaultLanguageCode].content;
      data.url = data.localisation[defaultLanguageCode].url;
    }
    if (data?.localisation?.[defaultLanguageCode]) delete data.localisation[defaultLanguageCode];

    data.url = data?.url && data.url !== "" ? transformUrl(data.url.toLowerCase(), true) : null;

    if (!isObjectEmpty(data.localisation)) {
      for (const lng in data.localisation) {
        const { url, content } = data.localisation[lng];
        if (!url && !content) {
          delete data.localisation[lng];
        } else if (url && url !== "") {
          data.localisation[lng].url = transformUrl(url.toLowerCase(), true);
        }
      }
    }

    if (data.coordinates) {
      const { latitude, longitude } = extractCoordinates(data.coordinates);
      data.latitude = latitude;
      data.longitude = longitude;
      delete data.coordinates;
      if (data.location_internal_id === "manually") delete data.location_internal_id;
    }
    if (displayMode === "trip") {
      const templatePropertiesToRemove = ["is_default_on", "localisation"];
      const coordinatePropertiesToRemove = ["coordinates", "latitude", "longitude"];
      templatePropertiesToRemove.forEach(prop => data?.hasOwnProperty(prop) && delete data[prop]);
      coordinatePropertiesToRemove.forEach(prop => data[prop] === undefined && delete data[prop]);
    }

    if (type === "timed") {
      if (moment.isMoment(data.start_at)) data.start_at = data.start_at.format(config.timeFormat);
    } else if (type === "gps") {
      if (moment.isMoment(data.start_at)) data.start_at = data.start_at.format(config.apiDateFormat);
      if (moment.isMoment(data.end_at)) data.end_at = data.end_at.toDate();
    } else {
      data.url = null;
    }

    let updatedNotification = data;
    if (type === "timed") {
      if (!skipValidation) {
        updatedNotification = calculateStartingPointChange(data, data.delivery_at_relative_to);
        if (displayMode === "template") {
          updatedNotification.delivery_at = new Date("1970-01-01T" + updatedNotification.start_at + ":00Z");
          delete updatedNotification.start_at;
        }
      } else {
        delete updatedNotification.delivery_at_days;
        delete updatedNotification.delivery_at_relative_to;
      }
    }
    onNotificationAdd(updatedNotification);
    if (displayMode === "template") {
      setNotificationToModify(updatedNotification);
    } else {
      setNotificationToModify({});
    }
    setInitialValuesSet(false);
  };

  const coordinatesHint = () => {
    const coordinatesInput = watch("coordinates");
    if (!coordinatesInput) return;
    const { latitude, longitude } = extractCoordinates(coordinatesInput);

    return `Latitude: ${latitude} Longitude: ${longitude}`;
  };

  const validateDate = (startAt, endAt) => {
    if (!startAt || !endAt) {
      return true;
    }
    const fromDate = new Date(startAt).setHours(0, 0, 0);
    const toDate = new Date(endAt).setHours(0, 0, 0);
    return fromDate <= toDate;
  };

  const renderInputsByType = () => ({
    timed: (
      <InputsRow showNumberOfDays={showNumberOfDays} type="timed">
        {showNumberOfDays ? (
          <Input
            control={control}
            label="Number of days"
            name="delivery_at_days"
            type="number"
            isClearable={false}
            rules={{
              required: !skipValidation,
              min: { value: minNumberOfDays, message: `Value must be greater than ${minNumberOfDays}` },
              max: { value: maxNumberOfDays, message: `Value must be less than ${maxNumberOfDays}` },
            }}
            disabled={displayMode === "template" && !localCurrentLanguage?.isDefault}
          />
        ) : null}
        <SelectInput
          control={control}
          label="Period"
          name="delivery_at_relative_to"
          options={periods}
          isClearable={false}
          handleChange={value => onChangePeriod(value)}
          menuPlacement="top"
          rules={{ required: !skipValidation }}
          disabled={displayMode === "template" && !localCurrentLanguage?.isDefault}
          isSearchable={false}
        />
        <AtWrap
          hasError={
            !isEmpty(formState.errors?.delivery_at_days) ||
            !isEmpty(formState.errors?.delivery_at_relative_to) ||
            !isEmpty(formState.errors?.start_at)
          }
        >
          at
        </AtWrap>
        <DateTimeInput
          control={control}
          label="Device local time"
          name="start_at"
          type="time"
          format={config.timeFormat}
          rules={{ required: !skipValidation }}
          disabled={displayMode === "template" && !localCurrentLanguage?.isDefault}
        />
      </InputsRow>
    ),
    gps: (
      <InputsRow type="gps">
        <DateTimeInput
          control={control}
          label="Active from"
          name="start_at"
          type="date"
          rules={{ required: !skipValidation }}
          disabled={displayMode === "template" && !localCurrentLanguage?.isDefault}
          {...(skipValidation ? { defaultValue: null } : {})}
        />
        <DateTimeInput
          control={control}
          label="Active to"
          name="end_at"
          type="date"
          rules={{
            required: !skipValidation,
            validate: value => validateDate(watch("start_at"), value) || "This date must be later than From date",
          }}
          disabled={displayMode === "template" && !localCurrentLanguage?.isDefault}
          {...(skipValidation ? { defaultValue: null } : {})}
        />
        <SelectInput
          control={control}
          options={locations}
          label="Location"
          name="location_internal_id"
          isClearable={false}
          handleChange={value => onChangeLocation(value)}
          defaultValue={locations[0].value}
          menuPlacement="top"
          rules={{ required: !skipValidation }}
          styleContainer={(displayMode === "template" || itineraryType === "stay") ? { opacity: "0" } : null}
          disabled={itineraryType === "stay" || (displayMode === "template" && !localCurrentLanguage?.isDefault)}
        />
        <Input
          style={{ display: showCoordinates ? "block" : "none" }}
          control={control}
          label="Coordinates"
          name="coordinates"
          hint={coordinatesHint()}
          rules={{
            required: skipValidation ? false : showCoordinates,
            validate: value => {
              if (!showCoordinates || skipValidation) return;
              const { latitude, longitude } = extractCoordinates(value);
              if (!longitude) return `Latitude: ${latitude} Longitude: is required`;
              const isValidLatitude = Number(latitude) >= -90 && Number(latitude) <= 90;
              const isValidLongitude = Number(longitude) >= -180 && Number(longitude) <= 180;

              return (
                (isValidLatitude && isValidLongitude) ||
                (!isValidLatitude ? "Latitude range must be between -90 and 90" : "Longitude range must be between -180 and 180")
              );
            },
          }}
          disabled={displayMode === "template" && !localCurrentLanguage?.isDefault}
        />
      </InputsRow>
    ),
  });

  const disableInputsWithTooltip = () => (
    <Tooltip title="These settings are only available in the main language" arrow>
      <div style={{ opacity: 0.7 }}>{renderInputsByType()[type]}</div>
    </Tooltip>
  );

  return (
    <BaseModal
      title={modalTitle}
      confirmTitle={confirmLabel}
      onCancel={onClose}
      isLoading={isLoading}
      onConfirm={handleSubmit(onSubmit)}
      modalStyle={{ overflow: "visible" }}
      contentStyle={{ overflowY: "auto" }}
      mobileFullScreen
    >
      {type !== "forced" && (
        <SwitchWrapper>
          <Switch
            style={{ backgroundColor: "rgba(232, 232, 233, 0.3)" }}
            noBackground
            control={control}
            name={isActive}
            handleChange={target => displayMode === "template" && onToggle(target)}
          />
          <ResponsiveHideFrom size="sm">Enable notification</ResponsiveHideFrom>
        </SwitchWrapper>
      )}
      {displayMode === "template" && additionalLanguages.length > 0 && (
        <LanguageSelectorWrapper>
          <LanguageSelector
            currentLanguage={localCurrentLanguage}
            onChange={switchLanguage}
            errors={isEmpty(formState.errors?.content) ? [] : ["default"]}
          />
        </LanguageSelectorWrapper>
      )}
      <Container>
        <Input
          control={control}
          autoFocus
          name="content"
          label="Notification text"
          multiline
          rows={4}
          rules={{
            required: "Notification text is required",
            validate: () => {
              if (displayMode !== "template" || !currentNotification.localisation?.[defaultLanguageCode] || localCurrentLanguage.isDefault)
                return;
              return (
                !!currentNotification.localisation?.[defaultLanguageCode].content || "Notification text is missing in the main language"
              );
            },
          }}
          placeholder={localCurrentLanguage.isDefault ? "" : currentNotification.localisation?.[defaultLanguageCode].content}
        />
        {type !== "forced" && (
          <Input
            control={control}
            name="url"
            label="Notification URL"
            placeholder={localCurrentLanguage.isDefault ? "" : currentNotification.localisation?.[defaultLanguageCode].url}
          />
        )}
        {displayMode === "template" && !localCurrentLanguage.isDefault ? disableInputsWithTooltip() : renderInputsByType()[type]}
      </Container>
    </BaseModal>
  );
};

NotificationModal.defaultProps = {
  isLoading: false,
  skipValidation: false,
  setSkipValidation: () => {},
};

NotificationModal.propTypes = {
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  confirmLabel: PropTypes.string.isRequired,
  onNotificationAdd: PropTypes.func.isRequired,
  notification: PropTypes.shape().isRequired,
  editMode: PropTypes.bool.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  displayMode: PropTypes.string.isRequired,
  setNotificationToModify: PropTypes.func.isRequired,
  skipValidation: PropTypes.bool,
  setSkipValidation: PropTypes.func,
};

export default NotificationModal;
