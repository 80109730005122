import React from "react";
import { colors } from "config/theme/colors";
import { fontWeight } from "config/theme/fonts";
import DeleteButton from "components/_new/DnD/DeleteIcon";
import { MobileItemWrap } from "feature/panel/Itinerary/helpers";
import { convertToDateTimeFormat } from "utils/dataConverters";
import TableAction from "components/_new/Table/Action";
import { PeopleAltOutlined } from "@mui/icons-material";
import takeoff from "../../../../../../../assets/icons/takeoffPlane.png";
import landing from "../../../../../../../assets/icons/landingPlane.png";
import arrow from "../../../../../../../assets/icons/arrow.svg";
import Badge from "../../../../../../../components/_new/Badge";

const Location = ({ icon, isRight, airport, date }) => {
  return (
    <div style={{ display: "flex", textAlign: isRight ? "right" : "left" }}>
      {!isRight && <img src={icon} style={{ width: 20, height: 20, marginRight: 10 }} alt="takeoff" />}
      <div>
        <div style={{ fontWeight: 700 }}>{airport}</div>
        <span style={{ color: colors.grey40, fontSize: 12, fontWeight: fontWeight.semiBold, whiteSpace: "nowrap" }}>{date}</span>
      </div>
      {isRight && <img src={icon} style={{ width: 20, height: 20, marginLeft: 10 }} alt="takeoff" />}
    </div>
  );
};

const MobileItem = ({ item, index, onClick, onDelete, handleTravellersModal, disabled, restrictedFlightsDataByPerson }) => {
  const { carrier_fs_code, carrier_flight_number, departure_airport, carrier, departure_at_utc, arrival_airport, arrival_at_utc } = item;

  const handleClick = () => onClick({ ...item, index });

  const handleDelete = () => onDelete({ ...item, index });

  const onTravellersModalOpen = e => {
    e.stopPropagation();
    handleTravellersModal({ ...item, index });
  };

  return (
    <MobileItemWrap onClick={handleClick}>
      {!disabled && <DeleteButton showMobileVersionFrom="md" onClick={handleDelete} />}
      <div style={{ fontWeight: 700, display: "flex", alignItems: "center", marginTop: -10 }}>
        {carrier_fs_code} {carrier_flight_number} - {carrier.name}{" "}
        <Badge
          badgeContent={restrictedFlightsDataByPerson[item.id]?.length ?? null}
          showZero
          sx={{
            "& .MuiBadge-badge": {
              top: 12,
              right: 28,
            },
          }}
        >
          <TableAction
            element={<PeopleAltOutlined />}
            mobileStyle={{ width: 48, height: 48, border: 0, marginLeft: 15, background: "#fff", marginRight: 10 }}
            onClick={onTravellersModalOpen}
          />
        </Badge>
      </div>

      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: 15 }}>
        <Location
          icon={takeoff}
          airport={departure_airport.iata_code}
          date={convertToDateTimeFormat(departure_at_utc, departure_airport.timezone)}
        />
        <img src={arrow} style={{ marginTop: -15, position: "absolute", left: "50%", transform: "translateX(-50%)" }} alt="arrow" />
        <Location
          icon={landing}
          airport={arrival_airport.iata_code}
          date={convertToDateTimeFormat(arrival_at_utc, arrival_airport.timezone)}
          isRight
        />
      </div>
    </MobileItemWrap>
  );
};

export default MobileItem;
