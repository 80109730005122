import { formFilePayloadObject } from "utils/library";
import { isObjectEmpty } from "utils/object";

export function InspirationRequestDto(inspiration, newOnly = false) {
  const dto = {};

  if (newOnly) {
    dto.new_only = newOnly;
  }

  dto.vamoos_id = inspiration.vamoos_id;
  dto.name = inspiration.name;
  dto.contact_email = inspiration.contact_email;
  dto.contact_phone = inspiration.contact_phone;
  dto.notification_text = inspiration.notification_text;
  dto.operator_code = inspiration.operator_code;
  dto.reference_code = inspiration.reference_code;
  dto.data = {
    rows: inspiration.data?.rows.map(({ id, isNewItem, ...restOfRow }, index) => ({
      ...restOfRow,
      position: index,
      images: restOfRow?.images
        ? restOfRow.images.map(({ description, image, content_type }) => ({
            description,
            content_type: content_type ?? "text/html",
            image: isObjectEmpty(formFilePayloadObject(image)) ? null : formFilePayloadObject(image),
          }))
        : [],
    })),
  };

  return dto;
}
