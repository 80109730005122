import React, { useEffect, useState } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { colors } from "config/theme/colors";
import { Wrap } from "./style";

const ErrorBlock = ({ text, timer, noHide, show, onClose, style }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (visible && !noHide) {
      setTimeout(() => {
        setVisible(false);
        if (onClose) onClose();
      }, timer || 5000);
    }
  }, [visible]);

  useEffect(() => {
    setVisible(show);
  }, [show]);

  if (!visible) return null;

  return (
    <Wrap style={style}>
      <ErrorOutlineIcon style={{ marginRight: 10, marginTop: -1, color: colors.error }} /> {text}
    </Wrap>
  );
};

export default ErrorBlock;
