import { responsiveConfig } from "config/responsive";
import styled from "styled-components";
import { getMediaPx } from "helpers";
import { Wrap as ListItem } from "./ListItem/style";

export const Wrap = styled.div`
  border-right: 1px solid #eaeaea;
  position: sticky;
  top: 0;
  height: ${p => (p.hasAdditionalMenu ? "calc(100vh - 195px)" : "calc(100vh - 130px)")};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    z-index: 12;
    background-color: #fff;
    padding: 20px 15px;
    border-top: 1px solid #eaeaea;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.14);
    width: 100vw;
    height: ${p => p.height100vh - (p.type === "trip" ? 179 : 244)}px;
    top: 165px;
    ${ListItem} {
      height: 40px;
      &:hover {
        background-color: #fff;
      }
    }
  }
`;
