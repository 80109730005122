import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Dialog } from "@material-ui/core";
import PropTypes from "prop-types";
import styled from "styled-components";
import DialogActions from "@material-ui/core/DialogActions";

import { validateWebUrl } from "utils/validation";
import { setNotification } from "store/app/actions";
import { IMAGES_MIME_TYPES, DOCUMENTS_FILE_TYPES, VIDEO_FILE_TYPES, MAX_VIDEO_SIZE_MB, MAX_IMG_SIZE_MB } from "constants/defaults";
import { WebpageUpload, FileUpload, LibrarySection } from "components/ui/LibraryPopup/Components";
import { GhostButton } from "components/ui/Buttons/GhostButton";
import { DialogTransition } from "components/ui/Dialogs";
import { Content } from "components/ui/Content";
import { LeftAlignedTab } from "components/ui/Tabs/LeftAlignedTab";
import { Tabs } from "components/ui/Tabs/Tabs";
import { H6 } from "components/ui/Typography/Typography";
import { colors } from "config/theme/colors";
import { Spinner } from "components/ui/Spinner/Spinner";
import { transformUrl } from "utils/dataConverters";

import { FileUploadService } from "services/FileUploadService";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { decodeFromUrl } from "utils/url";

import { useService } from "hooks/useService";
import { LibraryService } from "services/LibraryService";
import { Logger } from "services/application/Logger";

import { GLOBAL_CONTENT } from "constants/content";
import { useForm } from "react-hook-form";

import { StaySection } from "./Components/Stay/StaySection";
import { FlightsSection } from "./Components/FlightsSection/FlightsSection";
import { onFlightAdd } from "../../../feature/panel/Trips/_shared/Flights/FlightsSearchForm/helpers";
import { UnsplashSection } from "./Components/UnsplashSection/UnsplashSection";

const DialogWrapper = styled.div`
  position: relative;
`;

const ContentWrapper = styled.div`
  padding-right: ${({ theme }) => theme.setSpacing(4)}px;
`;

const TopSection = styled.div`
  box-shadow: ${({ theme }) => theme.shadows.bar};
  padding: ${({ theme }) => theme.setSpacing(6)}px ${({ theme }) => theme.setSpacing(6)}px 0px ${({ theme }) => theme.setSpacing(6)}px;
`;

const DialogCenteredContent = styled.div`
  overflow: inherit;
  background-color: ${colors.withOpacity(colors.grey10, 0.2)};
  /* min-height: 80px; */
  max-height: 90vh;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  /* align-items: center; */
`;
const DialogActionsWithBorder = styled(DialogActions)`
  && {
    padding: ${({ theme }) => theme.setSpacing(6)}px;
    height: 73px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }
`;

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: ${({ theme }) => theme.setSpacing(2)}px;
  }
`;

const WaitingOverlay = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: ${colors.grey50};
  opacity: 0.5;
  align-items: center;
  justify-content: center;
`;

const UPLOAD_FILE_ERROR = "Unable to add new file to the library";
export const DEFAULT_LIBRARY_TABS = {
  library: "library",
  files: "files",
  webUrl: "webUrl",
  stay: "stay",
  flights: "flights",
  unsplash: "unsplash",
};

const TABS_LABELS = {
  library: "Library",
  unsplash: "Unsplash",
  files: "Upload file",
  picker: "Choose file",
  webUrl: "Web page",
  stay: "Stay",
  flights: "Flights",
};

const INVALID_URL_MESSAGE = "Please provide correct web page address, eg. https://www.vamoos.com";

/**
  Example usage of LibraryPopup component:

    <LibraryPopup
      open={libraryPopupOpen}
      tabs={["library", "files", "webUrl"]}
      allowedFileTypes={["image/jpg", "application/json"]}
      multiUpload={false}
      onCancel={() => setLibraryModalOpen(false)}
      onSelect={onSelect}
    />

    onSelect:
    @type     {function}
    @returns  {Array<object>}
      Library Node        => [{ id: number, library_node_id: number, name: string, file_name: string, https_url: string }]
      Uploaded file Node  => [{ name: string, file_name: string, file_url: string, previewUrl: string }]
      Web Url             => [{ web_url: string, name: string, file_name: string }]
*/
const LibraryPopup = ({
  label,
  onCancel,
  allowedFileTypes,
  tabs,
  onSelect,
  multiUpload,
  isLibrary,
  libraryPath,
  onSelectStays,
  flightSectionConfig,
  maxResolution,
  minResolution,
  onlyResolution,
  returnEncoded,
  upload,
  showManualForm,
  setShowManualForm,
  maxFileSize,
  form,
  ...rest
}) => {
  const libraryService = useService(LibraryService);
  const permissionsService = usePermissionsService();
  const maxUploadSizeMB =
    maxFileSize || allowedFileTypes.every(type => IMAGES_MIME_TYPES.some(imgType => imgType === type))
      ? MAX_IMG_SIZE_MB
      : MAX_VIDEO_SIZE_MB;

  const fileUploadService = new FileUploadService(allowedFileTypes, maxResolution, maxUploadSizeMB, minResolution, onlyResolution);

  const canReadLibrary = permissionsService.can(PERMISSIONS.actions.read, PERMISSIONS.sections.operator, PERMISSIONS.resources.library);
  const hasAccessToFlights = permissionsService.can(PERMISSIONS.actions.read, PERMISSIONS.sections.operator, PERMISSIONS.resources.flights);

  const [libraryTabOpen, setLibraryTabOpen] = useState(0);
  const [isWaitingForClose, setIsWaitingForClose] = useState(false);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [selectedStay, setSelectedStay] = useState(null);
  const [selectedFlight, setSelectedFlight] = useState(null);
  const [permitUpload, setPermitUpload] = useState(false);

  const [uploadAssets, setUploadAssets] = useState(null);
  const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);

  const selectedObjects = !uploadAssets ? { libraryFiles: selectedAssets } : uploadAssets;
  const dispatch = useDispatch();

  const handleTabChange = newValue => {
    setSelectedAssets([]);
    setUploadAssets(null);
    setPermitUpload(false);
    setLibraryTabOpen(newValue);
    setShowManualForm?.(false);
  };

  const handleSelectAsset = node => {
    let assets = selectedAssets;
    const { id, name, file, remote_url, active } = node;

    if (!active) {
      const newAsset = { id, name };
      if (file) {
        newAsset.https_url = file.https_url;
        newAsset.file_id = file.id;
        newAsset.s3_url = file.s3_url;
        if (file && file.mime_type) newAsset.type = file.mime_type;
      } else newAsset.remote_url = remote_url;

      if (multiUpload || (!multiUpload && selectedAssets.length === 0)) {
        assets = selectedAssets.concat(newAsset);
      }

      if (!multiUpload && selectedAssets.length === 1) {
        assets = [newAsset];
      }

      setSelectedAssets(assets);
    } else {
      const withRemovedAsset = selectedAssets.filter(eachAsset => eachAsset.id !== id);
      setSelectedAssets(withRemovedAsset);
    }
  };

  const handleSelectedStay = stay => {
    setSelectedStay(stay);
  };

  const handleSelectedFlight = flightData => {
    setSelectedFlight(flightData);
  };

  const handleAddNewEntryBasedOnStay = () => {
    if (selectedStay) {
      onCancel();
      onSelectStays(selectedStay);
      setSelectedStay(null);
    }
  };

  const sections = {
    library: (
      <LibrarySection
        allowedFileTypes={allowedFileTypes}
        handleMiniatureClick={handleSelectAsset}
        selectedAssets={selectedAssets}
        fileUploadService={fileUploadService}
      />
    ),
    unsplash: <UnsplashSection setUploadAssets={setUploadAssets} setPermitUpload={setPermitUpload} />,
    files: (
      <FileUpload
        allowedFileTypes={allowedFileTypes}
        setPermitUpload={setPermitUpload}
        setUploadAssets={setUploadAssets}
        maxUploadSizeMB={maxUploadSizeMB}
        isLibrary={isLibrary}
        libraryPath={libraryPath}
        fileUploadService={fileUploadService}
      />
    ),
    webUrl: <WebpageUpload setPermitUpload={setPermitUpload} setUploadAssets={setUploadAssets} />,
    stay: <StaySection onStaySelect={handleSelectedStay} />,
    flights: (
      <FlightsSection
        searchDate={flightSectionConfig.tripStartDate}
        onFlightAdd={handleSelectedFlight}
        form={form}
        showManualForm={showManualForm}
        setShowManualForm={setShowManualForm}
      />
    ),
  };

  const availableSections = tabs
    .filter(tab => {
      if (tab === "flight" && !hasAccessToFlights) {
        return false;
      }
      if (tab === "library" && !canReadLibrary) {
        return false;
      }
      return true;
    })
    .map(tab => sections[tab]);

  const availableTabs = tabs
    .filter(tab => {
      if (tab === "flight" && !hasAccessToFlights) {
        return false;
      }
      if (tab === "library" && !canReadLibrary) {
        return false;
      }
      return true;
    })
    .map(tab => <LeftAlignedTab key={tab} label={TABS_LABELS[tab]} />);

  const handleAssetsSelect = async values => {
    if (selectedObjects.filesUpload) {
      setShowLoadingOverlay(true);
      try {
        if (upload) {
          let filesUpload = selectedObjects.filesUpload;
          let fileMeta = {};

          if (selectedObjects.isExternalUrl) {
            // for now, we only support 1 entry for isExternalUrl
            const { url, meta } = filesUpload[0];

            const file = await libraryService.imageUrlToFile(url, "external");

            if (meta !== undefined) {
              fileMeta = meta;
            }

            filesUpload = [{ file }];
          }

          const libraryUploadedFiles = await Promise.all(filesUpload.map(async ({ file }) => libraryService.uploadFileToS3(file)));

          if (selectedObjects.onUpload) {
            await selectedObjects.onUpload();
          }

          const mappedUploadedFiles = libraryUploadedFiles.map(({ file_url, preview_url, type }) => {
            const encodedFileName = file_url.split("/").pop();
            const file_name = decodeFromUrl(encodedFileName);
            let name = file_name;

            if (file_name.includes(".")) {
              name = file_name.split(".")[0];
            }

            return {
              file_url: returnEncoded ? file_url : file_url.replace(encodedFileName, file_name),
              file_name,
              name,
              preview_url,
              file_meta: fileMeta,
              type,
            };
          });

          onSelect(mappedUploadedFiles);
        } else {
          onSelect(selectedObjects.filesUpload);
        }
        onCancel();
        setShowLoadingOverlay(false);
      } catch (e) {
        Logger.debug(e);
        onCancel();
        dispatch(
          setNotification({
            type: "error",
            message: UPLOAD_FILE_ERROR,
          }),
        );
        setShowLoadingOverlay(false);
      }
    }

    if (selectedObjects.webpageUrl) {
      const { webpageUrl } = selectedObjects;
      try {
        const transformedWebUrl = transformUrl(webpageUrl, true);
        const validUrl = validateWebUrl(transformedWebUrl);

        const file_name = webpageUrl
          .split("/")
          .filter(part => !!part)
          .pop();

        onCancel();
        onSelect([{ web_url: validUrl, name: validUrl, file_name }]);
      } catch (error) {
        dispatch(
          setNotification({
            type: "error",
            message: INVALID_URL_MESSAGE,
          }),
        );
      }
    }

    if (selectedObjects.libraryFiles?.length) {
      onCancel();
      onSelect(
        selectedObjects.libraryFiles.map(({ id, file_id, name, https_url, remote_url, type, s3_url }) => {
          const [filename] = name.split(".");

          return {
            id,
            file_id,
            library_node_id: id,
            name: filename,
            file_name: name,
            https_url: https_url || remote_url,
            is_library_file: true,
            type,
            s3_url,
          };
        }),
      );
      setSelectedAssets([]);
    }

    if (showManualForm) {
      const flight = await onFlightAdd(values);
      const { date: flightDate, fs: airlineCode, number: flightNumber, selectedFlights } = flight;
      await flightSectionConfig.onSelectFlight({ airlineCode, flightNumber, flightDate, selectedFlights, noDateFormat: true });
      await setSelectedFlight(null);
      await onCancel();
    }

    if (selectedFlight) {
      setIsWaitingForClose(true);
      await flightSectionConfig.onSelectFlight(selectedFlight);
      await setSelectedFlight(null);
      await onCancel();
    }

    form?.reset();
    if (setShowManualForm) setShowManualForm(false);

    setIsWaitingForClose(false);

    return Promise.resolve(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "escapeKeyDown" || reason === "closeButtonClick") {
      setSelectedAssets([]);
      onCancel();
    }
  };

  const dataFromLibrarySelected = !selectedAssets.length && !selectedStay && !selectedFlight;

  const renderWaitingOverlay = () => {
    return (
      <WaitingOverlay>
        <Spinner />
      </WaitingOverlay>
    );
  };

  const isSelectDisabled = () => {
    if (showManualForm) return false;
    if (isWaitingForClose) {
      return true;
    }
    return uploadAssets ? !permitUpload : dataFromLibrarySelected;
  };

  useEffect(handleAddNewEntryBasedOnStay, [selectedStay]);
  useEffect(() => {}, [selectedAssets]);

  return (
    <StyledDialog TransitionComponent={DialogTransition} onClose={handleClose} maxWidth="md" fullWidth {...rest}>
      <DialogWrapper>
        <TopSection>
          <Content noMargin>
            <H6>{label}</H6>
            <Tabs value={libraryTabOpen} onChange={(_, newValue) => handleTabChange(newValue)} aria-label="library tabs choice selection">
              {availableTabs}
            </Tabs>
          </Content>
        </TopSection>
        <ContentWrapper />
      </DialogWrapper>
      <DialogCenteredContent>{availableSections[libraryTabOpen]}</DialogCenteredContent>
      <DialogActionsWithBorder>
        <GhostButton align="right" cv="grey40" cvh="grey60" onClick={() => handleClose(null, "closeButtonClick")}>
          {GLOBAL_CONTENT.cancel}
        </GhostButton>
        <GhostButton
          align="right"
          onClick={form ? form.handleSubmit(handleAssetsSelect) : handleAssetsSelect}
          disabled={isSelectDisabled()}
        >
          {GLOBAL_CONTENT.select}
        </GhostButton>
      </DialogActionsWithBorder>
      {(showLoadingOverlay || isWaitingForClose) && renderWaitingOverlay()}
    </StyledDialog>
  );
};

LibraryPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSelectStays: PropTypes.func,
  flightSectionConfig: PropTypes.shape({
    tripStartDate: PropTypes.string,
    onSelectFlight: PropTypes.func,
  }),
  multiUpload: PropTypes.bool,
  tabs: PropTypes.arrayOf(
    PropTypes.oneOf([
      DEFAULT_LIBRARY_TABS.library,
      DEFAULT_LIBRARY_TABS.unsplash,
      DEFAULT_LIBRARY_TABS.files,
      DEFAULT_LIBRARY_TABS.webUrl,
      DEFAULT_LIBRARY_TABS.stay,
      DEFAULT_LIBRARY_TABS.flights,
    ]),
  ),
  allowedFileTypes: PropTypes.arrayOf(PropTypes.string),
  isLibrary: PropTypes.bool,
  libraryPath: PropTypes.string,
  label: PropTypes.string,
  maxResolution: PropTypes.string,
  returnEncoded: PropTypes.bool,
  upload: PropTypes.bool,
};

LibraryPopup.defaultProps = {
  label: "Add file",
  maxResolution: null,
  multiUpload: false,
  tabs: [DEFAULT_LIBRARY_TABS.library, DEFAULT_LIBRARY_TABS.files, DEFAULT_LIBRARY_TABS.webUrl],
  allowedFileTypes: [...IMAGES_MIME_TYPES, ...DOCUMENTS_FILE_TYPES, ...VIDEO_FILE_TYPES],
  isLibrary: false,
  flightSectionConfig: {
    tripStartDate: null,
    onSelectFlight: () => {},
  },
  onSelectStays: () => {},
  libraryPath: null,
  returnEncoded: true,
  upload: true,
};

export { LibraryPopup, StyledDialog, DialogWrapper, LeftAlignedTab };
