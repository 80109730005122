import React from "react";
import { Entries } from "feature/panel/Trips/_shared/Storyboard/Entries/Entries";
import { HomeScreenIcons } from "feature/panel/Trips/_shared/Storyboard/HomeScreenIcons/HomeScreenIcons";

const Storyboard = () => {
  return (
    <>
      <HomeScreenIcons />
      <Entries />
    </>
  );
};

export { Storyboard };
