import { useState, useEffect, useCallback } from "react";

const useSticky = (reference, position, offset) => {
  const [isSticky, setIsSticky] = useState();
  const getPositionFor = useCallback(ref => (ref.current ? ref.current.getBoundingClientRect() : {}), []);

  const setSticky = useCallback(() => {
    let cleanup = true;
    const { top, bottom } = getPositionFor(reference);

    const getOffset = offset || 0;
    const getPosition = position || "top";

    const conditions = {
      top: top + 10 <= getOffset,
      bottom: bottom >= getOffset,
    };

    if (conditions[getPosition]) {
      if (cleanup) setIsSticky(true);
    } else if (cleanup) setIsSticky(false);

    return () => {
      cleanup = false;
    };
  }, [setIsSticky, position, getPositionFor, offset, reference]);

  useEffect(() => {
    window.addEventListener("scroll", setSticky);

    return () => {
      window.removeEventListener("scroll", setSticky, true);
    };
  }, [setSticky, isSticky]);

  return isSticky;
};

export { useSticky };
