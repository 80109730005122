import React from "react";
import PropTypes from "prop-types";
import TableCell from "@material-ui/core/TableCell";
import styled from "styled-components";

import { TableRow } from "components/ui/Tables";
import { TextLimiter } from "components/ui/TextLimiter";
import { ImagePreview, ImagePlaceholder } from "components/ui/Images";
import { convertToFileObject } from "utils/dataConverters";
import { getFilePreviewUrl } from "utils/library";
import { PANEL_INSPIRATIONS_EDIT_PATH } from "constants/routes";
import { setUrl } from "utils/url";
import { LinkedTableRowCell } from "components/ui/Tables/LinkedTableRowCell";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

const PreviewCell = styled(TableCell)`
  && {
    padding: ${({ theme }) => `${theme.setSpacing(2)}px ${theme.setSpacing(3)}px`};
  }
`;

const InspirationRowTemplate = ({ item }) => {
  const permissionsService = usePermissionsService();

  const canRead = permissionsService.can(PERMISSIONS.actions.read, PERMISSIONS.sections.operator, PERMISSIONS.resources.inspirations);
  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.operator, PERMISSIONS.resources.inspirations);

  const { data, name, operator_code, reference_code, contact_email, contact_phone } = item;
  const url = setUrl(PANEL_INSPIRATIONS_EDIT_PATH, { userId: operator_code, passcode: reference_code });

  const image =
    data?.rows.length > 0 &&
    data?.rows[0] &&
    data?.rows[0].images[0] &&
    data?.rows[0].images[0].image &&
    convertToFileObject(data?.rows[0].images[0].image);

  const previewImage = image ? <ImagePreview height="40px" image={getFilePreviewUrl(image)} /> : <ImagePlaceholder />;

  const nameCell =
    canRead && !canEdit ? (
      <LinkedTableRowCell url={url}>
        <TextLimiter text={name} maxChar={50} />
      </LinkedTableRowCell>
    ) : (
      <TableCell>
        <TextLimiter text={name} maxChar={50} />
      </TableCell>
    );

  const operatorCodeCell =
    canRead && !canEdit ? (
      <LinkedTableRowCell url={url}>{operator_code}</LinkedTableRowCell>
    ) : (
      <TableCell width="100px">{operator_code}</TableCell>
    );
  const referenceCodeCell =
    canRead && !canEdit ? (
      <LinkedTableRowCell width="100px" url={url}>
        {reference_code}
      </LinkedTableRowCell>
    ) : (
      <TableCell width="100px">{reference_code}</TableCell>
    );
  const contactEmailCell =
    canRead && !canEdit ? <LinkedTableRowCell url={url}>{contact_email}</LinkedTableRowCell> : <TableCell>{contact_email}</TableCell>;
  const contactPhoneCell =
    canRead && !canEdit ? <LinkedTableRowCell url={url}>{contact_phone}</LinkedTableRowCell> : <TableCell>{contact_phone}</TableCell>;

  return (
    <TableRow item={item}>
      <PreviewCell width="80px">{previewImage}</PreviewCell>
      {nameCell}
      {operatorCodeCell}
      {referenceCodeCell}
      {contactEmailCell}
      {contactPhoneCell}
    </TableRow>
  );
};

InspirationRowTemplate.propTypes = {
  item: PropTypes.shape().isRequired,
};

export { InspirationRowTemplate };
