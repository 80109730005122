import { takeEvery, put, call, select, take } from "redux-saga/effects";
import uuidv4 from "uuid";

import * as actionTypes from "store/trips/actionTypes";
import {
  getTripsSuccess,
  getTripsFail,
  getTripCreationsSuccess,
  getTripCreationsFail,
  getAirlineCodesSuccess,
  getAirlineCodesFail,
  createOrUpdateTripSuccess,
  createOrUpdateTripFail,
  searchFlightsSuccess,
  searchFlightsFail,
  copyTripFail,
  copyTripSuccess,
  createTripCreationSuccess,
  createTripCreationFail,
  deleteTripCreationSuccess,
  deleteTripCreationFail,
  refreshTripCreationSuccess,
  refreshTripCreationFail,
  tripsAddSelectedFlightsSuccess,
  tripsAddSelectedFlightsFail,
  checkIsPasscodeAvailableSuccess,
  getRecentLocationSuccess,
  getRecentLocationFail,
  getPublicStaysSuccess,
  getPublicStaysFail,
} from "store/trips/actions";

import { setUrl, getFlightsMapUrl, setUrlParams } from "utils/url";
import { handleErrorMessages } from "utils/store";
import { prepareDataToCopyingTrip } from "utils/dataConverters";
import { HttpClient } from "services/application/httpClient/httpClient";
import { MINIMUM_PASSCODE_LENGTH } from "constants/defaults";

import {
  ITINERARY_URL,
  DELETE_CREATRION,
  FLIGHTS_AIRLINES,
  FLIGHTS_LEGS,
  FLIGHTS_LEGS_DETAILS,
  CREATRIONS_URL,
  RECENT_LOCATIONS_URL,
  GET_PUBLIC_STAYS_URL,
} from "constants/api";

import { ERRORS } from "constants/content";
import notifications, { flightsAddedMessage, copyItinerarySuccess } from "constants/notifications";

import { tripInitialState } from "feature/panel/Trips/_shared/tripInitialState";
import { getSectionsWithErrorsNames } from "feature/panel/_shared/helpers";

import { setNotification, clearActionFlags, showSavingCover, hideSavingCover } from "store/app/actions";
import { createTripHandler, TRIPS_SECTION_ERRORS } from "feature/panel/Trips/_shared/helpers";

import { Trip } from "domain/Trip";
import { TripRequestDto } from "dto/TripRequestDto";
import { CreationsService } from "services/CreationsService";
import { getCurrentUserFail, getCurrentUserSuccess, setCurrentOperatorCode } from "store/auth/actions";
import { AuthService } from "services/AuthService";
import { TripService } from "services/TripService";
import { PasscodeAlreadyUsedException } from "exceptions/PasscodeAlreadyUsedException";

function* refreshCurrentUserData() {  
  try {
    const authService = new AuthService();
    const user = yield authService.getCurrentUser();
    const token = yield authService.getToken();
    const currentOperatorCode = yield authService.getCurrentOperatorCode();

    yield put(setCurrentOperatorCode(currentOperatorCode));
    yield put(
      getCurrentUserSuccess({
        user,
        operators: user.operators,
        token,
      }),
    );
  } catch (e) {
    yield put(getCurrentUserFail(e));
  }
}

function* getTrips({ payload }) {
  const { url, searchMode } = payload;
  if (searchMode) HttpClient.cancelRequest();
  try {
    const response = yield HttpClient.get(url);
    if (response) {
      const { items, total_matches } = response.data;
      yield put(getTripsSuccess({ items, total_matches, searchMode }));
    }
  } catch (e) {
    if (!HttpClient.isCancelError(e)) {
      yield put(getTripsFail({ errors: handleErrorMessages(e), searchMode }));
    }
  }
}

function* getTripCreations({ payload }) {
  const { url } = payload;
  try {
    const response = yield HttpClient.get(url);
    const { total_matches, items } = response.data;

    yield put(getTripCreationsSuccess({ count: total_matches, items }));
  } catch (e) {
    yield put(getTripCreationsFail(handleErrorMessages(e)));
  }
}

function* createTripCreation({ payload }) {
  const { url, data } = payload;
  try {
    yield HttpClient.post(url, data);
    const response = yield HttpClient.get(url);
    const { total_matches, items } = response.data;
    yield put(createTripCreationSuccess({ count: total_matches, items }));
    yield put(
      setNotification({
        type: "success",
        message: notifications.resource("creation")[data.id ? "update" : "create"].success,
      }),
    );
  } catch (e) {
    yield put(createTripCreationFail(handleErrorMessages(e)));
    yield put(
      setNotification({
        type: "error",
        message: notifications.resource("creation")[data.id ? "update" : "create"].fail,
      }),
    );
  }
}

function* deleteTripCreation({ payload }) {
  const { reference_code, operator_code, id } = payload;
  try {
    yield HttpClient.delete(setUrl(DELETE_CREATRION, { reference_code, operator_code, id }, true));
    const { listOfCreations } = yield select(state => state.trips);
    const newList = listOfCreations.filter(creation => creation.id !== id);
    yield put(deleteTripCreationSuccess(newList));
    yield put(
      setNotification({
        type: "success",
        message: notifications.resource("creation").delete.success,
      }),
    );
  } catch (e) {
    put(deleteTripCreationFail(e));
  }
}

function* refreshTripCreation({ payload }) {
  const { data, url } = payload;
  try {
    const { listOfCreations } = yield select(state => state.trips);
    const response = yield HttpClient.post(url, data);
    const newListOfCreations = listOfCreations.map(creation =>
      creation.id === data.id ? { ...creation, job: { ...creation.job, status: response.data.status } } : creation,
    );

    yield put(refreshTripCreationSuccess(newListOfCreations));
    yield put(
      setNotification({
        type: "success",
        message: notifications.resource("creation").refresh.success,
      }),
    );
  } catch (e) {
    put(refreshTripCreationFail(e));
  }
}

function* getAirlineCodes() {
  try {
    const response = yield HttpClient.get(FLIGHTS_AIRLINES);
    if (response) {
      const { data } = response;
      const airlineCodes = data.map(({ iata, fs, name }) => ({
        value: iata || fs,
        label: `${name} ${iata ? `(${iata})` : ""}`,
        key: uuidv4(),
      }));
      yield put(getAirlineCodesSuccess(airlineCodes));
    }
  } catch (e) {
    yield put(getAirlineCodesFail(handleErrorMessages(e)));
  }
}

function* createOrUpdateTrip({ payload, noMessage, operator }) {
  const tripService = new TripService();

  const {
    data: { version },
  } = payload;

  delete payload.data.version;

  const { data, referenceCode, operatorCode, context } = payload;

  const requestPayload = { ...data, new_only: context === "create" };

  yield put(showSavingCover());

  try {
    const response = yield tripService.saveTrip(operatorCode, referenceCode, requestPayload, operator);
    if (response) {
      const {
        data: { version: newVersion },
      } = response;

      yield call(refreshCurrentUserData);
      if (newVersion <= version) {
        yield put(
          setNotification({
            type: "error",
            message: "Versions mismatch, refresh the page",
          }),
        );
      }
      yield put(createOrUpdateTripSuccess(response));
      if (!noMessage)
        yield put(
          setNotification({
            type: "success",
            message: notifications.resource("trips")[context].success,
          }),
        );

      yield put(clearActionFlags("trips"));
    }

    yield put(hideSavingCover());
  } catch (e) {
    const errors = handleErrorMessages(e);
    if (typeof errors === "string" && e.response.status === 403) {
      yield put(createOrUpdateTripFail({ passcode: ERRORS.isUnique("Passcode") }));
    } else {
      yield put(createOrUpdateTripFail(errors));
    }
    // TODO: check why catch  block does not work for copying trips
    // if (!noMessage)
    yield put(
      setNotification({
        type: "error",
        message: notifications.resource("trips")[context].fail,
      }),
    );

    yield put(hideSavingCover());
  }
}

function* searchFlights({ payload }) {
  const { fs, number, date } = payload;
  try {
    const response = yield HttpClient.get(setUrl(FLIGHTS_LEGS, { fs, number, date }));
    const { data } = response;
    const flights = Array.isArray(data) ? data.map(flight => ({ id: uuidv4(), ...flight })) : [];
    yield put(searchFlightsSuccess(flights));
  } catch (e) {
    const errors = handleErrorMessages(e);
    yield put(searchFlightsFail(errors));
  }
}

function* addSelectedFlights({ payload }) {
  const { fs, number, date, selectedFlights } = payload;

  try {
    const flights = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const flight of selectedFlights) {
      const { departure_airport, arrival_airport } = flight;
      const flightDetails = yield HttpClient.get(
        setUrl(FLIGHTS_LEGS_DETAILS, {
          fs,
          number,
          date,
          from: departure_airport.fs_code || departure_airport,
          to: arrival_airport.fs_code || arrival_airport,
        }),
      );
      const { data: flightData } = flightDetails;
      flightData[0].map = getFlightsMapUrl(departure_airport.fs_code || departure_airport, arrival_airport.fs_code || arrival_airport);
      // initially to allow all travellers access to the flight
      flightData[0].restricted_to_traveller_ids = null;
      flightData[0].restricted_to_traveller_internal_ids = null;
      if (flightDetails) flights.push(flightData[0]);
    }

    yield put(tripsAddSelectedFlightsSuccess(flights));
    const message = flightsAddedMessage(selectedFlights.length);
    yield put(
      setNotification({
        type: "success",
        message,
      }),
    );
  } catch (e) {
    yield put(tripsAddSelectedFlightsFail(handleErrorMessages(e)));
  }
}

function* copyTrip({ payload }) {
  const tripService = new TripService();

  const { passcode, oldPasscode, userId, oldUserId, operator, copyToOtherOperator, sections } = payload;

  try {
    const data = yield tripService.getTrip(oldUserId, oldPasscode);
    if (data) {
      const dataToTransfer = Trip(data);
      const dataTransferredToBeCopied = prepareDataToCopyingTrip(sections, dataToTransfer, tripInitialState);

      dataTransferredToBeCopied.copiedFrom = data.id;
      const payloadObject = {
        data: TripRequestDto(dataTransferredToBeCopied, copyToOtherOperator),
        referenceCode: passcode,
        operatorCode: userId,
      };

      // Based on copyTrip saga but should be refactored to use services

      const { data: dataToCopy, referenceCode, operatorCode } = payloadObject;
      const requestPayload = { ...dataToCopy, new_only: true };

      yield put(showSavingCover());

      try {
        const copyResponse = yield tripService.saveTrip(operatorCode, referenceCode, requestPayload, operator);
        if (copyResponse) {
          yield call(refreshCurrentUserData);
          yield put(clearActionFlags("trips"));
        }

        yield put(hideSavingCover());
      } catch (e) {
        const errors = handleErrorMessages(e);
        if (typeof errors === "string" && e.response.status === 403) {
          yield put(createOrUpdateTripFail({ passcode: ERRORS.isUnique("Passcode") }));
        } else {
          yield put(createOrUpdateTripFail(errors));
        }
        // TODO: check why catch  block does not work for copying trips
        // if (!noMessage)
        yield put(
          setNotification({
            type: "error",
            message: notifications.resource("trips").create.fail,
          }),
        );

        yield put(hideSavingCover());
      }

      const { errors } = yield select(state => state.trips);
      take([actionTypes.CREATE_OR_UPDATE_TRIP_SUCCESS]);
      if (!errors.passcode) {
        if (sections.includes("creations")) {
          const setCreationsUrl = setUrl(CREATRIONS_URL, { operator_code: oldUserId, reference_code: oldPasscode }, true);
          const creations = yield HttpClient.get(setUrlParams(setCreationsUrl, { count: 5000 }));
          const {
            data: { items },
          } = creations || {};

          if (items && items.length > 0) {
            // eslint-disable-next-line no-restricted-syntax
            for (const item of items) {
              const setNewCreationsUrl = setUrl(CREATRIONS_URL, { operator_code: userId, reference_code: passcode }, true);
              const convertedItem = CreationsService.setupExistingCreation(item);
              delete convertedItem.id;
              yield HttpClient.post(setNewCreationsUrl, CreationsService.setupCreationPayload(convertedItem));
            }
          }
        }
        yield put(copyTripSuccess());
        yield put(
          setNotification({
            type: "success",
            message: copyItinerarySuccess("Vamoos"),
          }),
        );
      } else {
        yield put(copyTripFail(errors));
      }
    }
  } catch (e) {
    const errors = handleErrorMessages(e);
    yield put(copyTripFail(errors));
    yield put(
      setNotification({
        type: "error",
        message: ERRORS.copyVamoos("Vamoos"),
      }),
    );
  }
}

function* toggleTripActiveStatus({ payload }) {
  const { reference_code, operator_code, dispatcher } = payload;
  const url = setUrl(ITINERARY_URL, { operator: operator_code, code: reference_code }, true);
  try {
    const { data } = yield HttpClient.get(url);
    const updateTripPayload = {
      ...Trip(data),
      passcode: reference_code,
      is_active: !data.is_active,
    };
    createTripHandler(updateTripPayload, () => true, dispatcher);
  } catch {
    put(setNotification({ type: "error", message: ERRORS.failedUpdate("itinerary") }));
  }
}

function* checkPasscodeValidation({ payload }) {
  let errorMessage = null;
  const { passcode, userId, vamoosId } = payload;

  const tripService = new TripService();

  if (!passcode) {
    errorMessage = ERRORS.isRequired("passcode");
  }
  if (passcode.toLowerCase() === "nested") {
    errorMessage = ERRORS.reservedPasscode(passcode);
  } else if (passcode && passcode.length < MINIMUM_PASSCODE_LENGTH) {
    errorMessage = ERRORS.isTooShort(MINIMUM_PASSCODE_LENGTH);
  } else {
    const { currentOperatorCode } = yield select(state => state.auth);
    const operatorCode = currentOperatorCode === userId ? currentOperatorCode : userId;

    try {
      yield tripService.checkIfPasscodeIsTaken(operatorCode, passcode, vamoosId);
    } catch (e) {
      if (e instanceof PasscodeAlreadyUsedException) {
        errorMessage = ERRORS.isUnique("passcode");
      }
    }
  }
  yield put(checkIsPasscodeAvailableSuccess(errorMessage));
}

function* createOrUpdateTripFailHandler() {
  const { errors } = yield select(state => state.trips);

  const [errorSections, isPlural] = getSectionsWithErrorsNames(errors, TRIPS_SECTION_ERRORS);
  yield put(setNotification({ type: "error", message: ERRORS.saveActionError(errorSections, isPlural) }));
}

function* getPublicStays() {
  try {
    const url = setUrlParams(GET_PUBLIC_STAYS_URL, { count: 5000 });
    const { data } = yield HttpClient.get(url);
    yield put(getPublicStaysSuccess(data.items));
  } catch (e) {
    const errors = handleErrorMessages(e);
    yield put(getPublicStaysFail(errors));
  }
}

function* getRecentLocations() {
  try {
    const url = setUrlParams(RECENT_LOCATIONS_URL, { count: 5000, exclude_nested: 1 });
    const { data } = yield HttpClient.get(url);
    yield put(getRecentLocationSuccess(data.items));
  } catch (e) {
    const errors = handleErrorMessages(e);
    yield put(getRecentLocationFail(errors));
  }
}

export function* tripsSaga() {
  yield takeEvery(actionTypes.TRIPS_GET_TRIPS_START, getTrips);
  yield takeEvery(actionTypes.TRIPS_GET_TRIP_CREATIONS_START, getTripCreations);
  yield takeEvery(actionTypes.CREATE_TRIP_CREATION_START, createTripCreation);
  yield takeEvery(actionTypes.DELETE_TRIP_CREATION_START, deleteTripCreation);
  yield takeEvery(actionTypes.REFRESH_TRIP_CREATION_START, refreshTripCreation);
  yield takeEvery(actionTypes.TRIPS_GET_AIRLINE_CODES_START, getAirlineCodes);
  yield takeEvery(actionTypes.CREATE_OR_UPDATE_TRIP_START, createOrUpdateTrip);
  yield takeEvery(actionTypes.CREATE_OR_UPDATE_TRIP_VALIDATION_FAIL, createOrUpdateTripFailHandler);
  yield takeEvery(actionTypes.TRIPS_SEARCH_FLIGHTS_START, searchFlights);
  yield takeEvery(actionTypes.TRIPS_ADD_SELECTED_FLIGHTS_START, addSelectedFlights);
  yield takeEvery(actionTypes.COPY_TRIP_START, copyTrip);
  yield takeEvery(actionTypes.TOGGLE_TRIP_ACTIVE_STATUS_START, toggleTripActiveStatus);
  yield takeEvery(actionTypes.CLEAR_TRIP_CREATION_JOB, getTripCreations);
  yield takeEvery(actionTypes.CHECK_PASSCODE_AVAILABILITY_START, checkPasscodeValidation);
  yield takeEvery(actionTypes.GET_RECENT_LOCATION_START, getRecentLocations);
  yield takeEvery(actionTypes.GET_PUBLIC_STAYS_START, getPublicStays);
}
