import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Dialog } from "@material-ui/core";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { Spinner } from "components/ui/Spinner/Spinner";

import { isObject } from "contracts/types";

import { StoryboardFile } from "domain/StoryboardFile";

import { H6 } from "components/ui/Typography/Typography";

import { useRefreshedFileUrl } from "hooks/useRefreshedFileUrl";

import { getFilePreviewUrl } from "utils/library";
import { PdfView } from "../PdfView/PdfView";

const StyledDialog = styled(Dialog)`
  && {
    ${({ isOpen }) =>
      isOpen
        ? `
      &:parent {
        overflow: hidden !important;
      }
    `
        : null}
  }
`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.setSpacing(6)}px;
  height: ${({ theme }) => theme.setSpacing(19)}px;
  contain: size;
`;

const CloseButton = styled(CloseOutlinedIcon)`
  && {
    font-size: ${({ theme }) => theme.fonts.fontSize.body}px;
    color: ${({ theme }) => theme.colors.grey50};
    cursor: pointer;
  }
`;

const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-height: 75vh;
    max-width: 100%;
    width: auto;
    height: auto;
  }
`;

const Image = styled.img`
  display: ${({ loading }) => (loading === "true" ? "none" : "block")};
`;

const Video = styled.video`
  max-height: 75vh;
  max-width: 100%;
  width: auto;
  height: auto;
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 80vh;
  border: 0;

  body {
    text-align: center;
  }
`;

const PreviewTitle = styled(H6)`
  width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const SpinnerContainer = styled.div`
  height: 150px;
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PreviewModal = ({ file, name, id, preventDialogFullWidth, onClose }) => {
  const fileUrl = useRefreshedFileUrl(getFilePreviewUrl(file));
  const videoRef = useRef();
  const dialogRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);

  useEffect(() => {
    setIsOpen(!!dialogRef.current);
  }, [dialogRef]);

  useEffect(() => {
    const isLoadingRequired =
      isObject(file) &&
      (/(image\/jpg|image\/jpeg|image\/png|image\/gif)/.test(file.mime_type) || /[\w,\s-]+\.(jpg|jpeg|png|gif)/.test(file.short_name));
    if (isLoadingRequired) setImageLoading(true);
  }, [file]);

  if (!isObject(file)) return null;

  const onVideoLoaded = () => videoRef && videoRef.current && videoRef.current.load();

  const ariaCoreName = `${name}-${id}`;
  const isFullWidthRequired = () => {
    return /(application\/pdf)/.test(file.mime_type) || /[\w,\s-]+\.pdf/.test(file.short_name) ? true : !preventDialogFullWidth;
  };

  const onImageLoaded = () => {
    setImageLoading(false);
  };

  const onImageError = () => {
    setImageLoading(false);
  };

  const renderSpinner = () => (
    <SpinnerContainer>
      <Spinner size={60} />
    </SpinnerContainer>
  );

  const previewFile = () => {

    if(file.mime_type === StoryboardFile.FILE_TYPE) {
      return false;
    }

    if (/(application\/pdf)/.test(file.mime_type) || /[\w,\s-]+\.pdf/.test(file.short_name)) {
      return <PdfView fileUrl={fileUrl} />
    }

    if (/(video\/mp4)/.test(file.mime_type) || /[\w,\s-]+\.mp4/.test(file.short_name)) {
      return (
        <Video ref={videoRef} onloadeddata={onVideoLoaded} controls>
          <source src={fileUrl} type={file.mime_type} />
        </Video>
      );
    }

    if (/(image\/jpg|image\/jpeg|image\/png|image\/gif)/.test(file.mime_type) || /[\w,\s-]+\.(jpg|jpeg|png|gif)/.test(file.short_name)) {
      return (
        <>
          {imageLoading && renderSpinner()}
          <ImgContainer>
            <Image
              id={ariaCoreName}
              alt={ariaCoreName}
              src={fileUrl}
              loading={imageLoading.toString()}
              onLoad={onImageLoaded}
              onError={onImageError}
            />
          </ImgContainer>
        </>
      );
    }

    return <Iframe id={ariaCoreName} title={ariaCoreName} src={fileUrl} />;
  };

  return (
    fileUrl && file.mime_type !== StoryboardFile.FILE_TYPE && (
      <StyledDialog
        open={fileUrl !== ""}
        onClose={onClose}
        maxWidth="lg"
        isOpen={isOpen}
        ref={dialogRef}
        aria-labelledby={`${ariaCoreName}-title`}
        aria-describedby={`${ariaCoreName}-description`}
        fullWidth={isFullWidthRequired()}
        disableBackdropClick
      >
        <HeaderWrapper>
          <PreviewTitle>{name}</PreviewTitle>
          <CloseButton onClick={onClose} />
        </HeaderWrapper>
        {previewFile()}
      </StyledDialog>
    )
  );
};

PreviewModal.defaultProps = {
  file: null,
  preventDialogFullWidth: false,
};

PreviewModal.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    mime_type: PropTypes.string,
    short_name: PropTypes.string,
    outputs: PropTypes.shape({
      web: PropTypes.shape({
        file: PropTypes.shape({
          remote_url: PropTypes.string,
          https_url: PropTypes.string,
        }),
      }),
    }),
  }),
  onClose: PropTypes.func.isRequired,
  preventDialogFullWidth: PropTypes.bool,
};

export { PreviewModal, HeaderWrapper, CloseButton, Iframe, PreviewTitle, StyledDialog };
