import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import RefreshIcon from "@material-ui/icons/Refresh";
import { ArrowBackIos } from "@material-ui/icons";

import { ReactComponent as ShareIcon } from "assets/images/previewIcons/share.svg";
import presentationBg from "assets/images/ca_menu_bg.jpg";

import { GLOBAL_CONTENT } from "constants/content";
import { firstLetterUpperCase } from "utils/string";

import { PhonePresentation, blurredBgImage, bgDimmed, PhoneHeader, LeftSlot, MiddleSlot, RightSlot } from "../PhonePresentation";
import { WEATHER_CONTENT } from "../constants/customAppStatus";

const WeatherContent = styled.div`
  position: relative;
  z-index: 3;
`;

const WeatherWrapper = styled.div`
  height: 100%;
  color: #ffffff;
  ${({ bgImage }) => blurredBgImage(bgImage)};
  ${({ theme }) => bgDimmed(theme)}
`;
const ForecastWrapper = styled.div`
  margin: 0 0 0 ${({ theme }) => theme.setSpacing(1.25)}px;
`;
const ForecastLocation = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.setSpacing(3)}px;
  padding-top: 13px;
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.white};
`;

const WeatherDayHeader = styled.div`
  font-size: 13px;
  font-weight: 500;
  margin: ${({ theme }) => `${theme.setSpacing(1.5)}px 0 ${theme.setSpacing(1.5)}px ${theme.setSpacing(1.5)}px`};
`;
const WeatherDayWrapper = styled.div`
  ${({ dailyForecastCollapsed, theme }) =>
    dailyForecastCollapsed
      ? `
        text-align: left;
        height: 80px;
    `
      : `
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      height: 33px;
     
      img {
        width: ${theme.setSpacing(6.25)}px;
        height: ${theme.setSpacing(6.25)}px;
      }
      span {
        font-size: ${theme.setSpacing(3.25)}px;
      }
      div {
        align-items: center;
        display: flex;
        padding: 0 ${theme.setSpacing(1.5)}px 0 0;
      }  
    `}
`;
const WeatherHoursList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: ${({ theme }) => theme.setSpacing(6)}px;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 ${({ theme }) => theme.setSpacing(1.5)}px;
  }

  small {
    font-size: ${({ theme }) => theme.setSpacing(2.5)}px;
  }
  span {
    font-size: ${({ theme }) => theme.setSpacing(3.25)}px;
  }
  img {
    width: ${({ theme }) => theme.setSpacing(6.25)}px;
    height: ${({ theme }) => theme.setSpacing(6.25)}px;
  }
`;

const UpdateInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${({ theme }) => theme.setSpacing(2)}px;
  color: ${({ theme }) => theme.colors.white};

  div {
    display: flex;
    align-items: center;
    font-size: ${({ theme }) => theme.setSpacing(3)}px;

    &:first-of-type {
      font-size: 11px;

      svg {
        font-size: 20px;
      }
    }
  }

  svg {
    font-size: ${({ theme }) => theme.setSpacing(3)}px;
  }
`;

const DegreeTypeSwitch = styled.div`
  background-color: rgba(158, 162, 168, 70%);
  color: ${({ theme }) => theme.colors.black};
  padding: ${({ theme }) => theme.setSpacing(0.5)}px;
  border-radius: ${({ theme }) => theme.setSpacing(1)}px;

  span {
    display: inline-block;
    padding: ${({ theme }) => theme.setSpacing(1)}px;
    border-radius: ${({ theme }) => theme.setSpacing(1)}px;

    &:first-of-type {
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
`;

const BackButton = styled.div`
  && {
    font-size: 11px;
    color: ${({ theme }) => theme.colors.white};

    .MuiSvgIcon-root {
      font-size: 12px;
      margin-bottom: -2px;
      margin-right: -3px;
    }
  }
`;

const StyledShareIcon = styled(ShareIcon)`
  fill: ${({ theme }) => theme.colors.white};
  width: 30px;
  height: 30px;
  position: absolute;
  top: 20px;
  right: 15px;
`;

const WeatherPreview = () => {
  const [bgLoaded, setBgLoaded] = useState(false);
  const bgColor = useSelector(state => state.customAppForm.customAppForm.colors.defaultBackground);

  const backgroundImage = new Image();
  backgroundImage.src = presentationBg;

  useEffect(() => {
    const handleBgLoad = () => {
      setBgLoaded(true);
    };

    backgroundImage.addEventListener("load", handleBgLoad);

    return () => {
      backgroundImage.removeEventListener("load", handleBgLoad);
    };
  }, [presentationBg]);

  const previewLoading = !!presentationBg && !bgLoaded;
  const backButton = (
    <BackButton color="#FFFFFF">
      <ArrowBackIos />
      {firstLetterUpperCase(GLOBAL_CONTENT.back)}
    </BackButton>
  );

  const preview = {
    label: WEATHER_CONTENT.label,
    visible: true,
    fontColor: "#FFFFFF",
    leftSlot: backButton,
  };

  const hoursPreview = WEATHER_CONTENT.days.firstDay.hours?.map((item, index) => (
    <div key={`first-day-hour-${index}`}>
      <small>{item.hour}</small>
      <img src={item.icon} alt={`first-day-hour-${index}`} />
      <span>
        {item.temperature}
        &ordm;
      </span>
    </div>
  ));

  const lastUpdate = (
    <UpdateInfoWrapper>
      <div>
        {WEATHER_CONTENT.lastUpdated}
        <RefreshIcon />
      </div>
      <div>
        <DegreeTypeSwitch>
          <span>&ordm;C</span>
          <span>&ordm;F</span>
        </DegreeTypeSwitch>
      </div>
    </UpdateInfoWrapper>
  );

  const content = (
    <>
      <StyledShareIcon />
      <ForecastWrapper>
        <ForecastLocation>{WEATHER_CONTENT.location}</ForecastLocation>
        <Divider />
        <WeatherDayWrapper dailyForecastCollapsed>
          <WeatherDayHeader>{WEATHER_CONTENT.firstDay}</WeatherDayHeader>
          <WeatherHoursList>{hoursPreview}</WeatherHoursList>
        </WeatherDayWrapper>
        <Divider />
        <WeatherDayWrapper>
          <WeatherDayHeader>{WEATHER_CONTENT.secondDay}</WeatherDayHeader>
          <div>
            <img src={WEATHER_CONTENT.days.secondDay.icon} alt={WEATHER_CONTENT.secondDay} />
            <span>
              {WEATHER_CONTENT.days.secondDay.avgTemperature}
              &ordm;
            </span>
          </div>
        </WeatherDayWrapper>
        <Divider />
        <WeatherDayWrapper>
          <WeatherDayHeader>{WEATHER_CONTENT.thirdDay}</WeatherDayHeader>
          <div>
            <img src={WEATHER_CONTENT.days.thirdDay.icon} alt={WEATHER_CONTENT.thirdDay} />
            <span>
              {WEATHER_CONTENT.days.thirdDay.avgTemperature}
              &ordm;
            </span>
          </div>
        </WeatherDayWrapper>
        <Divider />
        <WeatherDayWrapper>
          <WeatherDayHeader>{WEATHER_CONTENT.fourthDay}</WeatherDayHeader>
          <div>
            <img src={WEATHER_CONTENT.days.fourthDay.icon} alt={WEATHER_CONTENT.fourthDay} />
            <span>
              {WEATHER_CONTENT.days.fourthDay.avgTemperature}
              &ordm;
            </span>
          </div>
        </WeatherDayWrapper>
        <Divider />
        <WeatherDayWrapper>
          <WeatherDayHeader>{WEATHER_CONTENT.fifthDay}</WeatherDayHeader>
          <div>
            <img src={WEATHER_CONTENT.days.fifthDay.icon} alt={WEATHER_CONTENT.fifthDay} />
            <span>
              {WEATHER_CONTENT.days.fifthDay.avgTemperature}
              &ordm;
            </span>
          </div>
        </WeatherDayWrapper>
      </ForecastWrapper>
      {lastUpdate}
    </>
  );

  return (
    <PhonePresentation>
      <>
        <WeatherWrapper bgImage={backgroundImage.src}>
          <WeatherContent>
            <PhoneHeader bgColor={preview.bgColor} fontColor={preview.fontColor}>
              <LeftSlot>{preview.leftSlot}</LeftSlot>
              <MiddleSlot>{preview.label}</MiddleSlot>
              <RightSlot />
            </PhoneHeader>
            {content}
          </WeatherContent>
        </WeatherWrapper>
      </>
    </PhonePresentation>
  );
};

export { WeatherPreview };
