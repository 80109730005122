import AddIcon from "@mui/icons-material/Add";
import OutlinedButton from "components/_new/Buttons/OutlinedButton";
import DnD from "components/_new/DnD_new";
import { NoResultsMessage } from "components/ui/Messages";
import TitleRow from "feature/panel/Itinerary/components/TitleRow";
import { Wrapper } from "feature/panel/Itinerary/style";
import { ResponsiveHideFrom } from "feature/panel/Itinerary/responsive";
import React, { useState } from "react";
import { useFieldArray } from "react-hook-form";
import AccessSettingInput from "../../../components/AccessInput/index";
import DirectoryModal from "../../../components/Modal/modal";
import Item from "./item";
import DnDHeader from "./DnDHeader";
import {
  CREATE_CONTENT_LABELS,
  EMPTY_LIST_MESSAGES_BASE,
  NAMES_OF_RESOURCES_LISTS
} from "../../../../../../constants/content";
import EmptyListMessages from "../../../components/EmptyListMessages";

const DailyActivities = ({ form: { control, watch, formState } }) => {
  const [isItemModalOpen, setIsItemModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { fields, replace, update, append, remove, move } = useFieldArray({
    name: "directories.daily",
    control,
    keyName: "rf_id",
  });

  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const onDragEnd = (_, { source, destination }) => move(source.index, destination.index);

  return (
    <Wrapper slimTopPadding>
      <AccessSettingInput control={control} name="general.meta.show_daily_activities" />

      {isModalOpen && (
        <DirectoryModal
          append={append}
          onClose={() => {
            setIsModalOpen(false);
          }}
          title="Create new daily activity item"
          confirmButton="Save"
          type="daily"
          isCreate
        />
      )}

      <TitleRow text="Daily activities" isSticky>
        <OutlinedButton text="Add" startIcon={<AddIcon />} onClick={toggleModal} />
      </TitleRow>

      <div style={{ height: 20 }} />

      {fields.length ? (
        <>
          <ResponsiveHideFrom size="md">
            {" "}
            <DnDHeader />
          </ResponsiveHideFrom>
          <DnD
            list={fields}
            element={
              <Item
                directories={fields}
                replace={replace}
                update={update}
                remove={remove}
                isItemModalOpen={isItemModalOpen}
                setIsItemModalOpen={setIsItemModalOpen}
              />
            }
            contentStyle={{ padding: 0 }}
            onDragEnd={onDragEnd}
            disabled={isItemModalOpen}
          />
        </>
      ) : (
        <EmptyListMessages watch={watch} emptyListBaseMessage={EMPTY_LIST_MESSAGES_BASE(CREATE_CONTENT_LABELS.add, NAMES_OF_RESOURCES_LISTS.daily)} />
      )}
    </Wrapper>
  );
};

export default DailyActivities;
