import React from "react";
import Switch from "components/_new/Switch";
import { useActiveIcon } from "hooks/useActiveIcon";
import { ChipWithRedirect } from "components/ui/Chips/ChipWithRedirect";
import { Wrap } from "../../../Stays/POIs/style";
import { TextLimiter } from "../../../../../../../components/ui/TextLimiter";

export default ({ onChange, disabled, isOverriden }) => [
  {
    id: "icon",
    label: "Icon",
    align: "left",
    type: "icon",
    renderCell: column => <img src={column.icon.url} style={{ width: 25 }} alt="icon" />,
  },

  { id: "name", label: "Name", minWidth: 100, align: "left", renderCell: item => <TextLimiter maxChar={35} text={item.name} /> },
  { id: "country", label: "Country", minWidth: 100, align: "left" },
  { id: "poi_range", label: "Range (km)", minWidth: 100, align: "left" },
  { id: "coordinates", label: "Coordinates", align: "left", renderCell: column => `${column.latitude}, ${column.longitude}` },
  { id: "description", label: "Description", align: "left", renderCell: item => <TextLimiter maxChar={75} text={item.description} wrap /> },
  {
    id: "files",
    label: "Files / URL links",
    align: "left",
    renderCell: column => {
      const chipFileIcon = useActiveIcon(column.library_node);

      return !column.library_node ? (
        "-"
      ) : (
        <ChipWithRedirect onDelete={() => {}} item={column.library_node} icon={chipFileIcon} editable={false} maxChar={15} />
      );
    },
  },
  {
    id: "switch",
    label: "",
    align: "right",
    isAction: true,
    maxWidth: 72,
    renderCell: (row, index) => {
      return (
        <Wrap>
          <Switch
            onChange={() => onChange(row, index)}
            disabled={disabled}
            checked={row.hasOwnProperty("is_on") ? Boolean(row.is_on) : row.is_default_on}
          />
        </Wrap>
      );
    },
  },
];
