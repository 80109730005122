import { getMediaPx } from "helpers";
import styled from "styled-components";

export const Wrap = styled.div`
  display: grid;
  grid-template-columns: 160px 1fr;
  gap: 25px;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    grid-template-columns: 80px 1fr;
  }
`;

export const ImageWrap = styled.div`
  width: 160px;
  height: 160px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(232, 232, 233, 0.2);
  border: 1px solid rgba(141, 144, 149, 0.2);
  overflow: hidden;
  img {
    width: 100%;
  }
  svg {
    color: rgba(141, 144, 149, 0.5);
  }
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    width: 80px;
    height: 80px;
  }
`;
