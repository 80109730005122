import React from "react";
import PropTypes from "prop-types";
import TableCell from "@material-ui/core/TableCell";
import styled from "styled-components";
import moment from "moment";

import { TableRow as MuiTableRow } from "components/ui/Tables";

import config from "config/app";

const IndexTableCell = styled(TableCell)`
  && {
    width: ${({ theme }) => theme.setSpacing(20)}px;
  }
`;

const NameTableCell = styled(TableCell)`
  && {
    white-space: nowrap;
    width: ${({ theme }) => theme.setSpacing(60)}px;
  }
`;

const DateTimeTableCell = styled(TableCell)`
  && {
    width: ${({ theme }) => theme.setSpacing(50)}px;
  }
`;

const LinkButton = styled.button`
  border: 0;
  background-color: transparent;
  font-family: ${({ theme }) => theme.fonts.font};
  display: inline;
  outline: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.grey100};
  font-size: ${({ theme }) => theme.setSpacing(3.5)}px;
  padding: 0;
`;

const TableRow = ({ item, onClickName }) => {
  const { name, updated_at } = item;

  return (
    <MuiTableRow item={item}>
      <NameTableCell>{item.outputs ? <LinkButton onClick={() => onClickName(item)}>{name}</LinkButton> : name}</NameTableCell>
      <DateTimeTableCell style={{ width: "200px" }}>{moment(updated_at).format(config.dateTimeFormat)}</DateTimeTableCell>
    </MuiTableRow>
  );
};

TableRow.propTypes = {
  item: PropTypes.shape().isRequired,
  onClickName: PropTypes.func.isRequired,
};

export { TableRow, IndexTableCell, NameTableCell, DateTimeTableCell };
