import moment from "moment";

import { defaultNotificationTime } from "feature/panel/Trips/_shared/tripInitialState";
import { NOTIFICATION_TYPES } from "constants/content";
import config from "config/app";

export const DISPLAYABLE_STARTING_POINT_OPTIONS = {
  disabled: { value: "disabled", label: "Disabled" },
  startDay: { value: "startDay", label: "Start day" },
  endDay: { value: "endDay", label: "End day" },
  daysBefore: { value: "daysBefore", label: "Days before start" },
  daysAfter: { value: "daysAfter", label: "Days after start" },
  daysBeforeEnd: { value: "daysBeforeEnd", label: "Days before end" },
  daysAfterEnd: { value: "daysAfterEnd", label: "Days after end" },
};

export const API_NOTIFICATION_FIELDS = {
  notifications: "notifications",
  start_at: "start_at",
  delivery_at: "delivery_at",
  end: "end",
  start: "start",
  content: "content",
  delivery_at_days: "delivery_at_days",
  inspiration_id: "inspiration_id",
};

export const createDefaultTimedNotifications = notificationsTemplates => {
  return notificationsTemplates.map(notification => ({
    ...notification,
    template_id: notification.id,
    start_at: defaultNotificationTime,
    delivery_at_relative_to: "start",
    delivery_at_days: 0,
    is_active: false,
  }));
};

export const createDefaultGpsNotifications = (notificationsTemplates, startDate) => {
  return notificationsTemplates.map(notification => ({
    ...notification,
    template_id: notification.id,
    start_at: startDate,
    end_at: moment(startDate)
      .add(1, "day")
      .toISOString(),
    location_internal_id: "",
    latitude: "",
    longitude: "",
    is_active: false,
  }));
};

export const createInspirationNotification = inspiration_vamoos_id => {
  return {
    type: NOTIFICATION_TYPES.inspiration,
    content: "",
    inspiration_vamoos_id: inspiration_vamoos_id || "",
    start_at: defaultNotificationTime,
    is_active: false,
  };
};

export const calculateStartingPointValue = (delivery_at_relative_to, delivery_at_days) => {
  if (!delivery_at_relative_to) {
    return DISPLAYABLE_STARTING_POINT_OPTIONS.disabled.value;
  }
  if (delivery_at_relative_to === API_NOTIFICATION_FIELDS.start && delivery_at_days === 0) {
    return DISPLAYABLE_STARTING_POINT_OPTIONS.startDay.value;
  }
    
  if (delivery_at_relative_to === API_NOTIFICATION_FIELDS.end && delivery_at_days === 0) {
    return DISPLAYABLE_STARTING_POINT_OPTIONS.endDay.value;
  }

  if(delivery_at_days > 0) {
    if(delivery_at_relative_to === API_NOTIFICATION_FIELDS.end) {
      return DISPLAYABLE_STARTING_POINT_OPTIONS.daysAfterEnd.value;
    }

    return DISPLAYABLE_STARTING_POINT_OPTIONS.daysAfter.value;
  }
  
  if(delivery_at_relative_to === API_NOTIFICATION_FIELDS.end) {
    return DISPLAYABLE_STARTING_POINT_OPTIONS.daysBeforeEnd.value;
  }

  return DISPLAYABLE_STARTING_POINT_OPTIONS.daysBefore.value;
};

export const calculateSelectedDayValue = (value, startingPointValue) => {
  const shouldNegateSelectedDayNumber =
    startingPointValue === DISPLAYABLE_STARTING_POINT_OPTIONS.daysBefore.value ||
    startingPointValue === DISPLAYABLE_STARTING_POINT_OPTIONS.daysBeforeEnd.value;
  return shouldNegateSelectedDayNumber ? Number(`-${value}`) : value;
};

export const calculateStartingPointChange = (currentNotification, value) => {
  const { delivery_at_days, delivery_at_relative_to, delivery_at, ...rest } = currentNotification || {};

  if (value === DISPLAYABLE_STARTING_POINT_OPTIONS.disabled.value) {
    return {
      ...rest,
      delivery_at_days: null,
      delivery_at_relative_to: null,
    };
  }

  const deliveryDays = (() => {
    if (value === DISPLAYABLE_STARTING_POINT_OPTIONS.startDay.value || value === DISPLAYABLE_STARTING_POINT_OPTIONS.endDay.value) {
      return 0;
    }

    const isBeforeDate =
      value === DISPLAYABLE_STARTING_POINT_OPTIONS.daysBefore.value || 
      value === DISPLAYABLE_STARTING_POINT_OPTIONS.daysBeforeEnd.value;

    if (!delivery_at_days) {
      return isBeforeDate ? -1 : 1;
    }

    return isBeforeDate ? -delivery_at_days : Math.abs(delivery_at_days);

  })();

  const deliveryAtStartingPoint = /end/i.test(value) ? API_NOTIFICATION_FIELDS.end : API_NOTIFICATION_FIELDS.start;

  return {
    ...rest,
    delivery_at_days: deliveryDays,
    delivery_at_relative_to: deliveryAtStartingPoint,
  };
};

export const calculateNotificationDate = ({ delivery_at_relative_to, delivery_at_days, departure_date, return_date }) => {
  const deliveryCountStart = delivery_at_relative_to === "start" ? departure_date : return_date;
  return moment(deliveryCountStart)
    .add(delivery_at_days, "day")
    .format(config.dateFormat);
};

export const filterOutExistingNotifications = (notificationTemplates, existingNotifications) => {
  return createDefaultTimedNotifications(
    notificationTemplates.filter(notification =>
      existingNotifications.every(existingNotification => existingNotification.template_id !== notification.id),
    ),
  );
};
