import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import uuidv4 from "uuid";
import { StickyHeaderFormSection } from "components/ui/Forms";
import AddIcon from "@material-ui/icons/Add";

import { SecondaryButton } from "components/ui/Buttons";
import { useManageTripContext } from "feature/panel/Trips/_shared/ManageTripContext";
import { PeopleList } from "feature/panel/Trips/_shared/People/PeopleList";
import { SortableList } from "components/ui/Lists";
import { setNewOrder } from "utils/sortable";
import { CREATE_CONTENT_LABELS, EMPTY_LIST_MESSAGES_BASE, NAMES_OF_RESOURCES_LISTS } from "constants/content";
import { NoResultsMessage } from "components/ui/Messages/NoResultsMessage";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { mergeTravelPeopleToDisplay } from "../../../../../helpers";
import { setErrors } from "../../../../../store/app/actions";
import { validateTravellers } from "../../../../../utils/validators/tripsValidator";
import { isObjectEmpty } from "../../../../../utils/object";
import { splitTravelPeopleByEmail } from "./helpers";

const People = () => {
  const {
    setValueFor,
    travelPeople,
    errors,
    vamoos_id,
    editMode,
    is_wiped,
    listOfFlights,
    destinationDocuments,
    travelDocuments,
    storyboard,
  } = useManageTripContext();
  const dispatch = useDispatch();
  const module = "trips";
  const permissionsService = usePermissionsService();
  const [travelPeopleToDisplay, setTravelPeopleToDisplay] = useState(null);
  const [shouldUpdateTravelPeopleToDisplay, setShouldUpdateTravelPeopleToDisplay] = useState(true);

  const restrictableSectionsMapper = [
    { data: listOfFlights, key: "listOfFlights" },
    { data: destinationDocuments, key: "destinationDocuments" },
    { data: travelDocuments, key: "travelDocuments" },
    { data: storyboard, key: "storyboard" },
  ];

  const travellerValidation = travelPeopleList => {
    const validationResult = validateTravellers(travelPeopleList);
    if (!isObjectEmpty(validationResult)) {
      dispatch(setErrors({ errors: { travellers: validationResult }, module }));
    } else {
      const { travellers, ...newErrors } = errors;
      dispatch(setErrors({ errors: newErrors, module }));
    }
  };

  useEffect(() => {
    // run travellerValidation initially
    travellerValidation(travelPeople);
  }, []);

  useEffect(() => {
    // shouldUpdateTravelPeopleToDisplay is used because we don't add a new email to travelPeople until it is a non-empty string,
    // but we need to add a comma to travelPeopleToDisplay
    // and without shouldUpdateTravelPeopleToDisplay the travelPeopleToDisplay will always be updated in this useEffect
    // and the comma will always be removed,
    // but we need to update travelPeopleToDisplay in all other cases, so we set setShouldUpdateTravelPeopleToDisplay to true
    if (!shouldUpdateTravelPeopleToDisplay) {
      setShouldUpdateTravelPeopleToDisplay(true);
      return;
    }
    const mergeTravelPeople = mergeTravelPeopleToDisplay(travelPeople, errors);
    setTravelPeopleToDisplay(mergeTravelPeople);
  }, [travelPeople, errors]);

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoos_id);

  const handlePersonEdit = addedPerson => {
    // add a new line no matter where the caret is
    const isMultiple = addedPerson.name.includes("\n");
    const newPerson = { ...addedPerson };
    const found = travelPeople.find(t => t.internal_id === addedPerson.internal_id);
    if (isMultiple && found && found.name.length > 0) newPerson.name = addedPerson.name.replace("\n", "") + "\n";

    const newPersonOldAndNewIds = {
      oldId: newPerson.internal_id,
      newId: "",
    };

    const isMultipleWithEmail = newPerson.name.includes("\t");

    const newPeople = newPerson.name.split("\n").map((p, index) => {
      let name = p;
      let email = index === 0 ? newPerson.email : "";
      if (isMultipleWithEmail) [name, email] = p.split("\t");
      const id = uuidv4();
      if (index === 0) newPersonOldAndNewIds.newId = id;
      return {
        internal_id: id,
        id,
        name,
        email,
      };
    });

    const updatedTravelPeopleToDisplay = travelPeopleToDisplay.map(person =>
      person.internal_id === newPerson.internal_id ? (isMultiple ? newPeople[0] : newPerson) : person,
    );

    newPeople.shift();
    const newTravelPeopleToDisplay = isMultiple ? [...updatedTravelPeopleToDisplay, ...newPeople] : updatedTravelPeopleToDisplay;

    // add new entries to the travelPeople list, based on the email address separated by a comma
    const { newTravelPeopleToDisplaySplitByEmail, emailAfterCommaIsEmpty } = splitTravelPeopleByEmail(newTravelPeopleToDisplay);

    // add new travelPeople to all restrictable sections
    restrictableSectionsMapper.forEach(({ data, key }) => {
      const updatedData = data.map(item => {
        const internalIds = item.restricted_to_traveller_internal_ids;
        let updatedInternalIds = null;
        if (internalIds !== null) {
          // change id of newPerson if newPerson added as multiple travellers
          if (newPeople.length !== 0) {
            const foundNewPersonIndex = internalIds.findIndex(id => id === newPersonOldAndNewIds.oldId);
            if (foundNewPersonIndex !== -1) {
              internalIds[foundNewPersonIndex] = newPersonOldAndNewIds.newId;
            }
          }
          // remove multiple traveller emails
          const newTravelPeopleToDisplayInternalIds = newTravelPeopleToDisplay.map(t => t.internal_id);
          const filteredIds = internalIds.filter(id => newTravelPeopleToDisplayInternalIds.includes(id));

          updatedInternalIds = new Set([...filteredIds]);

          // to handle unmodified item with internal_id as number
          const namesWithIdFromBackend = newTravelPeopleToDisplay
            .filter(t => typeof t.internal_id === "number" && filteredIds.includes(t.internal_id))
            .map(t => t.name);

          // add multiple traveller emails
          newTravelPeopleToDisplaySplitByEmail.forEach(person => {
            filteredIds.forEach(internalId => {
              if (
                person.internal_id.toString().startsWith(internalId.toString()) ||
                (typeof person.internal_id === "number" && namesWithIdFromBackend.includes(person.name)) // add multiple traveller emails with id from the backend
              ) {
                updatedInternalIds.add(person.internal_id);
              }
            });
          });
        }
        return {
          ...item,
          restricted_to_traveller_internal_ids: updatedInternalIds ? [...updatedInternalIds] : null,
        };
      });
      setValueFor(key, updatedData);
    });

    setShouldUpdateTravelPeopleToDisplay(!emailAfterCommaIsEmpty);
    setTravelPeopleToDisplay(newTravelPeopleToDisplay);
    setValueFor("travelPeople", newTravelPeopleToDisplaySplitByEmail);
    travellerValidation(newTravelPeopleToDisplaySplitByEmail);
  };

  const handleDeletePerson = personIdToDelete => {
    // delete from travelPeople to display
    const filteredTravelPeopleToDisplay = travelPeopleToDisplay.filter(t => t.internal_id !== personIdToDelete);
    setTravelPeopleToDisplay(filteredTravelPeopleToDisplay);

    // delete from travelPeople
    const mergedTravelPeople = mergeTravelPeopleToDisplay(travelPeople, errors);
    const filteredMergedTravelPeople = mergedTravelPeople.filter(t => t.internal_id !== personIdToDelete);
    const splitTravelPeople = [];
    filteredMergedTravelPeople.forEach(t => {
      const emails = t.email?.split(/\s*,\s*/);
      if (emails) {
        for (let i = 0; i < emails?.length; i++) {
          splitTravelPeople.push(t.nested[i]);
        }
      } else {
        splitTravelPeople.push(t.nested[0]);
      }
    });
    setValueFor("travelPeople", splitTravelPeople);

    // delete from all restrictable sections
    const allIdsToDelete = travelPeople
      .filter(tp => !splitTravelPeople.some(stp => tp.internal_id === stp.internal_id))
      .map(t => t.internal_id);
    restrictableSectionsMapper.forEach(({ data, key }) => {
      const updatedData = data.map(item => {
        const newItem = { ...item };
        if (newItem.restricted_to_traveller_internal_ids !== null) {
          newItem.restricted_to_traveller_internal_ids = newItem.restricted_to_traveller_internal_ids.filter(
            id => !allIdsToDelete.includes(id),
          );

          // set null if all travellers was removed
          if (splitTravelPeople.length === 0) newItem.restricted_to_traveller_internal_ids = null;
        }
        return newItem;
      });
      setValueFor(key, updatedData);
    });

    // invoke validation, to remove item with error from state, if was deleted
    travellerValidation(splitTravelPeople);
  };

  const handleAddPersonConfirm = () => {
    const id = uuidv4();
    setValueFor("travelPeople", [...travelPeople, { internal_id: id, id, name: "", email: "" }]);
    setTravelPeopleToDisplay(prevPeople => [...prevPeople, { internal_id: id, id, name: "", email: "" }]);
  };

  const handleChangeOrder = ({ newIndex, oldIndex }) => {
    // new order for display people
    const newList = setNewOrder(newIndex, oldIndex, travelPeopleToDisplay);
    setTravelPeopleToDisplay(newList);

    // new order for stored people.
    const mergedTravelPeople = mergeTravelPeopleToDisplay(travelPeople, errors);
    const newListMergedTravelPeople = setNewOrder(newIndex, oldIndex, mergedTravelPeople);
    const splitTravelPeople = [];
    newListMergedTravelPeople.forEach(t => {
      const emails = t.email?.split(/\s*,\s*/);
      if (emails) {
        for (let i = 0; i < emails?.length; i++) {
          splitTravelPeople.push(t.nested[i]);
        }
      } else {
        splitTravelPeople.push(t.nested[0]);
      }
    });
    setValueFor("travelPeople", splitTravelPeople);
    travellerValidation(splitTravelPeople);
  };

  useEffect(() => {
    return () => {
      setTravelPeopleToDisplay(null);
    };
  }, []);

  const AddPersonButton = () =>
    (editMode ? canEdit : true) && (
      <SecondaryButton disabled={is_wiped} onClick={handleAddPersonConfirm}>
        <AddIcon />
        ADD
      </SecondaryButton>
    );

  const content = travelPeopleToDisplay?.length ? (
    <SortableList lockAxis="y" onSortEnd={handleChangeOrder} lockToContainerEdges useDragHandle>
      <PeopleList
        people={travelPeopleToDisplay}
        onPersonChange={handlePersonEdit}
        onPersonDelete={handleDeletePerson}
        errors={errors.travellers ? errors.travellers : {}}
        disabled={is_wiped || (editMode && !canEdit)}
      />
    </SortableList>
  ) : (
    !(editMode && !canEdit) && (
      <NoResultsMessage>{EMPTY_LIST_MESSAGES_BASE(CREATE_CONTENT_LABELS.add, NAMES_OF_RESOURCES_LISTS.people)}</NoResultsMessage>
    )
  );

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={10}>
        <StickyHeaderFormSection title="People" headerActions={AddPersonButton}>
          {content}
        </StickyHeaderFormSection>
      </Grid>
    </Grid>
  );
};

export { People };
