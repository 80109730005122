import { isObjectEmpty } from "utils/object";
import uuidv4 from "uuid";

const replaceNewlineWithBr = input => {
  if (input === "") { return "" };
  
  return input.replace(/([^<>\s]+)(\s*\n\s*)/g, (match, prevWord, newline) => {
    if (prevWord.match(/<\/?[\w\s=".':;#-/?]+>/g)) {
      return match;
    } else {
      return prevWord + "<br />";
    }
  });
};

export function StoryboardComponent(storyboardFile) {
  const domain = {};

  domain.id = storyboardFile?.id || uuidv4();
  domain.headline = storyboardFile?.title || "";
  domain.content = replaceNewlineWithBr(storyboardFile?.content || "");
  domain.documents = storyboardFile.documents || [];
  if (storyboardFile?.location) {
    domain.location = storyboardFile.location;
  }

  if (!isObjectEmpty(storyboardFile?.image)) {
    domain.image = {
      ...storyboardFile.image,
    };
  }

  return domain;
}
