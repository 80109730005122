import React, { useContext } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";

import { Input } from "components/ui/Forms";
import { FlexGrow } from "components/ui/Content";
import { IconsSelect } from "feature/panel/_shared/IconsSelect";

import { checkErrorExistFor } from "utils/validation";
import { setStayWizardInfoButtonIcon, setStayWizardInfoButtonLabel } from "store/stayWizard/actions";
import { LanguageContext } from "components/_shared/LanguageSelector/LanguageContext";
import { LocalisationService } from "services/LocalisationService";

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const HotelInfoButton = ({ disabled }) => {
  const dispatch = useDispatch();

  const { currentLanguage } = useContext(LanguageContext);

  const localisationService = new LocalisationService(currentLanguage);

  const isStayDefaultLanguage = useSelector(({ stayWizard }) =>
    stayWizard.defaultVariant.language ? stayWizard.defaultVariant.language === currentLanguage.code : true,
  );

  const hotelInfoLabel = useSelector(({ stayWizard }) => stayWizard.form.hotelInfo.label);
  const hotelInfoIcon = useSelector(({ stayWizard }) => stayWizard.form.hotelInfo.icon);
  const defaultVariantHotelInfoLabel = useSelector(({ stayWizard }) => stayWizard.defaultVariant.hotelInfo.label);

  const currentOperator = useSelector(state => state.operator.currentOperator);
  const currentOperatorCode = useSelector(state => state.auth.currentOperatorCode);
  const hotelInfoIconId = useSelector(
    state =>
      state.auth.operators.find(operator => operator.code === currentOperatorCode).labels.find(label => label.screen === "hotelInfo")
        ?.iconId,
  );
  const { errors } = useSelector(state => state.stays);

  const currentHotelInfoIcon = Number(hotelInfoIcon || hotelInfoIconId);

  const defaultHotelInfoScreen = currentOperator?.labels?.find(label => label.screen === "hotelInfo");
  const hotelInfoLabelPlaceholder = defaultHotelInfoScreen ? localisationService.localise(defaultHotelInfoScreen, "label") : "";

  const handleInfoLabelChange = ({ target }) => {
    const { value } = target;
    dispatch(
      setStayWizardInfoButtonLabel({
        label: value,
        touch: true,
      }),
    );
  };

  const handleInfoIconChange = ({ target }) => {
    const { value } = target;
    dispatch(
      setStayWizardInfoButtonIcon({
        icon: value,
        touch: true,
      }),
    );
  };

  return (
    <Grid container>
      <Grid item xs={12} md={7}>
        <Flex>
          <FlexGrow grow={1}>
            <Input
              name="hotel_info_label"
              style={{ marginRight: 24 }}
              value={hotelInfoLabel}
              onChange={handleInfoLabelChange}
              error={checkErrorExistFor("hotel_info_label", errors?.meta)}
              helperText={errors?.meta ? errors?.meta.facebook : ""}
              disabled={disabled}
              placeholder={isStayDefaultLanguage ? hotelInfoLabelPlaceholder : defaultVariantHotelInfoLabel || hotelInfoLabelPlaceholder}
            />
          </FlexGrow>
          <IconsSelect
            name="hotel_info_icon_id"
            selected={currentHotelInfoIcon}
            fullWidth={false}
            style={{ minWidth: 80 }}
            onSelect={handleInfoIconChange}
            disabled={disabled}
          />
        </Flex>
      </Grid>
    </Grid>
  );
};
HotelInfoButton.defaultProps = {
  disabled: false,
};

HotelInfoButton.propTypes = {
  disabled: PropTypes.bool,
};

export { HotelInfoButton, Flex };
