import * as actionTypes from "store/inspirations/actionTypes";
import { reducer, setState, globalReducerActions } from "utils/store";

const initialState = {
  errors: {},
  actionType: null,
  listOfInspirations: [],
  inProgress: false,
  finished: false,
  inspirationsCount: 0
};

const ReducerInspirations = globalReducerActions("inspirations", initialState, {
  [actionTypes.GET_INSPIRATIONS_START](state) {
    return setState(state, {
      inProgress: true,
      actionType: actionTypes.GET_INSPIRATIONS_START,
    });
  },

  [actionTypes.GET_INSPIRATIONS_SUCCESS](state, payload) {
    const { items, total_matches } = payload;
    return setState(state, {
      inProgress: false,
      finished: true,
      listOfInspirations: items,
      inspirationsCount: total_matches,
      actionType: actionTypes.GET_INSPIRATIONS_SUCCESS,
    });
  },

  [actionTypes.GET_INSPIRATIONS_FAIL](state, payload) {
    return setState(state, {
      inProgress: false,
      finished: false,
      errors: payload,
    });
  },
});

export const inspirationsReducer = (state = initialState, action) => reducer(state, action, ReducerInspirations);
