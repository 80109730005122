import { SaveOutlined } from "@mui/icons-material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import OutlinedButton from "components/_new/Buttons/OutlinedButton";
import Checkbox from "components/_new/Checkbox";
import BaseModal from "components/_new/ModalBase";
import Switch from "components/_new/Switch";
import SelectInput from "components/ui/Inputs/Select";
import Input from "components/ui/Inputs/TextInput";
import { Message } from "components/ui/Messages";
import { DEFAULT_DEBOUNCE_DELAY } from "constants/defaults";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { HttpClient } from "services/application/httpClient/httpClient";
import { Tooltip } from "@mui/material";
import { encodeParameter } from "utils/url";
import { getData, modifyData, prepareDataForSubmit } from "./helpers";
import { getEncodedParametrURL } from "./components/TopSecondMenu/languageSelector";
import { ERRORS, USER_ID_EXPLANATION_MESSAGE } from "../../../constants/content";
import { setNotification } from "../../../store/app/actions";

const checkboxes = {
  trip: [
    {
      label: "Travel documents",
      value: "travel_documents",
      defaultValue: true,
    },
    {
      label: "Destination documents",
      value: "destination_documents",
      defaultValue: true,
    },
    {
      label: "Locations",
      value: "locations",
      defaultValue: true,
    },
    {
      label: "Storyboard",
      value: "details",
      defaultValue: true,
    },
    {
      label: "People",
      value: "travellers",
    },
    {
      label: "Notifications",
      value: "notifications",
      defaultValue: true,
    },
    {
      label: "Flights",
      value: "flight_ids",
    },
    {
      label: "Inspiration settings",
      value: "inspirations",
      defaultValue: true,
    },
  ],
  stay: [
    {
      label: "Actions",
      value: "documents",
      defaultValue: true,
    },
    {
      label: "POIs",
      value: "pois",
      defaultValue: true,
    },
    {
      label: "Gallery",
      value: "details",
      defaultValue: true,
    },
    {
      label: "Directory",
      value: "directory",
      defaultValue: true,
    },
    {
      label: "Daily",
      value: "daily",
      defaultValue: true,
    },
    {
      label: "Vouchers",
      value: "voucher",
      defaultValue: true,
    },
    {
      label: "Passcodes",
      value: "passcode_groups",
      defaultValue: true,
    },
    {
      label: "Notifications",
      value: "notifications",
      defaultValue: true,
    },
    {
      label: "Inspiration settings",
      value: "inspirations",
      defaultValue: true,
    },
    {
      label: "Translations",
      value: "translations",
      defaultValue: true,
    },
  ],
};

const CopyModal = ({
  form,
  onCancel,
  isSaving,
  onSave,
  handleRefreshItinerary,
  getTripLoading,
  type = "trip",
  copiedItinerary = null,
  isDirty,
}) => {
  const dispatch = useDispatch();
  const getValues = form?.getValues;

  const { currentOperatorCode, operators } = useSelector(state => state.auth);
  const copyName = copiedItinerary ? copiedItinerary.field1 : (getValues().general.field1 || getValues().main.general.field1);

  const { control, watch, setValue, setError, clearErrors, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues: {
      storyboard: {
        documents: true,
      },
    },
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [checkUserIdQuery, setCheckUserIdQuery] = useState("");
  const [confirmDisabled, setConfirmDisabled] = useState(true);
  const [passcodeCheckQueryEnabled, setPasscodeCheckQueryEnabled] = useState(false);
  const navigate = useNavigate();

  const { data: user } = useQuery({
    queryKey: ["/user/me"],
    select: data => ({
      ...data,
      operators: data.operators.map(item => ({ ...item, used_codes: item.used_codes.map(item => ({ label: item, value: item })) })),
    }),
  });

  const operator = watch("operator");
  const passcode = watch("passcode");
  const userId = watch("user_id");
  const storyboardChecked = watch("details");
  const userIDs = user?.operators.find(item => item.code === currentOperatorCode)?.used_codes;
  const debouncedSearch = useRef(debounce(search => setSearchQuery(search), DEFAULT_DEBOUNCE_DELAY));
  const debouncedOUserIdCheck = useRef(debounce(userId => setCheckUserIdQuery(userId), DEFAULT_DEBOUNCE_DELAY));
  const copyToOtherOperator = currentOperatorCode !== operator;

  const { data: passcodeCheck, refetch } = useQuery({
    queryKey: ["passcode_check", searchQuery],
    queryFn: ({ queryKey }) => {
      return HttpClient.get(`/itinerary/${copyToOtherOperator ? operator : userId}/${queryKey[1]}/passcode_check`);
    },
    enabled: Boolean(searchQuery?.length),
    onSuccess: res => {
      setPasscodeCheckQueryEnabled(false);
      if (type === "trip" && res?.data?.status === "Found") {
        setConfirmDisabled(true);
        setError("passcode", { message: "This passcode already exists" });
      }
    },
    onError: () => {
      setPasscodeCheckQueryEnabled(false);
      if (type === "trip") {
        setConfirmDisabled(false);
        clearErrors("passcode");
      }
    },
  });

  useQuery({
    queryKey: ["userid_check", checkUserIdQuery],
    queryFn: ({ queryKey }) => {
      return HttpClient.get(`/operator/check/operator_code/${encodeParameter(queryKey[1])}`);
    },
    enabled: Boolean(checkUserIdQuery?.length),
    onSuccess: res => {
      const { used, operator: resOperator, stay } = res.data || {};

      let isIdTaken;
      const isCurrentOperatorCode = operators.some(({ usedCodes }) => usedCodes && usedCodes.includes(userId));

      if (operator === userId) {
        isIdTaken = stay && (used || resOperator || isCurrentOperatorCode);
      } else {
        isIdTaken = used || resOperator || isCurrentOperatorCode;
      }

      if (isIdTaken) {
        setError("user_id", { message: ERRORS.userIdTaken });
        setConfirmDisabled(true);
      } else {
        setConfirmDisabled(false);
        clearErrors("user_id");
      }
    },
  });

  const { mutate: copyItinerary, isLoading } = useMutation(
    async values => {
      let copiedItineraryData;
      const fetchAndModifyData = async (url) => {
        const copiedItineraryResponse = await HttpClient.get(url);
        const modifiedItineraryData = modifyData(copiedItineraryResponse?.data);
        return getData(modifiedItineraryData);
      };

      if (copiedItinerary) {
        const baseUrl = `/itinerary/${encodeParameter(copiedItinerary.operator_code)}/${encodeParameter(copiedItinerary.reference_code)}`;
        const url = type === "trip" ? `${baseUrl}?_=${Date.now()}` : `${baseUrl}/language/${copiedItinerary.language}?_=${Date.now()}`;
        copiedItineraryData = await fetchAndModifyData(url);
      } else if (getValues().hasOwnProperty("main")) {
        const encodedUrl = getEncodedParametrURL(getValues().main.general.routing.fallback);
        copiedItineraryData = await fetchAndModifyData(encodedUrl);
      }

      const data = copiedItineraryData ?? getValues();
      const defaultLanguageCode = type === "stay" ? data?.general?.routing?.fallback?.split("/").slice(-1)[0] : "";

      const body = prepareDataForSubmit(data, values, copyToOtherOperator);
      const masterData = await HttpClient.post(
        type === "trip"
          ? `/itinerary/${encodeParameter(copyToOtherOperator ? operator : userId)}/${encodeParameter(passcode) ||
              "DEFAULT"}?_=${Date.now()}`
          : `/itinerary/${encodeParameter(userId)}/DEFAULT/language/${defaultLanguageCode}`,
        {
          ...body,
          type,
          new_only: type === "trip",
          is_active: true,
          source: "portal",
        },
        { headers: { "X-Operator-Code": operator } },
      );

      // copying of stays translations
      if (values.translations) {
        const routing = Object.entries(data?.general?.routing?.language || {}).map(([key, value]) => ({ [key]: value }));
        const routingWithoutDefaultLng = routing.filter(route => Object.keys(route)[0] !== defaultLanguageCode);

        const sequentialRequests = routingWithoutDefaultLng.reduce(async (previousPromise, route) => {
          // Wait for the previous request to complete before starting the next one
          await previousPromise;

          const [code, url] = Object.entries(route)[0];
          const encodedUrl = getEncodedParametrURL(url, 2);
          const originalData = await HttpClient.get(encodedUrl);
          const modifiedItineraryData = modifyData(originalData.data);
          const data = getData(modifiedItineraryData);
          const originalBody = prepareDataForSubmit(data, values, copyToOtherOperator, masterData.data.vamoos_id);

          await HttpClient.post(
            `/itinerary/${encodeParameter(userId)}/DEFAULT/language/${code}`,
            {
              ...originalBody,
              type,
              new_only: false,
              source: "portal",
              vamoos_id: masterData.data.vamoos_id,
            },
            { headers: { "X-Operator-Code": operator } },
          );
        }, Promise.resolve());
        await sequentialRequests;
      }
    },
    {
      onSuccess: data => {
        dispatch(setNotification({ type: "success", message: "Vamoos has been successfully copied" }));
        onCancel();
        if (!copyToOtherOperator) {
          if (type === "stay") navigate(`/panel/itinerary/${type}/edit/DEFAULT/${encodeParameter(userId)}/general`);
          else navigate(`/panel/itinerary/${type}/edit/${encodeParameter(userId)}/${encodeParameter(passcode) || "DEFAULT"}/general`);
        }
        if (handleRefreshItinerary) handleRefreshItinerary();
      },
      onError: ({
        response: {
          data: { error },
        },
      }) => dispatch(setNotification({ type: "error", message: error ?? ERRORS.unknownError })),
    },
  );

  const handleChangeOperator = async value => {
    if (searchQuery?.length > 2) setPasscodeCheckQueryEnabled(operator !== value);

    if (currentOperatorCode !== value) {
      setValue("notifications", false);
      setValue("inspirations", false);
      if (type === "stay") setValue("pois", false);
    }
  };

  const onSubmit = values => {
    const updatedValues = {
      ...values,
      storyboard: {
        ...values.storyboard,
        documents: type === "stay" ? false : values.storyboard.documents,
      },
    };
    copyItinerary(updatedValues);
  };

  useEffect(() => {
    const refetchPasscodeCheck = async () => {
      await refetch();
    };

    if (passcodeCheckQueryEnabled) {
      refetchPasscodeCheck();
    }
  }, [passcodeCheckQueryEnabled, refetch]);

  useEffect(() => {
    if (userIDs?.length && type === "trip") {
      setValue("user_id", userIDs[0].value);
    }
  }, [userIDs]);

  useEffect(() => {
    if (user?.operators) {
      setValue("operator", currentOperatorCode);
    }
  }, [user]);

  useEffect(() => {
    if (passcode?.length > 2) {
      debouncedSearch.current(passcode);
    } else {
      setSearchQuery("");
    }
  }, [passcode]);

  useEffect(() => {
    if (userId?.length > 2 && type === "stay") {
      debouncedOUserIdCheck.current(userId);
    } else {
      setCheckUserIdQuery("");
    }
  }, [userId]);

  useEffect(() => () => setConfirmDisabled(true), []);

  return (
    <BaseModal
      title={`Create a copy of "${copyName}"`}
      confirmTitle="Create a copy"
      onCancel={onCancel}
      isLoading={isLoading || getTripLoading}
      onConfirm={handleSubmit(onSubmit)}
      confirmDisabled={confirmDisabled || (type === "trip" ? !searchQuery : !checkUserIdQuery)}
      contentStyle={{ overflowY: "auto" }}
      modalStyle={{ maxWidth: 600 }}
      mobileFullScreen
    >
      {isDirty ? (
        <div style={{ textAlign: "center", maxWidth: 600, marginTop: 15 }}>
          <Message
            type="info"
            text="You currently have unsaved changes. In order to continue with this operation you need to save first."
          />
          <OutlinedButton text="Save" startIcon={<SaveOutlined />} isLoading={isSaving} onClick={onSave} style={{ marginTop: 15 }} />
        </div>
      ) : (
        <>
          <SelectInput
            autoFocus
            control={control}
            name="operator"
            label="Operator"
            options={user?.operators}
            optionValueVar="code"
            getOptionLabel={item => `${item.name} (${item.code})`}
            styleContainer={{ margin: "25px 0" }}
            isClearable={false}
            handleChange={value => handleChangeOperator(value)}
          />
          {type === "trip" ? (
            <>
              <SelectInput
                control={control}
                name="user_id"
                label="User ID"
                options={userIDs}
                styleContainer={{ marginBottom: 25 }}
                isClearable={false}
                noSort
              />
              <Input
                control={control}
                name="passcode"
                label="Passcode"
                rules={{
                  required: true,
                  validate: value => value.length > 2 || "Should be at least 3 characters long",
                }}
              />
            </>
          ) : (
            <Input
              control={control}
              name="user_id"
              label="User Id"
              rules={{ required: true }}
              hint={type === "stay" && USER_ID_EXPLANATION_MESSAGE}
              limit={12}
            />
          )}

          <div style={{ margin: "25px 0 20px 0" }}>Include: </div>
          {checkboxes[type].map(item => {
            const itemDisabled =
              copyToOtherOperator && (item.value === "inspirations" || item.value === "notifications" || item.value === "pois");
            const checkboxElement = (
              <Checkbox
                control={control}
                name={item.value}
                label={item.label}
                key={item.value}
                defaultValue={item.defaultValue}
                disabled={itemDisabled}
                handleChange={val => {
                  if (item.value === "details" && !val) setValue("storyboard.documents", false);
                }}
              />
            );

            return (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {itemDisabled ? (
                  <Tooltip
                    title="This field cannot be copied to another operator"
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [0, -25],
                            },
                          },
                        ],
                      },
                    }}
                  >
                    <span>{checkboxElement}</span>
                  </Tooltip>
                ) : (
                  checkboxElement
                )}
                {item.value === "details" && storyboardChecked && type === "trip" && (
                  <Switch control={control} name="storyboard.documents" label="Include documents: " />
                )}
              </div>
            );
          })}
        </>
      )}
    </BaseModal>
  );
};

export default CopyModal;
