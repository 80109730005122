import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { Checkbox } from "components/ui/Forms";

const FeatureOptionWrapper = styled.div`
  width: 220px;
  min-height: 60px;
  display: flex;
  align-items: center;
  & .MuiFormControlLabel-label {
    color: ${({ theme }) => theme.colors.grey90};
    font-size: 12px;
  }
`;

const FeatureOption = ({ label, onChange, value, disabled }) => {
  return (
    <FeatureOptionWrapper>
      <FormControlLabel label={label} control={<Checkbox />} onChange={() => onChange(label)} checked={value} disabled={disabled} />
    </FeatureOptionWrapper>
  );
};

FeatureOption.defaultProps = {
  disabled: false,
};

FeatureOption.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

export { FeatureOption, FeatureOptionWrapper };
