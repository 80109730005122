import { FileCopyOutlined, RemoveRedEyeOutlined, SmartphoneOutlined } from "@mui/icons-material";
import OutlinedButton from "components/_new/Buttons/OutlinedButton";
import { GLOBAL_CONTENT } from "constants/content";
import { PERMISSIONS } from "constants/permissions";
import { ResponsiveHideFrom } from "feature/panel/Itinerary/responsive";
import { usePermissionsService } from "hooks/usePermissionsService";
import React from "react";
import { RowButtons } from "./style";

const DefaultActionButtons = ({
  forceUpdateDisabled,
  toggleUpdateModal,
  triggerCopyVamoos,
  isEditMode,
  vamoosId,
  canEdit,
  isActive,
  onPreview,
  isDirty,
}) => {
  const permissionsService = usePermissionsService();
  const canRead = permissionsService.can(PERMISSIONS.actions.read, PERMISSIONS.sections.vamoosList, vamoosId);

  const shouldShow = isEditMode && canRead && canEdit;

  return (
    <RowButtons>
      {shouldShow && isActive && (
        <OutlinedButton
          startIcon={<SmartphoneOutlined />}
          text={GLOBAL_CONTENT.update}
          onClick={toggleUpdateModal}
          disabled={forceUpdateDisabled}
          type="grey"
        />
      )}
      {isEditMode && canRead && isActive && (
        <OutlinedButton
          startIcon={<FileCopyOutlined />}
          text={GLOBAL_CONTENT.copy}
          onClick={triggerCopyVamoos}
          disabled={forceUpdateDisabled}
          type="grey"
        />
      )}
      {isEditMode && isActive && (
        <ResponsiveHideFrom size="md">
          <OutlinedButton startIcon={<RemoveRedEyeOutlined />} text={isDirty ? "" : "Preview"} onClick={onPreview} disabled={isDirty} type="grey" />
        </ResponsiveHideFrom>
      )}
    </RowButtons>
  );
};

export { DefaultActionButtons };
