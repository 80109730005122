import React, { useState, useEffect, useCallback, memo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import uuidv4 from "uuid";
import { Grid } from "@material-ui/core";

import { NoResultsMessage } from "components/ui/Messages";
import { StickyHeaderFormSection } from "components/ui/Forms";
import { AddButton } from "components/ui/Buttons";
import { ADMIN_ICONS_CONTENT } from "constants/content";
import { PERMISSIONS } from "constants/permissions";
import { usePermissionsService } from "hooks/usePermissionsService";

import { OtherIconsElement } from "./OtherIconsElement";

const OtherIconsSection = memo(({ onChange, onDelete, onAdd, errors }) => {
  const permissionsService = usePermissionsService();
  const [icons, setIcons] = useState([]);
  const listOfIcons = useSelector(state => state.icons.listOfIcons);

  const canUserCreateNewIcon = permissionsService.can(
    PERMISSIONS.actions.create,
    PERMISSIONS.sections.admin,
    PERMISSIONS.resources.icons,
  );
  const canUserEditIcon = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.admin, PERMISSIONS.resources.icons);

  const canUserDeleteIcon = permissionsService.can(
    PERMISSIONS.actions.delete,
    PERMISSIONS.sections.admin,
    PERMISSIONS.resources.icons,
  );

  const handleAddNewIcon = () => {
    onAdd({
      id: uuidv4(),
      name: "",
      url: null,
      image: null,
      isNewIcon: true,
    });
  };

  const prepareIcons = useCallback(() => {
    const otherIconList = listOfIcons.filter(({ isDefault }) => !isDefault);
    setIcons(otherIconList);
  }, [listOfIcons]);

  const renderContent = () => {
    if (icons.length > 0) {
      return (
        <>
          {icons.map((icon, index) => (
            <OtherIconsElement
              errors={errors}
              key={icon.id}
              index={index}
              icon={icon}
              onChange={onChange}
              onDelete={onDelete}
              canEdit={canUserEditIcon || (!!icon?.isNewIcon && canUserCreateNewIcon)}
              canDelete={canUserDeleteIcon}
            />
          ))}
        </>
      );
    }
    return <NoResultsMessage>{ADMIN_ICONS_CONTENT.noIconsDefined}</NoResultsMessage>;
  };

  useEffect(prepareIcons, [listOfIcons]);

  const stickyContainer = ({ children }) => <Grid md={8}>{children}</Grid>;
  const AddNewIconButton = () => (canUserCreateNewIcon ? <AddButton onClick={handleAddNewIcon} /> : null);
  return (
    <StickyHeaderFormSection
      title={ADMIN_ICONS_CONTENT.listOfPoiIcons}
      headerActions={AddNewIconButton}
      stickyVariant="expanded"
      stickyContainer={stickyContainer}
      divider="top"
    >
      {renderContent()}
    </StickyHeaderFormSection>
  );
});

OtherIconsSection.propTypes = {
  errors: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};

export { OtherIconsSection };
