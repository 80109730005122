import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import lodash from "lodash";

import Grid from "@material-ui/core/Grid";

import { Input } from "components/ui/Forms";

import { useListOfCountries } from "hooks/useListOfCountries";
import { Autocomplete } from "components/ui/Forms/Autocomplete";

const FiltersWrapper = styled.div`
  flex-grow: 1;
  margin-left: ${({ theme }) => theme.setSpacing(5)}px;
  margin-bottom: ${({ theme }) => theme.setSpacing(5)}px;
`;

const countries = useListOfCountries();

const TableOfPoisFilters = ({ selectedCountries, setSelectedCountry, setPoiSearch }) => {
  const [searchValue, setSearchValue] = useState("");

  const debouncedOnSearch = useRef(lodash.debounce(value => setPoiSearch(value), 300));

  const onSearchChange = ({ target }) => {
    const { value } = target;
    setSearchValue(value);
    debouncedOnSearch.current(value);
  };

  const onCountriesChange = value => {
    setSelectedCountry(value);
  };

  return (
    <FiltersWrapper>
      <Grid container spacing={10}>
        <Grid item xs={4}>
          <Input label="Search POIs" name="poiSearch" value={searchValue} onChange={onSearchChange} />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            multiple
            autoComplete={false}
            inputProps={{ autoComplete: "new-country" }}
            name="select-country"
            label="Show POIs by country"
            onChange={(event, value) => {
              onCountriesChange(value);
            }}
            options={countries}
            value={selectedCountries}
            $selectedList={selectedCountries}
            getOptionLabel={option => option.label}
            filterSelectedOptions
          />
        </Grid>
      </Grid>
    </FiltersWrapper>
  );
};

TableOfPoisFilters.propTypes = {
  selectedCountries: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setSelectedCountry: PropTypes.func.isRequired,
  setPoiSearch: PropTypes.func.isRequired,
};

export { TableOfPoisFilters, FiltersWrapper };
