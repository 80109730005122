import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { DeleteOutline } from "@mui/icons-material";
import DateTimeInput from "../../../../../components/ui/Inputs/DateTimeInput";
import SelectInput from "../../../../../components/ui/Inputs/Select";
import Input from "../../../../../components/ui/Inputs/TextInput";
import { ERRORS } from "../../../../../constants/content";
import { useListOfCountries } from "../../../../../hooks/useListOfCountries";
import { DashWrap, DateTimeInputs, IconWrap, InputsGrid } from "./style";

const FilterItem = ({
  control,
  fields,
  index,
  remove,
  watch,
  reset,
  setValue,
  filterFields,
  setShowFilter,
  handleChangeParams,
  getValues,
  resetField,
  hasError,
  setOpenFilterModal,
  setFilterCount,
  selectedFilters,
  handleFocus,
  handleBlur,
}) => {
  const watchFieldColumn = watch(`filters[${index}].column`);
  const countries = useListOfCountries();
  const operatorOptions = filterFields[watchFieldColumn]?.operators;

  // set the initial operator value
  useEffect(() => {
    if (!operatorOptions) return;
    const operator = getValues(`filters[${index}].operator`);
    if (!operator) setValue(`filters[${index}].operator`, operatorOptions[0]?.value);
  }, [operatorOptions]);

  const handleDeleteItem = fieldsLength => {
    if (fieldsLength === 1) {
      reset();
      setShowFilter(false);
      setOpenFilterModal(false);
      setFilterCount(0);
      handleChangeParams(undefined, true);
    } else {
      remove(index);
    }
  };

  const onChangeColumn = () => {
    setValue(`filters[${index}].operator`, operatorOptions[0]?.value);

    const fieldsToReset = ["userInputValue", "dateValue", "dateFrom", "dateTo"];
    fieldsToReset.forEach(field => setValue(`filters[${index}].${field}`, null));
  };

  const setOptions = () => [
    { label: filterFields[selectedFilters[index]].label, value: selectedFilters[index] },
    ...Object.keys(filterFields)
      .filter(key => !selectedFilters.includes(key))
      .map(key => ({ label: filterFields[key].label, value: key })),
  ];

  const renderInputByType = () => {
    const fieldType = filterFields[watchFieldColumn]?.type;

    const validateDateRange = (dateFrom, dateTo) => {
      if (!dateFrom || !dateTo) {
        return true;
      }

      const fromDate = new Date(dateFrom);
      const toDate = new Date(dateTo);

      return fromDate.getTime() <= toDate.getTime();
    };

    if (fieldType === "date") {
      return watch(`filters[${index}].operator`) === "between" ? (
        <DateTimeInputs>
          <DateTimeInput
            control={control}
            name={`filters[${index}].dateFrom`}
            label="From"
            rules={{ required: true }}
            defaultValue={new Date().setSeconds(0, 0)}
          />
          <DashWrap hasError={hasError}>-</DashWrap>
          <DateTimeInput
            control={control}
            name={`filters[${index}].dateTo`}
            label="To"
            rules={{
              required: true,
              validate: value => validateDateRange(watch(`filters[${index}].dateFrom`), value) || "This date must be later than From date",
            }}
            defaultValue={new Date().setSeconds(0, 0)}
          />
        </DateTimeInputs>
      ) : (
        <DateTimeInput
          control={control}
          name={`filters[${index}].dateValue`}
          label="Date"
          rules={{
            required: true,
          }}
          defaultValue={new Date()}
        />
      );
    } else {
      return fieldType === "select" ? (
        <SelectInput
          label="Filter by country"
          control={control}
          name={`filters[${index}].country`}
          options={countries}
          rules={{ required: { value: true, message: "This field cannot be empty" } }}
          isMulti
          isClearable={false}
          handleFocus={handleFocus}
          onBlur={handleBlur}
          menuPosition="fixed"
        />
      ) : (
        <Input
          label="Value"
          control={control}
          name={`filters[${index}].userInputValue`}
          rules={{
            required: true,
            maxLength: {
              value: 100,
              message: ERRORS.isTooLong(100),
            },
            min:
              fieldType === "number"
                ? {
                    value: 0,
                    message: "The value must be greater than zero",
                  }
                : undefined,
          }}
          type={fieldType}
          numberMinValue={0}
        />
      );
    }
  };

  return (
    <InputsGrid>
      <SelectInput
        label="Column"
        control={control}
        name={`filters[${index}].column`}
        options={() => setOptions()}
        noSort
        handleChange={() => onChangeColumn()}
        handleFocus={handleFocus}
        onBlur={handleBlur}
        isClearable={false}
        menuPosition="fixed"
      />
      <SelectInput
        label="Operator"
        control={control}
        name={`filters[${index}].operator`}
        menuPosition="fixed"
        options={operatorOptions}
        isClearable={false}
      />
      {renderInputByType()}
      <IconWrap onClick={() => handleDeleteItem(fields?.length)} hasError={hasError}>
        <DeleteOutline />
      </IconWrap>
    </InputsGrid>
  );
};

FilterItem.propTypes = {
  control: PropTypes.shape({}).isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  index: PropTypes.number.isRequired,
  remove: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  filterFields: PropTypes.shape({}).isRequired,
  setShowFilter: PropTypes.func.isRequired,
  handleChangeParams: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  resetField: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  setOpenFilterModal: PropTypes.func.isRequired,
  setFilterCount: PropTypes.func.isRequired,
};

export default FilterItem;
