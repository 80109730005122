import React from "react";
import { CircularProgress } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffectDependenciesOnly } from "hooks/useEffectDependenciesOnly";
import { colors } from "config/theme/colors";
import { Icon, Text } from "./style";

const inProgressStatuses = ["enqueued", "processing"];

const PDFStatus = ({ job }) => {
  const queryClient = useQueryClient();

  const status = job?.status;

  const { data } = useQuery({
    queryKey: [`/job/${job?.id}`],
    refetchInterval: 1000,
    enabled: Boolean(status !== "completed" && status !== "failed"),
  });

  const isLoading = inProgressStatuses.find(item => item === status);
  const isFailed = status === "failed";

  useEffectDependenciesOnly(() => {
    if (data?.status === "completed" || data?.status === "failed") {
      queryClient.invalidateQueries({ queryKey: ["creations"] });
    }
  }, [data]);

  return (
    <div style={{ display: "grid", minWidth: 215, height: 72, width: "100%", gridTemplateColumns: "72px 1fr" }}>
      {isLoading ? (
        <Icon>
          <CircularProgress size={16} />
        </Icon>
      ) : <div />}

      <div style={{ width: "100%", flexGrow: 1, borderLeft: "1px solid #e8e8e9", textAlign: "center" }}>
        <Text>
          PDF generation: <br />
          <span style={{ color: isFailed && colors.error }}>{isLoading ? "in progress" : isFailed ? "failed" : ""} </span>
        </Text>
      </div>
    </div>
  );
};

export default PDFStatus;
