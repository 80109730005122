import React from "react";
import { useSelector } from "react-redux";

import { InformationField } from "feature/panel/Settings/_shared/InformationField";

import { OPERATOR_CONFIG } from "feature/admin/Operators/Details/constants";

const OperatorInfo = () => {
  const { details } = useSelector(state => state.adminOperatorDetails);
  const {
    companyName,
    companyCode,
    contactPerson,
    phoneNumber,
    numberOfBookings,
    numberOfPassengers,
    numberOfProperties,
    numberOfRooms,
    description: descriptionField,
    website: websiteField,
    sourceKnowledge,
  } = OPERATOR_CONFIG;

  const fieldLists = [
    { label: companyName.label, field_name: companyName.key, value: details?.name },
    { label: companyCode.label, field_name: companyCode.key, value: details?.code },
    { label: contactPerson.label, field_name: contactPerson.key, value: details?.meta?.contact_person },
    { label: phoneNumber.label, field_name: phoneNumber.key, value: details?.meta?.telephone },
    { label: numberOfBookings.label, field_name: numberOfBookings.key, value: details?.meta?.number_of_bookings },
    { label: numberOfPassengers.label, field_name: numberOfPassengers.key, value: details?.meta?.number_of_passengers },
    { label: numberOfProperties.label, field_name: numberOfProperties.key, value: details?.meta?.number_of_properties },
    { label: numberOfRooms.label, field_name: numberOfRooms.key, value: details?.meta?.number_of_rooms },
    { label: descriptionField.label, field_name: descriptionField.key, value: details?.meta?.description },
    { label: websiteField.label, field_name: websiteField.key, value: details?.meta?.website },
    { label: sourceKnowledge.label, field_name: sourceKnowledge.key, value: details?.meta?.source_knowledge_about_us },
  ];

  return (
    <>
      {fieldLists.map(({ label, field_name, value }) => (
        <InformationField key={`operator-info-${field_name}`} title={label} content={value || "-"} />
      ))}
    </>
  );
};

export { OperatorInfo };
