import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { useMutation } from "@tanstack/react-query";
import moment from "moment";

import { Tooltip } from "@mui/material";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import OutlinedButton from "../../../../../components/_new/Buttons/OutlinedButton";
import { BodyText2 } from "../../../../../components/ui/Typography/Typography";
import BaseModal from "../../../../../components/_new/ModalBase";

import { CustomBadge, OptionWrapper, ModalWrapper } from "./style";
import InspirationForm from "../../../Itinerary/components/pagesElements/InspirationsForm";
import { calculateStartingPointChange } from "../../../../../utils/notifications";
import DocumentBulkActionModal from "./DocumentBulkActionModal";
import { GLOBAL_CONTENT, TABLE_NAMES } from "../../../../../constants/content";

import { HttpClient } from "../../../../../services/application/httpClient/httpClient";
import { BULK_ACTIONS } from "../../../../../constants/api";
import { dispatch } from "../../../../../store/store";
import { setNotification } from "../../../../../store/app/actions";
import UpdateModal from "../../../Itinerary/updateModal";
import config from "../../../../../config/app";
import BasicPopover from "../../../Itinerary/components/TopSecondMenu/components/common/basicPopover";
import { ResponsiveHideFrom, ResponsiveShowFrom } from "../../../Itinerary/responsive";
import Checkbox from "../../../../../components/_new/Checkbox";
import { deviceType } from "../../../../../utils/deviceDetect";

const BulkActions = ({ selectedItems, setSelectedItems, url, forceQuery, totalMatches, type, setClearAllCheckboxSelections, defaultLanguageCode, languageOption }) => {
  const [action, setAction] = useState(null);
  const [actionModalOpen, setActionModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [applyBulkToAll, setApplyBulkToAll] = useState(false);
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(true);

  const togglePopover = e => setAnchorEl(anchorEl ? null : e.currentTarget);
  const typePlural = selectedItems.vamoosIds.length === 1 ? type : `${type}s`;
  const modalTitleLastPart = ` to ${applyBulkToAll ? "all matching" : selectedItems.vamoosIds.length} ${typePlural}`;
  const capitalisedTypePlural = typePlural.charAt(0).toUpperCase() + typePlural.slice(1);

  const initBulkActions = [
    { label: `Delete ${typePlural}`, value: "delete", modalTitle: "Delete confirmation" },
    { label: "Add update notification", value: "add_notification", modalTitle: "Add update notification" },
    { label: "Add inspiration", value: "add_inspiration", modalTitle: "Add inspiration" },
    { label: "Add document", value: "add_document", modalTitle: "Add document" },
  ];

  const bulkActions = type === "stay" ? initBulkActions.filter(action => action.value !== "add_document") : initBulkActions;
  const popoverHeight = bulkActions.length * 44; // 44 - the height of one popover item

  const form = useForm();
  const requiredInspirationIdField = form.watch("localInspiration.inspiration_vamoos_id");
  const requiredInspirationContentField = form.watch("localInspiration.content");
  const inspirationPeriodField = form.watch("localInspiration.period");
  const requiredDocTitleField = form.watch("name");
  const requiredDocFileField = form.watch("file");
  const requiredDocTypeField = form.watch("document_type");

  useEffect(() => {
    if (!totalMatches) return;
    setApplyBulkToAll(selectedItems.vamoosIds.length === totalMatches);
  }, [selectedItems.vamoosIds, totalMatches]);

  useEffect(() => {
    let enableConfirmButton = false;
    if (action === "delete") enableConfirmButton = true;
    if (action === "add_document") enableConfirmButton = requiredDocTitleField && requiredDocFileField && requiredDocTypeField;
    if (action === "add_inspiration") {
      const contentRequired = requiredInspirationIdField && inspirationPeriodField && inspirationPeriodField !== "disabled";
      enableConfirmButton = contentRequired ? requiredInspirationContentField : requiredInspirationIdField;
    }

    setConfirmButtonDisabled(!enableConfirmButton);
  }, [
    action,
    requiredInspirationIdField,
    requiredDocTitleField,
    requiredDocFileField,
    requiredDocTypeField,
    setConfirmButtonDisabled,
    inspirationPeriodField,
    requiredInspirationContentField,
  ]);

  const handleActionSelect = value => {
    togglePopover();
    setAction(value);
    setActionModalOpen(true);
  };

  const onCancel = () => {
    setApplyBulkToAll(false);
    setActionModalOpen(false);
    setAction(null);
    form.reset();
  };

  const { mutate, isLoading } = useMutation(
    requestBody => {
      const bulkActionsUrl = Array.isArray(requestBody.vamoos_ids) ? BULK_ACTIONS : BULK_ACTIONS + url.replace("/itinerary", "");
      return HttpClient.post(bulkActionsUrl, requestBody);
    },
    {
      onSuccess: () => {
        const message =
          action === "delete"
            ? `${capitalisedTypePlural} successfully deleted`
            : action === "add_notification"
            ? "Updated successfully"
            : `${capitalisedTypePlural} ${selectedItems.vamoosIds.length === 1 ? "has" : "have"} been successfully updated`;
        dispatch(setNotification({ type: "success", message }));
        setSelectedItems({ allItemsSelected: false, vamoosIds: [] });
        // setClearAllCheckboxSelections(type === TABLE_NAMES.trip);
        forceQuery(type !== TABLE_NAMES.trip);
        onCancel();
      },
      onError: ({ response: { data } }) => {
        const errorMessage = Array.isArray(data?.error) ? data?.error[0]?.message : data?.error;
        dispatch(setNotification({ type: "error", message: errorMessage ?? "Something went wrong" }));
        onCancel();
      },
    },
  );

  const onConfirm = value => {
    let data = null;
    const formData = form.watch();

    if (action === "add_notification") data = { ...value };
    if (action === "add_inspiration") {
      const { delivery_at_days, delivery_at_relative_to } = calculateStartingPointChange(
        formData.localInspiration,
        formData.localInspiration.period,
      );

      const deliveryAt = moment.isMoment(formData.localInspiration.start_at)
        ? formData.localInspiration.start_at
        : moment(formData.localInspiration.start_at);
      data = {
        delivery_at_days,
        delivery_at_relative_to,
        type: "inspiration",
        inspiration_vamoos_id: formData.localInspiration.inspiration_vamoos_id,
        is_active: true,
        content: formData.localInspiration.content,
        delivery_at: new Date("1970-01-01T" + deliveryAt.format(config.timeFormat) + ":00Z"),
      };
    }
    if (action === "add_document") {
      const { file, name } = formData;

      let convertedFileData = {
        name,
        meta: {
          restricted_to_traveller_internal_ids: null,
        },
      };

      if (file) {
        // doc from library
        if (file?.library_node_id) {
          if (file?.file_id) {
            // library file
            convertedFileData = {
              ...convertedFileData,
              library_node_id: file?.library_node_id,
              is_public: false,
            };
          } else {
            // library url
            convertedFileData = {
              ...convertedFileData,
              web_url: file?.https_url,
            };
          }
        } else if (file?.s3url) {
          // newly added file
          convertedFileData = {
            ...convertedFileData,
            is_public: false,
            file_url: file?.s3url,
          };
        } else {
          // newly added URL
          convertedFileData = {
            ...convertedFileData,
            web_url: file?.web_url,
          };
        }
      }

      data = {
        document: convertedFileData,
        document_type: formData.document_type,
      };
    }

    const requestBody = {
      source: "portal",
      action,
      data,
      vamoos_ids: selectedItems.allItemsSelected ? "*" : selectedItems.vamoosIds,
    };
    mutate(requestBody);
  };

  const options = (
    <>
      {bulkActions.map(type => (
        <OptionWrapper key={type.value} onClick={() => handleActionSelect(type.value)}>
          <BodyText2>{type.label}</BodyText2>
        </OptionWrapper>
      ))}
    </>
  );

  const actionContent = () => {
    const actionComponentMap = {
      delete: `Warning! Are you sure want to delete ${
        selectedItems.allItemsSelected ? "all matching" : selectedItems.vamoosIds.length
      } ${typePlural}?`,
      add_inspiration: (
        <InspirationForm
          form={form}
          canEdit
          wrapStyle={{ gridTemplateColumns: "1fr" }}
          menuPlacement={deviceType() === "mobile" ? "top" : "bottom"}
        />
      ),
      add_document: <DocumentBulkActionModal form={form} />,
    };

    return <ModalWrapper>{actionComponentMap[action]}</ModalWrapper>;
  };

  return (
    <>
      <CustomBadge badgeContent={selectedItems.vamoosIds?.length ?? null} badgesize="12px" textcolor="white">
        <ResponsiveHideFrom size="md">
          <Tooltip title={selectedItems.vamoosIds?.length === 0 ? `Please select at least one ${type} to enable actions` : null}>
            <span>
              <OutlinedButton
                text="Actions"
                startIcon={<AssignmentOutlinedIcon />}
                onClick={togglePopover}
                disabled={!selectedItems.vamoosIds?.length && !selectedItems.allItemsSelected}
              />
            </span>
          </Tooltip>
        </ResponsiveHideFrom>
        <ResponsiveShowFrom size="md">
          <OutlinedButton
            iconOnlyMobile
            type="grey"
            startIcon={<AssignmentOutlinedIcon />}
            onClick={togglePopover}
            disabled={!selectedItems.vamoosIds?.length && !selectedItems.allItemsSelected}
          />
        </ResponsiveShowFrom>
      </CustomBadge>
      <BasicPopover
        anchorEl={anchorEl}
        onClose={togglePopover}
        containerStyle={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: 0,
          height: popoverHeight,
          borderRadius: 20,
        }}
      >
        {options}
      </BasicPopover>
      {actionModalOpen &&
        (action === "add_notification" ? (
          <>
            {actionModalOpen && action === "add_notification" && (
              <UpdateModal
                onClose={onCancel}
                onSubmitCustom={onConfirm}
                customTitle={bulkActions.find(bulkAction => bulkAction.value === action).modalTitle + modalTitleLastPart}
                isLoading={isLoading}
                totalMatches={totalMatches}
                selectedItems={selectedItems}
                setChecked={setApplyBulkToAll}
                checked={applyBulkToAll}
                itinType={type}
                language={defaultLanguageCode}
                languages={languageOption}
              />
            )}
          </>
        ) : (
          <BaseModal
            title={bulkActions.find(bulkAction => bulkAction.value === action).modalTitle + (action === "delete" ? "" : modalTitleLastPart)}
            confirmTitle={action === "delete" ? GLOBAL_CONTENT.confirm : GLOBAL_CONTENT.add}
            onCancel={selectedItems.vamoosIds.length === 0 ? null : onCancel}
            onConfirm={form.handleSubmit(onConfirm)}
            modalStyle={{ overflow: "visible", maxWidth: "50%", ...(action === "delete" ? { height: 170 } : {}) }}
            isLoading={isLoading}
            contentStyle={{
              overflow: "visible",
              padding: "0 2px",
            }}
            leftAction={
              selectedItems.allItemsSelected &&
              selectedItems.vamoosIds.length !== totalMatches && (
                <Checkbox
                  checked={applyBulkToAll}
                  handleChange={() => {
                    setApplyBulkToAll(!applyBulkToAll);
                  }}
                  label={`Apply to all ${totalMatches} entries matching current criteria`}
                  labelStyle={{ fontSize: 12, lineHeight: "normal", marginLeft: -5 }}
                />
              )
            }
            hideCloseIcon
            mobileFullScreen={action !== "delete"}
            confirmDisabled={confirmButtonDisabled}
          >
            {actionContent()}
          </BaseModal>
        ))}
    </>
  );
};

BulkActions.propTypes = {
  selectedItems: PropTypes.shape().isRequired,
  setSelectedItems: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  forceQuery: PropTypes.func.isRequired,
  totalMatches: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  setClearAllCheckboxSelections: PropTypes.func.isRequired,
  defaultLanguageCode: PropTypes.string,
  languageOption: PropTypes.arrayOf(PropTypes.shape({})),
};

BulkActions.defaultProps = {
  defaultLanguageCode: null,
  languageOption: null,
};

export default BulkActions;
