export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SET_ERRORS = "SET_ERRORS";

export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";
export const SET_NOTIFICATION = "SET_NOTIFICATION";

export const PUSH_SUCCESS_NOTIFICATION = "PUSH_SUCCESS_NOTIFICATION";
export const PUSH_ERROR_NOTIFICATION = "PUSH_ERROR_NOTIFICATION";

export const SHOW_SAVING_COVER = "SHOW_SAVING_COVER";
export const HIDE_SAVING_COVER = "HIDE_SAVING_COVER";

export const CLEAR_ALL_STORES = "CLEAR_ALL_STORES";

export const CLEAR_ACTION_FLAGS = "CLEAR_ACTION_FLAGS";
export const CHANGE_OPERATOR_INIT_TOGGLE = "CHANGE_OPERATOR_INIT_TOGGLE";
export const CHANGE_OPERATOR_START = "CHANGE_OPERATOR_START";
export const CHANGE_OPERATOR_SUCCESS = "CHANGE_OPERATOR_SUCCESS";
export const CHANGE_OPERATOR_FAIL = "CHANGE_OPERATOR_FAIL";

export const TRY_ROUTE_CHANGE_START = "TRY_ROUTE_CHANGE_START";
export const TRY_ROUTE_CHANGE_FINISH = "TRY_ROUTE_CHANGE_FINISH";

export const CLEAR_CHANGE_OPERATOR_FLAGS = "CLEAR_CHANGE_OPERATOR_FLAGS";

export const TOGGLE_TRIP_REDIRECT_CHECKED = "TOGGLE_TRIP_REDIRECT_CHECKED";

export const GET_API_KEYS_START = "GET_API_KEYS_START";
export const GET_API_KEYS_SUCCESS = "GET_API_KEYS_SUCCESS";
export const GET_API_KEYS_FAIL = "GET_API_KEYS_FAIL";

export const CREATE_API_KEY_START = "CREATE_API_KEY_START";
export const CREATE_API_KEY_SUCCESS = "CREATE_API_KEY_SUCCESS";
export const CREATE_API_KEY_FAIL = "CREATE_API_KEY_FAIL";

export const DELETE_API_KEY_START = "DELETE_API_KEY_START";
export const DELETE_API_KEY_SUCCESS = "DELETE_API_KEY_SUCCESS";
export const DELETE_API_KEY_FAIL = "DELETE_API_KEY_FAIL";

export const DELETE_USER_FROM_OPERATOR_START = "DELETE_USER_FROM_OPERATOR_START";
export const DELETE_USER_FROM_OPERATOR_SUCCESS = "DELETE_USER_FROM_OPERATOR_SUCCESS";
export const DELETE_USER_FROM_OPERATOR_FAIL = "DELETE_USER_FROM_OPERATOR_FAIL";
