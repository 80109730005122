import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import TableCell from "@material-ui/core/TableCell";
import { TableHead, TableRow } from "@material-ui/core";

import { Table, TableBody, TablePagination } from "components/ui/Tables";

import { StatsTableRow } from "./StatsTableRow";
import columns from "./StatisticsTableColumns.json";

const StyledTableRow = styled(TableRow)`
  && {
    .MuiTableCell-root {
      border-bottom: none;
      height: ${({ theme }) => theme.setSpacing(6)}px;
      font-size: ${({ theme }) => theme.setSpacing(5)}px;
      font-family: Montserrat;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: ${({ theme }) => theme.colors.grey50}px;
    }
  }
`;

const StatisticsTable = ({ operatorCodes }) => {
  const [totalItem, setTotalItem] = useState(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const initState = {
    code: "Total",
    types: {
      trip: {
        downloads: {
          last30days: 0,
          total: 0,
        },
        created: {
          last30days: 0,
          total: 0,
        },
      },
      stay: {
        downloads: {
          last30days: 0,
          total: 0,
        },
      },
    },
    isTotal: true,
  };
  const prepareTotalDataRow = () => {
    const total = operatorCodes.reduce((acc, operator) => {
      const { trip, stay } = operator.types;
      acc.types.stay.downloads.last30days += (!!stay && stay?.downloads?.last30days) || 0;
      acc.types.stay.downloads.total += (!!stay && stay?.downloads?.total) || 0;
      acc.types.trip.downloads.last30days += (!!trip && trip?.downloads?.last30days) || 0;
      acc.types.trip.downloads.total += (!!trip && trip?.downloads?.total) || 0;
      acc.types.trip.created.last30days += (!!trip && trip?.created?.last30days) || 0;
      acc.types.trip.created.total += (!!trip && trip?.created?.total) || 0;

      return acc;
    }, initState);

    setTotalItem(total);
  };

  useEffect(prepareTotalDataRow, [operatorCodes]);

  const handlePageChange = pageNumber => {
    setPage(pageNumber + 1);
  };

  const handleRowsPerPageChange = selectedRowsAmount => {
    setRowsPerPage(selectedRowsAmount);
  };

  const getPagingStats = () => {
    const startItem = rowsPerPage * (page - 1);
    const endItem = startItem + rowsPerPage;
    const statsRows = [...operatorCodes].slice(startItem, endItem);

    return totalItem && page === 1 ? [totalItem, ...statsRows] : statsRows;
  };

  const tableHeader = (
    <TableHead>
      <StyledTableRow>
        <TableCell />
        <TableCell align="left" colSpan={4}>
          Trips
        </TableCell>
        <TableCell align="left" colSpan={2}>
          Stays
        </TableCell>
      </StyledTableRow>
      <TableRow>
        {columns.map(({ label, key }) => (
          <TableCell key={key}>{label}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  return (
    <Table list={getPagingStats()} headers={columns} onChangePage={handlePageChange} onChangeRowsPerPage={handleRowsPerPageChange} noPaper>
      {tableHeader}
      <TableBody rowComponent={StatsTableRow} />
      <TablePagination count={operatorCodes.length} page={page - 1} rowsPerPage={rowsPerPage} />
    </Table>
  );
};

StatisticsTable.propTypes = {
  operatorCodes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export { StatisticsTable };
