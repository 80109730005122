import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import PostAddIcon from "@material-ui/icons/PostAdd";
import Dropzone from "react-dropzone";
import uuidv4 from "uuid";

import { Message } from "components/ui/Messages/Message";
import { FilesList } from "components/ui/LibraryPopup/Components/FileUpload/FilesList";

import { colors } from "config/theme/colors";
import { LIBRARY_UPLOAD } from "constants/content";
import { useService } from "hooks/useService";
import { LibraryService } from "services/LibraryService";
import { Logger } from "services/application/Logger";

const AddDocumentIcon = styled(PostAddIcon)`
  && {
    margin-bottom: 30px;
    font-size: 63px;
    color: ${colors.grey50};
  }
`;

const AddDocumentText = styled.span`
  color: ${colors.grey50};
  size: ${({ theme }) => theme.setSpacing(4)}px;
  letter-spacing: 1px;
  font-weight: 600;
  line-height: 1.5;
  pointer-events: none;
  text-align: center;
`;

const FileDropZone = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 2px dashed ${colors.grey20};
  border-radius: ${({ theme }) => theme.setSpacing(2)}px;
  padding: ${({ theme }) => theme.setSpacing(6)}px;
`;

const InnerContainer = styled.div`
  width: 100%;
  overflow: auto;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const MessageContainer = styled.div`
  padding: ${({ theme }) => theme.setSpacing(4)}px;
  padding-bottom: 0;
`;

const PaddedDropZone = styled.div`
  height: 100%;
  //margin-top: 15px;
  // padding: 15px;
`;

const PaddedMiniaturesContainer = styled.div`
  // padding-left: ${({ theme }) => theme.setSpacing(6)}px;

  :focus {
    outline: none;
  }
`;

/**
 *
 * @param {object} props
 * @param {FileUploadService} props.fileUploadService
 * @returns
 */
const FileUpload = ({ setPermitUpload, setUploadAssets, multiUpload, allowedFileTypes, fileUploadService, isLibrary, libraryPath }) => {
  const libraryService = useService(LibraryService);

  const [files, setFiles] = useState([]);
  const [invalidFilesError, setInvalidFilesError] = useState([]);
  const [existingFiles, setExistingFiles] = useState([]);

  const setFileAssets = filesToUpload => {
    setFiles(filesToUpload);
    setUploadAssets({ filesUpload: filesToUpload });
  };

  const handleDropEvent = async dropzoneFiles => {
    setInvalidFilesError([]);

    const filesWithIds = dropzoneFiles.map(eachFile => ({
      id: uuidv4(),
      file: eachFile,
    }));

    const { validFiles, errors } = await fileUploadService.validateUploadedFiles(filesWithIds, isLibrary ? existingFiles : null);

    if (validFiles?.length) {
      const filesList = !multiUpload ? [validFiles.pop()] : files.concat(validFiles);

      setFileAssets(filesList.map(file => ({ ...file, active: false })));
      setPermitUpload(true);
    }
    if (errors) {
      setInvalidFilesError(errors);
    }
  };

  const handleFileDelete = deleteFileId => {
    const updatedFiles = files.filter(({ id }) => id !== deleteFileId);

    if (!updatedFiles.length) {
      setPermitUpload(false);
    }
    setFiles(updatedFiles);
  };

  const fetchExistingFiles = async () => {
    if (isLibrary) {
      try {
        const items = await libraryService.getAllItemsFromDirectory(libraryPath);
        setExistingFiles(items.filter(r => !r.is_folder).map(r => r.name));
      } catch (e) {
        Logger.debug(e);
      }
    }
  };

  const acceptedInputTypes = allowedFileTypes.join(", ");
  const renderFileDropZone = () => {
    return (
      <PaddedDropZone>
        <Dropzone onDrop={handleDropEvent}>
          {({ getRootProps, getInputProps }) => (
            <FileDropZone {...getRootProps()}>
              <input {...getInputProps()} accept={acceptedInputTypes} />
              <AddDocumentIcon />
              <AddDocumentText>{LIBRARY_UPLOAD.clickHere}</AddDocumentText>
            </FileDropZone>
          )}
        </Dropzone>
      </PaddedDropZone>
    );
  };

  const renderMiniatures = () => {
    return (
      <Dropzone onDrop={handleDropEvent}>
        {({ getRootProps, getInputProps }) => (
          <PaddedMiniaturesContainer {...getRootProps({ onClick: event => event.stopPropagation() })}>
            <input {...getInputProps()} accept={acceptedInputTypes} />
            <FilesList selectable={false} files={files} handleFileDelete={handleFileDelete} />
          </PaddedMiniaturesContainer>
        )}
      </Dropzone>
    );
  };

  const resetInvalidFilesError = () => setInvalidFilesError("");

  useEffect(() => {
    fetchExistingFiles();
  }, [isLibrary]);

  return (
    <InnerContainer>
      {invalidFilesError.length > 0 &&
        invalidFilesError.map(error => (
          <MessageContainer key={error}>
            <Message type="error" onDelete={resetInvalidFilesError} text={error} />
          </MessageContainer>
        ))}
      {files.length ? renderMiniatures() : renderFileDropZone()}
    </InnerContainer>
  );
};

FileUpload.propTypes = {
  multiUpload: PropTypes.bool,
  isLibrary: PropTypes.bool,
  libraryPath: PropTypes.string,
};

FileUpload.defaultProps = {
  multiUpload: true,
  isLibrary: false,
  libraryPath: null,
};

export { FileUpload, PaddedMiniaturesContainer };
