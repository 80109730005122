import React, { useEffect, useState } from "react";
import BaseModal from "components/_new/ModalBase";
import Input from "components/ui/Inputs/TextInput";
import { useForm } from "react-hook-form";
import Accordion from "components/_new/Accordion";
import TreeList from "components/_new/TreeList";
import { Message } from "components/ui/Messages";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import uuidv4 from "uuid";
import { WEEKDAY_VALUES_ARRAY, INIT_WEEKDAYS_VALUES } from "utils/directories";
import { WeekdayChip } from "feature/panel/Stays/_shared/DailyActivities/DailyListRow";
import SelectInput from "components/ui/Inputs/Select";
import { INPUT_BORDER_COLOR, INPUT_BORDER_RADIUS } from "components/ui/Inputs/config";
import AttachButtonRow from "./attachButton";
import Button from "./button";
import { weekdaysOptions } from "./helpers";

const titles = {
  daily: "daily activity",
  directory: "directory",
  voucher: "voucher",
};

const DirectoryModal = ({
  item = {},
  onClose,
  directories,
  replace,
  update,
  index,
  title,
  confirmButton,
  isList,
  isCreate,
  append,
  type,
  onCustomCreate,
  onCustomUpdate,
  activeDir,
}) => {
  const folders = [{ name: "Directories", children: directories }];
  const [expanded, setExpanded] = useState(false);
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    formState: { errors, isSubmitted },
  } = useForm({ defaultValues: { icon_id: 282 } });

  const folder = watch("folder");

  const weekdays = watch("weekdays") || INIT_WEEKDAYS_VALUES;
  const findObjectAndModifyChildren = (array, name, newItem) => {
    let result = [];
    for (const obj of array) {
      const { name: objName, children } = obj;

      if (objName === name) {
        if (children) {
          const findIndex = obj.children.findIndex(item => item.id === newItem.id);
          if (findIndex !== -1) {
            obj.children[findIndex] = newItem;
          } else {
            obj.children.push(newItem); // Modify children array
          }
          result = array; // Return modified array
        } else {
          obj.children = [newItem]; // Create children array and push newItem
          result = array; // Return modified array
        }
      } else {
        if (children?.find(item => item.name === newItem.name)) {
          obj.children = obj.children.filter(item => item.name !== newItem.name);
          // return array;
        }
      }
      if (children) {
        const foundInChildren = findObjectAndModifyChildren(children, name, newItem);
        if (foundInChildren) {
          result = array;
        }
      }
    }
    // return array;
    return result;
    // return null; // Object not found
  };

  const onSubmit = values => {
    const hasFolder = values.folder;
    if (hasFolder) {
      const newItem = { id: uuidv4(), ...values, is_enabled: true, type, ...(isList ? { is_list: true } : {}) };

      const result = findObjectAndModifyChildren(directories, values.folder, newItem).filter(item => item.name !== values.name);
      replace(result);
    } else {
      if (item?.name) {
        if (onCustomUpdate) {
          onCustomUpdate({ ...item, index, ...values });
        } else {
          update(index, { ...item, ...values });
        }
      } else {
        const newItem = { id: uuidv4(), ...values, is_enabled: true, type, ...(isList ? { is_list: true } : {}) };
        if (activeDir) onCustomCreate(newItem);
        else append(newItem);
      }
    }
    onClose();
  };

  const toggleExpand = () => setExpanded(!expanded);

  const toggleWeekday = weekday => {
    setValue("weekdays", { ...weekdays, [weekday]: !weekdays[weekday] });
  };

  const handleSetFolder = (_, itemId) => {
    const split = itemId.split("/");
    const length = split.length;
    const folder = split[length - 1];

    setValue("folder", folder && folder === "Directories" ? null : folder);
    if (folder === item.name) {
      setError("folder", { message: "You can't move the folder neither to its descendant nor to itself. Please, select valid path" });
    } else {
      clearErrors("folder");
    }
  };

  const renderItem = (item, prev) => {
    if (item.children?.length) {
      return (
        <TreeItem itemId={(prev || "") + "/" + item.name} label={item.name} style={{ textTransform: "capitalize" }}>
          {item.children.map(child => renderItem(child, (prev ? prev + "/" : "") + item.name))}
        </TreeItem>
      );
    } else {
      return <TreeItem itemId={(prev || "") + "/" + item.name} label={item.name} style={{ textTransform: "capitalize" }} />;
    }
  };

  const handleKeyDown = (event, weekday) => {
    if (event.key === "Enter") {
      toggleWeekday(weekday);
    }
  };

  useEffect(() => {
    reset({
      ...item,
      ...(activeDir?.name ? { folder: activeDir.name } : {}),
      weekdays: item.weekdays || INIT_WEEKDAYS_VALUES,
    });
  }, []);

  return (
    <BaseModal
      title={title || `Edit existing ${titles[type]} item`}
      confirmTitle={confirmButton || "Update"}
      onConfirm={handleSubmit(onSubmit)}
      onCancel={onClose}
      modalStyle={{ maxWidth: 600 }}
      contentStyle={{ overflow: "auto" }}
      mobileFullScreen
    >
      <Input
        autoFocus
        control={control}
        name="name"
        label="Title"
        rules={{ required: { value: true, message: "Title is required" } }}
        style={{ marginTop: 25 }}
      />
      {type === "daily" && (
        <div style={{ display: "grid", marginTop: 25, gridTemplateColumns: "repeat(7, 1fr)" }}>
          {WEEKDAY_VALUES_ARRAY.map(weekday => (
            <WeekdayChip
              key={weekday}
              active={weekdays?.[weekday]}
              style={{ height: 48, width: "auto", borderRadius: 25, cursor: "pointer" }}
              onClick={() => toggleWeekday(weekday)}
              tabIndex={0}
              onKeyDown={e => handleKeyDown(e, weekday)}
            >
              {weekday[0]}
            </WeekdayChip>
          ))}
        </div>
      )}
      <Input control={control} name="content" label="Description" multiline minRows={4} style={{ marginTop: 25 }} />
      <AttachButtonRow label="Background image" name="background" control={control} />
      <AttachButtonRow label="Video" name="video" control={control} buttonText="Attach / link" />
      <Button control={control} name="actions[0]" watch={watch} setValue={setValue} isSubmitted={isSubmitted} />
      <Button control={control} name="actions[1]" label="Button 2" watch={watch} setValue={setValue} isSubmitted={isSubmitted} />
      {errors?.folder && <Message type="error" text={errors?.folder.message} />}
      {!isCreate && type === "directory" && (
        <Accordion
          onChange={toggleExpand}
          expanded={expanded}
          title={folder || "Choose folder"}
          styleContainer={{ marginTop: 25, border: `1px solid ${INPUT_BORDER_COLOR}`, borderRadius: INPUT_BORDER_RADIUS }}
          styleSummary={{ borderRadius: INPUT_BORDER_RADIUS }}
          inputLikeLabel="Folder"
        >
          <TreeList onItemFocus={handleSetFolder}>{folders.map(item => renderItem(item))}</TreeList>
        </Accordion>
      )}
    </BaseModal>
  );
};

export default DirectoryModal;
