import React from "react";
import { colors } from "config/theme/colors";
import appConfig from "config/app";
import moment from "moment";

const Item = ({ onClick, item, message, isActive }) => {
  const { device_type, friendly_name } = item || {};
  const { sent_at, message: text } = message || {};

  return (
    <div
      onClick={() => onClick(item)}
      style={{
        height: 90,
        padding: 15,
        cursor: "pointer",
        borderBottom: "1px solid #eaeaea",
        backgroundColor: isActive ? colors.withOpacity(colors.brand, 0.3) : "#fff",
        // color: isActive ? "#000" : colors.grey40,
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", width: "70%", overflow: "hidden" }} title={device_type}>
          {friendly_name || device_type}
        </div>
        <span style={{ fontSize: 12, textAlign: "right", whiteSpace: "nowrap", marginTop: 3 }}>
          {moment(sent_at).format(appConfig.dateTimeFormat)}
        </span>
      </div>
      <div style={{ fontSize: 12, marginTop: 20, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{text}</div>
    </div>
  );
};

export default Item;
