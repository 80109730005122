import React from "react";
import PropTypes from "prop-types";
import { RoleWithUsersElement } from "feature/panel/Settings/Accounts/RoleWithUsersElement";
import { Content } from "components/ui/Content";
import { LeadText } from "components/ui/Typography/Typography";
import { Paper, ContentWrapper } from "feature/panel/Settings/_shared/styledComponents";
import { ALLOWED_ROLES_NAMES_AND_ORDER } from "constants/defaults";

const RolesWithUsersList = React.memo(({ roles, users }) => {
  const rolesList = roles
    .sort((a, b) => {
      const roleAPosition =
        ALLOWED_ROLES_NAMES_AND_ORDER.indexOf(a.name.toLowerCase()) !== -1
          ? ALLOWED_ROLES_NAMES_AND_ORDER.indexOf(a.name.toLowerCase())
          : roles.length;
      const roleBPosition =
        ALLOWED_ROLES_NAMES_AND_ORDER.indexOf(b.name.toLowerCase()) !== -1
          ? ALLOWED_ROLES_NAMES_AND_ORDER.indexOf(b.name.toLowerCase())
          : roles.length + 1;

      return roleAPosition - roleBPosition;
    })
    .map(role => <RoleWithUsersElement key={role.id} role={role} users={users.filter(user => user.role?.id === role.id)} />);

  return (
    <Paper>
      <ContentWrapper>
        <Content margin={10}>
          <LeadText>Existing Vamoos roles</LeadText>
        </Content>
        {rolesList}
      </ContentWrapper>
    </Paper>
  );
});

RolesWithUsersList.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export { RolesWithUsersList };
