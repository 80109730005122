import React, { createContext, useState } from "react";

const EntryFormContext = createContext(undefined);
const EntryFormDispatchContext = createContext(undefined);

function EntryFormProvider({ children, initialValue }) {
  const [entryContent, setEntryContent] = useState({
    initialValue
  });
  
  return (
    <EntryFormContext.Provider value={entryContent}>
      <EntryFormDispatchContext.Provider value={setEntryContent}>
        {children}
      </EntryFormDispatchContext.Provider>
    </EntryFormContext.Provider>
  );
}

export { EntryFormProvider, EntryFormContext, EntryFormDispatchContext };