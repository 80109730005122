// @ts-check
export class CustomApp {
  /** @type {string} */
  id;

  /** @type {string} */
  status;

  /** @type {string | number} */
  sequenceNumber;

  /** @type {string} */
  operatorId;

  /** @type {string|Date} */
  createdAt;

  /** @type {string|Date} */
  updatedAt;

  /** @type {string} */
  type;

  /** @type {string} */
  name;

  /** @type {string} */
  bundleId;

  /** @type {CustomAppColors} */
  colors;

  /** @type {CustomAppStrings} */
  strings;

  /** @type {CustomAppSettings} */
  settings;

  /** @type {CustomAppFont} */
  fonts;

  /** @type {CustomAppImages} */
  images;

  /** @type {CustomAppMenuDocument[]} */
  menuDocuments;

  /** @type {CustomApp[]} */
  versionsList;
}
