import React, { cloneElement } from "react";
import TableMui from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import { Checkbox } from "@mui/material";
import Checkbox from "components/_new/Checkbox";
import { colors } from "config/theme/colors";

import { FlexArea, SortingArrow, TableContainerS } from "./style";

const TableDesktop = ({
  columns,
  rows,
  onRowClick,
  renderRow,
  checkboxSelection,
  onCheckAll,
  onCheck,
  checked,
  onSort,
  sorting,
  isSorting,
  checkboxVarName,
  FooterCmp = null,
  containerStyle,
}) => {
  const isSelected = id => checked?.indexOf(id) !== -1;
  const numSelected = checked?.length;
  const rowCount = rows?.length;
  const sortingColumn = Object.keys(sorting)[0];

  const handleSort = column => {
    const currentSort = sorting[column];
    if (!currentSort) onSort(column, "asc");
    if (currentSort === "asc") onSort(column, "desc");
    if (currentSort === "desc") onSort(column, "");
  };

  return (
    <>
      <TableContainerS style={containerStyle} isSorting={isSorting}>
        <TableMui stickyHeader>
          <TableHead>
            <TableRow>
              {checkboxSelection && (
                <TableCell key="checkbox" style={{ width: 50, marginRight: 0 }}>
                  <Checkbox
                    onChange={onCheckAll}
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={rowCount > 0 && numSelected === rowCount}
                  />
                </TableCell>
              )}

              {columns.map(column => (
                <>
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      maxWidth: column.maxWidth,
                      cursor: isSorting && column?.sortable ? "pointer" : "default",
                      fontSize: 12,
                      ...(column.headerCellStyle || {}),
                    }}
                    onClick={isSorting && column?.sortable ? () => handleSort(column.id) : null}
                  >
                    <FlexArea>
                      {column.label}
                      {sortingColumn === column.id && sorting[column.id] && <SortingArrow order={sorting[column.id]} />}
                    </FlexArea>
                  </TableCell>
                </>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => {
              const isItemSelected = checkboxSelection && isSelected(row[checkboxVarName || "id"]);
              const labelId = `enhanced-table-checkbox-${index}`;
              return renderRow ? (
                <>
                  {/* {checkboxSelection && <Checkbox checked={isItemSelected} />} */}
                  {cloneElement(renderRow, { data: row, index, isItemSelected, onCheck: () => onCheck(row[checkboxVarName || "id"]) })}
                </>
              ) : (
                <TableRow
                  onClick={() => onRowClick && onRowClick(row)}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                  style={{ height: 72 }}
                >
                  {checkboxSelection && (
                    <TableCell>
                      {checkboxSelection && (
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                          onChange={() => onCheck(row[checkboxVarName || "id"])}
                        />
                      )}
                    </TableCell>
                  )}

                  {columns.map(column => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ padding: column.isAction && 0, width: column.isAction && 72 }}
                      >
                        {column.renderCell
                          ? column.renderCell(row, index)
                          : column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </TableMui>
      </TableContainerS>
      <div style={{ display: "flex", justifyContent: "flex-end", border: `1px solid ${colors.grey10}` }}>{FooterCmp}</div>
    </>
  );
};

export default TableDesktop;
