import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { MenuItem } from "@material-ui/core";

import styles from "config/theme";
import { Select } from "components/ui/Forms";

const StyledSelect = styled(Select)`
  z-index: 2;

  && {
    .MuiSelect-select {
      display: flex;
      justify-content: flex-end;
      padding-right: 13%;
    }
  }
`;

const InputLabel = styled.p`
  z-index: 1;
  user-select: none;
  position: absolute;
  margin: 0;
  top: 25%;
  left: 4%;
  color: ${styles.colors.grey50};
`;

const LabelContainer = styled.div`
  width: 380px;
  margin-right: ${({ theme }) => theme.setSpacing(10)}px;

  :last-of-type {
    margin-right: 0;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InputsContainer = styled.div`
  width: 100%;
  position: relative;
`;

const LabelledInputForm = ({ labelText, field, contextData, disabled }) => {
  const { meta, setValueFor } = contextData;
  return (
    <LabelContainer>
      <InnerContainer>
        <InputsContainer>
          <StyledSelect
            name="restriction"
            value={meta[field]}
            onChange={({ target }) => setValueFor("meta", { ...meta, [field]: target.value })}
            disabled={disabled}
          >
            {Array.from(Array(101).keys()).map(eachDay => (
              <MenuItem key={eachDay} value={eachDay}>
                {eachDay}
              </MenuItem>
            ))}
          </StyledSelect>
          <InputLabel>{labelText}</InputLabel>
        </InputsContainer>
      </InnerContainer>
    </LabelContainer>
  );
};

LabelledInputForm.propTypes = {
  labelText: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  contextData: PropTypes.shape().isRequired,
  disabled: PropTypes.bool.isRequired,
};

export { LabelledInputForm, StyledSelect, InputLabel, LabelContainer };
