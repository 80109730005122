import React, { useState, useEffect, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";
import styled from "styled-components";
import moment from "moment";

import { isNumber } from "contracts/types";

import { Input, Textarea } from "components/ui/Forms";
import { Content } from "components/ui/Content";
import { TimePicker } from "components/ui/Forms/DatePickers";
import { BodyText3 } from "components/ui/Typography/Typography";
import { DaySelector } from "components/ui/Forms/DaySelector";

import { NOTIFICATIONS_LABELS } from "constants/content";
import { CustomSelect, DateAndTimeInputsWrapper, NumberOfDaysWrapper } from "feature/panel/_shared/Notifications/styledComponents";
import {
  API_NOTIFICATION_FIELDS,
  DISPLAYABLE_STARTING_POINT_OPTIONS,
  calculateSelectedDayValue,
  calculateStartingPointChange,
  calculateStartingPointValue,
} from "utils/notifications";
import config from "config/app";
import { useManageTripContext } from "feature/panel/Trips/_shared/ManageTripContext";
import { getContent } from "../helpers";

const NotificationTimeInput = styled(TimePicker)`
  &&& {
    width: 104px;
    min-width: 104px;

    svg {
      font-size: 16px;
    }
  }
`;

const NotificationForm = ({ item, context, disabled: disabledForm, allNotifications, isLoading }) => {
  const [notificationTime, setNotificationTime] = useState(null);
  const { delivery_at_days, start_at, delivery_at_relative_to, template_id, url, id, content } = item;
  const { language } = useManageTripContext();

  const { notifications, departure_date, setValueFor } = useContext(context);

  const currentTemplate = allNotifications?.find(item => item.id === template_id);
  const initNotificationDates = () => {
    setNotificationTime(moment(start_at, config.timeFormat));
  };

  const changeNotificationValue = (attributeName, value) => {
    const newList = notifications.map(notification => {
      if (notification.id && notification.id === id) {
        return { ...notification, [attributeName]: value };
      }
      if (notification.template_id && notification.template_id === template_id) {
        return { ...notification, [attributeName]: value };
      }
      return notification;
    });
    setValueFor(API_NOTIFICATION_FIELDS.notifications, newList);
  };

  const changeWholeNotification = newNotification => {
    const newList = notifications.map(notification => {
      if (notification.id && notification.id === id) {
        return newNotification;
      }
      if (notification.template_id && notification.template_id === template_id) {
        return newNotification;
      }
      return notification;
    });
    setValueFor(API_NOTIFICATION_FIELDS.notifications, newList);
  };

  const handleInputChange = ({ target }) => changeNotificationValue(target.name, target.value);

  const handleNotificationTimeUpdate = momentValue => {
    const time = momentValue && momentValue.isValid() ? momentValue.format(config.timeFormat) : null;
    setNotificationTime(momentValue);
    changeNotificationValue(API_NOTIFICATION_FIELDS.start_at, time);
  };

  const handleStartingPointChange = ({ target: { value } }) => {
    const newNotification = calculateStartingPointChange(item, value, departure_date);
    changeWholeNotification(newNotification);
  };

  const startingPointValue = calculateStartingPointValue(delivery_at_relative_to, delivery_at_days);

  const handleDayChange = ({ target: { value } }) => {
    const day = calculateSelectedDayValue(value, startingPointValue);
    changeNotificationValue(API_NOTIFICATION_FIELDS.delivery_at_days, day);
  };

  useEffect(initNotificationDates, []);

  const renderDayDropdown = delivery_at_days !== 0 && isNumber(delivery_at_days) && (
    <NumberOfDaysWrapper>
      <DaySelector
        disabled={disabledForm}
        label="Number of days"
        size="small"
        value={delivery_at_days}
        onChange={handleDayChange}
        selectRange={400}
      />
    </NumberOfDaysWrapper>
  );

  const { disabled, startDay } = DISPLAYABLE_STARTING_POINT_OPTIONS;

  const contentValue = getContent(currentTemplate, content, language);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={6}>
        <Textarea
          rows={3}
          label={NOTIFICATIONS_LABELS.notificationText}
          name="content"
          value={!isLoading ? contentValue : ""}
          onChange={handleInputChange}
          disabled={disabledForm}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Content margin={6}>
          <Input
            id={`url-${id}`}
            size="small"
            name="url"
            label={NOTIFICATIONS_LABELS.notificationUrl}
            value={url}
            onChange={handleInputChange}
            disabled={disabledForm}
          />
        </Content>
        <DateAndTimeInputsWrapper>
          {renderDayDropdown}
          <CustomSelect
            size="small"
            value={startingPointValue !== disabled.value ? startingPointValue : startDay.value}
            onChange={handleStartingPointChange}
            name={`delivery_at_relative_to-for-${id}`}
            label={NOTIFICATIONS_LABELS.period}
            disabled={disabledForm}
          >
            {Object.values(DISPLAYABLE_STARTING_POINT_OPTIONS)
              .slice(1)
              .map(({ value, label }) => (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              ))}
          </CustomSelect>
          <BodyText3>at</BodyText3>
          <NotificationTimeInput
            label="Device local time"
            size="small"
            onChange={handleNotificationTimeUpdate}
            value={notificationTime}
            disabled={disabledForm}
          />
        </DateAndTimeInputsWrapper>
      </Grid>
    </Grid>
  );
};

NotificationForm.defaultProps = {
  disabled: false,
};

NotificationForm.propTypes = {
  item: PropTypes.shape().isRequired,
  context: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool,
};

export { NotificationForm, NotificationTimeInput };
