import React from "react";
import { useQuery } from "@tanstack/react-query";

const Icon = ({ iconId, style = {} }) => {
  const { data: { items: icons } = {} } = useQuery({ queryKey: [`/icon?count=500`] });

  const src = icons?.find(item => item.id === iconId)?.url;

  return src ? <img src={src} style={{ width: 25, height: 25, ...style }} alt="icon" /> : <div />;
};

export default Icon;
