import React from "react";
import { Wrap } from "./style";

const Pill = ({ active, selected, style, text, onClick, disabled }) => {
  return (
    <Wrap style={style} active={active} selected={selected} onClick={onClick} disabled={disabled}>
      {text}
    </Wrap>
  );
};

export default Pill;
