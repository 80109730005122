import styled from "styled-components";
import { responsiveConfig } from "config/responsive";

const StickyContainer = styled.div`
  display: flex;
  //   border-bottom: 2px solid #eaeaea;
  padding: 20px 0px;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  flex-wrap: nowrap;
  row-gap: 8px;
  min-height: 88px;
  ${props => props.style && { ...props.style }}

  ${p =>
    p.isSticky
      ? `
    border-bottom: none;
    position: sticky;
    margin-inline: -40px;
    padding-left: 40px;
    padding-right: 40px;
    top: ${p.offsetTabs ? p.extraTabPadding ? `68px` : `48px` : `0px`};
    z-index: 10;
    ${
      !p.noFade
        ? `
        &:after {
            content: '';
            position: absolute;
            bottom: -20px;
            left: 0px;
            width: 100%;
            height: 20px;
            background-image: linear-gradient(#FFFFFFFF, #FFFFFF00);
        }
    `
        : ``
    }

    @media (max-width: ${responsiveConfig.mediaPx.laptop}px) {
      margin-inline: -15px;
      padding-left: 15px;
      padding-right: 15px;
    }
  `
      : ``}
`;

export default StickyContainer;
