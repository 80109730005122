import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Input, TimePicker } from "components/ui/Forms";
import { Content } from "components/ui/Content";
import { STAY_WIZARD_CONTENT } from "constants/content";
import { useSelector } from "react-redux";
import { checkErrorExistFor } from "utils/validation";

const SettingsWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey10};
  padding-bottom: ${({ theme }) => theme.setSpacing(2)}px;
  margin-bottom: ${({ theme }) => theme.setSpacing(10)}px;
`;
const InputsWrapper = styled.div`
  max-width: 800px;
`;

const DndSettings = ({ 
  onEmailChange,
  onTimeChange,
  email,
  time,
  disabled
}) => {
  const wizardErrors = useSelector(({ stayWizard }) => stayWizard.errors.dnd);

  return (
    <SettingsWrapper>
      <InputsWrapper>
        <Content>
          <Input 
            label={STAY_WIZARD_CONTENT.dnd.contactEmail}
            name="dnd_contact_email" 
            value={email} 
            onChange={({ target }) => onEmailChange(target.value)} 
            error={checkErrorExistFor("email", wizardErrors)}
            helperText={wizardErrors ? wizardErrors.email : ""}
            disabled={disabled}
          />
        </Content>
        <Content>
          <TimePicker 
            label={STAY_WIZARD_CONTENT.dnd.cutOffTime} 
            name="dnd_cut_off_time" 
            value={time} 
            onChange={onTimeChange} 
            fullWidth 
            disabled={disabled}
          />
        </Content>
      </InputsWrapper>
    </SettingsWrapper>
  )
};

DndSettings.defaultProps = {
  disabled: false,
}

DndSettings.propTypes = {
  onEmailChange: PropTypes.func.isRequired,
  onTimeChange: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export { DndSettings, SettingsWrapper, InputsWrapper };
