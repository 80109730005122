import styled from "styled-components";

import { Link } from "components/ui/Links/Link";

const NavigationItem = styled(Link)`
  && {
    margin-right: ${({ theme }) => theme.setSpacing(3)}px;
    text-decoration: none;
    :last-child {
      margin-left: ${({ theme }) => theme.setSpacing(3)}px;
      margin-right: 0;
    }

    button span:first-of-type {
      white-space: nowrap;
    }
  }
`;

export { NavigationItem };
