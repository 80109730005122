import styled from "styled-components";

export const Wrap = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 0;
  background-color: #eaeaea;
  color: #696969;
`;
