import moment from "moment";

export const inputs = [
  {
    name: "branding_profile_id",
    label: "Select branding",
    optionLabelVar: "name",
    optionValueVar: "id",
    optionsVar: "allBrands",
    type: "select",
    shouldShow: ({ showBrandSelect, canOperatorsRead }) => showBrandSelect && canOperatorsRead,
    isClearable: false,
  },
  {
    name: "operator_code",
    label: "User ID",
    rules: {
      required: true,
      validate: () => {
        return "A:LSKDK:L";
      },
    },
    mainLanguageOnly: true,
    hint:
      "This is the login that will be used by your customers and business partners to access your Vamoos. Please keep this to between 3-12 letters, we recommend to make it as short as possible",
    limit: 12,
  },
  {
    name: "field1",
    label: "Property name",
    rules: { required: true },
  },
  {
    name: "meta.home_screen_description",
    label: "Home description",
    rules: { required: true },
    multiline: true,
    minRows: 2,
  },
  {
    name: "meta.phone",
    label: "Contact phone number",
  },
  {
    name: "meta.email",
    label: "Contact email",
  },
  {
    name: "meta.website",
    label: "Website",
  },
  {
    name: "meta.twitter",
    label: "Twitter",
  },
  {
    name: "meta.facebook",
    label: "Facebook",
  },
  {
    name: "meta.instagram",
    label: "Instagram",
  },
  {
    name: "meta.check_in_time",
    label: "Check-in-time (24h)",
    rules: { required: true },
    type: "time",
    format: "HH:mm",
    mainLanguageOnly: true,
    defaultValue: moment({ hour: 12, minutes: 0 }).format("HH:mm"),
  },
  {
    name: "meta.alert_emails",
    label: "Login messages",
    mainLanguageOnly: true,
    hint: "Send an email for each new login to the following addresses (multiple email addresses should be separated by a comma)",
  },
];
