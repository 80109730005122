import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Grid, Paper } from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";

import { ADMIN_ICONS_CONTENT, CREATE_CONTENT_LABELS } from "constants/content";
import { ADMIN_EDIT_ICONS_PATH } from "constants/routes";

import { AdminTemplate } from "components/templates/Admin/AdminTemplate";
import { StickyHeaderFormSection } from "components/ui/Forms";
import { SecondaryButton } from "components/ui/Buttons";
import { Spinner, SpinnerWrapper } from "components/ui/Spinner/Spinner";
import { NoResultsMessage } from "components/ui/Messages/NoResultsMessage";

import { SettingsSubMenu } from "feature/admin/_shared/SettingsSubMenu";

import { getPublicIconsStart } from "store/icons/actions";
import { GET_PUBLIC_ICONS_START } from "store/icons/actionTypes";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

import { IconsSection } from "./IconsSection";

const HeaderWrapper = styled.div`
  margin: 40px 0 0 0;
`;

const IconsIndex = () => {
  const permissionsService = usePermissionsService();
  const [defaultIcons, setDefaultIcons] = useState([]);
  const [icons, setIcons] = useState([]);

  const { listOfIcons, inProgress, finished, actionType } = useSelector(state => state.icons);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const contextBar = {
    left: null,
    middle: SettingsSubMenu,
    right: null,
  };

  const handleRedirectToEditPage = () => {
    navigate(ADMIN_EDIT_ICONS_PATH);
  };

  const canUserCreateNewIcon = permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.admin, PERMISSIONS.resources.icons);

  const canUserEditIcon = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.admin, PERMISSIONS.resources.icons);

  const canUserDeleteIcon = permissionsService.can(PERMISSIONS.actions.delete, PERMISSIONS.sections.admin, PERMISSIONS.resources.icons);

  const renderedContent = () => {
    if (inProgress && actionType === GET_PUBLIC_ICONS_START) {
      return (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      );
    }

    if (finished && actionType === GET_PUBLIC_ICONS_START && !listOfIcons.length) {
      return <NoResultsMessage>{ADMIN_ICONS_CONTENT.noIconsDefined}</NoResultsMessage>;
    }

    return (
      <>
        <IconsSection name={ADMIN_ICONS_CONTENT.default} icons={defaultIcons} />
        <IconsSection name={ADMIN_ICONS_CONTENT.general} icons={icons} />
      </>
    );
  };

  const getIcons = () => {
    dispatch(getPublicIconsStart());
  };

  const prepareIconsList = () => {
    const defaultIconList = listOfIcons.filter(icon => icon.isDefault);
    const generalIconList = listOfIcons.filter(icon => !icon.isDefault);
    setDefaultIcons(defaultIconList);
    setIcons(generalIconList);
  };

  const AddButton = () =>
    canUserCreateNewIcon || canUserEditIcon || canUserDeleteIcon ? (
      <SecondaryButton onClick={handleRedirectToEditPage}>
        <EditOutlinedIcon />
        {CREATE_CONTENT_LABELS.edit}
      </SecondaryButton>
    ) : null;

  useEffect(getIcons, []);

  useEffect(prepareIconsList, [listOfIcons]);

  const stickyContainer = ({ children }) => <Grid md={8}>{children}</Grid>;

  return (
    <AdminTemplate contextBar={contextBar}>
      <Grid container justifyContent="center">
        <Grid item sm={10}>
          <Paper>
            <Grid container justifyContent="center">
              <Grid item md={8}>
                <HeaderWrapper>
                  <StickyHeaderFormSection
                    title={ADMIN_ICONS_CONTENT.listOfPoiIcons}
                    headerActions={AddButton}
                    stickyVariant="expanded"
                    stickyContainer={stickyContainer}
                  >
                    {renderedContent()}
                  </StickyHeaderFormSection>
                </HeaderWrapper>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </AdminTemplate>
  );
};

export { IconsIndex };
