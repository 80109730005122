import React, { useState } from "react";
import styled from "styled-components";
import { Card, IconButton, MobileStepper } from "@material-ui/core";
import PropTypes from "prop-types";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const CustomCard = styled(Card)`
  && {
    background-color: ${({ theme }) => theme.colors.grey10};
  }
`;

const SlideIconButton = styled(IconButton)`
  && {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.brand};

    .MuiIconButton-label {
      max-width: 24px;
      width: 24px;
      overflow: hidden;

      svg {
        ${({ isBackButton }) =>
          isBackButton
            ? `
            margin-left: -4px;
            transform: rotate(180deg);
        `
            : `
            margin-left: 4px;
            `}
      }
    }
  }
`;

const CaruselContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const Caption = styled.div`
  font-family: Montserrat;
  font-size: ${({ theme }) => theme.setSpacing(4)}px;
  font-weight: normal;
  line-height: 1.25;
  letter-spacing: normal;
  margin: ${({ theme }) => theme.setSpacing(8)}px auto;
  color: ${({ theme }) => theme.colors.black};
`;
const PreviewContainer = styled.div`
  text-align: center;
`;
const ButtonsContainer = styled.div`
  margin: auto ${({ theme }) => theme.setSpacing(15)}px;
  width: 50px;
`;

const PresentationCarousel = ({ slides }) => {
  const [slideIndex, setSlideIndex] = useState(0);

  const slideNext = () => {
    const nextSlide = slides.length - 1 > slideIndex ? slideIndex + 1 : slideIndex;
    setSlideIndex(nextSlide);
  };
  const slidePrev = () => {
    const prevSlide = slideIndex > 0 ? slideIndex - 1 : 0;
    setSlideIndex(prevSlide);
  };
  const currentSlide = slides.find((_, index) => index === slideIndex);

  const nextButton = (
    <SlideIconButton onClick={slideNext}>
      <ArrowForwardIosIcon />
    </SlideIconButton>
  );
  const backButton = (
    <SlideIconButton onClick={slidePrev} isBackButton>
      <ArrowForwardIosIcon />
    </SlideIconButton>
  );

  return (
    <CustomCard>
      <CaruselContainer>
        <ButtonsContainer>{slideIndex > 0 && backButton}</ButtonsContainer>
        <PreviewContainer>
          <Caption>{currentSlide?.label}</Caption>
          {currentSlide?.preview}
          <Caption>
            {slideIndex + 1}/{slides.length}
          </Caption>
        </PreviewContainer>

        <ButtonsContainer>{slideIndex + 1 < slides.length && nextButton}</ButtonsContainer>
      </CaruselContainer>
    </CustomCard>
  );
};

PresentationCarousel.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      preview: PropTypes.element,
    }),
  ).isRequired,
};

export { PresentationCarousel };
