import styled from "styled-components";
import { Dialog } from "@material-ui/core";
import { BodyText, BodyText2 } from "../Typography/Typography";

export const Title = styled(BodyText)`
  &&& {
    font-weight: bold;
  }
`;
export const Description = styled(BodyText2)`
  &&& {
    color: ${({ theme }) => theme.colors.grey50};
  }
`;

export const StyledDialog = styled(Dialog)`
  && {
    .MuiPaper-root {
      padding: ${({ theme }) => theme.setSpacing(2)}px 0;
    }
  }
`;
