import React from "react";
import styled from "styled-components";
import { ImageOutlined } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { CUSTOM_APPS, GLOBAL_CONTENT } from "constants/content";

import { AttachFilesButton } from "components/ui/Buttons";
import { IMAGES_MIME_TYPES } from "constants/defaults";
import { DEFAULT_LIBRARY_TABS } from "components/ui/LibraryPopup/LibraryPopup";
import { ChipWithRedirect } from "components/ui/Chips/ChipWithRedirect";
import { TitleText4 } from "components/ui/Typography/Typography";

import { setValueForIconBackgroundColor, setValueForIconTransparentImage } from "store/customAppForm/actions";

import { useRefreshedFileUrl } from "hooks/useRefreshedFileUrl";
import { VamoosImage } from "domain/VamoosImage";
import { isObjectEmpty } from "utils/object";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

import {
  CustomCard,
  SectionTitle,
  CardSectionsContainer,
  CardSection,
  HintWrapper,
  PreviewCardSection,
  InputContentWrapper,
  ColorPickerWrapper,
  CustomInputContainer,
} from "../sharedComponents";
import { CustomColorPicker } from "../ColorPicker/CustomColorPicker";

const AndroidIconPreviewContainer = styled.div`
  width: 93px;
  height: 94px;
  border-radius: 70px;
  object-fit: cover;
  ${({ backgroundColor }) => `background-color: ${backgroundColor};`}
`;

const AndroidIconPreview = styled.img`
  width: 93px;
  height: 94px;
  border-radius: 70px;
  object-fit: cover;
`;

const IOSIconPreviewContainer = styled.div`
  width: 93px;
  height: 94px;
  border-radius: 20px;
  object-fit: cover;
  ${({ backgroundColor }) => `background-color: ${backgroundColor};`}
`;

const IOSIconPreview = styled.img`
  width: 93px;
  height: 94px;
  border-radius: 20px;
  object-fit: cover;
`;

const IconPreviewContainer = styled.div`
  margin: ${({ theme }) => theme.setSpacing(3)}px;
  text-align: center;

  p {
    margin-bottom: ${({ theme }) => theme.setSpacing(7)}px;
  }
`;

const Label = styled.div`
  font-size: ${({ theme }) => theme.setSpacing(3.5)}px;
  margin-bottom: ${({ theme }) => theme.setSpacing(2)}px;
  font-weight: 500;
`;

const AppIconSection = ({ isHistoryVersion }) => {
  const permissionsService = usePermissionsService();
  const dispatch = useDispatch();

  const iconImage = useSelector(state => state.customAppForm.customAppForm.images.icon);

  // Here should be something from new one property
  const iconTransparentImage = useSelector(state => state.customAppForm.customAppForm.images.iconTransparent);
  const iconBackgroundColor = useSelector(state => state.customAppForm.customAppForm.colors.iconBackgroundColor);

  const errors = useSelector(state => state.customAppForm.errors);
  const caStatus = useSelector(state => state.customAppForm?.customAppForm?.status);

  const iconUrl = useRefreshedFileUrl(iconImage?.previewUrl);
  const iconTransparentUrl = useRefreshedFileUrl(iconTransparentImage?.previewUrl);

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.operator, PERMISSIONS.resources.operators);
  const canCreate = permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.operator, PERMISSIONS.resources.operators);

  const editDisabled = !((caStatus === null && canCreate) || (caStatus === "draft" && canEdit));

  const handleChangeAttachment = files => {
    const image = VamoosImage(files[0]);

    dispatch(setValueForIconTransparentImage(image));
  };
  const handleRemoveAttachment = () => {
    if (!(isHistoryVersion || editDisabled)) {
      dispatch(setValueForIconTransparentImage(null));
    }
  };

  const onIconBackgroundColorUpdate = value => {
    dispatch(setValueForIconBackgroundColor(value));
  };

  const isIconPresent = iconImage && !isObjectEmpty(iconImage);
  const isIconTransparentPresent = iconTransparentImage && !isObjectEmpty(iconTransparentImage);

  const iconsPreview = (
    <>
      <IconPreviewContainer>
        <TitleText4>{CUSTOM_APPS.general.iconSection.ios}</TitleText4>
        <IOSIconPreviewContainer backgroundColor={iconBackgroundColor}>
          <IOSIconPreview src={iconTransparentUrl || iconUrl} />
        </IOSIconPreviewContainer>
      </IconPreviewContainer>

      <IconPreviewContainer>
        <TitleText4>{CUSTOM_APPS.general.iconSection.android}</TitleText4>
        <AndroidIconPreviewContainer backgroundColor={iconBackgroundColor}>
          <AndroidIconPreview src={iconTransparentUrl || iconUrl} />
        </AndroidIconPreviewContainer>
      </IconPreviewContainer>
    </>
  );

  const renderIconTransparentUploader = () => (
    <AttachFilesButton
      label={`${GLOBAL_CONTENT.attach}/${GLOBAL_CONTENT.link}`}
      size="small"
      onSelect={handleChangeAttachment}
      allowedFileTypes={IMAGES_MIME_TYPES}
      tabs={[DEFAULT_LIBRARY_TABS.library, DEFAULT_LIBRARY_TABS.files]}
      error={errors?.images?.iconTransparent || null}
      disabled={isHistoryVersion || editDisabled}
      returnEncoded={false}
      minResolution="1024x1024"
    />
  );

  const renderIconTransparentDeleter = () => (
    <ChipWithRedirect
      onDelete={handleRemoveAttachment}
      item={iconTransparentImage}
      maxChar={15}
      icon={<ImageOutlined />}
      editable={(caStatus === null && canCreate) || (caStatus === "draft" && canEdit)}
    />
  );

  const renderIconDeleter = () => (
    <ChipWithRedirect onDelete={() => {}} item={iconImage} maxChar={15} editable={false} disabled icon={<ImageOutlined />} />
  );

  return (
    <CustomCard>
      <CardSectionsContainer>
        <CardSection>
          <InputContentWrapper>
            <SectionTitle>{CUSTOM_APPS.general.iconSection.title}</SectionTitle>
            {isIconPresent && (
              <CustomInputContainer>
                <Label>Icon image</Label>
                {renderIconDeleter()}
              </CustomInputContainer>
            )}
            <CustomInputContainer>
              <Label>Transparent icon image</Label>
              {isIconTransparentPresent ? renderIconTransparentDeleter() : renderIconTransparentUploader()}
              <HintWrapper>{CUSTOM_APPS.general.iconSection.attachIconHint}</HintWrapper>
            </CustomInputContainer>
            <CustomInputContainer>
              <ColorPickerWrapper>
                <CustomColorPicker
                  color={iconBackgroundColor}
                  label="Icon background colour"
                  onColorChange={onIconBackgroundColorUpdate}
                  disabled={isHistoryVersion || editDisabled}
                  fullWidth
                />
              </ColorPickerWrapper>
            </CustomInputContainer>
          </InputContentWrapper>
        </CardSection>
        <PreviewCardSection>{(isIconTransparentPresent || isIconPresent) && iconsPreview}</PreviewCardSection>
      </CardSectionsContainer>
    </CustomCard>
  );
};

AppIconSection.defaultProps = {
  isHistoryVersion: false,
};

AppIconSection.propTypes = {
  isHistoryVersion: PropTypes.bool,
};

export { AppIconSection };
