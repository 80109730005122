import styled from "styled-components";

const GreySection = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.setSpacing(6)}px ${theme.setSpacing(10)}px`};
  margin-top: ${({ theme }) => `${theme.setSpacing(5)}px`};
  background: ${({ theme }) => theme.colors.withOpacity(theme.colors.grey10, 0.3)};
`;

export { GreySection };
