import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { GLOBAL_CONTENT } from "constants/content";
import { Wrap, Text } from "./style";

const BackButton = ({ onClick }) => {
  return (
    <Wrap role="button" tabIndex={0} onKeyDown={onClick} onClick={onClick}>
      <ArrowBackIosIcon fontSize="8" />
      <Text>{GLOBAL_CONTENT.back}</Text>
    </Wrap>
  );
};

export default BackButton;
