import React from "react";

import { useManageTripContext } from "feature/panel/Trips/_shared/ManageTripContext";
import { Messaging } from "feature/panel/_shared/Messaging/Messaging";

const MessagingWithTripsContext = () => {
  const { userId: operator_code, passcode: reference_code } = useManageTripContext();

  return (
    <Messaging conversationDetails={{ operator_code, reference_code }} contextHandler={useManageTripContext} configurationType="trip" />
  );
};

export { MessagingWithTripsContext };
