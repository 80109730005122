import React from "react";
import ItineraryTitle from "feature/panel/Itinerary/components/Title";
import { Wrapper } from "feature/panel/Itinerary/style";
import moment from "moment";
import InspirationForm from "feature/panel/Itinerary/components/pagesElements/InspirationsForm";
import { lineHeight } from "config/theme/fonts";
import TitleRow from "feature/panel/Itinerary/components/TitleRow";

const Inspirations = ({ form, isWiped, canEdit, type = "trip" }) => {
  const { watch } = form;

  const period = watch("localInspiration.period");
  const startDay = watch("general.departure_date");
  const endDay = watch("general.return_date");
  const numberOfDays = watch("localInspiration.delivery_at_days");
  const deviceLocalTime = watch("localInspiration.start_at") || new Date();
  const showTime = type === "trip" && period && period !== "disabled";

  const getDate = () => {
    const obj = {
      startDay: moment(startDay).format("DD MMM YYYY"),
      endDay: moment(endDay).format("DD MMM YYYY"),
      daysBefore: moment(startDay)
        .subtract(numberOfDays, "days")
        .format("DD MMM YYYY"),
      daysAfter: moment(startDay)
        .add(numberOfDays, "days")
        .format("DD MMM YYYY"),
      daysBeforeEnd: moment(endDay)
        .subtract(numberOfDays, "days")
        .format("DD MMM YYYY"),
      daysAfterEnd: moment(endDay)
        .add(numberOfDays, "days")
        .format("DD MMM YYYY"),
    };

    return obj[period];
  };

  return (
    <Wrapper slimTopPadding>
      <TitleRow text="Inspirations" isSticky>
        {showTime && (
          <>
            <div style={{ fontWeight: 700, flexShrink: 0 }}>
              {getDate()} {moment(deviceLocalTime, "HH:mm").format("HH:mm")}
            </div>
          </>
        )}
      </TitleRow>

      <div style={{ height: 20 }} />

      <InspirationForm form={form} isWiped={isWiped} canEdit={canEdit} />
    </Wrapper>
  );
};

export default Inspirations;
