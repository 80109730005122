import { useRef, useEffect } from "react";

export const useEffectDependenciesOnly = (func, deps) => {
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      func();
    } else {
      isMounted.current = true;
    }
  }, deps);
};
