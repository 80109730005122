import { createElement as h } from "react";
import styled from "styled-components";

export const withDynamicElement = Component => {
  const bucket = Object.create(null);

  const DynamicElement = props => {
    const { element } = props;

    if (typeof element !== "string" || !styled.hasOwnProperty(element)) {
      return h(Component, props);
    }

    if (bucket[element] === undefined) {
      bucket[element] = Component.withComponent(element);
    }

    return h(bucket[element], props);
  };

  const name = Component.displayName || Component.constructor.name;

  if (name) {
    DynamicElement.displayName = `DynamicElement(${name})`;
  }

  return DynamicElement;
};
