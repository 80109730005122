import { responsiveConfig } from "config/responsive";
import { colors } from "config/theme/colors";
import { getMediaPx } from "helpers";
import styled from "styled-components";

export const IconWrap = styled.div`
  color: ${colors.grey50};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  width: 80px;
  height: 80px;
  background-color: rgba(232, 232, 233, 0.3);
  border-left: 1px solid ${colors.grey10};
  &:hover {
    filter: brightness(95%);
  }
  svg {
    color: ${colors.grey40};
  }
  @media (max-width: ${p => getMediaPx(p, p.showMobileVersionFrom || "md")}px) {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: ${colors.redLight};
    width: 32px;
    height: 32px;
    border-radius: 100%;
    z-index: 1;
    svg {
      color: #eb5757;
    }
  }
`;
