import { DeleteOutline } from "@mui/icons-material";
import DeleteButton from "components/_new/DnD/DeleteIcon";
import TableAction from "components/_new/Table/Action";
import { ImagePlaceholder } from "components/ui/Images";
import Input from "components/ui/Inputs/TextInput";
import { ErrorText } from "components/ui/Inputs/TextInput/style";
import { colors } from "config/theme/colors";
import { IMAGES_MIME_TYPES } from "constants/defaults";
import React, { useState } from "react";
import { getFilePreviewUrl } from "utils/library";

import { ResponsiveHideFrom, ResponsiveShowFrom } from "feature/panel/Itinerary/responsive";
import { Controller } from "react-hook-form";
import { generateUniqueId } from "utils";
import { renderAddFileMini } from "../../Trips/Locations/ListOfLocations/item";
import { Buttons, Grid, Image, ImageWrap, PlaceholderWrap } from "./style";

const Item = ({ item, control, index, watch, onDelete, setValue, errors, hasFile, append }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const watchImageName = `storyboard.details.${index}.image`;
  const image = watch(watchImageName);
  const url = image && getFilePreviewUrl(image);
  const hasError = errors?.storyboard?.details?.[index]?.image;

  const handleDeleteFile = () => setValue(watchImageName, null, { shouldDirty: true });

  const handleConfirm = (files, onChange) => {
    if (!Array.isArray(files)) {
      onChange(files);
    } else {
      const remainingFiles = files.filter((file, index) => index !== 0);

      for (const file of remainingFiles) {
        append({
          id: generateUniqueId(),
          isNew: true,
          headline: "",
          content: "",
          location: null,
          documents: [],
          image: file,
        })
      }
      onChange(files[0]);
    }
  };

  return (
    <div key={item.rf_id}>
      <Grid>
        <ResponsiveShowFrom size="md">
          <DeleteButton onClick={() => onDelete(index)} />
        </ResponsiveShowFrom>
        <div style={{ display: "flex", alignItems: "center", gap: 20, padding: "0 15px" }}>
          <ImageWrap hasError={hasError}>
            {url ? (
              <Image src={url} alt="preview" />
            ) : (
              <PlaceholderWrap>
                <ImagePlaceholder hasError={hasError} />
              </PlaceholderWrap>
            )}
          </ImageWrap>{" "}
          <Input control={control} name={`storyboard.details.${index}.headline`} label="Title" forwardKey={item.id} autoFocus />
        </div>
        <Buttons>
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            name={watchImageName}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              renderAddFileMini({
                control,
                name: watchImageName,
                handleDeleteFile,
                watchItem: image,
                mediaTypes: IMAGES_MIME_TYPES,
                actionMobileStyle: { borderLeft: 0, borderTop: `1px solid ${colors.grey10}` },
                hideUrl: true,
                multiUpload: true,
                onCustomConfirm: (files => handleConfirm(files, onChange)),
                modalTypes: ["library", "upload"],
                shouldButtonExpand: hasFile,
                useNormalButton: !!image,
              })
            )}
          />
          <ResponsiveHideFrom size="md">
            <TableAction
              element={<DeleteOutline />}
              mobileStyle={{ width: "100%", borderTop: `1px solid ${colors.grey10}` }}
              onClick={() => onDelete(index)}
            />
          </ResponsiveHideFrom>
        </Buttons>
      </Grid>

      {hasError && <ErrorText style={{ margin: 0, padding: "0 0 15px 15px" }}>Image is required</ErrorText>}
    </div>
  );
};

export default Item;
