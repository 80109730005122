import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";

import styles from "config/theme";
import { TitleText3 } from "components/ui/Typography/Typography";
import { MESSAGING } from "constants/defaults";

const { VIEWPORT_BREAK_SIZE } = MESSAGING;

const StickyBar = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  min-width: ${({ theme }) => theme.setSpacing(95)}px;
  cursor: pointer;
  height: ${({ theme }) => theme.setSpacing(16)}px;
  padding: ${({ theme }) => theme.setSpacing(5)}px;
  background-color: ${props => (props.active ? "#F9ECE6" : `${styles.colors.grey30}19`)};

  &:hover {
    background-color: ${props => (props.active ? "#FAD9CB" : "#EEEEEE")};
  }

  @media (max-width: ${VIEWPORT_BREAK_SIZE}px) {
    min-width: ${({ theme }) => theme.setSpacing(80)}px;
  }

  svg {
    fill: ${props => (props.active ? styles.colors.brand : styles.colors.grey50)};
  }
`;

const BoldTitle = styled(TitleText3)`
  && {
    font-weight: 600;
    margin-left: ${({ theme }) => theme.setSpacing(4)}px;
    user-select: none;
    ${props => props.active && `color: ${styles.colors.brand};`}
  }
`;

const SettingsBar = ({ active, handleSettingsBarClick }) => {
  return (
    <StickyBar onClick={handleSettingsBarClick} active={active ? 1 : 0}>
      <SettingsApplicationsIcon />
      <BoldTitle active={active ? 1 : 0}>Settings</BoldTitle>
    </StickyBar>
  );
};

SettingsBar.propTypes = {
  active: PropTypes.bool.isRequired,
  handleSettingsBarClick: PropTypes.func.isRequired,
};

export { SettingsBar, StickyBar, BoldTitle };
