import styled from "styled-components";
import { getMediaPx } from "helpers";
import { Item } from "../../ViewFlightModal/style";

export const Info = styled.div`
  padding: 15px 25px;
  background-color: rgba(232, 232, 233, 0.3);
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 22px;
  border-radius: 8px;

  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    grid-template-columns: 1fr;
  }

  #left,
  #right {
    ${Item}:last-child {
      margin-bottom: 0;
    }
  }
`;
