import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { PERMISSIONS } from "constants/permissions";
import { Poi } from "domain/Poi";
import { PoiHasBeenCreated } from "events/PoiHasBeenCreated";
import { PoiHasBeenDeleted } from "events/PoiHasBeenDeleted";
import { PoiHasBeenUpdated } from "events/PoiHasBeenUpdated";
import { usePermissionsService } from "hooks/usePermissionsService";
import { EventBus } from "services/application/EventBus";
import MapWidget from "feature/panel/_shared/MapWidget";

const PoisMap = ({ pois, onAddMarker, loading }) => {
  const permissionsService = usePermissionsService();
  const canCreatePoi = permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.operator, PERMISSIONS.resources.pois);

  const mapWidgetRef = useRef();

  const handleAddPoiFromMap = addedPoi => {
    const { latitude, longitude, name, country, location } = addedPoi;

    const newPoi = new Poi();

    newPoi.name = name;
    newPoi.country = country;
    newPoi.latitude = Number.parseFloat(latitude);
    newPoi.longitude = Number.parseFloat(longitude);
    newPoi.location = location;

    onAddMarker(newPoi);
  };

  useEffect(() => {
    const closeInfoWindow = () => {
      mapWidgetRef.current?.closeInfoWindow();
    };

    EventBus.on(PoiHasBeenCreated, closeInfoWindow);
    EventBus.on(PoiHasBeenUpdated, closeInfoWindow);
    EventBus.on(PoiHasBeenDeleted, closeInfoWindow);

    return () => {
      EventBus.remove(PoiHasBeenCreated, closeInfoWindow);
      EventBus.remove(PoiHasBeenUpdated, closeInfoWindow);
      EventBus.remove(PoiHasBeenDeleted, closeInfoWindow);
    };
  }, []);

  const infoWindowProps = {
    onAddMarker: handleAddPoiFromMap,
    addButtonText: "Add POI",
    disableAddButton: !canCreatePoi,
  };

  return (
    <MapWidget
      data={{ pois }}
      switchControls={["pois"]}
      defaultSwitchesEnabled={["pois"]}
      infoWindowsProps={{
        search: infoWindowProps,
        rightClick: infoWindowProps,
      }}
      searchPlaceholder="Search POI to add"
      searchByLocation
      loading={loading}
    />
  );
};

PoisMap.propTypes = {
  pois: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onAddMarker: PropTypes.func.isRequired,
};

export { PoisMap };
