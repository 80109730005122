import React from "react";
import MuiAccordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import { InputLabel } from "@material-ui/core";

import { font } from "config/theme/fonts";
import { Wrap } from "./style";

const Accordion = ({ title, secondaryText, children, onChange, expanded, styleContainer, styleSummary, inputLikeLabel }) => {
  return (
    <Wrap style={styleContainer}>
      {inputLikeLabel && <InputLabel isFocused>{inputLikeLabel}</InputLabel>}
      <MuiAccordion expanded={expanded} style={{ backgroundColor: "transparent" }} onChange={onChange}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} style={styleSummary} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography sx={{ width: "95%", flexShrink: 0, fontFamily: font }}>{title}</Typography>
          {secondaryText && <Typography sx={{ color: "text.secondary", fontFamily: font }}>{secondaryText}</Typography>}
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </MuiAccordion>
    </Wrap>
  );
};

export default Accordion;
