import dot from "dot-object";

import { CLEAR_ERRORS, CLEAR_ACTION_FLAGS, CLEAR_ALL_STORES, SET_ERRORS } from "store/app/actionTypes";

import notifications from "constants/notifications";
import { ENV_TYPES } from "constants/defaults";

import config from "config/app";

import { isObjectEmpty } from "./object";

const symbolsToValidate = ["[", "]"];

export const reducer = (state, action, reducerActions) => {
  const { type, payload } = action;
  const actionHandler = reducerActions[type];
  if (actionHandler && typeof actionHandler === "function") {
    return actionHandler(state, payload);
  }
  return state;
};

export const setState = (state, newState) => ({ ...state, ...newState });

export const clearErrors = state => setState(state, { errors: {} });
export const setErrors = (state, errors) => setState(state, { errors });
export const clearActionFlags = state => setState(state, { inProgress: false, finished: false });

export const globalReducerActions = (context, initialReducerState, actions = {}) => ({
  [CLEAR_ALL_STORES]: () => initialReducerState,
  [CLEAR_ERRORS]: (state, payload) => (payload.module === context ? clearErrors(state) : state),
  [SET_ERRORS]: (state, payload) => (payload.module === context ? setErrors(state, payload.errors) : state),
  [CLEAR_ACTION_FLAGS]: (state, payload) => (payload.module === context ? clearActionFlags(state) : state),
  ...actions,
});

export const checkSymoblsInTheString = (string, arrayOfSymbols) => {
  if (typeof string === "string" && Array.isArray(arrayOfSymbols)) {
    return arrayOfSymbols.some(symbol => string.includes(symbol));
  }
  return false;
};

export const convertToObjectType = elemet => {
  if (checkSymoblsInTheString(elemet, symbolsToValidate)) {
    const [name, number] = elemet.split("[");
    const iterator = number.replace("]", "");
    return `${name}.${iterator}`;
  }
  return elemet;
};

export const convertToFinalPath = pathElements => {
  if (typeof pathElements === "string") {
    return pathElements
      .split(".")
      .map(element => convertToObjectType(element))
      .join(".");
  }
  return pathElements;
};

export const setResponseErrorMessages = errors => {
  if (Array.isArray(errors)) {
    const errorCollection = errors.reduce((acc, e) => {
      const { message, path } = e;
      const errorPath = String(path).replace(/^\.body./, "");

      if (checkSymoblsInTheString(errorPath, symbolsToValidate)) {
        if (errorPath.charAt(errorPath.length - 1) === "]") return acc;
        const finalPath = convertToFinalPath(errorPath);
        return { ...acc, [finalPath]: message };
      }
      return { ...acc, [errorPath]: message };
    }, {});
    return dot.object(errorCollection);
  }
  return {};
};

export const setResponseMessage = response => {
  const serverErrorStatusCode = 500;
  const { data, body, status } = response;
  try {
    if (data) return data.body || data.error;
    if (body) return body;
    return notifications.general[status || serverErrorStatusCode];
  } catch (e) {
    return notifications.general[serverErrorStatusCode];
  }
};

export const handleErrorMessages = errors => {
  const { data } = errors?.response ? errors.response : {};
  // For better bug tracking. It is available only in dev mode.
  // eslint-disable-next-line no-console
  if (!data && config.env === ENV_TYPES.dev) console.log(errors);

  let errorsObject = data?.error ? setResponseErrorMessages(data?.error) : null;
  if (errorsObject && Object.keys(errorsObject).length === 0) errorsObject = setResponseMessage(errors.response);
  return errorsObject;
};

export const createAction = type => (payload, options = {}) => {
  const areOptionsPresent = !isObjectEmpty(options);
  const defaultParams = payload !== undefined ? { type, payload } : { type };

  return areOptionsPresent ? { ...defaultParams, options } : defaultParams;
};
