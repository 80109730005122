import React from "react";
import moment from "moment";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Folder } from "@material-ui/icons";
import LinkIcon from "@material-ui/icons/Link";
import TableCell from "@material-ui/core/TableCell";

import { Link } from "components/ui/Links/Links";
import { TableRow } from "components/ui/Tables";
import config from "config/app";
import { PANEL_ROOT_PATH } from "constants/routes";
import { TextLimiter } from "components/ui/TextLimiter";
import { LONG_CELL_MAX_CHARACTERS } from "constants/defaults";
import { openPreviewInNewTab } from "utils/library";
import { useActiveIcon } from "hooks/useActiveIcon";
import { UrlRefreshService } from "services/application/UrlRefreshService";
import { StoryboardFile } from "domain/StoryboardFile";
import { encodeParameter } from "utils/url";

const FileAnchor = styled.a`
  color: ${({ theme }) => theme.colors.grey100};
  ${({ isStoryboardEntry }) => (isStoryboardEntry ? "" : "cursor: pointer;")};

  :focus,
  :visited,
  :hover {
    color: ${({ theme }) => theme.colors.grey100};
  }
  :hover {
    text-decoration: underline;
  }
`;

const StyledFolder = styled(Folder)`
  color: ${({ theme }) => theme.colors.grey50};
`;

const StyledLink = styled(LinkIcon)`
  color: ${({ theme }) => theme.colors.grey100};
`;

const FileIconWrapper = styled.span`
  color: ${({ theme }) => theme.colors.grey100};
`;

const CustomTableRow = ({ item, onClickAction }) => {
  const { is_folder, name, updated_at, remote_url, file, path, id, mime_type } = item;

  const handleWeblinkOpen = async () => {
    const refreshedUrl = await UrlRefreshService.refresh(remote_url);
    openPreviewInNewTab(refreshedUrl);
  };

  const handlePreviewOpen = () => {
    if (!file) {
      handleWeblinkOpen();
    } else {
      onClickAction({ file, name, id });
    }
  };

  const folderUrl = `${PANEL_ROOT_PATH}${path
    .replace("//", "/")
    .split("/")
    .map(encodeParameter)
    .join("/")}`;

  const nameCut = <TextLimiter maxChar={LONG_CELL_MAX_CHARACTERS} text={name} />;

  const LinkTo = text => (
    <Link to={folderUrl} cv="grey100" linkFor="table">
      {text}
    </Link>
  );
  const isStoryboardEntry = mime_type === StoryboardFile.FILE_TYPE;
  const activeFileIcon = useActiveIcon(file);

  const renderIcon = () => {
    return remote_url ? <StyledLink /> : <FileIconWrapper>{activeFileIcon}</FileIconWrapper>;
  };

  return (
    <>
      <TableRow item={item}>
        <TableCell style={{ width: "50px" }} align="center">
          {is_folder ? LinkTo(<StyledFolder />) : renderIcon()}
        </TableCell>
        <TableCell align="left" style={{ width: "calc(100% - 1px)" }}>
          {is_folder ? (
            LinkTo(nameCut)
          ) : (
            <FileAnchor onClick={handlePreviewOpen} role="button" isStoryboardEntry={isStoryboardEntry}>
              {nameCut}
            </FileAnchor>
          )}
        </TableCell>
        <TableCell style={{ width: "200px" }}>{moment(updated_at).format(config.dateTimeFormat)}</TableCell>
      </TableRow>
    </>
  );
};

CustomTableRow.defaultProps = {
  item: {},
};

CustomTableRow.propTypes = {
  item: PropTypes.shape(),
  onClickAction: PropTypes.func.isRequired,
};

export { CustomTableRow };
