import { ERRORS } from "constants/content";

export const localisedNotificationFormValidator = notification => {
  const { content } = notification;
  const errors = {};

  if(!content) {
    errors.default = {
      content: ERRORS.isRequired("Notification text")
    }
  }

  return errors;
}