import React from "react";
import PropTypes from "prop-types";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";

import { Chip } from "components/ui/Chips";
import { TextLimiter } from "components/ui/TextLimiter";

import { getFileHighQualityUrl, getFilePreviewUrl, openPreviewInNewTab } from "utils/library";
import { UrlRefreshService } from "services/application/UrlRefreshService";
import { useActiveIcon } from "hooks/useActiveIcon";
import { InputLabel } from "@mui/material";
import { ResponsiveShowFrom } from "feature/panel/Itinerary/responsive";

export const labelStyles = {
  fontFamily: "Montserrat, sans-serif",
  fontSize: 12,
  position: "absolute",
  backgroundColor: "#fff",
  zIndex: 10,
  width: "fit-content",
  top: -9,
  left: 14,
  padding: "0 5px",
};

const ChipWithRedirect = ({
  onDelete,
  icon,
  maxChar,
  dotsPosition,
  item,
  highQuality,
  editable,
  disabled,
  onChipCustomClick,
  handlePreview = false,
  label,
  chipWrapStyle = {},
  chipStyle = {},
}) => {
  if (!item) return;
  const activeIcon = useActiveIcon(item.file || item);

  const handlePreviewOpen = async () => {
    if (onChipCustomClick) {
      onChipCustomClick();
      return;
    }
    const refreshedUrl = await UrlRefreshService.refresh(highQuality ? getFileHighQualityUrl(item) : getFilePreviewUrl(item));
    openPreviewInNewTab(refreshedUrl);
    if (handlePreview) handlePreview(refreshedUrl);
  };

  const url =
    item.url ||
    item.https_url ||
    item.web_url ||
    item.preview_url ||
    item.previewUrl ||
    item.remote_url?.https_url ||
    item.web_url ||
    item.preview_url ||
    item.previewUrl ||
    item.remote_url;

  const getNameFromUrl = url => {
    const urlParts = url?.split("/");

    const lastPart = urlParts?.[urlParts?.length - 1];

    const name = lastPart?.split("?")[0];

    return name;
  };

  const name = item.web_url || item.webUrl || item.file_name || item.name || item.short_name || item.remote_url || getNameFromUrl(url);

  const tooltipItem = () => <TextLimiter text={name} maxChar={maxChar} dotsPosition={dotsPosition} />;

  return (
    <div style={{ position: "relative", ...chipWrapStyle }}>
      {label && (
        <ResponsiveShowFrom size="md">
          <InputLabel style={labelStyles} data-shrink="true">
            {label}
          </InputLabel>
        </ResponsiveShowFrom>
      )}

      <Chip
        icon={activeIcon}
        onClick={handlePreviewOpen}
        onDelete={editable && !disabled ? onDelete : null}
        label={tooltipItem()}
        style={chipStyle}
      />
    </div>
  );
};

ChipWithRedirect.propTypes = {
  onDelete: PropTypes.func.isRequired,
  item: PropTypes.shape(),
  icon: PropTypes.shape(),
  maxChar: PropTypes.number,
  dotsPosition: PropTypes.string,
  editable: PropTypes.bool,
  disabled: PropTypes.bool,
};

ChipWithRedirect.defaultProps = {
  icon: <DescriptionOutlinedIcon />,
  maxChar: 25,
  dotsPosition: "middle",
  editable: true,
  disabled: false,
  item: null,
};

export { ChipWithRedirect };
