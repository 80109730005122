import { DIRECTORY_LABELS, ERRORS } from "constants/content";
import { isObjectEmpty } from "utils/object";

export function DirectoryValidator({ name, actions }) {
  const errorsList = {};
  if (!name.length) errorsList.name = ERRORS.isRequired(DIRECTORY_LABELS.title);
  const actionsErrors = actions.reduce((errorsObject, action) => {
    const errors = {};
    if (!action.name.length) {
      errors.name = ERRORS.isRequired(DIRECTORY_LABELS.buttonTitle);
    }
    if (!action.file_name) {
      errors.library_node_id = ERRORS.isRequired(DIRECTORY_LABELS.file);
    }
    return isObjectEmpty(errors) ? errorsObject : [...errorsObject, { ...errors, orderNumber: action.orderNumber }];
  }, []);
  if (actionsErrors.length) errorsList.actions = actionsErrors;
  return errorsList;
}
