import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import TableCell from "@material-ui/core/TableCell";

import styled from "styled-components";

import { AttachFilesButton } from "components/ui/Buttons/AttachFilesButton";
import { ChipWithRedirect } from "components/ui/Chips/ChipWithRedirect";
import { TableRow } from "components/ui/Tables";
import { IconImage } from "components/ui/Icons";

import { DOCUMENTS_FILE_TYPES, DEFAULT_CELL_MAX_CHARACTERS, IMAGES_MIME_TYPES } from "constants/defaults";
import { formatDisplayableLibraryPopupValues } from "utils/library";
import { TextLimiter } from "components/ui/TextLimiter";
import { useActiveIcon } from "hooks/useActiveIcon";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { LanguageContext } from "components/_shared/LanguageSelector/LanguageContext";
import { LocalisationService } from "services/LocalisationService";
import { PoiService } from "services/PoiService";
import { pushErrorNotification, pushSuccessNotification } from "store/app/actions";
import { setDefaultApiErrorMessage } from "utils";

const IconCenterer = styled.div`
  display: flex;
  align-items: center;
`;

const LocalisedTableCell = styled(TableCell)`
  && {
    ${({ $localised }) =>
      !$localised &&
      `
      opacity: 0.4;
    `}
  }
`;

const PoisTableRow = ({ item, onPoiUpdate }) => {
  const dispatch = useDispatch();
  const { currentLanguage } = useContext(LanguageContext);
  const permissionsService = usePermissionsService();

  const localisationService = new LocalisationService(currentLanguage);
  const poiService = new PoiService();

  const canEditPoi = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.operator, PERMISSIONS.resources.pois);

  const { icon, poiRange, latitude, longitude, country } = item;

  const isNameLocalised = localisationService.isPropertyLocalised(item, "name");
  const isDescriptionLocalised = localisationService.isPropertyLocalised(item, "description");

  const name = isNameLocalised ? localisationService.localise(item, "name") : item.name;
  const description = isDescriptionLocalised ? localisationService.localise(item, "description") : item.description;
  const file = localisationService.localise(item, "file");

  const allowedUploadFileTypes = [...IMAGES_MIME_TYPES, ...DOCUMENTS_FILE_TYPES];
  const handleUpdatePoi = async poiToUpdate => {
    try {
      await poiService.updatePoi(poiToUpdate);
      onPoiUpdate();
      dispatch(pushSuccessNotification("POI has been updated successfully"));
    } catch (error) {
      dispatch(pushErrorNotification(setDefaultApiErrorMessage(error)));
    }
  };
  const handleFileSelect = files => {
    const [newFile] = formatDisplayableLibraryPopupValues(files);

    handleUpdatePoi({
      ...item,
      file: currentLanguage.isDefault ? newFile : item.file,
      localisation: {
        ...item.localisation,
        [currentLanguage.code]: {
          ...item.localisation[currentLanguage.code],
          file: newFile,
        },
      },
    });
  };

  const handleFileDelete = () => {
    handleUpdatePoi({
      ...item,
      file: currentLanguage.isDefault ? null : item.file,
      localisation: {
        ...item.localisation,
        [currentLanguage.code]: {
          ...item.localisation[currentLanguage.code],
          file: null,
        },
      },
    });
  };

  const chipFileIcon = useActiveIcon(file);

  const renderFileUploader = () => (
    <AttachFilesButton
      name="pdf"
      onSelect={handleFileSelect}
      size="small"
      align="right"
      allowedFileTypes={allowedUploadFileTypes}
      disabled={!canEditPoi}
    />
  );
  const renderFileDeleter = () => (
    <ChipWithRedirect onDelete={handleFileDelete} item={file} icon={chipFileIcon} editable={canEditPoi} maxChar={15} />
  );

  const getCountryName = country => (country?.length > 20 ? country.substring(0, 20) + "..." : country);

  return (
    <TableRow item={item}>
      <TableCell>
        <IconCenterer>
          <IconImage src={icon?.url} />
        </IconCenterer>
      </TableCell>
      <LocalisedTableCell $localised={isNameLocalised}>{name}</LocalisedTableCell>
      <TableCell>{getCountryName(country)}</TableCell>
      <TableCell style={{ minWidth: 120 }}>{poiRange}</TableCell>
      <TableCell>{`${latitude}, ${longitude}`}</TableCell>
      <LocalisedTableCell $localised={isDescriptionLocalised}>
        <TextLimiter maxChar={DEFAULT_CELL_MAX_CHARACTERS} text={description} />
      </LocalisedTableCell>
      <TableCell>{!file ? renderFileUploader() : renderFileDeleter()}</TableCell>
    </TableRow>
  );
};

PoisTableRow.defaultProps = {
  onPoiUpdate: () => {},
};

PoisTableRow.propTypes = {
  item: PropTypes.shape().isRequired,
  onPoiUpdate: PropTypes.func,
};

export { PoisTableRow };
