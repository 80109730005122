import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { ERRORS } from "constants/content";

import { Input } from "components/ui/Forms";

import { ColorPickerPopup } from "./ColorPicker";
import { ColorPreview, PICKER_POSITION } from "./ColorPickerStyledComponents";

const ColorControl = styled(ColorPreview)`
  width: 28px;
  height: 28px;
`;

const ColorPickerWrapper = styled.div`
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "300px")};
  max-width: 100%;

  && {
    .MuiInputBase-root {
      ${({ disabled }) => (disabled ? "background-color: rgba(232,232,233,0.2);" : "")}
    }

    input:disabled {
      background-color: rgba(232, 232, 233, 0) !important;
    }
  }
`;

const ColorControlWrapper = styled.div`
  position: relative;
  input {
    position: absolute;
    border: none;
    visibility: hidden;
    left: 0;
    top: 0;
  }
`;

const isValidHex = color => {
  return /^#([A-F0-9]{6,8})$/.test(color);
};

const setPositionInViewport = el => {
  if (el) {
    const rect = el?.getBoundingClientRect();
    const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

    const outrangeRight = viewportWidth < rect.left + 60 + 300;
    const outrangeTop = rect.top - 140 < 0;
    const outrangeBottom = viewportHeight < rect.top + (410 - 140);

    if ((!outrangeRight && !outrangeTop && outrangeBottom) || (outrangeRight && !outrangeTop && outrangeBottom)) {
      return { position: PICKER_POSITION.top };
    }

    if (outrangeRight && !outrangeTop && !outrangeBottom) {
      return { position: PICKER_POSITION.left };
    }

    if ((outrangeRight && outrangeTop && !outrangeBottom) || (!outrangeRight && outrangeTop && !outrangeBottom)) {
      return { position: PICKER_POSITION.bottom };
    }

    return { position: PICKER_POSITION.right };
  }
};

const CustomColorPicker = ({ color, label, onColorChange, disabled, fullWidth, alphaChannel, isRequired }) => {
  const colorPreviewRef = useRef(null);
  const [colorPopupPosition, setColorPopupPosition] = useState(null);
  const [customColorOpen, setCustomColorOpen] = useState(false);
  const [colorError, setColorError] = useState({});

  const [inputColor, setInputColor] = useState(null);

  const onColorUpdate = ({ target }) => {
    const value = target.value.toUpperCase();
    if (value === "" || !value) {
      setInputColor("");
      setColorError({ error: ERRORS.isRequired(label) });
    } else {
      const newColor = value.startsWith("#") ? value : `#${value}`;
      const error = isValidHex(newColor) ? null : ERRORS.invalidHexColorFormat;
      setColorError({ error });
      setInputColor(newColor);

      if (!error) {
        onColorChange(newColor);
      }
    }
  };

  const handleColorPickerClick = () => {
    if (!disabled) {
      const position = setPositionInViewport(colorPreviewRef.current);
      setColorPopupPosition(position?.position);
      setCustomColorOpen(!customColorOpen);
    }
  };
  const handleColorChange = newColor => {
    onColorChange(newColor);
    setCustomColorOpen(false);
    setColorPopupPosition(null);
  };

  const handleColorCancel = () => {
    setCustomColorOpen(false);
    setColorPopupPosition(null);
  };

  useEffect(() => {
    if (color) {
      setInputColor(color);
    }
  }, [color]);

  const colorControl = (
    <ColorControlWrapper>
      <ColorControl color={colorError?.error ? color : inputColor} onClick={handleColorPickerClick} ref={colorPreviewRef} />
      {customColorOpen && colorPopupPosition && (
        <ColorPickerPopup
          title={label}
          inputColor={inputColor}
          onColorSelect={handleColorChange}
          onColorCancel={handleColorCancel}
          position={colorPopupPosition}
          alphaChannel={alphaChannel}
        />
      )}
    </ColorControlWrapper>
  );

  return (
    <ColorPickerWrapper fullWidth={fullWidth} disabled={disabled}>
      <Input
        name="color-picker"
        label={label}
        value={inputColor || ""}
        onChange={onColorUpdate}
        endAdornment={colorControl}
        error={colorError?.error}
        helperText={colorError?.error}
        disabled={disabled}
        isRequired={isRequired}
      />
    </ColorPickerWrapper>
  );
};

CustomColorPicker.defaultProps = {
  color: "",
  onColorChange: () => {},
  disabled: false,
  fullWidth: false,
  alphaChannel: false,
};

CustomColorPicker.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  alphaChannel: PropTypes.bool,
  onColorChange: PropTypes.func,
};

export { CustomColorPicker };
