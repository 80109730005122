import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { EditAction, SwitchAction } from "components/ui/Tables/TableRowActions";

import { operatorActiveStatusToggleStart } from "store/adminOperators/actions";

import { TABLE_ACTIONS_TITLES } from "constants/content";
import { ADMIN_OPERATOR_DETAILS_GENERAL_PATH } from "constants/routes";
import { setUrl } from "utils/url";

import { PERMISSIONS } from "constants/permissions";
import { usePermissionsService } from "hooks/usePermissionsService";

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SwitchWrapper = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colors.grey10};
  background-color: ${({ theme }) => theme.colors.withOpacity(theme.colors.grey10, 0.3)};
`;

const TableRowActions = ({ item }) => {
  const permissionsService = usePermissionsService();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id, isActive } = item;

  const handleClickEditAction = () => {
    navigate(setUrl(ADMIN_OPERATOR_DETAILS_GENERAL_PATH, { id }));
  };

  const toggleActiveStatus = (operatorId, operatorDetails) => {
    const target = { ...operatorDetails, isActive: !operatorDetails.isActive };

    dispatch(operatorActiveStatusToggleStart({ id: operatorId, target }));
  };

  const isToggleActiveStatusDisabled = !permissionsService.can(
    PERMISSIONS.actions.update,
    PERMISSIONS.sections.admin,
    PERMISSIONS.resources.operators,
    id || null,
  );

  const isReadDetailsOperatorDisabled = !permissionsService.can(
    PERMISSIONS.actions.read,
    PERMISSIONS.sections.admin,
    PERMISSIONS.resources.operators,
    id || null,
  );

  return (
    <Wrapper>
      <SwitchWrapper>
        <SwitchAction checked={isActive} clickAction={() => toggleActiveStatus(id, item)} disabled={isToggleActiveStatusDisabled} />
      </SwitchWrapper>
      <EditAction
        clickAction={handleClickEditAction}
        tooltip={TABLE_ACTIONS_TITLES.editResource(TABLE_ACTIONS_TITLES.resourceTypes.operator)}
        disabled={isReadDetailsOperatorDisabled}
      />
    </Wrapper>
  );
};

TableRowActions.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    isActive: PropTypes.bool,
  }).isRequired,
};

export { TableRowActions, Wrapper, SwitchWrapper };
