import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import AddIcon from "@material-ui/icons/Add";

import { SortableList } from "components/ui/Lists";
import { BodyText2 } from "components/ui/Typography/Typography";
import { ActionButton } from "components/ui/Buttons";

import { ADMIN_FEATURES_CONTENT, CREATE_CONTENT_LABELS } from "constants/content";

import { addNewFeatureItemStart, setOrderForFeatureItem } from "store/adminFeatures/actions";

import { FeatureListItem } from "./FeatureListItem";

const DocumentsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.setSpacing(5)}px;
`;

const FeaturesList = ({ features, featureGroupId, errors, disabled }) => {
  const dispatch = useDispatch();
  const handleChangeOrder = event => {
    const { newIndex, oldIndex } = event;
    dispatch(setOrderForFeatureItem({ newIndex, oldIndex, featureGroupId }));
  };
  const handleAdd = () => {
    dispatch(addNewFeatureItemStart(featureGroupId));
  };

  return (
    <div>
      <DocumentsHeader>
        <BodyText2>{ADMIN_FEATURES_CONTENT.featuresSectionTitle}</BodyText2>
        {!disabled && (
          <ActionButton onClick={handleAdd} size="small">
            <AddIcon />
            {CREATE_CONTENT_LABELS.add}
          </ActionButton>
        )}
      </DocumentsHeader>

      <SortableList lockAxis="y" onSortEnd={handleChangeOrder} lockToContainerEdges useDragHandle>
        {features.map((feature, index) => (
          <FeatureListItem
            key={`feature-item-${feature.id}`}
            feature={feature}
            index={index}
            errors={errors[feature.id]}
            disabled={disabled}
          />
        ))}
      </SortableList>
    </div>
  );
};

FeaturesList.defaultProps = {
  errors: {},
  featureGroupId: null,
  disabled: false,
};

FeaturesList.propTypes = {
  features: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.number,
      isActive: PropTypes.bool,
    }),
  ).isRequired,
  featureGroupId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  errors: PropTypes.shape({}),
  disabled: PropTypes.bool,
};

export { FeaturesList };
