import { inputs } from "config/theme/forms";
import { callFn } from "utils";

const setInputHeight = variant => (inputs[variant] ? inputs[variant].height : inputs.medium.height);

const setInputInputHeight = variant => (inputs[variant] ? inputs[variant].inputHeight : inputs.medium.inputHeight);

const setInputPadding = variant => {
  const returnedValue = (top, right, bottom, left) => `${top}px ${right}px ${bottom}px ${left}px`;
  if (inputs[variant]) {
    const { top, right, left, bottom } = inputs[variant].padding;
    return returnedValue(top, right, bottom, left);
  }
  const { top, right, left, bottom } = inputs.medium.padding;
  return returnedValue(top, right, bottom, left);
};

const setInputLabelTransform = (variant = "medium", stage) => {
  const defaultValue = "0%, 0%";
  const calcYtranslation = size => (inputs[size] ? (inputs[size].height - inputs[size].fontSize) / 2 : 0);
  if (inputs[variant]) {
    if (stage === "before") return `${inputs[variant].padding.left}px, ${parseFloat(calcYtranslation(variant))}px`;
    if (stage === "after") return `${inputs[variant].padding.left}px, -6px`;
    return defaultValue;
  }
  return defaultValue;
};

const setInputFontSize = variant => (inputs[variant] ? inputs[variant].fontSize : inputs.medium.fontSize);

const setInputAttr = callFn({
  padding: setInputPadding,
  height: setInputHeight,
  "input-height": setInputInputHeight,
  "font-size": setInputFontSize,
  "transform-label": setInputLabelTransform,
});

export { setInputHeight, setInputInputHeight, setInputLabelTransform, setInputPadding, setInputAttr, setInputFontSize };
