import { ERRORS, CUSTOM_APPS } from "constants/content";

import { isObjectEmpty } from "utils/object";
import { webUrlValidation } from "utils/validation";
import { ERROR_NAMES_LABELS } from "./constants/customAppStatus";

const settingsValidator = (settings, type) => {
  const result = {};

  if (typeof settings.uppercasedMenuNames !== "boolean") {
    result.uppercasedMenuNames = ERROR_NAMES_LABELS.shouldBeBoolean;
  }

  if (!["light", "dark", "default"].includes(settings.loginStatusbarStyle)) {
    result.loginStatusbarStyle = ERROR_NAMES_LABELS.loginStatusBarError;
  }

  if (!["none", "tb", "lr", "tblr"].includes(settings.loginBorders)) {
    result.loginBorders = ERROR_NAMES_LABELS.loginBorders;
  }

  if (type === "stay") {
    if (!settings?.defaultUserId || settings?.defaultUserId === "") {
      result.defaultUserId = ERRORS.isRequired(ERROR_NAMES_LABELS.propertyUserId);
    }
  }

  return result;
};

// disable no-useless-escape
const colorsValidator = colors => {
  const result = {};
  if (!/^#[A-F0-9]{6,8}$/.test(colors.accentColor)) {
    result.accentColor = ERROR_NAMES_LABELS.uppercaseRequired;
  }
  if (!/^#[A-F0-9]{6,8}$/.test(colors.defaultBackground)) {
    result.defaultBackground = ERROR_NAMES_LABELS.uppercaseRequired;
  }
  if (!/^#[A-F0-9]{6,8}$/.test(colors.loginBackground)) {
    result.loginBackground = ERROR_NAMES_LABELS.uppercaseRequired;
  }
  if (!/^#[A-F0-9]{6,8}$/.test(colors.loginBorderColor)) {
    result.loginBorderColor = ERROR_NAMES_LABELS.uppercaseRequired;
  }
  if (!/^#[A-F0-9]{6,8}$/.test(colors.loginTextColor)) {
    result.loginTextColor = ERROR_NAMES_LABELS.uppercaseRequired;
  }
  if (!/^#[A-F0-9]{6,8}$/.test(colors.loginNavigationColor)) {
    result.loginNavigationColor = ERROR_NAMES_LABELS.uppercaseRequired;
  }
  if (!/^#[A-F0-9]{6,8}$/.test(colors.inputFieldBackgroundColor)) {
    result.inputFieldBackgroundColor = ERROR_NAMES_LABELS.uppercaseRequired;
  }
  if (!/^#[A-F0-9]{6,8}$/.test(colors.inputFieldFrameColor)) {
    result.inputFieldFrameColor = ERROR_NAMES_LABELS.uppercaseRequired;
  }
  if (!/^#[A-F0-9]{6,8}$/.test(colors.inputHintColor)) {
    result.inputHintColor = ERROR_NAMES_LABELS.uppercaseRequired;
  }
  if (!/^#[A-F0-9]{6,8}$/.test(colors.buttonBackgroundColorActive)) {
    result.buttonBackgroundColorActive = ERROR_NAMES_LABELS.uppercaseRequired;
  }
  if (!/^#[A-F0-9]{6,8}$/.test(colors.buttonTextColorActive)) {
    result.buttonTextColorActive = ERROR_NAMES_LABELS.uppercaseRequired;
  }
  if (!/^#[A-F0-9]{6,8}$/.test(colors.buttonTextColorInactive)) {
    result.buttonTextColorInactive = ERROR_NAMES_LABELS.uppercaseRequired;
  }
  if (!/^#[A-F0-9]{6,8}$/.test(colors.buttonBackgroundColorInactive)) {
    result.buttonBackgroundColorInactive = ERROR_NAMES_LABELS.uppercaseRequired;
  }
  if (!/^#[A-F0-9]{6,8}$/.test(colors.menuBackgroundColour)) {
    result.menuBackgroundColour = ERROR_NAMES_LABELS.uppercaseRequired;
  }
  if (!/^#[A-F0-9]{6,8}$/.test(colors.menuTextColour)) {
    result.menuTextColour = ERROR_NAMES_LABELS.uppercaseRequired;
  }

  if (!/^#[A-F0-9]{6,8}$/.test(colors.menuItineraryBackgroundColour)) {
    result.menuItineraryBackgroundColour = ERROR_NAMES_LABELS.uppercaseRequired;
  }
  if (!/^#[A-F0-9]{6,8}$/.test(colors.menuDividerColour)) {
    result.menuDividerColour = ERROR_NAMES_LABELS.uppercaseRequired;
  }

  return result;
};

const fontsValidator = fonts => {
  const result = {};

  if (fonts?.customFont && !webUrlValidation(fonts.customFont)) {
    result.customFont = ERRORS.invalidURL;
  }

  return result;
};

const validateBaseImages = images => {
  const result = {};
  const iconFileUrl = images?.icon?.fileUrl || images?.icon?.fileUrl;

  if (iconFileUrl && !webUrlValidation(iconFileUrl)) {
    result.icon = ERRORS.invalidURL;
  }
  const logoFileUrl = images?.logo?.fileUrl || images?.logo?.fileUrl;
  if (logoFileUrl && !webUrlValidation(logoFileUrl)) {
    result.logo = ERRORS.invalidURL;
  }

  if (!images.iconTransparent) {
    result.iconTransparent = ERRORS.isRequired("App transparent icon");
  }

  if (!images.logo) {
    result.logo = ERRORS.isRequired("App logo");
  }

  return result;
};

const stringsValidator = (strings, type, language) => {
  const result = {};

  if (type !== "trip") return result;

  const stringsToCheck = ["loginText1", "loginText2", "loginText3", "loginText4", "loginUserIdHint", "loginNextButton"];

  for (let i = 0; i < stringsToCheck.length; i++) {
    const item = stringsToCheck[i];
    if (item === "loginUserIdHint" || item === "loginNextButton") {
      if (!strings?.[item]?.languages?.[language]?.text?.length) {
        result[item] = ERRORS.isRequired(CUSTOM_APPS.loginPage.placeholderText);
      }
    } else {
      if (!strings?.[item]?.colour?.length) {
        result[stringsToCheck[i]] = {
          // ...(result[item][language] || {}),
          colour: !/^#[A-F0-9]{6}$/.test(strings?.[item]?.[language]?.colour) ? ERRORS.invalidHexColorFormat : null,
        };
      }
    }
  }

  // if (!strings?.loginText1?.colour?.length) {
  //   result.loginText1 = {
  //     ...result.loginText1,
  //     colour: !/^#[A-F0-9]{6}$/.test(strings?.loginText1?.colour) ? ERRORS.invalidHexColorFormat : null,
  //   };
  // }

  // if (!strings?.loginText2?.colour?.length) {
  //   result.loginText2 = {
  //     ...result.loginText2,
  //     colour: !/^#[A-F0-9]{6}$/.test(strings?.loginText2?.colour) ? ERRORS.invalidHexColorFormat : null,
  //   };
  // }

  // if (!strings?.loginText3?.colour?.length) {
  //   result.loginText3 = {
  //     ...result.loginText3,
  //     colour: !/^#[A-F0-9]{6}$/.test(strings?.loginText3?.colour) ? ERRORS.invalidHexColorFormat : null,
  //   };
  // }

  // if (!strings?.loginText4?.colour?.length) {
  //   result.loginText4 = {
  //     ...result.loginText4,
  //     colour: !/^#[A-F0-9]{6}$/.test(strings?.loginText4?.colour) ? ERRORS.invalidHexColorFormat : null,
  //   };
  // }

  // if (!strings?.loginUserIdHint?.length) {
  //   result.loginUserIdHint = ERRORS.isRequired(CUSTOM_APPS.loginPage.placeholderText);
  // }
  // if (!strings?.loginNextButton?.length) {
  //   result.loginNextButton = ERRORS.isRequired(CUSTOM_APPS.loginPage.actionButtonText);
  // }

  return result;
};

const customAppBaseValidator = (customApp, editing, saving) => {
  const result = {};
  const info = customApp?.info || {};
  const shouldValidateInfo = !editing && !saving;

  const isConditionError = (field, cond, message) => {
    const languages = field.languages;
    const errors = {};
    const missedLanguages = [];

    for (const key in languages) {
      if (cond(languages?.[key])) {
        errors[key] = message;
        missedLanguages.push(key);
      }
    }

    return Object.keys(errors).length ? { errors, missedLanguages } : {};
  };

  const isRequiredError = field => {
    const availableLanguages = customApp.languages || [customApp.default_language || "en"];

    const errors = {};
    const missedLanguages = [];
    const message = "This field is required";
    availableLanguages.forEach(lang => {
      if (!field.languages?.[lang]) {
        errors[lang] = message;
        missedLanguages.push(lang);
      }
    });

    return Object.keys(errors).length ? { errors, missedLanguages } : {};
  };
  const nameOverrideRequiredError = shouldValidateInfo && isRequiredError(info.name_override);
  const nameOverrideMaxLength =
    shouldValidateInfo && isConditionError(info.name_override, value => value.length > 30, "Max length 30 characters");
  const nameOverrideMinLength =
    shouldValidateInfo && isConditionError(info.name_override, value => value.length < 2, "Min length 2 characters");

  const shortDescriptionRequiredError = shouldValidateInfo && isRequiredError(info?.ios?.short_description);
  const fullDescriptionRequiredError = shouldValidateInfo && isRequiredError(info?.ios?.full_description);
  const fullDescriptionLittleLength =
    shouldValidateInfo && isConditionError(info?.ios?.full_description, value => value.length < 10, "Min length 10 characters");
  const fullDescriptionMaxLength =
    shouldValidateInfo && isConditionError(info?.ios?.full_description, value => value.length > 4000, "Max length 4000 characters");
  const shortDescriptionMaxLength =
    shouldValidateInfo && isConditionError(info?.ios?.short_description, value => value.length > 80, "Max length 80 characters");

  if (shouldValidateInfo && !info?.android?.feature_image) {
    result.information = [...(result.information || []), { field: "android.feature_image", message: "This field is required" }];
  }

  if (shouldValidateInfo && !info.documents.ios_assignment_letter) {
    result.information = [...(result.information || []), { field: "assignment_letter", message: "This field is required" }];
  }

  if (shouldValidateInfo && !info?.screenshots?.operator_code) {
    result.information = [...(result.information || []), { field: "screenshots.operator_code" }];
  }

  if (shouldValidateInfo && !info?.screenshots?.passcode) {
    result.information = [...(result.information || []), { field: "screenshots.passcode" }];
  }

  if (nameOverrideMaxLength.errors) {
    result.information = [
      ...(result.information || []),
      {
        field: "name_override",
        message: nameOverrideMaxLength.errors,
        missedLanguages: nameOverrideMaxLength.missedLanguages,
      },
    ];
  }

  if (nameOverrideMinLength.errors) {
    result.information = [
      ...(result.information || []),
      {
        field: "name_override",
        message: nameOverrideMinLength.errors,
        missedLanguages: nameOverrideMinLength.missedLanguages,
      },
    ];
  }

  if (nameOverrideRequiredError.errors) {
    result.information = [
      ...(result.information || []),
      {
        field: "name_override",
        message: nameOverrideRequiredError.errors,
        missedLanguages: nameOverrideRequiredError.missedLanguages,
      },
    ];
  }

  if (shortDescriptionMaxLength.errors) {
    result.information = [
      ...(result.information || []),
      {
        field: "short_description",
        message: shortDescriptionMaxLength.errors,
        missedLanguages: shortDescriptionMaxLength.missedLanguages,
      },
    ];
  }

  if (shortDescriptionRequiredError.errors) {
    result.information = [
      ...(result.information || []),
      {
        field: "short_description",
        message: shortDescriptionRequiredError.errors || `This field is required, check all languages`,
        missedLanguages: shortDescriptionRequiredError.missedLanguages,
      },
    ];
  }

  if (fullDescriptionLittleLength.errors) {
    result.information = [
      ...(result.information || []),
      {
        field: "full_description",
        message: fullDescriptionLittleLength.errors,
        missedLanguages: fullDescriptionLittleLength.missedLanguages,
      },
    ];
  }

  if (fullDescriptionMaxLength.errors) {
    result.information = [
      ...(result.information || []),
      {
        field: "full_description",
        message: fullDescriptionMaxLength.errors,
        missedLanguages: fullDescriptionMaxLength.missedLanguages,
      },
    ];
  }

  if (fullDescriptionRequiredError.errors) {
    result.information = [
      ...(result.information || []),
      {
        field: "full_description",
        message: fullDescriptionRequiredError.errors || "This field is required, check all languages",
        missedLanguages: fullDescriptionRequiredError.missedLanguages,
      },
    ];
  }

  if (shouldValidateInfo && !info.ios.developer_account_name) {
    result.information = [
      ...(result.information || []),
      {
        field: "ios.developer_account_name",
        message: "This field is required",
      },
    ];
  }

  if (!customApp?.name || customApp?.name === "") {
    result.name = ERRORS.isRequired(CUSTOM_APPS.tableHeaders.title);
  }
  if (customApp?.name.length > 30) {
    result.name = ERRORS.isTooLong(30);
  }

  if (customApp?.name.length < 2) {
    result.name = ERRORS.isTooShort(2);
  }

  if (!customApp?.bundleId || customApp?.bundleId === "") {
    result.bundleId = ERRORS.isRequired(CUSTOM_APPS.tableHeaders.title);
  } else {
    const bundleAppName = `${customApp?.bundleId}`.replace("com.vamoos.apps.", "");

    if (bundleAppName === "") {
      result.bundleId = ERRORS.isRequired(CUSTOM_APPS.tableHeaders.title);
    }
  }
  if (!customApp?.type || customApp?.type === "") {
    result.type = ERRORS.isRequired(CUSTOM_APPS.tableHeaders.type);
  }
  if (customApp?.type === "stay" && !customApp?.settings?.defaultUserId) {
    result.settings = {
      defaultUserId: ERRORS.isRequired(CUSTOM_APPS.general.userIdProp),
    };
  }
  return result;
};

const customAppValidator = (customApp, editing) => {
  const errors = customAppBaseValidator(customApp, editing);
  const result = { ...errors };

  const imagesErrors = validateBaseImages(customApp.images);

  if (!editing && imagesErrors && !isObjectEmpty(imagesErrors)) {
    result.images = {
      ...result.images,
      ...imagesErrors,
    };
  }

  const colorsErrors = colorsValidator(customApp.colors);
  if (colorsErrors && !isObjectEmpty(colorsErrors)) {
    result.colors = colorsErrors;
  }
  const stringsErrors = stringsValidator(customApp.strings, customApp?.type, customApp?.default_language);
  if (stringsErrors && !isObjectEmpty(stringsErrors)) {
    result.strings = stringsErrors;
  }

  const settingsErrors = settingsValidator(customApp.settings, customApp?.type);
  if (settingsErrors && !isObjectEmpty(settingsErrors)) {
    result.settings = settingsErrors;
  }

  const fontsErrors = fontsValidator(customApp.fonts);
  if (fontsErrors && !isObjectEmpty(fontsErrors)) {
    result.fonts = fontsErrors;
  }

  if (result.bundleId === null) {
    delete result.bundleId;
  }
  return result;
};

export { customAppValidator, customAppBaseValidator };
