import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { MessageBubble } from "./MessageBubble";

const MessagesScrollWindow = styled.div`
  min-width: ${({ theme }) => theme.setSpacing(190)}px;
  overflow-x: hidden;
  overflow-y: auto;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - ${({ theme }) => theme.setSpacing(106)}px);
  height: auto;
  justify-content: flex-end;
`;

const DynamicHeightInnerContainer = styled(InnerContainer)`
  height: ${props => props.dynamicHeight};
`;

const MessagesWindow = ({ messages }) => {
  const [initialScrollDone, setInitialScrollDone] = useState(false);
  const bottomElementRef = useRef();

  useEffect(() => {
    if (bottomElementRef.current && !initialScrollDone) {
      bottomElementRef.current.scrollIntoView({ behavior: "smooth" });
      setInitialScrollDone(true);
    }
  }, [initialScrollDone]);

  return (
    <MessagesScrollWindow>
      <DynamicHeightInnerContainer>
        {messages.map(({ id, type, label, content }) => {
          const isPrimary = type === "primary";
          return <MessageBubble key={id} primary={isPrimary} label={label} text={content} />;
        })}
        <div ref={bottomElementRef} />
      </DynamicHeightInnerContainer>
    </MessagesScrollWindow>
  );
};

MessagesWindow.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export { MessagesWindow, DynamicHeightInnerContainer };
