import React from "react";
import { useOutletContext } from "react-router-dom";
import { NotificationsTemplate } from "feature/panel/_shared/Notifications/NotificationsTemplate";
import { ManageTripContext, useManageTripContext } from "feature/panel/Trips/_shared/ManageTripContext";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

const Notifications = () => {
  const { vamoos_id, is_wiped, editMode } = useManageTripContext();
  const permissionsService = usePermissionsService();

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoos_id);

  return <NotificationsTemplate disabled={is_wiped || (editMode && !canEdit)} context={ManageTripContext} />;
};

export { Notifications };
