import React, { useState, useEffect, useContext } from "react";
import AddIcon from "@material-ui/icons/Add";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { useNavigate, useParams } from "react-router-dom";

import { StickyHeaderFormSection } from "components/ui/Forms";
import { SecondaryButton } from "components/ui/Buttons";
import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";

import { LoadingArea } from "feature/panel/_shared/CreationsAndPublishers/styledComponents";

import { PublishersList } from "feature/panel/Stays/_shared/Publisher/PublishersList";

import { setUrl } from "utils/url";
import { GLOBAL_CONTENT, PUBLISHER_CONTENT } from "constants/content";
import { CREATRIONS_URL } from "constants/api";
import { openPublisherModal } from "store/stays/actions";
import { PublisherService } from "services/PublisherService";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { PANEL_STAYS_EDIT_PATH } from "constants/routes";
import { LanguageContext } from "components/_shared/LanguageSelector/LanguageContext";

const Publisher = () => {
  const permissionsService = usePermissionsService();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { operator_code } = useParams();

  const [apiUrl, setApiUrl] = useState("");

  const { currentLanguage } = useContext(LanguageContext);

  const isWiped = useSelector(({ stayWizard }) => stayWizard.form.isWiped);
  const vamoosId = useSelector(({ stayWizard }) => stayWizard.form.vamoosId);
  const editMode = useSelector(({ stayWizard }) => stayWizard.form.vamoosId !== null);
  const userId = useSelector(({ stayWizard }) => stayWizard.form.userId);

  const canEditVamoos = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoosId);

  const isStayDefaultLanguage = useSelector(({ stayWizard }) =>
    stayWizard.defaultVariant.language ? stayWizard.defaultVariant.language === currentLanguage.code : true,
  );

  const handleToggleModal = payload => {
    const isEditModeToggled = payload && payload.id;
    if (isEditModeToggled) {
      const publisherPayload = PublisherService.setupExitingPublisher(payload);
      dispatch(openPublisherModal(publisherPayload));
    } else {
      dispatch(openPublisherModal(null));
    }
  };

  const AddDocumentButton = () =>
    !isWiped &&
    (editMode ? canEditVamoos : true) && (
      <SecondaryButton onClick={handleToggleModal}>
        <AddIcon />
        {GLOBAL_CONTENT.new}
      </SecondaryButton>
    );

  const prepareUrl = () => {
    if (operator_code) {
      const url = setUrl(CREATRIONS_URL, { operator_code, reference_code: "DEFAULT" }, true);
      setApiUrl(url);
    }
  };

  useEffect(prepareUrl, [operator_code]);

  useEffect(() => {
    if (!isStayDefaultLanguage) {
      const url = setUrl(PANEL_STAYS_EDIT_PATH, { operator_code: userId }, true);
      navigate(url);
    }
  }, [isStayDefaultLanguage]);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={10}>
        <StickyHeaderFormSection title={PUBLISHER_CONTENT.listOfPublishers} headerActions={AddDocumentButton}>
          {apiUrl ? (
            <PublishersList onEditStart={handleToggleModal} url={apiUrl} />
          ) : (
            <LoadingArea>
              <LoadingScreen />
            </LoadingArea>
          )}
        </StickyHeaderFormSection>
      </Grid>
    </Grid>
  );
};

export { Publisher };
