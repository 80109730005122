import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";

import { useItemOriginalIndex } from "hooks/useItemOriginalIndex";
import { FlexGrow } from "components/ui/Content";
import { Input } from "components/ui/Forms";
import { AttachFilesButton } from "components/ui/Buttons/AttachFilesButton";
import { ChipWithRedirect } from "components/ui/Chips/ChipWithRedirect";

import { SortableListElement, RemoveButton } from "components/_shared/SortableListElement/SortableListElement";
import {
  SortableListElementContentWithError
} from "components/_shared/SortableListElementContentWithError/SortableListElementContentWithError";

import { DOCUMENTS_FILE_TYPES } from "constants/defaults";
import {
  ERRORS,
  INDIVIDUAL_SETTINGS,
  TABLE_ACTIONS_TITLES,
} from "constants/content";
import { useActiveIcon } from "hooks/useActiveIcon";
import { TravellersRestrictionAction } from "../../../../../components/ui/Tables/TableRowActions";
import { getRestrictionInfo } from "../../../../../helpers";
import { useManageTripContext } from "../ManageTripContext";
import {
  TravellersRestrictionModal
} from "../../../../../components/_shared/TravellersRestrictionModal/TravellersRestrictionModal";
import { ConfirmationModal } from "../../../../../components/ui/Modals/ConfirmationModal";
import { Message } from "../../../../../components/ui/Messages";

const Number = styled.span`
  color: ${({ theme }) => theme.colors.grey50};
`;

const DocumentsListItem = ({ index, item, name, onRemove, onChange, onFilesUpload, documentsErrors, disabled }) => {
  const { currentOperator } = useSelector(state => state.operator);

  const documentRef = useRef(null);
  const { travelPeople, errors, destinationDocuments, travelDocuments } = useManageTripContext();

  const [travellerRestrictionModal, setTravellerRestrictionModal] = useState({ open: false, body: {} });
  const [warningModal, setWarningModal] = useState(null);

  const { name: title, id, file_name, restricted_to_traveller_internal_ids } = item;

  const originalIndex = useItemOriginalIndex(id, name, documentsErrors);
  const nameError = documentsErrors && documentsErrors[originalIndex] ? documentsErrors[originalIndex].name : null;
  const fileError = documentsErrors && documentsErrors[originalIndex] ? documentsErrors[originalIndex].file : null;
  const hasError = documentsErrors && documentsErrors[originalIndex];
  const itemIndex = index + 1;
  const activeIcon = useActiveIcon(item);

  const handleChange = ({ target }) => {
    const { value } = target;
    onChange(id, { ...item, name: value });
  };

  const handleRemoveAttachedFile = () => {
    onChange(id, { id, restricted_to_traveller_internal_ids, name: title });
  };

  const renderFileUploader = () => (
    <AttachFilesButton
      size="small"
      name={name}
      onSelect={files => onFilesUpload(id, files)}
      allowedFileTypes={DOCUMENTS_FILE_TYPES}
      error={fileError}
      multiUpload
      disabled={disabled}
    />
  );

  const renderFileDeleter = () => (
    <ChipWithRedirect disabled={disabled} onDelete={handleRemoveAttachedFile} item={item} icon={activeIcon} />
  );

  const handleTravellersRestrictionToggle = doc => {
    if (errors?.travellers?.validationErrors) {
      setWarningModal("applyWarning");
      return;
    }
    const {
      hasRestrictions,
      mergeTravelPeople
    } = getRestrictionInfo(travelPeople, errors, item, [...destinationDocuments, ...travelDocuments]);
    const showWarningModal = mergeTravelPeople?.length < 2 && !hasRestrictions;
    if (showWarningModal) {
      setWarningModal("enableWarning");
      return;
    }

    const { open } = travellerRestrictionModal;
    const body = open ? travellerRestrictionModal.body : doc;
    setTravellerRestrictionModal({ body, open: !open });
  };

  const renderTravellersRestrictionAction = () => {
    const { personalisedTravellerIds } = getRestrictionInfo(travelPeople, errors, item, [...destinationDocuments, ...travelDocuments])

    return (
      (currentOperator?.meta?.show_personalisation !== false) ? (
        <TravellersRestrictionAction
          clickAction={event => {
            event.stopPropagation();
            handleTravellersRestrictionToggle(item);
          }}
          tooltip={
            restricted_to_traveller_internal_ids === null ? TABLE_ACTIONS_TITLES.addRestrictions : TABLE_ACTIONS_TITLES.editRestrictions
          }
          numberOfTravelers={personalisedTravellerIds?.length ?? null}
          noBackground
          noBorder
        />
      ) : null
    )
  }

  useEffect(() => {
    if (!documentRef.current.value) {
      documentRef.current.focus();
      documentRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <SortableListElement disabled={disabled} index={index} withError={!!hasError}>
      <SortableListElementContentWithError error={hasError ? ERRORS.invalidDocumentFile : undefined}>
        <FlexGrow grow={1}>
          <Input
            label="Title"
            value={title}
            onChange={handleChange}
            name={`${name}-document-nr${itemIndex}`}
            inputRef={documentRef}
            size="small"
            error={!!nameError}
            helperText={nameError}
            disabled={disabled}
          />
        </FlexGrow>
        {file_name ? renderFileDeleter() : renderFileUploader()}
        {renderTravellersRestrictionAction()}
        <TravellersRestrictionModal
          open={travellerRestrictionModal.open}
          data={travellerRestrictionModal.body}
          onClose={handleTravellersRestrictionToggle}
          onToggle={updatedData =>
            setTravellerRestrictionModal(prevState => ({
              open: prevState.open,
              body: updatedData,
            }))
          }
          name={name}
        />
        <ConfirmationModal
          open={warningModal}
          title={INDIVIDUAL_SETTINGS.title}
          description={
            <>
              <Message text={warningModal === "applyWarning" ? INDIVIDUAL_SETTINGS.applyWarningDesc : INDIVIDUAL_SETTINGS.enableWarningDesc} type="info" />
            </>
          }
          confirmLabel="OK"
          onConfirm={() => setWarningModal(null)}
        />
        {!disabled && <RemoveButton onClick={() => onRemove(id)} />}
      </SortableListElementContentWithError>
    </SortableListElement>
  );
};

DocumentsListItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    restricted_to_traveller_internal_ids: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.oneOf(null)]),
    name: PropTypes.string,
    file_name: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  onFilesUpload: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  documentsErrors: PropTypes.shape().isRequired,
};

export { DocumentsListItem, Number };
