import { types } from "contracts/types";

export const toCamelCase = (first, ...next) => {
  const firstEl = types(first, "string");
  let result = firstEl;
  if (next) {
    next.forEach(el => {
      const validEl = types(el, "string");
      result = result + validEl.charAt(0).toUpperCase() + validEl.slice(1);
    });
  }
  return result;
};

export const firstLetterUpperCase = string => `${string?.charAt(0).toUpperCase()}${string?.slice(1)}`;

export function splitCamelCaseToString(string) {
  return string.split(/(?=[A-Z])/)
    .map((p) => p.charAt(0).toUpperCase() + p.slice(1))
    .join(" ");
}

export const shorten = (text, length) => {
  if(text.length <= length) {
    return text;
  }
  return `${text.substr(0, length)}...`
}