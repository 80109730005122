import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { set } from "lodash";

import { Grid } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import { SaveAdminButton } from "feature/admin/_shared/SaveAdminButton";
import { AdminTemplate } from "components/templates/Admin/AdminTemplate";
import { ContextBarTitle } from "components/templates/Panel/ContextBarTitle";
import { BackHistoryButton, CancelHistoryButton, SecondaryButton } from "components/ui/Buttons";
import { StickyHeaderFormSection } from "components/ui/Forms";
import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";

import { GET_FEATURES_STAYS_START, SAVE_FEATURE_STAYS_START } from "store/adminFeatures/actionTypes";
import { tryRouteChangeStart } from "store/app/actions";
import {
  addNewStayFeatureStart,
  getFeaturesStaysStart,
  saveStayFeatureStart,
  setStaysFeaturesFormTouched,
} from "store/adminFeatures/actions";

import { ADMIN_FEATURES_CONTENT, CREATE_CONTENT_LABELS, ERRORS, SITE_LEAVE_WARNING_MESSAGES } from "constants/content";
import { ADMIN_FEATURES_PATH } from "constants/routes";
import { isObjectEmpty } from "utils/object";

import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { usePrompt } from "components/ui/CustomPrompt/CustomPrompt";
import { useExtendedNavigate } from "hooks/useExtendedNavigate";

import { FeaturesCategoriesList } from "./FeaturesCategoriesList";

const HeaderWrapper = styled.div`
  margin: 40px 0 0 0;
`;

const validate = stayFeatures => {
  const errorsList = {};

  Object.values(stayFeatures).forEach(({ name, features, id }) => {
    if (!name || name === "") {
      errorsList[id] = { name: ERRORS.isRequired(ADMIN_FEATURES_CONTENT.name) };
    }

    if (!features || Object.keys(features).length === 0) {
      set(errorsList, `${id}.featuresRequired`, ERRORS.isRequired(ADMIN_FEATURES_CONTENT.features, true));
    }

    if (Object.keys(features).length) {
      Object.keys(features).forEach(key => {
        if (!features[key]?.name || features[key]?.name === "") {
          set(errorsList, `${id}.features[${features[key].id}].name`, ERRORS.isRequired(ADMIN_FEATURES_CONTENT.name));
        }
        if (!features[key]?.icon) {
          set(errorsList, `${id}.features[${features[key].id}].image`, ERRORS.isRequired(ADMIN_FEATURES_CONTENT.image));
        }
      });
    }
  });

  return errorsList;
};

const EditFeature = () => {
  const permissionsService = usePermissionsService();
  const navigate = useExtendedNavigate();
  const features = useSelector(state => state.adminFeatureStays.features);
  const isFeaturesFormTouched = useSelector(state => state.adminFeatureStays.isFormTouched);
  const inProgress = useSelector(state => state.adminFeatureStays.inProgress);
  const actionType = useSelector(state => state.adminFeatureStays.actionType);
  const showSaveButtonSpinner = inProgress && actionType === SAVE_FEATURE_STAYS_START;
  const showSpinner = inProgress && actionType === GET_FEATURES_STAYS_START;
  const dispatch = useDispatch();

  usePrompt(SITE_LEAVE_WARNING_MESSAGES.createTitle(SITE_LEAVE_WARNING_MESSAGES.contexts.vamoosEditor), isFeaturesFormTouched);

  const [errorsList, setErrorsList] = useState({});

  const handleOnSave = () => {
    const featuresToUpdate = Object.values(features).filter(feature => feature.isTouched);
    const errors = validate(featuresToUpdate);

    if (isObjectEmpty(errors)) {
      setErrorsList({});
      dispatch(saveStayFeatureStart(featuresToUpdate));
    } else {
      setErrorsList(errors);
    }
  };
  const handleAdd = () => {
    dispatch(addNewStayFeatureStart());
  };

  const getFetaures = () => {
    dispatch(getFeaturesStaysStart());

    return () => {
      dispatch(setStaysFeaturesFormTouched(false));
    };
  };
  const handleCancelButtonAction = () => {
    dispatch(tryRouteChangeStart(ADMIN_FEATURES_PATH));
    navigate(ADMIN_FEATURES_PATH);
  };

  const isCreatePermitted = permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.admin, PERMISSIONS.resources.features);

  const isEditPermitted = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.admin, PERMISSIONS.resources.features);

  const contextBar = {
    left: () =>
      isFeaturesFormTouched ? (
        <CancelHistoryButton role="link" clickHandler={handleCancelButtonAction} />
      ) : (
        <BackHistoryButton role="link" clickHandler={handleCancelButtonAction} />
      ),
    middle: () => <ContextBarTitle title={ADMIN_FEATURES_CONTENT.editFeatureList} />,
    right: () => <SaveAdminButton onClick={() => handleOnSave()} showSpinner={showSaveButtonSpinner} disabled={!isFeaturesFormTouched} />,
  };

  const content = showSpinner ? (
    <LoadingScreen />
  ) : (
    <FeaturesCategoriesList errors={errorsList} isEditPermitted={isEditPermitted} isCreatePermitted={isCreatePermitted} />
  );

  const AddFeatureButton = () =>
    isCreatePermitted ? (
      <SecondaryButton onClick={handleAdd}>
        <AddIcon />
        {CREATE_CONTENT_LABELS.add}
      </SecondaryButton>
    ) : null;

  const stickyContainer = ({ children }) => <Grid md={10}>{children}</Grid>;

  useEffect(getFetaures, []);

  return (
    <AdminTemplate contextBar={contextBar} whiteBg>
      <Grid container justifyContent="center">
        <Grid item sm={10}>
          <Grid container justifyContent="center">
            <Grid item md={10}>
              <HeaderWrapper>
                <StickyHeaderFormSection
                  title={ADMIN_FEATURES_CONTENT.listOfFeaturesCategories}
                  headerActions={AddFeatureButton}
                  stickyVariant="expanded"
                  stickyContainer={stickyContainer}
                >
                  {content}
                </StickyHeaderFormSection>
              </HeaderWrapper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AdminTemplate>
  );
};

export { EditFeature };
