import { DeleteOutline } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OutlinedButton from "components/_new/Buttons/OutlinedButton";
import DeleteButton from "components/_new/DnD/DeleteIcon";
import TableAction from "components/_new/Table/Action";
import SelectInput from "components/ui/Inputs/Select";
import Input from "components/ui/Inputs/TextInput";
import { colors } from "config/theme/colors";
import { ResponsiveHideFrom, ResponsiveShowFrom } from "feature/panel/Itinerary/responsive";
import React, { useState } from "react";
import { useFieldArray } from "react-hook-form";
import { css } from "styled-components";
import { ExpandWrap, Passcode, PasscodeContainer, PermissionItem, PermissionsGrid, TopLine } from "./style";

const options = [
  {
    label: "Always show",
    value: "always",
  },
  {
    label: "Show during stay",
    value: "during",
  },
  {
    label: "Off",
    value: "off",
  },
];

const permissions = [
  {
    key: 0,
    label: "Directory",
    name: "show_directory",
  },
  {
    key: 1,
    label: "Daily",
    name: "show_daily",
  },
  {
    key: 2,
    label: "Vouchers",
    name: "show_vouchers",
  },
  {
    key: 3,
    label: "DND",
    name: "show_dnd",
    defaultOption: "during",
    options: [
      {
        label: "Show during stay",
        value: "during",
      },
      {
        label: "Off",
        value: "off",
      },
    ],
  },
];

const actionMobileStyle = { width: 65, height: 65 };

const Item = ({ onRemove, control, index, errors, watch }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const { fields: passcodes, append, remove } = useFieldArray({
    control,
    name: `passcode_groups.passcode_groups.${index}.passcodes`,
  });

  const categoryName = watch(`passcode_groups.passcode_groups.${index}.name`);

  const toggleExpand = () => setIsExpanded(!isExpanded);
  const hasError = passcodeIndex => errors?.passcode_groups?.passcode_groups[index]?.passcodes[passcodeIndex]?.passcode;
  return (
    <div>
      <TopLine>
        <ResponsiveShowFrom size="md" customStyles={{ display: "contents" }}>
          <DeleteButton onClick={() => onRemove(index)} />
        </ResponsiveShowFrom>
        <Input control={control} name={`passcode_groups.passcode_groups.${index}.name`} label="Category name *" autoFocus={!categoryName} />
        <div style={{ display: "flex", alignItems: "center" }}>
          <TableAction
            element={isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            onClick={toggleExpand}
            mobileStyle={actionMobileStyle}
          />
          <ResponsiveHideFrom size="md">
            <TableAction element={<DeleteOutline />} onClick={() => onRemove(index)} />
          </ResponsiveHideFrom>
        </div>
      </TopLine>
      {isExpanded && (
        <ExpandWrap>
          <div style={{ padding: "25px 15px", borderTop: `1px solid ${colors.grey10}` }}>
            <div>Permissions</div>
            <PermissionsGrid>
              {permissions.map(item => (
                <PermissionItem>
                  <span style={{ color: colors.grey40 }}>{item.label}</span>
                  <SelectInput
                    control={control}
                    name={`passcode_groups.passcode_groups.${index}.display_rules.${item.name}`}
                    options={item.options || options}
                    isClearable={false}
                    defaultValue={item.defaultOption || "always"}
                    styleContainer={{ width: "100%" }}
                  />
                </PermissionItem>
              ))}
            </PermissionsGrid>
          </div>
          <div style={{ padding: "25px 15px", borderTop: `1px solid ${colors.grey10}` }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: 25 }}>
              Passcodes{" "}
              <OutlinedButton
                text="Add"
                startIcon={<AddIcon />}
                style={{ border: "none" }}
                onClick={() => append({ passcode: "", description: "" })}
              />
            </div>
            {passcodes.map((_, passcodeIndex) => (
              <PasscodeContainer hasError={hasError(passcodeIndex)}>
                <Passcode hasError={hasError(passcodeIndex)}>
                  <Input
                    control={control}
                    name={`passcode_groups.passcode_groups.${index}.passcodes.${passcodeIndex}.passcode`}
                    label="Passcode"
                  />
                  <Input
                    control={control}
                    name={`passcode_groups.passcode_groups.${index}.passcodes.${passcodeIndex}.description`}
                    label="Description"
                  />

                  <ResponsiveHideFrom
                    size="md"
                    customCss={css`
                      display: contents;
                    `}
                  >
                    <TableAction element={<DeleteOutline />} onClick={() => remove(passcodeIndex)} />
                  </ResponsiveHideFrom>
                </Passcode>
                <ResponsiveShowFrom
                  size="md"
                  customCss={css`
                    display: contents;
                  `}
                >
                  <DeleteButton onClick={() => remove(passcodeIndex)} />
                </ResponsiveShowFrom>
              </PasscodeContainer>
            ))}
          </div>
        </ExpandWrap>
      )}
    </div>
  );
};

export default Item;
