import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MenuItem } from "@material-ui/core";
import styled from "styled-components";
import PropTypes from "prop-types";

import { BodyText2 } from "components/ui/Typography/Typography";
import { Select } from "components/ui/Forms";

import { CUSTOM_APPS, CUSTOM_APP_OPTIONS } from "constants/content";
import { PANEL_SETTINGS_CUSTOM_APPS } from "constants/routes";
import { setValueForType } from "store/customAppForm/actions";

const StyledBodyText = styled(BodyText2)`
  && {
    margin-bottom: ${({ theme }) => theme.setSpacing(2)}px;
  }
`;

const CustomAppTypeSelectModal = ({ openPopup, onModalClose }) => {
  const [type, setType] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isConfirmDisable = !type || type === "";

  const handleTypeChange = ({ target }) => {
    setType(target.value);
  };

  const handleConfirm = () => {
    dispatch(setValueForType(type));
    onModalClose();
  };
  const handleCancel = () => {
    navigate(PANEL_SETTINGS_CUSTOM_APPS);
    onModalClose();
  };

  return (
    <>
      {openPopup && (
        <ConfirmationModal
          open={openPopup}
          title={CUSTOM_APPS.general.typeModal.popupTitle}
          cancelLabel={CUSTOM_APPS.general.typeModal.cancelLabel}
          confirmLabel={CUSTOM_APPS.general.typeModal.confirmLabel}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
          confirmDisabled={isConfirmDisable}
          description={CUSTOM_APPS.general.typeModal.popupBodyText}
        >
          <StyledBodyText>{CUSTOM_APPS.general.typeModal.infoText}</StyledBodyText>
          <Select name="custom-app-type" value={type} onChange={handleTypeChange} label={CUSTOM_APPS.general.type}>
            {CUSTOM_APP_OPTIONS.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </ConfirmationModal>
      )}
    </>
  );
};

CustomAppTypeSelectModal.defaultProps = {
  openPopup: false,
};

CustomAppTypeSelectModal.propTypes = {
  openPopup: PropTypes.bool,
  onModalClose: PropTypes.func.isRequired,
};

export { CustomAppTypeSelectModal };
