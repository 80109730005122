// @ts-check

// eslint-disable-next-line import/no-cycle
import { User } from "domain/User";
import { UserRole } from "domain/UserRole";

import { BaseMapper } from "./BaseMapper";
import { OperatorMapper } from "./OperatorMapper";

export class UserMapper extends BaseMapper {
  /**
   *
   * @param {OperatorMapper} operatorMapper
   */
  constructor(operatorMapper = new OperatorMapper()) {
    super();
    // @ts-ignore
    this.operatorMapper = operatorMapper;
  }

  /**
   *
   * @param {UserResponseDto} dto
   * @returns {import("domain/User").User}
   */
  // eslint-disable-next-line class-methods-use-this
  fromDtoToDomain(dto) {
    const domain = new User();

    domain.id = dto.id;
    domain.username = dto.username;
    domain.email = dto.email;
    domain.firstname = dto.firstname;
    domain.lastname = dto.lastname;
    domain.confirmedAt = dto.confirmed_at;
    domain.lastLoginAt = dto.last_login_at;
    domain.confirmationRequestSentAt = dto.confirmation_request_sent_at;
    domain.userIsActive = dto.user_is_active;
    domain.isSkeletal = dto.is_skeletal;
    domain.operators = dto?.operators?.length ? dto?.operators?.map(operatorDto => this.operatorMapper.fromDtoToDomain(operatorDto)) : [];
    domain.passwordExpiresAt = dto.password_expires_at;
    domain.passwordChangedAt = dto.password_changed_at;
    domain.meta = dto.meta;
    domain.createdAt = dto.created_at;
    domain.updatedAt = dto.updated_at;
    domain.operatorId = dto.operator_id;
    domain.role = dto.role && UserRole(dto.role);
    domain.additionalVamoosAccess = dto.additional_vamoos_access;

    return domain;
  }
}
