import React, { useState, useRef } from "react";
import styled from "styled-components";

import Grid from "@material-ui/core/Grid";

import { ADMIN_OPERATOR_INDEX } from "constants/content";

import { Switch } from "components/ui/Forms";
import { AdminTemplate } from "components/templates/Admin/AdminTemplate";

import { ManageAccountsSubMenu } from "feature/admin/_shared/ManageAccountsSubMenu";
import { SHOW_DEACTIVATED_NAME, SHOW_DEACTIVATED_LABEL_PLACEMENT } from "feature/admin/Operators/_shared/constants";

import { SearchField } from "feature/panel/_shared/SearchField/SearchField";
import { DEFAULT_DEBOUNCE_DELAY } from "constants/defaults";

import { debounce } from "lodash";

import { TableContainer } from "./TableOfOperators/TableContainer";

const ShowArchivedContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${({ theme }) => theme.setSpacing(2)}px;
`;

const OperatorsIndex = () => {
  const [showDeactivated, setShowDeactivated] = useState(false);
  const [queryString, setQueryString] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const handleSearchChange = query => {
    setQueryString(query);
  };

  const debounceSearchQuery = useRef(debounce(query => handleSearchChange(query), DEFAULT_DEBOUNCE_DELAY));

  const onSearch = query => {
    setSearchValue(query);
    return debounceSearchQuery.current(query);
  };

  const contextBar = {
    left: <SearchField label={ADMIN_OPERATOR_INDEX.searchLabel} onSearchChange={onSearch} value={searchValue} />,
    middle: ManageAccountsSubMenu,
    right: null,
  };

  const handleArchivedOperators = archived => {
    setShowDeactivated(archived);
  };

  return (
    <AdminTemplate contextBar={contextBar}>
      <Grid container justifyContent="center">
        <Grid item md={12}>
          <ShowArchivedContainer>
            <Switch
              name={SHOW_DEACTIVATED_NAME}
              label={ADMIN_OPERATOR_INDEX.showDeactivated}
              labelPlacement={SHOW_DEACTIVATED_LABEL_PLACEMENT}
              checked={showDeactivated}
              onChange={() => handleArchivedOperators(!showDeactivated)}
            />
          </ShowArchivedContainer>
          <TableContainer archived={showDeactivated} queryString={queryString} />
        </Grid>
      </Grid>
    </AdminTemplate>
  );
};

export { OperatorsIndex, ShowArchivedContainer };
