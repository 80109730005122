import * as actionTypes from "store/icons/actionTypes";
import { reducer, setState, globalReducerActions } from "utils/store";

const iconsInitialState = {
  errors: {},
  actionType: null,
  finished: false,
  inProgress: false,
  defaultIcon: null,
  listOfIcons: [],
  isIconFormTouched: false,
};

const ReducerActions = globalReducerActions("icons", iconsInitialState, {
  [actionTypes.GET_PUBLIC_ICONS_START](state) {
    return setState(state, {
      actionType: actionTypes.GET_PUBLIC_ICONS_START,
      inProgress: true,
      finished: false,
    });
  },

  [actionTypes.GET_PUBLIC_ICONS_SUCCESS](state, payload) {
    return setState(state, {
      actionType: actionTypes.GET_PUBLIC_ICONS_SUCCESS,
      inProgress: false,
      finished: true,
      listOfIcons: payload.items,
    });
  },

  [actionTypes.GET_PUBLIC_ICONS_FAIL](state, { errors }) {
    return setState(state, {
      actionType: actionTypes.GET_PUBLIC_ICONS_FAIL,
      inProgress: false,
      finished: false,
      errors,
    });
  },

  [actionTypes.UPDATE_ICONS_START](state) {
    return setState(state, {
      actionType: actionTypes.UPDATE_ICONS_START,
      inProgress: true,
      finished: false,
    });
  },

  [actionTypes.UPDATE_ICONS_SUCCESS](state) {
    return setState(state, {
      actionType: actionTypes.UPDATE_ICONS_SUCCESS,
      inProgress: false,
      finished: true,
    });
  },

  [actionTypes.UPDATE_ICONS_FAIL](state, { errors }) {
    return setState(state, {
      actionType: actionTypes.UPDATE_ICONS_FAIL,
      inProgress: false,
      finished: false,
      errors,
    });
  },

  [actionTypes.ADD_NEW_ICON](state, payload) {
    return setState(state, {
      listOfIcons: [...state.listOfIcons, payload],
    });
  },

  [actionTypes.DELETE_ICON](state, payload) {
    return setState(state, {
      listOfIcons: state.listOfIcons.filter(icon => icon.id !== payload),
    });
  },
  [actionTypes.TOGGLE_ICON_FORM_TOUCHED](state, payload) {
    return setState(state, {
      isIconFormTouched: payload,
    });
  },
});

const iconsReducer = (state = iconsInitialState, action) => reducer(state, action, ReducerActions);

export { iconsReducer };
