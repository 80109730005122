import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PreviewIcon from "@mui/icons-material/Preview";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import AttachButton from "components/_new/Buttons/AttachButton";
import OutlinedButton from "components/_new/Buttons/OutlinedButton";
import LogoSelect from "components/_new/LogoSelect";
import Switch from "components/_new/Switch";
import DateTimeInput from "components/ui/Inputs/DateTimeInput";
import SelectInput from "components/ui/Inputs/Select";
import Input from "components/ui/Inputs/TextInput";
import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";
import { Message } from "components/ui/Messages";
import TimezoneSelect from "components/ui/TimezonePicker";
import { PERMISSIONS } from "constants/permissions";
import { ResponsiveShowFrom } from "feature/panel/Itinerary/responsive";
import { Wrapper } from "feature/panel/Itinerary/style";
import { useEffectDependenciesOnly } from "hooks/useEffectDependenciesOnly";
import { useListOfLanguagesObject } from "hooks/useListOfLanguages";
import { usePermissionsService } from "hooks/usePermissionsService";
import { useRefreshedFileUrl } from "hooks/useRefreshedFileUrl";
import React, { cloneElement, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import { setNotification } from "store/app/actions";
import { dispatch } from "store/store";
import { css } from "styled-components";
import moment from "moment";
import config from "config/app";
import { Buffer } from "buffer";
import { encodeParameter } from "utils/url";

import { getFilePreviewUrl } from "utils/library";
import allTimezones from "../../../../../../components/ui/TimezonePicker/timezones";
import ItineraryTitle from "../../../components/Title";
import Preview from "../../../components/pagesElements/General/Preview/index";
import {
  Grid,
  InputsGrid,
  InstantViewFlex,
  InstantViewGrid,
  InstantViewWrap,
  SwithcersWrap,
} from "../../../components/pagesElements/General/style";
import { inputs } from "./inputs";

const inputTypes = {
  text: <Input />,
  select: <SelectInput />,
  date: <DateTimeInput type="date" />,
  time: <DateTimeInput type="time" />,
};

const General = ({
  form: {
    control,
    watch,
    resetField,
    setValue,
    reset,
    getValues,
    formState: { errors, dirtyFields },
    setError,
    clearErrors,
  },
  canEdit,
  isWiped,
  isActive,
  isReseted,
  action,
  isDirty,
}) => {
  const { reference_code: reference_code_p, ...rest } = useParams();
  // Requests data
  const { data: { operators } = { operators: [] }, isFetching: fetchingOperators } = useQuery({
    queryKey: ["/user/me"],
  });
  const { data: operator } = useQuery({ queryKey: ["operator"] });
  const { data: { items: brands } = { brands: [] }, isFetching: fetchingBrands } = useQuery({
    queryKey: ["/operator/branding?count=5000"],
    enabled: Boolean(operator),
  });
  // Variables
  const qc = useQueryClient();
  const currentOperator = operator?.code;
  const allBrands = operator && brands ? [{ id: "default", name: "Default" }, ...brands] : [];
  const additional_languages = operator?.meta?.additional_languages || [];
  const default_language = operator?.default_language;
  const listOfAllLanguages = useListOfLanguagesObject();
  const languages =
    additional_languages?.length &&
    [default_language, ...additional_languages].map(item => ({
      label: listOfAllLanguages[item].name,
      value: item,
    }));

  const background = watch("general.background");
  const operator_code = watch("general.operator_code");
  const logo = watch("general.logo");
  const previewLink = watch("general.preview_link");
  const storyboard = watch("storyboard.details");
  const reference_code = watch("general.reference_code");
  const departure_date = watch("general.departure_date");
  const return_date = watch("general.return_date");
  const vamoos_id = watch("general.vamoos_id");

  const { data } = useQuery({
    queryKey: [`/itinerary/${currentOperator}/${encodeParameter(reference_code)}/passcode_check`],
    enabled: Boolean(reference_code && reference_code?.length > 2 && reference_code_p !== reference_code),
  });
  const permissionsService = usePermissionsService();
  const canOperatorsRead = permissionsService.can(PERMISSIONS.actions.read, PERMISSIONS.sections.operator, PERMISSIONS.sections.operators);

  const showBrandSelect = brands?.length >= 1;

  const backgroundUrl = useRefreshedFileUrl(getFilePreviewUrl(background));

  // State
  const [isMobilePreviewOpen, setIsMobilePreviewOpen] = useState(false);

  const getOperatorCodes = code => {
    return code ? operators.find(item => item.code === code)?.used_codes.map(item => ({ label: item, value: item })) : [];
  };

  const options = {
    allBrands,
    languages,
    userID: getOperatorCodes(currentOperator),
  };

  const shouldRenderInput = item => {
    return item.hasOwnProperty("shouldShow") ? item.shouldShow({ showBrandSelect, canOperatorsRead, additional_languages }) : true;
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(previewLink);
    dispatch(setNotification({ message: "Copied to clipboard", type: "success" }));
  };

  const navigateToPreview = () => {
    const webURL = `${config.webVamoos[config.webVamoosEnv]}/sample-trip`;
    const tripHashInBase64 = Buffer.from(`${operator_code}-${reference_code}`).toString("base64");
    const url = `${webURL}/${tripHashInBase64}`;

    window.open(url, "_blank");
  };

  const openPreview = () => {
    document.body.style.overflow = "hidden";
    setIsMobilePreviewOpen(true);
  };

  const closePreview = () => {
    document.body.style.overflow = "auto";
    setIsMobilePreviewOpen(false);
  };

  const renderInput = item =>
    shouldRenderInput(item) &&
    cloneElement(inputTypes[item.type || "text"], {
      ...item,
      name: `general.${item.name}`,
      control,
      options: options[item.optionsVar],
      disabled: !canEdit || isWiped,
    });

  useEffect(() => {
    if (action === "create" && operator) setValue("general.operator_code", operator?.used_codes[0]);
  }, [operator]);

  useEffectDependenciesOnly(() => {
    if (operator) {
      setValue("general", {
        ...getValues().general,
        ...(action === "create"
          ? {
              language: default_language,
              departure_date: new Date(),
              return_date: new Date(),
              operator_code: operator?.used_codes[0],
              branding_profile_id: getValues().branding_profile_id || "default",
            }
          : {}),
      });
    }
  }, [default_language, operator, brands]);

  useEffect(() => {
    if (dirtyFields?.general?.language) return;
    if (action === "create" && operator) {
      setValue("general.language", default_language);
    }
  }, [operator]);

  useEffect(() => {
    if (moment(departure_date).isAfter(return_date)) setValue("general.return_date", moment(departure_date).add(1, "days"));
  }, [departure_date, return_date]);

  useEffect(() => {
    if (data?.status === "Found" && reference_code !== reference_code_p && vamoos_id !== data?.vamoos_id) {
      setError("general.reference_code", { message: "This passcode already exists" });
    } else {
      clearErrors("general.reference_code");
    }
  }, [data]);

  useEffect(() => {
    qc.invalidateQueries({ queryKey: [`/itinerary/${currentOperator}/${reference_code}/passcode_check`] });
  }, []);

  // used to add any initial data/defaultValues to the general.meta for trips instead of using defaultValues, as adding it there causes unexpected behaviour
  useEffect(() => {
    if (isDirty || action !== "create") return;
    setValue("general.meta", { show_journal: true, show_poi_list: true });
  }, [isDirty, action]);

  if (fetchingOperators || fetchingBrands || !currentOperator) {
    return <LoadingScreen />;
  }

  return (
    <Wrapper>
      {!isActive && isReseted && (
        <Message
          type="error"
          text="This information will be permanently deleted after 5 days. Click the “Restore” button now to restore your data.
"
          margin={{ bottom: "25px" }}
        />
      )}
      <Grid>
        <div>
          <ItineraryTitle style={{ paddingLeft: 0 }}>General information</ItineraryTitle>
          <ResponsiveShowFrom
            size="md"
            customStyles={css`
              display: flex;
              align-items: center;
              gap: 15px;
            `}
          >
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              name="general.background"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <AttachButton
                    text="Add image"
                    onConfirm={onChange}
                    onDelete={() => onChange(null)}
                    file={value}
                    disabled={!canEdit || isWiped}
                    onChipCustomClick={openPreview}
                    label="Background"
                    mobileFullScreen
                    hideDescription
                  />
                </>
              )}
            />
            {/* {background && <OutlinedButton text="Preview" startIcon={<RemoveRedEye />} type="grey" onClick={openPreview} />} */}
          </ResponsiveShowFrom>

          <InputsGrid>
            {inputs.map(item => renderInput(item))}
            <TimezoneSelect
              timezones={allTimezones}
              control={control}
              watch={watch}
              setValue={setValue}
              disabled={!canEdit || isWiped}
              name="general.timezone"
              shouldSetUsersTimezone={!reference_code_p}
            />

            <SwithcersWrap>
              <Switch label="Enable Posts" control={control} name="general.meta.show_journal" disabled={!canEdit || isWiped} />
              <Switch label="Enable The List" control={control} name="general.meta.show_poi_list" disabled={!canEdit || isWiped} />
            </SwithcersWrap>
          </InputsGrid>
        </div>
        <Preview
          control={control}
          image={background}
          resetField={resetField}
          open={isMobilePreviewOpen}
          watch={watch}
          onClose={closePreview}
          error={errors?.general?.background}
          disabled={!canEdit || isWiped}
        />
      </Grid>

      <div style={{ marginTop: 40 }} />

      <ItineraryTitle>Overlay logo</ItineraryTitle>
      <LogoSelect
        onConfirm={img => setValue("general.logo", img, { shouldDirty: true })}
        onDelete={() => setValue("general.logo", null, { shouldDirty: true })}
        img={logo}
        disabled={!canEdit || isWiped}
        hideUrl
      />
      {/* !!! shouldShowInstantViewSection = editMode && storyboard.length > 0; */}
      {previewLink && storyboard?.length > 0 && (
        <InstantViewWrap>
          <ItineraryTitle>Instant view</ItineraryTitle>
          <InstantViewFlex>
            <Input control={control} name="general.preview_link" disabled />
            <InstantViewGrid>
              <OutlinedButton text="Copy" type="grey" startIcon={<ContentCopyIcon />} onClick={handleCopy} disabled={isWiped} />
              <OutlinedButton text="Preview" type="grey" startIcon={<PreviewIcon />} onClick={navigateToPreview} disabled={isWiped} />
            </InstantViewGrid>
          </InstantViewFlex>
        </InstantViewWrap>
      )}
    </Wrapper>
  );
};

export default General;
