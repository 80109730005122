export const inputs = [
  {
    name: "branding_profile_id",
    label: "Select branding",
    optionLabelVar: "name",
    optionValueVar: "id",
    optionsVar: "allBrands",
    type: "select",
    shouldShow: ({ showBrandSelect, canOperatorsRead }) => showBrandSelect && canOperatorsRead,
    isClearable: false,
  },
  {
    name: "language",
    label: "Language",
    optionsVar: "languages",
    type: "select",
    shouldShow: ({ additional_languages }) => additional_languages?.length > 1,
    isClearable: false,
  },
  {
    name: "operator_code",
    label: "User ID",
    optionsVar: "userID",
    rules: { required: true },
    type: "select",
  },
  {
    name: "reference_code",
    label: "Passcode",
    rules: { required: true },
  },
  {
    name: "field1",
    label: "Destination / Event title",
    rules: { required: true },
  },
  {
    name: "field3",
    label: "Name / Location",
    rules: { required: true },
  },

  {
    name: "departure_date",
    label: "Start date",
    rules: { required: true },
    type: "date",
  },
  {
    name: "return_date",
    label: "End date",
    rules: { required: true },
    type: "date",
  },
  {
    name: "start_time",
    label: "Start time (24h)",
    rules: { required: true },
    type: "time",
    format: "HH:mm",
  },

  {
    name: "client_reference",
    label: "Client ref",
  },
];
