import { colors } from "config/theme/colors";
import { getMediaPx } from "helpers";
import styled from "styled-components";

export const Wrap = styled.div`
  padding: 25px;
  background-color: ${colors.lightGreyBg};
  border-bottom: 1px solid #eaeaea;

  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    padding: 15px;
  }
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #8d9095;
  }
`;

export const BackButton = styled.div`
  display: none;
  color: #8d9095;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    display: block;
  }
`;

export const DescriptionWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    gap: 15px;
    justify-content: space-between;
  }
`;

export const Description = styled.div`
  margin-top: 25px;
  span {
    color: ${colors.grey40};
  }

  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    margin-top: 15px;
    font-size: 12px;
  }
`;
