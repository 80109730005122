import styled, { css } from "styled-components";
import { colors } from "config/theme/colors";
import { getMediaPx } from "helpers";
import { INPUT_BORDER_COLOR, INPUT_BORDER_RADIUS, INPUT_HEIGHT, INPUT_LABEL_COLOR } from "../config";

export const commonInputCss = css`
  /* &.MuiInputBase-root {
    height: ${INPUT_HEIGHT.desktop}px;
    padding: 0 17px;
  } */
  /* .MuiInputBase-adornedEnd {
    padding-right: : ;
  } */

  fieldset {
    legend {
      margin-left: 6px;
    }
  }
  textarea {
    padding: 0 !important;
  }
  .MuiInputBase-root {
    z-index: 6;
    /* padding: ${p => (p.multiline ? "12.5px 14px" : 0)} ; */
    @media (max-width: ${p => getMediaPx(p, "md")}px) {
      padding: ${p => p.multiline && "12.5px 14px"} !important;
    }
  }
  .MuiInputAdornment-root {
    margin-right: 0;
  }
  .Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.error} !important;
  }
  .MuiOutlinedInput-input {
    height: ${INPUT_HEIGHT.desktop}px;
    padding: 0 17px;
    &:-webkit-autofill {
      opacity: ${({ value }) => (value ? 1 : 0)};
    }
    /* @media (max-width: ${p => getMediaPx(p, "sm")}px) {
      height: ${INPUT_HEIGHT.mobile}px;
    } */
  }

  .MuiInputLabel-root, &.MuiInputLabel-root {
    color: ${INPUT_LABEL_COLOR};
    z-index: 7;
  }

  .MuiOutlinedInput-root, &.MuiOutlinedInput-root {
    border-radius: ${INPUT_BORDER_RADIUS}px;
    .MuiOutlinedInput-notchedOutline {
      border-color: ${({ borderColor }) => borderColor || INPUT_BORDER_COLOR};
      transition: all 0.2s ease-in-out;
    }
    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${({ error }) => (error ? colors.error : colors.brand)} !important;
      }
    }
    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${p => !p.disabled && colors.brand};
      }
    }
  }
  .MuiInputBase-input {
    text-overflow: ellipsis;
    font-family: "Montserrat";
  }
  .MuiInputLabel-outlined, &.MuiOutlinedInput-notchedOutline {
    transform: translate(14px, ${({ value, isFocused, placeholder }) => (value || isFocused || placeholder ? "-9px" : "13px")})
      scale(${({ value, isFocused, placeholder }) => (value || isFocused || placeholder ? 0.75 : 1)});
    font-family: ${({ theme }) => theme.fonts.font};
    background-color: #fff;
    padding: 0 5px;
    border-radius: 8px;
    color: ${INPUT_LABEL_COLOR};
    &.Mui-focused {
      transform: translate(14px, -9px) scale(0.75);
      color: ${colors.brand};
    }

    @media (max-width: 600px) {
      transform: translate(14px, ${({ value, isFocused, placeholder }) => (value || isFocused || placeholder ? "-8px" : "13px")})
        scale(${({ value, isFocused }) => (value || isFocused ? 0.75 : 1)});
    }
  }
  .MuiSvgIcon-root {
    fill: ${p => (p.isFocused ? colors.brand : INPUT_BORDER_COLOR)};
  }
  .MuiAutocomplete-inputRoot {
   
    input {
        padding: 0 15px !important;
      }

  }
  .MuiInputAdornment {
    svg {
      fill: red;
    }
  }
`;

export const InputStyled = styled("div")`
  width: 100%;
  ${commonInputCss};
  opacity: ${p => (p.disabled ? 0.4 : 1)};
  ul {
    position: absolute;
    max-height: 300px;
    max-width: 300px;
    overflow-y: scroll;
    background-color: #fff;
    margin-top: 5px;
    border: 1px solid #eaeaea;
    border-radius: ${INPUT_BORDER_RADIUS}px;
    z-index: 9;
    padding: 0;
    li {
      list-style: none;
      padding: 7px 15px;
      &:hover {
        background-color: #eaeaea;
        transition: all 0.2s ease-in-out;
      }
    }
  }
`;

export const ErrorText = styled.div`
  font-size: 12px;
  color: ${colors.error};
  font-family: ${({ theme }) => theme.fonts.font};
  margin-top: 5px;
`;
