import * as actionTypes from "store/adminOperatorDetails/actionTypes";

import { reducer, setState, globalReducerActions } from "utils/store";

const adminOperatorsInitialState = {
  errors: {},
  actionType: null,
  inProgress: false,
  finished: false,
  isFormTouched: false,
  details: {},
  customApps: [],
  count: 0,
};

const ReducerActions = globalReducerActions("adminOperatorDetails", adminOperatorsInitialState, {
  [actionTypes.GET_OPERATOR_DETAILS_START](state) {
    return setState(state, {
      actionType: actionTypes.GET_OPERATOR_DETAILS_START,
      inProgress: true,
      finished: false,
      details: {},
    });
  },

  [actionTypes.GET_OPERATOR_DETAILS_SUCCESS](state, payload) {
    return setState(state, {
      actionType: actionTypes.GET_OPERATOR_DETAILS_SUCCESS,
      inProgress: false,
      finished: true,
      isFormTouched: false,
      details: { ...payload },
    });
  },

  [actionTypes.GET_OPERATOR_DETAILS_FAIL](state, payload) {
    return setState(state, {
      actionType: actionTypes.GET_OPERATOR_DETAILS_FAIL,
      inProgress: false,
      finished: false,
      errors: { ...payload },
    });
  },

  [actionTypes.SET_VALUE_FOR_OPERATOR_START](state, payload) {
    const { fieldName, value } = payload;

    return setState(state, {
      ...state,
      isFormTouched: true,
      details: {
        ...state.details,
        [fieldName]: value,
      },
    });
  },
  [actionTypes.UPDATE_ADMIN_OPERATOR_DETAILS_START](state) {
    return setState(state, {
      actionType: actionTypes.UPDATE_ADMIN_OPERATOR_DETAILS_START,
      inProgress: true,
      finished: false,
    });
  },
  [actionTypes.UPDATE_ADMIN_OPERATOR_DETAILS_SUCCESS](state) {
    return setState(state, {
      actionType: actionTypes.UPDATE_ADMIN_OPERATOR_DETAILS_SUCCESS,
      inProgress: false,
      finished: true,
    });
  },
  [actionTypes.UPDATE_ADMIN_OPERATOR_DETAILS_FAIL](state, payload) {
    return setState(state, {
      actionType: actionTypes.UPDATE_ADMIN_OPERATOR_DETAILS_FAIL,
      inProgress: false,
      finished: false,
      errors: { ...payload },
    });
  },
  [actionTypes.SET_OPERATOR_DETAILS_FORM_TOUCHED](state, payload) {
    return setState(state, {
      isFormTouched: payload,
    });
  },
  [actionTypes.GET_CUSTOM_APP_LIST_FOR_ADMIN_OPERATOR_START](state) {
    return setState(state, {
      inProgress: true,
      finished: false,
      actionType: actionTypes.GET_CUSTOM_APP_LIST_FOR_ADMIN_OPERATOR_START,
    });
  },
  [actionTypes.GET_CUSTOM_APP_LIST_FOR_ADMIN_OPERATOR_SUCCESS](state, payload) {
    const { customApps, count } = payload;
    return setState(state, {
      customApps,
      count,
      inProgress: false,
      finished: true,
    });
  },
  [actionTypes.GET_CUSTOM_APP_LIST_FOR_ADMIN_OPERATOR_FAIL](state, payload) {
    const { errors } = payload;
    return setState(state, {
      errors,
      customApps: [],
      inProgress: false,
    });
  },
  [actionTypes.ACCEPT_CUSTOM_APP_START](state) {
    return setState(state, {
      actionType: actionTypes.ACCEPT_CUSTOM_APP_START,
      finished: false,
      inProgress: true,
    });
  },
  [actionTypes.ACCEPT_CUSTOM_APP_SUCCESS](state) {
    return setState(state, {
      inProgress: false,
      finished: true,
    });
  },
  [actionTypes.ACCEPT_CUSTOM_APP_FAIL](state, payload) {
    return setState(state, {
      inProgress: false,
      finished: false,
      errors: { ...payload.error },
    });
  },
  [actionTypes.REJECT_CUSTOM_APP_START](state) {
    return setState(state, {
      actionType: actionTypes.REJECT_CUSTOM_APP_START,
      inProgress: true,
      finished: false,
    });
  },
  [actionTypes.REJECT_CUSTOM_APP_SUCCESS](state) {
    return setState(state, {
      inProgress: false,
      finished: true,
    });
  },
  [actionTypes.REJECT_CUSTOM_APP_FAIL](state, payload) {
    return setState(state, {
      inProgress: false,
      finished: false,
      errors: { ...payload.error },
    });
  },
  [actionTypes.REVERT_TO_DRAFT_CUSTOM_APP_START](state) {
    return setState(state, {
      actionType: actionTypes.REVERT_TO_DRAFT_CUSTOM_APP_START,
      inProgress: true,
      finished: false,
    });
  },
  [actionTypes.REVERT_TO_DRAFT_CUSTOM_APP_SUCCESS](state) {
    return setState(state, {
      inProgress: false,
      finished: true,
    });
  },
  [actionTypes.REVERT_TO_DRAFT_CUSTOM_APP_FAIL](state, payload) {
    return setState(state, {
      inProgress: false,
      finished: false,
      errors: { ...payload },
    });
  },

  [actionTypes.MAKE_LIVE_CUSTOM_APP_START](state) {
    return setState(state, {
      actionType: actionTypes.MAKE_LIVE_CUSTOM_APP_START,
      finished: false,
      inProgress: true,
    });
  },
  [actionTypes.MAKE_LIVE_CUSTOM_APP_SUCCESS](state) {
    return setState(state, {
      inProgress: false,
      finished: true,
    });
  },
  [actionTypes.MAKE_LIVE_CUSTOM_APP_FAIL](state, payload) {
    return setState(state, {
      inProgress: false,
      finished: false,
      errors: { ...payload.error },
    });
  },
  [actionTypes.GET_CUSTOM_APP_BUNDLE_VERSIONS_SUCCESS](state, payload) {
    const { bundleId, versionsList } = payload;
    const customApps = [...state.customApps].map(app => {
      return app.bundleId === bundleId ? { ...app, versionsList } : app;
    });
    return setState(state, {
      customApps,
    });
  },
  [actionTypes.GET_CUSTOM_APP_BUNDLE_VERSIONS_FAIL](state, payload) {
    return setState(state, {
      inProgress: false,
      finished: false,
      errors: { ...payload.error },
    });
  },
});

const adminOperatorDetailsReducer = (state = adminOperatorsInitialState, action) => reducer(state, action, ReducerActions);

export { adminOperatorDetailsReducer };
