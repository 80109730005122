import styled from "styled-components";
import { SectionTitle } from "../sharedComponents";

export const Section = styled.div`
  margin-bottom: 40px;
  max-width: 400px;
  ${SectionTitle} {
    /* font-size: 18px; */
    margin-bottom: 20px;
  }
`;

export const Hint = styled.div`
  font-size: 12px;
  color: #919191;
`;
