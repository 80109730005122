import React from "react";
import PropTypes from "prop-types";
import styled, { css, keyframes } from "styled-components";

import { TableCell, TableRow } from "@mui/material";
import SpecificSettingsPlaceholder from "./specificSettingsPlaceholder";
import { TextLimiter } from "../../../../../../components/ui/TextLimiter";

export const blinkAnimation = keyframes`
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
`;

export const Wrap = styled(TableRow)`
  && {
    width: 100%;
    cursor: ${({ item }) => (item?.isOverridden ? "pointer" : "default")};

    ${({ clickedRow, item }) =>
            clickedRow && clickedRow === item?.template_id &&
            css`
              animation: ${blinkAnimation} 0.5s ease-out 2;
            `};
  }
`;

export const CustomTableRow = ({ data, onClick, columns, clickedRow }) => {
  if (!data) return null;

  const isOverridden = data?.isOverridden;

  return (
    <>
      {isOverridden ? (
        <Wrap item={data} onClick={() => onClick && onClick(data.id)} style={{ opacity: 0.3 }}>
          <TableCell>{columns[0].renderCell(data)}</TableCell>
          <TableCell>
            <TextLimiter maxChar={45} text={data.content} />
          </TableCell>
          <TableCell colSpan={data.type === "timed" ? 4 : 5}>
            <div style={{ display: "flex", alignItems: "center" }}>{isOverridden && <SpecificSettingsPlaceholder />}</div>
          </TableCell>
        </Wrap>
      ) : (
        <Wrap item={data} clickedRow={clickedRow} id={`row-${data.template_id}`}>
          {columns.map(item => (
            <TableCell key={item.id} align={item.align} style={{ padding: item.isAction && 0, width: item.isAction && 72 }}>
              {item.renderCell
                ? item.renderCell(data)
                : data[item.id]}
            </TableCell>
          ))}
        </Wrap>
      )}
    </>
  );
};

CustomTableRow.propTypes = {
  data: PropTypes.shape().isRequired,
  onClick: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  clickedRow: PropTypes.number.isRequired,
};
export default CustomTableRow;