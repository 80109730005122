import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import RateReviewOutlinedIcon from "@material-ui/icons/RateReviewOutlined";

import { ActionButton } from "components/ui/Buttons";
import { BodyText } from "components/ui/Typography/Typography";

import { VAMOOS_INFO_EMAIL, STAYS_FEATURES_LABEL } from "constants/content";

import { ReactComponent as TopFour } from "assets/images/top_four.svg";
import { useSelector } from "react-redux";
import { LanguageContext } from "components/_shared/LanguageSelector/LanguageContext";

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 24px;
  height: 72px;
`;

const ActionIcon = styled(TopFour)`
  margin: 3px 7px 3px 0;
`;

const StyledActionButton = styled(ActionButton)`
  && {
    margin-right: ${({ theme }) => theme.setSpacing(6)}px;

    :hover {
      path {
        fill: ${({ theme }) => theme.colors.brandHover};
      }
    }
    ${({ disabled, theme }) =>
      disabled
        ? `
      path {
        fill: ${theme.colors.grey20};
      }
      `
        : ""}
  }
`;

const Title = styled(BodyText)`
  display: flex;
  align-items: center;
  gap: 24px;
`

const FeaturesAccordionHeader = ({ disabled, isTop4Disabled, onTopFourClick, lockedInfo }) => {

  const { currentLanguage } = useContext(LanguageContext);

  const isStayDefaultLanguage = useSelector(
    ({ stayWizard }) => stayWizard.defaultVariant.language ? stayWizard.defaultVariant.language === currentLanguage.code : true
  )

  return (
    <HeaderWrapper>
      <Title>
        {STAYS_FEATURES_LABEL.title}
        {lockedInfo}
      </Title>
      <div>
        <StyledActionButton size="small" onClick={onTopFourClick} disabled={isTop4Disabled || !isStayDefaultLanguage}>
          <ActionIcon />
          <span>{STAYS_FEATURES_LABEL.setTop4}</span>
        </StyledActionButton>
        {!disabled && (
          <a href={`mailto:${VAMOOS_INFO_EMAIL}`}>
            <ActionButton disabled={!isStayDefaultLanguage} size="small">
              <RateReviewOutlinedIcon />
              <span>{STAYS_FEATURES_LABEL.requestNewFeature}</span>
            </ActionButton>
          </a>
        )}
      </div>
    </HeaderWrapper>
  )
};

FeaturesAccordionHeader.defaultProps = {
  disabled: false,
  isTop4Disabled: false,
};

FeaturesAccordionHeader.propTypes = {
  disabled: PropTypes.bool,
  isTop4Disabled: PropTypes.bool,
  onTopFourClick: PropTypes.func.isRequired,
  lockedInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export { FeaturesAccordionHeader, HeaderWrapper };
