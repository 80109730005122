import { LS_ITEMS_ORIGINAL_INDEXES } from "constants/localstorage";

export class ListIndexService {
  constructor(storage) {
    this.storageService = storage;
  }

  async removeFromOriginalIndexes(index, context) {
    const indexes = await this.storageService.getPropertyByName(LS_ITEMS_ORIGINAL_INDEXES);

    if (index && context && indexes && indexes[context]) {
      const newIndex = {
        ...indexes,
        [context]: indexes[context].filter(i => i !== index),
      };
      return this.storageService.setPropertyByName(LS_ITEMS_ORIGINAL_INDEXES, newIndex);
    }
    return null;
  }

  async addToOriginalIndexes(index, context) {
    const oldIndexes = await this.storageService.getPropertyByName(LS_ITEMS_ORIGINAL_INDEXES);
    if (index && context && oldIndexes && oldIndexes[context]) {
      const newList = oldIndexes && oldIndexes[context] ? [...oldIndexes[context], index] : [index];
      const result = { ...oldIndexes, [context]: newList };
      await this.storageService.setPropertyByName(LS_ITEMS_ORIGINAL_INDEXES, { ...oldIndexes, [context]: newList });
      return result;
    }
    return oldIndexes;
  }

  async updateOriginalIndexes(indexes, context) {
    const oldIndexes = await this.storageService.getPropertyByName(LS_ITEMS_ORIGINAL_INDEXES);
    if (indexes && context) {
      return this.storageService.setPropertyByName(LS_ITEMS_ORIGINAL_INDEXES, { ...oldIndexes, [context]: indexes });
    }

    return oldIndexes;
  }

  clearOriginalIndexes() {
    this.storageService.setPropertyByName(LS_ITEMS_ORIGINAL_INDEXES, {});
  }

  async updateOriginalIndexesPosition(payload, payloadMap) {
    if (typeof payload === "object" && Array.isArray(payloadMap)) {
      // eslint-disable-next-line no-restricted-syntax
      for (const el of payloadMap) {
        const items = payload[el];
        if (Array.isArray(items) && items.length > 0) {
          // eslint-disable-next-line no-await-in-loop
          await this.updateOriginalIndexes(
            items.map(key => key.id),
            el,
          );
        }
      }
    }
  }
}
