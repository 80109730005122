import { getMediaPx } from "helpers";
import styled from "styled-components";

export const Wrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
  padding: 50px 0;
  backdrop-filter: blur(10px);
  #phone {
    position: absolute;
    width: 300px;
  }
  iframe {
    height: 80vh;
    aspect-ratio: 9 / 19.5;
    max-width: 400px;
    border-radius: 40px;
    border: 8px solid #000;
    position: relative;
  }
`;

export const IframeWrap = styled.div`
  position: relative;
  &::after {
    content: "";
    position: absolute;
    height: 20px;
    width: 25%;
    border-radius: 10px;
    background-color: #000;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
`;
