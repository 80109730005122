import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormControlLabel } from "@material-ui/core";

import { Checkbox } from "components/ui/Forms";

import { CUSTOM_APPS } from "constants/content";
import { ToggleControl } from "components/ui/ToggleControl/ToggleControl";
import {
  setValueForDividerColor,
  setValueForMenuBgColor,
  setValueForMenuItinerartBgColor,
  setValueForMenuTextColor,
} from "store/customAppForm/actions";

import { PERMISSIONS } from "constants/permissions";
import { usePermissionsService } from "hooks/usePermissionsService";

import {
  CustomCard,
  SectionTitle,
  CardSectionsContainer,
  CardSection,
  PreviewCardSection,
  ColorPickerWrapper,
  InputContentWrapper,
} from "../sharedComponents";
import { CustomColorPicker } from "../ColorPicker/CustomColorPicker";
import { MenuOpenedPreview } from "../previews/MenuOpenedPreview";

const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    .MuiFormControlLabel-label {
      font-size: ${({ theme }) => theme.setSpacing(3)}px;
    }
  }
`;

const COLORS = {
  light: "#FFFFFF",
  dark: "#000000",
};

const MenuBox = ({ isHistoryVersion }) => {
  const permissionsService = usePermissionsService();
  const dispatch = useDispatch();

  const [defaultAccentColor, setDefaultAccentColor] = useState(true);

  const caStatus = useSelector(state => state.customAppForm?.customAppForm?.status);
  const definitionId = useSelector(state => state.customAppForm.customAppForm.id);
  const separationLineColor = useSelector(state => state.customAppForm.customAppForm.colors.menuDividerColour);
  const menuTextColour = useSelector(state => state.customAppForm.customAppForm.colors.menuTextColour);
  const menuBackgroundColour = useSelector(state => state.customAppForm.customAppForm.colors.menuBackgroundColour);
  const accentColor = useSelector(state => state.customAppForm.customAppForm.colors.accentColor);

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.operator, PERMISSIONS.resources.operators);
  const canCreate = permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.operator, PERMISSIONS.resources.operators);

  const editDisabled = !((caStatus === null && canCreate) || (caStatus === "draft" && canEdit));

  const handleBgColorChange = color => {
    dispatch(setValueForMenuBgColor(color));
  };
  const handleTextAndIconColorChange = color => {
    dispatch(setValueForMenuTextColor(color));
  };
  const handleSeparationLineColorChange = ({ target }) => {
    dispatch(setValueForDividerColor(target.value));
    dispatch(setValueForMenuItinerartBgColor(target.value));
  };

  const onUseAccentColorChange = () => {
    if (!defaultAccentColor) {
      handleTextAndIconColorChange(accentColor);
    }
    setDefaultAccentColor(!defaultAccentColor);
  };

  useEffect(() => {
    if (definitionId) {
      setDefaultAccentColor(accentColor === menuTextColour);
    }
  }, [definitionId]);

  const colorSetupContent = (
    <>
      <ColorPickerWrapper>
        <CustomColorPicker
          color={menuBackgroundColour}
          label={CUSTOM_APPS.menu.bgLabel}
          onColorChange={handleBgColorChange}
          disabled={isHistoryVersion || editDisabled}
        />
      </ColorPickerWrapper>
      <ColorPickerWrapper>
        <CustomColorPicker
          color={menuTextColour}
          label={CUSTOM_APPS.menu.textAndIconLabel}
          onColorChange={handleTextAndIconColorChange}
          disabled={defaultAccentColor || isHistoryVersion || editDisabled}
        />
        <StyledFormControlLabel
          label={CUSTOM_APPS.elements.buttons.useAccentColorLabel}
          control={<Checkbox checked={defaultAccentColor} onChange={onUseAccentColorChange} />}
          disabled={isHistoryVersion || editDisabled}
          style={{ marginLeft: 0, marginTop: 15 }}
        />
      </ColorPickerWrapper>

      <ToggleControl
        label={CUSTOM_APPS.menu.divider.separationLineLabel}
        value={separationLineColor}
        onChange={handleSeparationLineColorChange}
        firstValue={COLORS.dark}
        secondValue={COLORS.light}
        firstValueLabel={CUSTOM_APPS.menu.divider.dark}
        secondValueLabel={CUSTOM_APPS.menu.divider.light}
        disabled={isHistoryVersion || editDisabled}
      />
    </>
  );

  return (
    <CustomCard>
      <CardSectionsContainer>
        <CardSection>
          <InputContentWrapper>
            <SectionTitle>{CUSTOM_APPS.menu.title}</SectionTitle>
            {colorSetupContent}
          </InputContentWrapper>
        </CardSection>
        <PreviewCardSection>
          <MenuOpenedPreview />
        </PreviewCardSection>
      </CardSectionsContainer>
    </CustomCard>
  );
};

MenuBox.defaultProps = {
  isHistoryVersion: false,
};

MenuBox.propTypes = {
  isHistoryVersion: PropTypes.bool,
};

export { MenuBox };
