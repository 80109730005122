import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";

import { Select } from "components/ui/Forms";

import { BRANDING_LABELS } from "constants/content";

const SelectBrandProfile = ({ brandingProfileId, onBrandProfileChange, disabled }) => {
  const brandList = useSelector(({ branding }) => branding?.brandingList || []);
  useEffect(() => {
    if (brandingProfileId === null && brandList.length) {
      const defaultBrand = brandList.find(brand => !!brand?.isDefault);

      onBrandProfileChange({ target: { value: defaultBrand.id } });
    }
  }, [brandingProfileId, brandList]);

  return (
    <Select
      name="branding"
      value={brandingProfileId || ""}
      label={BRANDING_LABELS.selectBrand}
      onChange={onBrandProfileChange}
      disabled={brandList?.length === 1 || disabled}
    >
      {brandList.map(brand => (
        <MenuItem key={brand.id} value={brand.id}>
          {brand.isDefault ? BRANDING_LABELS.default : brand.name}
        </MenuItem>
      ))}
    </Select>
  );
};

SelectBrandProfile.defaultProps = {
  brandingProfileId: null,
  disabled: null,
};

SelectBrandProfile.propTypes = {
  brandingProfileId: PropTypes.number,
  onBrandProfileChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export { SelectBrandProfile };
