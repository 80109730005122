import React from "react";
import styled from "styled-components";
import { SecondaryButton } from "components/ui/Buttons/SecondaryButton";

const ActionButton = styled(({ noBorder, ...rest }) => <SecondaryButton {...rest} />)`
  && {
    color: ${({ theme }) => theme.colors.grey100};
    transition: ${({ theme }) => theme.transition};
    opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};

    ${({ noBorder, theme }) => (!noBorder ? `border: 1px solid ${theme.colors.grey50};` : null)}

    .MuiSvgIcon-root {
      color: ${({ theme }) => theme.colors.grey50};
      transition: ${({ theme }) => theme.transition};
    }

    svg {
      color: ${({ theme }) => theme.colors.grey50};
    }

    :hover {
      .MuiSvgIcon-root {
        color: ${({ theme }) => theme.colors.brand};
      }

      svg {
        color: ${({ theme }) => theme.colors.brand};
      }
    }
  }
`;

export { ActionButton };
