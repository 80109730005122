import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PERMISSIONS } from "constants/permissions";
import { usePermissionsService } from "hooks/usePermissionsService";
import { getCustomAppForAdminOperatorStart } from "store/adminOperatorDetails/actions";

import { CustomAppList } from "./CustomAppList/CustomAppList";

const CustomAppIndex = () => {
  const permissionsService = usePermissionsService();
  const customApps = useSelector(state => state.adminOperatorDetails.customApps);
  const id = useSelector(state => state.adminOperatorDetails.details.id);

  const dispatch = useDispatch();
  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.admin, PERMISSIONS.resources.operators, id);

  useEffect(() => {
    dispatch(getCustomAppForAdminOperatorStart());
  }, []);

  return <CustomAppList customApps={customApps} canEdit={canEdit} />;
};

export { CustomAppIndex };
