import { DeleteOutline, DeleteOutlineOutlined, EditOutlined } from "@mui/icons-material";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import TableAction from "components/_new/Table/Action";
import { colors } from "config/theme/colors";
import { css } from "styled-components";
import { Tooltip } from "@mui/material";
import DeleteDirectoryModal from "feature/panel/Itinerary/components/DeleteDirectoryModal";
import { ResponsiveHideFrom, ResponsiveShowFrom } from "feature/panel/Itinerary/responsive";
import { WeekdayChip } from "feature/panel/Stays/_shared/DailyActivities/DailyListRow";
import React, { useState } from "react";
import { WEEKDAY_VALUES_ARRAY } from "utils/directories";
import DirectoryModal from "../../../components/Modal/modal";
import { Buttons } from "../../Trips/Creations/MobileItem/style";
import { Buttons as ButtonsWrap, NameWrap, Weekdays, Wrap } from "./style";

const Item = ({ item, index, directories, replace, update, remove }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const toggleButtons = () => setIsOpen(!isOpen);
  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);

  return (
    <Wrap>
      {showDeleteModal && (
        <DeleteDirectoryModal type="daily activity" name={item.name} onConfirm={() => remove(index)} onCancel={toggleDeleteModal} />
      )}

      {isEditModalOpen && (
        <DirectoryModal
          item={item}
          onClose={() => setIsEditModalOpen(false)}
          directories={directories}
          replace={replace}
          update={update}
          index={index}
          type="daily"
        />
      )}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <AccessTimeFilledIcon />
      </div>
      <ResponsiveShowFrom size="md">
        <NameWrap>
          <Tooltip title={item.name}>
            <div style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", paddingRight: 15 }}>{item.name}</div>
          </Tooltip>
          <Weekdays>
            {WEEKDAY_VALUES_ARRAY.map(weekday => (
              <WeekdayChip key={weekday} active={item.weekdays?.[weekday]}>
                {weekday[0]}
              </WeekdayChip>
            ))}
          </Weekdays>
        </NameWrap>
      </ResponsiveShowFrom>
      <ResponsiveHideFrom
        size="md"
        customStyles={css`
          display: contents;
        `}
      >
        <Tooltip title={item.name}>
          <div style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", paddingRight: 15 }}>{item.name}</div>
        </Tooltip>
        <Weekdays>
          {WEEKDAY_VALUES_ARRAY.map(weekday => (
            <WeekdayChip key={weekday} active={item.weekdays?.[weekday]}>
              {weekday[0]}
            </WeekdayChip>
          ))}
        </Weekdays>
      </ResponsiveHideFrom>

      <ButtonsWrap>
        <ResponsiveShowFrom size="md">
          <Buttons style={{ gap: 0, height: 50, top: 15, padding: 0 }}>
            {isOpen && (
              <>
                <TableAction
                  element={<EditOutlined />}
                  onClick={() => setIsEditModalOpen(true)}
                  wrapStyle={{ width: 48, height: 48, backgroundColor: "#fff", border: `1px solid ${colors.grey10}` }}
                />
                <TableAction
                  element={<DeleteOutline />}
                  onClick={() => remove(index)}
                  wrapStyle={{ width: 48, height: 48, backgroundColor: "#fff", border: `1px solid ${colors.grey10}` }}
                />
              </>
            )}

            <TableAction
              element={<MoreHorizIcon />}
              onClick={toggleButtons}
              wrapStyle={{ width: 48, height: 48, backgroundColor: "#fff", border: `1px solid ${colors.grey10}` }}
            />
          </Buttons>
        </ResponsiveShowFrom>
        <ResponsiveHideFrom size="md">
          <TableAction element={<EditOutlined />} onClick={() => setIsEditModalOpen(true)} />
        </ResponsiveHideFrom>
        <ResponsiveHideFrom size="md">
          <TableAction element={<DeleteOutlineOutlined />} onClick={toggleDeleteModal} />
        </ResponsiveHideFrom>
      </ButtonsWrap>
    </Wrap>
  );
};

export default Item;
