import React, { cloneElement, forwardRef, useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { FormControl, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { matchSorter } from "match-sorter";
import { InputStyled, ErrorText } from "./style";
import { ConditionalWrapper } from "../conditionalWrapper";
import InputErrorOrHint from "../components/Error";

const Input = forwardRef(
  (
    {
      control,
      name,
      rules,
      label,
      type = "text",
      style,
      onBlur,
      autoFocus,
      disabled,
      labelStyle,
      modifyValue,
      modifyError,
      modifiedOnChange,
      currentLanguage,
      endAdornment,
      onChange,
      customOnChange,
      defaultValue,
      value,
      multiline,
      rows,
      onKeyPress,
      options,
      renderOption,
      limit,
      hint,
      minRows,
      maxRows,
      placeholder,
      handleOptionClick,
      handleFocus,
      forwardKey,
      numberMinValue,
      borderColor,
    },
    ref,
  ) => {
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [isFocused, setIsFocused] = useState(false);

    const handleBlur = () => {
      setIsFocused(false);
      if (onBlur) onBlur();
    };

    const onFocus = () => {
      setIsFocused(true);
      if (handleFocus) handleFocus();
    };

    const onOptionClick = (item, onChange) => {
      onChange(item.name);
      if (handleOptionClick) handleOptionClick(item);
      setIsFocused(false);
    };

    useEffect(() => {
      if (!value) setFilteredOptions([]);
    }, [value]);

    const renderInput = ({ error, value, onChange }) => (
      <InputStyled multiline={multiline} style={style} value={value} isFocused={isFocused} disabled={disabled} placeholder={placeholder} borderColor={borderColor}>
        <FormControl style={{ width: "100%", textAlign: "left" }} focused={isFocused}>
          <InputLabel error={modifyError ? modifyError(error) : error} style={labelStyle} data-shrink="true">
            {label ? label + (rules?.required && !multiline ? "*" : "") : null}
          </InputLabel>

          <OutlinedInput
            label={label ? label + (rules?.required ? "*" : "") : null}
            variant="outlined"
            value={(modifyValue ? modifyValue(value) : value) || ""}
            onChange={e => {
              let { value } = e.target;
              if (type === "number" && value !== "0") value = +value;
              if (typeof numberMinValue === "number" && type === "number" && +value < numberMinValue)
                value = numberMinValue === 0 ? "0" : +numberMinValue;
              if (limit && e.target.value?.length >= limit) value = value.slice(0, limit);

              onChange(modifiedOnChange ? modifiedOnChange(e) : value);

              if (options) {
                setFilteredOptions(
                  !e.target.value ? options : options?.filter(item => item.name?.toLowerCase().includes(value?.toLowerCase())),
                );
              }
              if (customOnChange) customOnChange(value);
            }}
            onBlur={handleBlur}
            onFocus={onFocus}
            onKeyPress={onKeyPress}
            error={modifyError ? modifyError(error) : error}
            type={type}
            autoFocus={autoFocus}
            disabled={disabled}
            endAdornment={endAdornment && <InputAdornment position="start">{endAdornment}</InputAdornment>}
            multiline={multiline}
            rows={rows}
            minRows={minRows}
            maxRows={maxRows}
            placeholder={placeholder}
            key={forwardKey}
            inputRef={ref}
          />

          <InputErrorOrHint error={error} currentLanguage={currentLanguage} hint={hint} />
        </FormControl>
        {isFocused && options && value?.length > 0 && (
          <ul>
            {filteredOptions?.map(item =>
              renderOption ? (
                <li onClick={() => onOptionClick(item, onChange)} style={{ padding: 0 }}>
                  {renderOption(item)}
                </li>
              ) : (
                <li onClick={() => onOptionClick(item, onChange)}>{item.name}</li>
              ),
            )}
          </ul>
        )}
      </InputStyled>
    );

    return (
      <ConditionalWrapper
        condition={control}
        wrapper={() => (
          <Controller
            control={control}
            name={name}
            rules={rules}
            defaultValue={defaultValue}
            key={forwardKey}
            render={({ field: { onChange, value }, fieldState: { error } }) => renderInput({ error, onChange, value })}
          />
        )}
      >
        {renderInput({ onChange, value })}
      </ConditionalWrapper>
    );
  },
);

export default Input;
