import React from "react";
import PropTypes from "prop-types";
import { ButtonListElement } from "feature/panel/MenusLabels/ButtonsListCategory/ButtonsList/ButtonListElement/ButtonListElement";
import { useSelector } from "react-redux";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";

const ButtonsList = ({ onEditClick }) => {
  const permissionsService = usePermissionsService();
  const { currentOperator } = useSelector(state => state.operator);

  const list = currentOperator.labels ? currentOperator.labels.sort((a, b) => (a.screen < b.screen ? -1 : 1)) : [];

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.operator, PERMISSIONS.resources.operators)

  return list.length > 0 ? list.map(buttonLabel => (
    <ButtonListElement
      button={buttonLabel}
      isEditable={canEdit} 
      onEditClick={() => onEditClick(buttonLabel)}
      key={buttonLabel.screen}
    />
  )) : <LoadingScreen />;
};

ButtonsList.propTypes = {
  onEditClick: PropTypes.func.isRequired,
};

export { ButtonsList };
