import React from "react";
import { copyToClipboard } from "utils";
import { dispatch } from "store/store";
import { setNotification } from "store/app/actions";
import { Button } from "feature/panel/_shared/MapWidget_old/styledComponents";
import CopyIcon from "@material-ui/icons/FileCopy";
import { LOCATIONS_LABELS } from "constants/content";
import { copyFailed, copySuccess } from "constants/notifications";

const CoordinatesButton = ({ latitude, longitude }) => {
  const copyExistingLocation = () => {
    const coords = `${latitude.toFixed(7)}, ${longitude.toFixed(7)}`;
    copyToClipboard(
      coords,
      () => {
        dispatch(
          setNotification({
            type: "success",
            message: copySuccess("coordinates"),
          }),
        );
      },
      () => {
        dispatch(
          setNotification({
            type: "error",
            message: copyFailed("coordinates"),
          }),
        );
      },
    );
  };

  return (
    <Button onClick={copyExistingLocation}>
      <CopyIcon />
      <span>{LOCATIONS_LABELS.copyCoords}</span>
    </Button>
  );
};

export default CoordinatesButton;
