import { StayVariantMapper } from "mappers/StayVariantMapper";
import { StayVariantRepository } from "repositories/StayVariantRepository";
import { Logger } from "../application/Logger";

export class StayVariantService {

  /**
   * @param {import("repositories/StayRepository").StayRepository=} stayRepository 
   * @param {import("mappers/StayVariantMapper").StayVariantMapper=} stayVariantMapper 
   */
   constructor(
    stayVariantRepository = new StayVariantRepository(), 
    stayVariantMapper = new StayVariantMapper(),
  ) {
    /** @type {import("repositories/StayRepository").StayRepository} */
    this.stayVariantRepository = stayVariantRepository;
  

    /** @type {import("mappers/StayVariantMapper").StayVariantMapper} */
    this.stayVariantMapper = stayVariantMapper;
  }

  async getStayVariant(operatorCode, languageCode) {
    try {
      const stayObj = await this.stayVariantRepository.getStayVariant(operatorCode, languageCode);

      return this.stayVariantMapper.fromDtoToDomain(stayObj);
    } catch(e) {
      Logger.debug(e);

      return null;
    }
  }
}