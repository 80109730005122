import { HttpClient } from "services/application/httpClient/httpClient";
import { setUrl } from "utils/url";

import { CURRENT_OPERATOR_URL, OPERATOR_BRANDING_URL, OPERATOR_BRANDS_URL, OPERATOR_BRAND_BY_ID_URL } from "constants/api";

export class BrandRepository {
  constructor() {
    /** @type {HttpClient} */
    this.httpClient = HttpClient;
  }

  /**
   *
   * @returns {OperatorResponseDto}
   */
  async getCurrentOperator() {
    const { data } = await this.httpClient.get(CURRENT_OPERATOR_URL);

    return { ...data, is_default: true };
  }

  /**
   *
   * @param {OperatorRequestDto} body
   * @returns {OperatorResponseDto}
   */
  async updateDefaultBrand(body) {
    const { data } = await this.httpClient.post(CURRENT_OPERATOR_URL, body);

    return data;
  }

  /**
   *
   * @returns {BrandDto[]}
   */
  async getBrandsList() {
    const data = await Promise.all([this.getBrandings(), this.getCurrentOperator()]);

    return [...data[0], data[1]];
  }

  /**
   *
   * @param {number} id
   * @returns {BrandDto}
   */
  async getBrandById(id) {
    const url = setUrl(OPERATOR_BRAND_BY_ID_URL, { branding_profile_id: id });
    const { data } = await this.httpClient.get(url);

    return data;
  }

  /**
   *
   * @param {string} name
   * @returns {BrandDto}
   */
  async createNewBrand(name) {
    const body = { name };
    const { data } = await this.httpClient.post(OPERATOR_BRANDING_URL, body);

    return data;
  }

  /**
   *
   * @param {number} id
   * @param {BrandRequestDto} body
   * @returns {BrandDto}
   */
  async updateBrand(id, body) {
    const url = setUrl(OPERATOR_BRAND_BY_ID_URL, { branding_profile_id: id });
    const { data } = await this.httpClient.post(url, body);

    return data;
  }

  /**
   * @private
   * @returns {OperatorResponseDto}
   */
  async getBrandings() {
    const { data } = await this.httpClient.get(OPERATOR_BRANDS_URL);

    return data?.items || [];
  }
}
