import { responsiveConfig } from "config/responsive";
import styled from "styled-components";

export const HideOnDesktop = styled.div`
  display: none;
  @media (max-width: 600px) {
    display: contents;
  }
`;

export const getPanelPaddingStyles = () => {
  return `
    padding: 20px 40px;

    @media (max-width: ${responsiveConfig.mediaPx.laptop}px) {
      padding: 20px 15px;
    }
  `
}

export const getStickyPanelContentStyles = () => {
  return `
    margin-inline: -40px;
    padding-left: 40px;
    padding-right: 40px;

    @media (max-width: ${responsiveConfig.mediaPx.laptop}px) {
      margin-inline: -15px;
      padding-left: 15px;
      padding-right: 15px;
    }
  `
}