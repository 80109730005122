import { getMediaPx } from "helpers";
import styled from "styled-components";

export const Wrap = styled.div`
  display: grid;
  grid-template-columns: 80px minmax(100px, 300px) 1fr 161px;
  align-items: center;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    grid-template-columns: 60px minmax(100px, 200px) 81px;
  }
`;

export const Weekdays = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    justify-content: flex-start;
  }
`;

export const NameWrap = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Buttons = styled.div`
  display: flex;
`;
