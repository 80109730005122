import React, { useState, useReducer, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import Grid from "@material-ui/core/Grid";
import StepLabel from "@material-ui/core/StepLabel";
import Step from "@material-ui/core/Step";

import { Content } from "components/ui/Content/Content";
import { PrimaryButton, SecondaryButton } from "components/ui/Buttons";
import { StepperContent, StepperActions, Stepper } from "components/ui/Content/Stepper";
import { Spinner } from "components/ui/Spinner/Spinner";
import { StorageService } from "services/StorageService";

import { SignUpContext } from "feature/views/SignUp/SignUpContext";
import { SignUpFormStep1 } from "feature/views/SignUp/SignUpFormStep1";
import { SignUpFormStep2 } from "feature/views/SignUp/SignUpFormStep2";
import { SignUpFormStep3 } from "feature/views/SignUp/SignUpFormStep3";
import { SignUpFormStep4 } from "feature/views/SignUp/SignUpFormStep4";
import { setFormContext } from "feature/views/SignUp/helpers";

import { registerUserWithOperatorStart, registerUserStart, registerOperatorSuccess, setIsInitialAuth } from "store/auth/actions";

import { PANEL_TRIPS_ROOT_PATH, PANEL_USER_SETTINGS, ROOT_PATH, VERIFY_PATH } from "constants/routes";

import { isObjectEmpty } from "utils/object";
import { isString, isObject } from "contracts/types";
import { clearErrorsFor, setNotification, tryRouteChangeStart } from "store/app/actions";
import { ERRORS, GLOBAL_CONTENT } from "constants/content";
import { RegisterValidationService } from "services/domain/RegisterValidator";
import { HttpClient } from "../../../services/application/httpClient/httpClient";
import { CREATE_OPERATOR_URL } from "../../../constants/api";
import { TabSessionService } from "../../../services/TabSessionService";
import { OperatorMapper } from "../../../mappers/OperatorMapper";

const editStateReducer = (state, action) => {
  switch (action.type) {
    case "setValueFor": {
      return {
        ...state,
        [action.fieldName]: action.value,
      };
    }
    case "setAllValues": {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

const userInitialState = {
  username: "",
  email: "",
  password: "",
};

const operatorInitialState = {
  code: "",
  name: "",
  terms_accepted_at: false,
  labels: [],
};

const metaInitialState = {
  company_type: "",
  number_of_bookings: "",
  number_of_passengers: "",
  source_knowledge_about_us: "",
  contact_person: "",
  telephone: "",
  description: "",
  website: "",
};

const SignUpForm = ({ createOperator }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validationService = new RegisterValidationService();

  const { inProgress, finished, errors, isInitialAuth } = useSelector(reduxState => reduxState.auth);

  const [activeStep, setActiveStep] = useState(isInitialAuth ? 1 : 0);
  const [allowForNextStep, setAllowForNextStep] = useState(false);
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const steps = createOperator ? [1, 2, 3] : [1, 2, 3, 4];
  const numUnnecessaryCreateOperatorSteps = 1;

  const [userState, userDispatch] = useReducer(editStateReducer, userInitialState);
  const [operatorState, operatorDispatch] = useReducer(editStateReducer, operatorInitialState);
  const [metaState, metaDispatch] = useReducer(editStateReducer, metaInitialState);

  const { terms_accepted_at: terms } = operatorState;
  const { company_type: companyType } = metaState;
  const storageService = new StorageService();
  const tabSessionService = new TabSessionService(storageService);
  const operatorMapper = new OperatorMapper();

  const { mutate, isLoading } = useMutation(
    payload => {
      return HttpClient.post(CREATE_OPERATOR_URL, payload);
    },
    {
      onSuccess: async ({ data }) => {
        const operatorDomain = operatorMapper.fromDtoToDomain(data);
        const operatorData = {
          operators: [operatorDomain],
          currentOperatorCode: operatorDomain.code,
        };

        await tabSessionService.setGlobalOperatorCode(operatorData.currentOperatorCode);
        await tabSessionService.setLocalOperatorCode(operatorData.currentOperatorCode);

        dispatch(setIsInitialAuth(false));
        dispatch(registerOperatorSuccess(operatorData));
        dispatch(tryRouteChangeStart(PANEL_TRIPS_ROOT_PATH));
        navigate(PANEL_TRIPS_ROOT_PATH);
      },
      onError: ({
        response: {
          data: { error },
        },
      }) => dispatch(setNotification({ type: "error", message: error ?? ERRORS.unknownError })),
    },
  );

  const setValueFor = (fieldName, value, context) => {
    const dispatches = {
      user: params => userDispatch(params),
      operator: params => operatorDispatch(params),
      meta: params => metaDispatch(params),
    };
    const parameters = {
      type: "setValueFor",
      fieldName,
      value,
    };
    dispatches[context](parameters);
  };

  const handleChangeStep = step => setActiveStep(prevActiveStep => prevActiveStep + step);
  const handleNext = () => handleChangeStep(companyType === "user" && activeStep === 1 ? 2 : 1);
  const handleBack = () => handleChangeStep(companyType === "user" && activeStep === 3 ? -2 : -1);

  const handleSubmit = () => {
    if (companyType === "user") {
      if (isInitialAuth) {
        dispatch(tryRouteChangeStart(PANEL_USER_SETTINGS));
        dispatch(setIsInitialAuth(false));
        navigate(PANEL_USER_SETTINGS);
      } else {
        dispatch(registerUserStart({ ...userState }));
      }
    } else if (createOperator || isInitialAuth) {
      mutate({
        ...operatorState,
        terms_accepted_at: terms ? new Date() : false,
        meta: metaState,
      });
    } else {
      dispatch(
        registerUserWithOperatorStart({
          user: userState,
          operator: {
            ...operatorState,
            terms_accepted_at: terms ? new Date() : false,
            meta: metaState,
          },
        }),
      );
    }
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    const contexts = {
      user: userState,
      operator: operatorState,
      meta: metaState,
    };

    const context = setFormContext(name, contexts);
    if (context) {
      const parsedValue = name === "code" ? value.replaceAll("-", "") : value;
      setValueFor(name, parsedValue, context);
    }
  };

  const stepContent = [
    <SignUpFormStep1 />,
    <SignUpFormStep2 createOperator={createOperator} />,
    <SignUpFormStep3 errors={errors} />,
    <SignUpFormStep4 errors={errors} />,
  ];
  const getStepContent = createOperator ? stepContent.slice(numUnnecessaryCreateOperatorSteps) : stepContent;

  const contextData = {
    passwordConfirmation,
    setPasswordConfirmation,
    user: userState,
    operator: {
      ...operatorState,
      meta: metaState,
    },
    setValueFor,
    handleChange,
    validationService,
    canGoNext: setAllowForNextStep,
  };

  const nextStepDisabled = !allowForNextStep || !!errors.username || !!errors.email || !!errors.operator_code;

  const clearErrorsOnUnmount = () => {
    dispatch(clearErrorsFor("auth"));
    return () => {
      dispatch(clearErrorsFor("auth"));
    };
  };

  useEffect(clearErrorsOnUnmount, []);
  useEffect(() => {
    const errorSectionNames = ["user", null, "operator"];

    if (isString(errors) && errors.length > 0) {
      setActiveStep(0);
    } else if (isObject(errors) && !isObjectEmpty(errors)) {
      const errorsKeysArray = Object.keys(errors);
      let isStepSet = false;
      errorSectionNames.forEach((el, index) => {
        if (errorsKeysArray.includes(el) && !isStepSet) {
          setActiveStep(index);
          isStepSet = true;
        }
      });
    }
  }, [errors]);

  return (
    <>
      {finished && !createOperator && !isInitialAuth && <Navigate to={ROOT_PATH} />}
      <Grid container>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Content margin={12}>
              <Stepper activeStep={activeStep}>
                {steps.map(v => (
                  <Step key={v}>
                    <StepLabel />
                  </Step>
                ))}
              </Stepper>
            </Content>
          </Grid>
          <Grid item xs={12}>
            <StepperContent>
              <Grid container spacing={8}>
                <Grid item xs={12}>
                  <SignUpContext.Provider value={contextData}>{getStepContent[activeStep]}</SignUpContext.Provider>
                </Grid>
              </Grid>
            </StepperContent>
          </Grid>
          <Grid item xs={12}>
            <StepperActions>
              <Grid container spacing={3}>
                {activeStep === 0 && (
                  <Grid item xs={12}>
                    <PrimaryButton data-testid="signup-next-button" fullWidth size="large" onClick={handleNext} disabled={nextStepDisabled}>
                      {GLOBAL_CONTENT.next}
                    </PrimaryButton>
                  </Grid>
                )}
                {activeStep > 0 && (
                  <>
                    <Grid item xs={6}>
                      <SecondaryButton
                        data-testid="signup-back-button"
                        fullWidth
                        size="large"
                        onClick={handleBack}
                        disabled={activeStep === 1 && isInitialAuth}
                      >
                        {GLOBAL_CONTENT.back}
                      </SecondaryButton>
                    </Grid>
                    <Grid item xs={6}>
                      {activeStep === steps.length - 1 ? (
                        <Content margin={20}>
                          <PrimaryButton
                            data-testid="signup-next-button"
                            fullWidth
                            size="large"
                            onClick={handleSubmit}
                            disabled={nextStepDisabled}
                          >
                            {inProgress || isLoading ? <Spinner size={28} cv="white" /> : "register"}
                          </PrimaryButton>
                        </Content>
                      ) : (
                        <PrimaryButton
                          data-testid="signup-next-button"
                          fullWidth
                          size="large"
                          onClick={handleNext}
                          disabled={nextStepDisabled}
                        >
                          {GLOBAL_CONTENT.next}
                        </PrimaryButton>
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
            </StepperActions>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

SignUpForm.defaultProps = {
  createOperator: false,
};

SignUpForm.propTypes = {
  createOperator: PropTypes.bool,
};

export { SignUpForm };
