import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import styles from "config/theme";
import { useWindowResize } from "hooks/useWindowResize";
import { createDateTimeParagraph } from "utils/createDateTimeParagraph";
import { createThreadTitle } from "feature/panel/_shared/Messaging/shared/createThreadTitle";
import { BodyText2, BodyText3 } from "components/ui/Typography/Typography";
import { TextLimiter } from "components/ui/TextLimiter";
import { MESSAGING } from "constants/defaults";

const { VIEWPORT_BREAK_SIZE } = MESSAGING;
const { semiBold, default: defaultFontWeight } = styles.fonts.fontWeight;

const ThreadTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.setSpacing(2)}px;
`;

const InnerContainer = styled.div`
  padding: ${({ theme }) => theme.setSpacing(5)}px ${({ theme }) => theme.setSpacing(7)}px;
  background-color: ${props => (props.active ? styles.colors.withOpacity(styles.colors.brand, 0.3) : "none")};
  border-left: ${props => (props.active ? `2px solid ${styles.colors.brand}` : "none")};
  cursor: pointer;
`;

const ThreadTitle = styled(({ read, active, ...rest }) => <BodyText2 {...rest} />)`
  && {
    font-weight: ${({ read, active }) => (!active && !read ? semiBold : defaultFontWeight)};
    color: ${({ read, active }) => (read && !active ? styles.colors.grey50 : styles.colors.black)};
  }
`;

const TextPreviewParagraph = styled(({ read, active, normalWeight, align, ...rest }) => <BodyText3 {...rest} />)`
  && {
    text-align: ${({ align }) => align || "left"};
    font-weight: ${({ read, active, normalWeight }) => (!active && !read && !normalWeight ? semiBold : defaultFontWeight)};
    color: ${({ read, active }) => (read && !active ? styles.colors.grey50 : styles.colors.black)};
  }
`;

const TEXT_DEFAULTS = {
  MESSAGE_TEXT_LENGTH: {
    short: 30,
  },
  TITLE_TEXT_LENGTH: {
    short: 17,
  },
};

const shrinkTitleText = () => (window.screen.width > VIEWPORT_BREAK_SIZE ? null : TEXT_DEFAULTS.TITLE_TEXT_LENGTH.short);
const ThreadBar = ({ id, data, displayed, onElementClick, active }) => {
  const titleTextLength = useWindowResize(shrinkTitleText);
  const { name, device, messages, friendlyName } = data;
  const lastMessage = messages[messages.length - 1];
  const messageTitle = friendlyName || createThreadTitle({ name, device });

  return (
    <InnerContainer active={active} onClick={() => onElementClick(id)}>
      <ThreadTitleContainer>
        <ThreadTitle read={!displayed} active={active}>
          <TextLimiter text={messageTitle} maxChar={titleTextLength || messageTitle.length} />
        </ThreadTitle>
        <TextPreviewParagraph align="right" read={!displayed} active={active}>
          {createDateTimeParagraph(lastMessage.dateSent)}
        </TextPreviewParagraph>
      </ThreadTitleContainer>
      <TextPreviewParagraph read={!displayed} active={active} normalWeight>
        <TextLimiter text={lastMessage.content} maxChar={TEXT_DEFAULTS.MESSAGE_TEXT_LENGTH.short} />
      </TextPreviewParagraph>
    </InnerContainer>
  );
};

ThreadBar.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.shape().isRequired,
  onElementClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  displayed: PropTypes.bool.isRequired,
};

export { ThreadBar, ThreadTitleContainer, ThreadTitle, InnerContainer };
