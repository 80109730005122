import React from "react";
import PropTypes from "prop-types";
import { BodyText } from "components/ui/Typography/Typography";

import Tooltip from "@material-ui/core/Tooltip";

const ContextBarTitle = ({ title }) => <BodyText><Tooltip title={title}><span>{title}</span></Tooltip></BodyText>;

ContextBarTitle.defaultProps = {
  title: "",
};

ContextBarTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

export { ContextBarTitle };
