import * as actionTypes from "store/branding/actionTypes";
import { createAction } from "utils/store";

export const getBrandingListStart = createAction(actionTypes.GET_BRANDING_LIST_START);
export const getBrandingListSuccess = createAction(actionTypes.GET_BRANDING_LIST_SUCCESS);
export const getBrandingListFail = createAction(actionTypes.GET_BRANDING_LIST_FAIL);

export const getDefaultBrandingDataStart = createAction(actionTypes.GET_DEFAULT_BRANDING_DATA_START);
export const getSelectedBrandDataStart = createAction(actionTypes.GET_SELECTED_BRANDING_DATA_START);
export const getSelectedBrandDataSuccess = createAction(actionTypes.GET_SELECTED_BRANDING_DATA_SUCCESS);
export const getSelectedBrandDataFail = createAction(actionTypes.GET_SELECTED_BRANDING_DATA_FAIL);

export const createNewBrandStart = createAction(actionTypes.CREATE_NEW_BRAND_START);
export const createNewBrandSuccess = createAction(actionTypes.CREATE_NEW_BRAND_SUCCESS);
export const createNewBrandFail = createAction(actionTypes.CREATE_NEW_BRAND_FAIL);

export const setValueForCreationHeaderStart = createAction(actionTypes.SET_VALUE_FOR_CREATION_HEADER_START);
export const setValueForCreationFooterStart = createAction(actionTypes.SET_VALUE_FOR_CREATION_FOOTER_START);
export const setValueForLogoStart = createAction(actionTypes.SET_VALUE_FOR_LOGO_START);

export const setValueForDefaultCreationHeaderStart = createAction(actionTypes.SET_VALUE_FOR_DEFAULT_CREATION_HEADER_START);
export const setValueForDefaultCreationFooterStart = createAction(actionTypes.SET_VALUE_FOR_DEFAULT_CREATION_FOOTER_START);
export const setValueForDefaultLogoStart = createAction(actionTypes.SET_VALUE_FOR_DEFAULT_LOGO_START);

export const setValueForCreationLinkStart = createAction(actionTypes.SET_VALUE_FOR_CREATION_LINK_START);
export const setValueForDefaultCreationLinkStart = createAction(actionTypes.SET_VALUE_FOR_DEFAULT_CREATION_LINK_START);

export const updateBrandItemSuccess = createAction(actionTypes.UPDATE_BRAND_ITEM_SUCCESS);
export const updateBrandItemFail = createAction(actionTypes.UPDATE_BRAND_ITEM_FAIL);

export const clearBrandingForm = createAction(actionTypes.CLEAR_BRANDING_FORM);

export const setValueForIsActive = createAction(actionTypes.SET_VALUE_FOR_IS_ACTIVE);
