import React, { useContext } from "react";
import PropTypes from "prop-types";

import { DeleteAction, EditAction, SwitchAction, TableActionsContainer } from "components/ui/Tables/TableRowActions";
import { ActionsContext } from "feature/panel/Notifications/ActionsContext";
import { TABLE_ACTIONS_TITLES } from "constants/content";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { getNotificationEmptyFields } from "../Itinerary/pages/Trips/Notifications/helpers";

const NotificationsRowActions = ({ item }) => {
  const permissionsService = usePermissionsService();
  const { updateDefaultActiveStatus, onEditClick, handleDeleteOpen } = useContext(ActionsContext);
  const handleEdit = () => onEditClick(item, true);
  const handleDelete = () => handleDeleteOpen(item);

  const handleToggleDefaultActiveStatus = () =>  {
    const emptyFields = getNotificationEmptyFields(item);
    if (emptyFields && emptyFields?.length > 0 ) {
      onEditClick(item);
    } else {
      item.is_default_on = !item.is_default_on;
      updateDefaultActiveStatus(item);
    }
  };


  return (
    <TableActionsContainer>
      {permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.operator, PERMISSIONS.resources.notifications) && (
        <>
          {item.type !== "forced" && <SwitchAction clickAction={handleToggleDefaultActiveStatus} checked={!!item.is_default_on} />}
          <EditAction
            clickAction={handleEdit}
            tooltip={TABLE_ACTIONS_TITLES.editResource(TABLE_ACTIONS_TITLES.resourceTypes.template)}
          />
        </>
      )}
      {permissionsService.can(PERMISSIONS.actions.delete, PERMISSIONS.sections.operator, PERMISSIONS.resources.notifications) && (
        <DeleteAction
          clickAction={handleDelete}
          tooltip={TABLE_ACTIONS_TITLES.deleteResource(TABLE_ACTIONS_TITLES.resourceTypes.template)}
        />
      )}
    </TableActionsContainer>
  );
};
NotificationsRowActions.propTypes = {
  item: PropTypes.shape().isRequired,
};

export { NotificationsRowActions };
