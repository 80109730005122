import styled from "styled-components";
import { SecondaryButton } from "components/ui/Buttons/SecondaryButton";
import { Content } from "components/ui/Content";

export const FlightFormWrapper = styled(Content)`
  display: grid;
  grid-template-columns: ${({ showManualForm }) => (showManualForm ? "repeat(3, 1fr)" : "repeat(4, 1fr)")};
  /* max-width: 800px; */
  gap: 15px;
  margin-bottom: 15px;
`;

export const MarginedAInputWrapper = styled.div`
  width: 100%;
`;

export const SearchButton = styled(SecondaryButton)`
  && {
    min-width: 132px;
  }
`;

export const SpinnerSection = styled.div`
  display: flex;
  width: 100%;
  height: ${({ theme }) => theme.setSpacing(20)}px;
  justify-content: center;
`;
