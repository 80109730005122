import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Grid, Paper } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";

import { Table, TableBody, TableHeader, TablePagination } from "components/ui/Tables";
import { EditAction } from "components/ui/Tables/TableRowActions";
import { CustomiseNestedStay } from "feature/panel/_shared/CustomiseNestedStay/CustomiseNestedStay";
import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";
import { NoResultsMessage } from "components/ui/Messages";

import { GET_NESTED_ITINERARIES_URL } from "constants/api";
import { useTableHandlers } from "hooks/useTableHandlers";
import * as nestedActionTypes from "store/nested/actionTypes";
import { checkQueryParamExistence } from "utils/url";
import { isFunction } from "contracts/types";
import { NO_RESULTS_FOUND_MESSAGE, SETTINGS_STAYS } from "constants/content";
import { getNestedItinerariesStart } from "store/nested/actions";
import { getPublicStaysStart } from "store/trips/actions";

import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

import MapWidget from "feature/panel/_shared/MapWidget";

import { StaysTableControls } from "./StaysTableControls";
import { StaysTableRow } from "./StaysTableRow";

const headers = [
  { label: SETTINGS_STAYS.table.userId, key: "operatorCode", show: true, sortable: true },
  { label: SETTINGS_STAYS.table.propertyName, key: "name", show: true, sortable: true },
  { label: SETTINGS_STAYS.table.shortDescription, key: "shortDescription", show: true, sortable: false },
  { label: SETTINGS_STAYS.table.lognDescription, key: "longDescription", show: true, sortable: false },
];

const TableContainer = styled(Paper)`
  && {
    border-radius: ${({ theme }) => theme.borderRadius}px;
  }
`;

const Stays = () => {
  const permissionsService = usePermissionsService();
  const dispatch = useDispatch();
  const stays = useSelector(state => state.nested.listOfNested);
  const count = useSelector(state => state.nested.nestedCount);
  const isLoading = useSelector(state => state.nested.actionType === nestedActionTypes.GET_NESTED_ITINERARIES_START);

  const [countryCode, setCountryCode] = useState(null);

  const {
    handleChangeOrder,
    handleChangeRowsPerPage,
    handleChangePage,
    handleChangeParams,
    handleSearch,
    forceQuery,
    url,
  } = useTableHandlers(GET_NESTED_ITINERARIES_URL, getNestedItinerariesStart, {
    customSearchParam: "q",
    defaultOrderBy: "operatorCode",
    defaultOrder: "asc",
    params: {
      country: countryCode,
    },
  });

  const [editNestedStayModal, setEditNestedStayModal] = useState({
    open: false,
    body: null,
  });

  const handleCloseEditModal = () => {
    setEditNestedStayModal({
      open: false,
      body: null,
    });
    forceQuery();
  };

  const handleOpenEditModal = (item, marker, closeInfoWindow) => {
    setEditNestedStayModal({
      open: true,
      body: {
        name: item.name,
        nested: item.stay || item, // handle event from map and list
      },
    });
    if (closeInfoWindow && isFunction(closeInfoWindow)) closeInfoWindow();
  };

  const renderActiveTripActions = ({ item }) => {
    return (
      permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.operator, PERMISSIONS.resources.operators) && (
        <>
          <EditAction
            clickAction={() => {
              handleOpenEditModal(item);
            }}
            tooltip={SETTINGS_STAYS.editStaysDescription}
          />
        </>
      )
    );
  };

  const handleStaySearch = useRef(
    debounce(query => {
      handleSearch(query, true);
    }, 300),
  );

  const handleCountryChange = code => {
    setCountryCode(code);
    handleChangeParams({
      country: code,
    });
  };

  /* For map */

  const publicStays = useSelector(state => state.trips?.publicStays);
  const [mapStays, setMapStays] = useState([]);
  const [mapSearch, setMapSearch] = useState("");

  const handleMapSearch = useRef(
    debounce(value => {
      setMapStays([]);
      setMapSearch(value);
    }, 300),
  );

  const getPublicStays = () => {
    dispatch(getPublicStaysStart());
  };

  useEffect(getPublicStays, []);

  useEffect(() => {
    if (mapSearch) {
      const lowerCaseQuery = mapSearch.toLocaleLowerCase();
      setMapStays(
        publicStays.filter(
          stay => stay.name.toLowerCase().includes(lowerCaseQuery) || stay.operator_code.toLowerCase().includes(lowerCaseQuery),
        ),
      );
    } else {
      setMapStays(publicStays);
    }
  }, [mapSearch, publicStays]);

  const canReadNestedStays = permissionsService.can(
    PERMISSIONS.actions.read,
    PERMISSIONS.sections.operator,
    PERMISSIONS.resources.operators,
  );

  const content = (() => {
    return (
      <>
        <MapWidget
          data={{ stays: mapStays }}
          onSearch={handleMapSearch.current}
          switchControls={["placeholder"]}
          searchPlaceholder="Search stays to add"
          isSearching={mapSearch}
          infoWindowsProps={{
            stays: {
              handleOpenEditModal,
            },
          }}
          disableRightClick
          noGoogle
        />
        {canReadNestedStays && (
          <>
            <TableContainer>
              <StaysTableControls onSearchChange={handleStaySearch.current} onCountryChange={handleCountryChange} />
              {stays.length && !isLoading ? (
                <Table
                  headers={headers}
                  list={stays}
                  actions={renderActiveTripActions}
                  onChangeOrder={handleChangeOrder}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  isDataLoading={isLoading}
                  noPaper
                >
                  <TableHeader withActions />
                  <TableBody rowComponent={StaysTableRow} />
                  <TablePagination count={count} />
                </Table>
              ) : isLoading ? (
                <LoadingScreen />
              ) : (
                <NoResultsMessage height="120px">
                  {checkQueryParamExistence(url, "q") ? NO_RESULTS_FOUND_MESSAGE : SETTINGS_STAYS.pleaseSelectStay}
                </NoResultsMessage>
              )}
            </TableContainer>

            <CustomiseNestedStay isOpen={editNestedStayModal.open} stay={editNestedStayModal.body} onClose={handleCloseEditModal} />
          </>
        )}
      </>
    );
  })();

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          {content}
        </Grid>
      </Grid>
    </>
  );
};

export { Stays };
