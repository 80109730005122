// @ts-check

export class LabelMapper {
  /**
   *
   * @param {LabelResponseDto} labelDto - user object
    * @returns {import("domain/Label").Label} Label item
   */
  // eslint-disable-next-line class-methods-use-this
  fromDtoToDomain(labelDto) {
    /**
     * @type {import("domain/Label").Label}
     */
     const domain = {};
  
     domain.iconId = labelDto.icon_id;
     domain.icon_id = labelDto.icon_id;
     domain.screen = labelDto.screen;
     domain.label = labelDto.label;
 
     domain.localisation = labelDto.localisation ? labelDto.localisation : {};
   
     return domain;
  }

  /**
   *
   * @param {import("domain/Label").Label} domain
   * @returns {object}
   */
  // eslint-disable-next-line class-methods-use-this
  fromDomainToDto(domain) {
    const dto = {};

    dto.icon_id = domain.iconId || domain.icon_id;
    dto.label = domain.label;
    dto.screen = domain.screen;
    dto.localisation = domain.localisation;

    return dto;
  }
}
