import { setSpacing } from "config/theme/spacing";

const inputs = {
  variant: "outlined",
  borderRadius: setSpacing(2),
  small: {
    height: setSpacing(8),
    inputHeight: setSpacing(4),
    fontSize: setSpacing(3),
    padding: {
      top: setSpacing(2),
      right: setSpacing(3),
      bottom: setSpacing(2),
      left: setSpacing(3) + 1.5,
    },
  },

  medium: {
    height: setSpacing(10),
    inputHeight: setSpacing(5),
    fontSize: setSpacing(4),
    padding: {
      top: setSpacing(2.5),
      right: setSpacing(4),
      bottom: setSpacing(2.5),
      left: setSpacing(4) - 2,
    },
  },

  large: {
    height: setSpacing(12),
    inputHeight: setSpacing(6),
    fontSize: setSpacing(4),
    padding: {
      top: setSpacing(3),
      right: setSpacing(4),
      bottom: setSpacing(3),
      left: setSpacing(4) - 2,
    },
  },
};
const forms = {
  inputs,
};
export { forms, inputs };
