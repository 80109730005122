import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";

import { FlightsListSection } from "feature/panel/Trips/_shared/Flights/FlightsListSection";
import { FlightsSearchSection } from "feature/panel/Trips/_shared/Flights/FlightsSearchSection";
import { StickyHeaderFormSection } from "components/ui/Forms";
import { SecondaryButton } from "components/ui/Buttons";
import { CREATE_CONTENT_LABELS } from "constants/content";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { useManageTripContext } from "../ManageTripContext";

const Flights = () => {
  const { vamoos_id, editMode, is_wiped } = useManageTripContext();
  const [flightModalOpen, setFlightModalOpen] = useState(false);
  const permissionsService = usePermissionsService();

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoos_id);

  const handleFlightModalOpen = () => {
    setFlightModalOpen(true);
  };
  const handleFlightModalClose = () => {
    setFlightModalOpen(false);
  };

  const AddFlightButton = () =>
    (editMode ? canEdit : true) && (
      <SecondaryButton disabled={is_wiped} onClick={handleFlightModalOpen}>
        <AddIcon />
        {CREATE_CONTENT_LABELS.flight}
      </SecondaryButton>
    );

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={10}>
        <StickyHeaderFormSection title="Flights" headerActions={AddFlightButton}>
          <FlightsListSection />
          <FlightsSearchSection open={flightModalOpen} onCancel={handleFlightModalClose} />
        </StickyHeaderFormSection>
      </Grid>
    </Grid>
  );
};

export { Flights };
