import { Brand } from "../domain/Brand";
import { BrandRequestDto } from "../dto/BrandRequestDto";
import { BrandSectionMapper } from "./BrandSectionMapper";

export class BrandMapper {
  /**
   *
   * @param {import(mappers/BrandSectionMapper).BrandSectionMapper} brandSection
   */
  constructor(brandSection = new BrandSectionMapper()) {
    this.brandSectionMapper = brandSection;
  }

  /**
   *
   * @param {import(types/dto).BrandDto} dto
   * @returns {Brand}
   */
  // eslint-disable-next-line class-methods-use-this
  fromDtoToDomain(dto) {
    const domain = new Brand();

    domain.id = dto.id;
    domain.name = dto.name;
    domain.logo = dto.logo ? this.brandSectionMapper.fromDtoToDomain(dto.logo) : null;
    domain.creationsHeader = dto.creations_header ? this.brandSectionMapper.fromDtoToDomain(dto.creations_header) : null;
    domain.creationsFooter = dto.creations_footer ? this.brandSectionMapper.fromDtoToDomain(dto.creations_footer) : null;
    domain.instantViewLabel = dto.meta?.creation_instant_view_link_label ?? "View this itinerary online with interactive maps and weather";
    domain.instantViewMapsLabel = dto.meta?.creation_instant_view_maps_link_label ?? "For further details, view the interactive map online";
    domain.createdAt = dto.created_at;
    domain.updatedAt = dto.updated_at;
    domain.isActive = dto.is_active;
    domain.isDefault = !!dto?.is_default;

    return domain;
  }

  /**
   * @param {Brand} domain
   * @returns {BrandRequestDto}
   */
  // eslint-disable-next-line class-methods-use-this
  fromDomainToDto(domain) {
    const dto = new BrandRequestDto();

    dto.name = domain.name;
    dto.creations_header = domain.creationsHeader ? this.brandSectionMapper.fromDomainToDto(domain.creationsHeader) : null;
    dto.creations_footer = domain.creationsFooter ? this.brandSectionMapper.fromDomainToDto(domain.creationsFooter) : null;
    dto.logo = domain.logo ? this.brandSectionMapper.fromDomainToDto(domain.logo) : null;
    dto.meta = {
      creation_instant_view_link_label: domain.instantViewLabel ?? "View this itinerary online with interactive maps and weather",
      creation_instant_view_maps_link_label: domain.instantViewMapsLabel ?? "For further details, view the interactive map online"
    }

    return dto;
  }
}
