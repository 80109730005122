// @ts-check

/**
 * @param {User} userDomain
 * @returns {UserDetailsRequestDto} user request dto
 */
export function UserDetailsRequestDto(userDomain) {
  /**
   * @type {UserDetailsRequestDto}
   */
  const dto = {};

  dto.username = userDomain.username;
  dto.email = userDomain.email;
  dto.user_is_active = userDomain.userIsActive;

  return dto;
}
