import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import Input from "components/ui/Inputs/TextInput";

import { Content } from "components/ui/Content/Content";
import { Spinner } from "components/ui/Spinner/Spinner";
import { PrimaryButton } from "components/ui/Buttons";
import { Link } from "components/ui/Links/Links";
import {
  PASSWORD_RESET_PATH,
  PANEL_ROOT_PATH,
  REGISTER_PATH,
  ADMIN_ROOT_PATH,
  ADMIN_OPERATORS_PATH,
  PANEL_USER_SETTINGS,
  VERIFY_PATH
} from "constants/routes";
import { LS_LAST_VISITED_PAGE_KN, LS_TOKEN_KN } from "constants/localstorage";

import { BodyText3 } from "components/ui/Typography/Typography";
import { OPERATOR_TYPE } from "constants/content";
import { StorageService } from "services/StorageService";
import { useForm } from "react-hook-form";
import { HttpClient } from "services/application/httpClient/httpClient";
import { useMutation } from "@tanstack/react-query";
import { TabSessionService } from "services/TabSessionService";
import { UserMapper } from "mappers/UserMapper";
import { dispatch } from "store/store";
import { loginSuccess } from "store/auth/actions";
import { setNotification, tryRouteChangeStart } from "store/app/actions";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";

import { AuthWrapper, LoggingActionsWrapper, OrText, OrWrapper } from "./style";
import { inputs } from "./inputs";
import { LOGIN_URL } from "../../../../constants/api";
import Checkbox from "../../../../components/ui/Inputs/Checkbox/index";
import SSOAuth from "./SSOAuth";
import { deviceType } from "../../../../utils/deviceDetect";

const SignInFormComponent = ({ currentOperatorCode, operators }) => {
  const [cookies, setCookie, removeCookie] = useCookies([LS_TOKEN_KN]);
  const { control, handleSubmit, watch } = useForm();
  const needsVerification = useSelector(state => state.auth.requireVerification);

  const storageService = new StorageService();
  const tabSessionSerivce = new TabSessionService(storageService);
  const userMapper = new UserMapper();

  const navigate = useNavigate();
  const { username, password } = watch();
  const device = deviceType();

  const setLoginRedirectPath = redirectPath => {
    const isAdminOperator = operators?.find(({ code }) => code === currentOperatorCode)?.type === OPERATOR_TYPE.admin;

    if (isAdminOperator) {
      return redirectPath?.startsWith(ADMIN_ROOT_PATH) ? redirectPath : ADMIN_OPERATORS_PATH;
    }

    return redirectPath?.startsWith(PANEL_ROOT_PATH) ? redirectPath : PANEL_ROOT_PATH;
  };

  const remeberMe = watch("rememberMe");

  const redirectAfterLogin = isInitialAuth => {
    if (isInitialAuth) {
      navigate(REGISTER_PATH);
      localStorage.removeItem("linkToRedirect");
      return;
    }

    if (needsVerification) {
      navigate(VERIFY_PATH);
      localStorage.removeItem("linkToRedirect");
      return;
    }

    // if user tried to visit page while
    // he was logged out
    // we add link he tried to visit to LS
    // and here we get it
    const linkToRedirect = localStorage.getItem("linkToRedirect");

    if (linkToRedirect) {
      navigate(linkToRedirect);
      localStorage.removeItem("linkToRedirect");
      return;
    }

    storageService
      .getPropertyByName(LS_LAST_VISITED_PAGE_KN)
      .then(value => {
        const redirectPath = setLoginRedirectPath(value);

        navigate(redirectPath);
      })
      .catch(() => {
        const redirectPath = setLoginRedirectPath("");

        navigate(redirectPath);
      });
  };

  const onSuccessHandler = async (user, token, isInitialAuth) => {
    const userDomain = userMapper.fromDtoToDomain(user);
    const { operators } = userDomain;

    const userData = { user, operators, token };
    if (operators.length > 0) {
      await tabSessionSerivce.setGlobalOperatorCode(operators[0].code);
      await tabSessionSerivce.setLocalOperatorCode(operators[0].code);
      userData.currentOperatorCode = operators[0].code;
    }

    if (operators.length === 0) dispatch(tryRouteChangeStart(isInitialAuth ? REGISTER_PATH : PANEL_USER_SETTINGS));

    dispatch(loginSuccess(userData));
    redirectAfterLogin(isInitialAuth);
  }

  const { mutate, isLoading } = useMutation(
    values => {
      return HttpClient.post(LOGIN_URL, values);
    },
    {
      onSuccess: async ({ data: { user, key, require_verification }, status }) => {
        const userDomain = userMapper.fromDtoToDomain(user);
        const { operators } = userDomain;

        if (remeberMe) {
          localStorage.setItem("rememberMeVamoos", true);
          await tabSessionSerivce.setToken(key);
        } else {
          setCookie(LS_TOKEN_KN, key);
        }

        const userData = { user: userDomain, operators, token: key, require_verification };

        if (operators.length > 0) {
          await tabSessionSerivce.setGlobalOperatorCode(operators[0].code);
          await tabSessionSerivce.setLocalOperatorCode(operators[0].code);
          userData.currentOperatorCode = operators[0].code;
        }

        if (operators.length === 0) dispatch(tryRouteChangeStart(PANEL_USER_SETTINGS));
        dispatch(loginSuccess(userData));

        if (status !== 201 && !require_verification) {
          redirectAfterLogin();
        }
      },
      onError: ({
        response: {
          data,
        },
      }) => {
        dispatch(setNotification({ type: "error", message: data?.error ?? "Something went wrong" }))
      }
    },
  );

  const onSubmit = ({ username, password }) => mutate({ username, password });

    return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      {inputs.map(item => (
        <Content key={item.key}>
          <Input {...item} control={control} />
        </Content>
      ))}
      <LoggingActionsWrapper>
        <Checkbox
          control={control}
          name="rememberMe"
          label="Remember me"
          style={device === "mobile" ? { fontSize: "10px"} : ""}
          labelStyle={device === "mobile" ? { marginLeft: "-4px" } : ""}
        />
        <Link to={PASSWORD_RESET_PATH} size={device === "mobile" ? "smaller" : "small"} cv="grey100">
          Forgot your password?
        </Link>
      </LoggingActionsWrapper>
      <Content margin={3}>
        <PrimaryButton fullWidth size="large" type="submit" disabled={!username || !password || isLoading}>
          {isLoading ? <Spinner cv="white" size={28} /> : "Log In"}
        </PrimaryButton>
      </Content>
      <AuthWrapper>
        <OrWrapper>
          <OrText>OR</OrText>
        </OrWrapper>
        <SSOAuth
          onSuccessHandler={onSuccessHandler}
          tabSessionSerivce={tabSessionSerivce}
        />
      </AuthWrapper>
      <BodyText3>
        {"If you don’t currently have an account "}
        <Link to={REGISTER_PATH} size="small" cv="grey100">
          sign up
        </Link>
      </BodyText3>
    </form>
  );
};

SignInFormComponent.defaultProps = {
  currentOperatorCode: null,
  operators: [],
};

SignInFormComponent.propTypes = {
  currentOperatorCode: PropTypes.string,
  operators: PropTypes.arrayOf(PropTypes.shape()),
};

export default SignInFormComponent;

// lines before refactor 191
