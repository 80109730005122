import React from "react";
import { Input } from "components/ui/Forms";
import { DatePicker } from "components/ui/Forms/DatePickers";
import SearchableSelect from "../../../../../../components/ui/Forms/Select/index";

export const inputs = [
  {
    key: 0,
    element: <SearchableSelect creatable />,
    rules: { required: { value: true, message: "This field is required" } },
    props: {
      label: "Airline code",
      type: "text",
      name: "carrier_code",
      getOptionLabel: option => option.label || "",
      // onChange: (changeFunc, value) => changeFunc(value),
    },
  },
  {
    key: 1,
    element: <Input />,
    rules: { required: { value: true, message: "This field is required" } },
    props: {
      label: "Flight number",
      type: "number",
      name: "carrier_flight_number",
      hideControls: true,
      size: "large",
    },
  },
  {
    key: 2,
    element: <DatePicker />,
    props: {
      label: "Flight date",
      name: "date",
      autoOk: true,
      size: "large",
    },
  },
];
