import { font } from "config/theme/fonts";
import { getMediaPx } from "helpers";
import styled from "styled-components";

export const Text = styled.div`
  font-family: ${font};
  font-size: 24px;
  color: #313840;
  margin-bottom: 25px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${p => getMediaPx(p, "md")}px) {
    font-size: 18px;
    font-weight: 600;
  }
`;
