import config from "config/app";

export class Poi {
  /** @type {number} */
  id;

  /** @type {string} */
  name = "";

  /** @type {string} */
  location;

  /** @type {number} */
  latitude;

  /** @type {number} */
  longitude;

  /** @type {number} */
  position;

  /** @type {string} */
  description;

  /** @type {string} */
  country;

  /** @type {string} */
  countryIso;

  /** @type {number} */
  iconId = 1;

  /** @type {object} */
  icon;

  /** @type {number} */
  createdBy;

  /** @type {number} */
  operatorId;

  /** @type {string} */
  timezone;

  /** @type {number} */
  itineraryId;

  /** @type {string} */
  createdAt;

  /** @type {string} */
  updatedAt;

  /** @type {object} */
  libraryNode;

  /** @type {boolean} */
  isDefaultOn = true;

  /** @type {boolean} */
  isOn;

  /** @type {number} */
  poiRange = config.modules.pois.defaultRange;

  /** @type {object} */
  meta;

  /** @type {object} */
  localisation = {};

  /** @type {"poi"|"track"|"waypoint"} */
  type = "poi";

  /** @type {number} */
  parentId;

  /** @type {array} */
  children = [];

  /** @type {number} */
  aliasForId;

  /** @type {object} */
  aliasFor;
}
