import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { CUSTOM_APPS } from "constants/content";
import {
  setValueForTextsBgColor,
  setValueForTextsContentColor,
  setValueForTextsHeadlineColor,
  setColorForApp,
} from "store/customAppForm/actions";
import { PERMISSIONS } from "constants/permissions";
import { usePermissionsService } from "hooks/usePermissionsService";

import { CustomColorPicker } from "../ColorPicker/CustomColorPicker";
import { CustomCard, SectionTitle } from "../sharedComponents";

const ColorPickerWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.setSpacing(5)}px;
`;

const PickerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const GeneralSectionContent = styled.div`
  padding: 51px 91px 21px 84px;
`;

const ColorBox = ({ isHistoryVersion }) => {
  const permissionsService = usePermissionsService();
  const dispatch = useDispatch();

  const caStatus = useSelector(state => state.customAppForm?.customAppForm?.status);
  const textsColors = useSelector(state => state.customAppForm?.customAppForm?.menu?.documents?.terms?.styles);

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.operator, PERMISSIONS.resources.operators);
  const canCreate = permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.operator, PERMISSIONS.resources.operators);
  const editDisabled = !((caStatus === null && canCreate) || (caStatus === "draft" && canEdit));

  const handleBackgroundColor = color => {
    dispatch(setColorForApp({ varName: "backgroundColor", color }));
  };

  const handleHeaderTextColor = color => {
    dispatch(setColorForApp({ varName: "headlineColor", color }));
  };

  const handleBodyTextColor = color => {
    dispatch(setColorForApp({ varName: "textColor", color }));
  };

  return (
    <CustomCard noColor>
      <GeneralSectionContent>
        <SectionTitle>{CUSTOM_APPS.colors.title}</SectionTitle>
        <PickerContainer>
          <ColorPickerWrapper>
            <CustomColorPicker
              color={textsColors?.backgroundColor}
              label={CUSTOM_APPS.colors.bgColorLabel}
              onColorChange={handleBackgroundColor}
              disabled={isHistoryVersion || editDisabled}
            />
          </ColorPickerWrapper>
          <ColorPickerWrapper>
            <CustomColorPicker
              color={textsColors?.headlineColor}
              label={CUSTOM_APPS.colors.headerTextLabel}
              onColorChange={handleHeaderTextColor}
              disabled={isHistoryVersion || editDisabled}
            />
          </ColorPickerWrapper>
          <ColorPickerWrapper>
            <CustomColorPicker
              color={textsColors?.textColor}
              label={CUSTOM_APPS.colors.bodyTextLabel}
              onColorChange={handleBodyTextColor}
              disabled={isHistoryVersion || editDisabled}
            />
          </ColorPickerWrapper>
        </PickerContainer>
      </GeneralSectionContent>
    </CustomCard>
  );
};

ColorBox.defaultProps = {
  isHistoryVersion: false,
};

ColorBox.propTypes = {
  isHistoryVersion: PropTypes.bool,
};

export { ColorBox };
