import React, { useEffect } from "react";
import Checkbox from "components/_new/Checkbox";
import { Controller, useFieldArray } from "react-hook-form";
import Switch from "components/_new/Switch";
import { colors } from "config/theme/colors";
import { SizePicker, PickerButton } from "feature/panel/Stays/_shared/Publisher/styledComponents";
import { PUBLISHER_CONTENT } from "constants/content";
import { ResponsiveHideFrom, ResponsiveShowFrom } from "feature/panel/Itinerary/responsive";
import DnDHeader from "../../Stays/DailyActivities/DnDHeader";
import { DirectoryItemWrap } from "./style";

const sizes = [
  {
    key: "large",
    label: PUBLISHER_CONTENT.large,
  },
  {
    key: "small",
    label: PUBLISHER_CONTENT.small,
  },
];

const PublisherStep2 = ({ control, directories, setValue, watch, data }) => {
  const name = `sections.directory.nodes`;
  const { fields } = useFieldArray({
    control,
    name,
    keyName: "dir_key",
  });

  const savedDirectories = data?.savedDirectoryNodes;
  const hasSavedDirectories = savedDirectories?.length > 0;
  const watchFields = watch(name);
  const checkedCount = Array.isArray(watchFields) && watchFields?.filter(item => item.checked)?.length;
  const getDefaultOptions = item => ({ image_size: "small", show_image: true, page_break_before: false, section: item.tag });
  const findSavedItem = item => savedDirectories?.find(savedItem => savedItem.section === item.tag);

  useEffect(() => {
    if (!fields.length) {
      setValue(
        name,
        directories?.map(item => {
          return {
            checked: hasSavedDirectories ? Boolean(findSavedItem(item)) : true,
            name: item.name,
            ...(hasSavedDirectories ? findSavedItem(item) || getDefaultOptions(item) : getDefaultOptions(item)),
          };
        }),
      );
    }
  }, [directories]);

  return (
    <div>
      <DnDHeader
        content={
          <>
            <div>
              <Checkbox
                checked={checkedCount === watchFields?.length}
                indeterminate={checkedCount > 0 && checkedCount !== watchFields?.length}
              />
            </div>
            <ResponsiveHideFrom size="md">
              <div>Directory</div>
              <div>New page</div>
              <div>Show image</div>
              <div>Image size</div>
            </ResponsiveHideFrom>
            <ResponsiveShowFrom size="md">
              <div style={{ whiteSpace: "nowrap" }}>Select all</div>
            </ResponsiveShowFrom>
          </>
        }
        wrapStyle={{
          gridTemplateColumns: "40px 1fr 100px 100px 160px",
          padding: 0,
          paddingLeft: 15,
          marginTop: 15,
          borderBottom: `1px solid ${colors.grey10}`,
        }}
      />
      {watchFields?.map((item, index) => (
        <DirectoryItemWrap>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox name={`${name}.${index}.checked`} control={control} wrapStyle={{ marginRight: 0 }} />
            <div>{item.name}</div>
          </div>
          <div>
            <Switch
              name={`${name}.${index}.page_break_before`}
              label={
                <ResponsiveShowFrom size="md" style={{ color: colors.grey30 }}>
                  New page
                </ResponsiveShowFrom>
              }
              control={control}
            />
          </div>
          <div>
            <Switch
              name={`${name}.${index}.show_image`}
              label={
                <ResponsiveShowFrom size="md" style={{ color: colors.grey30 }}>
                  Show image
                </ResponsiveShowFrom>
              }
              control={control}
            />
          </div>
          <div>
            <SizePicker disabled={!item.show_image} disableRipple>
              {sizes?.map(item => (
                <Controller
                  control={control}
                  name={`${name}.${index}.image_size`}
                  defaultValue="small"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <PickerButton size={item.key} style={{ fontSize: 12 }} selected={value === item.key} onClick={() => onChange(item.key)}>
                      {item.label}
                    </PickerButton>
                  )}
                />
              ))}
            </SizePicker>
          </div>
        </DirectoryItemWrap>
      ))}
    </div>
  );
};

export default PublisherStep2;
