import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import SearchIcon from "@mui/icons-material/Search";
import { useQuery } from "@tanstack/react-query";
import BackButton from "components/_new/Buttons/BackButton";
import Input from "components/ui/Inputs/TextInput";
import { FilesList } from "components/ui/LibraryPopup/Components/FileUpload/FilesList";
import { Spinner } from "components/ui/Spinner/Spinner";
import { colors } from "config/theme/colors";
import { LIBRARY_ROOT_PATH } from "constants/api";
import { DEFAULT_DEBOUNCE_DELAY } from "constants/defaults";
import { ResponsiveHideFrom } from "feature/panel/Itinerary/responsive";
import { debounce } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { getLibraryPathJSON } from "utils/library";
import { encodeParameter } from "utils/url";
import Breadcrumbs from "./breadcrumbs";
import Folders from "./folders";
import { FilesWrap, NavLine } from "./style";

const getActiveFile = (arr, selectedAssets) => {
  return arr?.map(item => {
    const active = selectedAssets.map(selected => selected.id).includes(item.id);
    return { ...item, active };
  });
};

const FilesTab = ({ allowedFileTypes = [], handleMiniatureClick, selectedAssets, hideUrl }) => {
  const { control, watch } = useForm();

  const currentOperatorCode = useSelector(state => state.auth.currentOperatorCode);
  const libraryPathJSON = getLibraryPathJSON(currentOperatorCode);

   const [currentPath, setCurrentPath] = useState(libraryPathJSON?.operator === currentOperatorCode ? libraryPathJSON.path : LIBRARY_ROOT_PATH);
  // const [currentPath, setCurrentPath] = useState(localStorage.getItem("libraryPath") || LIBRARY_ROOT_PATH);
  
  const [search, setSearch] = useState("");

  const { data, isFetching } = useQuery({
    queryKey: [`/file?path=${encodeParameter(currentPath)}`],
    keepPreviousData: true,
    refetchOnMount: true,
  });
  const { data: searchData, isLoading: isLoadingSearch, isFetching: isFetchingSearch } = useQuery({
    queryKey: [`/file/search?q=${search}`],
    enabled: search?.length > 0,
  });
  const searchWatch = watch("search");

  const folders = useMemo(() => {
    const baseResults = search?.length > 0 ? searchData?.items?.filter(item => item.is_folder) : data?.filter(item => item.is_folder);
    return baseResults?.sort((a, b) => {
      if (a.name.localeCompare(b.name) < 0) {
        return -1;
      }
      if (a.name.localeCompare(b.name) > 0) {
        return 1;
      }
      return 0;
    });
  }, [searchData, search, data]);

  const files =
    search?.length > 0
      ? getActiveFile(
          searchData?.items?.filter(
            item => !item.is_folder && (allowedFileTypes.includes(item.file?.mime_type) || (!hideUrl && item.remote_url)),
          ),
          selectedAssets,
        )
      : getActiveFile(
          data?.filter(item => !item.is_folder && (allowedFileTypes.includes(item.file?.mime_type) || (!hideUrl && item.remote_url))),
          selectedAssets,
        );

  const debouncedSearch = useRef(debounce(value => setSearch(value), DEFAULT_DEBOUNCE_DELAY));

  const handleBackButton = () => {
    const path = currentPath
      .split("/")
      .slice(0, -1)
      .join("/");

    localStorage.setItem("libraryPath", path);
    setCurrentPath(path);
  };

  const handleFolderClick = ({ path }) => {
    localStorage.setItem("libraryPath", path);
    setCurrentPath(path);
    setSearch("");
  };

  useEffect(() => {
    if (searchWatch?.length > 0) {
      debouncedSearch.current(searchWatch);
    }

    if (!searchWatch) setSearch("");
  }, [searchWatch]);

  return (
    <>
      <Input endAdornment={<SearchIcon />} label="Search" control={control} name="search" style={{ marginTop: 15 }} />
      <ResponsiveHideFrom size="xs">
        <NavLine style={{ marginTop: 15, marginLeft: 0 }}>
          {LIBRARY_ROOT_PATH !== currentPath ? <BackButton onClick={handleBackButton} /> : <div />}
          <Breadcrumbs currentPath={currentPath} setCurrentPath={setCurrentPath} />
        </NavLine>
      </ResponsiveHideFrom>
      <div style={{ width: "100%", overflowY: "scroll" }}>
        {folders?.length > 0 && !isFetching && (
          <Folders currentPath={currentPath} folders={folders} handleFolderClick={handleFolderClick} isLoading={isFetching} />
        )}
        {files?.length > 0 && !isFetching && (
          <FilesWrap>
            <FilesList files={files} handleMiniatureClick={handleMiniatureClick} selectable webSource />
          </FilesWrap>
        )}
        <div>{(isFetching || isFetchingSearch) && <Spinner />}</div>
        {((search?.length > 0 && searchData?.total_matches === 0) || (!files?.length && !folders?.length)) && !isLoadingSearch && (
          <div style={{ display: "flex", alignItems: "center", color: colors.grey50, marginTop: 15 }}>
            <ContentPasteSearchIcon style={{ marginRight: 5 }} /> No files found
          </div>
        )}
      </div>
    </>
  );
};

export default FilesTab;
