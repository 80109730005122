// @ts-check

import {
  CREATRIONS_URL,
  GET_ITINERARIES_URL,
  GET_PUBLIC_STAYS_URL,
  ITINERARY_REMOVE_USER_URL,
  ITINERARY_UPDATE_URL,
  ITINERARY_URL,
  ITINERARY_USERS_URL,
  LOCALISED_ITINERARY_URL,
  SET_DEFAULT_ITINERARY_LANGUAGE_URL,
} from "constants/api";
import { DEFAULT_PASSCODE } from "constants/defaults";
import { stayResponseDataMapper } from "feature/panel/Stays/_shared/payloadDataMapper";
import { HttpClient } from "services/application/httpClient/httpClient";
import { setUrl, setUrlParams } from "utils/url";

class StayRepository {
  constructor() {
    /**
     * @type {import("services/application/httpClient/httpClient").HttpClient}
     */
    this.httpClient = HttpClient;
  }

  async createStay(operatorCode, stay, operator = null) {
    const url = setUrl(ITINERARY_URL, { operator: operatorCode, code: DEFAULT_PASSCODE }, true);
    const headers = operator ? { "X-Operator-Code": operator } : {};

    const { data } = await this.httpClient.post(url, stay, { headers });

    return data;
  }

  async createStayVariant(operatorCode, stay, language, operator = null) {
    let url = "";
    if (language.isDefault) {
      url = setUrl(ITINERARY_URL, { operator: operatorCode, code: DEFAULT_PASSCODE }, true);
    } else {
      url = setUrl(LOCALISED_ITINERARY_URL, { operator: operatorCode, code: DEFAULT_PASSCODE, languageCode: language.code }, true);
    }
    const headers = operator ? { "X-Operator-Code": operator } : {};

    const { data } = await this.httpClient.post(url, stay, { headers });

    return data;
  }

  async updateStay(operatorCode, stay, operator = null) {
    const url = setUrl(ITINERARY_URL, { operator: operatorCode, code: DEFAULT_PASSCODE }, true);
    const headers = operator ? { "X-Operator-Code": operator } : {};

    const { data } = await this.httpClient.post(url, stay, { headers });

    return data;
  }

  async updateStayVariant(operatorCode, stay, language, operator = null) {
    let url = "";
    if (language.isDefault) {
      url = setUrl(ITINERARY_URL, { operator: operatorCode, code: DEFAULT_PASSCODE }, true);
    } else {
      url = setUrl(LOCALISED_ITINERARY_URL, { operator: operatorCode, code: DEFAULT_PASSCODE, languageCode: language.code }, true);
    }
    const headers = operator ? { "X-Operator-Code": operator } : {};

    const { data } = await this.httpClient.post(url, stay, { headers });

    return data;
  }

  async setStayFallbackLanguage(operatorCode, language, operator = null) {
    const url = setUrl(SET_DEFAULT_ITINERARY_LANGUAGE_URL, { operator: operatorCode, code: DEFAULT_PASSCODE }, true);
    const headers = operator ? { "X-Operator-Code": operator } : {};

    const { data } = await this.httpClient.post(url, { language }, { headers });

    return data;
  }

  /**
   * @param {string} operator
   * @returns {Promise<any>}
   */
  async getStay(operator) {
    const url = setUrl(ITINERARY_URL, { operator, code: DEFAULT_PASSCODE }, true);

    const { data: stayDto } = await this.httpClient.get(url);

    return stayResponseDataMapper(stayDto);
  }

  async saveStay(operatorCode, { variants, ...stay }, operator = null) {
    const url = setUrl(ITINERARY_URL, { operator: operatorCode, code: DEFAULT_PASSCODE }, true);
    const headers = operator ? { "X-Operator-Code": operator } : {};

    const { data: createdStay } = await this.httpClient.post(url, stay, { headers });

    return createdStay;
  }

  async saveStayVariant(operatorCode, { variants, ...variant }, operator = null, language) {
    const headers = operator ? { "X-Operator-Code": operator } : {};
    const variantUrl = setUrl(
      LOCALISED_ITINERARY_URL,
      {
        operator: operatorCode,
        code: DEFAULT_PASSCODE,
        languageCode: language,
      },
      true,
    );

    return this.httpClient.post(variantUrl, { ...variant }, { headers });
  }

  async deactivateStay(operatorCode, operator = null) {
    const headers = operator ? { "X-Operator-Code": operator } : {};

    const variantUrl = setUrl(ITINERARY_URL, {
      operator: operatorCode,
      code: DEFAULT_PASSCODE,
    });

    return this.httpClient.delete(variantUrl, { headers });
  }

  async deactivateStayVariant(operatorCode, language, operator = null) {
    const headers = operator ? { "X-Operator-Code": operator } : {};

    const variantUrl = setUrl(LOCALISED_ITINERARY_URL, {
      operator: operatorCode,
      code: DEFAULT_PASSCODE,
      languageCode: language,
    });

    return this.httpClient.delete(variantUrl, { headers });
  }

  /**
   * @param {*} operator
   * @returns
   */
  async getStayVariant(operator, languageCode = null) {
    if (languageCode === null) {
      const url = setUrl(ITINERARY_URL, { operator, code: DEFAULT_PASSCODE }, true);
      const { data } = await this.httpClient.get(url);
      return stayResponseDataMapper(data);
    }

    const url = setUrl(LOCALISED_ITINERARY_URL, { operator, code: DEFAULT_PASSCODE, languageCode }, true);
    const { data } = await this.httpClient.get(url);
    return stayResponseDataMapper(data);
  }

  async getVariantsStatus(operator, variants) {
    const statuses = await Promise.all(
      variants.map(async variant => {
        try {
          const url = setUrl(LOCALISED_ITINERARY_URL, { operator, code: DEFAULT_PASSCODE, languageCode: variant.language }, true);
          const { data } = await this.httpClient.get(url);
          return {
            ...variant,
            isActive: data.is_active,
          };
        } catch (e) {
          return null;
        }
      }),
    );

    return statuses.filter(variant => !!variant);
  }

  /**
   *
   * @param {string} operator
   * @param {any=} params
   * @returns {Promise<any>}
   */
  async getStayUsers(operator, { rowsPerPage, page, sortBy = "", order = "desc" }) {
    const requestParams = {
      count: rowsPerPage,
      page,
      order_by: sortBy?.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`),
      order,
    };

    const url = setUrlParams(setUrl(ITINERARY_USERS_URL, { operator, code: DEFAULT_PASSCODE }, true), requestParams);
    const { data } = await this.httpClient.get(url);
    return data;
  }

  /**
   *
   * @param {string} email
   * @param {"read"|"write"} permission
   * @param {string} operator
   * @returns {Promise<any>}
   */
  async addUserToStay(email, permission, operator) {
    const url = setUrl(ITINERARY_USERS_URL, { operator, code: DEFAULT_PASSCODE }, true);
    const { data } = await this.httpClient.put(url, {
      email,
      permission,
    });
    return data;
  }

  /**
   *
   * @param {number} userId
   * @param {string} operator
   * @returns {Promise<any>}
   */
  async removeUserFromStay(userId, operator) {
    const url = setUrl(ITINERARY_REMOVE_USER_URL, { operator, code: DEFAULT_PASSCODE, userId }, true);
    const { data } = await this.httpClient.delete(url);
    return data;
  }

  async sendUpdate(userId, updateDto) {
    const url = setUrl(
      ITINERARY_UPDATE_URL,
      {
        operator_code: userId,
        reference_code: "DEFAULT",
        resource_type: "itinerary",
      },
      true,
    );

    const { data } = await this.httpClient.post(url, updateDto);

    return data;
  }

  async getAllPublisherDocuments(userId) {
    const setPublishersUrl = setUrl(CREATRIONS_URL, { operator_code: userId, reference_code: DEFAULT_PASSCODE }, true);
    const publishers = await this.httpClient.get(setUrlParams(setPublishersUrl, { count: 5000 }));
    const {
      data: { items },
    } = publishers || {};

    return items;
  }

  async getStays(page, rowsPerPage, sortingOrder, sortingBy, showArchived, searchQuery = null, requestParams) {
    const { data } = await this.httpClient.get(
      setUrlParams(GET_ITINERARIES_URL, {
        type: "stay",
        page,
        count: rowsPerPage,
        order: sortingOrder,
        order_by: sortingBy,
        search: searchQuery,
        archived: showArchived ? "only" : "off",
        ...(requestParams ?? {}),
      }),
    );
    return data;
  }

  async getPublicStays() {
    const url = setUrlParams(GET_PUBLIC_STAYS_URL, { count: 5000 });
    const { data } = await this.httpClient.get(url);

    return data;
  }
}

export { StayRepository };
