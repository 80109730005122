import { getMediaPx } from "helpers";
import styled from "styled-components";

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 15px;
  width: 80%;
  @media (max-width: ${p => getMediaPx(p, "sm")}px) {
    grid-template-columns: 1fr;
    width: 100%;
  }
`;
