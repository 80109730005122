import React, { useEffect } from "react";
import { useTreeViewApiRef } from "@mui/x-tree-view";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// eslint-disable-next-line  import/no-unresolved
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import FolderIcon from "@mui/icons-material/Folder";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { colors } from "config/theme/colors";
import { Wrap } from "./style";

const CustomFolderIcon = () => <FolderIcon style={{ color: colors.grey40 }} />;
const CustomFolderOpenIcon = () => <FolderOpenIcon style={{ color: colors.grey40 }} />;

const TreeList = ({ children, onItemFocus, defaultExpandedItems, setExpanded }) => {
  const apiRef = useTreeViewApiRef();

  const focusAndScroll = () => {
    const addToLibraryPath = localStorage.getItem("addToLibraryPath");
    const nodeId = addToLibraryPath?.slice(1);
    const expandedItem = apiRef.current.getItemDOMElement(nodeId);

    if (!expandedItem) {
      setExpanded(false);
      localStorage.removeItem("addToLibraryPath");
      return;
    }

    apiRef.current.focusItem("", nodeId);
    apiRef.current.getItemDOMElement(nodeId)?.scrollIntoView({ block: "center" });
  };

  useEffect(() => {
    if (!defaultExpandedItems?.length) return;
    const timeout = setTimeout(() => {
      focusAndScroll();
    }, 0);
    return () => clearTimeout(timeout);
  }, [defaultExpandedItems?.length]);

  return (
    <Wrap>
      <SimpleTreeView
        aria-label="multi-select"
        slots={{ expandIcon: CustomFolderIcon, collapseIcon: CustomFolderOpenIcon }}
        onItemFocus={onItemFocus}
        sx={{ flexGrow: 1, overflowY: "auto" }}
        defaultExpandedItems={defaultExpandedItems}
        apiRef={apiRef}
      >
        {children}
      </SimpleTreeView>
    </Wrap>
  );
};

export default TreeList;
